import Config from '../../config';
import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { crediavance_assessment_study } from './crediavance_socioeconomic_study_pages';
import { credicapital_assessment_study } from './credicapital_socioeconomic_study_pages';
import { rentamigo_assessment } from './rentamigo_assesment_pages';

export function assessment_study(locale = DEFAULT_LOCALE) {
  // deployment specific pages
  const availablePages = {
    crediavance: crediavance_assessment_study,
    credicapital: credicapital_assessment_study,
    rentamigo: rentamigo_assessment,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showQuestionNumbers: 'off',
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate('general.startSurveyText'),

    showCompletedPage: false,

    pagePrevText: translate('general.pagePrevText'),
    pageNextText: translate('general.pageNextText'),
    completeText: translate('general.pageCompleteText'),
    requiredText: '',
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
    locale,
  };
}
