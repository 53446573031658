import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const intake_procredito_pages_transport = (clientUrlForCatalog) => [
  // Page 1: Applicant Information
  {
    title: { en: "Applicant information", "es-MX": "Datos generales" },
    innerIndent: 1,
    name: "applicant_info",
    elements: [
      {
        title: { en: "First name*", "es-MX": "Primer nombre*" },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: { en: "Middle name", "es-MX": "Segundo nombre" },
        type: "text",
        name: "middle_name",
      },
      {
        title: { en: "Surname *", "es-MX": "Primer apellido*" },
        type: "text",
        name: "surname",
        isRequired: required,
      },
      {
        title: { en: "Second surname", "es-MX": "Segundo apellido" },
        type: "text",
        name: "second_surname",
      },
      {
        title: { en: "Gender", "es-MX": "Género" },
        type: "dropdown",
        isRequired: required,
        name: "gender",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_genders/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Date of Birth *",
          "es-MX": "Fecha de nacimiento *",
        },
        name: "date_of_birth",
        type: "text",
        inputType: "date",
        isRequired: required,
      },
      {
        title: {
          en: "Occupation/Profession",
          "es-MX": "Ocupación/Profesión",
        },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_8",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_professions/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "To confirm identification, please upload a profile photo",
          "es-MX":
            "Para confirmar la identificación, por favor sube una foto de perfil",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        type: "panel",
        name: "INE_panel",
        title: {
          en: "Which type of INE do you have? Select one *",
          "es-MX": "¿Qué tipo de INE tienes? Elige un opción *",
        },
        elements: [
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>1.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_1.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>2.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_2.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>3.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_3.png")}' width='95%'/>`,
          },
          {
            title: { en: "INE Type", "es-MX": "Tipo de INE" },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_71",
            choices: [
              { value: "1", text: { en: "1.", "es-MX": "1." } },
              { value: "2", text: { en: "2.", "es-MX": "2." } },
              { value: "3", text: { en: "3.", "es-MX": "3." } },
            ],
          },
          {
            type: "panel",
            name: "INE_1_panel",
            visibleIf: "{custom_text_71} == '1'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 digitos)",
                  "es-MX": "CIC (9 digitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 digitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Identificador del ciudadano (9 digitos)",
                  "es-MX": "Identificador del ciudadano (9 digitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 digitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_2_panel",
            visibleIf: "{custom_text_71} == '2'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 digitos)",
                  "es-MX": "CIC (9 digitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 digitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "OCR (13 digitos)",
                  "es-MX": "OCR (13 digitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 digitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_3_panel",
            visibleIf: "{custom_text_71} == '3'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "Clave de elector (18 digitos)",
                  "es-MX": "Clave de elector (18 digitos)",
                },
                maxLength: "18",
                placeHolder: "GAVLLR68082309A123",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "18 digits. Incorrect format",
                      "es-MX": "18 digitos. Formato incorrecto",
                    },
                    regex: `^[a-zA-Z]{6}[0-9]{8}[a-zA-Z]{1}[0-9]{3}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número OCR (13 digitos)",
                  "es-MX": "Número OCR (13 digitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 digitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
              {
                name: "custom_text_73",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número de Emisión (2 digitos)",
                  "es-MX": "Número de Emisión (2 digitos)",
                },
                maxLength: "2",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "2 digits",
                      "es-MX": "2 digitos",
                    },
                    regex: `^[0-9]{2}$`,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: { en: "front image", "es-MX": "Imagen parte de adelante" },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file",
        isRequired: required,
      },
      {
        title: { en: "back image", "es-MX": "Imagen parte de atrás" },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file_back",
        isRequired: required,
      },
      {
        title: {
          en: "To verify your identification, please upload a picture of yoursel holding you ID",
          "es-MX":
            "Para verificar tu identidad por favor, sube una foto sujetando dicha  identificación",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file_with_avatar",
        isRequired: required,
      },
      {
        name: "phone_number",
        type: "phone",
        isRequired: required,
        title: {
          en: "Mobile phone number *",
          "es-MX": "No. celular*",
        },
        maxLength: "10",
        validators: [
          expressions.phone_en("phone_number"),
          expressions.phone_length("phone_number"),
        ],
      },
      {
        name: "advisor_phone_number",
        type: "phone",
        title: {
          en: "home telephone number",
          "es-MX": "Teléfono casa",
        },
        maxLength: "10",
        validators: [
          expressions.phone_en("advisor_phone_number"),
          expressions.phone_length("advisor_phone_number"),
        ],
      },
      {
        title: { en: "Email*", "es-MX": "Correo electrónico*" },
        type: "text",
        name: "email",
        isRequired: required,
        validators: [{ type: "email" }],
      },
      {
        title: {
          en: "Type of person",
          "es-MX": "Tipo de persona",
        },
        type: "dropdown",
        name: "custom_text_3",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_client_types/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Do you have registration with the SAT?",
          "es-MX": "¿Cuentas con registro ante el SAT?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_9",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Indicanos tu Número de Identificación Fiscal (RFC)",
          "es-MX": "Indícanos tu Número de Identificación Fiscal (RFC)",
        },
        type: "text",
        readOnly: false,
        visibleIf: "{custom_text_9} == 'si'",
        name: "custom_text_5",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 10 characters or 13 with homoclave",
              "es-MX": "RFC 10 dígitos o 13 dígitos incluyendo homoclave",
            }, // Requires translation
            regex: `^([A-Za-z]{4}[ |-]{0,1}[0-9]{6})([0-9A-Za-z]{3}|$)$`, // should be the correct regex that includes 3 more chars at the end
          },
        ],
        placeHolder: "VECJ880326",
      },
      {
        title: {
          en: "Type of request",
          "es-MX": "Tipo de solicitud ",
        },
        type: "dropdown",
        name: "custom_text_10",
        choices: [
          { value: "nueva", text: { en: "New", "es-MX": "Nueva" } },
          {
            value: "recurrente",
            text: { en: "Recurring", "es-MX": "Recurrente" },
          },
          {
            value: "refinanciamiento_o_reestructura",
            text: {
              en: "Refinancing or restructuring",
              "es-MX": "Refinanciamiento o Reestructura",
            },
          },
        ],
      },
      {
        title: {
          en: "Credit destination",
          "es-MX": "Destino del crédito",
        },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_15",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_loan_destinations/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Specify credit destination",
          "es-MX": "Especificar destino del crédito",
        },
        type: "text",
        name: "custom_text_23",
      },
      {
        title: {
          en: "Activity or line of business",
          "es-MX": "Actividad o giro del negocio ",
        },
        type: "dropdown",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_economic_activity_types/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
        name: "custom_text_24",
      },
      {
        title: {
          en: "Years of business operation",
          "es-MX": "Años de operación del negocio",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_33",
      },
    ],
  },
  // Page 2: Fixed Costs
  {
    title: { en: "Fixed costs", "es-MX": "Gastos familiares" },
    innerIndent: 1,
    name: "fixed_costs",
    elements: [
      {
        title: {
          en: "Are you paying rent or mortgage?",
          "es-MX": "¿Estás pagando renta o hipoteca?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_25",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Tell us how much you pay per month",
          "es-MX": "Indícanos cuánto pagas al mes",
        },
        type: "text",
        visibleIf: "{custom_text_25} == 'si'",
        inputType: "number",
        name: "custom_float_10",
        isRequired: required,
      },
      {
        name: "custom_float_2",
        type: "text",
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_2")],
        title: {
          en: "Fixed costs (monthly)",
          "es-MX": "Gastos familiares (mensual)",
        },
      },
      {
        title: {
          en: "Total monthly expenses",
          "es-MX": "Total de gastos familiares",
        },
        type: "expression",
        name: "edited_mcf_total_expense_amount",
        expression:
          "formatCurrency(fixed({mcf_total_expense_amount}, 2, true))+' MXN'",
      },
      {
        visibleIf: "1 == 0",
        title: {
          en: "Total monthly expenses",
          "es-MX": "Total de gastos familiares",
        },
        type: "expression",
        name: "mcf_total_expense_amount",
        expression: "fixed(add({custom_float_10}, {custom_float_2}))", // Floating Point Proof
      },
    ],
  },
  // Page 3: Family Information
  {
    title: {
      en: "Family information",
      "es-MX": "Información familiar",
    },
    innerIndent: 1,
    name: "family_info",
    elements: [
      {
        title: {
          en: "What is your marital status? *",
          "es-MX": "¿Cuál es tu estado civil?",
        },
        type: "dropdown",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_marital_status/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
        isRequired: required,
        name: "marital",
      },
      {
        type: "panel",
        visibleIf: "{marital} == '2'",
        innerIndent: 1,
        name: "if_married",
        elements: [
          {
            title: {
              en: "Please upload a photo of the marriage certificate",
              "es-MX": "Por favor, sube una foto del acta de matrimonio",
            },
            description: {
              en: "If you do not have this document at hand, you will have to bring it with you on the day of signing",
              "es-MX":
                "De no tener este documento a mano, lo tendrás que llevar el día de la firma",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_2",
          },
          {
            title: {
              en: "Indicate the regime for which you are married",
              "es-MX": "Indica el régimen por el que estás casado",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_11",
            choices: [
              {
                value: "bienes_mancomunados",
                text: { en: "Joint", "es-MX": "Bienes mancomunados" },
              },
              {
                value: "separación_de_bienes",
                text: {
                  en: "Separate assets",
                  "es-MX": "Separación de bienes",
                },
              },
            ],
          },
          {
            title: {
              en: "Spouse fullname",
              "es-MX": "Nombre completo del cónyuge",
            },
            type: "text",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_12",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse Mobile No.",
              "es-MX": "Número de teléfono del cónyuge",
            },
            type: "phone",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_1",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse Job",
              "es-MX": "Situación laboral del cónyuge",
            },
            type: "text",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_13",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse Work Address",
              "es-MX": "Dirección laboral del cónyuge",
            },
            type: "text",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_77",
            isRequired: required,
          },
        ],
      },
      {
        title: {
          en: "How many people depend on you financially? *",
          "es-MX": "¿Cuántas personas dependen de ti financieramente? *",
        },
        description: {
          en: "Ex. aunts, parents, cousins, friends",
          "es-MX": "Ej. tías, padres, primos, amigos",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_6",
        isRequired: required,
      },
    ],
  },
  // Page 4: Residence and home information
  {
    title: {
      en: "Residence and home information",
      "es-MX": "Información de residencia",
    },
    innerIndent: 1,
    name: "residence_home_info",
    elements: [
      {
        title: { en: "Nationality *", "es-MX": "Nacionalidad *" },
        type: "text",
        name: "nationality",
        isRequired: required,
      },
      {
        title: { en: "Country of birth *", "es-MX": "País de nacimiento *" },
        type: "dropdown",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
        name: "custom_text_26",
        isRequired: required,
      },
      {
        title: { en: "State of birth *", "es-MX": "Estado de nacimiento *" },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_85",
        isRequired: required,
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={custom_text_26}`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
      },
      {
        title: {
          en: "Municipality of birth *",
          "es-MX": "Municipio de nacimiento *",
        },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_27",
        isRequired: required,
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_85}`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
      },
      {
        title: { en: "City of birth *", "es-MX": "Ciudad/Localidad de nacimiento *" },
        type: "dropdown",
        renderAs: "select2",
        name: "id_question_birthplace",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_27}`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
      },
      {
        title: { en: "Where do you live?", "es-MX": "¿En dónde vives?" },
        type: "panel",
        name: "address_details",
        elements: [
          {
            title: {
              en: "Type of address",
              "es-MX": "Tipo de domicilio",
            },
            type: "dropdown",
            name: "custom_text_30",
            defaultValue: "2",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_address_types/?id=2`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Country *", "es-MX": "País *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_country",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "State", "es-MX": "Estado *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_14",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={home_country}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Municipality", "es-MX": "Municipio *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_28",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_14}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "City/Town", "es-MX": "Ciudad/Localidad *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_city",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_28}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Postal Code *", "es-MX": "Código postal*" },
            type: "dropdown",
            renderAs: "select2",
            name: "po_box_postal_code",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_28}`,
              path: "results",
              valueName: "zipcode",
            },
          },
          {
            title: { en: "Suburb", "es-MX": "Colonia" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_29",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={po_box_postal_code}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: {
              en: "Years of residence",
              "es-MX": "Años de residencia ",
            },
            type: "text",
            inputType: "number",
            name: "custom_integer_27",
            isRequired: required,
          },
          {
            name: "advisor_phone_number",
            type: "phone",
            validators: [
              expressions.phone_en("custom_text_31"),
              expressions.phone_length("custom_text_31"),
            ],
            maxLength: "10",
            title: {
              en: "Home phone number",
              "es-MX": "Número de teléfono del domicilio",
            },
          },
          {
            title: {
              en: "Attach proof of address",
              "es-MX": "Adjuntar imagen comprobante de domicilio",
            },
            description: {
              en: "No more than 3 months, electricity, water, telephone, etc.",
              "es-MX": "No mayor a 3 meses, luz, agua, teléfono, etc",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_3",
            isRequired: required,
          },
        ],
      },
    ],
  },
  // Page 5: Tax residence
  {
    title: {
      en: "Tax residence",
      "es-MX": "Domicilio fiscal",
    },
    innerIndent: 1,
    name: "tax_residence",
    elements: [
      {
        title: {
          en: "Tax certificate 1",
          "es-MX": "Constancia fiscal 1",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_4",
        visibleIf: "{custom_text_9} == 'si'",
      },
      {
        title: {
          en: "Tax certificate 2",
          "es-MX": "Constancia fiscal 2",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_5",
        visibleIf: "{custom_text_9} == 'si'",
      },
      {
        title: {
          en: "Tax certificate 3",
          "es-MX": "Constancia fiscal 3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_6",
        visibleIf: "{custom_text_9} == 'si'",
      },
      {
        title: {
          en: "Requires billing",
          "es-MX": "Requiere facturación ",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_32",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "tax_residence_details",
        title: {
          en: "Tax residence details",
          "es-MX": "Detalles de la residencia fiscal",
        },
        visibleIf: "{custom_text_32} == 'si'",
        elements: [
          {
            title: { en: "Street", "es-MX": "Calle" },
            type: "text",
            name: "custom_text_33",
          },
          {
            title: { en: "Outdoor number", "es-MX": "Número exterior" },
            type: "text",
            name: "custom_text_34",
          },
          {
            title: { en: "Interior number", "es-MX": "Número interior" },
            type: "text",
            name: "custom_text_35",
          },
          {
            title: { en: "Country *", "es-MX": "País *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_36",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "State", "es-MX": "Estado *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_38",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={custom_text_36}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Municipality", "es-MX": "Municipio" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_39",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_38}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "City/Delegation", "es-MX": "Ciudad/Localidad" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_40",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_39}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Postal Code *", "es-MX": "Código postal *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_37",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_39}`,
              path: "results",
              valueName: "zipcode",
            },
          },
          {
            title: { en: "Suburb", "es-MX": "Colonia" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_41",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={custom_text_37}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: {
              en: "Type of address",
              "es-MX": "Tipo de domicilio",
            },
            type: "dropdown",
            name: "custom_text_49",
            defaultValue: "7",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_address_types/?id=7`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: {
              en: "Years of residence",
              "es-MX": "Años de residencia ",
            },
            type: "text",
            inputType: "number",
            name: "custom_integer_28",
            isRequired: required,
          },
          {
            name: "custom_text_51",
            type: "phone",
            maxLength: "10",
            validators: [
              expressions.phone_en("custom_text_51"),
              expressions.phone_length("custom_text_51"),
            ],
            title: {
              en: "Telephone number of this address",
              "es-MX": "Número telefónico del domicilio",
            },
          },
        ],
      },
    ],
  },
  // Page 6: Client profile
  {
    title: {
      en: "Client profile",
      "es-MX": "Perfil del cliente",
    },
    innerIndent: 1,
    name: "client profile",
    elements: [
      {
        title: {
          en: "Have you held any public office in the last 12 months (national / foreign)? *",
          "es-MX":
            "¿Ha desempeñado algún cargo público en los últimos 12 meses (nacional/extranjero)? *",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_52",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "public_office_details",
        visibleIf: "{custom_text_52} == 'si'",
        elements: [
          {
            title: { en: "Job title", "es-MX": "Nombre del cargo" },
            type: "text",
            name: "custom_text_53",
          },
          {
            title: { en: "Dependence", "es-MX": "Dependencia" },
            type: "text",
            name: "custom_text_54",
          },
        ],
      },
      {
        title: {
          en: "Are you related to any person (spouse, relative or by affinity up to the second degree) who has held any public office in the last 12 months (National / Foreign)? *",
          "es-MX":
            "¿Está usted relacionado con alguna persona (Cónyuge, familiar consaguíneo o por afinidad hasta el segundo grado) que ha desempeñado algún cargo público en los últimos 12 meses (Nacional/Extranjero)? *",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_55",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_public_office_relation/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        type: "panel",
        name: "related_public_office_details",
        visibleIf: "{custom_text_55} == '334' || {custom_text_55} == '335' || {custom_text_55} == '336'",
        elements: [
          {
            title: { en: "Job title", "es-MX": "Nombre del cargo" },
            type: "text",
            name: "custom_text_56",
          },
          {
            title: { en: "Dependence", "es-MX": "Dependencia" },
            type: "text",
            name: "custom_text_57",
          },
        ],
      },
      {
        title: {
          en: "Is the resource provider for this contract the same requester?",
          "es-MX":
            "¿El proveedor de recursos para este contrato es el mismo solicitante? ",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_58",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "resource_provider_panel",
        visibleIf: "{custom_text_58} == 'no'",
        elements: [
          {
            title: { en: "Name and surname", "es-MX": "Nombre y apellidos" },
            type: "text",
            name: "custom_text_59",
          },
          {
            title: {
              en: "Mention the source from which you will obtain the resources (Own Business Salary, Inheritance, Loans, Savings, etc.)",
              "es-MX":
                "Mencionar la fuente de la cual obtendrá los recursos (Negocio propio, Salario, Herencia, Préstamos, Ahorro, etc)",
            },
            type: "text",
            name: "custom_text_60",
          },
        ],
      },
      {
        title: {
          en: "Mention the means of payment",
          "es-MX": "Mencionar el medio de pago ",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_61",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_payment/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Indicate the area in which the business is conducted",
          "es-MX": "Indique la zona en la que se realiza el negocio ",
        },
        type: "dropdown",
        renderAs: "select2",
        isRequired: required,
        name: "custom_text_62",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_zona_negocio/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "According to the previous question, indicate cities, state and localities",
          "es-MX":
            "Conforme a la pregunta anterior, indique ciudades, estado y localidades",
        },
        type: "text",
        name: "custom_text_63",
      },
    ],
  },
  // Page 7: Unit info
  {
    title: {
      en: "Unit info",
      "es-MX": "Información de la unidad actual",
    },
    innerIndent: 1,
    name: "unit_info",
    elements: [
      {
        name: "custom_integer_1",
        type: "text",
        inputType: "number",
        isRequired: required,
        title: {
          en: "How many units/trucks do you have? *",
          "es-MX": "¿Cuántas unidades tienes? *",
        },
      },
      {
        title: {
          en: "Unit/truck info",
          "es-MX": "Información de la unidad",
        },
        name: "custom_jsonfield_4",
        valueName: "custom_jsonfield_4",
        type: "matrixdynamic",
        minRowCount: 1,
        rowCount: 1, // @TODO @Themba can this be based on the question/amount above?
        isRequired: required,
        addRowText: {
          en: "Add",
          "es-MX": "Agregar unidad",
        },
        columns: [
          {
            title: {
              en: "Type",
              "es-MX": "Tipo",
            },
            name: "tipo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            name: "marca",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Model",
              "es-MX": "Modelo",
            },
            name: "modelo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Aproximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado_unidad",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
          },
        ],
      },
      {
        title: {
          en: "How many passengers do you carry?",
          "es-MX": "¿Cuál es la capacidad de pasajeros de la unidad?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_8",
        isRequired: required,
      },
      {
        title: {
          en: "How many years of experience do you have?",
          "es-MX": "¿Cuántos años de experiencia tienes?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_9",
        isRequired: required,
      },
      {
        title: {
          en: "Please briefly tell us about the founding story of the business",
          "es-MX":
            "Por favor, cuéntanos brevemente la historia de fundación del negocio (Cuándo y cómo empezaste)",
        },
        type: "text",
        name: "custom_text_16",
        isRequired: required,
      },
      {
        title: {
          en: "Are you in charge of driving a unit?",
          "es-MX": "¿Te encargas de manejar una unidad?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_17",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Do you have units on account?",
          "es-MX": "¿Tienes unidades a cuenta?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_18",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "resource_provider_panel",
        visibleIf: "{custom_text_18} == 'si'",
        elements: [
          {
            title: { en: "How many", "es-MX": "¿Cuántas?" },
            type: "text",
            inputType: "number",
            name: "custom_integer_29",
          },
          {
            title: {
              en: "On what routes?",
              "es-MX": "¿En que rutas?",
            },
            name: "custom_jsonfield_5",
            valueName: "custom_jsonfield_5",
            type: "matrixdynamic",
            minRowCount: 1,
            rowCount: 1,
            isRequired: required,
            addRowText: {
              en: "Add",
              "es-MX": "Agregar ruta",
            },
            columns: [
              {
                title: {
                  en: "Route",
                  "es-MX": "Ruta",
                },
                name: "ruta",
                isRequired: required,
                cellType: "text",
              },
            ],
          },
        ],
      },
    ],
  },
  // Page 8: Extra unit info
  {
    title: {
      en: "Extra unit info",
      "es-MX": "Información complementaria de la unidad ",
    },
    innerIndent: 1,
    name: "extra_unit_info",
    elements: [
      {
        title: {
          en: "What type of fuel do the units use?",
          "es-MX": "¿Qué tipo de combustible utilizan las unidades?",
        },
        type: "dropdown",
        name: "custom_text_20",
        isRequired: required,
        choices: [
          {
            value: "gas_natural",
            text: { en: "Natural gas", "es-MX": "Gas Natural" },
          },
          { value: "gas_lp", text: { en: "LP Gas", "es-MX": "Gas LP" } },
          { value: "gasolina", text: { en: "Gasoline", "es-MX": "Gasolina" } },
          { value: "diesel", text: { en: "Diesel", "es-MX": "Diesel" } },
        ],
      },
      {
        title: {
          en: "What is the route of your route?",
          "es-MX": "¿Qué recorrido tiene su ruta?",
        },
        type: "text",
        name: "custom_text_21",
        isRequired: required,
      },
      {
        title: {
          en: "How many kilometers does it have for a complete lap of the route?",
          "es-MX": "¿Cuántos kilómetros tiene por vuelta completa de la ruta?",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_11",
        isRequired: required,
      },
      {
        title: {
          en: "How long does it take to make a full turn?",
          "es-MX": "¿En cuánto tiempo da una vuelta completa? (en horas)",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_12",
        isRequired: required,
      },
      {
        title: {
          en: "How many hours do you work a day?",
          "es-MX": "¿Cuántas horas trabajan las unidades al día?",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_13",
        isRequired: required,
      },
      {
        title: {
          en: "Number of good laps",
          "es-MX": "Número de vueltas buenas",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_14",
        isRequired: required,
      },
      {
        title: {
          en: "Number of turns bad",
          "es-MX": "Número de vueltas malas",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_15",
        isRequired: required,
      },
      {
        title: {
          en: "Minimum fare charge",
          "es-MX": "Cobro mínimo del pasaje",
        },
        type: "text",
        inputMask: "currency",
        inputType: "number",
        name: "custom_float_16",
        isRequired: required,
      },
      {
        title: {
          en: "Maximum fare charge",
          "es-MX": "Cobro máximo del pasaje",
        },
        type: "text",
        inputMask: "currency",
        inputType: "number",
        name: "custom_float_17",
        isRequired: required,
      },
      {
        title: {
          en: "What is your work schedule?",
          "es-MX": "¿Cuál es tu horario de trabajo?",
        },
        type: "text",
        name: "custom_text_22",
        isRequired: required,
      },
      {
        title: {
          en: "What fuel capacity does the tank have?",
          "es-MX": "¿Qué capacidad de combustible tiene el tanque?",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_18",
        isRequired: required,
      },
      {
        title: {
          en: "What amount in pesos do you put per day?",
          "es-MX": "¿Qué monto en pesos de combustible pones al día?",
        },
        type: "text",
        inputMask: "currency",
        inputType: "number",
        name: "custom_float_19",
        isRequired: required,
      },
      {
        title: {
          en: "Average daily profit",
          "es-MX": "Promedio de ganancia diaria de la unidad que usted maneja",
        },
        type: "text",
        inputMask: "currency",
        inputType: "number",
        name: "custom_float_20",
        isRequired: required,
      },
      {
        title: {
          en: "How much do you ask for your account per day?",
          "es-MX": "¿Cuánto pide de cuenta al día por cada unidad?",
        },
        type: "text",
        inputMask: "currency",
        inputType: "number",
        name: "custom_float_21",
        isRequired: required,
      },
      {
        title: {
          en: "How many days do you work per month?",
          "es-MX": "¿Cuántos días se trabaja al mes?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_22",
        validators: [
          {
            type: "regexvalidator",
            regex: `^[0-9]+$`,
          },
        ],
        isRequired: required,
      },
    ],
  },
  // Page 9: Income
  {
    title: { en: "Income ", "es-MX": "Ingresos" },
    innerIndent: 1,
    name: "income",
    elements: [
      {
        html: {
          en: "On this part we want to know more about your incomes and liabilities. Please add as many as necessary.",
          "es-MX":
            "En esta parte queremos saber más sobre sus ingresos. Por favor, añade tantos como consideres necesario.",
        },
        type: "html",
      },
      {
        type: "dropdown",
        name: "custom_text_107",
        title: {
          en: "Do you have other income?",
          "es-MX": "¿Tienes otro ingreso?",
        },
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Add your other incomes",
          "es-MX": "Agrega tus otros ingresos",
        },
        name: "other_incomes",
        valueName: "other_incomes",
        type: "matrixdynamic",
        visibleIf: "{custom_text_107} == 'si'",
        minRowCount: 1,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add another income",
          "es-MX": "Añadir otro ingreso",
        },
        columns: [
          {
            title: {
              en: "Amount",
              "es-MX": "Monto al que asciende",
            },
            name: "monto",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Where do you get this income:",
              "es-MX": "¿De dónde obtiene estos ingresos?",
            },
            name: "de_donde_obtiene_estos_ingresos",
            isRequired: required,
            cellType: "text",
            inputType: "text",
          },
          {
            title: {
              en: "Proof of other income",
              "es-MX": "Constancia(s) de otros ingresos",
            },
            name: "constancia_otros_ingresos_1",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: false,
          },
          {
            title: {
              en: "Proof of other income",
              "es-MX": "Constancia(s) de otros ingresos",
            },
            name: "constancia_otros_ingresos_2",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: false,
          },
          {
            title: {
              en: "Proof of other income",
              "es-MX": "Constancia(s) de otros ingresos",
            },
            name: "constancia_otros_ingresos_3",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: false,
          },
        ],
      },
      {
        title: {
          en: "Total other monthly income",
          "es-MX": "Total de otros ingresos mensuales",
        },
        type: "expression",
        name: "edited_total_monthly_income",
        visibleIf: "{custom_text_107} == 'si'",
        expression:
          "formatCurrency(fixed({total_monthly_income}, 2, true))+' MXN'",
      },
      {
        visibleIf: "1 == 0",
        title: {
          en: "Total other monthly income",
          "es-MX": "Total de otros ingresos mensuales",
        },
        type: "expression",
        name: "total_monthly_income",
        expression: `fixed(sumMatrixdropdown({other_incomes}, 'monto'))`, // Floating Point Proof
      },
      {
        title: {
          en: "Approximate savings (in cash or in your bank account)",
          "es-MX": "Ahorro aproximado (en efectivo o en su cuenta de bancaria)",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_22",
        displayStyle: "currency",
      },
    ],
  },
  // Page 10: Assets
  {
    title: { en: "Assets", "es-MX": "Activos" },
    name: "assets",
    elements: [
      {
        html: {
          en: "In this part we want to know more about your assets. Please add as many as necessary",
          "es-MX":
            "En esta parte queremos saber más sobre sus ingresos. Por favor, añade tantos como consideres necesario.",
        },
        type: "html",
      },
      {
        title: {
          en: "Do you have property?",
          "es-MX": "¿Cuenta con bienes inmuebles?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_84",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Properties (real estate, etc)",
          "es-MX": "Propiedades (bienes raíces,etc)",
        },
        visibleIf: "{custom_text_84} == 'si'",
        name: "custom_jsonfield_1",
        valueName: "custom_jsonfield_1",
        type: "matrixdynamic",
        columnLayout: "vertical",
        minRowCount: 0,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Type of asset", "es-MX": "Tipo de activo" },
            name: "tipo_de_activo",
            isRequired: required,
            cellType: "dropdown",
            inputType: "string",
            choices: [
              {
                value: "casa_habitación",
                text: { en: "House", "es-MX": "Casa habitación" },
              },
              {
                value: "terreno_urbano",
                text: {
                  en: "Urban land",
                  "es-MX": "Terreno urbano",
                },
              },
              {
                value: "terreno_rústico",
                text: {
                  en: "Rustic land",
                  "es-MX": "Terreno rústico",
                },
              },
              {
                value: "local_comercial",
                text: {
                  en: "Comercial premises",
                  "es-MX": "Local comercial",
                },
              },
            ],
          },
          {
            title: {
              en: "Municipality/Town",
              "es-MX": "Municipio/Localidad",
            },
            name: "ubicacion",
            isRequired: required,
            cellType: "text",
            inputType: "text",
          },
          {
            title: {
              en: "Square meters",
              "es-MX": "Metros cuadrados",
            },
            name: "metros_cuadrados",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Approximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado_bienes_raices",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
            inputType: "text",
          },
          {
            title: {
              en: "Do you have a deed, property or purchase agreement?",
              "es-MX":
                "¿Tiene una escritura, propiedad o contrato de compraventa?",
            },
            cellType: "dropdown",
            isRequired: required,
            name: "escritura_propiedad_o_contrato_de_compraventa",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: {
              en: "Please upload photo of property document",
              "es-MX":
                "Por favor, suba foto de documento predial o algún documento de respaldo",
            },
            visibleIf:
              "{row.escritura_propiedad_o_contrato_de_compraventa} == 'si'",
            cellType: "file_uploader",
            isRequired: false,
            accept: "image/*,application/pdf",
            name: "foto_de_documento_predial",
          },
        ],
      },
      {
        title: {
          en: "Do you have a personal vehicle?",
          "es-MX": "¿Tienes vehículo personal?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_64",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Property (personal vehicles)",
          "es-MX": "Propiedades (vehículos personales)",
        },
        // columnLayout: "vertical",
        name: "custom_jsonfield_2",
        valueName: "custom_jsonfield_2",
        type: "matrixdynamic",
        visibleIf: "{custom_text_64} == 'si'",
        minRowCount: 0,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Vehicle type", "es-MX": "Tipo de vehículo" },
            name: "tipo_de_vehiculo",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            name: "marca",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Model",
              "es-MX": "Modelo",
            },
            name: "modelo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Aproximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
          },
        ],
      },
    ],
  },
  // Page 11: Unit maintenance expense
  {
    title: {
      en: "Unit maintenance expense",
      "es-MX": "Gastos Mantenimiento de la unidad",
    },
    name: "unit_maintenance",
    elements: [
      {
        type: "panel",
        innerIndent: 0,
        title: {
          en: "Maintenance expenses per unit",
          "es-MX": "Gastos mensuales de mantenimiento por unidad",
        },
        elements: [
          {
            title: {
              en: "Maintenance",
              "es-MX": "Mantenimiento",
            },
            description: {
              en: "Example: Brake Pads / Tires / Tuning / Oil Change / Services",
              "es-MX":
                "Ejemplo: Balatas/ Llantas/ Afinación/ Cambio de aceite/ Servicios",
            },
            type: "panel",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Amount", "es-MX": "Monto de mantenimiento" },
                type: "text",
                inputType: "number",
                name: "custom_float_6",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Adminstrative",
              "es-MX": "Administrativos",
            },
            description: {
              en: "Example: Internal Insurance / Route Administration / Commercial Insurance",
              "es-MX":
                "Ejemplo: Seguros internos/ Administración de Ruta/ Seguros comerciales",
            },
            type: "panel",
            innerIndent: 1,
            elements: [
              {
                title: {
                  en: "Administrative amount",
                  "es-MX": "Monto de administrativos",
                },
                type: "text",
                inputType: "number",
                name: "custom_float_7",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Permits",
              "es-MX": "Gastos de permisos",
            },
            description: {
              en: "Example: Check / Restrain",
              "es-MX": "Ejemplo: Verificación/ Refrendo",
            },
            type: "panel",
            innerIndent: 1,
            elements: [
              {
                title: {
                  en: "Permit amount",
                  "es-MX": "Monto de Gastos de permisos",
                },
                type: "text",
                inputType: "number",
                name: "custom_float_8",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Other expenses",
              "es-MX": "Otros gastos",
            },
            description: {
              en: "Example: Payment to your accountant / Other",
              "es-MX": "Ejemplo: Pago a su contador/ Otro",
            },
            type: "panel",
            innerIndent: 1,
            elements: [
              {
                title: {
                  en: "Other expense amount",
                  "es-MX": "Monto de otros gastos",
                },
                type: "text",
                inputType: "number",
                name: "custom_float_9",
                isRequired: required,
              },
            ],
          },
        ],
      },
      {
        title: {
          en: "Total unit maintenance",
          "es-MX": "Total de gastos mantenimiento de la unidad",
        },
        name: "edited_custom_float_1",
        isRequired: required,
        type: "expression",
        expression: "formatCurrency(fixed({custom_float_1}, 2, true))+' MXN'",
      },
      {
        visibleIf: "1 == 0",
        title: {
          en: "Total unit maintenance",
          "es-MX": "Total de gastos mantenimiento de la unidad",
        },
        name: "custom_float_1",
        isRequired: required,
        type: "expression",
        expression:
          "fixed(sum({custom_float_6}, {custom_float_7}, {custom_float_8}, {custom_float_9}))", // Floating Point Proof
      },
      {
        title: {
          en: "Payment to the route",
          "es-MX": "Pago a la ruta",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_42",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Who makes the payment?",
          "es-MX": "¿Quién realiza el pago?",
        },
        description: {
          en: "Full name",
          "es-MX": "Nombre completo",
        },
        visibleIf: "{custom_text_42} == 'si'",
        type: "text",
        name: "custom_text_43",
        isRequired: required,
      },
      {
        title: {
          en: "Payment to checkers (amount)",
          "es-MX": "Pago a los checadores (monto mensual)",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_23",
        isRequired: required,
      },
      {
        title: {
          en: "Who makes the payment?",
          "es-MX": "¿Quién realiza el pago?",
        },
        type: "text",
        name: "custom_text_45",
        isRequired: required,
      },
      {
        title: {
          en: "How many plates / concessions do you have?",
          "es-MX": "¿Cuántas Placas/concesiones tienes?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_2",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            regex: `^[0-9]+$`,
          },
        ],
      },
      {
        title: {
          en: "How many are your own?",
          "es-MX": "¿Cuántas son propias?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_4",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            regex: `^[0-9]+$`,
          },
        ],
      },
      {
        title: {
          en: "How many are rented",
          "es-MX": "¿Cuántas son rentadas?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_5",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            regex: `^[0-9]+$`,
          },
        ],
      },
      {
        title: {
          en: "Enter amount of rented concessions",
          "es-MX": "Introducir monto de las concesiones rentadas",
        },
        visibleIf: "{custom_integer_5} > 0",
        type: "text",
        inputType: "number",
        name: "custom_float_24",
        isRequired: required,
      },
    ],
  },
  // Page 12: References
  {
    title: {
      en: "References",
      "es-MX": "Referencias personales",
    },
    innerIndent: 1,
    name: "references",
    elements: [
      {
        type: "panel",
        elements: [
          {
            html: {
              en: "Please list two references whom would like to give you a positive endorsement. For example: your previous employer, colleague, teacher, advisor, etc. ",
              "es-MX":
                "Por favor, indica dos referencias. Por ejemplo: Su empleador anterior, colega, maestro, consejero, etc.",
            },
            type: "html",
          },
          {
            html: {
              en: "Reference can be anyone who can provide an insight into your study and work ethics. Reference should not be one of your guarantors.",
              "es-MX":
                "La referencia puede ser cualquier persona que pueda proporcionar una visión de su estudio y ética laboral. La referencia no debe ser uno de sus garantes.",
            },
            type: "html",
          },
        ],
      },
      {
        title: {
          en: "Reference #1 details",
          "es-MX": "Referencia personales #1",
        },
        name: "panel_ref1",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "ref1_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Reference Full Name *",
              "es-MX": "Nombre completo de la referencia *",
            },
          },
          {
            name: "ref1_mobile_phone",
            type: "phone",
            visibleIf: "{ref1_name} notempty",
            isRequired: required,
            maxLength: "10",
            title: {
              en: "Reference Mobile No. *",
              "es-MX": "Número de teléfono de la referencia *",
            },
            validators: [
              expressions.phone_en("ref1_mobile_phone"),
              expressions.phone_length("ref1_mobile_phone"),
            ],
          },
          {
            title: {
              en: "What is the relation of reference #1 to you? *",
              "es-MX": "¿Cuál es la relación con la referencia #1? *",
            },
            description: {
              en: "(Ex. Boss, Friend, Family, etc.)",
              "es-MX": "(Ej. Amigo, familiar, proveedor, etc.)",
            },
            name: "ref1_relation",
            visibleIf: "{ref1_name} notempty",
            isRequired: required,
            type: "dropdown",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_relation_types/`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Address", "es-MX": "Dirección" },
            name: "ref1_street",
            type: "text",
            isRequired: required,
            visibleIf: "{ref1_name} notempty",
          },
        ],
      },
      {
        title: {
          en: "Reference #2 details",
          "es-MX": "Referencia personales #2",
        },
        name: "panel_ref2",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "ref2_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Reference Full Name *",
              "es-MX": "Nombre completo de la referencia *",
            },
          },
          {
            name: "ref2_mobile_phone",
            type: "phone",
            visibleIf: "{ref2_name} notempty",
            isRequired: required,
            maxLength: "10",
            title: {
              en: "Reference Mobile No. *",
              "es-MX": "Número de teléfono de la referencia *",
            },
            validators: [
              expressions.phone_en("ref2_mobile_phone"),
              expressions.phone_length("ref2_mobile_phone"),
            ],
          },
          {
            title: {
              en: "What is the relation of reference #2 to you? *",
              "es-MX": "¿Cuál es la relación con la referencia #2? *",
            },
            description: {
              en: "(Ex. Boss, Friend, Family, etc.)",
              "es-MX": "(Ej. Amigo, familiar, proveedor, etc.)",
            },
            name: "ref2_relation",
            visibleIf: "{ref2_name} notempty",
            isRequired: required,
            type: "dropdown",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_relation_types/`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Address", "es-MX": "Dirección" },
            name: "ref2_street",
            type: "text",
            isRequired: required,
            visibleIf: "{ref2_name} notempty",
          },
        ],
      },
    ],
  },
  // Page 13: Joint obligor
  {
    title: {
      en: "Joint obligor",
      "es-MX": "Obligado solidario",
    },
    innerIndent: 1,
    name: "obligor",
    elements: [
      {
        type: "panel",
        elements: [
          {
            html: {
              en: "In this part, the data of the joint obligor will be requested, in general it is the person closest to you (mother, father, uncle, wife, etc.).",
              "es-MX":
                "En esta parte se pedirán los datos del obligado solidario, en general es la persona más cercana a ti (madre, padre, tío, esposa,etc).",
            },
            type: "html",
          },
          {
            html: {
              en: "A message will be sent by WhatsApp where a small information will be asked to the person who entered. Please let him know about this so he can fill in the details.",
              "es-MX":
                "Se enviará un mensaje por WhatsApp en donde se le pedirán unos pequeños datos a la persona que puso. Por favor hazle saber de esto para que pueda llenar los datos.",
            },
            type: "html",
          },
        ],
      },
      {
        name: "guarantor_first_name",
        type: "text",
        isRequired: required,
        title: {
          en: "obligor's name *",
          "es-MX": "Nombre del obligado *",
        },
      },
      {
        name: "guarantor_last_name",
        type: "text",
        isRequired: false,
        title: {
          en: "Last name",
          "es-MX": "Apellido paterno",
        },
      },
      {
        name: "guarantor_mother_name",
        type: "text",
        isRequired: false,
        title: {
          en: "Second surname",
          "es-MX": "Apellido materno",
        },
      },
      {
        title: {
          en: "Kinship (mother, father, wife, friend, etc)",
          "es-MX": "Parentesco (madre, padre, esposa, amigo, etc)",
        },
        name: "guarantor_relation",
        isRequired: required,
        type: "text",
        inputType: "string",
      },
      {
        name: "guarantor_phone",
        type: "phone",
        isRequired: required,
        title: {
          en: "Obligor phone number *",
          "es-MX": "Número de teléfono del(a) obligado solidario *",
        },
        maxLength: "10",
        validators: [
          expressions.phone_en("guarantor_phone"),
          expressions.phone_length("guarantor_phone"),
          expressions.same_phone_number("guarantor_phone")
        ],
      },
    ],
  },
  // Page 14: Bank account info
  {
    title: {
      en: "Bank account info",
      "es-MX": "Información de cuenta bancaria",
    },
    innerIndent: 1,
    name: "bank_account_info",
    elements: [
      {
        title: {
          en: "Do you have a bank account? *",
          "es-MX": "¿Tienes una cuenta bancaria?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_70",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Bank Name *",
          "es-MX": "Nombre del Banco *",
        },
        visibleIf: "{custom_text_70} == 'si'",
        type: "text",
        name: "custom_text_105",
        isRequired: required,
      },
      {
        title: {
          en: "Bank Account No. *",
          "es-MX": "Número de Cuenta Bancaria. *",
        },
        visibleIf: "{custom_text_70} == 'si'",
        type: "text",
        inputType: "number",
        name: "custom_text_82",
        isRequired: required,
      },
      {
        title: {
          en: "Code *",
          "es-MX": "Clabe *",
        },
        visibleIf: "{custom_text_70} == 'si'",
        type: "text",
        name: "custom_text_106",
        isRequired: required,
        maxLength: 18,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Enter 18 digits",
              "es-MX": "Ingrese 18 dígitos",
            },
            regex: `^[0-9]{18}$`,
          },
        ],
      },
    ],
  },
];

export const intake_procredito_pages_agro = (clientUrlForCatalog) => [
  // Page 1: Applicant Information
  {
    title: { en: "Applicant information", "es-MX": "Datos generales" },
    innerIndent: 1,
    name: "applicant_info",
    elements: [
      {
        title: { en: "First name*", "es-MX": "Primer nombre*" },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: { en: "Middle name", "es-MX": "Segundo nombre" },
        type: "text",
        name: "middle_name",
      },
      {
        title: { en: "Surname *", "es-MX": "Primer apellido*" },
        type: "text",
        name: "surname",
        isRequired: required,
      },
      {
        title: { en: "Second surname", "es-MX": "Segundo apellido" },
        type: "text",
        name: "second_surname",
      },
      {
        title: { en: "Gender", "es-MX": "Género" },
        type: "dropdown",
        isRequired: required,
        name: "gender",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_genders/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Date of Birth *",
          "es-MX": "Fecha de nacimiento *",
        },
        name: "date_of_birth",
        type: "text",
        inputType: "date",
        isRequired: required,
      },
      {
        title: {
          en: "Occupation/Profession",
          "es-MX": "Ocupación/Profesión",
        },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_8",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_professions/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "To confirm identification, please upload a profile photo",
          "es-MX":
            "Para confirmar la identificación, por favor sube una foto de perfil",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        type: "panel",
        name: "INE_panel",
        title: {
          en: "Which type of INE do you have? Select one *",
          "es-MX": "¿Qué tipo de INE tienes? Elige un opción *",
        },
        elements: [
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>1.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_1.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>2.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_2.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>3.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_3.png")}' width='95%'/>`,
          },
          {
            title: { en: "INE Type", "es-MX": "Tipo de INE" },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_71",
            choices: [
              { value: "1", text: { en: "1.", "es-MX": "1." } },
              { value: "2", text: { en: "2.", "es-MX": "2." } },
              { value: "3", text: { en: "3.", "es-MX": "3." } },
            ],
          },
          {
            type: "panel",
            name: "INE_1_panel",
            visibleIf: "{custom_text_71} == '1'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 digitos)",
                  "es-MX": "CIC (9 digitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 digitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Identificador del ciudadano (9 digitos)",
                  "es-MX": "Identificador del ciudadano (9 digitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 digitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_2_panel",
            visibleIf: "{custom_text_71} == '2'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 digitos)",
                  "es-MX": "CIC (9 digitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 digitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "OCR (13 digitos)",
                  "es-MX": "OCR (13 digitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 digitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_3_panel",
            visibleIf: "{custom_text_71} == '3'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "Clave de elector (18 digitos)",
                  "es-MX": "Clave de elector (18 digitos)",
                },
                maxLength: "18",
                placeHolder: "GAVLLR68082309A123",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "18 digits. Incorrect format",
                      "es-MX": "18 digitos. Formato incorrecto",
                    },
                    regex: `^[a-zA-Z]{6}[0-9]{8}[a-zA-Z]{1}[0-9]{3}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número OCR (13 digitos)",
                  "es-MX": "Número OCR (13 digitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 digitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
              {
                name: "custom_text_73",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número de Emisión (2 digitos)",
                  "es-MX": "Número de Emisión (2 digitos)",
                },
                maxLength: "2",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "2 digits",
                      "es-MX": "2 digitos",
                    },
                    regex: `^[0-9]{2}$`,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: { en: "front image", "es-MX": "Imagen parte de adelante" },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file",
        isRequired: required,
      },
      {
        title: { en: "back image", "es-MX": "Imagen parte de atrás" },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file_back",
        isRequired: required,
      },
      {
        title: {
          en: "To verify your identification, please upload a picture of yoursel holding you ID",
          "es-MX":
            "Para verificar tu identidad por favor, sube una foto sujetando dicha  identificación",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file_with_avatar",
        isRequired: required,
      },
      {
        name: "phone_number",
        type: "phone",
        isRequired: required,
        title: {
          en: "Mobile phone number *",
          "es-MX": "No. celular*",
        },
        maxLength: "10",
        validators: [
          expressions.phone_en("phone_number"),
          expressions.phone_length("phone_number"),
        ],
      },
      {
        name: "advisor_phone_number",
        type: "phone",
        title: {
          en: "home telephone number",
          "es-MX": "Teléfono casa",
        },
        maxLength: "10",
        validators: [
          expressions.phone_en("advisor_phone_number"),
          expressions.phone_length("advisor_phone_number"),
        ],
      },
      {
        title: { en: "Email*", "es-MX": "Correo electrónico*" },
        type: "text",
        name: "email",
        isRequired: required,
        validators: [{ type: "email" }],
      },
      {
        title: {
          en: "Type of person",
          "es-MX": "Tipo de persona",
        },
        type: "dropdown",
        name: "custom_text_3",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_client_types/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Do you have registration with the SAT?",
          "es-MX": "¿Cuentas con registro ante el SAT?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_9",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Indicanos tu Número de Identificación Fiscal (RFC)",
          "es-MX": "Indícanos tu Número de Identificación Fiscal (RFC)",
        },
        type: "text",
        readOnly: false,
        visibleIf: "{custom_text_9} == 'si'",
        name: "custom_text_5",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 10 characters or 13 with homoclave",
              "es-MX": "RFC 10 dígitos o 13 dígitos incluyendo homoclave",
            }, // Requires translation
            regex: `^([A-Za-z]{4}[ |-]{0,1}[0-9]{6})([0-9A-Za-z]{3}|$)$`, // should be the correct regex that includes 3 more chars at the end
          },
        ],
        placeHolder: "VECJ880326",
      },
      {
        title: {
          en: "Type of request",
          "es-MX": "Tipo de solicitud ",
        },
        type: "dropdown",
        name: "custom_text_10",
        choices: [
          { value: "nueva", text: { en: "New", "es-MX": "Nueva" } },
          {
            value: "recurrente",
            text: { en: "Recurring", "es-MX": "Recurrente" },
          },
          {
            value: "refinanciamiento_o_reestructura",
            text: {
              en: "Refinancing or restructuring",
              "es-MX": "Refinanciamiento o Reestructura",
            },
          },
        ],
      },
      {
        title: {
          en: "Credit destination",
          "es-MX": "Destino del crédito",
        },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_15",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_loan_destinations/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Specify credit destination",
          "es-MX": "Especificar destino del crédito",
        },
        type: "text",
        name: "custom_text_23",
      },
      {
        title: {
          en: "Activity or line of business",
          "es-MX": "Actividad o giro del negocio ",
        },
        type: "dropdown",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_economic_activity_types/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
        name: "custom_text_24",
      },
      {
        title: {
          en: "Years of business operation",
          "es-MX": "Años de operación del negocio",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_33",
      },
    ],
  },
  // Page 2: Fixed Costs
  {
    title: { en: "Fixed costs", "es-MX": "Gastos familiares" },
    innerIndent: 1,
    name: "fixed_costs",
    elements: [
      {
        title: {
          en: "Are you paying rent or mortgage?",
          "es-MX": "¿Estás pagando renta o hipoteca?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_25",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Tell us how much you pay per month",
          "es-MX": "Indícanos cuánto pagas al mes",
        },
        type: "text",
        visibleIf: "{custom_text_25} == 'si'",
        inputType: "number",
        name: "custom_float_10",
        isRequired: required,
      },
      {
        name: "custom_float_2",
        type: "text",
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_2")],
        title: {
          en: "Fixed costs (monthly)",
          "es-MX": "Gastos familiares (mensual)",
        },
      },
      {
        title: {
          en: "Total monthly expenses",
          "es-MX": "Total de gastos familiares",
        },
        type: "expression",
        name: "edited_mcf_total_expense_amount",
        expression:
          "formatCurrency(fixed({mcf_total_expense_amount}, 2, true))+' MXN'",
      },
      {
        visibleIf: "1 == 0",
        title: {
          en: "Total monthly expenses",
          "es-MX": "Total de gastos familiares",
        },
        type: "expression",
        name: "mcf_total_expense_amount",
        expression: "fixed(add({custom_float_10}, {custom_float_2}))", // Floating Point Proof
      },
    ],
  },
  // Page 3: Family Information
  {
    title: {
      en: "Family information",
      "es-MX": "Información familiar",
    },
    innerIndent: 1,
    name: "family_info",
    elements: [
      {
        title: {
          en: "What is your marital status? *",
          "es-MX": "¿Cuál es tu estado civil?",
        },
        type: "dropdown",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_marital_status/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
        isRequired: required,
        name: "marital",
      },
      {
        type: "panel",
        visibleIf: "{marital} == '2'",
        innerIndent: 1,
        name: "if_married",
        elements: [
          {
            title: {
              en: "Please upload a photo of the marriage certificate",
              "es-MX": "Por favor, sube una foto del acta de matrimonio",
            },
            description: {
              en: "If you do not have this document at hand, you will have to bring it with you on the day of signing",
              "es-MX":
                "De no tener este documento a mano, lo tendrás que llevar el día de la firma",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_2",
          },
          {
            title: {
              en: "Indicate the regime for which you are married",
              "es-MX": "Indica el régimen por el que estás casado",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_11",
            choices: [
              {
                value: "bienes_mancomunados",
                text: { en: "Joint", "es-MX": "Bienes mancomunados" },
              },
              {
                value: "separación_de_bienes",
                text: {
                  en: "Separate assets",
                  "es-MX": "Separación de bienes",
                },
              },
            ],
          },
          {
            title: {
              en: "Spouse fullname",
              "es-MX": "Nombre completo del cónyuge",
            },
            type: "text",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_12",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse Mobile No.",
              "es-MX": "Número de teléfono del cónyuge",
            },
            type: "phone",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_1",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse Job",
              "es-MX": "Situación laboral del cónyuge",
            },
            type: "text",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_13",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse Work Address",
              "es-MX": "Dirección laboral del cónyuge",
            },
            type: "text",
            visibleIf: "{custom_text_11} == 'bienes_mancomunados'",
            name: "custom_text_77",
            isRequired: required,
          },
        ],
      },
      {
        title: {
          en: "How many people depend on you financially? *",
          "es-MX": "¿Cuántas personas dependen de ti financieramente? *",
        },
        description: {
          en: "Ex. aunts, parents, cousins, friends",
          "es-MX": "Ej. tías, padres, primos, amigos",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_6",
        isRequired: required,
      },
    ],
  },
  // Page 4: Residence and home information
  {
    title: {
      en: "Residence and home information",
      "es-MX": "Información de residencia",
    },
    innerIndent: 1,
    name: "residence_home_info",
    elements: [
      {
        title: { en: "Country of birth *", "es-MX": "País de nacimiento *" },
        type: "dropdown",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
        name: "custom_text_26",
        isRequired: required,
      },
      {
        title: { en: "State of birth *", "es-MX": "Estado de nacimiento *" },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_85",
        isRequired: required,
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={custom_text_26}`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
      },
      {
        title: {
          en: " Municipality / Town of birth *",
          "es-MX": "Municipio de nacimiento *",
        },
        type: "dropdown",
        renderAs: "select2",
        name: "custom_text_27",
        isRequired: required,
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_85}`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
      },
      {
        title: { en: "City of birth *", "es-MX": "Ciudad/Localidad de nacimiento *" },
        type: "dropdown",
        renderAs: "select2",
        name: "id_question_birthplace",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_27}`,
          path: "results",
          titleName: "description",
          valueName: "id",
        },
      },
      {
        title: { en: "Where do you live?", "es-MX": "¿En dónde vives?" },
        type: "panel",
        name: "address_details",
        elements: [
          {
            title: {
              en: "Type of address",
              "es-MX": "Tipo de domicilio",
            },
            type: "dropdown",
            name: "custom_text_30",
            defaultValue: "2",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_address_types/?id=2`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Country *", "es-MX": "País *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_country",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "State", "es-MX": "Estado *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_14",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={home_country}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Municipality", "es-MX": "Municipio" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_28",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_14}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: {
              en: "City/Town", "es-MX": "Ciudad/Localidad" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_city",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_28}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Postal Code *", "es-MX": "Código postal *" },
            type: "dropdown",
            renderAs: "select2",
            name: "po_box_postal_code",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_28}`,
              path: "results",
              valueName: "zipcode",
            },
          },
          {
            title: { en: "Suburb", "es-MX": "Colonia" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_29",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={po_box_postal_code}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: {
              en: "Years of residence",
              "es-MX": "Años de residencia ",
            },
            type: "text",
            inputType: "number",
            name: "custom_integer_27",
            isRequired: required,
          },
          {
            name: "advisor_phone_number",
            type: "phone",
            validators: [
              expressions.phone_en("custom_text_31"),
              expressions.phone_length("custom_text_31"),
            ],
            maxLength: "10",
            title: {
              en: "Home phone number",
              "es-MX": "Número de teléfono del domicilio",
            },
          },
          {
            title: {
              en: "Attach proof of address",
              "es-MX": "Adjuntar imagen comprobante de domicilio",
            },
            description: {
              en: "No more than 3 months, electricity, water, telephone, etc.",
              "es-MX": "No mayor a 3 meses, luz, agua, teléfono, etc",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_3",
            isRequired: required,
          },
        ],
      },
    ],
  },
  // Page 5: Tax residence
  {
    title: {
      en: "Tax residence",
      "es-MX": "Domicilio fiscal",
    },
    innerIndent: 1,
    name: "tax_residence",
    elements: [
      {
        title: {
          en: "Tax certificate 1",
          "es-MX": "Constancia fiscal 1",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_4",
        visibleIf: "{custom_text_9} == 'si'",
      },
      {
        title: {
          en: "Tax certificate 2",
          "es-MX": "Constancia fiscal 2",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_5",
        visibleIf: "{custom_text_9} == 'si'",
      },
      {
        title: {
          en: "Tax certificate 3",
          "es-MX": "Constancia fiscal 3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_6",
        visibleIf: "{custom_text_9} == 'si'",
      },
      {
        title: {
          en: "Requires billing",
          "es-MX": "Requiere facturación ",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_32",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "tax_residence_details",
        title: {
          en: "Tax residence details",
          "es-MX": "Detalles de la residencia fiscal",
        },
        visibleIf: "{custom_text_32} == 'si'",
        elements: [
          {
            title: { en: "Street", "es-MX": "Calle" },
            type: "text",
            name: "custom_text_33",
          },
          {
            title: { en: "Outdoor number", "es-MX": "Número exterior" },
            type: "text",
            name: "custom_text_34",
          },
          {
            title: { en: "Interior number", "es-MX": "Número interior" },
            type: "text",
            name: "custom_text_35",
          },
          {
            title: { en: "Country *", "es-MX": "País *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_36",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "State", "es-MX": "Estado *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_38",
            isRequired:required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={custom_text_36}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Municipality", "es-MX": "Municipio" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_39",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_38}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "City/Delegation", "es-MX": "Ciudad/Localidad" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_40",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_39}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Postal Code", "es-MX": "Código postal *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_37",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_39}`,
              path: "results",
              valueName: "zipcode",
            },
          },
          {
            title: { en: "Suburb", "es-MX": "Colonia" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_41",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={custom_text_37}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: {
              en: "Type of address",
              "es-MX": "Tipo de domicilio",
            },
            type: "dropdown",
            name: "custom_text_49",
            defaultValue: "7",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_address_types/?id=7`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: {
              en: "Years of residence",
              "es-MX": "Años de residencia ",
            },
            type: "text",
            inputType: "number",
            name: "custom_integer_28",
            isRequired: required,
          },
          {
            name: "custom_text_51",
            type: "phone",
            maxLength: "10",
            validators: [
              expressions.phone_en("custom_text_51"),
              expressions.phone_length("custom_text_51"),
            ],
            title: {
              en: "Telephone number of this address",
              "es-MX": "Número telefónico del domicilio",
            },
          },
        ],
      },
    ],
  },
  // Page 6: Client profile
  {
    title: {
      en: "Client profile",
      "es-MX": "Perfil del cliente",
    },
    innerIndent: 1,
    name: "client profile",
    elements: [
      {
        title: {
          en: "Have you held any public office in the last 12 months (national / foreign)? *",
          "es-MX":
            "¿Ha desempeñado algún cargo público en los últimos 12 meses (nacional/extranjero)? *",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_52",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "public_office_details",
        visibleIf: "{custom_text_52} == 'si'",
        elements: [
          {
            title: { en: "Job title", "es-MX": "Nombre del cargo" },
            type: "text",
            name: "custom_text_53",
          },
          {
            title: { en: "Dependence", "es-MX": "Dependencia" },
            type: "text",
            name: "custom_text_54",
          },
        ],
      },
      {
        title: {
          en: "Are you related to any person (spouse, relative or by affinity up to the second degree) who has held any public office in the last 12 months (National / Foreign)? *",
          "es-MX":
            "¿Está usted relacionado con alguna persona (Cónyuge, familiar consaguíneo o por afinidad hasta el segundo grado) que ha desempeñado algún cargo público en los últimos 12 meses (Nacional/Extranjero)? *",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_55",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_public_office_relation/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        type: "panel",
        name: "related_public_office_details",
        visibleIf: "{custom_text_55} == '334' || {custom_text_55} == '335' || {custom_text_55} == '336'",
        elements: [
          {
            title: { en: "Job title", "es-MX": "Nombre del cargo" },
            type: "text",
            name: "custom_text_56",
          },
          {
            title: { en: "Dependence", "es-MX": "Dependencia" },
            type: "text",
            name: "custom_text_57",
          },
        ],
      },
      {
        title: {
          en: "Is the resource provider for this contract the same requester?",
          "es-MX":
            "¿El proveedor de recursos para este contrato es el mismo solicitante? ",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_58",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "resource_provider_panel",
        visibleIf: "{custom_text_58} == 'no'",
        elements: [
          {
            title: { en: "Name and surname", "es-MX": "Nombre y apellidos" },
            type: "text",
            name: "custom_text_59",
          },
          {
            title: {
              en: "Mention the source from which you will obtain the resources (Own Business Salary, Inheritance, Loans, Savings, etc.)",
              "es-MX":
                "Mencionar la fuente de la cual obtendrá los recursos (Negocio propio, Salario, Herencia, Préstamos, Ahorro, etc)",
            },
            type: "text",
            name: "custom_text_60",
          },
        ],
      },
      {
        title: {
          en: "Mention the means of payment",
          "es-MX": "Mencionar el medio de pago ",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_61",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_payment/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "Indicate the area in which the business is conducted",
          "es-MX": "Indique la zona en la que se realiza el negocio ",
        },
        type: "dropdown",
        renderAs: "select2",
        isRequired: required,
        name: "custom_text_62",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_zona_negocio/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        title: {
          en: "According to the previous question, indicate cities, state and localities",
          "es-MX":
            "Conforme a la pregunta anterior, indique ciudades, estado y localidades",
        },
        type: "text",
        name: "custom_text_63",
      },
    ],
  },
  // Page 7: Sowing-cultivation information
  {
    name: "Sowing-cultivation information 3x",
    title: {
      en: "Sowing-cultivation information",
      "es-MX": "Información de la siembra-cultivo",
    },
    elements: [
      {
        type: "text",
        name: "custom_text_86",
        title: {
          en: "Product to be financed *",
          "es-MX": "Producto a financiar *",
        },
        isRequired: required,
        maxLength: 20,
      },
      {
        type: "text",
        name: "custom_float_11",
        title: {
          en: "How many hectares are currently planted? *",
          "es-MX": "¿Cuántas hectáreas sembradas tiene actualmente? *",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Answer must contain up to one decimal place e.g. 40.5",
              "es-MX": "La respuesta debe tener máximo un decimal p.ej. 40.5",
            },
            regex: `^[0-9]+(\\.[0-9])?$`,
          },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_87",
        title: {
          en: "Do you have any additional crop(s)? *",
          "es-MX": "¿Tiene alguna(s) siembra(s) adicional(es)? *",
        },
        isRequired: required,
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        type: "matrixdynamic",
        name: "custom_jsonfield_5",
        valueName: "custom_jsonfield_5",
        title: {
          en: "Other Crops",
          "es-MX": "Otras Siembras",
        },
        minRowCount: 1,
        rowCount: 1,
        isRequired: required,
        visibleIf: "{custom_text_87} = 'si'",
        addRowText: {
          en: "Add another crop",
          "es-MX": "Añadir otra siembra",
        },
        columns: [
          {
            cellType: "text",
            name: "siembra",
            title: {
              en: "What are they? *",
              "es-MX": "¿Cuáles son? *",
            },
            maxLength: 100,
          },
          {
            cellType: "text",
            name: "monto",
            title: {
              en: "Indicate total amount in $ *",
              "es-MX": "Indica el monto total en $ *",
            },
            inputType: "number",
          },
        ],
      },
      {
        type: "expression",
        expression: "formatCurrency(fixed({custom_float_3}, 2, true))+' MXN'",
        name: "edited_custom_float_3",
        visibleIf: "fixed(sumInArray({custom_jsonfield_5}, 'monto')) > 0",
        title: {
          en: "Total of other crops",
          "es-MX": "Total de otras siembras",
        },
      },
      {
        visibleIf: "1 == 0",
        type: "expression",
        expression: "fixed(sumInArray({custom_jsonfield_5}, 'monto'))",
        name: "custom_float_3",
        title: {
          en: "Total of other crops",
          "es-MX": "Total de otras siembras",
        },
      },
      {
        type: "text",
        name: "custom_float_13",
        title: {
          en: "Number of Hectares Owned *",
          "es-MX": "Número de hectáreas propias *",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Answer must contain up to one decimal place e.g. 40.5",
              "es-MX": "La respuesta debe tener máximo un decimal p.ej. 40.5",
            },
            regex: `^[0-9]+(\\.[0-9])?$`,
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_14",
        title: {
          en: "Number of hectares rented *",
          "es-MX": "Número de hectáreas rentadas *",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Answer must contain up to one decimal place e.g. 40.5",
              "es-MX": "La respuesta debe tener máximo un decimal p.ej. 40.5",
            },
            regex: `^[0-9]+(\\.[0-9])?$`,
          },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_89",
        title: {
          en: "Do you have informal business/payments? *",
          "es-MX": "¿Cuenta con pasivos/pagos informales empresariales? *",
        },
        isRequired: required,
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_15",
        visibleIf: "{custom_text_89} = 'si'",
        title: {
          en: "Indicate total amount in $ *",
          "es-MX": "Indica el monto total en $ *",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "dropdown",
        name: "custom_text_90",
        title: {
          en: "Indicate the type of crop *",
          "es-MX": "Indica el tipo de cultivo *",
        },
        isRequired: required,
        choices: [
          {
            value: "cielo_abierto",
            text: { en: "Open sky", "es-MX": "Cielo abierto" },
          },
          {
            value: "protegido",
            text: { en: "Protected", "es-MX": "Protegido" },
          },
        ],
      },
      {
        type: "text",
        name: "custom_text_91",
        title: {
          en: "What type of irrigation do you use? *",
          "es-MX": "¿Qué tipo de riego utiliza? *",
        },
        isRequired: required,
        maxLength: 50,
      },
      {
        type: "text",
        name: "custom_float_21",
        title: {
          en: "What is your supply inventory?",
          "es-MX": "¿Cuál es tu inventario de insumos?",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_float_1",
        title: {
          en: "What is your utility per hectare? *",
          "es-MX": "¿Cuál es tu utilidad por hectárea? *",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "panel",
        name: "expenditure_panel",
        title: {
          en: "Expenditure?",
          "es-MX": "Gastos",
        },
        elements: [
          {
            type: "text",
            name: "custom_float_6",
            title: {
              en: "What is your input expenditure? *",
              "es-MX": "¿Cuál es el tu gasto en insumos? *",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_7",
            title: {
              en: "What is your labour cost? *",
              "es-MX": "¿Cuál es tu gasto en mano de obra? *",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "expression",
            name: "edited_custom_float_23",
            title: {
              en: "Total average expenditure",
              "es-MX": "Gasto promedio total",
            },
            expression:
              "formatCurrency(fixed({custom_float_23}, 2, true))+' MXN'",
          },
          {
            visibleIf: "1 == 0",
            type: "expression",
            name: "custom_float_23",
            title: {
              en: "Total average expenditure",
              "es-MX": "Gasto promedio total",
            },
            expression: "fixed(sum({custom_float_6},{custom_float_7}))",
          },
        ],
      },
    ],
  },
  // Page 8: Sowing-cultivation information page 2
  {
    name: "Sowing-cultivation information page 2",
    title: {
      en: "Sowing-cultivation information page 2",
      "es-MX": "Información de la siembra-cultivo",
    },
    elements: [
      {
        type: "panel",
        name: "employee_panel",
        title: {
          en: "How many employees do you have?",
          "es-MX": "¿Cuántos empleados tiene?",
        },
        elements: [
          {
            type: "text",
            name: "custom_integer_1",
            title: {
              en: "Permanent employees",
              "es-MX": "Empleados fijos",
            },
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_integer_8",
            title: {
              en: "Temporary employees",
              "es-MX": "Empleados temporales",
            },
            inputType: "number",
          },
          {
            type: "expression",
            expression: "fixed(sum({custom_integer_1}, {custom_integer_8}))",
            name: "custom_integer_7",
            title: {
              en: "Employee Total",
              "es-MX": "Total de Empleados",
            },
          },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_92",
        title: {
          en: "Do you have crop insurance? *",
          "es-MX": "¿Maneja seguro de siembra? *",
        },
        isRequired: required,
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_25",
        visibleIf: "{custom_text_92} = 'si'",
        title: {
          en: "What is the insured amount? *",
          "es-MX": "¿Cuál es su monto asegurado? *",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_float_33",
        title: {
          en: "What is the amount of the operating costs? (Water, electricity, fuel, maintenance, etc.",
          "es-MX":
            "¿Cuál es el monto de los gastos operativos? (Agua, electricidad, combusible, mantenimiento, etc.)",
        },
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_text_93",
        title: {
          en: "What are the cycles you manage for sowing and harvesting? *",
          "es-MX":
            "Coméntanos cuáles son los ciclos que maneja para la siembras y cosechas *",
        },
        isRequired: required,
      },
      {
        type: "dropdown",
        name: "custom_text_94",
        title: {
          en: "Do you currently owe any payments to your suppliers, in what amount? *",
          "es-MX":
            "¿Actualmente tiene algún pago que deba a sus proveedores? *",
        },
        isRequired: required,
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_34",
        visibleIf: "{custom_text_94} = 'si'",
        title: {
          en: "For what amount? *",
          "es-MX": "¿Por qué monto? *",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "dropdown",
        name: "custom_text_95",
        title: {
          en: "Do you have any payments pending for the sale of the last harvest? *",
          "es-MX":
            "¿Tiene pendiente algún pago por recibir por la venta de la última cosecha? *",
        },
        isRequired: required,
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_35",
        visibleIf: "{custom_text_95} = 'si'",
        title: {
          en: "How much? *",
          "es-MX": "¿Por qué monto? *",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "comment",
        name: "custom_textbox_1",
        title: {
          en: "Tell how your business started (when and how you started, recent changes or implements)",
          "es-MX":
            "Cuéntanos cómo empezó tu negocio (Cuándo y cómo empezaste, cambios o implementaciones recientes, etc.)",
        },
        maxLength: 500,
      },
      {
        type: "dropdown",
        name: "custom_text_97",
        title: {
          en: "Are you currently working with any of these companies? *",
          "es-MX": "¿Actualmente trabaja con alguna de estas empresas? *",
        },
        isRequired: required,
        hasOther: true,
        otherText: { en: "Other", "es-MX": "Otra" },
        storeOthersAsComment: false,
        choices: [
          {
            value: "verqor",
            text: {
              "es-MX": "Verqor",
            },
          },
          {
            value: "giddings",
            text: {
              "es-MX": "Giddings",
            },
          },
          {
            value: "natur_agui_food",
            text: {
              "es-MX": "Natur Agui Food",
            },
          },
          {
            value: "natur_berry",
            text: {
              "es-MX": "Natur Berry",
            },
          },
          {
            value: "alpasa_farms",
            text: {
              "es-MX": "Alpasa Farms",
            },
          },
          {
            value: "alsa",
            text: {
              "es-MX": "Alsa",
            },
          },
          {
            value: "impactum",
            text: {
              "es-MX": "Impactum",
            },
          },
        ],
      },
    ],
  },
  // Page 9a: Giddings/Alsa F&P
  {
    name: "Giddings / Alsa F&P",
    showPageTitles: false,
    visibleIf:
      "{custom_text_97} == 'giddings' || {custom_text_97} == 'alpasa_farms' || {custom_text_97} == 'alsa' || {custom_text_97} == 'impactum'",
    elements: [
      {
        type: "panel",
        name: "previous_cycle_panel",
        showTitle: false,
        elements: [
          {
            type: "text",
            name: "custom_float_36",
            title: {
              en: "What was the invoiced revenue in the previous cycle? *",
              "es-MX": "¿Cuál fue el ingreso facturado en el ciclo anterior? *",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_7",
            title: {
              en: "Settlements from this last cycle (year) *",
              "es-MX": "Liquidaciones de este último ciclo (año) *",
            },
            isRequired: required,
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_37",
        title: {
          en: "What is the contract agreed for this cycle? *",
          "es-MX": "¿Cuál es el ingreso acordado para este ciclo? *",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "expression",
        name: "edited_custom_float_38",
        title: {
          en: "The maximum credit that can be considered is for",
          "es-MX": "El crédito máximo que se puede considerar es por",
        },
        expression: "formatCurrency(fixed({custom_float_38}, 2, true))+' MXN'",
      },
      {
        visibleIf: "1 == 0",
        type: "expression",
        name: "custom_float_38",
        title: {
          en: "The maximum credit that can be considered is for",
          "es-MX": "El crédito máximo que se puede considerar es por",
        },
        expression:
          "templateConditional(multiply({custom_float_36}, 0.5), multiply({custom_float_37}, 0.5), {custom_float_36}, {custom_float_37}, 'greater_than')",
      },
      {
        type: "text",
        name: "custom_text_99",
        title: {
          en: "Additional information or commentary on your business-revenue *",
          "es-MX":
            "Información o comentario adicional sobre su negocio-ingresos *",
        },
        isRequired: required,
        maxLength: 200,
      },
    ],
  },
  // Page 9b: Verqor
  {
    name: "Verqor",
    showPageTitles: false,
    visibleIf:
      "{custom_text_97} == 'verqor' || {custom_text_97} == 'natur_agui_food' || {custom_text_97} == 'natur_berry'",
    elements: [
      {
        type: "text",
        name: "custom_text_100",
        title: {
          en: "Where is the business/planting located? *",
          "es-MX": "¿Dónde se encuentra ubicado el negocio/siembra? *",
        },
        isRequired: required,
        maxLength: 50,
      },
      {
        type: "panel",
        name: "income_panel",
        title: {
          en: "What was the income in the previous cycle?",
          "es-MX": "¿Cuál fue tu producción en el ciclo anterior?",
        },
        elements: [
          {
            type: "text",
            name: "custom_float_39",
            title: {
              default: "In hectares *",
              "es-MX": "En hectáreas *",
            },
            isRequired: required,
            inputType: "number",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Answer must contain up to one decimal place e.g. 40.5",
                  "es-MX":
                    "La respuesta debe tener máximo un decimal p.ej. 40.5",
                },
                regex: `^[0-9]+(\\.[0-9])?$`,
              },
            ],
          },
          {
            type: "text",
            name: "custom_float_40",
            title: {
              en: "In tons *",
              "es-MX": "En toneladas *",
            },
            isRequired: required,
            inputType: "number",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Answer must contain up to one decimal place e.g. 40.5",
                  "es-MX":
                    "La respuesta debe tener máximo un decimal p.ej. 40.5",
                },
                regex: `^[0-9]+(\\.[0-9])?$`,
              },
            ],
          },
          {
            type: "text",
            name: "custom_float_41",
            title: {
              en: "In Pesos $ *",
              "es-MX": "En pesos $ *",
            },
            isRequired: required,
            inputType: "number",
          },
        ],
      },
      {
        type: "panel",
        name: "modelo_panel",
        title: {
          en: "What is the contract agreed with Grupo Modelo for this cycle?",
          "es-MX":
            "¿Cuál es el contrato acordado con Grupo Modelo para este ciclo?",
        },
        elements: [
          {
            type: "text",
            name: "custom_float_42",
            title: {
              en: "In hectares *",
              "es-MX": "En hectáreas *",
            },
            inputType: "number",
            isRequired: required,
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Answer must contain up to one decimal place e.g. 40.5",
                  "es-MX":
                    "La respuesta debe tener máximo un decimal p.ej. 40.5",
                },
                regex: `^[0-9]+(\\.[0-9])?$`,
              },
            ],
          },
          {
            type: "text",
            name: "custom_float_43",
            title: {
              en: "In tons *",
              "es-MX": "En toneladas *",
            },
            isRequired: required,
            inputType: "number",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Answer must contain up to one decimal place e.g. 40.5",
                  "es-MX":
                    "La respuesta debe tener máximo un decimal p.ej. 40.5",
                },
                regex: `^[0-9]+(\\.[0-9])?$`,
              },
            ],
          },
          {
            type: "text",
            name: "custom_float_44",
            title: {
              en: "In Pesos $ *",
              "es-MX": "En pesos $ *",
            },
            isRequired: required,
            inputType: "number",
          },
        ],
      },
      {
        type: "panel",
        name: "modelo_2_panel",
        elements: [
          {
            type: "text",
            name: "initial_date",
            title: {
              en: "How long have you been working with Grupo Modelo?",
              "es-MX": "¿Cuánto tiempo tiene trabajando con Grupo Modelo?",
            },
            description: {
              en: "(Use the cycle, exact date is not necessary)",
              "es-MX": "(Al ciclo, no es necesario fecha exacta)",
            },
            inputType: "date",
          },
          {
            type: "expression",
            title: {
              en: "Time working",
              "es-MX": "Tiempo trabajando",
            },
            name: "custom_text_101",
            expression:
              "fixed(divide(calcDaysDifference({initial_date}), 365))",
          },
          {
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_8",
            title: {
              en: "Letter from Grupo Modelo *",
              "es-MX": "Carta de Grupo Modelo *",
            },
          },
          {
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_9",
            title: {
              en: "Analysis by Verqor *",
              "es-MX": "Análisis Realizado por Verqor *",
            },
          },
        ],
      },
      {
        type: "expression",
        name: "edited_custom_float_45",
        title: {
          en: "The maximum credit that can be considered for the moment is for",
          "es-MX":
            "El crédito máximo que se puede considerar por el momento es por",
        },
        expression: "formatCurrency(fixed({custom_float_45}, 2, true))+' MXN'",
      },
      {
        visibleIf: "1 == 0",
        type: "expression",
        name: "custom_float_45",
        title: {
          en: "The maximum credit that can be considered for the moment is for",
          "es-MX":
            "El crédito máximo que se puede considerar por el momento es por",
        },
        expression: "multiply({custom_float_44}, 0.5)",
      },
      {
        type: "text",
        name: "custom_text_102",
        title: {
          en: "Additional information or comments about your business-income",
          "es-MX":
            "Información o comentario adicional sobre su negocio-ingresos",
        },
        maxLength: 200,
      },
    ],
  },
  // Page 10: Income
  {
    title: { en: "Income ", "es-MX": "Ingresos" },
    innerIndent: 1,
    name: "income",
    elements: [
      {
        html: {
          en: "On this part we want to know more about your incomes and liabilities. Please add as many as necessary.",
          "es-MX":
            "En esta parte queremos saber más sobre tus ingresos. Por favor, añade tantos como consideres necesario.",
        },
        type: "html",
      },
      {
        title: {
          en: "Approximate savings (in cash or in your bank account)",
          "es-MX": "Ahorro aproximado (en efectivo o en su cuenta de bancaria)",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_22",
        displayStyle: "currency",
      },
      {
        //Preapproved amounts from pages 9a or 9b
        visibleIf: "1 == 0",
        type: "expression",
        expression: "sum({custom_float_45},{custom_float_38})",
        name: "custom_float_46",
        title: "Preapproved amount to edit for loan officers",
      },
    ],
  },
  // Page 11: Assets
  {
    title: { en: "Assets", "es-MX": "Activos" },
    name: "assets",
    elements: [
      {
        html: {
          en: "In this part we want to know more about your assets. Please add as many as necessary",
          "es-MX":
            "En esta parte queremos saber más sobre sus ingresos. Por favor, añade tantos como consideres necesario.",
        },
        type: "html",
      },
      {
        title: {
          en: "Do you have property?",
          "es-MX": "¿Cuenta con bienes inmuebles?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_84",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Properties (Land, Plots, House, Storage Units, etc.)",
          "es-MX": "Propiedades (Terrenos, Parcelas, Casa, Bodegas, etc.)",
        },
        visibleIf: "{custom_text_84} == 'si'",
        name: "custom_jsonfield_1",
        valueName: "custom_jsonfield_1",
        type: "matrixdynamic",
        columnLayout: "vertical",
        minRowCount: 1,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: {
              en: "Select the origin of the asset",
              "es-MX": "Selecciona el origen del activo",
            },
            name: "origen_de_activo",
            isRequired: required,
            cellType: "dropdown",
            choices: [
              {
                value: "empresarial",
                text: { en: "Business", "es-MX": "Empresarial" },
              },
              {
                value: "personal",
                text: { en: "Personal", "es-MX": "Personal" },
              },
            ],
          },
          {
            title: { en: "Type of asset", "es-MX": "Tipo de activo" },
            name: "tipo_de_activo",
            isRequired: required,
            cellType: "dropdown",
            inputType: "string",
            choices: [
              {
                value: "casa_habitacion",
                text: { en: "House", "es-MX": "Casa habitación" },
              },
              {
                value: "terreno_urbano",
                text: {
                  en: "Urban land",
                  "es-MX": "Terreno urbano",
                },
              },
              {
                value: "terreno_rustico",
                text: {
                  en: "Rustic land",
                  "es-MX": "Terreno rústico",
                },
              },
              {
                value: "local_comercial",
                text: {
                  en: "Comercial premises",
                  "es-MX": "Local comercial",
                },
              },
              {
                value: "parcelas",
                text: {
                  en: "Plots",
                  "es-MX": "Parcelas",
                },
              },
            ],
          },
          {
            title: {
              en: "Municipality/Town",
              "es-MX": "Municipio/Localidad",
            },
            name: "ubicacion",
            isRequired: required,
            cellType: "text",
            inputType: "text",
          },
          {
            title: {
              en: "Square meters",
              "es-MX": "Metros cuadrados",
            },
            name: "metros_cuadrados",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Approximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado_bienes_raices",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
            inputType: "text",
          },
          {
            title: {
              en: "Do you have a deed, property or purchase agreement?",
              "es-MX":
                "¿Tiene una escritura, propiedad o contrato de compraventa?",
            },
            cellType: "dropdown",
            isRequired: required,
            name: "escritura_propiedad_o_contrato_de_compraventa",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: {
              en: "Please upload photo of property document",
              "es-MX":
                "Por favor, suba foto de documento predial o algún documento de respaldo",
            },
            visibleIf:
              "{row.escritura_propiedad_o_contrato_de_compraventa} == 'si'",
            cellType: "file_uploader",
            isRequired: false,
            accept: "image/*,application/pdf",
            name: "foto_de_documento_predial",
          },
        ],
      },
      {
        title: {
          en: "Do you have a personal vehicle?",
          "es-MX": "¿Tienes vehículo personal?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_64",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Property (personal vehicles)",
          "es-MX": "Propiedades (vehículos personales)",
        },
        columnLayout: "vertical",
        name: "custom_jsonfield_2",
        valueName: "custom_jsonfield_2",
        type: "matrixdynamic",
        visibleIf: "{custom_text_64} == 'si'",
        minRowCount: 1,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: {
              en: "Select the origin of the asset",
              "es-MX": "Selecciona el origen del activo",
            },
            name: "origen_de_activo",
            isRequired: required,
            cellType: "dropdown",
            choices: [
              {
                value: "empresarial",
                text: { en: "Business", "es-MX": "Empresarial" },
              },
              {
                value: "personal",
                text: { en: "Personal", "es-MX": "Personal" },
              },
            ],
          },
          {
            title: { en: "Vehicle type", "es-MX": "Tipo de vehículo" },
            name: "tipo_de_vehiculo",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            name: "marca",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Model",
              "es-MX": "Modelo",
            },
            name: "modelo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Aproximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
          },
        ],
      },
      {
        title: {
          en: "Do you have machinery or equipment",
          "es-MX": "¿Cuenta con maquinaria y/o equipo?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_103",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Machinery/Equipment",
          "es-MX": "Maquinaria/Equipo",
        },
        columnLayout: "vertical",
        name: "custom_jsonfield_3",
        valueName: "custom_jsonfield_3",
        type: "matrixdynamic",
        visibleIf: "{custom_text_103} == 'si'",
        minRowCount: 1,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: {
              en: "Select the origin of the asset",
              "es-MX": "Selecciona el origen del activo",
            },
            name: "origen_de_activo",
            isRequired: required,
            cellType: "dropdown",
            choices: [
              {
                value: "empresarial",
                text: { en: "Business", "es-MX": "Empresarial" },
              },
              {
                value: "personal",
                text: { en: "Personal", "es-MX": "Personal" },
              },
            ],
          },
          {
            title: { en: "Type", "es-MX": "Tipo" },
            name: "tipo",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            name: "marca",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Model",
              "es-MX": "Modelo",
            },
            name: "modelo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Aproximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
          },
        ],
      },
      {
        title: {
          en: "Do you have other kind of asset?",
          "es-MX": "¿Cuenta con otro tipo de activo?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_104",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Other assets",
          "es-MX": "Otros activos",
        },
        columnLayout: "vertical",
        name: "custom_jsonfield_4",
        valueName: "custom_jsonfield_4",
        type: "matrixdynamic",
        visibleIf: "{custom_text_104} == 'si'",
        minRowCount: 1,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: {
              en: "Select the origin of the asset",
              "es-MX": "Selecciona el origen del activo",
            },
            name: "origen_de_activo",
            isRequired: required,
            cellType: "dropdown",
            choices: [
              {
                value: "empresarial",
                text: { en: "Business", "es-MX": "Empresarial" },
              },
              {
                value: "personal",
                text: { en: "Personal", "es-MX": "Personal" },
              },
            ],
          },
          {
            title: { en: "Type", "es-MX": "Tipo" },
            name: "tipo",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            name: "marca",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Model",
              "es-MX": "Modelo",
            },
            name: "modelo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Aproximate value",
              "es-MX": "Valor aproximado",
            },
            name: "valor_aproximado",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Full name of the holder",
              "es-MX": "Nombre completo del titular",
            },
            name: "nombre_completo_del_titular",
            isRequired: required,
            cellType: "text",
          },
        ],
      },
    ],
  },
  // Page 12: References
  {
    title: {
      en: "References",
      "es-MX": "Referencias personales",
    },
    innerIndent: 1,
    name: "references",
    elements: [
      {
        type: "panel",
        elements: [
          {
            html: {
              en: "Please list two references whom would like to give you a positive endorsement. For example: your previous employer, colleague, teacher, advisor, etc. ",
              "es-MX":
                "Por favor, indica dos referencias. Por ejemplo: Su empleador anterior, colega, maestro, consejero, etc.",
            },
            type: "html",
          },
          {
            html: {
              en: "Reference can be anyone who can provide an insight into your study and work ethics. Reference should not be one of your guarantors.",
              "es-MX":
                "La referencia puede ser cualquier persona que pueda proporcionar una visión de su estudio y ética laboral. La referencia no debe ser uno de sus garantes.",
            },
            type: "html",
          },
        ],
      },
      {
        title: {
          en: "Reference #1 details",
          "es-MX": "Referencia personales #1",
        },
        name: "panel_ref1",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "ref1_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Reference Full Name *",
              "es-MX": "Nombre completo de la referencia *",
            },
          },
          {
            name: "ref1_mobile_phone",
            type: "phone",
            visibleIf: "{ref1_name} notempty",
            isRequired: required,
            maxLength: "10",
            title: {
              en: "Reference Mobile No. *",
              "es-MX": "Número de teléfono de la referencia *",
            },
            validators: [
              expressions.phone_en("ref1_mobile_phone"),
              expressions.phone_length("ref1_mobile_phone"),
            ],
          },
          {
            title: {
              en: "What is the relation of reference #1 to you? *",
              "es-MX": "¿Cuál es la relación con la referencia #1? *",
            },
            description: {
              en: "(Ex. Boss, Friend, Family, etc.)",
              "es-MX": "(Ej. Amigo, familiar, proveedor, etc.)",
            },
            name: "ref1_relation",
            visibleIf: "{ref1_name} notempty",
            isRequired: required,
            type: "dropdown",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_relation_types/`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Address", "es-MX": "Dirección" },
            name: "ref1_street",
            type: "text",
            isRequired: required,
            visibleIf: "{ref1_name} notempty",
          },
        ],
      },
      {
        title: {
          en: "Reference #2 details",
          "es-MX": "Referencia personales #2",
        },
        name: "panel_ref2",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "ref2_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Reference Full Name *",
              "es-MX": "Nombre completo de la referencia *",
            },
          },
          {
            name: "ref2_mobile_phone",
            type: "phone",
            visibleIf: "{ref2_name} notempty",
            isRequired: required,
            maxLength: "10",
            title: {
              en: "Reference Mobile No. *",
              "es-MX": "Número de teléfono de la referencia *",
            },
            validators: [
              expressions.phone_en("ref2_mobile_phone"),
              expressions.phone_length("ref2_mobile_phone"),
            ],
          },
          {
            title: {
              en: "What is the relation of reference #2 to you? *",
              "es-MX": "¿Cuál es la relación con la referencia #2? *",
            },
            description: {
              en: "(Ex. Boss, Friend, Family, etc.)",
              "es-MX": "(Ej. Amigo, familiar, proveedor, etc.)",
            },
            name: "ref2_relation",
            visibleIf: "{ref2_name} notempty",
            isRequired: required,
            type: "dropdown",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_relation_types/`,
              path: "results",
              titleName: "description",
              valueName: "id",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Address", "es-MX": "Dirección" },
            name: "ref2_street",
            type: "text",
            isRequired: required,
            visibleIf: "{ref2_name} notempty",
          },
        ],
      },
    ],
  },
  // Page 13: Joint obligor
  {
    title: {
      en: "Joint obligor",
      "es-MX": "Obligado solidario",
    },
    innerIndent: 1,
    name: "obligor",
    elements: [
      {
        type: "panel",
        elements: [
          {
            html: {
              en: "In this part, the data of the joint obligor will be requested, in general it is the person closest to you (mother, father, uncle, wife, etc.).",
              "es-MX":
                "En esta parte se pedirán los datos del obligado solidario, en general es la persona más cercana a ti (madre, padre, tío, esposa,etc).",
            },
            type: "html",
          },
          {
            html: {
              en: "A message will be sent by WhatsApp where a small information will be asked to the person who entered. Please let him know about this so he can fill in the details.",
              "es-MX":
                "Se enviará un mensaje por WhatsApp en donde se le pedirán unos pequeños datos a la persona que puso. Por favor hazle saber de esto para que pueda llenar los datos.",
            },
            type: "html",
          },
        ],
      },
      {
        name: "guarantor_first_name",
        type: "text",
        isRequired: required,
        title: {
          en: "obligor's name *",
          "es-MX": "Nombre del obligado *",
        },
      },
      {
        name: "guarantor_last_name",
        type: "text",
        isRequired: false,
        title: {
          en: "Last name",
          "es-MX": "Apellido paterno",
        },
      },
      {
        name: "guarantor_mother_name",
        type: "text",
        isRequired: false,
        title: {
          en: "Second surname",
          "es-MX": "Apellido materno",
        },
      },
      {
        title: {
          en: "Kinship (mother, father, wife, friend, etc)",
          "es-MX": "Parentesco (madre, padre, esposa, amigo, etc)",
        },
        name: "guarantor_relation",
        isRequired: required,
        type: "text",
        inputType: "string",
      },
      {
        name: "guarantor_phone",
        type: "phone",
        isRequired: required,
        title: {
          en: "Obligor phone number *",
          "es-MX": "Número de teléfono del(a) obligado solidario *",
        },
        maxLength: "10",
        validators: [
          expressions.phone_en("guarantor_phone"),
          expressions.phone_length("guarantor_phone"),
          expressions.same_phone_number("guarantor_phone")
        ],
      },
    ],
  },
  // Page 14: Bank account info
  {
    title: {
      en: "Bank account info",
      "es-MX": "Información de cuenta bancaria",
    },
    innerIndent: 1,
    name: "bank_account_info",
    elements: [
      {
        title: {
          en: "Do you have a bank account? *",
          "es-MX": "¿Tienes una cuenta bancaria?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_70",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Bank Name *",
          "es-MX": "Nombre del Banco *",
        },
        visibleIf: "{custom_text_70} == 'si'",
        type: "text",
        name: "custom_text_105",
        isRequired: required,
      },
      {
        title: {
          en: "Bank Account No. *",
          "es-MX": "Número de Cuenta Bancaria. *",
        },
        visibleIf: "{custom_text_70} == 'si'",
        type: "text",
        inputType: "number",
        name: "custom_text_82",
        isRequired: required,
      },
      {
        title: {
          en: "Code *",
          "es-MX": "Clabe *",
        },
        visibleIf: "{custom_text_70} == 'si'",
        type: "text",
        name: "custom_text_106",
        isRequired: required,
        maxLength: 18,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Enter 18 digits",
              "es-MX": "Ingrese 18 dígitos",
            },
            regex: `^[0-9]{18}$`,
          },
        ],
      },
    ],
  },
];
