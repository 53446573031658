import { SurveyComponent } from "../../../../components/survey/survey_component";
import { expressions } from "../../../../survey/validators";
import env from "../../../../env";
const required = env.ENDPOINT !== "local";

export const references_ffdl = new SurveyComponent({
  title: {
    en: "References",
    "es-GT": "Referencias personales",
  },
  description: {
    en: "Please add contact details of at least one person who is willing to be your referee. The  referee might be contacted for confirmation.",
    "es-GT":
      "Agregue los datos de contacto de al menos dos  personas que lo conozcan. Es posible que se les contacte.",
  },
  name: "references",
  elements: [
    {
      title: {
        en: "Reference #1 details",
        "es-GT": "Referencia #1 personales",
      },
      name: "panel_ref1",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "ref1_name",
          type: "text",
          isRequired: required,
          title: {
            en: "Full name *",
            "es-GT": "Nombre *",
          },
        },
        {
          title: { en: "Address", "es-GT": "Dirección" },
          name: "ref1_street",
          isRequired: required,
          type: "text",
        },
        {
          name: "ref1_phone",
          type: "phone",
          isRequired: required,
          title: {
            en: "Telephone number *",
            "es-GT": "Teléfono *",
          },
          validators: [
            expressions.phone_en("ref1_phone"),
            expressions.phone_length("ref1_phone"),
          ],
        },
      ],
    },
    {
      title: {
        en: "Reference #2 details",
        "es-GT": "Referencia #2 personales",
      },
      description: {
        en: "A second reference is not mandatory",
        "es-GT": "Una segunda referencia no es obligatoria",
      },
      name: "panel_ref2",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "ref2_name",
          type: "text",
          title: {
            en: "Full name *",
            "es-GT": "Nombre *",
          },
        },
        {
          title: { en: "Address", "es-GT": "Dirección" },
          name: "ref2_street",
          isRequired: "{ref2_name} notempty",
          type: "text",
        },
        {
          name: "ref2_phone",
          type: "phone",
          isRequired: "{ref2_name} notempty",
          title: {
            en: "Telephone number *",
            "es-GT": "Teléfono *",
          },
          validators: [
            expressions.phone_en("ref2_phone"),
            expressions.phone_length("ref2_phone"),
          ],
        },
      ],
    },
    {
      title: {
        en: "Family reference #1",
        "es-GT": "Referencia #1 familiares",
      },
      name: "panel_family_ref1",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "custom_text_49",
          type: "text",
          isRequired: required,
          title: {
            en: "Full name *",
            "es-GT": "Nombre *",
          },
        },
        {
          title: { en: "Address", "es-GT": "Dirección" },
          name: "custom_text_52",
          isRequired: required,
          type: "text",
        },
        {
          name: "custom_text_50",
          type: "phone",
          isRequired: required,
          title: {
            en: "Telephone number *",
            "es-GT": "Teléfono *",
          },
          validators: [
            expressions.phone_en("custom_text_50"),
            expressions.phone_length("custom_text_50"),
          ],
        },
      ],
    },
    {
      title: {
        en: "Reference's #2 familiares",
        "es-GT": "Referencia #2 familiares",
      },
      description: {
        en: "A secondreference is not mandatory",
        "es-GT": "Una segunda referencia no es obligatoria",
      },
      name: "panel_family_ref2",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "custom_text_51",
          type: "text",
          title: {
            en: "Full name *",
            "es-GT": "Nombre *",
          },
        },
        {
          title: { en: "Address", "es-GT": "Dirección" },
          name: "custom_text_53",
          isRequired: "{custom_text_51} notempty",
          type: "text",
        },
        {
          name: "custom_text_54",
          type: "phone",
          isRequired: "{custom_text_51} notempty",
          title: {
            en: "Telephone number *",
            "es-GT": "Teléfono *",
          },
          validators: [
            expressions.phone_en("custom_text_54"),
            expressions.phone_length("custom_text_54"),
          ],
        },
      ],
    },
  ],
});
