import React from 'react';
import ReactDOM from 'react-dom';
import nouislider from "nouislider";
import './likert_scale.scss';


class LikertComponent extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: null
    };

    this.slider = null;
  }

  componentDidMount() {
    // create component

    this.slider = nouislider.create(this.sliderContainer, {
      start: 2.5,
      tooltips: false,
      range: {
        min: 0,
        max: 5
      }
    });

    this.slider.on("set", () => {

      // if set value to unclear, uncheck this box
      if (this.state.value === 'unclear') this.setState({value: null});

      // depending on scoring, either Normal or Reverse
      if (this.props.question.scoring === 'Normal') {
        this.props.changeHandler(this.slider.get())
      } else {
        this.props.changeHandler(5 - this.slider.get())
      }

    });
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  _update(newValue) {
    this.setState({value: newValue});
    this._sendValue(newValue);
  };

  _setUnclear() {
    // only allows for unclear to be made
    this.setState({value: 'unclear'});
    this.slider.set(2.5);
    this.props.changeHandler(null);
    this.unClearInput.focus();
  }

  _sendValue(value) {
    if (value) {
      this.props.changeHandler(value)
    }
  }

  render({choices, question} = this.props) {
    return (
      <div className='likert'>

        <h3>{choices[0].locText.text}</h3>

        <div className='trapezoids'>
          <div className='top element'/>
          <div className='bottom element'/>
        </div>
        <div ref={ref => this.sliderContainer = ref}/>

        <div className='choices'>
          <span className='choice small darkgray'>{choices[1].locText.text}</span>
          <span className='choice small thin'>{choices[2].locText.text}</span>
          <span className='choice small darkgray'>{choices[3].locText.text}</span>
          <span className='choice small thin'>{choices[4].locText.text}</span>
          <span className='choice small darkgray'>{choices[5].locText.text}</span>
        </div>

        <div className='unclear'>

          <div className={`checkbox ${this.state.value === 'unclear' ? 'heavy' : ''}`}>
            <div>
              <label>
                <input type="checkbox" value="unclear" checked={this.state.value === 'unclear'}
                       onChange={this.state.value === 'unclear' ? () => this._update(null) : () => this._setUnclear()}/>
                {question.otherItem.text}
              </label>
            </div>
          </div>

          <div className={`input_unclear ${this.state.value === 'unclear' ? '' : 'hidden'}`}>
            <span className='small'>{question.otherErrorText}</span>
            <input
              ref={(ref) => this.unClearInput = ref}
              type="text"
              name="comment"
              maxLength={230}
              onBlur={(event) => this._sendValue(event.target.value ? `unclear: ${event.target.value}` : null)}
            />
          </div>

        </div>
      </div>
    )
  }
}

export function register_widget_likert(Survey) {

  let widget = {
    name: "likert_scale",

    widgetIsLoaded: function () {
      //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
      return !!nouislider.version
    },
    isFit: function (question) {
      return question.getType() === "radiogroup" && question["renderAs"] === "likert";
    },

    activatedByChanged: function (activatedBy) {
      //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us

      Survey.JsonObject.metaData.addProperties("radiogroup", [
        {
          name: "renderAs",
          default: "standard",
        },
        {
          name: "scoring:string",
          default: 'Normal' // or Reverse
        },
        {
          name: "statement:string",
          default: ''
        },
      ]);
    },

    afterRender: function (question, el) {
      let rendering = <LikertComponent choices={question.choices} question={question}
                                       changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, el);
    },

    //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
    htmlTemplate: "<div style='padding: 0 20px'></div>",

    onReadOnlyChanged: function (question) {
      let el = question.noUiSliderEl;
      if (!el) return;
      if (question.isReadOnly) {
        el.setAttribute("disabled", true);
      } else {
        el.removeAttribute("disabled");
      }
    },

    willUnmount: function (question, el) {
      // All unounting is handled in component itself
    }
  };

  // register widget
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");

}