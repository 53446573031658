import React from 'react';
import { connect } from 'react-redux';
import * as Survey from 'survey-react';
import { Bullet } from '../../components/bullet/bullet';
import { Footer } from '../../components/footer/footer';
import HelpComponent from '../../components/help/help';
import { Start } from '../../components/start/start';
import Config from '../../config';
import { translate, translateList } from '../../lib/intl';
import { loanActions } from '../../store/store';
import { extractSurveyData } from '../../survey/survey.utils';
import '../../theme/survey.scss';
import { assessment_study } from './assessment_study';

class AssessmentStudyContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    // setup model
    this.model = new Survey.Model(assessment_study(props.device.locale));

    // restore data
    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan.intake,
      purpose_question: this.props.loan.loan_purpose,
    };

    this.descList = [];
    this.bullets = [];
    this.requirements = [];
    this.custom_text = [];
    try {
      this.requirements = translateList('assessment_study.requirements').map((req, idx) =>
        Bullet(translate(req), idx)
      );
      translateList('assessment_study.custom_text').forEach((requirement, idx) => {
        this.custom_text.push(
          <p className="spacer" key={idx}>
            {requirement}
          </p>
        );
      });
    } catch (e) {
      console.error(`Unable to translate ${e}, check translation key is added in locale file `);
    }

    // set callbacks
    this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };
  }

  onPartialSend(survey) {
    this.props.saveIntake(this.props.loan.uuid, extractSurveyData(survey, this.state, this.base));
  }

  async onCompleting(survey) {
    //update intake first
    await this.props.saveIntake(
      this.props.loan.uuid,
      extractSurveyData(survey, this.state, this.base),
      false
    );

    let state =
      Config.assessment_study_container && Config.assessment_study_container.next_state
        ? Config.assessment_study_container.next_state
        : 'intake_in_progress';
    this.props
      .completeAssessmentStudy(this.props.loan.uuid, state, survey.data.purpose_question)
      .then(() => this.props.setPage(state));
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time: this.state.help_time + Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render = ({ device } = this.props) => (
    <div className="completeAssessmentStudy">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="home"
        locale={device.locale}
      />

      {this.state.is_started ? (
        <div>
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="assessment_study"
            time={Config.has_no_container_time ? '0' : '5'}
            onStart={() => this.setState({ is_started: true })}
          >
            <p className="heavy">{translate('assessment_study.desc1')}</p>
            <p className="spacer">{translate('assessment_study.desc2')}</p>
            {this.descList}
            {this.bullets}
            {translate('assessment_study.req') !== 'assessment_study.req' && (
              <div>
                <p className="spacer">{translate('assessment_study.req')}</p>
              </div>
            )}
            {this.requirements}
            {this.custom_text}
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveIntake: (uuid, assessment, submit) =>
      dispatch(loanActions.saveIntake(uuid, assessment, submit)),
    completeAssessmentStudy: (uuid, state, purpose) =>
      dispatch(loanActions.completeAssessmentStudy(uuid, state, purpose)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(mapStateToProps, mapDispatchToProps)(AssessmentStudyContainer);

export { connectedContainer as AssessmentStudyContainer };
