import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

export const extra_marital = new SurveyComponent({
  title: {
    en: "If you are married or in a common-law union, give details of the partner",
    "es-GT": "Si está casado o con unión de hecho dar detalles del compañero",
  },
  name: "extra_marital",
  type: "panel",
  innerIndent: 1,
  visibleIf: "{marital} == 'casado' || {marital} == 'union_de_hecho'",
  elements: [
    {
      type: "text",
      name: "custom_text_10",
      isRequired: required,
      title: {
        en: "First name *",
        "es-GT": "Primer nombre *",
      },
    },
    {
      type: "text",
      name: "custom_text_11",
      title: {
        en: "Middle name",
        "es-GT": "Segundo nombre",
      },
    },
    {
      type: "text",
      name: "custom_text_60",
      isRequired: required,
      title: {
        en: "Surname *",
        "es-GT": "Primer apellido *",
      },
    },
    {
      type: "text",
      name: "custom_text_12",
      title: { en: "Second surname", "es-GT": "Segundo apellido" },
    },
    {
      type: "text",
      name: "custom_text_13",
      title: { en: "Married name", "es-GT": "Apellido de casada" },
    },
    {
      name: "custom_text_14",
      type: "text",
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 13 digits length",
            "es-GT": "El número debe tener una longitud de 13 dígitos",
          },
          regex: "^[0-9]{13}$",
        },
      ],
      title: {
        en: "ID No.",
        "es-GT": "DPI",
      },
    },
    {
      type: "text",
      name: "custom_text_15",
      title: {
        en: "Activity carried out",
        "es-GT": "Actividad que realiza",
      },
    },
    {
      type: "text",
      name: "custom_text_16",
      title: {
        en: "Place of work",
        "es-GT": "Lugar de trabajo",
      },
    },
    {
      type: "text",
      name: "custom_text_17",
      title: {
        en: "Position held",
        "es-GT": "Cargo que ocupa",
      },
    },
    {
      type: "number",
      name: "custom_text_18",
      title: {
        en: "Income/Salary",
        "es-GT": "Ingreso/Salario",
      },
    },
    {
      title: {
        en: "Freq. Income/Salary",
        "es-GT": "Frec. Ingreso/Salario",
      },
      name: "custom_text_19", // check mapping
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "semanal",
          text: {
            en: "Weekly",
            "es-GT": "Semanal",
          },
        },
        { value: "quincenal", text: { en: "Biweekly", "es-GT": "Quincenal" } },
        { value: "mensual", text: { en: "Monthly", "es-GT": "Mensual" } },
      ],
    },
    {
      type: "text",
      name: "custom_text_20",
      title: {
        en: "Working time",
        "es-GT": "Tiempo de laborar",
      },
    },
    {
      name: "custom_text_21", // Check mapping
      type: "phone",
      validators: [
        expressions.phone_en("custom_text_21"),
        expressions.phone_length("custom_text_21"),
      ],
      title: {
        en: "Tel.",
        "es-GT": "Tel.",
      },
    },
    {
      title: {
        en: "Are you an FDL client? *",
        "es-GT": "¿Es cliente de FDL? *",
      },
      name: "custom_text_22",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "si", text: { en: "Yes", "es-GT": "Si" } },
        { value: "no", text: { en: "No", "es-GT": "No" } },
      ],
    },
  ],
});
