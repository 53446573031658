import React from "react";
import { connect } from "react-redux";
import * as Survey from "survey-react";
import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import { Start } from "../../components/start/start";
import Config from "../../config";
import { DEFAULT_LOCALE, translate } from "../../lib/intl";
import { loanActions } from "../../store/store";
import { Message } from "./../home/message";
import {
  removeElementTitles,
  saveBase64,
  useBase,
} from "../../survey/survey.utils";
import "./guarantor_credit_check.container.scss";
import { guarantor_credit_check_banco_popular } from "./guarantor_credit_check_banco_popular";

class GuarantorCreditCheckContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const deploymentModels = {
      banco_popular: guarantor_credit_check_banco_popular,
    };

    // setup model
    const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
      const model =
        deploymentModels[Config.deployment] !== undefined
          ? deploymentModels[Config.deployment]
          : deploymentModels.banco_popular;
      model.locale = locale;
      return model;
    };

    this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

    // TODO: remodel signature object without file
    let data = this.props.loan.intake_guarantor;

    // add in beneficiary first_name and the loan terms
    data["user_first_name"] = this.props.loan.first_name;
    data["applicant_id_number"] = this.props.loan.id_number;
    data["applicant_phone"] = this.props.loan.phone_number;
    data["applicant_nationality"] = this.props.loan.nationality;

    data["amount"] = this.props.loan.amount;
    data["repayment_period"] = this.props.loan.repayment_period;
    data["monthly_insurance"] = this.props.loan.monthly_insurance;
    data["monthly_installment"] = this.props.loan.monthly_installment;
    data["total_fees"] = this.props.loan.total_fees;

    // make loan terms available in intake
    let loan_terms_summary = this.props.loan.loan_terms_summary
      ? this.props.loan.loan_terms_summary
      : {};

    delete data["signature"];
    const intake = this.props.loan.intake_guarantor;
    this.model.data = this.base = {
      ...this.model.data,
      ...data,
      loan_purpose: this.props.loan.loan_purpose,
      loan_terms_summary,
      intake,
    };

    try {
      this.model.currentPageNo = this.props.loan.intake_guarantor.current_page;
    } catch (error) {
      console.error(
        "Sometimes setting the page fails, so starting on title due to error: ",
        error
      );
    }

    // set callbacks
    this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };
  }

  onPartialSend(survey) {
    let data = {
      ...survey.data,
      current_page: survey.currentPageNo,
    };
    if (data.guarantor_refused) {
      this.props
        .updateGuarantor(this.props.loan.uuid, this.base, data, false)
        .then(() => {
          this.props.transitionLoanState(
            this.props.loan.uuid,
            "guarantor_refused"
          );
        });
    } else {
      this.props.updateGuarantor(this.props.loan.uuid, this.base, data, false);
    }
  }

  onCompleting(survey) {
    let data = survey.data;

    saveBase64(
      "signature.png",
      "image/png",
      data.signature,
      (signature_date) => {
        data["signature"] = signature_date;
        console.log("saving data:", data);
        this.props
          .updateGuarantor(this.props.loan.uuid, this.base, data, false)
          .then(() => {
            this.props.transitionLoanState(
              this.props.loan.uuid,
              "complete_guarantor_credit_check"
            );
          });
      }
    );
  }

  componentDidMount() {
    removeElementTitles(document, ["removeMe"]);
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time:
        this.state.help_time +
        Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render = ({ history, account, device, loan } = this.props) => (
    <div className="guarantorCreditCheckContainer">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="guarantor"
        locale={device.locale}
      />

      {this.props.loan.intake_guarantor.guarantor_refused ? (
        <div>
          {/* Page to be displayed when guarantor refused */}
          <Message message="submitted_guarantor" /> 
        </div>
      ) : this.state.is_started ? (
        <div className="centerWrapper">
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="guarantor"
            time={Config.has_no_container_time ? "0" : "10"}
            onStart={() => this.setState({ is_started: true })}
          >
            <h2 className="heavy bigSpacer">
              {translate("start_guarantor.header", {
                guarantor_first_name: this.model.data.intake.first_name
                  ? this.model.data.intake.first_name
                  : loan.guarantor_first_name,
              })}
            </h2>
            <p className="heavy">
              {translate("start_guarantor.desc1", {
                first_name: loan.first_name,
                amount: loan.amount,
              })}
            </p>
            <p className="spacer">{translate("start_guarantor.desc2")}</p>
            <p className="spacer">{translate("start_guarantor.desc3")}</p>
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transitionLoanState: (uuid, action) =>
      dispatch(loanActions.transitionLoanState(uuid, action)),
    updateGuarantor: (uuid, base, guarantor_intake, is_completed) =>
      dispatch(
        loanActions.updateGuarantor(
          uuid,
          useBase(base, guarantor_intake),
          is_completed
        )
      ),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuarantorCreditCheckContainer);

export { connectedContainer as GuarantorCreditCheckContainer };
