export const review_bll = {
  
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rn: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and sign your loan application",
        rn: "Suzuma hanyuma ushireko umukono",
        "fr-BI": "Vérifiez et signez",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          elements: [
            {
              title: {
                en: "Requested loan amount",
                rn: "Amafranga y'ingurane",
                "fr-BI": "Montant Demandée"
              },
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression: "{amount}",
            },
            {
              title: {
                en: "Loan term (in months)",
                rn: "Ikiringo c'ingurane",
                "fr-BI": "Durée du pret (mois) "
              },
              type: "expression",
              expression: "6",
            },
            {
              title: {
                en: "Total administrative fees in FBu to be deducted from the loan amount (4%)",
                rn: "Amafranga y‘idosiye (4%)",
                "fr-BI": "Frais de dossie (4%)"
              },
              name:"custom_float_43",
              type: "expression",
              displayStyle: "currency",
              currency: "BIF",
              expression:
                "fixed(multiply({amount},0.04))",
            },
            {
              title: {
                en: "Monthly interest rate (7%)",
                rn:
                  "Inyungu ku ngunzanyo mu mwaka mu FBu (7%)",
                  "fr-BI": "Taux d'intérêt mensuel (7%)"
              },
              name:"custom_float_44",
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression:"fixed(divide(multiply({amount},0.14),2))",
            },
            {
              title: {
                en: "calculation",
                rn:
                  "calculation",
                  "fr-BI": "calculation"
              },
              visibleIf:"1==0",
              name:"custom_float_46",
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression:"fixed(add({amount},{custom_float_44})",
            },
            {
              title: {
                en: "half calculation",
                rn:
                  "half calculation",
                  "fr-BI": "half calculation"
              },
              visibleIf:"1==0",
              name:"custom_float_45",
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression:"fixed(multiply({custom_float_46},0.5)",
            }, 
            {
              title: {
                en: "Amount that will be delivered on the account",
                "fr-BI":
                  "Montant qui sera débourse sur le compte",
                rn: "Amafranga azoshirwa kuri kontei"  
              },
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression: "fixed(subtract({amount},{custom_float_43}))",
            },
            {
              html: {
                en: "The amount afterwards a loan of 6 months will total {custom_float_46}. This can be paid half after 3 months ( {custom_float_45} ) and the other half at the end ( {custom_float_45} ) ",
                "fr-BI":
                  "Vous recevez le prêt qui va être payer pendant 6 moins. Le montant qui va être paye est {custom_float_46}.  Le prêt va être payer en deux parties, pendant les trois premières mois vous payerez {custom_float_45}  et les autres trois mois restants vous payerez {custom_float_45} ",
                  rn: "Uronse ingurane, ikiringo co kwishura ni amezi 6, ingurane yose izoba ihejeje kwishurwa. Kuri iyo ngurane, amafranga azokwishurwa azoba ari {custom_float_46}. Izishurwa mu byiciro biriri icambere nyuma ya amezi atatu ni {custom_float_45} icindi mu mezi atatu asigaye {custom_float_45}.​",
              },
              type: "html",
            },
            {
              html: {
                en: "To ensure that you are serious about a timely monthly payment, you will be charged 1% for every day you are late with payment.",
                "fr-BI":
                  "En cas de retard de paiement, un supplément de 1% sur chaque échéance due (capital + intérêts) vous sera facturé prorata temporis. ",
                  rn: "Mu gihe habayeho gucerwa kwishura, harategekanijwe ibihano ku rugezo rw'ibice 1% ku gice cose c'ingurane kitarishwe (umutahe+inyungu). Muzobiriha bivanye n'iminsi uzoba wacerewe kwishura",
              },
              type: "html",
            },
            {
              html: {
                en: " No variation or amendment of this Agreement shall be valid unless committed to writing and signed by or on behalf of both parties",
                "fr-BI":
                  "Aucune variation ou amendement au présent Accord ne sera valide à moins d'être commis par écrit et signé par ou au nom des deux parties.",
                rn: "Ntakizohindura ayo masezerano kiretse habaye urwandiko",  
              },
              type: "html",
            },
          ],
        },
        {
          title: "",
          type: "checkbox",
          name: "agree",
          isRequired: true,
          choices: [
            {
              value: {
                en: "My name is {first_name} and my ID number is {id_number}. I confirm that the information given in this form is true, complete and accurate.",
                rn: "Izina ryanje ni {first_name} inimero indanga ni {id_number}. Ndemeje ibi nanditse kuri uru rupapuro ko ari ivy'ukuri",
                "fr-BI": "Mon nom est {first_name} et mon numéro d'identification est {id_number}. Je confirme que les informations fournies sur ce formulaire sont vraies, complètes et exactes."
              },
              text: {
                en: "I agree",
                rn: "Ndabyemeye",
                "fr-BI": "Je suis d'accord",
              },
            },
          ],
          validators: [],
        },
      ],
    },
  ],
};
