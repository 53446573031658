import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { fiduciary } from "./fiduciary";
import { mortgage } from "./mortgage";
import { movable } from "./movable";

const required = env.ENDPOINT !== "local";

export const guarantor_collateral_ffdl = new SurveyComponent({
  title: {
    en: "Guarantor or collateral info",
    "es-GT": "Garantía",
  },
  name: "guarantor_collateral",
  elements: [
    {
      type: "panel",
      visibleIf:"{amount} > 20000 && {custom_bool_1} == true || {amount} > 10000 && {custom_bool_1} == false",
      elements: [
        {
          title: {
            en: "What is your collateral? *",
            "es-GT": "¿Cuál es tu garantía? *",
          },
          name: "collateral_arrangements",
          type: "dropdown",
          isRequired: required,
          choices: [
            {
              value: "fiduciaria",
              text: {
                en: "Fiduciary",
                "es-GT": "Fiduciaria",
              },
            },
            { value: "hipotecaria", text: { en: "Mortgage", "es-GT": "Hipotecaria" } },
            { value: "mobiliaria", text: { en: "Movable", "es-GT": "Mobiliaria" } },
          ],
        },
        fiduciary,
        mortgage,
        movable,
      ]
    }
  ],
});
