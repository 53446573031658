import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const job_information_credicapital = (clientUrlForCatalog) =>
  new SurveyComponent({
    title: { en: "Job information", "es-MX": "Información del negocio" },
    elements: [
      {
        title: {
          en: "Please share details about your current job with us.",
          "es-MX":
            "Por favor, comparta detalles sobre su trabajo actual con nosotros.",
        },
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            title: { en: "Business Type", "es-MX": "Tipo de negocio" },
            type: "dropdown",
            name: "custom_text_59",
            isRequired: required,
            choices: [
              {
                value: "Ambulante",
                text: { en: "Ambulante", "es-MX": "Ambulante" },
              },
              { value: "Fijo", text: { en: "Fijo", "es-MX": "Fijo" } },
            ],
          },
          {
            title: { en: "Business name", "es-MX": "Nombre del negocio" },
            type: "text",
            name: "custom_text_60",
            isRequired: required,
          },
          {
            title: { en: "Line of business", "es-MX": "Giro del negocio" },
            type: "dropdown",
            name: "custom_text_61",
            isRequired: required,
            choices: [
              {
                value: "Industria",
                text: { en: "Industria", "es-MX": "Industria" },
              },
              {
                value: "Comercio",
                text: { en: "Comercio", "es-MX": "Comercio" },
              },
              {
                value: "Servicio",
                text: { en: "Servicio", "es-MX": "Servicio" },
              },
            ],
          },
          {
            title: { en: "Business address", "es-MX": "Dirección del negocio" },
            type: "panel",
            innerIndent: 1,
            elements: [
              {
                title: { en: "State", "es-MX": "Estado" },
                type: "dropdown",
                isRequired: required,
                name: "custom_text_65",
                renderAs: "select2",
                choicesByUrl: {
                  url: `${clientUrlForCatalog}/api/entidadfederativa/entidades-federativa`,
                  titleName: "Descripcion",
                  valueName: "Descripcion",
                  attachOriginalItems: true,
                },
                
              },
              {type: "text", name: "idEntidadFederativa_custom_text_65", visible: false},
              {
                title: { en: "Municipality", "es-MX": "Municipio" },
                type: "dropdown",
                isRequired: required,
                name: "custom_text_64",
                renderAs: "select2",
                choicesByUrl: {
                  url: `${clientUrlForCatalog}/api/municipio/id-municipio-by-entidadfederativa?idEntidadFederativa={idEntidadFederativa_custom_text_65}`,
                  titleName: "Descripcion",
                  valueName: "Descripcion",
                  attachOriginalItems: true,
                },
              },
              {type: "text", name: "idMunicipio_custom_text_64", visible: false},
              {
                type: "dropdown",
                name: "custom_text_66",
                title: { en: "Suburb", "es-MX": "Colonia" },
                renderAs: "select2",
                isRequired: required,
                choicesByUrl: {
                  url: `${clientUrlForCatalog}/api/colonia/colonias-municipio?idMunicipio={idMunicipio_custom_text_64}&idEntidadFederativa={idEntidadFederativa_custom_text_65}`,
                  titleName: "Descripcion",
                  valueName: "Descripcion",
                  attachOriginalItems: true,
                },
              },
              {type: "text", name: "codigoPostal_custom_text_63", visible: false},
              {
                title: {
                  en: "Postal Code",
                  "es-MX": "Código postal"
                },
                type: "text",
                isRequired: required,
                name: "custom_text_63",
                readOnly: true,
              },
              {
                title: { en: "Address", "es-MX": "Domicilio" },
                type: "text",
                name: "custom_text_62",
                isRequired: required,
              },
            ],
          },
          {
            type: "dropdown",
            name: "custom_text_15",
            readOnly: true,
            title: {
              en: "Key and economic activity",
              "es-MX": "Clave y actividad económica",
            },
          },
        ],
      },
    ],
  });
