import env from '../../env';
import { DEFAULT_LOCALE } from '../../lib/intl';
import { add_big5_questions } from './data/big5';
import { add_choice_questions } from './data/choice';
import { add_iq_questions } from './data/iq';
import { add_memory_questions } from './data/memory';
import { add_pauze } from './data/pauze';
import { add_rank_questions } from './data/rank';
import { add_raw_questions } from './data/raw';

const isTest = env.ENDPOINT !== 'production';

const next = { en: 'Next', ar: 'التالي', rw: 'Ahakurikira' };

let assessment = {
  showProgressBar: 'top',
  showQuestionNumbers: 'off',
  firstPageIsStarted: false,
  showPrevButton: false,
  showCompletedPage: false,
  startSurveyText: { en: 'to start', ar: 'انقر لتبدأ' },
  completeText: next,
  pageNextText: next,
  requiredText: '',
  isSinglePage: false, // Can be set to True for review mode
  // mode: 'display', // for review
  pages: [
    {
      title: {
        en: 'Get started with the second part of the loan application',
        ar: 'ابدأ الجزء الثاني من طلب القرض',
      },
      name: 'start',
      elements: [],
    },
  ],
};

// add all questions in order
add_raw_questions(assessment, isTest);
add_choice_questions(assessment, isTest);
add_pauze(assessment, 1);
add_big5_questions(assessment, isTest, 0);
add_pauze(assessment, 1);
add_iq_questions(assessment, isTest);
add_memory_questions(assessment, isTest);
add_pauze(assessment, 1);
add_big5_questions(assessment, isTest, 1);
add_rank_questions(assessment, isTest);

function withLocale(locale = DEFAULT_LOCALE) {
  assessment.locale = locale;
  return assessment;
}

export default withLocale;
