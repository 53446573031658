import React from "react";
import { Icon, ws_check_round } from "../icon/icon";
import { colors } from "../../theme/theme";
import "./bullet.scss";
import { v4 as uuidv4 } from "uuid";

export const Bullet = (message, key) => {
  key = key ? key : uuidv4();
  return (
    <div className="bullet spacer" key={key}>
      <Icon icon={ws_check_round} size={15} color={colors.brand.accent} />
      {/* Not sure if this is the best way to render html */}
      <p dangerouslySetInnerHTML={{ __html: message }} className="small" />
    </div>
  );
};
