export const survey_fr = {
    "pagePrevText": "Précédent",
    "pageNextText": "Suivant",
    "completeText": "Complete",
    "startSurveyText": "Démarrer",
    "progressText": "Page {0} de {1}",
    "requiredError": "Veuillez répondre à la question.",

    "otherItemText": "Autre",
    "emptySurvey": "Il n'y a pas de page ou de question visible dans le sondage.",
    "completeingSurvey": "Merci d'avoir répondu au sondage !",
    "completeingSurveyBefore": "Nos dossiers montrent que vous avez déjà répondu à cette enquête.",
    "loadingSurvey": "Le sondage est en cours de chargement...",
    "optionsCaption": "Choisir...",
    "valeur": "valeur",
    "requiredErrorInPanel": "Veuillez répondre à au moins une question.",
    "requiredInAllRowsError": "Veuillez répondre aux questions dans toutes les lignes.",
    "numericError": "La valeur doit être numérique.",
    "textMinLength": "Veuillez saisir au moins {0} caractères.",
    "textMaxLength": "Veuillez saisir moins de {0} caractères.",
    "textMinMaxLength": "Veuillez saisir plus de {0} et moins de {1} caractères.",
    "minRowCountError": "Veuillez remplir au moins {0} lignes.",
    "minSelectError": "Veuillez sélectionner au moins {0} variantes.",
    "maxSelectError": "Veuillez ne pas sélectionner plus de {0} variantes.",
    "numericMinMax": "Le '{0}' doit être égal ou supérieur à {1} et égal ou inférieur à {2}",
    "numericMin": "Le '{0}' doit être égal ou supérieur à {1}",
    "numericMax": "Le '{0}' doit être égal ou inférieur à {1}",
    "invalidEmail": "Veuillez entrer une adresse e-mail valide.",
    "invalidExpression": "L'expression : {0} doit retourner 'true'.",
    "urlRequestError": "La requête a renvoyé l'erreur '{0}'. {1}",
    "urlGetChoicesError": "La requête a renvoyé des données vides ou la propriété 'path' est incorrecte",
    "exceedMaxSize": "La taille du fichier ne doit pas dépasser {0}.",
    "otherRequiredError": "Veuillez saisir l'autre valeur.",
    "uploadingFile": "Votre fichier est en cours de téléchargement. Veuillez patienter quelques secondes et réessayer.",
    "loadingFile": "Chargement...",
    "chooseFile": "Choisir le(s) fichier(s)...",
    "confirmDelete": "Voulez-vous supprimer l'enregistrement ?",
    "keyDuplicationError": "Cette valeur doit être unique.",
    "addColumn": "Ajouter une colonne",
    "addRow": "Ajouter une ligne",
    "removeRow": "Supprimer",
    "addPanel": "Ajouter nouveau",
    "removePanel": "Supprimer",
    "choices_Item": "item",
    "matrix_column": "Colonne",
    "matrix_row": "Ligne",
    "savingData": "Les résultats sont enregistrés sur le serveur...",
    "savingDataError": "Une erreur s'est produite et nous n'avons pas pu enregistrer les résultats.",
    "savingDataSuccess": "Les résultats ont été enregistrés avec succès !",
    "saveAgainButton": "Réessayez",
    "timerMin": "min",
    "timerSec": "sec",
    "timerSpentAll": "Vous avez dépensé {0} sur cette page et {1} au total.",
    "timerSpentPage": "Vous avez dépensé {0} sur cette page.",
    "timerSpentSurvey": "Vous avez dépensé {0} au total.",
    "timerLimitAll": "Vous avez dépensé {0} sur {1} sur cette page et {2} sur {3} au total.",
    "timerLimitPage": "Vous avez dépensé {0} sur {1} sur cette page.",
    "timerLimitSurvey": "Vous avez dépensé {0} sur {1} au total.",
    "cleanCaption": "Nettoyer",
    "clearCaption": "Effacer"
  };
  