export class Network {
  static addListeners(ping = () => Promise.resolve(), callback = () => { }) {
    document.addEventListener("DOMContentLoaded", Network.handleConnectionChange(ping, callback));

    window.addEventListener('online', Network.handleConnectionChange(ping, callback));
    window.addEventListener('offline', Network.handleConnectionChange(ping, callback));
  }

  static handleConnectionChange = (pingMethod, callback) => {
    return () => {
      if (navigator.onLine) {
        const webPing = setInterval(
          () => {
              // @TODO use our own server: /_health
            pingMethod().then(() => {
              callback(true);
              return clearInterval(webPing)
            }).catch(() => callback(false))
          }, 500);
        return;
      }
  
      callback(false);
    };
  }
}
