import Config from '../../config';
import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { crediavance_interview } from './interview_crediavance';

export function interview(locale = DEFAULT_LOCALE) {
  // deployment specific pages
  const availablePages = {
    crediavance: crediavance_interview,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showQuestionNumbers: 'off',
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate('general.startSurveyText'),

    showCompletedPage: false,

    pagePrevText: translate('general.pagePrevText'),
    pageNextText: translate('general.pageNextText'),
    completeText: translate('general.pageCompleteText'),

    requiredText: '',
    isSinglePage: false, // Can be set to True for review mode

    pages: currentDeploymentPages,
  };
}
