import {loanState} from './loan.state';

export function loanReducer(state = loanState, action) {
  switch (action.type) {

    case 'GET_LOAN_PENDING':
      return {
        ...state,
        getLoanPending: true,
      };
    case 'LOAN_SUCCESS':
      return {
        ...state,
        getLoanPending: false,
        ...action.data // can be data, intake, intake_guarantor and assessment
      };

    case 'GET_LOAN_DONE':
      return {
        ...state,
        getLoanPending: false,
      };

    case 'COMPLETE_INTAKE':
      return {
        ...state,
        intake: {
          ...state.intake,
          is_completed: true
        }
      };
    case 'COMPLETE_ASSESSMENT':
      return {
        ...state,
        assessment: {
          ...state.assessment,
          is_completed: true
        }
      };

    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };

    case 'SET_GUARANTOR_PAGE':
      return {
        ...state,
        page: action.page,
        state: action.page
     
      };

    case 'RESET_LOAN_STATE':
      return {
        ...loanState
      };

    default:
      return state;
  }
}
