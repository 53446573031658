import env from "../../env";

const required = env.ENDPOINT !== "local";

export const rentamigo_assessment = [
  // Page 1
  {
    title: {
      en: "Cash flow",
      "es-MX": "Flujo de caja",
    },
    elements: [
      {
        type: "expression",
        name: "custom_float_1",
        title: { en: "amount", "es-MX": "Monto solicitado" },
        expression: "fixed({amount})",
        startWithNewLine: false,
        displayStyle: "currency",
        currency: "MXN",
      },
      {
        title: {
          en: "Credit destiny",
          "es-MX": "Destino del credito",
        },
        type: "text",
        isRequired: required,
        name: "custom_text_58",
      },
      {
        title: {
          en: "monthly sell",
          "es-MX": "Ventas mensuales",
        },
        name: "custom_float_2",
        type: "text",
        inputType: "number",
      },
      {
        title: {
          en: "weekly sell",
          "es-MX": "Ventas semanales",
        },
        name: "custom_float_3",
        type: "text",
        inputType: "number",
      },
      {
        title: {
          en: "weekly purchase",
          "es-MX": "Compras semanales negocio",
        },
        name: "custom_float_4",
        type: "text",
        inputType: "number",
      },
      {
        title: {
          en: "Earnings",
          "es-MX": "Ganancia bruta",
        },
        type: "expression",
        name: "custom_float_5",
        expression: "subtract({custom_float_3},{custom_float_4})",
        displayStyle: "decimal",
      },
      {
        title: {
          en: "weekly expenses business",
          "es-MX": "Gastos semanales (negocio)",
        },
        name: "custom_float_6",
        type: "text",
        inputType: "number",
      },
      {
        title: {
          en: "earinings",
          "es-MX": "Lo que gana mi negocio",
        },
        type: "expression",
        name: "custom_float_7",
        expression: "subtract({custom_float_5},{custom_float_6})",
        displayStyle: "decimal",
      },
      {
        title: {
          en: "partner alimony",
          "es-MX": "Sueldo o apoyo pareja",
        },
        name: "custom_float_8",
        type: "text",
        inputType: "number",
      },
      {
        title: {
          en: "weekly expenses family",
          "es-MX": "Gastos semanales (familia)",
        },
        name: "custom_float_9",
        type: "text",
        inputType: "number",
      },
      {
        title: {
          en: "credits payback",
          "es-MX": "Pago de otros créditos",
        },
        name: "custom_float_10",
        type: "text",
        inputType: "number",
      },

      {
        title: {
          en: "payment capacity",
          "es-MX": "Capacidad de pago",
        },
        type: "expression",
        name: "custom_float_11",
        expression:
          "sum({custom_float_7},subtract({custom_float_8},{custom_float_9},{custom_float_10}))",
        displayStyle: "decimal",
      },
      {
        title: {
          en: "Other credits reported",
          "es-MX": "Otros créditos reportados en círculo de crédito",
        },
        type: "text",
        isRequired: required,
        name: "custom_text_61",
      },
      {
        title: {
          en: "General coments",
          "es-MX": "Comentarios generales sobre el solicitante/cliente",
        },
        type: "text",
        isRequired: required,
        name: "custom_text_62",
      },
      {
        title: {
          en: "Paying with legal money",
          "es-MX":
            "¿Los recursos con los que pagará el crédito son de procedencia legal?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_63",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        title: {
          en: "Is the credit for yourself?",
          "es-MX":
            "¿El crédito solicitado será en su beneficio para el uso de una tercera persona?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_64",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        name: "",
        type: "html",
        html: {
          en: "I approve of RENTAMIGO SA DE CV SOFOM ENR to give me the loan",
          "es-MX":
            "<p>Por la presente declaro bajo juramento que toda la información proporcionada a Rentamigo S.A. de C.V. SOFOM ENR es correcta y real, en caso de comprobarse que la misma es falsa, la institución podrá tomar las acciones legales correspondientes por las pérdidas que pudiese ocasionar esta situación. De igual forma me comprometo a informar a Rentamigo S.A. de C.V. SOFOM ENR cuando cambie de domicilio o actividad económica en un plazo no mayor a los 15 días de haberse producido el cambio.</p>",
        },
      },
      {
        title: {
          en: "Name",
          "es-MX": "Nombre del solicitante",
        },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: {
          en: "signature",
          "es-MX": "Firma",
        },
        type: "signaturepad",
        isRequired: required,
        name: "file_7",
      },
    ],
  },
  // Page 2
  {
    title: {
      en: "visit info",
      "es-MX": "Información de la visita",
    },

    elements: [
      {
        html: {
          en: "Group check",
          "es-MX": "Consulta con el círculo",
        },
        type: "html",
      },
      {
        html: {
          en: "Legal disclaimer",
          "es-MX":
            "Para uso exclusivo de la Empresa que efectúa la consulta (RENTAMIGO SA DE CV SOFOM ENR)",
        },
        type: "html",
      },
      {
        title: {
          en: "Meeting date",
          "es-MX": "Fecha de consulta",
        },
        type: "text",
        inputType: "date",
        isRequired: required,
        name: "custom_text_65",
      },
      {
        title: { en: "check reference", "es-MX": "Folio de consulta" },
        type: "text",
        name: "custom_text_66",
        isRequired: required,
      },
      {
        title: { en: "Signature number", "es-MX": "No. De firma" },
        type: "text",
        name: "custom_text_67",
        isRequired: required,
      },
      {
        html: {
          en: "Member data",
          "es-MX": "Ficha de datos cliente",
        },
        type: "html",
      },
      {
        type: "expression",
        title: {
          en: "Date of inquiry",
          "es-MX": "Fecha de envío de solicitud",
        },
        expression: "{custom_text_8}",
        isRequired: required,
      },
      {
        type: "panel",
        elements: [
          {
            title: { en: "Branch", "es-MX": "Sucursal" },
            type: "expression",
            expression: "{custom_text_10}",
          },
          {
            title: {
              en: "Do you want to change the branch?",
              "es-MX": "¿Desea cambiar la sucursal?",
            },
            type: "dropdown",
            name: "change_branch",
            choices: [
              { value: "si", text: { en: "yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "no", "es-MX": "No" } },
            ],
          },
          {
            visibleIf: "{change_branch} == 'si'",
            title: { en: "Branch", "es-MX": "Sucursal" },
            type: "dropdown",
            name: "custom_text_10",
            choices: [
              { value: "Texcoco", text: { en: "Texcoco", "es-MX": "Texcoco" } },
              {
                value: "Azcapotzalco",
                text: { en: "Azcapotzalco", "es-MX": "Azcapotzalco" },
              },
              {
                value: "Tultitlán",
                text: { en: "Tultitlán", "es-MX": "Tultitlán" },
              },
            ],
          },
        ],
      },
      {
        title: { en: "Client id", "es-MX": "ID cliente" },
        type: "text",
        name: "custom_text_76",
        isRequired: required,
      },
      {
        title: {
          en: "Group name",
          "es-MX": "Nombre del grupo",
        },
        name: "custom_text_150", //readonly for co applicants
        type: "text",
        visibleIf: "{custom_bool_10} == false",
        readOnly: true,
      },
      {
        type: "expression",
        expression: "daysFromNow(0, 0, 'es-MX')",
        title: {
          en: "Date visit",
          "es-MX": "Fecha de visita",
        },
        name: "custom_text_77",
        isRequired: required,
      },

      {
        html: {
          en: "Data check",
          "es-MX": "Comprobación de documentos presentados por el cliente",
        },
        type: "html",
      },
      {
        title: { en: "Ine check", "es-MX": "Confirmación INE" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_68",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        title: { en: "Coments", "es-MX": "Comentarios" },
        type: "text",
        name: "custom_text_69",
      },
      {
        title: { en: "Check Home", "es-MX": "Comprobante de domicilio" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_70",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        title: { en: "Coments", "es-MX": "Comentarios" },
        type: "text",
        name: "custom_text_71",
      },
      {
        title: { en: "Check ID", "es-MX": "Confrimación de Identificación " },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_72",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        title: { en: "Coments", "es-MX": "Comentarios" },
        type: "text",
        name: "custom_text_73",
      },
      {
        title: {
          en: "Coments on home and business",
          "es-MX": "Observaciones del domicilio vivienda y negocio",
        },
        type: "text",
        name: "custom_text_74",
        isRequired: required,
      },
      {
        title: {
          en: "Name officer",
          "es-MX": "Nombre del asesor de crédito",
        },
        type: "text",
        name: "custom_text_75",
        isRequired: required,
      },
      {
        title: {
          en: "signature",
          "es-MX": "Firma del asesor de crédito",
        },
        type: "signaturepad",
        isRequired: required,
        name: "file_8",
      },
      {
        title: {
          en: "Pic together",
          "es-MX": "Foto del colaborador con cliente en domicilio",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_12",
      },
      {
        title: {
          en: "Pic with assets",
          "es-MX": "Foto del asesor de crédito con el cliente y la mercancía",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_13",
      },
    ],
  },
];
