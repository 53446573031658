import Config from "../../config";
import {DEFAULT_LOCALE, translate} from "../../lib/intl";
import {select_survey_js_model} from "../../survey/survey.utils";
import {
  intake_banco_popular_pages_existing_client,
  intake_banco_popular_pages_new_client,
} from "./intake_banco_popular";
import {intake_fogec} from "./intake_fogec";
import {intake_crediavance_pages} from "./intake_crediavance";
import {intake_finadev_pages} from "./intake_finadev";
import {intake_procredito_pages_agro, intake_procredito_pages_transport,} from "./intake_procredito";
import {intake_rw_pages_business, intake_rw_pages_farming} from "./intake_rw";
import {
  intake_rwanda_direct_model_pages_chili,
  intake_rwanda_direct_model_pages_default,
  intake_rwanda_direct_model_pages_tea
} from "./intake_rwanda_direct_model";
import {intake_rentamigo_pages} from "./intake_rentamigo";
import {applicant_bll} from "./pages/bll/01_applicant_bll";
import {identification_bll} from "./pages/bll/02_identification_bll";
import {residence_home_bll} from "./pages/bll/03_residence_home_bll";
import {production_bll} from "./pages/bll/04_production_bll";
import {guarantor_bll} from "./pages/bll/05_guarantor_bll";
import {
  applicant_credicapital_new_client,
  applicant_credicapital_recurring_client
} from "./pages/credicapital/01_applicant_credicapital";
import {
  identification_credicapital_new_client,
  identification_credicapital_recurring_client
} from "./pages/credicapital/02_identification_credicapital";
import {marital_status_credicapital} from "./pages/credicapital/03_marital_status";
import {beneficiary_credicapital} from "./pages/credicapital/04_beneficiary_credicapital";
import {credit_credicapital} from "./pages/credicapital/05_credit_credicapital";
import {guarantor_credicapital} from "./pages/credicapital/06_guarantor_credicapital";
import {job_information_credicapital} from "./pages/credicapital/07_job_information_credicapital";
import {additional_info_credicapital} from "./pages/credicapital/08_additional_info_credicapital";
import {applicant_essentials} from "./pages/essentials/01_applicant_essentials";
import {identification_essentials} from "./pages/essentials/02_identification_essentials";
import {guarantor_essentials} from "./pages/essentials/03_guarantor_essentials";
import {applicant_ffdl} from "./pages/ffdl/01_applicant_ffdl";
import {work_details_ffdl} from "./pages/ffdl/02_work_details_ffdl";
import {loan_details_ffdl} from "./pages/ffdl/03_loan_details_ffdl";
import {insurance_ffdl} from "./pages/ffdl/04_insurance_ffdl";
import {economic_ffdl} from "./pages/ffdl/05_economic_ffdl";
import {guarantor_collateral_ffdl} from "./pages/ffdl/06_guarantor_collateral_ffdl";
import {references_ffdl} from "./pages/ffdl/07_references_ffdl";
import {intake_demo_pages} from "./intake_demo";
import {business_intake_mfw, education_intake_mfw} from "./intake_mfw";

export function intake(
  locale = DEFAULT_LOCALE,
  loan_purpose = "default",
  purpose = null,
  api_url = ""
) {
  const mfw_pages = {
    default: business_intake_mfw,
    business: business_intake_mfw,
    education: education_intake_mfw
  };

  const bll_pages = [
    applicant_bll,
    identification_bll,
    residence_home_bll,
    production_bll,
    guarantor_bll,
  ];

  const credicapital_new_client_pages = [
    applicant_credicapital_new_client(api_url),
    identification_credicapital_new_client(api_url),
    marital_status_credicapital(api_url),
    beneficiary_credicapital(api_url),
    credit_credicapital(api_url),
    guarantor_credicapital(api_url),
    job_information_credicapital(api_url),
    additional_info_credicapital(api_url)
  ];

  const credicapital_recurring_client_pages = [
    applicant_credicapital_recurring_client(api_url),
    identification_credicapital_recurring_client(api_url),
    marital_status_credicapital(api_url),
    beneficiary_credicapital(api_url),
    credit_credicapital(api_url),
    guarantor_credicapital(api_url),
    job_information_credicapital(api_url),
    additional_info_credicapital(api_url)
  ];

  const credicapital_pages = {
    default: credicapital_new_client_pages,
    new_client: credicapital_new_client_pages,
    recurring_client: credicapital_recurring_client_pages
  };

  const essentials_pages = [
    applicant_essentials,
    identification_essentials,
    guarantor_essentials,
  ];

  const ffdl_pages = [
    applicant_ffdl,
    work_details_ffdl,
    loan_details_ffdl,
    insurance_ffdl,
    economic_ffdl,
    guarantor_collateral_ffdl,
    references_ffdl,
  ];

  const procredito_pages = {
    default: intake_procredito_pages_transport(api_url),
    agro: intake_procredito_pages_agro(api_url),
    transport: intake_procredito_pages_transport(api_url),
  };

  const banco_popular_pages = {
    new_client: intake_banco_popular_pages_new_client,
    existing_client: intake_banco_popular_pages_existing_client,
  };

  const rw_pages = {
    default: intake_rw_pages_business(api_url),
    business: intake_rw_pages_business(api_url),
    farming: intake_rw_pages_farming(api_url),
  };

  const rwanda_direct_model_pages = {
    default: intake_rwanda_direct_model_pages_default(api_url),
    carrots: intake_rwanda_direct_model_pages_default(api_url),
    chili: intake_rwanda_direct_model_pages_chili(api_url),
    potatoes: intake_rwanda_direct_model_pages_default(api_url),
    tea: intake_rwanda_direct_model_pages_tea(api_url)
  }

  const availablePages = {
    banco_popular: select_survey_js_model(loan_purpose, banco_popular_pages),
    bll: bll_pages,
    crediavance: intake_crediavance_pages,
    credicapital: select_survey_js_model(loan_purpose, credicapital_pages, purpose),
    demo: intake_demo_pages,
    essentials: essentials_pages,
    ffdl: ffdl_pages,
    fogec: intake_fogec,
    finadev: intake_finadev_pages,
    mfw: select_survey_js_model(loan_purpose, mfw_pages),
    procredito: select_survey_js_model(loan_purpose, procredito_pages),
    rentamigo: intake_rentamigo_pages,
    rw: select_survey_js_model(loan_purpose, rw_pages),
    rwanda_direct_model: select_survey_js_model(loan_purpose, rwanda_direct_model_pages)    
  };

  const currentDeploymentPages = availablePages[Config.deployment] || [];

  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),

    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageNextText"), // note same as `Next`

    requiredText: "",
    isSinglePage: false, // Can be set to True for review mode

    pages: currentDeploymentPages,
    locale,
  };
}
