/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */

export const sign = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: false,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: { en: "Sign contract", ar: "توقيع العقد" },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and sign your loan contract",
        ar: "قم بمراجعة وتوقيع العقد",
      },
      name: "contract",
      elements: [
        {
          title: {
            en: "Your final loan terms are:",
            ar: ":شروط عقدك النهائية هي",
          },
          name: "terms",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression:
                "calcLoanterms({amount}, {repayment_period}, {monthly_insurance})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Approved loan amount",
                ar: "قيمة التمويل الموافق عليها",
              },
              type: "expression",
              expression: "fixed({amount}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "JOD",
            },

            {
              title: {
                en: "Monthly administrative fees",
                ar: "المصاريف الإدارية الشهرية",
              },
              type: "expression",
              expression:
                "(getLoanTerm({loan_terms}, 'monthly_administrative_fees') * 100) + '%'", // Floating Point Proof
            },

            {
              title: {
                en: "Approved loan term",
                ar: "مدة التمويل الموافق عليها",
              },
              description: {
                ar: "عدد الأشهر",
                en: "in months",
              },
              type: "expression",
              expression: "{repayment_period}", // Floating Point Proof
            },

            {
              title: {
                en: "Total administrative fees",
                ar: "مجمل المصاريف الإدارية",
              },
              type: "expression",
              expression: "fixed({total_fees}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "JOD",
            },

            {
              title: {
                en: "Approved monthly installment",
                ar: "الدفعة الشهرية المتفق عليها",
              },
              type: "expression",
              expression: "fixed({monthly_installment}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "JOD",
            },

            {
              title: {
                en: "Approved first installment date",
                ar: "تاريخ الدفعة الأولى الموافق عليها",
              },
              type: "expression",
              expression: "{start_repayment_date_translated}", // Floating Point Proof
            },

            {
              title: {
                en: "Insurance fees to be added to the monthly installment",
                ar: "رسوم التأمين والتي سيتم إضافتها للدفعة الشهرية",
              },
              type: "expression",
              expression: "fixed({monthly_insurance}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "JOD",
            },

            {
              title: {
                en: "Sales tax (to be deducted from the approved loan)",
                ar: "ضريبة المبيعات والتي سيتم اقتطاعها من التمويل الموافق عليه",
              },
              type: "expression",
              expression: "fixed(getLoanTerm({loan_terms}, 'sales_tax'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "JOD",
            },

            {
              title: {
                en: "Stamp (to be deducted from the approved loan)",
                ar: "الطوابع والتي سيتم اقتطاعها من قيمة التمويل الموافق عليه",
              },
              type: "expression",
              expression: "fixed(getLoanTerm({loan_terms}, 'stamp'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "JOD",
            },

            {
              title: " ",
              type: "checkbox",
              name: "agree_final_loan_terms",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", ar: "أنا أوافق" } },
              ],
              validators: [],
            },

            {
              type: "html",
              name: "penalty",
              html: {
                en: "To ensure that you are serious about a timely monthly payment, you will be charged 0.5 JOD for every day you are late with payment.",
                ar: "للتأكد من جديتك بسداد الدفعة الشهرية في وقتها المحدد، سيتم فرض غرامة مالية قيمتها نصف دينار أردني عن كل يوم تأخير في الدفع",
              },
            },

            {
              type: "html",
              name: "loan_fees",
              html: {
                en: "During {repayment_period} months, the loan will be repaid in full. For this loan the total fees will be {total_fees} JD.",
                ar: "خلال {repayment_period} شهراً, وسيتم سداد التمويل كاملاً. قيمة الرسوم الكاملة لهذا التمويل هي {total_fees} ",
              },
            },
          ],
        },

        {
          title: { en: "Your signature", ar: "توقيعك" },
          name: "signature",
          type: "panel",
          innerIndent: 1,

          elements: [
            {
              title: {
                en: "My name is {first_name} {last_name} and my ID number is {id_number}.  I confirm that the information given in this form is true, complete and accurate.",
                ar: "أنا {first_name} {last_name} أحمل هوية رقم {id_number} أقر بأن المعلومات المسجلة في هذا الطلب صحيحة وكاملة ودقيقة.",
              },
              type: "checkbox",
              name: "truth",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", ar: "أنا أوافق" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "The loan amount of {amount} JD that you want to borrow is reasonable and responsible considering your personal situation.",
                ar: "إن قيمة التمويل   {amount} دينار أردني هي منطقية ومناسبة مقارنة بوضعك الشخصي",
              },
              type: "checkbox",
              name: "responsible",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", ar: "أنا أوافق" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "I understand the risks involved and will guarantee the repayment of the loan.",
                ar: "أدرك المخاطر المحتملة المترتبة على هذا التمويل  وأتعهد بسداد التمويل كاملاً",
              },
              type: "checkbox",
              name: "agree",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", ar: "أنا أوافق" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "Are you sure that you agree on the loan terms and conditions?",
                ar: "هل أنت متأكد من موافقتك على شروط وأحكام التمويل؟",
              },
              type: "checkbox",
              name: "agree_toc",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", ar: "أنا أوافق" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "Please upload a video or audio file of yourself",
                ar: "حمل الفيديو أو التسجيل الصوتي الخاص بك",
              },
              description: {
                en: "Please upload a video or audio file of yourself in which you agree to the loan terms and conditions.",
                ar: "قم رجاءاً بتحميل فيديو أو تسجيل صوتي لك تقوم فيه بالموافقة على شروط وأحكام هذا التمويل ",
              },
              name: "i_agree_on_video",
              type: "file_uploader",
              accept: "video/*,audio/*",
              isRequired: true,
            },

            {
              type: "signaturepad",
              name: "signature",
              isRequired: true,
              title: {
                en: "Please enter your signature",
                ar: "الرجاء قم بالتوقيع في المربع في الأسفل",
              },
            },

            {
              type: "text",
              inputType: "text",
              name: "sign_name",
              isRequired: true,
              title: {
                en: "Applicant Full Name",
                ar: "اسم مقدّم الطلب الكامل (الاسم الرباعي)",
              },
            },

            {
              type: "text",
              inputType: "date",
              name: "sign_date",
              isRequired: true,
              title: {
                en: "Application Date",
                ar: "تاريخ الطلب",
              },
            },
          ],
        },
      ],
    },
  ],
};
