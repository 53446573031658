import { api, setupAuthToken } from "../../app";
import Config from "../../config";

async function _completeLogin(dispatch, res, type) {
  // set token in api so we can make auth calls again
  api.token = {
    access_token: res.access_token,
    expires_in: res.expires_in,
    refresh_token: res.refresh_token,
    scope: res.scope,
    token_type: res.token_type,
  };

  if (Config.uses_access_token_for_ChoicesRestfull) {
    await setupAuthToken(res.access_token);
  }

  dispatch({
    type: "GET_ACCOUNT_SUCCESS",
    profile: res.profile,
  });

  dispatch({
    type: "SET_AUTHENTICATION_TOKEN",
    token: {
      access_token: res.access_token,
      expires_in: res.expires_in,
      refresh_token: res.refresh_token,
      scope: res.scope,
      token_type: res.token_type,
    },
  });

  dispatch({
    type: "LOGIN_SUCCESS",
  });

  return res.profile;
}

export function loginWithEmail(email, password, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LOGIN_EMAIL_PENDING",
      });
      let res = await api.post("auth/login.json", {
        utm_campaign: utm_campaign,
        grant_type: "password",
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        email: email,
        password: password,
      });
      return await _completeLogin(dispatch, res, "email_login");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error,
      });
    }
  };
}

export function loginWithPasswordResetLink(
  email,
  password,
  password_reset_code,
  utm_campaign = ""
) {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "LOGIN_EMAIL_PENDING",
      });
      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        grant_type: "password_link",
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        email: email,
        password: password,
        password_reset_code: password_reset_code,
      });
      return await _completeLogin(dispatch, res, "password_link");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error,
      });
    }
  };
}

export function loginWithPasswordMagicLink(
  email,
  password,
  magic_link_code,
  utm_campaign = ""
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LOGIN_EMAIL_PENDING",
      });
      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        grant_type: "magic_link",
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        email: email,
        password: password,
        magic_link_code: magic_link_code,
      });
      return await _completeLogin(dispatch, res, "magic_link");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error,
      });
    }
  };
}

export function loginWithAccessToken(access_token, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LOGIN_EMAIL_PENDING",
      });
      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        grant_type: "access_token",
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        access_token: access_token,
      });
      return await _completeLogin(dispatch, res, "access_token");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error,
      });
    }
  };
}

export function signupWithEmail(firstName, email, password, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SIGNUP_EMAIL_PENDING",
      });
      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        grant_type: "email",
        email: email,
        password: password,
        first_name: firstName,
      });
      return await _completeLogin(dispatch, res, "email_login");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error,
      });
    }
  };
}

export function loginWithPlugin(uuid, token, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LOGIN_PLUGIN_PENDING",
      });
      let res = await api.postPlugin(
        "/login.json",
        {
          utm_campaign: utm_campaign,
          grant_type: "api",
          client_secret: api.endpoint.client_secret,
          client_id: api.endpoint.client_id,
          uuid: uuid,
        },
        token
      );
      return await _completeLogin(dispatch, res, "plugin_login");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error: error,
      });
    }
  };
}

export function loginWithFacebook(access_token, payload, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SIGNUP_FACEBOOK_PENDING",
      });
      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        grant_type: "facebook",
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        access_token: access_token,
        payload: payload,
      });
      return await _completeLogin(dispatch, res, "facebook_login");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error: error,
      });
    }
  };
}

export function loginWithFacebookMessenger(payload, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SIGNUP_FACEBOOK_PENDING",
      });

      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        grant_type: "fb_messenger",
        client_id: api.endpoint.client_id,
        client_secret: api.endpoint.client_secret,
        payload: payload,
      });

      return await _completeLogin(dispatch, res, "facebook_messenger_login");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error: error,
      });
    }
  };
}

export function signupWithGoogle(access_token, payload, utm_campaign = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SIGNUP_GOOGLE_PENDING",
      });
      let res = await api.post("auth/signup.json", {
        utm_campaign: utm_campaign,
        grant_type: "google",
        client_secret: api.endpoint.client_secret,
        client_id: api.endpoint.client_id,
        access_token: access_token,
        payload: payload,
      });
      return await _completeLogin(dispatch, res, "google_login");
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error: error,
      });
    }
  };
}

export function sendPasswordReset(email) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "PASSWORD_RESET_PENDING",
      });
      await api.post("/user/reset_password.json", { email: email });
      dispatch({
        type: "PASSWORD_RESET_SUCCESS",
      });
    } catch (error) {
      dispatch({
        type: "PASSWORD_RESET_SUCCESS",
        error,
      });
    }
  };
}

export function logout(refresh_token) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LOGOUT_PENDING",
      });

      await api.post("auth/logout.json", { refresh_token: refresh_token });

      dispatch({
        type: "RESET_AUTHENTICATION_STATE",
      });
      dispatch({
        type: "RESET_ACCOUNT_STATE",
      });
      dispatch({
        type: "RESET_DEVICE_STATE",
      });
    } catch (error) {
      dispatch({
        type: "RESET_AUTHENTICATION_STATE",
      });
      dispatch({
        type: "RESET_ACCOUNT_STATE",
      });
      dispatch({
        type: "RESET_DEVICE_STATE",
      });
    }
  };
}

export function resetStates() {
  return async (dispatch) => {
    // alsow reload screen after resetting states
    setTimeout(() => window.location.reload(), 500);

    dispatch({
      type: "RESET_AUTHENTICATION_STATE",
    });
    dispatch({
      type: "RESET_ACCOUNT_STATE",
    });
    dispatch({
      type: "RESET_DEVICE_STATE",
    });
  };
}

// End of App
