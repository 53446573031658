import "../../intake.container.scss";
import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";
const required = env.ENDPOINT !== "local";

export const work_details_ffdl = new SurveyComponent({
  title: {
    en: "Applicant's work details",
    "es-GT": "Datos labores del solicitante",
  },
  name: "work_details",
  elements: [
    {
      title: {
        en: "Company name *",
        "es-GT": "Nombre de empresa *",
      },
      name: "current_job_company",
      isRequired: required,
      type: "text",
    },
    {
      title: {
        en: "Religious Organisation ? *",
        "es-GT": "¿La empresa es parte de las obras de la Compañía de Jesús?*",
      },
      name: "custom_bool_1",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: true, text: { en: "Yes", "es-GT": "Si" } },
        { value: false, text: { en: "No", "es-GT": "No" } },
      ],
    },
    {
      title: {
        en: "Job position *",
        "es-GT": "Cargo que ocupa *",
      },
      name: "current_job_position",
      isRequired: required,
      type: "text",
    },
    {
      title: {
        en: "How long have you been working here? *",
        "es-GT": "Tiempo de laborar *",
      },
      name: "current_job_duration",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Type of company *",
        "es-GT": "Tipo de empresa *",
      },
      name: "custom_text_27",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "Privada", text: { en: "Private", "es-GT": "Privada" } },
        { value: "Pública", text: { en: "State", "es-GT": "Pública" } },
      ],
    },
    {
      title: {
        en: "Work phone no. *",
        "es-GT": "Teléfono de empresa *",
      },
      name: "current_job_phone",
      type: "phone",
      isRequired: required,
      validators: [
        expressions.phone_en("current_job_phone"),
        expressions.phone_length("current_job_phone"),
      ],
    },
    {
      title: {
        en: "Work Address *",
        "es-GT": "Dirección de la empresa *",
      },
      name: "current_job_address",
      type: "text",
      isRequired: required,
    },
  ],
});
