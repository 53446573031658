import env from "../../env";
const required = env.ENDPOINT !== "local";

export const review_fogec = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "fr-CD": "Soumettre la demande",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign",
        "fr-CD": "Vérifiez et signez"
      },
      elements: [
        {
          type: "panel",
          elements: [
            {
              type: "expression",
              title: {
                en: "CUSTOMER NAME",
                "fr-CD": "NOM COMPLET",
              },
              expression: `getFullName({first_name},{last_name})`,
            },
            {
              type: "expression",
              expression: "age({date_of_birth})",
              title: { en: "AGE", "fr-CD": "ÂGE" },
            },
            {
              type: "expression",
              expression: "{amount}",
              title: {
                en: "AMOUNT REQUESTED",
                "fr-CD": "MONTANT",
              },
              displayStyle: "currency",
              currency: "USD",
            },
            {
              type: "expression",
              expression: "{charge_ref_dict.interest_rate.amount} + '%'",
              title: {
                en: "INTEREST RATE",
                "fr-CD": "TAUX D'INTÉRÊT",
              },
            },
            {
              type: "expression",
              expression: "{repayment_period}",
              title: {
                en: "REPAYMENT PERIOD",
                "fr-CD": "ECHÉANCIER"
              }
            },
          ],
        },
        {
          title: { en: "Your signature", "fr-CD": "Votre signature" },
          type: "panel",
          name: "Votre signature",
          elements: [
            {
              type: "checkbox",
              name: "confirm_4",
              isRequired: required,
              storeOthersAsComment: false,
              choices: [
                {
                  value: "Je suis d'accord",
                  text: { en: "I agree", "fr-CD": "Je suis d'accord" },
                },
              ],
              title: {
                en: "My name is {first_name} and my ID number is {custom_text_9}. I confirm that the information given in this form is true, complete and accurate.",
                "fr-CD":
                  "Mon nom est {first_name} et mon numéro d'identification est {custom_text_9}. Je confirme que les informations fournies sur ce formulaire sont vraies, complètes et exactes.",
              },
            },
            {
              title: {
                en: "Sign loan application",
                "fr-CD": "Signez la demande",
              },
              type: "signaturepad",
              isRequired: true,
              name: "signature",
            },
          ],
        },
      ],
    },
  ],
};
