import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const guarantor_crediavance = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicación",
  },
  questionStartIndex: 1,
  requiredText: "",
  pages: [
    {
      name: "guarantor_acceptance",
      title: {
        en: "Guarantor acceptance",
        "es-MX": "Aceptación del Obligado Solidario",
      },
      innerIndent: 1,
      elements: [
        {
          type: "radiogroup",
          title: {
            en: "Guarantor acceptance",
            "es-MX": "Aceptación del fiador",
          },
          titleLocation: "hidden",
          name: "guarantor_refused",
          isRequired: required,
          colCount: 2,
          choices: [
            {
              value: false,
              text: { en: "I accept", "es-MX": "Acepto" },
            },
            {
              value: true,
              text: { en: " I don't accept", "es-MX": "No acepto" },
            },
          ],
        },
      ],
    },
    {
      title: {
        en: "Third party request ",
        "es-MX": "Solicitud del tercero(obligado)",
      },
      name: "guarantor_intake",
      elements: [
        {
          title: { en: "Branch office", "es-MX": "Sucursal" },
          type: "dropdown",
          name: "custom_text_1",
          isRequired: required,
          choices: [
            {
              value: "obregon",
              text: { en: "Obregon", "es-MX": "Obregón" },
            },
            {
              value: "los_mochis",
              text: { en: "Los Mochis", "es-MX": "Los Mochis" },
            },
          ],
        },
        {
          title: { en: "Application date", "es-MX": "Fecha de solicitud" },
          type: "expression",
          name: "custom_text_2",
          isRequired: required,
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          title: { en: "Nationality", "es-MX": "Nacionalidad" },
          type: "radiogroup",
          name: "custom_text_25",
          isRequired: required,
          choices: [
            {
              value: "Nacionalidad mexicana",
              text: {
                en: "Mexican nationality",
                "es-MX": "Nacionalidad mexicana",
              },
            },
            {
              value: "Nacionalidad extranjera con residencia permanente",
              text: {
                en: "Foreign nationality with permanent residence",
                "es-MX": "Nacionalidad extranjera con residencia permanente",
              },
            },
            {
              value: "Nacionalidad extranjera con residencia temporal",
              text: {
                en: "Foreign nationality with temporary residence",
                "es-MX": "Nacionalidad extranjera con residencia temporal",
              },
            },
          ],
        },
        {
          title: { en: "Jointly obligated", "es-MX": "Obligado solidario" },
          type: "panel",
          description: {
            en: "It is not mandatory, but verification is done with the buro in case of having this information",
            "es-MX":
              "No es obligatorio, pero si se hace verificación con el buró en caso de tener esta información",
          },
          elements: [],
        },
        {
          title: { en: "Name", "es-MX": "Nombre" },
          type: "text",
          name: "first_name",
          isRequired: required,
        },
        {
          title: { en: "Second name", "es-MX": "Segundo nombre" },
          type: "text",
          name: "middle_name",
          isRequired: required,
        },
        {
          title: { en: "Last name", "es-MX": "Apellido paterno" },
          type: "text",
          name: "surname",
          isRequired: required,
        },
        {
          title: { en: "Mother's last name", "es-MX": "Apellido materno" },
          type: "text",
          name: "second_surname",
          isRequired: required,
        },
        {
          title: { en: "Curp", "es-MX": "Curp" },
          type: "text",
          name: "custom_text_3",
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct CURP of 18 characters",
                "es-MX": "CURP 18 dígitos",
              },
              regex: `^[a-zA-Z]{4}[0-9]{6}[a-zA-Z]{6}[0-9]{2}$`,
            },
          ],
          placeHolder: "HEGG560427MVZRRL04",
        },
        {
          title: { en: "RFC", "es-MX": "RFC" },
          type: "text",
          name: "custom_text_4",
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct Tax Id Number (RFC) of 13 characters",
                "es-MX": "RFC 13 dígitos",
              },
              regex: `^[A-Za-z]{4}[ |-]{0,1}[0-9]{6}[ |-]{0,1}[0-9A-Za-z]{3}$`,
            },
          ],
          placeHolder: "VECJ880326XXX",
        },
        {
          title: { en: "Sex", "es-MX": "Sexo" },
          type: "dropdown",
          isRequired: required,
          name: "gender",
          choices: [
            {
              value: "masculino",
              text: { en: "Masculine", "es-MX": "Masculino" },
            },
            {
              value: "femenino",
              text: { en: "Feminine", "es-MX": "Femenino" },
            },
          ],
        },
        {
          title: { en: "Date of birth", "es-MX": "Fecha de nacimiento" },
          type: "text",
          name: "date_of_birth",
          isRequired: required,
          inputType: "date",
          validators: [
            {
              type: "expression",
              text: {
                en: "You must be between 21 and 70 years of age.",
                "es-MX": "Debes tener entre 21 y 70 años de edad.",
              },
              expression:
                "age({date_of_birth}) >= 21 && age({date_of_birth}) <= 70",
            },
          ],
        },
        {
          title: { en: "Age", "es-MX": "Edad" },
          type: "expression",
          name: "custom_integer_1",
          isRequired: required,
          expression: "age({date_of_birth})",
        },
        {
          title: {
            en: "Federal entity of birth",
            "es-MX": "Entidad federativa de nacimiento",
          },
          type: "text",
          name: "custom_text_5",
          isRequired: required,
        },
        {
          title: { en: "Country of birth", "es-MX": "País de nacimiento" },
          type: "text",
          name: "home_country",
          isRequired: required,
        },
        {
          title: { en: "Nationality", "es-MX": "Nacionalidad" },
          type: "text",
          name: "nationality",
          isRequired: required,
        },
        {
          title: {
            en: "Occupation / profession / activity",
            "es-MX": "Ocupación/profesión/actividad",
          },
          type: "text",
          name: "custom_text_6",
          isRequired: required,
        },
        {
          title: { en: "Address", "es-MX": "Domicilio" },
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              title: {
                en: "Street (post or block)",
                "es-MX": "Calle (poste o manzana)",
              },
              type: "text",
              name: "home_street",
              isRequired: required,
            },
            {
              title: { en: "N. exterior", "es-MX": "N. exterior" },
              type: "text",
              inputType: "number",
              name: "custom_text_7",
              isRequired: required,
            },
            {
              title: { en: "N. interior", "es-MX": "N. interior" },
              type: "text",
              inputType: "number",
              name: "custom_text_8",
              isRequired: required,
            },
            {
              title: { en: "Settlement", "es-MX": "Colonia" },
              type: "text",
              name: "custom_text_9",
              isRequired: required,
            },
            {
              title: { en: "Postal Code", "es-MX": "Código postal" },
              type: "text",
              inputType: "number",
              name: "custom_text_10",
              isRequired: required,
            },
            {
              title: { en: "Municipality", "es-MX": "Municipio" },
              type: "text",
              name: "custom_text_11",
              isRequired: required,
            },
            {
              title: {
                en: "City or population",
                "es-MX": "Ciudad o población",
              },
              type: "text",
              name: "custom_text_12",
              isRequired: required,
            },
            {
              title: { en: "State", "es-MX": "Estado" },
              type: "text",
              name: "custom_text_13",
              isRequired: required,
            },
            {
              title: { en: "Country", "es-MX": "País" },
              type: "text",
              name: "custom_text_14",
              isRequired: required,
            },
          ],
        },
        {
          title: {
            en: "Home phone (include ada)",
            "es-MX": "Tel. particular (incluir lada)",
          },
          type: "phone",
          name: "custom_text_15",
          validators: [
            expressions.phone_en("custom_text_15"),
            expressions.phone_length("custom_text_15"),
          ],
          isRequired: required,
        },
        {
          title: {
            en: "Cell phone (include ada)",
            "es-MX": "Tel. celular (incluir lada)",
          },
          type: "phone",
          name: "phone_number",
          validators: [
            expressions.phone_en("phone_number"),
            expressions.phone_length("phone_number"),
          ],
          isRequired: required,
        },
        {
          title: { en: "Cell company", "es-MX": "Compañía celular" },
          type: "dropdown",
          name: "custom_text_28",
          isRequired: required,
          hasOther: true,
          otherText: { en: "Other", "es-MX": "Otra" },
          choices: [
            { value: "telcel", text: { en: "Telcel", "es-MX": "Telcel" } },
            {
              value: "movistar",
              text: { en: "Movistar", "es-MX": "Movistar" },
            },
            { value: "AT&T", text: { en: "AT&T", "es-MX": "AT&T" } },
            { value: "unefon", text: { en: "Unefon", "es-MX": "Unefon" } },
            {
              value: "virgin mobile",
              text: { en: "Virgin Mobile", "es-MX": "Virgin Mobile" },
            },
          ],
        },
        {
          title: { en: "Scholarship", "es-MX": "Escolaridad" },
          type: "dropdown",
          name: "custom_text_17",
          isRequired: required,
          choices: [
            { value: "primaria", text: { en: "Primary", "es-MX": "Primaria" } },
            {
              value: "secundaria",
              text: { en: "High School", "es-MX": "Secundaria" },
            },
            {
              value: "preparatoria",
              text: { en: "Prep School", "es-MX": "Preparatoria" },
            },
            {
              value: "carrera técnica",
              text: { en: "Technical career", "es-MX": "Carrera técnica" },
            },
            {
              value: "carrera profesional",
              text: {
                en: "Professional Career",
                "es-MX": "Carrera profesional",
              },
            },
          ],
        },
        {
          title: { en: "Regiment of goods", "es-MX": "Régimen de bienes" },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_18",
          choices: [
            {
              value: "Mancomunados",
              text: { en: "Joint", "es-MX": "Mancomunados" },
            },
            {
              value: "Separados",
              text: { en: "separated", "es-MX": "Separados" },
            },
          ],
        },
        {
          title: { en: "Housing type", "es-MX": "Tipo de vivienda" },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_19",
          hasOther: true,
          otherText: { en: "Other", "es-MX": "Otro" },
          choices: [
            { value: "propia", text: { en: "Own", "es-MX": "Propia" } },
            {
              value: "financiada",
              text: { en: "Financed", "es-MX": "Financiada" },
            },
            {
              value: "rentada_con_aval",
              text: {
                en: "Rented (with endorsement)",
                "es-MX": "Rentada (con aval)",
              },
            },
            {
              value: "rentada_sin_aval",
              text: {
                en: "Rented (without endorsement)",
                "es-MX": "Rentada (sin aval)",
              },
            },
            { value: "familiar", text: { en: "Family", "es-MX": "Familiar" } },
          ],
        },
        {
          title: { en: "Owner's name", "es-MX": "Nombre del propietario" },
          visibleIf: "{custom_text_19} == 'familiar'",
          type: "text",
          name: "custom_text_20",
          isRequired: required,
        },
        {
          title: { en: "Time to reside", "es-MX": "Tiempo de residir" },
          visibleIf: "{custom_text_19} == 'familiar'",
          type: "text",
          name: "custom_text_21",
          isRequired: required,
        },
        {
          title: { en: "Share", "es-MX": "Cuota" },
          visibleIf: "{custom_text_19} == 'familiar'",
          type: "text",
          name: "custom_text_22",
          isRequired: required,
        },
        {
          title: {
            en: "Suggested time to call ",
            "es-MX": "Horario sugerido para llamadas",
          },
          type: "text",
          name: "custom_text_23",
          isRequired: required,
        },
      ],
    },
    {
      title: {
        en: "Economical information",
        "es-MX": "Información económica",
      },
      elements: [
        {
          title: {
            en: "Heritage situation",
            "es-MX": "Situación patrimonial",
          },
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              title: { en: "Current assets", "es-MX": "Activo circulante" },
              type: "text",
              inputType: "number",
              name: "custom_float_1",
              isRequired: required,
            },
            {
              title: { en: "Fixed asset", "es-MX": "Activo fijo " },
              type: "text",
              inputType: "number",
              name: "custom_float_2",
              isRequired: required,
            },
            {
              title: { en: "Passives", "es-MX": "Pasivos" },
              type: "text",
              inputType: "number",
              name: "custom_float_3",
              isRequired: required,
            },
            {
              title: { en: "Heritage", "es-MX": "Patrimonio" },
              type: "expression",
              expression:
                "fixed(sum({custom_float_1}, {custom_float_2}, {custom_float_3}), 2)",
              name: "custom_float_4",
              displayStyle: "currency",
              currency: "MXN",
            },
          ],
        },
        {
          title: {
            en: "Income and expenses",
            "es-MX": "Ingresos y gastos",
          },
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              title: { en: "Business income", "es-MX": "Ingreso de negocio" },
              type: "text",
              inputType: "number",
              name: "custom_float_5",
              isRequired: required,
            },
            {
              title: { en: "Other income", "es-MX": "Otros ingresos" },
              type: "text",
              inputType: "number",
              name: "custom_float_6",
              isRequired: required,
            },
            {
              title: {
                en: "Business and family expenses",
                "es-MX": "Gastos de negocio y familiares",
              },
              type: "text",
              inputType: "number",
              name: "custom_float_7",
              isRequired: required,
            },
            {
              title: {
                en: "Payment of debts",
                "es-MX": "Pago de deudas",
              },
              type: "text",
              inputType: "number",
              name: "custom_float_8",
              isRequired: required,
            },
            {
              title: { en: "Available balance", "es-MX": "Saldo disponible" },
              type: "expression",
              expression:
                "fixed(sum({custom_float_5}, {custom_float_6}, {custom_float_7}, {custom_float_8}), 2)",
              name: "custom_float_9",
              displayStyle: "currency",
              currency: "MXN",
            },
          ],
        },
        {
          html: {
            en: "If you do not have the document to be signed with you, you can download it at the following <a href='https://09c9bb80-84a6-431d-b48f-e85deff2d025.usrfiles.com/ugd/09c9bb_fd0411c68beb4d1e8e9472f8cd6ecd10.pdf' target='_blank' rel='noopener noreferrer'>link</a>..",
            "es-MX":
              "Si no tienes contigo el documento para firmar, puedes descargarlo en el siguiente <a href='https://09c9bb80-84a6-431d-b48f-e85deff2d025.usrfiles.com/ugd/09c9bb_fd0411c68beb4d1e8e9472f8cd6ecd10.pdf' target='_blank' rel='noopener noreferrer'>enlace</a>.",
          },
          type: "html",
        },
      ],
    },
    {
      title: {
        en: "Third party Annexes",
        "es-MX": "Anexos Terceros",
      },
      elements: [
        {
          title: { en: "Credit request", "es-MX": "Solicitud de crédito" },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_1",
        },
        {
          title: {
            en: "Receipt (no more than three months)",
            "es-MX": "Recibo (no mayor a tres meses)",
          },
          type: "radiogroup",
          name: "custom_text_24",
          choices: [
            { value: "CFE", text: { en: "CFE", "es-MX": "CFE" } },
            { value: "AGUA", text: { en: "AGUA", "es-MX": "AGUA" } },
            {
              value: "TEL. FIJA",
              text: { en: "TEL. FIJA", "es-MX": "TEL. FIJA" },
            },
            {
              value: "CABLE O INTERNET",
              text: { en: "CABLE OR INTERNET", "es-MX": "CABLE O INTERNET" },
            },
          ],
        },
        {
          title: { en: "Receipt", "es-MX": "Recibo" },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          visibleIf: "{custom_text_1} notempty",
          name: "file_2",
          isRequired: required,
        },
        {
          title: {
            en: "IFE / INE current and clear",
            "es-MX": "IFE/INE vigente y clara",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_4",
          isRequired: required,
        },
        {
          title: {
            en: "Property receipt (no more than 1 year)",
            "es-MX": "Recibo de predial (no mayor a 1 año)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_5",
          isRequired: required,
        },
        {
          title: {
            en: "Address statement (when applicable)",
            "es-MX": "Manifiesto de domicilio (cuando aplique)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_6",
        },
        {
          title: { en: "Bureau consultation", "es-MX": "Consulta de buró" },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_7",
          isRequired: required,
        },
        {
          title: {
            en: "Proof of income (wage earners only)",
            "es-MX": "Comprobante de ingresos (solo asalariados)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_8",
        },
        {
          title: {
            en: "CURP (when applicable)",
            "es-MX": "CURP (cuando aplique) ",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_9",
        },
      ],
    },
  ],
};
