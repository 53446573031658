
// CSS3 vh100 does not include mobile address bar, thus we set the initial min-height in css on 90vh and correct in JS.
export const windowHeight = window.innerHeight;
export const windowWidth = window.innerWidth;

const brand = {
  brand: {
    secondary: '#077D9B',
    secondary_light: '#25A7C4',
    secondary_lighter: '#7DD9F1',

    primary: '#00B7E4',
    primary_light: '#B8E5F3',
    primary_lighter: '#F2FCFF',

    accent: '#FFC72A',

    green: '#82D62E',
    green_light: '#BFEE90',
    green_lighter: '#D2F2B2',

    darkgray: '#444444',
    alert: '#D32E6D',

    whatsapp: '#65d072',
    facebook: '#3b5998',
    facebookMessenger: '#1981ff',
    google: '#5588EA',
  },
};

const coolGray = {
  coolGray: {
    c0: '#f4f4f6',
    c1: '#ececf1',
    c2: '#d9d9de',
    c3: '#d1d1d8',
    c4: '#BABAC3',
    c5: '#76767a'
  }
};

export const colors = {
  ...brand,
  ...coolGray,
};
