export const guarantor_signing = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {en: "Submit information", ar: "أرسل البيانات"},

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {en: 'Review the loan and sign below', ar: "قم بقراءة العقد كاملاً ومن ثم قم بالتوقيع أدناه"},
      name: 'loan',
      elements: [

        {
          title: {en: "The loan terms:", ar: "شروط العقد"},
          name: "terms",
          type: "panel",
          innerIndent: 1,
          elements: [

            {
              type: "html",
              name: "loan_amount",
              html: {
                en: "The total loan amount is {amount} JD, to be repaid in  {repayment_period} months",
                ar: "يبلغ مجمل التمويل {repayment_period} دينار {amount} والذي سيتم سداده خلال"
              }
            },

            {
              type: "html",
              name: "loan_installment",
              html: {
                en: "The monthly installment will be {monthly_installment} JD, to be paid before the 25th of each month.",
                ar: "ستكون قيمة الدفعة الشهرية والذي سيتم دفعه حسب جدول السداد الخاص بك {monthly_installment} دينار أردني"
              }
            },

            {
              type: "html",
              name: "loan_fees",
              html: {
                en: "During {repayment_period} months, the loan will be repaid in full. For this loan the total fees will be {total_fees} JD.",
                ar: "خلال {repayment_period} أشهر, سيتم سداد التمويللقرض كاملا. ستكون الرسوم الكاملة لهذا التمويل لاتمويللقرض"
              }
            },
          ]
        },

        {
          title: {en: "Your signature", ar: "توقيعك"},
          name: "signature",
          type: "panel",
          innerIndent: 1,

          elements: [

            {
              title: {
                en: "The loan amount of {amount} JD that {first_name} is taking is reasonable and responsible considering their personal situation.",
                ar: "مبلغ التمويلالقرض {amount} المأخوذ من {first_name} دينار هو معقول ومناسب مقارنة بحالتها الشخصية"},
              type: "checkbox",
              name: "final_responsible",
              isRequired: true,
              choices: [{"value": "agree", "text": {en: "I agree", ar: "أنا أوافق"}}],
              validators: []
            },

            {
              title: {
                en: "I understand the risks of taking out a loan and will guarantee the repayment of the loan in case {first_name} is not able to make the repayment.",
                ar: "أدرك المخاطر المحتملة وأكفل سداد التمويلقرض كاملا في حال لم يتمكن {first_name}من السداد"},
              type: "checkbox",
              name: "final_agree",
              isRequired: true,
              choices: [{"value": "agree", "text": {en: "I agree", ar: "أنا أوافق"}}],
              validators: []
            },

            {
              title: {
                en: "I confirm that the information given in this form is true, complete and accurate.",
                ar: "أقر بأن المعلومات في هذا الطلب صحيحة وكاملة ودقيقة"
              },
              type: "checkbox",
              name: "final_truth",
              isRequired: true,
              choices: [{"value": "agree", "text": {en: "I agree", ar: "أنا أوافق"}}],
              validators: []
            },

            {
              type: "signaturepad",
              name: "final_signature",
              isRequired: true,
              title: {
                en: "Please enter your signature",
                ar: "الرجاء إدخال توقيعك"
              }
            },
          ]
        }
      ]
    },
  ]
};


