const numeral = require("numeral");

const func =
  (op) =>
  ([initial, ...params]) =>
    params
      .map((p) => (p ? p : 0))
      .reduce(
        (acc, value) => acc[op](value || 0), // Added check for value being undefined
        numeral(initial ? initial : 0)
      )
      .value();

export const add = (...params) => func("add")(params);
export const multiply = (...params) => func("multiply")(params);
export const divide = (...params) =>
  params.some((p) => !p) ? 0 : func("divide")(params); // Incorrect, because x / 0 never is 0. But used to prevent errors. Handle with care
export const subtract = (...params) => func("subtract")(params);

/**
 * Spawns functions to prevent floating point issues. MON-641
 * @param {*} Survey
 */
export const register_money_functions = (Survey) => {
  const surveySum = (params) => func("add")(params) || "0";

  Survey.FunctionFactory.Instance.register("add", surveySum);
  Survey.FunctionFactory.Instance.register("sum", surveySum);
  Survey.FunctionFactory.Instance.register(
    "multiply",
    (params) => func("multiply")(params) || "0"
  );
  Survey.FunctionFactory.Instance.register("divide", (params) =>
    params.some((p) => !p) ? 0 : func("divide")(params) || "0"
  );
  Survey.FunctionFactory.Instance.register(
    "subtract",
    (params) => func("subtract")(params) || "0"
  );
};

export function register_fixed(Survey) {
  Survey.FunctionFactory.Instance.register(
    "fixed",
    ([num, factor = 2, toZero = false]) =>
      num ? parseFloat(parseFloat(num).toFixed(factor)) : toZero ? 0.0 : ""
  );
}
