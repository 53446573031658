import env from "../../env";
import {expressions} from "../../survey/validators";

const required = env.ENDPOINT !== "local";

export const guarantor_ffdl = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-GT": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 0: Guarantor Acceptance
    {
      name: "guarantor_intake",
      title: { en: "Guarantor acceptance", "es-GT": "Aceptación del fiador" },
      innerIndent: 1,
      elements: [
        {
          type: "radiogroup",
          title: {
            en: "Guarantor acceptance",
            "es-GT": "Aceptación del fiador",
          },
          name: "guarantor_refused",
          isRequired: required,
          choices: [
            {
              value: false,
              text: { en: "I accept", "es-GT": "Acepto" },
            },
            {
              value: true,
              text: { en: " I don't accept", "es-GT": "No acepto" },
            },
          ],
        },
      ],
    },
    // Page 1: Guarantor Information
    {
      name: "guarantor_information",
      title: {
        en: "General data of the guarantor",
        "es-GT": "Datos generales del fiador",
      },
      innerIndent: 1,
      elements: [
        {
          type: "text",
          name: "guarantor_first_name",
          isRequired: required,
          title: { en: "What is your first name?", "es-GT": "Primer nombre*" },
        },
        {
          type: "text",
          name: "custom_text_1",
          title: { en: "Middle name:", "es-GT": "Segundo nombre:" },
        },
        {
          type: "text",
          name: "custom_text_2",
          isRequired: required,
          title: { en: "First surname:", "es-GT": "Primer apellido:*" },
        },
        {
          type: "text",
          name: "custom_text_3",
          title: { en: "Second surname:", "es-GT": "Segundo apellido:" },
        },
        {
          type: "text",
          name: "custom_text_4",
          title: { en: "Married surname:", "es-GT": "Apellido de Casado/a:" },
        },
        {
          type: "text",
          name: "custom_text_5",
          title: {
            en: "Type of relationship or kinship:",
            "es-GT": "Tipo de relación o parentesco con el solicitante:",
          },
        },
        {
          type: "radiogroup",
          name: "custom_text_64",
          colCount: 2,
          title: {
            en: "11. Are you a FDL customer?",
            "es-GT": " ¿Eres cliente de FDL?",
          },
          storeOthersAsComment: false,
          choices: [
            { value: "sí", text: { en: "Yes", "es-GT": "Sí" } },
            { value: "no", text: { en: "No", "es-GT": "No" } },
          ],
        },
        {
          title: {
            en: "FFDL Customer",
            "es-GT": "Eres cliente de FDL",
          },
          name: "income_details",
          type: "panel",
          visibleIf: "{custom_text_64} == 'sí'",
          innerIndent: 1,
          elements: [
            {
              type: "text",
              name: "custom_text_65",
              inputType: "number",
              isRequired: required,
              title: {
                en: "If yes, how much do you owe?",
                "es-GT": "Si es afirmativo, ¿cuánto debes?",
              },
            },
            {
              type: "radiogroup",
              name: "custom_text_66",
              isRequired: required,
              colCount: 2,
              title: {
                en: "Do you have credit in other institutions?",
                "es-GT": "¿Tienes crédito en otras instituciones?*",
              },
              choices: [
                { value: "sí", text: { en: "Yes", "es-GT": "Sí" } },
                { value: "no", text: { en: "No", "es-GT": "No" } },
              ],
            },
            {
              type: "text",
              name: "custom_text_10",
              visibleIf: "{custom_text_66} == 'sí'",
              title: {
                en: "Indicate institution",
                "es-GT": "Indica el nombre de la institución",
              },
            },
            {
              type: "text",
              name: "custom_text_11",
              visibleIf: "{custom_text_66} == 'sí'",

              title: {
                en: "Indicate amount",
                "es-GT": "Indica el monto adeudado",
              },
            },
            {
              type: "text",
              inputType: "number",
              name: "custom_text_12",
              visibleIf: "{custom_text_66} == 'sí'",
              isRequired: required,
              title: {
                en: "Indicate monthly fee",
                "es-GT": "Indica la cuota mensual",
              },
            },
            {
              type: "text",
              inputType: "number",
              name: "custom_text_13",
              visibleIf: "{custom_text_66} == 'sí'",
              isRequired: required,
              title: { en: "DPI", "es-GT": "DPI" },
              validators: [
                {
                  type: "regexvalidator",
                  text: {
                    en: "Number should be from 13 digits length",
                    "es-GT": "El número debe tener una longitud de 13 dígitos",
                  },
                  regex: "^[0-9]{13}$",
                },
              ],
            },
          ],
        },
        {
          type: "text",
          name: "custom_text_14",
          title: { en: "N.I.T.:", "es-GT": "N.I.T.:" },
        },
        {
          type: "text",
          name: "date_of_birth",
          isRequired: required,
          inputType: "date",
          title: { en: "Date of birth:", "es-GT": "Fecha de nacimiento:*" },
        },
        {
          title: {
            en: "What is your marital status? *",
            "es-GT": "Estado civil *",
          },
          name: "marital",
          type: "radiogroup",
          isRequired: required,
          choices: [
            { value: "casado", text: { en: "Married", "es-GT": "Casado" } },
            { value: "soltero", text: { en: "Single", "es-GT": "Soltero" } },
            {
              value: "union_de_hecho",
              text: { en: "Domestic partnership", "es-GT": "Unión de hecho" },
            },
          ],
        },
        {
          title: {
            en: "If you are married or in a common-law union, give details of the partner",
            "es-GT":
              "Si está casado o con unión de hecho dar detalles del compañero",
          },
          name: "extra_marital",
          type: "panel",
          innerIndent: 1,
          visibleIf: "{marital} == 'casado' || {marital} == 'union_de_hecho'",
          elements: [
            {
              type: "text",
              name: "custom_text_30",
              isRequired: required,
              title: {
                en: "First name *",
                "es-GT": "Primer nombre *",
              },
            },
            {
              type: "text",
              name: "custom_text_31",
              title: {
                en: "Middle name",
                "es-GT": "Segundo nombre",
              },
            },
            {
              type: "text",
              name: "custom_text_43",
              isRequired: required,
              title: {
                en: "Surname *",
                "es-GT": "Primer apellido *",
              },
            },
            {
              type: "text",
              name: "custom_text_33",
              title: { en: "Second surname", "es-GT": "Segundo apellido" },
            },
            {
              type: "text",
              name: "custom_text_34",
              title: { en: "Married name", "es-GT": "Apellido de casada" },
            },
            {
              name: "custom_text_35",
              type: "text",
              validators: [
                {
                  type: "regexvalidator",
                  text: {
                    en: "Number should be from 13 digits length",
                    "es-GT": "El número debe tener una longitud de 13 dígitos",
                  },
                  regex: "^[0-9]{13}$",
                },
              ],
              title: {
                en: "ID No.",
                "es-GT": "DPI",
              },
            },
            {
              type: "text",
              name: "custom_text_36",
              title: {
                en: "Activity carried out",
                "es-GT": "Actividad que realiza",
              },
            },
            {
              type: "text",
              name: "custom_text_37",
              title: {
                en: "Place of work",
                "es-GT": "Lugar de trabajo",
              },
            },
            {
              type: "text",
              name: "custom_text_38",
              title: {
                en: "Position held",
                "es-GT": "Cargo que ocupa",
              },
            },
            {
              type: "number",
              name: "custom_float_8",
              title: {
                en: "Income/Salary",
                "es-GT": "Ingreso/Salario",
              },
            },
            {
              title: {
                en: "Freq. Income/Salary",
                "es-GT": "Frec. Ingreso/Salario",
              },
              name: "custom_text_40", // check mapping
              type: "dropdown",
              isRequired: required,
              choices: [
                {
                  value: "semanal",
                  text: {
                    en: "Weekly",
                    "es-GT": "Semanal",
                  },
                },
                {
                  value: "quincenal",
                  text: { en: "Biweekly", "es-GT": "Quincenal" },
                },
                {
                  value: "mensual",
                  text: { en: "Monthly", "es-GT": "Mensual" },
                },
              ],
            },
            {
              type: "text",
              name: "custom_text_41",
              title: {
                en: "Working time",
                "es-GT": "Tiempo de laborar",
              },
            },
            {
              name: "custom_text_42", // Check mapping
              type: "phone",
              validators: [
                expressions.phone_en("custom_text_42"),
                expressions.phone_length("custom_text_42"),
              ],
              title: {
                en: "Tel.",
                "es-GT": "Tel.",
              },
            },
            {
              title: {
                en: "Are you an FDL client? *",
                "es-GT": "¿Es cliente de FDL? *",
              },
              name: "custom_bool_1", //check mapping
              type: "radiogroup",
              isRequired: required,
              choices: [
                { value: true, text: { en: "Yes", "es-GT": "Sí" } },
                { value: false, text: { en: "No", "es-GT": "No" } },
              ],
            },
          ],
        },
        {
          type: "dropdown",
          name: "gender",
          isRequired: required,
          storeOthersAsComment: false,
          title: { en: "Gender:", "es-GT": "Género:*" },
          choices: [
            { value: "Hombre", text: { en: "Male", "es-GT": "Hombre" } },
            { value: "Mujer", text: { en: "Female", "es-GT": "Mujer" } },
          ],
        },

        {
          type: "text",
          name: "custom_text_16",
          isRequired: required,
          title: { en: "Schooling:", "es-GT": "Escolaridad:*" },
        },
        {
          type: "dropdown",
          name: "custom_text_17",
          title: {
            en: "Did you finish studies?",
            "es-GT": "¿Terminaste los estudios?*",
          },
          isRequired: required,
          storeOthersAsComment: false,
          choices: [
            { value: "Sí", text: { en: "yes", "es-GT": "Sí" } },
            { value: "No", text: { en: "no", "es-GT": "No" } },
          ],
        },
        {
          type: "text",
          name: "current_job_description",
          isRequired: required,
          title: {
            en: "Profession or trade:",
            "es-GT": "Profesión u oficio:*",
          },
        },
        {
          type: "text",
          name: "custom_text_18",
          isRequired: required,
          title: { en: "Main activity", "es-GT": "Actividad principal *" },
        },
        {
          type: "text",
          name: "custom_text_19",
          isRequired: false,
          title: {
            en: "Secondary activity",
            "es-GT": "Actividad secundaria",
          },
        },
        {
          type: "text",
          name: "children",
          isRequired: required,
          title: {
            en: "Dependents",
            "es-GT": "No. Dependientes*",
          },
        },
        {
          type: "text",
          name: "home_addres_details",
          isRequired: required,
          title: { en: "Address", "es-GT": "Dirección *" },
        },
        {
          title: {
            en: "Basic services",
            "es-GT": "Servicios básicos *",
          },
          name: "expenses_details",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "custom_jsonfield_2",
              type: "matrixdynamic",
              title: {
                en: "Services *",
                "es-GT": "Servicios básicos *",
              },
              minRowCount: 1,
              rowCount: 1,
              isRequired: required,
              addRowText: {
                en: "Add",
                "es-GT": "Agregar",
              },
              columns: [
                {
                  title: {
                    en: "Item",
                    "es-GT": "Elemento",
                  },
                  name: "Tipo",
                  isRequired: required,
                  hasOther: true,
                  otherText: {
                    en: "Other",
                    "es-GT": "Otros",
                  },
                  cellType: "dropdown",
                  inputType: "string",
                  choices: [
                    {
                      value: "Alimentación",
                      text: {
                        en: "Food",
                        "es-GT": "Alimentación",
                      },
                    },
                    {
                      value: "Energía eléctrica",
                      text: {
                        en: "Electricity",
                        "es-GT": "Energía eléctrica",
                      },
                    },
                    {
                      value: "Agua potable",
                      text: {
                        en: "drinking_water",
                        "es-GT": "Agua potable",
                      },
                    },
                    {
                      value: "TV por cable",
                      text: {
                        en: "Cable TV",
                        "es-GT": "TV por cable",
                      },
                    },
                    {
                      value: "Basura",
                      text: {
                        en: "Garbage",
                        "es-GT": "Basura",
                      },
                    },
                    {
                      value: "Telefóno en casa",
                      text: {
                        en: "Home phone",
                        "es-GT": "Telefóno en casa",
                      },
                    },
                    {
                      value: "Internet",
                      text: {
                        en: "Internet",
                        "es-GT": "Internet",
                      },
                    },
                    {
                      value: "Educación",
                      text: {
                        en: "Education",
                        "es-GT": "Educación",
                      },
                    },
                    {
                      value: "Telefóno Celular",
                      text: {
                        en: "Cell phone",
                        "es-GT": "Telefóno Celular",
                      },
                    },
                    {
                      value: "Transporte",
                      text: {
                        en: "Transport",
                        "es-GT": "Transporte",
                      },
                    },
                    {
                      value: "Salud",
                      text: {
                        en: "Health",
                        "es-GT": "Salud",
                      },
                    },
                    {
                      value: "Mantenimiento Vehículo",
                      text: {
                        en: "Vehicle maintenance",
                        "es-GT": "Mantenimiento Vehículo",
                      },
                    },
                    {
                      value: "Combustible",
                      text: {
                        en: "Fuel",
                        "es-GT": "Combustible",
                      },
                    },
                    {
                      value: "Alquiler",
                      text: {
                        en: "Rent",
                        "es-GT": "Alquiler",
                      },
                    },
                  ],
                },
                {
                  title: {
                    en: "Amount",
                    "es-GT": "Monto",
                  },
                  name: "Monto",
                  cellType: "text",
                  inputType: "number",
                },
              ],
            },
            {
              title: {
                en: "Total basic services expenses",
                "es-GT": "Total Servicios básicos",
              },
              type: "expression",
              name: "custom_float_1",
              visibleIf: "sumInArray({custom_jsonfield_2},'Monto') > 0",
              expression: "fixed(sumInArray({custom_jsonfield_2},'Monto'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              name: "custom_jsonfield_3",
              type: "matrixdynamic",
              title: {
                en: "Other Expenses *",
                "es-GT": "Otros gastos (IGSS, ISR) *",
              },
              description: {
                en: "Enter at least one *",
                "es-GT": "Que ingrese al menos una *",
              },
              minRowCount: 1,
              rowCount: 1,
              isRequired: required,
              addRowText: {
                en: "Add",
                "es-GT": "Agregar",
              },
              columns: [
                {
                  title: {
                    en: "Item",
                    "es-GT": "Elemento",
                  },
                  name: "Tipo",
                  isRequired: required,
                  cellType: "dropdown",
                  inputType: "string",
                  choices: [
                    {
                      value: "IGSS",
                      text: {
                        en: "IGSS",
                        "es-GT": "IGSS",
                      },
                    },
                    {
                      value: "ISR",
                      text: {
                        en: "ISR",
                        "es-GT": "ISR",
                      },
                    },
                    {
                      value: "Otros",
                      text: {
                        en: "Other",
                        "es-GT": "Otros",
                      },
                    },
                  ],
                },
                {
                  title: {
                    en: "Amount",
                    "es-GT": "Monto",
                  },
                  name: "Monto",
                  cellType: "text",
                  inputType: "number",
                },
              ],
            },
            {
              title: {
                en: "Total other expenses",
                "es-GT": "Total de otros gastos",
              },
              type: "expression",
              name: "custom_float_2",
              visibleIf: "sumInArray({custom_jsonfield_3},'Monto') > 0",
              expression: "fixed(sumInArray({custom_jsonfield_3},'Monto' ), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "GTQ",
            },
          ],
        },
        {
          type: "text",
          name: "custom_text_20",
          title: { en: "Time of residence:", "es-GT": "Tiempo de residir" },
        },
        {
          type: "dropdown",
          name: "custom_text_21",
          isRequired: false,
          choices: [
            { value: "Urbana", text: { en: "urban", "es-GT": "Urbana" } },
            { value: "Rural", text: { en: "no", "es-GT": "Rural" } },
          ],
          title: { en: "Location geographic", "es-GT": "Ubic. geográfica:" },
        },
        {
          name: "custom_text_22",
          isRequired: false,
          type: "phone",
          validators: [
            expressions.phone_en("custom_text_22"),
            expressions.phone_length("custom_text_22"),
          ],
          title: {
            en: "Home phone",
            "es-GT": "Teléfono casa",
          },
        },
        {
          name: "cell",
          isRequired: false,
          type: "phone",
          validators: [
            expressions.phone_en("cell"),
            expressions.phone_length("cell"),
          ],
          title: {
            en: "Cell phone number:",
            "es-GT": "No. celular",
          },
        },
        {
          type: "text",
          name: "email",
          title: { en: "Email:", "es-GT": "Correo electrónico" },
        },
      ],
    },
    // Page 2: Guarantor work data
    {
      name: "guarantor_work_data",
      title: {
        en: "Guarantor's work data",
        "es-GT": "Datos de trabajo del fiador",
      },
      innerIndent: 1,
      elements: [
        {
          name: "current_job_details",
          type: "panel",
          title: {
            en: "Employment data (if you are a salaried employee)",
            "es-GT": "Datos laborales ",
          },
          innerIndent: 1,
          elements: [
            {
              type: "text",
              name: "custom_text_67",
              isRequired: required,
              title: {
                en: "Company name *",
                "es-GT": "Nombre de empresa *",
              },
            },
            {
              type: "dropdown",
              name: "custom_text_68",
              isRequired: required,
              title: {
                en: "Type of company:*",
                "es-GT": "Tipo de empresa:*",
              },
              choices: [
                {
                  value: "privada",
                  text: { en: "Private", "es-GT": "Privada" },
                },
                {
                  value: "pública",
                  text: { en: "Public", "es-GT": "Pública" },
                },
              ],
            },
            {
              type: "text",
              name: "custom_text_69",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Salary or income*",
                "es-GT": "Salario o ingresos*",
              },
            },
            {
              type: "dropdown",
              name: "custom_text_70",
              isRequired: false,
              storeOthersAsComment: false,
              choices: [
                {
                  value: "Quincenal",
                  text: { en: "Biweekly", "es-GT": "Quincenal" },
                },
                {
                  value: "Mensual",
                  text: { en: "Monthly", "es-GT": "Mensual" },
                },
              ],
              title: {
                en: "Salary frequency:",
                "es-GT": "Frequencia salario:",
              },
            },
            {
              title: {
                en: "Job position *",
                "es-GT": "Cargo que ocupa *",
              },
              name: "custom_text_71",
              isRequired: required,
              type: "text",
            },
            {
              title: {
                en: "How long have you been working here? *",
                "es-GT": "Tiempo de laborar *",
              },
              name: "custom_text_72",
              type: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Work phone no. *",
                "es-GT": "Teléfono de empresa dónde labora *",
              },
              name: "custom_text_73",
              type: "phone",
              isRequired: required,
              validators: [
                expressions.phone_en("current_job_phone"),
                expressions.phone_length("current_job_phone"),
              ],
            },
            {
              title: {
                en: "Work Address *",
                "es-GT": "Dirección de la empresa dónde labora *",
              },
              name: "custom_text_74",
              type: "text",
              isRequired: required,
            },
          ],
        },
        {
          name: "Accounts",
          type: "panel",
          visibleIf: "{custom_float_74} == 'This will stay hidden'",
          title: {
            en: "ACCOUNTS",
            "es-GT": "Evaluación económica",
          },
          innerIndent: 1,
          elements: [
            {
              type: "expression",
              name: "custom_float_7",
              expression: "fixed({current_job_salary}, 2)",
              title: {
                en: "Salary income",
                "es-GT": "Ingreso salarial",
              },
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "text",
              name: "other_income",
              inputType: "number",
              title: {
                en: "Other income",
                "es-GT": "Otros ingresos",
              },
            },
            {
              type: "expression",
              expression: `fixed(sum({other_income},{current_job_salary}), 2, true)`, // Floating Point Proof
              name: "custom_float_5", //check_mapping
              title: {
                en: "TOTAL REVENUES",
                "es-GT": "TOTAL DE INGRESOS",
              },
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              title: {
                en: "Total familiar expenses",
                "es-GT": "Total gastos familiares",
              },
              type: "expression",
              name: "mcf_total_expense_amount",
              expression: "fixed({custom_float_1}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "expression",
              name: "custom_float_3",
              expression: "{loan_terms_summary.product_interest_rate}",
              title: {
                en: "Financial expense ",
                "es-GT": "Gasto administrativo",
              },
            },
            {
              type: "expression",
              name: "custom_float_10",
              title: {
                en: "Payment of fees with other MFIs",
                "es-GT": "Pago de cuota con otras IMF", // (que jale lo indicado en línea 17 de hoja de datos generales del fiador)
              },
              expression: `fixed({custom_text_12}, 2)`,
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "expression",
              name: "custom_float_9",
              // expression: "fixed(multiply({amount}, 0.11),2)",
              expression: "{custom_text_65} || 0",
              title: {
                en: "Fee with FDL (guarantor)",
                "es-GT": "Cuota con FDL (fiador) ",
              },
            },
            {
              title: {
                en: "Total other expenses",
                "es-GT": "Total de otros gastos",
              },
              type: "expression",
              name: "custom_float_2",
              expression: `fixed({custom_float_2}, 2)`, // Floating Point Proof
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "expression",
              name: "custom_float_4", //check_mapping
              expression: `fixed(add({custom_float_1}, {custom_float_2}, {custom_float_3}), 2)`,
              title: {
                en: "TOTAL EXPENDITURES",
                "es-GT": "TOTAL DE GASTOS",
              },
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "expression",
              name: "custom_float_6", //check_mapping
              expression: `fixed(subtract({custom_float_5}, {custom_float_4}), 2)`,
              title: {
                en: "Availability",
                "es-GT": "Disponibilidad",
              },
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "expression",
              name: "custom_float_20",
              title: {
                en: "Capital share (requested loan amount /loan term)",
                "es-GT": "Cuota de capital "
              },
              expression: `fixed(divide({amount},subtract({custom_float_5},{custom_float_4}), 2 , true)`, //amount / disponibilidad
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              type: "expression",
              name: "custom_float_21",
              title: {
                en: "NEI (capital quota / availability)",
                "es-GT": "NEI (cuota capital dividido disponibilidad)" //cuota de capital / disponibilidad
              },
              expression: `fixed(divide(divide({amount},subtract({custom_float_5},{custom_float_4})),substract({custom_float_5},{custom_float_4})), 2 , true)`,
              displayStyle: "currency",
              currency: "GTQ",
            },
          ],
        },
      ],
    },
    // Page 3: Guarantor Signature
    {
      name: "Guarantor's Signature",
      title: { en: "Guarantor's signature", "es-GT": "Firma del garante" },
      innerIndent: 1,
      elements: [
        {
          type: "html",
          title: {
            en: "One of the conditions to be a guarantor is that you have a good credit record. With your permission, this will be checked at the credit bureau.",
            "es-GT":
              "Una de las condiciones para ser fiador es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito.",
          },
        },
        {
          type: "dropdown",
          name: "confirm_1",
          isRequired: required,
          storeOthersAsComment: false,
          choices: [
            { value: "acepto", text: { en: "I agree", "es-GT": "Acepto" } },
          ],
          title: {
            en: "Permission to check record at the credit bureau",
            "es-GT": "Consentimiento para comprobar record crediticio",
          },
        },
        {
          name: "Consentimiento consulta a buro de credito",
          type: "html",
          html: `<div><p>Una de las condiciones para ser fiador es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito.
           </p><br><a class='button sv_complete_btn' 
           href='https://staticassets-moneyphone-dev.s3.eu-central-1.amazonaws.com/Consentimiento+consulta+a+buro+de+credito.pdf' download='Consentimiento' target="_blank">Descargar consentimiento para firma</a></div>`,
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "picture_1",
          title: {
            en: "Upload signed permission *",
            "es-GT": "Subir consentimiento firmado *",
          },
        },
        {
          type: "signaturepad",
          name: "file_10",
          isRequired: true,
          title: {
            en: "Please enter your signature *",
            "es-GT": "Por favor ingrese su firma *",
          },
        },
      ],
    },
  ],
};
