import Config from "../../config";
import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const guarantor_credit_check_banco_popular = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-HN": "Firmar aplicación",
  },

  questionStartIndex: 1,
  requiredText: "",
  pages: [
    // Page 1: Guarantor acceptance
    {
      name: "guarantor_intake",
      title: {
        en: "Guarantor acceptance",
        "es-HN": "Aceptación del aval",
      },
      elements: [
        {
          type: "html",
          html: {
            en: `Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.`,
            "es-HN": `Selecciona aceptas o no. Si tiene alguna pregunta, por favor contáctenos.`,
          },
        },
        {
          type: "radiogroup",
          title: {
            en: "Guarantor acceptance",
            "es-HN": "Aceptación del aval",
          },
          name: "guarantor_refused",
          isRequired: required,
          choices: [
            {
              value: false,
              text: { en: "I accept", "es-HN": "Acepto" },
            },
            {
              value: true,
              text: { en: "I don't accept", "es-HN": "No acepto" },
            },
          ],
        },
      ],
    },
    // Page 2: Guarantor personal information
    {
      title: {
        en: "Guarantor personal information",
        "es-HN": "Información personal del aval",
      },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          title: {
            en: "Type of client",
            "es-HN": "Tipo de cliente",
          },
          expression: "Aval",
        },
        {
          type: "text",
          name: "guarantor_first_name",
          title: {
            en: "Full name",
            "es-HN": "Nombre completo",
          },
          isRequired: required,
        },
        {
          name: "id_type",
          title: {
            en: "Type of document",
            "es-HN": "Tipo de documento",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.tipo_documentos || [],
          isRequired: required,
        },
        {
          type: "text",
          name: "id_number",
          title: {
            en: "ID Number",
            "es-HN": "Número de documento",
          },
          isRequired: required,
          validators: [
            {
              type: "text",
              minLength: 13,
              text: "Valida tu identificación. Ingresa 13 caracteres como mínimo",
            },
            {
              type: "text",
              maxLength: 18,
              text: "Valida tu identificación. Ingresa 18 caracteres como máximo",
            },
          ],
        },
        {
          type: "text",
          title: {
            en: "Place of Birth",
            "es-HN": "Lugar de nacimiento",
          },
          name: "custom_text_1",
          isRequired: required,
        },
        {
          title: {
            en: "Identity Document",
            "es-HN": "DNI/RTN",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "picture_1",
          isRequired: required,
        },
        {
          name: "nationality",
          title: {
            en: "Nationality",
            "es-HN": "Nacionalidad",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.nacionalidad || [],
          isRequired: required,
        },
        {
          name: "birth_date_panel",
          type: "panel",
          innerIndent: 2,
          title: {
            en: "Date of birth",
            "es-HN": "Fecha de nacimiento",
          },
          elements: [
            {
              type: "dropdown",
              name: "date_type_1",
              title: {
                en: "Enter date with:",
                "es-HN": "Ingresar fecha con:",
              },
              defaultValue: "calendario",
              choices: [
                {
                  value: "calendario",
                  text: { en: "Calendar", "es-HN": "Calendario" },
                },
                { value: "texto", text: { en: "Text", "es-HN": "Texto" } },
              ],
            },
            {
              visibleIf: "{date_type_1} == 'calendario'",
              type: "text",
              name: "date_of_birth",
              isRequired: required,
              inputType: "date",
              title: { en: "Date of birth", "es-HN": "Fecha de nacimiento" },
            },
            {
              visibleIf: "{date_type_1} == 'texto'",
              name: "date_of_birth",
              type: "text",
              title: { en: "Date of birth", "es-HN": "Fecha de nacimiento" },
              placeHolder: "aaaa-mm-dd",
              validators: [
                {
                  type: "regexvalidator",
                  text: {
                    en: "Enter date with the format yyyy-mm-dd",
                    "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                  },
                  regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
                },
              ],
            },
          ],
        },
        {
          name: "custom_text_2",
          title: {
            en: "Current Profession / Occupation / Dedication",
            "es-HN": "Profesión/ Oficio / Dedicación actual",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.profesiones || [],
          isRequired: required,
        },
        {
          name: "custom_text_9",
          title: {
            en: "Activity that develops",
            "es-HN": "Actividad que desarrolla",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.act_economicas || [],
          isRequired: required,
        },
        {
          name: "custom_text_10",
          title: {
            en: "Economic sector",
            "es-HN": "Sector económico",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.sect_economicos || [],
          isRequired: required,
        },
        {
          type: "dropdown",
          name: "custom_text_12",
          title: {
            "es-HN": "Sueldo o venta promedio al mes",
            en: "Average monthly salary or sales",
          },
          isRequired: required,
          choices: Config.sueldos || [],
        },
        {
          name: "custom_text_3",
          title: {
            en: "Educational level",
            "es-HN": "Nivel educativo",
          },
          isRequired: required,
          type: "dropdown",
          renderAs: "select2",
          choices: Config.nivel_educativo || [],
        },
        {
          title: { en: "Marital status", "es-HN": "Estado civil" },
          name: "marital",
          isRequired: required,
          type: "dropdown",
          renderAs: "select2",
          choices: Config.estado_civil || [],
        },
        {
          type: "text",
          name: "dependence_number",
          title: {
            "es-HN": "N. dependientes",
            en: "Number of Dependents ",
          },
          isRequired: required,
          inputType: "number",
          validators: [
            {
              type: "numeric",
              minValue: 1,
              text: "Responda con mínimo 1",
            },
            {
              type: "numeric",
              maxValue: 99,
              text: "Responda con máximo 99",
            },
          ],
        },
        {
          type: "dropdown",
          name: "gender",
          title: {
            "es-HN": "Género",
            en: "Gender",
          },
          isRequired: required,
          choices: [
            { value: "masculino", text: { en: "Male", "es-HN": "MASCULINO" } },
            { value: "femenino", text: { en: "Female", "es-HN": "FEMENINO" } },
          ],
        },
      ],
    },
    // Page 3: Guarantor Address Information
    {
      title: {
        en: "Guarantor Address Information",
        "es-HN": "Datos Domiciliares",
      },
      innerIndent: 1,
      elements: [
        {
          name: "home_city",
          title: {
            en: "City ",
            "es-HN": "Ciudad",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.ciudades || [],
          isRequired: required,
        },
        {
          name: "custom_text_4",
          title: {
            en: "Department ",
            "es-HN": "Departamento",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.departamento || [],
          isRequired: required,
        },
        {
          type: "text",
          title: {
            en: "Municipality",
            "es-HN": "Municipio",
          },
          name: "custom_text_22",
          isRequired: required,
        },
        {
          type: "comment",
          name: "custom_textbox_1",
          isRequired: required,
          title: {
            en: "Exact home address",
            "es-HN": "Dirección exacta del domicilio",
          },
          maxLength: 500,
        },
        {
          name: "custom_text_5",
          title: {
            en: "Type of housing",
            "es-HN": "Tipo de Vivienda",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.tipo_propiedad || [],
          isRequired: required,
        },
        {
          title: {
            en: "Utilities",
            "es-HN": "Servicios Públicos",
          },
          type: "checkbox",
          name: "custom_jsonfield_1",
          choices: [
            {
              value: "alcantarillado",
              text: { en: "Sewage", "es-HN": "Alcantarillado" },
            },
            {
              value: "luz",
              text: { en: "Electricity", "es-HN": "Luz" },
            },
            {
              value: "agua",
              text: { en: "Water", "es-HN": "Agua" },
            },
            {
              value: "telefono",
              text: { en: "Telephone", "es-HN": "Teléfono" },
            },
          ],
        },
        {
          title: {
            "es-HN": "Celular",
            en: "Mobile phone number",
          },
          type: "phone",
          name: "phone_number",
          validators: [
            expressions.phone_en("phone_number"),
            expressions.phone_length("phone_number"),
          ],
        },
        {
          title: {
            en: "Additional phone-number ",
            "es-HN": "N de teléfono adicional",
          },
          type: "phone",
          name: "mobile_phone_number_2",
          validators: [
            expressions.phone_en("mobile_phone_number_2"),
            expressions.phone_length("mobile_phone_number_2"),
          ],
        },
        {
          type: "text",
          name: "email",
          title: {
            en: "Email ",
            "es-HN": "Correo electrónico",
          },
          isRequired: required,
          inputType: "email",
          validators: [{ type: "email" }],
        },
        {
          type: "signaturepad",
          name: "file_1",
          title: {
            en: "Guarantor's digital signature",
            "es-HN": "Firma digital del aval",
          },
          isRequired: required,
        },
      ],
    },
  ],
};
