import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const intake_rentamigo_pages = [
  // Page 0: Group creation
  //hiden page for co applicants
  {
    title: {
      en: "Group information",
      "es-MX": "Integrantes del grupo",
    },
    elements: [
      {
        name: "custom_jsonfield_1",
        title: {
          en: "Group members - Add from 6 to 15 members (for now)",
          "es-MX":
            "Para poder empezar tu solicitud de crédito debes ingresar al menos 6 integrantes que conformaran tu grupo.",
        },
        type: "matrixdynamic",
        minRowCount: 6,
        rowCount: 6,
        horizontalScroll: true,
        columnLayout: "vertical",
        addRowLocation: "bottom",
        columnMinWidth: "130px",
        isRequired: "{custom_bool_10} == true",
        visibleIf: "{custom_bool_10} == true",
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        validators: [
          {
            type: "expression",
            text: {
              en: "Please make sure to input different phone numbers for all members.",
              "es-MX":
                "Asegúrese de ingresar números de teléfono diferentes para todos los integrantes.",
            },
            expression: `differentPhoneNumbers({custom_jsonfield_1}, 'telefono') == true`,
          },
        ],
        columns: [
          {
            title: {
              en: "Member name",
              "es-MX": "Nombre integrante",
            },
            cellType: "text",
            inputType: "string",
            name: "nombre",
            isRequired: required,
          },
          {
            title: {
              en: "Member Phone number",
              "es-MX": "Número de telefono de integrante",
            },
            name: "telefono",
            cellType: "phone",
            inputType: "string",
            isRequired: required,
            validators: [
              expressions.phone_en("telefono"),
              expressions.phone_length("telefono"),
              expressions.same_phone_number("telefono")
            ],
          },
          {
            title: {
              en: "Member address",
              "es-MX": "Dirección integrante",
            },
            cellType: "text",
            inputType: "string",
            name: "direccion",
            isRequired: required,
          },
          {
            title: {
              en: "Member job",
              "es-MX": "Actividad económica integrante",
            },
            cellType: "text",
            inputType: "string",
            name: "trabajo",
            isRequired: required,
          },
        ],
      },
    ],
  },
  //hiden page for president
  {
    title: {
      en: "Member acceptance",
      "es-MX": "Aceptación del integrante",
    },
    elements: [
      {
        visibleIf: "{custom_bool_10} == false",
        title: {
          en: "Welcome {first_name}, you are invited to be the cooperative of  group {user_first_name} for a loan amount of {amount} To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
          "es-MX": `Bienvenido(a) {first_name}, estás referido para ser un integrante en el grupo de {custom_text_150} por un monto de préstamo de {amount} MXN. Para cumplir con este requerimiento, se te pedirá información sobre ti. Selecciona aceptas o no. Si tienes alguna pregunta, por favor contáctenos.`,
        },
        name: "guarantor_refused",
        type: "radiogroup",
        isRequired: required,
        choices: [
          {
            value: false,
            text: {
              en: "I accept",
              "es-MX": "Acepto",
            },
          },
          {
            value: true,
            text: {
              en: "I don’t accept",
              "es-MX": "No acepto",
            },
          },
        ],
      },
    ],
  },
  // Page 1: Applicant Information
  {
    title: {
      en: "Applicant information",
      "es-MX": "Datos personales",
    },
    elements: [
      // This question is asked in the Wix frontend and mapped to custom_text_23.
      // {
      //   title: {
      //     en: "Are you already a customer of Rentamigo?",
      //     "es-MX": "¿Ya eres cliente de Rentamigo?",
      //   },
      //   type: "text",
      //   name: "custom_text_23",
      //   isRequired: required,
      // },
      {
        title: { en: "Sucursal", "es-MX": "Sucursal" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_10",
        choices: [
          { value: "Texcoco", text: { en: "Texcoco", "es-MX": "Texcoco" } },
          {
            value: "Azcapotzalco",
            text: { en: "Azcapotzalco", "es-MX": "Azcapotzalco" },
          },
          {
            value: "Tultitlán",
            text: { en: "Tultitlán", "es-MX": "Tultitlán" },
          },
        ],
      },
      {
        title: {
          en: "Name(s)",
          "es-MX": "Nombre(s)",
        },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: { en: "Last name", "es-MX": "Apellido paterno" },
        type: "text",
        name: "surname",
        isRequired: required,
      },
      {
        title: { en: "Mother's last name", "es-MX": "Apellido materno" },
        type: "text",
        name: "second_surname",
        isRequired: required,
      },
      {
        title: { en: "Gender", "es-MX": "Genero" },
        name: "gender",
        isRequired: required,
        type: "dropdown",
        choices: [
          { value: "hombre", text: { en: "Male", "es-MX": "Hombre" } },
          { value: "mujer", text: { en: "Female", "es-MX": "Mujer" } },
        ],
      },
      {
        title: { en: "Place of birth", "es-MX": "Lugar de nacimiento" },
        type: "text",
        name: "custom_text_12",
        isRequired: required,
      },
      {
        title: { en: "Nationality", "es-MX": "Nacionalidad" },
        type: "text",
        name: "nationality",
        isRequired: required,
      },
      {
        title: {
          en: "No offsprings",
          "es-MX": "No. De hijos",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_1",
      },
      {
        title: {
          en: "No. economic leaches",
          "es-MX": "No. Dependientes económicos",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_2",
      },
      {
        title: { en: "Email*", "es-MX": "Correo electrónico" },
        type: "text",
        name: "email",
        isRequired: required,
        validators: [{ type: "email" }],
      },
      {
        title: {
          en: "Serial number of the Electronic Signature",
          "es-MX": "Número de serie de la Firma Electrónica",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_3",
      },
      // custom_text_1 is in credit check and it would be prefilled with the number they already filled
      {
        title: {
          en: "RFC",
          "es-MX": "RFC",
        },
        visibleIf: "{custom_text_1} == 'RFC'",
        type: "text",
        name: "custom_text_3",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 13 characters",
              "es-MX": "RFC 13 dígitos",
            }, // Requires translation
            regex: `^[A-Za-z]{4}[ |-]{0,1}[0-9]{6}[ |-]{0,1}[0-9A-Za-z]{3}$`, // should be the correct regex
          },
        ],
        placeHolder: "VECJ880326 XXX",
      },
      {
        title: {
          en: "CURP",
          "es-MX": "CURP",
        },
        visibleIf: "{custom_text_1} == 'CURP'",
        type: "text",
        name: "custom_text_4",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct CURP of 18 characters",
              "es-MX": "CURP 18 dígitos",
            }, // Requires translation
            regex: `^[a-zA-Z0-9]{18}$`, // should be the correct regex
          },
        ],
        placeHolder: "HEGG560427MVZRRL04",
      },
      {
        title: { en: "Marital status", "es-MX": "Estado civil" },
        name: "marital",
        isRequired: required,
        type: "dropdown",
        choices: [
          {
            value: "casado",
            text: { en: "Married", "es-MX": "Casado(a)" },
          },
          {
            value: "soltero",
            text: { en: "Married", "es-MX": "Soltero(a)" },
          },
          {
            value: "viudo",
            text: { en: "Widowed", "es-MX": "Viudo(a)" },
          },
          {
            value: "pariente_soltero",
            text: { en: "Single parent", "es-MX": "Padre/Madre soltero(a)" },
          },
          {
            value: "divorciado",
            text: { en: "Divorced", "es-MX": "Divorciado(a)" },
          },
          {
            value: "union_libre",
            text: { en: "Common law", "es-MX": "Unión Libre" },
          },
        ],
      },
      {
        title: { en: "Property regime", "es-MX": "Régimen matrimonial" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_13",
        visibleIf: "{marital} == 'casado'",
        choices: [
          {
            value: "mancomunados",
            text: { en: "Joint", "es-MX": "Mancomunados" },
          },
          {
            value: "separados",
            text: { en: "Separated", "es-MX": "Separados" },
          },
        ],
      },
      {
        title: {
          en: "Partner info",
          "es-MX": "Datos de la pareja",
        },
        type: "panel",
        visibleIf: "{marital} == 'casado' or {marital} == 'union_libre'",
        elements: [
          {
            title: { en: "Full name", "es-MX": "Nombre(s) y apellidos" },
            type: "text",
            name: "custom_text_14",
            isRequired: required,
          },
          {
            title: { en: "Ocupation", "es-MX": "Ocupación" },
            type: "text",
            name: "custom_text_15",
            isRequired: required,
          },
          {
            title: { en: "Salary", "es-MX": "Salario" },
            type: "text",
            inputType: "number",
            name: "custom_integer_4",
            isRequired: required,
          },
          {
            title: { en: "Work place", "es-MX": "Lugar de trabajo" },
            type: "text",
            name: "custom_text_16",
            isRequired: required,
          },
          {
            title: {
              en: "Phone number",
              "es-MX": "Teléfono",
            },
            name: "phone_number_spouse",
            type: "phone",
            isRequired: required,
            validators: [
              expressions.phone_en("phone_number_spouse"),
              expressions.phone_length("phone_number_spouse"),
            ],
          },
        ],
      },
      {
        title: {
          en: "Health servicess available",
          "es-MX": "Acceso a servicios de salud",
        },
        name: "custom_text_17",
        isRequired: required,
        type: "dropdown",
        choices: [
          {
            value: "publico",
            text: { en: "Public", "es-MX": "Público" },
          },
          {
            value: "privado",
            text: { en: "Private", "es-MX": "Privada" },
          },
          {
            value: "imss",
            text: { en: "IMSS ISSSTE", "es-MX": "IMSS ISSSTE" },
          },
          {
            value: "popular",
            text: { en: "Popular", "es-MX": "Popular" },
          },
          {
            value: "none",
            text: { en: "No access", "es-MX": "Sin acceso a servicio" },
          },
        ],
      },
      {
        title: {
          en: "Studies",
          "es-MX": "Nivel escolar",
        },
        name: "custom_text_18",
        isRequired: required,
        type: "dropdown",
        choices: [
          {
            value: "none",
            text: { en: "No studies", "es-MX": "Sin estudios" },
          },
          {
            value: "primaria",
            text: { en: "Primary", "es-MX": "Primaria" },
          },
          {
            value: "secundaria",
            text: { en: "Second grade", "es-MX": "Secundaria" },
          },
          {
            value: "bachillerato",
            text: { en: "High school", "es-MX": "Bachillerato" },
          },
          {
            value: "carrera_tecnica",
            text: { en: "Degree", "es-MX": "Carrera técnica" },
          },
          {
            value: "profesional",
            text: { en: "Professional", "es-MX": "Profesional" },
          },
        ],
      },
      {
        title: { en: "Account number", "es-MX": "No. CTA" },
        type: "text",
        name: "custom_text_19",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter 14 digits",
              "es-MX": "Max 14 dígitos",
            },
            regex: `^[a-zA-Z0-9]{1,14}$`,
          },
        ],
      },
      {
        title: { en: "Account number", "es-MX": "No. cuenta clabe" },
        type: "text",
        name: "custom_text_20",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter 18 digits",
              "es-MX": "18 dígitos",
            },
            regex: `^[a-zA-Z0-9]{18}$`,
          },
        ],
      },
      {
        title: { en: "Job", "es-MX": "Es usted" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_21",
        choices: [
          {
            value: "jefe_hogar",
            text: { en: "Hose boss", "es-MX": "Jefe de hogar" },
          },
          {
            value: "ama_casa",
            text: { en: "House hold", "es-MX": "Ama de casa" },
          },
          {
            value: "hijo_familia",
            text: { en: "Family son", "es-MX": "Hijo de familia" },
          },
          {
            value: "otro",
            text: { en: "other", "es-MX": "Otro" },
          },
        ],
      },
    ],
  },
  // Page 2: Address details
  {
    title: {
      en: "Current address details",
      "es-MX": "Domicilio actual del cliente",
    },
    elements: [
      {
        title: {
          en: "Full Address",
          "es-MX": "Domicilio/ Calle / Número / Interior",
        },
        type: "text",
        name: "home_street",
        isRequired: required,
      },
      {
        title: { en: "Colony", "es-MX": "Colonia o barrio" },
        type: "text",
        name: "custom_text_5",
        isRequired: required,
      },
      {
        title: {
          en: "City",
          "es-MX": "Ciudad o localidad",
        },
        type: "text",
        name: "home_city",
      },
      {
        title: { en: "Postal Code", "es-MX": "Código postal" },
        type: "text",
        name: "custom_text_7",
        isRequired: required,
      },
      {
        title: {
          en: "Municipality/Delegation ",
          "es-MX": "Delegación o municipio",
        },
        type: "text",
        isRequired: required,
        name: "custom_text_6",
      },
      {
        title: {
          en: "Country",
          "es-MX": "País",
        },
        type: "text",
        isRequired: required,
        name: "home_country",
      },
      {
        title: {
          en: "No ppl at home",
          "es-MX": "Tamaño del hogar (número de personas)",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_5",
        isRequired: required,
      },
      {
        title: {
          en: "House type",
          "es-MX": "Tipo de vivienda (especificar)",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_24",
        choices: [
          { value: "Propia", text: { en: "Own", "es-MX": "Propia" } },
          { value: "Rentada ", text: { en: "Rented ", "es-MX": "Rentada " } },
          {
            value: "Familiar",
            text: { en: "Familiar", "es-MX": "Familiar" },
          },
        ],
      },
      {
        title: {
          en: "Time living at current place",
          "es-MX": "Años de vivir en domicilio",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_6",
        isRequired: required,
      },
      {
        title: { en: "Cell number", "es-MX": "Teléfono celular" },
        type: "phone",
        name: "phone_number",
        isRequired: required,
        validators: [
          expressions.phone_en("phone_number"),
          expressions.phone_length("phone_number"),
        ],
      },
      {
        title: { en: "Phone number", "es-MX": "Teléfono fijo" },
        type: "phone",
        name: "mobile_phone_number_2",
        isRequired: required,
        validators: [
          expressions.phone_en("mobile_phone_number_2"),
          expressions.phone_length("mobile_phone_number_2"),
        ],
      },
    ],
  },
  // Page 3: Insurance
  {
    title: {
      en: "Insurance",
      "es-MX": "Amigo Seguro",
    },
    elements: [
      {
        html: {
          en: "Please complete the required information about the applicant.",
          "es-MX": `<p>El Microseguro de  Rentamigo es un plan integral de protección para nuestros clientes, incluye:</p> 
          <ul>
            <li>Seguro de vida por $20,000.- por fallecimiento por cualquier causa del titular asegurado.</li>
            <li>Condonación del adeudo por fallecimiento del titular.</li>
            <li>Condonación del adeudo por fallecimiento del esposo(a) o pareja.</li>
            <li>Seguro hospitalario hasta por $5,000.- ($500.- diarios hasta por 10 días).</li>
          </ul>`,
        },
        type: "html",
      },
      {
        title: {
          en: "Partner data",
          "es-MX": "Datos de la pareja",
        },
        type: "panel",
        visibleIf: "{marital} == 'casado'",
        elements: [
          {
            title: { en: "Full name", "es-MX": "Nombre(s) y apellidos" },
            type: "text",
            name: "custom_text_14",
            isRequired: required,
          },
          {
            title: {
              en: "birth date",
              "es-MX": "Fecha de nacimiento",
            },
            type: "text",
            inputType: "date",
            isRequired: required,
            name: "date_of_birth_spouse",
          },
          {
            title: {
              en: "INE",
              "es-MX": "INE",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_25",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Please enter 13 digits",
                  "es-MX": "Max 13 dígitos",
                }, // Requires translation
                regex: `^[a-zA-Z0-9]{13}$`, // should be the correct regex
              },
            ],
          },
          {
            title: {
              en: "image from the front (INE)",
              "es-MX": "Imagen parte de adelante (INE)",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: required,
            name: "file_2",
          },
          {
            title: {
              en: "image from the back (INE)",
              "es-MX": "Imagen parte de atrás (INE)",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: required,
            name: "file_3",
          },
        ],
      },
      {
        title: {
          en: "Beneficiary data",
          "es-MX": "Datos del beneficiario",
        },
        type: "panel",
        elements: [
          {
            title: { en: "Full name", "es-MX": "Nombre(s) y apellidos" },
            type: "text",
            name: "custom_text_26",
            isRequired: required,
          },
          {
            title: {
              en: "Birth date",
              "es-MX": "Fecha de nacimiento",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_27",
            inputType: "date",
          },
          {
            title: {
              en: "INE",
              "es-MX": "INE",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_28",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "INE 13 digits",
                  "es-MX": "INE 13 dígitos",
                },
                regex: `^[a-zA-Z0-9]{13}$`,
              },
            ],
          },
          {
            title: {
              en: "Image from the front (INE)",
              "es-MX": "Imagen parte de adelante (INE)",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_4",
            isRequired: required,
          },
          {
            title: {
              en: "Image from the back (INE)",
              "es-MX": "Imagen parte de atrás (INE)",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_5",
            isRequired: required,
          },
          {
            html: {
              en: "100% beneficiary",
              "es-MX": "100% beneficiario",
            },
            type: "html",
          },
        ],
      },
    ],
  },
  // Page 4: References
  {
    title: {
      en: "References",
      "es-MX": "Referencias",
    },
    description: {
      en: "Cant be already existing members of the group.",
      "es-MX": "No pueden ser integrantes del grupo. Añadir dos referencias",
    },
    elements: [
      {
        title: {
          en: "Family reference",
          "es-MX": "Referencia familiar",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "Full name",
              "es-MX": "Nombre completo",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_29",
          },
          {
            title: {
              en: "Family relation",
              "es-MX": "Familiar (parentesco)",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_30",
          },
          {
            title: { en: "Cell number", "es-MX": "Teléfono" },
            type: "phone",
            name: "custom_text_31",
            isRequired: required,
            validators: [
              expressions.phone_en("custom_text_31"),
              expressions.phone_length("custom_text_31"),
            ],
          },
        ],
      },
      {
        title: {
          en: "Personal reference",
          "es-MX": "Referencia personal",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "Full name",
              "es-MX": "Nombre completo",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_32",
          },
          {
            title: {
              en: "Personal relation",
              "es-MX": "Personal (relación)",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_33",
          },
          {
            title: { en: "Cell number", "es-MX": "Teléfono" },
            type: "phone",
            name: "custom_text_34",
            isRequired: required,
            validators: [
              expressions.phone_en("custom_text_34"),
              expressions.phone_length("custom_text_34"),
            ],
          },
        ],
      },
    ],
  },
  // Page 5: Business info
  {
    title: {
      en: "Business info",
      "es-MX": "Datos del negocio",
    },
    elements: [
      {
        title: {
          en: "Actividades económicas.",
          "es-MX": "Actividades económicas.",
        },
        type: "panel",
        elements: [
          {
            title: { en: "Primary", "es-MX": "Principal" },
            type: "text",
            name: "custom_text_35",
            isRequired: required,
          },
          {
            title: { en: "Other", "es-MX": "Otras" },
            type: "text",
            name: "custom_text_36",
            isRequired: required,
          },
        ],
      },
      {
        title: {
          en: "Activity description",
          "es-MX": "Descripción código de actividad",
        },
        type: "panel",
        elements: [
          {
            title: { en: "Start date", "es-MX": "Fecha de inicio" },
            type: "text",
            name: "custom_text_37",
            inputType: "date",
            isRequired: required,
          },
          {
            type: "expression",
            name: "custom_integer_7",
            title: { en: "Antigüedad", "es-MX": "Antigüedad (en años)" },
            expression: "calcYearsDifference({custom_text_37})",
          },
          {
            title: { en: "Description", "es-MX": "Descripción" },
            type: "text",
            name: "custom_text_38",
            isRequired: required,
          },
        ],
      },
      {
        title: {
          en: "Local availability",
          "es-MX": "Disponibilidad del local",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "Do you have a place?",
              "es-MX": "¿Cuenta con local?",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_39",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              {
                value: "no",
                text: { en: "No", "es-MX": "No" },
              },
            ],
          },
        ],
      },

      {
        title: {
          en: "How do u finance your business",
          "es-MX": "¿Cómo hace para financiar su negocio?",
        },
        type: "text",
        name: "custom_text_40",
        isRequired: required,
      },
      {
        title: {
          en: "How do u finance your business",
          "es-MX": "Motivo principal para iniciar la actividad",
        },
        type: "text",
        name: "custom_text_22",
        isRequired: required,
      },
      {
        title: {
          en: "Good Months",
          "es-MX": "Meses buenos",
        },
        name: "custom_jsonfield_2",
        type: "matrixdropdown",
        isRequired: false,
        columns: [
          {
            title: {
              en: "Good Months",
              "es-MX": "Meses buenos",
            },
            cellType: "checkbox",
            inputType: "string",
            choices: [{ value: "Bueno" }],
          },
        ],
        rows: [
          { value: "Enero", text: "Enero" }, // --
          { value: "Febrero", text: "Febrero" }, // --
          { value: "Marzo", text: "Marzo" }, // --
          { value: "Abril", text: "Abril" }, // --
          { value: "Mayo", text: "Mayo" }, // --
          { value: "Junio", text: "Junio" }, // --
          { value: "Julio", text: "Julio" }, // --
          { value: "Agosto", text: "Agosto" }, // --
          { value: "sSeptiembreep", text: "Septiembre" }, // --
          { value: "Octubre", text: "Octubre" }, // --
          { value: "Noviembre", text: "Noviembre" }, // --
          { value: "Diciembre", text: "Diciembre" }, // --
        ],
      },

      {
        title: {
          en: "Have you had a public job?",
          "es-MX": "¿Ha desempeñado o desempeña algún cargo público?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_53",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        title: {
          en: "Job",
          "es-MX": "Cargo",
        },
        type: "text",
        name: "custom_text_54",
        visibleIf: "{custom_text_53} == 'si'",
        isRequired: required,
      },
      {
        title: {
          en: "Family member with a public job?",
          "es-MX": "Familiar con cargo público",
        },
        type: "dropdown",
        isRequired: required,
        visibleIf: "{custom_text_53} == 'si'",
        name: "custom_text_55",
        choices: [
          { value: "si", text: { en: "yes", "es-MX": "Sí" } },
          {
            value: "no",
            text: { en: "no", "es-MX": "No" },
          },
        ],
      },
      {
        title: {
          en: "Job",
          "es-MX": "Cargo",
        },
        type: "text",
        name: "custom_text_56",
        isRequired: required,
        visibleIf: "{custom_text_55} == 'si' and {custom_text_53} == 'si'",
      },
      {
        title: {
          en: "Mapped directions to the home",
          "es-MX":
            "Croquis de ubicación de la vivienda del solicitante/cliente",
        },
        description: {
          en: "Draw the indications and take a pic for the iputs bellow",
          "es-MX":
            "Dibuja el croquis en un papel, tómale una foto a tu croquis y súbela donde indica.",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_4",
      },
      {
        title: {
          en: "Tell us how to get to your home          ",
          "es-MX": "Explícanos cómo llegar a tu vivenda",
        },
        type: "text",
        name: "custom_text_59",
        isRequired: required,
      },
      {
        title: {
          en: "Photo's of your home 1/3",
          "es-MX": "Fotos de tu casa 1/3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_5",
      },
      {
        title: {
          en: "Photo's of your home 2/3",
          "es-MX": "Fotos de tu casa 2/3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_6",
      },
      {
        title: {
          en: "Photo's of your home 3/3",
          "es-MX": "Fotos de tu casa 3/3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_7",
      },
      {
        title: {
          en: "Mapped directions to the business",
          "es-MX":
            "Croquis de la ubicación del negocio del solicitante/cliente",
        },
        description: {
          en: "Draw the indications and take a pic for the iputs bellow",
          "es-MX":
            "Dibuja el croquis en un papel, tómale una foto a tu croquis y súbela donde indica.",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_8",
      },
      {
        title: {
          en: "Tell us how to get to your business",
          "es-MX": "Explícanos cómo llegar a tu negocio",
        },
        type: "text",
        name: "custom_text_60",
        isRequired: required,
      },
      {
        title: {
          en: "Photos of your business 1/3",
          "es-MX": "Fotos de tu negocio 1/3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_9",
      },
      {
        title: {
          en: "Photos of your business 2/3",
          "es-MX": "Fotos de tu negocio 2/3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_10",
      },
      {
        title: {
          en: "Photos of your business 3/3",
          "es-MX": "Fotos de tu negocio 3/3",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "picture_11",
      },
    ],
  },
  // Page 6: Credit details
  {
    title: {
      en: "Credit details",
      "es-MX": "Detalles del crédito",
    },
    elements: [
      {
        title: { en: "Antigüedad", "es-MX": "Monto solicitado $" },
        name: "amount", // only editable by primary loan user
        type: "nouislider",
        step: 1,
        rangeMin: 3000,
        rangeMaxStart: 40000,
        maxStep: 100,
        rangeMax: 40000,
        isRequired: required,
      },
      {
        title: {
          en: "Time to pay",
          "es-MX": "Plazo",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_57", // only editable by primary loan user
        choices: [
          { value: "12", text: { en: "12 weeks", "es-MX": "12 semanas" } },
          {
            value: "16",
            text: { en: "16 weeks", "es-MX": "16 semanas" },
          },
        ],
      },
      {
        title: {
          en: "Credit destiny",
          "es-MX": "Destino del crédito",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_58",
        choices: [
          {
            value: "Compra_local_vehiculo",
            text: { en: "12 weeks", "es-MX": "Compra local o vehículo" },
          },
          {
            value: "ampliar",
            text: { en: "expand", "es-MX": "Ampliar" },
          },
          {
            value: "adecuar_local_vehiculo",
            text: {
              en: "adecuate vehicle or place",
              "es-MX": "Adecuar o reparar el local o el vehículo",
            },
          },
          {
            value: "adquirir_mercancia",
            text: {
              en: "Buy products",
              "es-MX": "Adquirir o comprar mercancían",
            },
          },
          {
            value: "pagar_deudas",
            text: { en: "Pay debts", "es-MX": "Pagar deudas del negocio" },
          },
          {
            value: "comprar_maquinaria",
            text: {
              en: "Buy asset",
              "es-MX": "Comprar maquinaria, equipo o heramientas",
            },
          },
          {
            value: "otro_fin_negocio",
            text: {
              en: "Another thing for the business",
              "es-MX": "Otro fin relacionado",
            },
          },
          {
            value: "otro_fin_ajeno",
            text: {
              en: "Not for the business",
              "es-MX": "Fines ajenos al negocio",
            },
          },
        ],
      },
      {
        title: {
          en: "Name",
          "es-MX": "Nombre del solicitante",
        },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: {
          en: "signature",
          "es-MX": "Firma",
        },
        type: "signaturepad",
        isRequired: required,
        name: "signature",
      },
    ],
  },
];
