import React from "react";
import { colors } from "../../theme/theme";
import { translate } from "../../lib/intl";
import { Button } from "../../components/button/button";
import { connect } from "react-redux";
import { loanActions } from "../../store/store";
import HelpComponent from "../../components/help/help";

/**
 * This container is used to when the workflow for the guarantor is 'paused'.
 * This usually happens when the workflow relies on actions from multiple
 * users.
 *
 * Use the transitionLoanState action to move the loan to the next state.
 */
export class GroupGuarantorIntakeIncomplete extends React.PureComponent {
  render({ loan, device } = this.props) {
    return (
      <div className="message">
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />

        <h2 className="bigSpacer">{translate(`group_intake.header`)}</h2>

        {/** Only visible when waiting group intakes */}
        {loan.intake_guarantor.is_completed && (
          <p className="center small bigSpacer">
            {translate(`group_intake.guarantor_waiting_group_intakes_message`)}
          </p>
        )}

        {!loan.intake_guarantor.is_completed && (
          <p className="center small bigSpacer">
            {translate(`group_intake.guarantor_intake_incomplete_message`)}
          </p>
        )}

        {/** Waiting group intakes */}
        {!loan.intake_guarantor.is_completed &&
          loan.state === "group_guarantor_intake_incomplete" && (
            <Button
              style={{ width: "240px" }}
              backgroundColor={colors.brand.primary}
              color="#fff"
              title={translate(`group_intake.button_title`)}
              onPress={() =>
                this.props.transitionLoanState(
                  loan.uuid,
                  "guarantor_start_applying"
                )
              }
            />
          )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    transitionLoanState: (uuid, action) => {
      dispatch(loanActions.transitionLoanState(uuid, action));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loan: state.loan,
    device: state.device,
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupGuarantorIntakeIncomplete);

export { connectedContainer as GroupGuarantorIntakeIncompleteContainer };
