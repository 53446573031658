export const authenticationState = {
  loginEmailPending: false,
  signupEmailPending: false,

  Error: '',

  passwordResetPending: false,

  logoutPending: false,
  logoutError: null,

};
