import Config from "../../config";
import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const intake_banco_popular_pages_new_client = [
  // Page 1: Business Information (new clients)
  {
    name: "Type of client",
    title: {
      en: "Select what type of client you are",
      "es-HN": "Selecciona qué tipo de cliente eres",
    },
    elements: [
      {
        type: "dropdown",
        name: "custom_text_6",
        title: {
          "es-HN": "Tipo de cliente",
          en: "Type of client ",
        },
        isRequired: required,
        choices: [
          {
            value: "micro",
            text: {
              en: "Micro",
              "es-HN": "Micro",
            },
          },
          {
            value: "asalariado",
            text: {
              en: "Salaried",
              "es-HN": "Asalariado",
            },
          },
        ],
      },
    ],
  },
  {
    name: "micro_page",
    title: {
      en: "Business information",
      "es-HN": "Información del negocio",
    },
    visibleIf:
      "{purpose_question} == 'new_client' and {custom_text_6} == 'micro'",
    elements: [
      {
        name: "custom_text_7",
        title: {
          en: "Type of business ",
          "es-HN": "Tipo de negocio",
        },
        type: "text",
        isRequired: required,
      },
      {
        name: "custom_text_8",
        title: {
          en: "Activity that develops",
          "es-HN": "Actividad que desarrolla",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.act_economicas || [],
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_text_9",
        title: {
          en: "Name of the business",
          "es-HN": "Nombre del negocio",
        },
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_integer_2",
        title: {
          en: "Years in operation",
          "es-HN": "Años de operación",
        },
        isRequired: required,
      },
      {
        type: "radiogroup",
        name: "custom_text_10",
        title: {
          en: "Do you have an operating permit ",
          "es-HN": "Cuenta con Permiso Op.",
        },
        isRequired: required,
        colCount: 2,
        choices: [
          {
            value: "si",
            text: {
              en: "Yes",
              "es-HN": "Sí",
            },
          },
          {
            value: "no",
            text: {
              en: "No",
              "es-HN": "No",
            },
          },
        ],
      },
      {
        name: "custom_text_11",
        title: {
          en: "Type of business premises ",
          "es-HN": "Tipo de local del negocio",
        },
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_local_negocio || [],
      },
      {
        type: "text",
        name: "custom_float_1",
        visibleIf: "{custom_text_11} == 'alquilado'",
        title: {
          en: "Rental value",
          "es-HN": "Valor de alquiler",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_float_34",
        visibleIf: "{custom_text_11} == 'alquilado'",
        title: {
          en: "Rental time",
          "es-HN": "Tiempo de alquiler",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "phone",
        name: "current_job_phone",
        visibleIf: "{custom_text_11} == 'alquilado'",
        title: {
          "es-HN": "Teléfono del negocio",
          en: "Business phone number",
        },
        isRequired: required,
        validators: [
          expressions.phone_en("current_job_phone"),
          expressions.phone_length("current_job_phone"),
        ],
      },
      {
        type: "text",
        name: "custom_integer_4",
        visibleIf: "{custom_text_11} == 'alquilado'",
        title: {
          "es-HN": "N. de empleados",
          en: "Number of employees ",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "panel",
        title: {
          en: "Business address",
          "es-HN": "Dirección del negocio",
        },
        elements: [
          {
            name: "custom_text_12",
            title: {
              en: "Business City",
              "es-HN": "Ciudad",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.ciudades || [],
            isRequired: required,
          },
          {
            name: "custom_text_13",
            title: {
              en: "Business department",
              "es-HN": "Departamento",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.departamento || [],
            isRequired: required,
          },
          {
            title: {
              en: "Operating Permit Photo",
              "es-HN": "Foto de Permiso de Operación",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_4",
            isRequired: required,
          },
          {
            title: {
              en: "Photo of the front of the business",
              "es-HN": "Foto frontal del negocio",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_5",
            isRequired: required,
          },
          {
            title: {
              en: "Photo of Public Address Receipt",
              "es-HN": "Foto Recibo público domicilio",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "picture_6",
            isRequired: required,
          },
        ],
      },
    ],
  },
  {
    name: "asalaradio_page",
    title: {
      en: "Salaried info",
      "es-HN": "Información Asalariado",
    },
    visibleIf:
      "{purpose_question} == 'new_client' and {custom_text_6} == 'asalariado'",
    elements: [
      {
        type: "text",
        name: "custom_text_14",
        title: {
          en: "Place of work ",
          "es-HN": "Lugar de trabajo",
        },
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_text_15",
        title: {
          en: "Position held",
          "es-HN": "Puesto que desempeña",
        },
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_text_35",
        title: {
          en: "Seniority of client working in the company",
          "es-HN": "Antigüedad del cliente trabajando en la empresa",
        },
        isRequired: required,
      },
      {
        type: "phone",
        name: "custom_text_16",
        title: {
          en: "Company phone number",
          "es-HN": "Teléfono de la empresa",
        },
        isRequired: required,
        validators: [
          expressions.phone_en("custom_text_16"),
          expressions.phone_length("custom_text_16"),
        ],
      },
      {
        type: "text",
        name: "email",
        title: {
          en: "Email ",
          "es-HN": "Correo",
        },
        isRequired: required,
        inputType: "email",
        validators: [{ type: "email" }],
      },
      {
        type: "text",
        name: "custom_float_2",
        title: {
          en: "Total salary ",
          "es-HN": "Salario total",
        },
        isRequired: required,

        inputType: "number",
      },
      {
        type: "text",
        name: "custom_float_3",
        title: {
          en: "Net salary ",
          "es-HN": "Salario neto",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_3")],
      },
      {
        type: "dropdown",
        name: "custom_text_17",
        title: {
          en: "Method of payment",
          "es-HN": "Método de pago",
        },
        isRequired: required,
        choices: [
          {
            value: "DEPÓSITO A CUENTA",
            text: { en: "Account deposit", "es-HN": "DEPÓSITO A CUENTA" },
          },
          { value: "CHEQUE", text: { en: "Check", "es-HN": "CHEQUE" } },
          { value: "EFECTIVO", text: { en: "Cash", "es-HN": "EFECTIVO" } },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_18",
        title: {
          en: "Days of payment ",
          "es-HN": "Días de pago",
        },
        isRequired: required,
        choices: [
          { value: "semanal", text: { en: "WEEKLY", "es-HN": "SEMANAL" } },
          {
            value: "quincenal",
            text: { en: "BIWEEKLY", "es-HN": "QUINCENAL" },
          },
          { value: "mensual", text: { en: "MONTHLY", "es-HN": "MENSUAL" } },
        ],
      },
      {
        name: "payment_date_panel",
        type: "panel",
        innerIndent: 2,
        title: {
          en: "Exact date of payment",
          "es-HN": "Fecha exacta de pago",
        },
        elements: [
          {
            type: "dropdown",
            name: "date_type_2",
            title: {
              en: "Enter date with:",
              "es-HN": "Ingresar fecha con:",
            },
            defaultValue: "calendario",
            choices: [
              {
                value: "calendario",
                text: { en: "Calendar", "es-HN": "Calendario" },
              },
              { value: "texto", text: { en: "Text", "es-HN": "Texto" } },
            ],
          },
          {
            visibleIf: "{date_type_2} == 'calendario'",
            type: "text",
            name: "custom_text_19",
            isRequired: required,
            inputType: "date",
            title: {
              en: "Exact date of payment",
              "es-HN": "Fecha exacta de pago",
            },
          },
          {
            visibleIf: "{date_type_2} == 'texto'",
            name: "custom_text_19",
            type: "text",
            title: {
              en: "Exact date of payment",
              "es-HN": "Fecha exacta de pago",
            },
            placeHolder: "aaaa-mm-dd",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Enter date with the format yyyy-mm-dd",
                  "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                },
                regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
              },
            ],
          },
        ],
      },
      {
        type: "text",
        name: "custom_text_20",
        title: {
          en: "Company address",
          "es-HN": "Dirección de la empresa",
        },
        isRequired: required,
        maxLength: 40,
      },
      {
        name: "custom_text_21",
        title: {
          en: "Company city",
          "es-HN": "Ciudad",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.ciudades || [],
        isRequired: required,
      },
      {
        name: "custom_text_22",
        title: {
          en: "Company department ",
          "es-HN": "Departamento",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.departamento || [],
        isRequired: required,
      },
      {
        title: {
          en: "Photo proof of employment/recent pay slip",
          "es-HN": "Foto constancia de trabajo/Baucher reciente de pago",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_7",
        isRequired: required,
      },
    ],
  },
  // Page 2: Spouse info (only if married) (new clients)
  {
    name: "Spouse information",
    visibleIf: "{marital} == 'CASADO' || {marital} == 'UNIDO'",
    title: {
      en: "Spouse information",
      "es-HN": "Información del cónyuge ",
    },
    elements: [
      {
        type: "text",
        name: "full_name_spouse",
        title: {
          en: "Full name spouse",
          "es-HN": "Nombre completo",
        },
        isRequired: required,
      },
      {
        name: "custom_text_23",
        title: {
          en: "Type of document",
          "es-HN": "Tipo de documento",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_documentos || [],
        isRequired: required,
      },
      {
        type: "text",
        name: "id_number_spouse",
        title: {
          en: "ID number spouse",
          "es-HN": "Número de documento",
        },
        isRequired: required,
        maxLength: 18,
      },
      {
        type: "text",
        name: "custom_integer_6",
        title: { en: "Age spouse", "es-HN": "Edad" },
        isRequired: required,
        inputType: "number",
        maxLength: 2,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Answer the question",
              "es-HN": "Responda la pregunta",
            },
            regex: `^[1-9][0-9]+$`,
          },
        ],
      },
      {
        name: "current_job_spouse",
        title: {
          en: "Profession/Trade/Current occupation",
          "es-HN": "Profesión/Oficio/Dedicación actual",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.profesiones || [],
        isRequired: required,
      },
      {
        type: "dropdown",
        name: "custom_text_24",
        title: {
          en: "Educational level spouse",
          "es-HN": "Nivel educativo",
        },
        isRequired: required,
        choices: Config.nivel_educativo || [],
      },
      {
        type: "dropdown",
        name: "custom_text_25",
        title: {
          en: "Gender spouse",
          "es-HN": "Género",
        },
        isRequired: required,
        choices: [
          { value: "masculino", text: { en: "Male", "es-HN": "Masculino" } },
          { value: "femenino", text: { en: "Female", "es-HN": "Femenino" } },
        ],
      },
      {
        name: "nationality_spouse",
        title: {
          en: "Nationality spouse",
          "es-HN": "Nacionalidad",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.nacionalidad || [],
        isRequired: required,
      },
      {
        type: "phone",
        name: "phone_number_spouse",
        title: {
          en: "Phone number spouse",
          "es-HN": "Celular",
        },
        isRequired: required,
        validators: [
          expressions.phone_en("phone_number_spouse"),
          expressions.phone_length("phone_number_spouse"),
        ],
      },
      {
        type: "text",
        name: "job_address_spouse",
        title: {
          en: "Place of work spouse",
          "es-HN": "Lugar de trabajo",
        },
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_float_4",
        title: {
          en: "Monthly income spouse",
          "es-HN": "Ingresos mensuales",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_text_26",
        title: {
          en: "Contribution to household expenditure ",
          "es-HN": "Aporte al gasto familiar",
        },
        isRequired: required,
      },
    ],
  },
  // Page 3: Credit application (new clients)
  {
    name: "Credit application",
    title: {
      en: "Credit application",
      "es-HN": "Solicitud de crédito",
    },
    elements: [
      {
        //Micro slider-new client
        visibleIf: "{custom_text_6} == 'micro'",
        type: "nouislider",
        name: "amount",
        title: {
          en: "Loan amount",
          "es-HN": "Monto solicitado",
        },
        description: {
          en: "Indicate the amount you want to request in HNL",
          "es-HN": "Indica el monto que quieres solicitar en HNL",
        },
        step: 10,
        rangeMin: 6000,
        maxStep: 500,
        rangeMax: 720000,
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 6000,
            text: {
              en: "Please enter the requested amount",
              "es-HN": "Ingresa la cantidad a solicitar",
            },
          },
        ],
      },
      {
        //Asalariado slider-new client
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "nouislider",
        name: "amount",
        title: {
          en: "Loan amount",
          "es-HN": "Monto solicitado",
        },
        description: {
          en: "Indicate the amount you want to request in HNL",
          "es-HN": "Indica el monto que quieres solicitar en HNL",
        },
        step: 10,
        rangeMin: 6000,
        maxStep: 500,
        rangeMax: 220000,
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 6000,
            text: {
              en: "Please enter the requested amount",
              "es-HN": "Ingresa la cantidad a solicitar",
            },
          },
        ],
      },
      {
        // loan_term salary
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 1,
            maxValue: 36,
            text: {
              en: "Enter a number between 1 and 36",
              "es-HN": "Ingresa un número entre 1 y 36",
            },
          },
        ],
      },
      {
        // loan_term micro 1
        visibleIf:
          "{amount} >= 6000 && {amount} <= 15000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 3,
            maxValue: 12,
            text: {
              en: "Enter a number between 3 and 12",
              "es-HN": "Ingresa un número entre 3 y 12",
            },
          },
        ],
      },
      {
        // loan_term micro 2
        visibleIf:
          "{amount} > 15000 && {amount} <= 50000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 6,
            maxValue: 18,
            text: {
              en: "Enter a number between 6 and 18",
              "es-HN": "Ingresa un número entre 6 y 18",
            },
          },
        ],
      },
      {
        // loan_term micro 3
        visibleIf:
          "{amount} > 50000 && {amount} <= 200000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 12,
            maxValue: 24,
            text: {
              en: "Enter a number between 12 and 24",
              "es-HN": "Ingresa un número entre 12 y 24",
            },
          },
        ],
      },
      {
        // loan_term micro 4
        visibleIf:
          "{amount} > 200000 && {amount} <= 720000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 12,
            maxValue: 30,
            text: {
              en: "Enter a number between 12 and 30",
              "es-HN": "Ingresa un número entre 12 y 30",
            },
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_5",
        title: {
          en: "Fee you can pay",
          "es-HN": "Cuota que puedes pagar",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        name: "custom_text_27",
        title: {
          en: "Type of payment",
          "es-HN": "Forma de pago",
        },
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.modalidades || [],
      },
      {
        name: "requested_payment_date_panel",
        type: "panel",
        innerIndent: 2,
        title: {
          en: "Requested date of payment",
          "es-HN": "Fecha solicitada de pago",
        },
        elements: [
          {
            type: "dropdown",
            name: "date_type_3",
            title: {
              en: "Enter date with:",
              "es-HN": "Ingresar fecha con:",
            },
            defaultValue: "calendario",
            choices: [
              {
                value: "calendario",
                text: { en: "Calendar", "es-HN": "Calendario" },
              },
              { value: "texto", text: { en: "Text", "es-HN": "Texto" } },
            ],
          },
          {
            visibleIf: "{date_type_3} == 'calendario'",
            type: "text",
            name: "custom_text_28",
            isRequired: required,
            inputType: "date",
            title: {
              en: "Requested date of payment",
              "es-HN": "Fecha solicitada de pago",
            },
          },
          {
            visibleIf: "{date_type_3} == 'texto'",
            name: "custom_text_28",
            type: "text",
            title: {
              en: "Requested date of payment",
              "es-HN": "Fecha solicitada de pago",
            },
            placeHolder: "aaaa-mm-dd",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Enter date with the format yyyy-mm-dd",
                  "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                },
                regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
              },
            ],
          },
        ],
      },
      {
        name: "custom_text_29",
        title: {
          en: "Guarantee offered",
          "es-HN": "Garantía ofrecida",
        },
        isRequired: required,
        type: "dropdown",
        hasOther: true,
        otherText: { en: "OTHER", "es-HN": "OTRA" },
        renderAs: "select2",
        choices: Config.garantias || [],
      },
      {
        title: {
          en: "Document of the offered guarantee",
          "es-HN": "Documento de la garantía ofrecida",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_11",
        requiredIf: "{custom_text_29} != 'FIDUCIARIO'",
      },
      {
        type: "text",
        name: "custom_text_30",
        title: {
          en: "Tell us in what you are going to invest your money",
          "es-HN": "Cuéntanos en qué invertirás tu crédito",
        },
        isRequired: required,
      },
    ],
  },
  // Page 4: Financial and Equity Information (new clients)
  {
    name: "Financial and Equity Information",
    title: {
      en: "Financial and Equity Information",
      "es-HN": "Información Financiera y Patrimonial",
    },
    description: {
      en: "Please provide real information",
      "es-HN": "Favor brindar información real",
    },
    elements: [
      {
        visibleIf: "{custom_text_6} == 'micro'",
        type: "text",
        name: "custom_integer_7",
        title: {
          en: "How many days per week do you sell or work?",
          "es-HN": "¿Cuántos días a la semana vendes o trabajas?",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a value between 1 and 7 days",
              "es-HN": "Por favor, añada un valor entre 1 y 7 días",
            },
            regex: `^[1-7]$`,
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_6",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Normal day sales amount ",
          "es-HN": "Monto de venta día normal",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_float_7",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Bad day sales amount",
          "es-HN": "Monto de venta día malo",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_integer_8",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Good days of the week ",
          "es-HN": "Días buenos de la semana",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a value between 1 and 7 days",
              "es-HN": "Por favor, añada un valor entre 1 y 7 días",
            },
            regex: `^[1-7]$`,
          },
        ],
      },
      {
        type: "text",
        name: "custom_integer_9",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Bad days of the week ",
          "es-HN": "Días malos de la semana",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a value between 1 and 7 days",
              "es-HN": "Por favor, añada un valor entre 1 y 7 días",
            },
            regex: `^[1-7]$`,
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_8",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "How much does your business sell per month? ",
          "es-HN": "¿Cuánto vende tu negocio al mes?",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_8")],
      },
      {
        type: "text",
        name: "custom_float_10",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Average weekly purchase ",
          "es-HN": "Compra promedio a la semana",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "radiogroup",
        name: "custom_text_31",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Do you carry invoices ",
          "es-HN": "¿Llevas facturas?",
        },
        isRequired: required,
        colCount: 2,
        choices: [
          {
            value: "si",
            text: {
              en: "Yes",
              "es-HN": "Sí",
            },
          },
          {
            value: "no",
            text: {
              en: "No",
              "es-HN": "No",
            },
          },
        ],
      },
      {
        title: {
          en: "Photo of your last purchase invoice ",
          "es-HN": "Foto de tu última factura de compra",
        },
        visibleIf: "{custom_text_6} == 'micro'",
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_8",
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_float_11",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Cash on register ",
          "es-HN": "Efectivo en caja",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_11")],
      },
      {
        type: "text",
        name: "custom_float_42",
        visibleIf: "{custom_text_6} == 'asalariado'",
        title: {
          en: "Cash on hand ",
          "es-HN": "Efectivo disponible con el que cuentas",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_42")],
      },
      {
        type: "text",
        name: "custom_float_12",
        title: {
          en: "Available in bank",
          "es-HN": "Disponible en banco",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_12")],
      },
      {
        title: {
          en: "Bankbook photo",
          "es-HN": "Foto de libreta de ahorros",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_9",
        isRequired: required,
      },
      {
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Inventory photo",
          "es-HN": "Sube una foto de parte de tu inventario",
        },
        name: "custom_jsonfield_2",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 3,
        rowCount: 1,
        isRequired: required,
        addRowText: {
          en: "Add another photo",
          "es-HN": "Añadir foto",
        },
        columns: [
          {
            title: {
              en: "Photo",
              "es-HN": "Foto de inventario",
            },
            name: "photo",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: false,
          },
        ],
      },
      {
        type: "radiogroup",
        name: "custom_text_33",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Accounts receivable",
          "es-HN": "¿Cuentas por cobrar?",
        },
        isRequired: required,
        colCount: 2,
        choices: [
          { value: "si", text: { en: "Yes", "es-HN": "Sí" } },
          { value: "no", text: { en: "No", "es-HN": "No" } },
        ],
      },
      {
        type: "text",
        name: "custom_float_41",
        visibleIf: "{custom_text_33} == 'si' && {custom_text_6} == 'micro'",
        title: {
          en: "Amount of the accounts receivable",
          "es-HN": "Monto de las cuentas por cobrar",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_41")],
      },
      {
        type: "text",
        name: "custom_integer_11",
        visibleIf: "{custom_text_33} == 'si' && {custom_text_6} == 'micro'",
        title: {
          "es-HN": "Antigüedad de las cuentas",
          en: "Age of the accounts",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "text",
        name: "custom_float_13",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Investment in business assets. (equipment, machinery, vehicles tec.)",
          "es-HN":
            "Inversión en activos del negocio (equipo, maquinaria, vehículos)",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_13")],
      },
      {
        visibleIf: "{custom_text_6} == 'micro'",
        type: "text",
        name: "custom_float_36",
        title: {
          en: "Approximate inventory amount",
          "es-HN": "Monto aproximado de inventario",
        },
        isRequired: required,
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_36")],
      },
      {
        visibleIf: "{custom_text_6} == 'micro'",
        type: "text",
        name: "custom_float_37",
        title: {
          en: "Investment in business properties",
          "es-HN": "Inversión en propiedades del negocio (casas, terrenos.)",
        },
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_37")],
      },
      {
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "text",
        name: "custom_float_38",
        title: {
          en: "Value of furniture in the home",
          "es-HN": "Valor en menaje de casa",
        },
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_38")],
      },
      {
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "text",
        name: "custom_float_39",
        title: {
          en: "Vehicle value",
          "es-HN": "Valor de vehículo",
        },
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_39")],
      },
      {
        visibleIf: "{custom_text_6} == 'asalariado'",
        title: {
          en: "Verification picture",
          "es-HN": "Foto de revisión",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_14",
      },
      {
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "text",
        name: "custom_float_40",
        title: {
          en: "Land value",
          "es-HN": "Valor en propiedades (terrenos, casas, etc)",
        },
        inputType: "number",
        validators: [expressions.amount_greater_than_zero("custom_float_40")],
      },
    ],
  },
  // Page 5: Guarantor Information (new clients)
  {
    name: "Guarantor Information",
    title: {
      en: "Guarantor Information",
      "es-HN": "Informacion del aval",
    },
    elements: [
      {
        type: "html",
        html: {
          en: "Enter the necessary information so that we can contact your guarantor",
          "es-HN":
            "Ingrese la información necesaria para que podamos contactar a su aval",
        },
      },
      {
        title: {
          en: "Guarantor #1 details",
          "es-HN": "Detalles del aval #1",
        },
        name: "panel_guarantor_1",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "guarantor_first_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Guarantor Full Name",
              "es-HN": "Nombre completo del aval",
            },
          },
          {
            name: "guarantor_phone",
            type: "phone",
            isRequired: required,
            title: {
              en: "Guarantor Mobile No.",
              "es-HN": "Número de teléfono del aval",
            },
            validators: [
              expressions.phone_length("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone"),
              expressions.compare_phone_numbers("guarantor_phone", "guarantor_2_phone", "Número del aval 1", "Número del aval 2")
            ],
          },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_37",
        title: {
          en: "Would you like to add another guarantor",
          "es-HN": "¿Desea agregar otro aval?",
        },
        description: {
          en: "If you guarantor cannot be contacted or the credit check gets rejected, we will contact the second guarantor",
          "es-HN":
            "Si no se puede contactar al primer aval o su revisión de crédito es rechazada, contactaremos al segundo aval",
        },
        defaultValue: "no",
        choices: [
          { value: "si", text: { en: "Yes", "es-HN": "Sí" } },
          { value: "no", text: { en: "No", "es-HN": "No" } },
        ],
      },
      {
        title: {
          en: "Guarantor #2 details",
          "es-HN": "Detalles del aval #2",
        },
        name: "panel_guarantor_2",
        visibleIf: "{custom_text_37} == 'si'",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "guarantor_2_first_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Reference Full Name",
              "es-HN": "Nombre completo del aval",
            },
          },
          {
            name: "guarantor_2_phone",
            type: "phone",
            isRequired: required,
            title: {
              en: "Guarantor Mobile No.",
              "es-HN": "Número de teléfono del aval",
            },
            validators: [
              expressions.phone_length("guarantor_2_phone"),
              expressions.same_phone_number("guarantor_2_phone"),
              expressions.compare_phone_numbers("guarantor_phone", "guarantor_2_phone", "Número del aval 1", "Número del aval 2")
            ],
          },
        ],
      },
    ],
  },
  // Page 6: Information on your debts and expenses (new clients)
  {
    name: "Information on your debts and expenses",
    title: {
      en: "information on your debts and expenses",
      "es-HN": "Deudas y gastos",
    },
    elements: [
      {
        type: "html",
        html: {
          en: "The information provided in this form will be validated with the risk central",
          "es-HN":
            "La información que proporcione en este cuestionario será validada con la central de riesgo",
        },
      },
      {
        type: "matrixdynamic",
        name: "custom_jsonfield_1",
        title: {
          en: "Information on your current debts",
          "es-HN": "Información de tus deudas vigentes",
        },
        description: {
          en: "Add up until 4 debts",
          "es-HN": "Suma hasta 4 deudas",
        },
        isRequired: required,
        minRowCount: 0,
        maxRowCount: 4,
        rowCount: 1,
        addRowText: {
          en: "Add",
          "es-HN": "Añadir",
        },
        columns: [
          {
            name: "nombre_de_la_institución",
            cellType: "text",
            inputType: "string",
            title: {
              en: "Name of the institution",
              "es-HN": "Nombre de la institución",
            },
            maxLength: 100,
            isRequired: required,
          },
          {
            name: "saldo",
            cellType: "text",
            inputType: "number",
            title: {
              en: "Balance",
              "es-HN": "Saldo",
            },
            isRequired: required,
          },
          {
            name: "cuota",
            cellType: "text",
            inputType: "number",
            title: {
              en: "Fee",
              "es-HN": "Cuota",
            },
            isRequired: required,
          },
          {
            name: "plazo",
            cellType: "text",
            inputType: "string",
            title: {
              en: "Term (months)",
              "es-HN": "Plazo (meses)",
            },
            isRequired: required,
          },
        ],
      },
      {
        name: "custom_float_32",
        type: "expression",
        title: {
          "es-HN": "Total de la cuota",
          en: "Total fee",
        },
        expression: "sumInArray({custom_jsonfield_1}, 'cuota')",
        displayStyle: "currency",
        currency: "HNL",
      },
      {
        type: "panel",
        title: {
          "es-HN": "Gastos familiares",
          en: "Familiar costs",
        },
        elements: [
          {
            name: "custom_float_14",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Monthly expenditure on food",
              "es-HN": "Gasto al mes en Alimentación",
            },
            displayStyle: "currency",
            currency: "HNL",
            validators: [expressions.amount_greater_than_zero("custom_float_14")],
          },
          {
            name: "custom_float_15",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Expenditure per month on Education",
              "es-HN": "Gasto al mes en Educación",
            },
            displayStyle: "currency",
            currency: "HNL",
          },
          {
            name: "custom_float_16",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Monthly expenditure on public services",
              "es-HN": "Gasto al mes en Servicios Públicos",
            },
            displayStyle: "currency",
            currency: "HNL",
            validators: [expressions.amount_greater_than_zero("custom_float_16")],
          },
          {
            name: "custom_float_17",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Spending on Rents",
              "es-HN": "Gasto en Alquileres",
            },
            displayStyle: "currency",
            currency: "HNL",
          },
          {
            name: "custom_float_18",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Health expenditure per month.",
              "es-HN": "Gasto al mes en Salud",
            },
            displayStyle: "currency",
            currency: "HNL",
            validators: [expressions.amount_greater_than_zero("custom_float_18")],
          },
          {
            name: "custom_float_19",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Business expenses",
              "es-HN": "Gastos del negocio",
            },
            displayStyle: "currency",
            currency: "HNL",
          },
          {
            name: "custom_float_20",
            type: "text",
            isRequired: required,
            inputType: "number",
            title: {
              en: "Unforeseen",
              "es-HN": "Imprevistos",
            },
            displayStyle: "currency",
            currency: "HNL",
          },
        ],
      },
      {
        name: "custom_float_33",
        type: "expression",
        title: {
          "es-HN": "Total gastos familiares",
          en: "Total family expenses",
        },
        expression:
          "sum({custom_float_14},{custom_float_15},{custom_float_16},{custom_float_17},{custom_float_18},{custom_float_19},{custom_float_20})",
        displayStyle: "currency",
        currency: "HNL",
      },
      {
        type: "panel",
        title: {
          en: "By submitting this form, you authorise Banco Popular to verify and request information about your accounts or obligations in public or private credit bureaus.",
          "es-HN":
            "Al enviar este formulario, autorizas a Banco Popular que pueda verificar y solicitar información sobre tus cuentas u obligaciones en las centrales de riesgos públicas o privadas",
        },
        elements: [
          {
            type: "expression",
            title: {
              en: "Sign date",
              "es-HN": "Fecha de firma",
            },
            name: "sign_date",
            expression: `daysFromNow(0, 0, 'es-HN')`,
          },
          {
            type: "signaturepad",
            name: "signature",
            title: {
              en: "Online signature",
              "es-HN": "Firma online",
            },
            isRequired: required,
          },
        ],
      },
    ],
  },
];

export const intake_banco_popular_pages_existing_client = [
  {
    name: "Type of client",
    title: {
      en: "Select what type of client you are",
      "es-HN": "Selecciona qué tipo de cliente eres",
    },
    elements: [
      {
        type: "dropdown",
        name: "custom_text_6",
        title: {
          "es-HN": "Tipo de cliente",
          en: "Type of client ",
        },
        isRequired: required,
        choices: [
          {
            value: "micro",
            text: {
              en: "Micro",
              "es-HN": "Micro",
            },
          },
          {
            value: "asalariado",
            text: {
              en: "Salaried",
              "es-HN": "Asalariado",
            },
          },
        ],
      },
    ],
  },
  // Page 1: Updating general data (existing client)
  {
    name: "Updating general data",
    title: {
      en: "Updating general data",
      "es-HN": "Actualización de datos generales",
    },
    elements: [
      {
        type: "panel",
        name: "Update your data",
        title: {
          en: "Update your data ",
          "es-HN": "Actualiza tus datos",
        },
        elements: [
          {
            name: "custom_text_1",
            title: {
              en: "Current Profession / Occupation / Dedication",
              "es-HN": "Profesión/ Oficio / Dedicación actual",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.profesiones || [],
            isRequired: required,
          },
          {
            name: "custom_text_2",
            title: {
              en: "Educational level",
              "es-HN": "Nivel educativo",
            },
            isRequired: required,
            type: "dropdown",
            renderAs: "select2",
            choices: Config.nivel_educativo || [],
          },
          {
            title: { en: "Marital status", "es-HN": "Estado civil" },
            name: "marital",
            isRequired: required,
            type: "dropdown",
            renderAs: "select2",
            choices: Config.estado_civil || [],
          },
        ],
      },
      {
        name: "If married or cohabiting",
        visibleIf: "{marital} == 'casado' or {marital} == 'unido'",
        title: {
          en: "Spouse information",
          "es-HN": "Información del cónyuge ",
        },
        type: "panel",
        elements: [
          {
            type: "text",
            name: "full_name_spouse",
            title: {
              en: "Partner full name",
              "es-HN": "Nombre completo del conyuge",
            },
            isRequired: required,
          },
          {
            name: "custom_text_23",
            title: {
              en: "Type of document",
              "es-HN": "Tipo de documento",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.tipo_documentos || [],
            isRequired: required,
          },
          {
            type: "text",
            name: "id_number_spouse",
            title: {
              en: "ID number spouse",
              "es-HN": "Número de documento",
            },
            isRequired: required,
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Please enter at least 13 characters and no more than 18 characters",
                  "es-HN":
                    "Por favor, ingrese al menos 13 caracteres y no más de 18 caracteres",
                },
                regex: `^[0-9a-zA-Z]{13,18}$`,
              },
            ],
          },
          {
            type: "phone",
            name: "phone_number_spouse",
            title: {
              en: "Phone number spouse",
              "es-HN": "Celular",
            },
            isRequired: required,
            validators: [
              expressions.phone_en("phone_number_spouse"),
              expressions.phone_length("phone_number_spouse"),
            ],
          },
        ],
      },
      {
        type: "text",
        name: "dependence_number",
        title: {
          "es-HN": "N. dependientes",
          en: "Number of Dependents ",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        visibleIf: "{custom_text_6} == 'asalariado'",
        name: "custom_text_3",
        title: {
          en: "Type of housing",
          "es-HN": "Tipo de Vivienda",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_propiedad || [],
        isRequired: required,
      },
      {
        visibleIf: "{custom_text_6} == 'micro'",
        name: "custom_text_3",
        title: {
          en: "Type of housing",
          "es-HN": "Tipo de Vivienda",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_propiedad_existing_micro || [],
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_integer_1",
        title: {
          "es-HN": "Años de residencia",
          en: "Years of residence ",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "panel",
        name: "micro_panel",
        visibleIf: "{custom_text_6} == 'micro'",
        title: {
          en: "Micro information ",
          "es-HN": "Información Micro",
        },
        elements: [
          {
            name: "custom_text_7",
            title: {
              en: "Type of business ",
              "es-HN": "Tipo de negocio",
            },
            type: "text",
            isRequired: required,
          },
          {
            name: "custom_text_8",
            title: {
              en: "Activity that develops",
              "es-HN": "Actividad que desarrolla",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.act_economicas || [],
            isRequired: required,
          },
          {
            type: "text",
            name: "custom_text_9",
            title: {
              en: "Name of the business",
              "es-HN": "Nombre del negocio",
            },
            isRequired: required,
          },
          {
            type: "text",
            name: "custom_integer_2",
            title: {
              en: "Years in operation",
              "es-HN": "Años de operación",
            },
            isRequired: required,
          },
          {
            type: "phone",
            name: "current_job_phone",
            title: {
              "es-HN": "Teléfono del negocio",
              en: "Business phone number",
            },
            isRequired: required,
            validators: [
              expressions.phone_en("current_job_phone"),
              expressions.phone_length("current_job_phone"),
            ],
          },
          {
            type: "text",
            name: "custom_integer_4",
            title: {
              "es-HN": "N. de empleados",
              en: "Number of employees ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_21",
            title: {
              "es-HN": "Venta mensual",
              en: "Monthly sales ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_22",
            title: {
              "es-HN": "Compra del negocio al mes",
              en: "Monthly purchase ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_23",
            title: {
              "es-HN": "Otros ingresos ",
              en: "Other income ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_24",
            title: {
              "es-HN": "Gastos del negocio al mes",
              en: "Monthly expenses ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_25",
            title: {
              "es-HN": "Inversión en inventario",
              en: "Inventory investment ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_26",
            title: {
              "es-HN": "Activos del negocio",
              en: "Business Assets ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_27",
            title: {
              "es-HN": "Activos familiares",
              en: "Family Assets ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_28",
            title: {
              "es-HN": "Saldo deudas vigentes",
              en: "Outstanding debts ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_29",
            title: {
              "es-HN": "Pago de cuotas al mes",
              en: "Payment of monthly instalments ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_30",
            title: {
              "es-HN": "Gasto familiar al mes",
              en: "Monthly household expenditure ",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_text_20",
            title: {
              en: "Company address",
              "es-HN": "Dirección de la empresa",
            },
            isRequired: required,
            maxLength: 40,
          },
          {
            name: "custom_text_21",
            title: {
              en: "Company city",
              "es-HN": "Ciudad",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.ciudades || [],
            isRequired: required,
          },
          {
            name: "custom_text_22",
            title: {
              en: "Company department ",
              "es-HN": "Departamento",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.departamento || [],
            isRequired: required,
          },
        ],
      },
      {
        type: "panel",
        name: "asalaradio_panel",
        visibleIf: "{custom_text_6} == 'asalariado'",
        title: {
          en: "Salaried information ",
          "es-HN": "Información Asalariado",
        },
        elements: [
          {
            type: "text",
            name: "custom_text_14",
            title: {
              en: "Place of work ",
              "es-HN": "Lugar de trabajo",
            },
            isRequired: required,
          },
          {
            type: "text",
            name: "custom_text_15",
            title: {
              en: "Position held",
              "es-HN": "Puesto que desempeña",
            },
            isRequired: required,
          },
          {
            type: "text",
            name: "custom_text_35",
            title: {
              en: "Seniority of the business ",
              "es-HN": "Antigüedad del negocio",
            },
            isRequired: required,
          },
          {
            type: "phone",
            name: "custom_text_16",
            title: {
              en: "Company phone number",
              "es-HN": "Teléfono de la empresa",
            },
            isRequired: required,
            validators: [
              expressions.phone_en("custom_text_16"),
              expressions.phone_length("custom_text_16"),
            ],
          },
          {
            type: "text",
            name: "email",
            title: {
              en: "Email ",
              "es-HN": "Correo",
            },
            isRequired: required,
            inputType: "email",
            validators: [{ type: "email" }],
          },
          {
            type: "text",
            name: "custom_float_31",
            title: {
              en: "Salario bruto",
              "es-HN": "Salario bruto",
            },
            isRequired: required,
            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_2",
            title: {
              en: "Total salary ",
              "es-HN": "Salario total",
            },
            isRequired: required,

            inputType: "number",
          },
          {
            type: "text",
            name: "custom_float_3",
            title: {
              en: "Net salary",
              "es-HN": "Salario neto",
            },
            isRequired: required,
            inputType: "number",
            validators: [expressions.amount_greater_than_zero("custom_float_3")],
          },
          {
            type: "text",
            name: "custom_text_20",
            title: {
              en: "Company address",
              "es-HN": "Dirección de la empresa",
            },
            isRequired: required,
            maxLength: 40,
          },
          {
            name: "custom_text_21",
            title: {
              en: "Company city",
              "es-HN": "Ciudad",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.ciudades || [],
            isRequired: required,
          },
          {
            name: "custom_text_22",
            title: {
              en: "Company department ",
              "es-HN": "Departamento",
            },
            description: {
              en: "You can search the name",
              "es-HN": "Puedes buscar el nombre",
            },
            type: "dropdown",
            renderAs: "select2",
            choices: Config.departamento || [],
            isRequired: required,
          },
        ],
      },
    ],
  },
  // Page 2: Guarantor Information (existing clients)
  {
    name: "Guarantor Information",
    title: {
      en: "Guarantor Information",
      "es-HN": "Información del aval",
    },
    elements: [
      {
        type: "dropdown",
        name: "custom_text_38",
        title: {
          en: "Do you want to add a guarantor?",
          "es-HN": "¿Quieres agregar un aval?",
        },
        isRequired: required,
        choices: [
          { value: "si", text: { en: "Yes", "es-HN": "Sí" } },
          { value: "no", text: { en: "No", "es-HN": "No" } },
        ],
      },
      {
        title: {
          en: "Guarantor #1 details",
          "es-HN": "Detalles del aval #1",
        },
        name: "panel_guarantor_1",
        type: "panel",
        innerIndent: 1,
        visibleIf: "{custom_text_38} == 'si'",
        elements: [
          {
            type: "html",
            html: {
              en: "Enter the necessary information so that we can contact your guarantor",
              "es-HN":
                "Ingrese la información necesaria para que podamos contactar a su aval",
            },
          },
          {
            name: "guarantor_first_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Guarantor Full Name",
              "es-HN": "Nombre completo del aval",
            },
          },
          {
            name: "guarantor_phone",
            type: "phone",
            isRequired: required,
            title: {
              en: "Guarantor Mobile No.",
              "es-HN": "Número de teléfono del aval",
            },
            validators: [
              expressions.phone_length("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone"),
              expressions.compare_phone_numbers("guarantor_phone", "guarantor_2_phone", "Número del aval 1", "Número del aval 2")
            ],
          },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_37",
        visibleIf: "{custom_text_38} == 'si'",
        title: {
          en: "Would you like to add another guarantor",
          "es-HN": "¿Desea agregar otro aval?",
        },
        description: {
          en: "If you guarantor cannot be contacted or the credit check gets rejected, we will contact the second guarantor",
          "es-HN":
            "Si no se puede contactar al primer aval o su revisión de crédito es rechazada, contactaremos al segundo aval",
        },
        defaultValue: "no",
        choices: [
          { value: "si", text: { en: "Yes", "es-HN": "Sí" } },
          { value: "no", text: { en: "No", "es-HN": "No" } },
        ],
      },
      {
        title: {
          en: "Guarantor #2 details",
          "es-HN": "Detalles del aval #2",
        },
        name: "panel_guarantor_2",
        visibleIf: "{custom_text_38} == 'si' && {custom_text_37} == 'si'",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "guarantor_2_first_name",
            type: "text",
            isRequired: required,
            title: {
              en: "Reference Full Name",
              "es-HN": "Nombre completo del aval",
            },
          },
          {
            name: "guarantor_2_phone",
            type: "phone",
            isRequired: required,
            title: {
              en: "Guarantor Mobile No.",
              "es-HN": "Número de teléfono del aval",
            },
            validators: [
              expressions.phone_length("guarantor_2_phone"),
              expressions.same_phone_number("guarantor_2_phone"),
              expressions.compare_phone_numbers("guarantor_phone", "guarantor_2_phone", "Número del aval 1", "Número del aval 2")
            ],
          }
        ],
      },
    ],
  },
  // Page 3: Credit application data (existing client)
  {
    name: "Credit application data",
    title: {
      en: "Credit application data",
      "es-HN": "Datos de solicitud del crédito",
    },
    elements: [
      {
        //Micro slider-existing client
        visibleIf: "{custom_text_6} == 'micro'",
        type: "nouislider",
        name: "amount",
        title: {
          en: "Loan amount",
          "es-HN": "Monto solicitado",
        },
        description: {
          en: "Indicate the amount you want to request in HNL",
          "es-HN": "Indica el monto que quieres solicitar en HNL",
        },
        step: 10,
        rangeMin: 6000,
        maxStep: 500,
        rangeMax: 720000,
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 6000,
            text: {
              en: "Please enter the requested amount",
              "es-HN": "Ingresa la cantidad a solicitar",
            },
          },
        ],
      },
      {
        //Asalariado slider-existing client
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "nouislider",
        name: "amount",
        title: {
          en: "Loan amount",
          "es-HN": "Monto solicitado",
        },
        description: {
          en: "Indicate the amount you want to request in HNL",
          "es-HN": "Indica el monto que quieres solicitar en HNL",
        },
        step: 10,
        rangeMin: 6000,
        maxStep: 500,
        rangeMax: 220000,
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 6000,
            text: {
              en: "Please enter the requested amount",
              "es-HN": "Ingresa la cantidad a solicitar",
            },
          },
        ],
      },
      {
        // loan_term salary
        visibleIf: "{custom_text_6} == 'asalariado'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 1,
            maxValue: 36,
            text: {
              en: "Enter a number between 1 and 36",
              "es-HN": "Ingresa un número entre 1 y 36",
            },
          },
        ],
      },
      {
        // loan_term micro 1
        visibleIf:
          "{amount} >= 6000 && {amount} <= 15000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 3,
            maxValue: 12,
            text: {
              en: "Enter a number between 3 and 12",
              "es-HN": "Ingresa un número entre 3 y 12",
            },
          },
        ],
      },
      {
        // loan_term micro 2
        visibleIf:
          "{amount} > 15000 && {amount} <= 50000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 6,
            maxValue: 18,
            text: {
              en: "Enter a number between 6 and 18",
              "es-HN": "Ingresa un número entre 6 y 18",
            },
          },
        ],
      },
      {
        // loan_term micro 3
        visibleIf:
          "{amount} > 50000 && {amount} <= 200000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 12,
            maxValue: 24,
            text: {
              en: "Enter a number between 12 and 24",
              "es-HN": "Ingresa un número entre 12 y 24",
            },
          },
        ],
      },
      {
        // loan_term micro 4
        visibleIf:
          "{amount} > 200000 && {amount} <= 720000 && {custom_text_6} == 'micro'",
        type: "text",
        name: "loan_term",
        title: {
          en: "Loan term (months)",
          "es-HN": "Plazo solicitado (meses)",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 12,
            maxValue: 30,
            text: {
              en: "Enter a number between 12 and 30",
              "es-HN": "Ingresa un número entre 12 y 30",
            },
          },
        ],
      },
      {
        type: "text",
        name: "custom_float_5",
        title: {
          en: "Fee you can pay",
          "es-HN": "Cuota que puedes pagar",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        name: "custom_text_27",
        title: {
          en: "Payment method ",
          "es-HN": "Forma de pago",
        },
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.modalidades || [],
      },
      {
        name: "requested_payment_date_panel",
        type: "panel",
        innerIndent: 2,
        title: {
          en: "Requested date of payment",
          "es-HN": "Fecha solicitada de pago",
        },
        elements: [
          {
            type: "dropdown",
            name: "date_type_3",
            title: {
              en: "Enter date with:",
              "es-HN": "Ingresar fecha con:",
            },
            defaultValue: "calendario",
            choices: [
              {
                value: "calendario",
                text: { en: "Calendar", "es-HN": "Calendario" },
              },
              { value: "texto", text: { en: "Text", "es-HN": "Texto" } },
            ],
          },
          {
            visibleIf: "{date_type_3} == 'calendario'",
            type: "text",
            name: "custom_text_28",
            isRequired: required,
            inputType: "date",
            title: {
              en: "Requested date of payment",
              "es-HN": "Fecha solicitada de pago",
            },
          },
          {
            visibleIf: "{date_type_3} == 'texto'",
            name: "custom_text_28",
            type: "text",
            title: {
              en: "Requested date of payment",
              "es-HN": "Fecha solicitada de pago",
            },
            placeHolder: "aaaa-mm-dd",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Enter date with the format yyyy-mm-dd",
                  "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                },
                regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
              },
            ],
          },
        ],
      },
      {
        name: "custom_text_29",
        title: {
          en: "Guarantee offered",
          "es-HN": "Garantía ofrecida",
        },
        isRequired: required,
        type: "dropdown",
        hasOther: true,
        otherText: { en: "OTHER", "es-HN": "OTRA" },
        renderAs: "select2",
        choices: Config.garantias || [],
      },
      {
        title: {
          en: "Document of the offered guarantee",
          "es-HN": "Documento de la garantía ofrecida",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_11",
        requiredIf: "{custom_text_29} != 'FIDUCIARIO'",
      },
      {
        type: "text",
        name: "custom_text_30",
        title: {
          en: "Tell us in what you are going to invest your money",
          "es-HN": "Cuéntanos en qué invertirás tu dinero",
        },
        isRequired: required,
      },
      {
        title: {
          en: "Operating Permit Photo",
          "es-HN": "Foto de Permiso de Operación",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_4",
        isRequired: required,
      },
      {
        title: {
          en: "Photo of the front of the business",
          "es-HN": "Foto frontal del negocio",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_5",
        isRequired: required,
      },
      {
        type: "panel",
        title: {
          en: "By submitting this form, you authorise Banco Popular to verify and request information about your accounts or obligations in public or private credit bureaus.",
          "es-HN":
            "Al enviar este formulario, autorizas a Banco Popular que pueda verificar y solicitar información sobre tus cuentas u obligaciones en las centrales de riesgos públicas o privadas",
        },
        elements: [
          {
            type: "signaturepad",
            name: "signature",
            title: {
              en: "Online signature",
              "es-HN": "Firma online",
            },
            isRequired: required,
          },
        ],
      },
    ],
  },
];
