import { expressions } from "../../survey/validators";
import env from "../../env";

const required = env.ENDPOINT !== "local";

const i_agree = {
  title: "removeMe",
  type: "checkbox",
  name: "agree",
  isRequired: true,
  choices: [
    {
      value: {
        en: "Guarantor has agreed to loan amount, loan term and total fees.",
        rw: "needs translation",
      },
      text: {
        en: "I agree",
        rw: "Ndabyemeye",
      },
    },
  ],
  validators: [],
};

export const cooperative_rwanda = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,

  completeText: { en: "Submit information", rw: "Ohereza amakuru" },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: { en: "Guarantor/Accountant acceptance", rw: "Kwemera ingwate" },
      name: "refused_page",
      elements: [
        {
          title: {
            en:
              "Welcome {guarantor_first_name}, you are invited to be the guarantor/cooperative of {user_first_name} for a loan amount of {amount} RWF. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
            rw:
              "Murakaza neza {guarantor_first_name} mutumiwe kuba ingwate ya {user_first_name} kunguzanyo ingana naya mafaranga {amount} RWF kuzuza ubu butumire, murabazwa amwe mumakuru aberekeyeho. Turabasaba guhitamo niba ubutumire mubwemeye, cyangwa mubuhakanye. Niba mufite ikibazo mwatumenyesha.",
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: "I accept",
                rw: "Ndabyemeye",
              },
            },
            {
              value: true,
              text: {
                en: "I don’t accept",
                rw: "Simbyemeye",
              },
            },
          ],
        },
      ],
    },
    {
      title: { en: "Guarantor Information", rw: "Umwishingizi" },
      name: "personal",
      elements: [
        {
          title: {
            en: "Guarantor Full Name (cooperative) *",
            rw: "Izina rya koperative *",
          },
          name: "cooperative_name",
          type: "text",
          isRequired: required,
        },
        {
          name: "legal_registration_number",
          type: "text",
          title: {
            en: "Legal registration number",
            rw: "Nimero iranga koperative",
          },
        },
        {
          name: 'file_4',
          type: 'file_uploader',
          accept: 'image/*,application/pdf',
          title: {
            en: 'Please upload RCA certficate',
            rw: "Shyiraho icyangombwa RCA",
          },
        },
        {
          name: "guarantor_first_name",
          type: "text",
          isRequired: required,
          title: {
            en: "Name of the president of the cooperative *",
            rw: "Izina rya perezida wa koperative yawe *",
          }
        },
        {
          name: "guarantor_phone",
          type: "phone",
          isRequired: required,
          title: {
            en: "President of cooperative No*",
            rw: "Nimero ya perezida wa koperative *",
          },
          validators: [
            expressions.phone_en("phone"),
            expressions.phone_length("phone"),
          ],
        },
        {
          title: {
            en: "Your Personal Details",
            rw: "Umwirondoro wawe",
          },
          name: "details_applicant",
          readOnly: "false",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "advisor_name",
              type: "text",
              isRequired: required,
              title: {
                en: "Your full name",
                rw: "Amazina yombi ",
              },
            },
            {
              name: "advisor_job_title",
              type: "text",
              title: {
                en: "Your job title",
                rw: "Ushinzwe iki muri koperative",
              },
            },
            {
              name: "advisor_id",
              type: "text",
              title: {
                en: "Your ID Number",
                rw: "Nimero y'indangamuntu",
              },
              isRequired: required,
              validators: [
                {
                  type: "regexvalidator",
                  text: {en: "Number should be from 16 digits length",
                        rw: "Imibare igomba kuba ari 16"
                          },
                  regex: "^[0-9]{16}$",
                }
              ],
            },
            {
              name: "advisor_phone_number",
              type: "phone",
              title: {
                en: "Your Phone Number",
                rw: "Telefone",
              },
            },
          ],
        }
      ]
    },
    {
      title: { en: "Provided Production Information", rw: "Amakuru y'umusaruro yatanzwe" }, // @TODO needs translation
      description: {
        en: "Production information provided by the applicant",
        rw: "Amakuru y'umusaruro yatwanzwe n'usaba inguzanyo", // @TODO needs translation
      },
      name: "provided_production_information",
      elements: [
        {
          title: {
            en: 'Information related to season 1',
            rw: "Amakuru ku gihembwe cya 1​",
          },
          name: 'agricultural_season_1',
          type: 'panel',
          visibleIf: "{loan_purpose} == 'business'",
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Irish Potato Cultivated Land size (hectare) - business',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare) - business ",
              },
            },
            {
              name: 'supply_volume_kg_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama ",
              },
            },
            {
              name: 'variety_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price/kg in RWF',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf ',
              },
            },
            {
              name: 'sales_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf',
              },
            },
          ],
        },
        {
          title: {
            en: 'Season 1',
            rw: "Igihembwe cya 1​",
          },
          name: 'agricultural_season_1',
          type: 'panel',
          visibleIf: "{loan_purpose} == 'farming'",
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Cultivated Land of the selected crop (hectare)',
                rw: "Ingano y’ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
            },
            {
              name: 'supply_volume_kg_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Production of the selected crop in KGs',
                rw: "Ingano y’umusaruro kugihigwa wahisemo muri kilogarama",
              },
            },
            {
              name: 'variety_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw’igihingwa wahisemo",
              },
            },
            {
              name: 'price_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price of the selected crop/kg in RWF',
                rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
              },
            },
            {
              name: 'sales_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales of selected crop in RWF',
                rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
              },
            },
          ],
        },
        {
          title: {
            en: 'Information related to season 2',
            rw: "Amakuru ku gihembwe cya 2",
          },
          name: 'agricultural_season_2',
          visibleIf: "{loan_purpose} == 'business'",
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Irish Potato Cultivated Land size (hectare)',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare)",
              },
            },
            {
              name: 'supply_volume_kg_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama ",
              },
            },
            {
              name: 'variety_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price/kg in RWF 2',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf 2',
              },
            },
            {
              name: 'sales_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf',
              },
            },
          ],
        },
        {
          title: {
            en: 'Season 2',
            rw: "Igihembwe cya 2",
          },
          name: 'agricultural_season_2',
          visibleIf: "{loan_purpose} == 'farming'",
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Cultivated land of the selected crop in hectare',
                rw: "Ingano y’ubuso wahinzeho igihingwa wahisemo muri hegitare ",
              },
            },
            {
              name: 'supply_volume_kg_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Production of the selected crop in KG',
                rw: "Ingano y’umusaruro kugihigwa wahisemo  muri kilogarama ",
              },
            },
            {
              name: 'variety_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw’igihingwa wahisemo",
              },
            },
            {
              name: 'price_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price of the selected crop/kg in RWF ',
                rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
              },
            },
            {
              name: 'sales_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales of the selected crop in Rwf',
                rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
              },
            },
          ],
        },
        {
          title: {
            en: 'Information related to season 3',
            rw: "Amakuru ku gihembwe cya 3",
          },
          name: 'agricultural_season_3',
          visibleIf: "{loan_purpose} == 'business'",
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Irish Potato Cultivated Land size (hectare)',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare)",
              },
            },
            {
              name: 'supply_volume_kg_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama ",
              },
            },
            {
              name: 'variety_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price/kg in RWF 3',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf 3',
              },
            },
            {
              name: 'sales_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf',
              },
            },
          ],
        },
        {
          title: {
            en: 'Season 3',
            rw: "Igihembwe cya 3",
          },
          name: 'agricultural_season_3',
          visibleIf: "{loan_purpose} == 'farming'",
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Cultivated land of the selected crop in hectare',
                rw: "Ingano y’ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
            },
            {
              name: 'supply_volume_kg_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Production of selected crop in KG',
                rw: "Ingano y’umusaruro kugihigwa wahisemo  muri kilogarama",
              },
            },
            {
              name: 'variety_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw’igihingwa wahisemo",
              },
            },
            {
              name: 'price_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price of selected crop/kg in RWF ',
                rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
              },
            },
            {
              name: 'sales_season_3',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales of selected crop in Rwf',
                rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
              },
            },
          ],
        },
        {
          title: {
            en: 'Fill the current agriculture season',
            rw: 'Andika amakuru kugihembwe ugiye guhinga',
          },
          name: 'current_agricultural_season',
          visibleIf: "{loan_purpose} == 'business'",
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'What size of land will you cultivate (hectare)?',
                rw: 'Uzahinga kubuso bungana iki (hegitare)?',
              },
            },
            {
              name: 'variety_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Which variety of potato will you cultivate?',
                rw: "Uzahinga ubuhe bwoko bw'ibirayi?",
              },
            },
          ],
        },
        {
          title: {
            en: 'Information on current season of the selected crop',
            rw: 'Amakuru ku gihembwe  ugiye guhinga',
          },
          name: 'current_agricultural_season',
          visibleIf: "{loan_purpose} == 'farming'",
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected Cultivated land of the selected crop in hectare',
                rw: 'Ingano y’ubuso uteganya guhinga muri hegitare',
              },
            },
            {
              name: 'supply_volume_kg_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected production of selected crop in KG',
                rw: 'Ingano y’umusaruro uteganyijwe  muri kilogarama',
              },
            },
            {
              name: 'variety_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Which variety of potato will you cultivate?',
                rw: "Ubwoko uteganya guhinga",
              },
            },
            {
              name: 'price_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected price of selected crop/kg in RWF',
                rw: "Igiciro uteganya kugurishirizaho (RWF)?",
              },
            },
            {
              name: 'sales_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected sales of selected crop in Rwf',
                rw: "Amafaranga yose uteganya kwinjiza (RWF)",
              },
            },
          ],
        },
      ],
    },
    {
      title: {
        en: "Review the loan and sign below",
        rw: "Ongera usuzume inguzanyo, hanyuma usinye hepfo",
      },
      name: "loan",
      elements: [
        {
          title: { en: "The loan terms:", rw: "Inguzanyo:" },
          name: "terms",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance}, {loan_purpose})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in Rwf",
                rw: "Inguzanyo yasabwe mu Rwf ",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'repayment_period'))", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in Rwf to be deducted from the loan amount (2%)",
                rw: "Umufuragiro mu Rwf akurwa ku nguzanyo yemejwe (2%)",
              },
              type: "expression",
              expression:
                "getLoanTerm({loan_terms}, 'total_admin_fee')", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in Rwf to be deducted from the loan amount (18%)",
                rw: "Umusoro(VAT) kumufuragiro akurwa kunguzanyo yemejwe  (18%)", //TODO nees translation
              },
              type: "expression",
              expression:
                "getLoanTerm({loan_terms}, 'total_vat_on_admin_fee')", // Floating Point Proof
            },
            {
              title: {
                en:"Insurance fees in Rwf to be deducted from the loan amount (0.6%) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe (0.6%)",
              },
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'total_insurance_fee'))", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in Rwf (16% per year)",
                rw:
                  "Inyungu ku ngunzanyo mu mwaka mu Rwf (16% ku mwaka)",
              },
              visibleIf: "{loan_purpose} == 'business'", 
              type: "expression",
              expression:"(getLoanTerm({loan_terms}, 'total_interest'))", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in Rwf (16% per year)",
                rw:
                  "Inyungu ku ngunzanyo mu mwaka mu Rwf (16% ku mwaka)",
              },
              visibleIf: "{loan_purpose} == 'farming'", 
              type: "expression",
              expression:"(getLoanTerm({loan_terms}, 'total_interest'))", // Floating Point Proof
            },
          ],
        },

        {
          title: { en: "Your signature", rw: "Umukono wawe" },
          name: "signature",
          type: "panel",
          innerIndent: 1,

          elements: [
            {
              type: "html",
              title: "",
              html: {
                en:
                  "The loan amount of {amount} RWF that {user_first_name} is taking is reasonable and responsible considering their personal situation.",
                rw:
                  "Umubare winguzanyo ingana namafaranga {amount} {user_first_name} afashe ifite ishingiro hagendewe kubushobozi bwe bwite.",
              },
            },
            {
              type: "html",
              title: "",
              html: {
                en:
                  "I understand the risks of taking out a loan and will guarantee the repayment of the loan in case {user_first_name} is not able to make the repayment.",
                rw:
                  "Ndumva neza ingaruka ndetse no kuba umwishingizi w'iyi nguzanyo mugihe {user_first_name} mugihe adashoboye kwishyura.",
              },
            },
            {
              type: "html",
              title: "",
              html: {
                en:
                  "I confirm that the information given in this form is true, complete and accurate.",
                rw: "Ndemeza ko amakuru natanze yuzuye kandi arukuri.",
              },
            },
            { ...i_agree, name: "respond" },
          ],
        },
      ],
    },
 ]
}
