import { SurveyComponent } from "../../components/survey/survey_component";
import env from "../../env";

const required = env.ENDPOINT !== "local";

export const credit_check_form_ffdl = new SurveyComponent({
  title: {
    en: "information form from credit bureau check",
    "es-GT": "Formulario para el buró de crédito",
  },

  name: "bureau_check",
  elements: [
    {
      type: "html",
      name: "penalty",
      html: {
        en: "One of the conditions to apply for a loan is that you are creditworthy. We will do a check with the credit bureau with your permission. Only when this credit check has as positive outcome, you will be asked for more information.",
        "es-GT":
          "Una de las condiciones para aplicar a un préstamo es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito para continuar tu solicitud.",
      },
    },
    {
      type: "text",
      name: "first_name",
      isRequired: required,
      title: {
        en: "First name*",
        "es-GT": "Primer nombre*",
      },
    },
    {
      type: "text",
      name: "middle_name",
      title: {
        en: "Middle name",
        "es-GT": "Segundo nombre",
      },
    },
    {
      type: "text",
      name: "surname",
      isRequired: required,
      title: {
        en: "Surname*",
        "es-GT": "Primer apellido*",
      },
    },
    {
      type: "text",
      name: "second_surname",
      title: { en: "Second surname", "es-GT": "Segundo apellido" },
    },
    {
      type: "text",
      name: "married_name",
      title: { en: "Married name", "es-GT": "Apellido de casada" },
    },
    {
      title: {
        en: "Gender",
        "es-GT": "Género*",
      },
      name: "gender",
      type: "radiogroup",
      isRequired: required,
      colCount: 2,
      choices: [
        { value: "hombre", text: { en: "Male", "es-GT": "Hombre" } },
        { value: "mujer", text: { en: "Female", "es-GT": "Mujer" } },
      ],
    },
    {
      title: {
        en: "Ethnic Group*",
        "es-GT": "Grupo étnico*",
      },
      name: "custom_text_61",
      type: "radiogroup",
      isRequired: required,
      colCount: 2,
      choices: [
        { value: "Indígena", text: { en: "Indigenous", "es-GT": "Indígena" } },
        { value: "No indígena", text: { en: "Non-indigenous", "es-GT": "No indígena" } },  
      ],
    },
    {
      title: {
        en: "Date of Birth *",
        "es-GT": "Fecha de nacimiento *",
      },
      name: "date_of_birth",
      type: "text",
      inputType: "date",
      isRequired: required,
    },
    {
      type: "text",
      name: "custom_text_62",
      isRequired: required,
      title: { en: "N.I.T.*", "es-GT": "N.I.T.*" },
    },
    {
      name: "nationality",
      type: "dropdown",
      isRequired: required,
      storeOthersAsComment: false,
      hasOther: true,
      otherText: { en: "Other", "es-GT": "Otros" },
      title: {
        en: "Nationality *",
        "es-GT": "Nacionalidad *",
      },
      choices: [
        {
          value: "guatemalteco(a)",
          text: {
            en: "Guatemalen",
            "es-GT": "Guatemalteco(a)",
          },
        },
      ],
    },
    {
      name: "id_number",
      type: "text",
      isRequired: required,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 13 digits length",
            "es-GT": "El número debe tener una longitud de 13 dígitos",
          },
          regex: "^[0-9]{13}$",
        },
      ],
      title: {
        en: "ID No. *",
        "es-GT": "DPI *",
      },
    },
    {
      title: {
        en: "Front Image (Attachment) *",
        "es-GT": "Imágen frontal del DPI *​",
      },
      description: {
        en: "If you don't have an ID card upload the replacement document.",
        "es-GT":
          "Si no puedes subir el documento ahora, puedes cerrar este formulario y volver más tarde.",
      },
      name: "id_file",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Back Image (Attachment) *",
        "es-GT": "Imágen trasera del DPI *​",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        "es-GT":
          "Si no puedes subir tu fotografía ahora, puedes cerrar este formulario y volver más tarde.",
      },
      name: "id_file_back",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      name: "id_avatar",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "Please verify that this card belongs to you",
        "es-GT": "Verificación pertenencia de DPI  ",
      },
      elements: [
        {
          name: "id_avatar_desc",
          type: "html",
          description: {
            en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
            "es-GT": "Suba una foto suya mostrando DPI",
          },
          html: {
            en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
            "es-GT": "Suba una foto suya mostrando DPI",
          },
        },
        {
          name: "id_avatar_desc_picture",
          type: "html",
          html: `<img alt="example" src='${require("../../assets/images/example_card.png")}' width='200'/>`,
        },
        {
          name: "id_file_with_avatar",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          title: {
            en: "Identification Picture * ",
            "es-GT": "Foto de identificación *",
          },
        },
        {
          name: "Consentimiento consulta a buro de credito",
          type: "html",
          html: `<div><p>En el botón descargar haz click para bajar el consentimiento a buró, imprime y firma el documento. Sube el documento mediante
           fotografía o escáner con el botón subir. Con lo anterior autorizas a FDL de Guatemala para consultar tu récord crediticio.
           </p><br><a class='button sv_complete_btn' 
           href='https://09c9bb80-84a6-431d-b48f-e85deff2d025.usrfiles.com/ugd/09c9bb_944c6c28918242d682e499179dd26d20.pdf' download='Consentimiento' target='_blank'>Consentimiento consulta a buro de credito</a></div>`,
        },
        {
          name: "picture_1",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          title: {
            en: "Upload Signed Permission * ",
            "es-GT": "Subir consentimiento firmado *",
          },
        },
        {
          title: {
            en: "Select the branch closest to your home",
            "es-GT": "Selecciona la sucursal más cercana a tu domicilio: *",
          },
          name: "custom_text_68",
          type: "radiogroup",
          isRequired: required,
          choices: [
            { value: "Ciudad Guatemala", text: { en: "Ciudad Guatemala", "es-GT": "Ciudad Guatemala" } },
            { value: "Chimaltenango", text: { en: "Chimaltenango", "es-GT": "Chimaltenango" } },  
            { value: "Mazatenango", text: { en: "Mazatenango", "es-GT": "Mazatenango" } },  
            { value: "Quetzaltenango", text: { en: "Quetzaltenango", "es-GT": "Quetzaltenango" } },  
          ],
        },
      ],
    },
  ],
});
