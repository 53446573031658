import env from "../../env";
const required = env.ENDPOINT !== "local";

export const review_banco_popular = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit application",
    "es-HN": "Firmar la solicitud",
  },
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review your application",
        "es-HN": "Revisa tu solicitud",
      },
      elements: [
        {
          type: "panel",
          title: {
            en: "The loan terms:",
            "es-HN": "Condiciones del préstamo:",
          },
          elements: [
            {
              type: "expression",
              name: "today_date",
              expression: "daysFromNow(0)",
              title: { en: "Date", "es-HN": "Fecha" },
            },
            {
              type: "expression",
              title: {
                en: "Applicant's full name",
                "es-HN": "Nombre completo  del cliente",
              },
              expression: `getFullName({first_name},{middle_name},{surname},{second_surname})`,
            },
            {
              type: "expression",
              expression: "{custom_text_6}",
              title: { en: "Type of client", "es-HN": "Tipo de Cliente" },
            },
            {
              type: "expression",
              expression: "{amount}",
              title: {
                en: "Loan amount",
                "es-HN": "Monto del crédito",
              },
            },
            {
              type: "expression",
              expression: "{custom_float_5}",
              title: {
                en: "Fee",
                "es-HN": "Cuota",
              },
            },
            {
              type: "expression",
              expression: "{loan_term}",
              title: {
                en: "Loan term",
                "es-HN": "Plazo",
              },
            },
            {
              type: "panel",
              title: {
                en: "By submitting this form, you authorise Banco Popular to verify and request information about your accounts or obligations in public or private credit bureaus.",
                "es-HN":
                  "Al enviar este formulario, autorizas a Banco Popular que pueda verificar y solicitar información sobre tus cuentas u obligaciones en las centrales de riesgos públicas o privadas",
              },
              elements: [
                {
                  type: "expression",
                  title: {
                    en: "Sign date",
                    "es-HN": "Fecha de firma",
                  },
                  name: "custom_text_35",
                  expression: `daysFromNow(0)`,
                },
                {
                  type: "signaturepad",
                  name: "file_2",
                  title: {
                    en: "Online signature",
                    "es-HN": "Firma online",
                  },
                  isRequired: required,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
