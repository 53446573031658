import React from 'react';
import { connect } from 'react-redux';
import * as Survey from 'survey-react';
import { logger } from '../../app';
import { Bullet } from '../../components/bullet/bullet';
import { Footer } from '../../components/footer/footer';
import HelpComponent from '../../components/help/help';
import { Start } from '../../components/start/start';
import { translate } from '../../lib/intl';
import { loanActions } from '../../store/store';
import '../../theme/survey.scss';
import assessment from './assessment';
import './assessment.container.scss';

class AssessmentContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    // setup model
    this.model = new Survey.Model(assessment(props.device.locale));

    // restore data, for assessment we do not restore the answers
    try {
      this.model.currentPageNo = this.props.loan.assessment.current_page;
    } catch (error) {
      console.error('Sometimes setting the page fails, so starting on title due to error: ', error);
    }

    // set callbacks
    this.model.onCompleting.add((survey) => this.onCompleting(survey));
    this.model.onValueChanged.add((survey, options) => this.onValueChange(survey, options));
    // this.model.onCurrentPageChanging.add((survey, options) => this.onCurrentPageChanging(survey, options));

    this.model.startTimer();
    this.model.render();

    // keep help data seperate so you don't have re-renders
    this.helpData = {
      help_count: this.props.loan.assessment.help_count || 0,
      help_time: this.props.loan.assessment.help_time || 0,
      help_log: this.props.loan.assessment.help_log || [],
      timeOpen: null,
    };

    // meta data is saved seperatly
    this.metaData = {};

    this.state = {
      is_started: false,
    };
  }

  onCurrentPageChanging(survey, options) {
    // on back, track that you went back. On forward track time and submit information to backedn.

    if (options.oldCurrentPage.visibleIndex > options.newCurrentPage.visibleIndex) {
      // You were able to go back, so now disallow
      this.model.showPrevButton = false;

      try {
        // track going back
        this.metaData[options.newCurrentPage.name].back = 1;
      } catch (error) {
        logger.leaveBreadcrumb('Type error, "showPrevButton" on in default.', {
          assessment: assessment,
        });
        logger.captureException(new Error('Assessment error'));
      }
    } else {
      // going forward, if back is disallowed then allow
      if (!this.model.showPrevButton) {
        this.model.showPrevButton = true;
      }

      try {
        // test if object metaData is already been made
        if (!this.metaData[options.oldCurrentPage.name])
          this.metaData[options.oldCurrentPage.name] = {
            back: 0,
            log: [],
            count: 0,
            time: 0,
          };

        // track time
        this.metaData[options.oldCurrentPage.name].time = options.oldCurrentPage.timeSpent;
      } catch (error) {
        logger.leaveBreadcrumb('Type error, Page with different question name.', {
          assessment: assessment,
        });
        logger.captureException(new Error('Assessment error'));
      }

      this._saveData(survey, false);
    }
  }

  onValueChange(survey, options) {
    // First interaction, check if you need to create a new answer here
    if (!this.metaData[options.name])
      this.metaData[options.name] = { back: 0, log: [], count: 0, time: 0 };

    // track number of answers
    this.metaData[options.name].log.push(options.value);
    this.metaData[options.name].count = this.metaData[options.name].count + 1;
  }

  onCompleting(survey) {
    console.log('Complete! ', survey);

    this.model.stopTimer();
    this._saveData(survey, true);

    this.props.setPage('applying');
  }

  _saveData(survey, submit) {
    // and we'll save the data
    let data = {
      help_count: this.helpData.help_count,
      help_time: this.helpData.help_time,
      help_log: this.helpData.help_log,
      current_page: survey.currentPageNo,
      answers: survey.data,
      answers_meta: this.metaData,
    };

    this.props.saveAssessment(this.props.loan.uuid, data, submit);
  }

  _openHelp() {
    // counts and keeps track of time
    this.helpData.timeOpen = new Date();
    this.helpData.help_count = this.helpData.help_count + 1;
    this.helpData.help_log.push(this.model.currentPage.name);
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.helpData.help_time =
      this.helpData.help_time + Math.ceil((new Date() - this.helpData.timeOpen) / 1000);
    this.helpData.timeOpen = null;
    this.model.startTimer();
  }

  render = ({ history, account, device } = this.props) => (
    <div className="assessmentContainer centerWrapper">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="home"
        locale={device.locale}
      />

      {this.state.is_started ? (
        <div className="centerWrapper">
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="assessment"
            time="60"
            onStart={() => this.setState({ is_started: true })}
            onSkip={() => {
              console.log('skipping');
              this.onCompleting(this.model);
            }}
          >
            <p className="heavy">{translate('start_assessment.desc1')}</p>
            <p className="spacer">{translate('start_assessment.desc2')}</p>
            {Bullet(translate('start_assessment.bullet_1'))}
            {device.locale === 'rw' ? null : Bullet(translate('start_assessment.bullet_2'))}
            {Bullet(translate('start_assessment.bullet_3'))}
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAssessment: (uuid, assessment, complete) =>
      dispatch(loanActions.saveAssessment(uuid, assessment, complete)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(mapStateToProps, mapDispatchToProps)(AssessmentContainer);

export { connectedContainer as AssessmentContainer };
