import Config from "../../config";
import {DEFAULT_LOCALE, translate} from "../../lib/intl";
import {select_survey_js_model} from "../../survey/survey.utils";
import {
  credit_check_form_banco_popular_existing_client,
  credit_check_form_banco_popular_new_client,
} from "./credit_check_pages_banco_popular";
import {credit_check_form_crediavance} from "./credit_check_pages_crediavance";
import {
  credit_check_form_credicapital_new_client,
  credit_check_form_credicapital_recurring_client
} from "./credit_check_pages_credicapital";
import {credit_check_form_ffdl} from "./credit_check_pages_ffdl";
import {
  credit_check_form_procredito_agro,
  credit_check_form_procredito_transport,
} from "./credit_check_pages_procredito";
import {credit_check_form_rentamigo} from "./credit_check_pages_rentamigo";

export function credit_check(
  locale = DEFAULT_LOCALE,
  loan_purpose = "default",
  purpose = null,
  api_url = ""
) {
    // deployment specific pages
  const credicapital_pages = {
    default: credit_check_form_credicapital_new_client(api_url),
    new_client: credit_check_form_credicapital_new_client(api_url),
    recurring_client: credit_check_form_credicapital_recurring_client(api_url)
  };
  const ffdl_pages = [credit_check_form_ffdl];
  const procredito_pages = {
    default: credit_check_form_procredito_transport(api_url),
    agro: credit_check_form_procredito_agro(api_url),
    transport: credit_check_form_procredito_transport(api_url),
  };
  const banco_popular_pages = {
    new_client: credit_check_form_banco_popular_new_client,
    existing_client: credit_check_form_banco_popular_existing_client,
  };
  const crediavance_pages = [credit_check_form_crediavance];
  const rentamigo_pages = [credit_check_form_rentamigo];

  const availablePages = {
    banco_popular: select_survey_js_model(loan_purpose, banco_popular_pages),
    crediavance: crediavance_pages,
    credicapital: [select_survey_js_model(loan_purpose, credicapital_pages, purpose)],
    ffdl: ffdl_pages,
    procredito: select_survey_js_model(loan_purpose, procredito_pages),    
    rentamigo: rentamigo_pages    
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,
    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),
    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageCompleteText"),
    requiredText: "",
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
    locale,
  };
}
