import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const movable = new SurveyComponent({
  title: {
    en: "Movable",
    "es-GT": "Mobiliaria",
  },
  name: "movable",
  type: "panel",
  innerIndent: 1,
  visibleIf: "{collateral_arrangements} == 'mobiliaria'",
  elements: [
    {
      title: {
        en: "Please choose the type of furniture *",
        "es-GT": "Por favor, elija el tipo de mueble *",
      },
      name: "custom_text_45",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "vehiculo", text: { en: "Vehicle", "es-GT": "Vehículo" } },
        {
          value: "maquinaria",
          text: {
            en: "Machinery",
            "es-GT": "Maquinaria",
          },
        },
      ],
    },
    {
      title: {
        en: "Please choose the type of vehicle *",
        "es-GT": "Por favor, elija el tipo de vehículo *",
      },
      name: "custom_text_56",
      visibleIf: "{custom_text_45} == 'vehiculo'",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "automovil", text: { en: "Automobile", "es-GT": "Automóvil" } },
        {
          value: "motocicleta",
          text: {
            en: "Motorcycle",
            "es-GT": "Motocicleta",
          },
        },
        {
          value: "tuc_tuc",
          text: {
            en: "Tuc tuc",
            "es-GT": "Tuc tuc",
          },
        },
      ],
    },
    {
      title: {
        en: "Please briefly indicate the type of machinery *",
        "es-GT": "Por favor, indique brevemente el tipo de maquinaria *",
      },
      name: "custom_text_46", // check mapping
      visibleIf: "{custom_text_45} == 'maquinaria'",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Upload a legible photo of the vehicle registration card or machinery invoice *",
        "es-GT":
          "Subir fotografía legible de tarjeta de circulación o factura de maquinaria *",
      },
      name: "picture_9",
      type: "file_uploader",
      accept: "image/*,application/pdf",
    },
  ],
});
