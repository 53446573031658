import {combineReducers} from 'redux';

import {authenticationReducer} from './authentication/authentication.reducer';
import {deviceReducer} from './device/device.reducer';
import {accountReducer} from './account/account.reducer';
import {loanReducer} from "./loan/loan.reducer";

// Add the possibilty to add additional reducers specific to each Platform (EG: react-native, react)
export const initReducer = () => combineReducers({

  // Containers
  authentication: authenticationReducer,
  device: deviceReducer,
  account: accountReducer,
  loan: loanReducer
});
