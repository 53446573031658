import { endpoints } from "./endpoints";
import { setAlertDialog } from "../components/alert/alert";
import packageInfo from "../../package.json";
import { translate } from "../lib/intl";
import { logger } from "../app";
import env from "../env";

export class Api {
  constructor() {
    this.endpoint = endpoints[env.DEPLOYMENT];

    // Prefer client secret from env variable
    let client_secret = env.CLIENT_SECRET || this.endpoint.client_secret;
    this.endpoint = { ...this.endpoint, client_secret: client_secret };

    this.token = null;
    this.store = null;

    // use this to distinguish chat en web, initiated in initStore
    this.platform = "web";
    this.appVersion = packageInfo.version;
  }

  get(postfix = "") {
    logger.leaveBreadcrumb("api.get", { postfix: postfix });
    return fetch(`${this.endpoint.api_url}/${postfix}`, {
      method: "GET",
      headers: this.buildHeaders(),
    })
      .then((response) => this.handleResponse(postfix, response))
      .catch(this.handleError);
  }

  post(postfix = "", payload = {}) {
    const crumbMetaData = { postfix: postfix };
    if (payload && payload.uuid) crumbMetaData.uuid = payload.uuid;
    logger.leaveBreadcrumb("api.post", crumbMetaData);
    return fetch(`${this.endpoint.api_url}/${postfix}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: this.buildHeaders(),
    })
      .then((response) => this.handleResponse(postfix, response))
      .catch(this.handleError);
  }

  ping() {
    return fetch(`${this.endpoint.api_url}/_ping`, { method: "GET" });
  }

  buildHeaders() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("AppVersion", this.platform + "-" + this.appVersion);

    if (this.token) {
      headers.append("Authorization", `Bearer ${this.token.access_token}`);
    }
    return headers;
  }

  handleResponse(postfix, response) {
    logger.leaveBreadcrumb("api.handleResponse", {
      postfix: postfix,
      responseStatus: response.status,
    });
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else if (response.status === 401) {
      this.handleError(response.json());
    } else if (response.status === 404) {
      this.handleError("not_found");
    } else if (response.status === 403 || response.status === 418) {
      this.store.dispatch({
        type: "RESET_AUTHENTICATION_STATE",
      });
      this.store.dispatch({
        type: "RESET_ACCOUNT_STATE",
      });
      this.store.dispatch({
        type: "RESET_DEVICE_STATE",
      });

      this.handleError("logged_out");
    } else if (response.status === 500) {
      this.handleError("server_error");
    }
  }

  handleError(error) {
    if (error instanceof Promise) {
      error.then((data) => {
        logger.captureException(new Error("errors." + data.error), { data });
        console.log("promise error", data);
        setAlertDialog(
          translate("errors.title"),
          translate("errors." + data.error)
        );
      });
    } else {
      if (error instanceof TypeError) {
        logger.captureException(error, { alert: "errors.server_error" });
        console.log("Type error", error);
        setAlertDialog(
          translate("errors.title"),
          translate("errors.server_error")
        );
      } else {
        logger.captureException(error, { alert: "errors.not_found" });
        console.log("Other error", error);
        setAlertDialog(
          translate("errors.title"),
          translate("errors.not_found")
        );
      }
    }

    throw new Error("not_authorized");
  }
}
