import { expressions } from "../../survey/validators";
import env from "../../env";

const required = env.ENDPOINT !== "local";

export const cooperative_bll = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,

  completeText: { en: "Submit information", rn: "Ohereza amakuru" },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: { en: "Guarantor/Accountant acceptance", rn: "Kwemera ingwate" },
      name: "refused_page",
      elements: [
        {
          title: {
            en: "Welcome {guarantor_first_name}, you are invited to be the guarantor/cooperative of {user_first_name} for a loan amount of {amount}. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
            rn: "Murakaza neza {guarantor_first_name} mutumiwe kuba ingwate ya {user_first_name} kunguzanyo ingana naya mafaranga {amount} kuzuza ubu butumire, murabazwa amwe mumakuru aberekeyeho. Turabasaba guhitamo niba ubutumire mubwemeye, cyangwa mubuhakanye. Niba mufite ikibazo mwatumenyesha.",
            "fr-BI":
              "Bienvenue {guarantor_first_name}, vous êtes le garant de {user_first_name} popur un montant de {amount}, demandé chez BLL. Pour répondre à cette exigence, il vous sera demandé des informations vous concernant. Sélectionnez si vous acceptez ou pas. Si vous avez des questions, voullez nous contacter",
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: "I accept",
                rn: "Ndabyemeye",
                "fr-BI": "Je suis d'accord",
              },
            },
            {
              value: true,
              text: {
                en: "I don't accept",
                rn: "Simbyemeye",
                "fr-BI": "Je n'accepte pas",
              },
            },
          ],
        },
      ],
    },
    {
      title: { en: "Guarantor Information", rn: "Umwishingizi" },
      name: "personal",
      elements: [
        {
          type: "text",
          name: "custom_text_1",
          title: {
            en: "Full name of the guarantor",
            "fr-BI": "Nom complet du garant",
            rn: "Amazina yose y'umwishingizi",
          },
          isRequired: required,
        },
        {
          type: "panel",
          name: "guarantor_data_panel",
          title: {
            en: "Please provide us with your personal data",
            "fr-BI": "Veuillez partager vos données personnelles",
            rn: "Tanga ibikuranga vyose",
          },
          isRequired: required,
          elements: [
            {
              type: "text",
              name: "custom_text_1",
              title: {
                en: "Your full name",
                "fr-BI": "Votre nom complet",
                rn: "Amazina yawe yose",
              },
            },
            {
              type: "text",
              name: "custom_text_2",
              title: {
                en: "What place do you occupy in the cooperative ?",
                "fr-BI":
                  "Quelle est votre fonction au sein de la coopérative ?",
                rn: "Ufise igikorwa icahe muri Koperative?",
              },
            },
            {
              type: "text",
              name: "custom_text_3",
              title: {
                en: "Your Identity card number",
                "fr-BI": "Numéro de votre carte d'identité",
                rn: "Inimero y'ikarata karangamuntu yawe",
              },
            },
            {
              type: "phone",
              name: "phone",
              title: {
                en: "Your phone number",
                "fr-BI": "Votre numéro de téléphone",
                rn: "Inimero ya terefone yawe",
              },
              validators: [
                expressions.phone_en("phone"),
                expressions.phone_length("phone"),
              ],
            },
          ],
        },
        {
          type: "text",
          name: "custom_text_4",
          title: {
            en: "Provide legal registration number of the cooperative",
            "fr-BI": "Numéro d'enregistrement légal de la coopérative",
            rn: "Inimero iranga Koperative mu mategeko",
          },
          isRequired: required,
        },
        {
          type: "file_uploader",
          name: "picture_1",
          accept: "image/*,application/pdf",
          title: {
            en: "Upload the legal registration certificate",
            "fr-BI":
              "Veuillez télécharger le certificat d'enregistrement légal de la coopérative",
            rn: "Erekana igipapuro cerekana ko Koperative iri mu mategeko",
          },
          isRequired: required,
        },
        {
          type: "text",
          inputType: "number",
          name: "custom_text_48",
          title: {
            en: "The value of the guarantee in BIF",
            "fr-BI": "La valeur de la garantie",
            rn: "Agaciro k'ingwate mu BIF",
          },
          isRequired: required,
        },
        {
          type: "dropdown",
          title: {
            en: "Which type of the  warranty are you offering  ? ",
            "fr-BI": "Choisissez le type de garantie ?",
            rn: "Hitamwo ubwoko bw'ingwate",
          },
          isRequired: required,

          name: "custom_text_18",
          hasOther: true,
          storeOthersAsComment: false,
          otherText: {
            en: "Other",
            "fr-BI": "Autre",
            rn: "Ikindi",
          },
          choices: [
            {
              value: "terrain_nu",
              text: {
                en: "Bare ground",
                "fr-BI": "Terrain nu",
                rn: "Ikibanza cigaragara",
              },
            },
            {
              value: "maison",
              text: { en: "House", "fr-BI": "Maison", rn: "Ikibanze cubatse" },
            },
            {
              value: "terrain_bati",
              text: { en: "Built plot", "fr-BI": "Terrain Bâti", rn: "Inzu" },
            },
          ],
        },
        {
          type: "text",
          name: "custom_text_6",
          visibleIf: "{custom_text_5} = 'autre'",
          title: {
            en: "What is the name of warranty holder ?",
            "fr-BI": "Quel est le nom du titulaire de la garantie ?",
          },
          isRequired: required,
        },
        {
          type: "panel",
          name: "warranty_location_panel",
          title: {
            en: "Location of the warranty",
            "fr-BI": "Emplacement de la garantie",
          },
          isRequired: required,
          elements: [
            {
              type: "text",
              name: "custom_text_7",
              title: {
                en: "Province",
                "fr-BI": " Province",
                rn: "Provensi",
              },
            },
            {
              type: "text",
              name: "custom_text_8",
              title: {
                en: "Municipality",
                "fr-BI": "Commune",
                rn: "Komine",
              },
            },
            {
              type: "text",
              name: "custom_text_9",
              title: {
                en: "Zone",
                "fr-BI": "Zone",
                rn: "Zone",
              },
            },
            {
              type: "text",
              name: "custom_text_45",
              title: {
                en: "Hill",
                "fr-BI": "Colline",
                rn: "Umutumba",
              },
            },
            {
              title: {
                en: "First Season",
                rn: "Isizeni ya mbere",
                "fr-BI": "Première saison",
              },
              name: "season_1",
              type: "panel",
              innerIndent: 1,
              elements: [
                {
                  name: "custom_float_1",
                  type: "text",
                  inputType: "number",
                  readOnly: true,
                  title: {
                    en: "Number of beehives",
                    rn: "Igitigiri c'ibitiba",
                    "fr-BI": "Nombre de ruches",
                  },
                },
                {
                  name: "custom_float_2",
                  type: "text",
                  inputType: "number",
                  readOnly: true,
                  title: {
                    en: "Production in kg",
                    rn: "Umwimbu mu biro",
                    "fr-BI": "Production en kg",
                  },
                },
                {
                  name: "custom_float_3",
                  type: "text",
                  inputType: "number",
                  readOnly: true,
                  title: {
                    en: "Price per KG in BIF",
                    rn: "Igiciro ku kilo mu mafranga y'amarundi",
                    "fr-BI": "Prix par kg en BIF",
                  },
                },
                {
                  name: "custom_float_4",
                  type: "text",
                  inputType: "number",
                  readOnly: true,
                  title: {
                    en: "Total Sales",
                    rn: "Ivyadandajwe vyose ",
                    "fr-BI": "Ventes totales",
                  },
                },
              ],
            },
            {
              title: {
                en: "Second season",
                rn: "Isizeni ya kabiri",
                "fr-BI": "Deuxième saison",
              },
              name: "season_2",
              type: "panel",
              innerIndent: 1,
              elements: [
                {
                  name: "custom_float_5",
                  type: "text",
                  readOnly: true,
                  inputType: "number",
                  title: {
                    en: "Number of beehives.",
                    rn: "Igitigiri c'ibitiba",
                    "fr-BI": "Nombre de ruches",
                  },
                },
                {
                  name: "custom_float_6",
                  type: "text",
                  inputType: "number",
                  readOnly: true,
                  title: {
                    en: "Production in kg",
                    rn: "Umwimbu mu biro",
                    "fr-BI": "Production en kg",
                  },
                },
                {
                  name: "custom_float_7",
                  type: "text",
                  readOnly: true,
                  inputType: "number",
                  title: {
                    en: "Price per KG in BIF",
                    rn: "Igiciro ku kilo mu mafranga y'amarundi",
                    "fr-BI": "Prix par kg en BIF",
                  },
                },
                {
                  name: "custom_float_8",
                  type: "text",
                  inputType: "number",
                  readOnly: true,
                  title: {
                    en: "Total Sales",
                    rn: "Ivyadandajwe vyose ",
                    "fr-BI": "Ventes totales",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: {
        en: "Review the loan and sign below",
        rn: "Suzuma amasezerano y'uwo wishingira",
        "fr-TD": "Vérifier le contract du demandeur",
      },
      name: "loan",
      elements: [
        {
          title: { en: "The loan terms:", rn: "Inguzanyo:" },
          name: "terms",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              title: {
                en: "Requested loan amount",
                rn: "Amafranga y'ingurane yamewe",
                "fr-BI": "Montant du prêt apprové",
              },
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression: "{amount}",
            },
            {
              title: {
                en: "Loan term (in months)",
                rn: "Ikiringo c'ingurane",
                "fr-BI": "Durée du pret (mois) ",
              },
              type: "expression",
              expression: "6",
            },
            {
              title: {
                en: "Total administrative fees in FBu to be deducted from the loan amount (4%)",
                rn: "Amafranga y‘idosiye (4%)",
                "fr-BI": "Frais de dossie (4%)",
              },
              type: "expression",
              displayStyle: "currency",
              currency: "BIF",
              expression: "fixed(multiply({amount},0.04))",
            },
            {
              title: {
                en: "Monthly interest rate (14% per year)",
                rn: "Inyungu ku ngunzanyo mu mwaka mu FBu (14% per year)",
                "fr-BI": "Taux d'intérêt mensuel (14%/an)",
              },
              displayStyle: "currency",
              currency: "BIF",
              type: "expression",
              expression: "fixed(divide(multiply({amount},0.14),12))",
            },
          ],
        },

        {
          title: { en: "Sign", "fr-BI": "Signer", rn: "Umukono wawe" },
          name: "signpanel",
          type: "panel",
          innerIndent: 1,

          elements: [
            {
              html: {
                en: "Representat of cooperative called {custom_text_37} I voluntarily agree to stand as joint and indivisible surety to guarantee the repayment of the credit that BLL will grant to Mr / Mrs {last_name} In the event of non-payment , I declare that I am ready to meet the amount of the due date and the late interest due in proportion to the number of days of delay known in the event of the latter's inability to meet his financial commitments whatever the cause.",
                "fr-BI":
                  "En tant que le représentant   de la coopérative {custom_text_37} nous acceptons volontairement de garantir le remboursement du crédit qu’accordera BLL à Mr/Mme {last_name}​ ",
                rn: "Nkuhagarariye koperative {custom_text_37}  twemeye atagahato kwishingira ubwishu bw’ingurane BLL Izoha {last_name}. ",
              },
              type: "html",
            },
            {
              html: {
                en: "I understand the risks of taking out a loan and will guarantee the repayment of the loan in case {last_name} is nos able to make the repayment.",
                "fr-BI":
                  "Nous comprenons les risques liés à une demande de prêt et garantirai le remboursement du prêt au cas où {last_name} ne serait pas en mesure de rembourser.​.",
                rn: "Turumva neza ingaruka zizoba mugihe {last_name} atazoshobora kwishura iyi ngurane ​",
              },
              type: "html",
            },
          ],
        },
        {
          name: "custom_text_40",
          type: "expression",
          visibleIf: "1==0",
          title: {
            en: " ",
            rn: " ",
            "fr-BI": " ",
          },
          expression: ` 'En tant que le représentant   de la coopérative ' + {custom_text_37} + ' nous acceptons volontairement de garantir le remboursement du crédit qu’accordera BLL à Mr/Mme  ' + {last_name}  `,
        },
        {
          name: "custom_text_41",
          type: "expression",
          visibleIf: "1==0",
          title: {
            en: " ",
            rn: " ",
            "fr-BI": " ",
          },
          expression: `'Nous comprenons les risques liés à une demande de prêt et garantirai le remboursement du prêt au cas où ' + {last_name} + ' ne serait pas en mesure de rembourser'`,
        },
        {
          title: {
            en: "I agree",
            rn: "Ndabyemeye",
            "fr-BI": "Je suis d'accord",
          },
          type: "checkbox",
          name: "agreement",
          isRequired: true,
          choices: [
            {
              value: {
                en: "I agree",
                rn: "Ndabyemeye",
                "fr-BI": "Je suis d'accord",
              },
              text: {
                en: "I agree",
                rn: "Ndabyemeye",
                "fr-BI": "Je suis d'accord",
              },
            },
          ],
          validators: [],
        },
      ],
    },
  ],
};
