import React from "react";
import { connect } from "react-redux";

import * as Survey from "survey-react";

import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import Config from "../../config";
import { DEFAULT_LOCALE, translate } from "../../lib/intl";
import { colors } from "../../theme/theme";
import { Button } from "../../components/button/button";
import { loanActions } from "../../store/store";

export function auth_valid_pages(
  locale = DEFAULT_LOCALE,
  loan_purpose = "default"
) {
  const procredito_pages = [];

  const availablePages = {
    procredito: procredito_pages,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),

    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageNextText"), // note same as `Next`

    requiredText: "",
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
  };
}
export class AuthenticationConfirm extends React.PureComponent {
  constructor(props) {
    // restore data
    super(props);
    this.model = new Survey.Model(auth_valid_pages(props.device.locale));
    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan.intake,
    };
  }

  render = ({ device, loan } = this.props) => {
    return (
      <div>
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />
        {(loan.state === "authentication_failed" ||
          loan.state === "guarantor_authentication_failed") && (
            <div className="centerWrapper">
              <h2 className="title bigSpacer center">
                {translate("two_factor_auth.failed_title")}
              </h2>

              <p className="center bigSpacer">
                {translate("two_factor_auth.failed_text")}
              </p>

              <div style={{ width: "200px", margin: "auto" }}>
                <Button
                  backgroundColor={colors.brand.accent}
                  color={colors.brand.darkgray}
                  title={translate("two_factor_auth.retry_token_button")}
                  onPress={() => this.props.twoFactorAuth(loan.uuid, "send")}
                />
              </div>
            </div>
          )}
        {(loan.state === "authentication_valid" ||
          loan.state === "guarantor_authentication_valid") && (
            <div className="centerWrapper">
              <h2 className="title bigSpacer center">
                {translate("two_factor_auth.auth_valid_title")}
              </h2>

              <p className="center bigSpacer">
                {translate("two_factor_auth.auth_valid_text", {
                  first_name: loan.state === 'guarantor_authentication_valid' ? loan.intake_guarantor.guarantor_first_name : loan.intake.first_name,
                })}
              </p>

              <div style={{ width: "200px", margin: "auto" }}>
                <Button
                  backgroundColor={colors.brand.accent}
                  color={colors.brand.darkgray}
                  title={translate("two_factor_auth.auth_valid_button")}
                  onPress={() =>
                    this.props.twoFactorAuth(loan.uuid, "complete")
                  }
                />
              </div>
            </div>
          )}

        <Footer />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    twoFactorAuth: (uuid, action) =>
      dispatch(loanActions.twoFactorAuth(uuid, "", action)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const mapStateToProps = (state) => {
  return {
    loan: state.loan,
    device: state.device,
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationConfirm);

export { connectedContainer as AuthenticationConfirmContainer };
