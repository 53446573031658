import React from "react";
import { connect } from "react-redux";
import * as Survey from "survey-react";
import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import { Start } from "../../components/start/start";
import Config from "../../config";
import { DEFAULT_LOCALE, translate } from "../../lib/intl";
import { loanActions } from "../../store/store";
import { saveBase64, useBase } from "../../survey/survey.utils";
import { guarantor_signing } from "./guarantor_signing";
import { guarantor_signing_finadev } from "./guarantor_signing_finadev";
import "./guarantor_signing.container.scss";
import {Message} from "./../home/message";

class GuarantorSigningContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const deploymentModels = {
      mfw: guarantor_signing,
      finadev: guarantor_signing_finadev,
    };

    // setup model
    const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
      const model =
        deploymentModels[Config.deployment] !== undefined
          ? deploymentModels[Config.deployment]
          : deploymentModels.mfw;
      model.locale = locale;
      return model;
    };

    this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

    // make loan terms available in intake
    let loan_terms_summary = this.props.loan.loan_terms_summary
      ? this.props.loan.loan_terms_summary
      : {};

    const intake = this.props.loan.intake_guarantor;
    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan,
      ...loan_terms_summary,
      intake: intake,

      // reset signature because of possible CORS
      // errors when not setting it.
      signature: "",
    };

    try {
      this.model.currentPageNo = this.props.loan.intake_guarantor.current_page;
    } catch (error) {
      console.error(
        "Sometimes setting the page fails, so starting on title due to error: ",
        error
      );
    }

    // set callbacks
    this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };

    this.header = "";
    try {
        let partner_number = undefined;
        if (this.model.data.intake.custom_text_127) {
            partner_number = this.model.data.intake.custom_text_127;
        } else if (this.model.data.intake.custom_text_128) {
            partner_number = this.model.data.intake.custom_text_128;
        }

        this.header = (
            <h2 className="heavy bigSpacer">
                {translate("start_guarantor.header", {
                    guarantor_first_name: this.model.data.intake.first_name
                        ? this.model.data.intake.first_name
                        : this.props.loan.guarantor_first_name,
                    partner_number,
                })}
            </h2>
        );
    } catch (e) {
        console.error(`Unable to translate ${e}`);
    }
  }

  onPartialSend(survey) {
    let data = {
      ...survey.data,
      current_page: survey.currentPageNo,
    };
    this.props.updateGuarantor(this.props.loan.uuid, this.base, data, false);
  }

  onCompleting(survey) {
    let data = survey.data;

    // first save signature
    saveBase64(
      "signature.png",
      "image/png",
      data.final_signature,
      (signature_date) => {
        data["final_signature"] = signature_date;
        console.log("saving data:", data);
        this.props.updateGuarantor(this.props.loan.uuid, this.base, data, true);
      }
    );
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time:
        this.state.help_time +
        Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render = ({ history, account, device, loan } = this.props) => (
    <div className="guarantorContainer centerWrapper">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="guarantor"
        locale={device.locale}
      />
      {this.props.loan.intake_guarantor.guarantor_refused ? (
                <div>
                    {/* Page to be displayed when guarantor refused */}
                    <Message message="submitted_guarantor"/>
                </div>
            ) : this.state.is_started ? (
        <div className="centerWrapper">
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="sign_guarantor"
            time="2"
            onStart={() => this.setState({ is_started: true })}
          >
            {this.header}
            
            <p className="heavy">
              {translate("sign_guarantor.desc1", {
                first_name: loan.first_name,
                amount: loan.amount,
              })}
            </p>
            <p className="spacer">{translate("sign_guarantor.desc2")}</p>
            <p className="spacer">{translate("sign_guarantor.desc3")}</p>
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};


    

const mapDispatchToProps = (dispatch) => {
  return {
    updateGuarantor: (uuid, base, guarantor_intake, is_completed) =>
      dispatch(
        loanActions.signGuarantor(
          uuid,
          useBase(base, guarantor_intake),
          is_completed
        )
      ),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuarantorSigningContainer);

export { connectedContainer as GuarantorSigningContainer };
