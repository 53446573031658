import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
const required = env.ENDPOINT !== "local";

export const identification_bll = new SurveyComponent({
  name: "identification",
  title: {
    en: "Identification Information",
    rn: "Ibijanye n'umwidondoro w'umunywanyi",
    "fr-BI": "Les informations sur l'identification du client",
  },
  elements: [
    {
      name: "nationality",
      type: "dropdown",
      isRequired: required,
      storeOthersAsComment: false,
      hasOther: false,
      title: {
        en: "Nationality *",
        rn: "Ubwenegihugu bwawe ni ubwawe?",
        "fr-BI": "Quelle est votre nationalité ?",
      },
      choices: [
        {
          value: "Burundais",
          text: {
            en: "Burundian",
            rn: "Umurundi",
            "fr-BI": "Burundais",
          },
        },
        {
          value: "Autre",
          text: {
            en: "Other",
            rn: "Ubundi bwenegihugu",
            "fr-BI": "Autre",
          },
        },
      ],
    },
    {
      name: "custom_text_3",
      type: "text",
      isRequired: required,
      visibleIf: "{nationality} == 'other'",
      title: {
        en: " Specify",
        rn: "Erekana neza",
        "fr-BI": "Précise",
      },
    },
    {
      title: {
        en: "What is your marital status? *",
        rn: "Uri mu rwego uruhe rw'umuryango?",
        "fr-BI": "Quelle est votre situation matrimoniale ?",
      },
      name: "marital",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: "Marié(e)",
          text: { en: "Married", rn: "Urubatse", "fr-BI": "Marié (e)" },
        },
        {
          value: "Célibataire",
          text: { en: "Single", rn: "Umusore", "fr-BI": "Célibataire" },
        },
        {
          value: "Veuf(ve)",
          text: { en: "Widowed", rn: "Umupfakazi", "fr-BI": "Veuf (ve)" },
        },
      ],
    },
    {
      name: "custom_text_1",
      type: "text",
      isRequired: required,
      visibleIf: "{marital} == 'Marié(e)'",
      title: {
        en: " What is the full name  of your spouse ?",
        rn: "Ni ayahe mazina yose y'uwo mwubakanye?",
        "fr-BI": "Quel est le nom complet de votre conjoint ?",
      },
    },
    {
      name: "custom_text_2",
      type: "text",
      isRequired: required,
      visibleIf: "{marital} == 'Marié(e)'",
      title: {
        en: "What is identification card number of your spouse ? ",
        rn: "Ni iyahe nimero ya karangamuntu y'uwo mwubakanye?",
        "fr-BI": "Quel est le numéro de carte d'identité de votre conjoint ?",
      },
    },
    {
      title: {
        en: "Upload Id card of your spouse (front)",
        rn: "Shiramwo kopi y'ikarata karangamuntu y'uwo mwubakanye (imbere) *",
        "fr-BI":
          "Veuillez insérer une copie de carte d'identité de votre conjoint (avant)",
      },
      name: "file_1",
      visibleIf: "{marital} == 'Marié(e)'",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Upload Id card of your spouse ( Verso)",
        rn: "Shiramwo kopi y'ikarata karangamuntu y'uwo mwubakanye (inyuma)",
        "fr-BI":
          "Veuillez insérer une copie de carte d'identité de votre conjoint (recto)",
      },
      name: "file_2",
      visibleIf: "{marital} == 'Marié(e)'",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      name: "custom_text_4",
      type: "text",
      isRequired: required,
      visibleIf: "{marital} == 'Marié(e)'",
      title: {
        en: "Number of children",
        rn: "Igitigiri c'abana",
        "fr-BI": "Nombre d'enfants",
      },
    },
    {
      name: "id_number",
      type: "text",
      isRequired: required,
      title: {
        en: "ID No. *",
        rn: "Inimero y'ikarata karangamuntu yawe",
        "fr-BI": "Quel est le numéro de carte d'identité ?",
      },
    },
    {
      title: {
        en: "Front Image (Attachment) *",
        rn: "Shiramwo ikarata karangamuntu kawe (imbere)​",
        "fr-BI": "Veuillez insérer une copie de votre carte d'identité (recto)",
      },
      name: "id_file",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Back Image (Attachment) *",
        rn: "Shiramwo ikarata karangamuntu kawe (inyuma)​",
        "fr-BI": "Veuillez insérer une copie de votre carte d'identité (verso)",
      },
      name: "id_file_back",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      name: "id_avatar",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "Please verify that this card belongs to you",
        rn: "Kugira tubone neza ibikuranga, Erekana ifoto yawe ufise ikarata karangamuntu yawe",
        "fr-BI": "Pour vérifier votre identification",
      },
      elements: [
        {
          name: "id_avatar_desc_picture",
          type: "html",
          html: `<img alt="example" src='${require("../../../../assets/images/example_card.png")}' width='200'/>`,
        },
        {
          name: "id_file_with_avatar",
          title: {
            en: " ",
            rn: " ",
            "fr-BI": " ",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: false,
        },
      ],
    },
  ],
});
