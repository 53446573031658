export const accountState = {

  // seperate pending states and user profile
  getAccountPending: false,
  updateAccountPending: false,
  localePending: false,

  profile: {

    uuid: null,
    email: "",
    first_name: "",
  }
};
