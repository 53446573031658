import React from 'react';
import ReactDOM from 'react-dom';
import './multiple_choice.scss';

class MultipleChoice extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: null,
      choices: []
    };

    // reverse answers sometimes
    if (Math.random() < 0.5) {
      this.state = {value: null, choices: props.choices.reverse()}
    } else {
      this.state = {
        value: null,
        choices: props.choices
      };
    }
  }

  _update(newValue) {
    this.setState({value: newValue});
    this._sendValue(newValue);
  };

  _setUnclear() {
    // only allows for unclear to be made
    this.setState({value: 'unclear'});
    this.props.changeHandler('unclear');
    this.unClearInput.focus();
  }

  _sendValue(value) {
    if (value) {
      this.props.changeHandler(value)
    }
  }

  render({question} = this.props) {
    return (
      <div className='multipleChoice'>
        <div className='choices'>
          {this.state.choices.map((_c, index) => (
            <div key={index} className='choice'>
              <label>
                <input type="radio" value={_c.value} checked={this.state.value === _c.value}
                       onChange={() => this._update(_c.value)}/>
                {_c.locText.text}
              </label>
            </div>
          ))}
        </div>

        <div className='unclear'>

          <div className={`checkbox ${this.state.value === 'unclear' ? 'heavy' : ''}`}>
            <div>
              <label>
                <input type="checkbox" value="unclear" checked={this.state.value === 'unclear'}
                       onChange={this.state.value === 'unclear' ? () => this._update(null) : () => this._setUnclear()}/>
                {question.otherItem.locText.text}
              </label>
            </div>
          </div>

          <div className={`input_unclear ${this.state.value === 'unclear' ? '' : 'hidden'}`}>
            <span className='small'>{question.otherErrorText}</span>
            <input
              ref={(ref) => this.unClearInput = ref}
              type="text"
              name="comment"
              maxLength={230}
              onBlur={(event) => this._sendValue(event.target.value ? `unclear: ${event.target.value}` : 'unclear')}
            />
          </div>

        </div>
      </div>
    )
  }
}


export function register_widget_multiple_choice(Survey) {
  const widget = {
    name: "radiogroup",

    widgetIsLoaded: function () {
      return true;
    },

    htmlTemplate: "<div></div>",

    isFit: function (question) {
      return question.getType() === "radiogroup" && question["renderAs"] === "choice";
    },

    activatedByChanged: function (activatedBy) {
      // add possible properties so that you can pass them along
      Survey.JsonObject.metaData.addProperties("radiogroup", [{
        name: "renderAs",
        default: "standard",
        choices: ["standard", "choice"]
      }]);
    },

    afterRender: function (question, element) {

      let rendering = <MultipleChoice choices={question.choices} question={question}
                                      changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, element);
    },

    willUnmount: function (question, el) {
      // destroy callbacks if needed
    }
  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "property");
}
