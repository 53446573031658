export const survey_ar = {
  "pagePrevText": "السابق",
  "pageNextText": "التالي",
  "completeText": "انهاء- تم",
  "progressText": "صفحة {0} من {1}",
  "requiredError": "يرجى الإجابة على السؤال",

  "otherItemText": "أخرى",
  "emptySurvey": "لا توجد صفحة أو سؤال في الإستبيان",
  "completingSurvey": "شكرا لك لاستكمال الاستبيان!",
  "completingSurveyBefore": "تظهر سجلاتنا بأنك قد أكملت هذا الإستبيان من قبل",
  "loadingSurvey": "...يتم تحميل الاستبيان",
  "optionsCaption": "...اختر",

  "requiredInAllRowsError": "يرجى الإجابة على الأسئلة في جميع الصفوف",
  "numericError": "يجب أن تكون القيمة الرقمية.",
  "textMinLength": "الرجاء إدخال ما لا يقل عن {0} حرف",
  "textMaxLength": "الرجاء إدخال أقل من {0} حرف",
  "textMinMaxLength": "يرجى إدخال أكثر من {0} وأقل من {1} حرف",
  "minRowCountError": "يرجى ملء ما لا يقل عن {0} الصفوف",
  "minSelectError": "يرجى تحديد ما لا يقل عن {0} المتغيرات",
  "maxSelectError": "يرجى تحديد ما لا يزيد عن {0} المتغيرات",
  "numericMin": "و'{0}' يجب أن تكون مساوية أو أكثر من {1}ا",
  "numericMax": "و'{0}' يجب أن تكون مساوية أو أقل من {1}ا",
  "invalidEmail": "رجاء قم بإدخال بريد الكتروني صحيح",
  "urlRequestError": "طلب إرجاع خطأ '{0}'. {1}ا",
  "urlGetChoicesError": "عاد طلب بيانات فارغة أو 'المسار' ممتلكات غير صحيحة ",
  "exceedMaxSize": "وينبغي ألا يتجاوز حجم الملف {0}ا",
  "otherRequiredError": "الرجاء إدخال قيمة أخرى",
  "uploadingFile": "الملف الخاص بك تحميل. يرجى الانتظار عدة ثوان وحاول مرة أخرى",
  "addRow": "اضافة صف",
  "removeRow": "إزالة"
};
