export const survey_es = {
    "pagePrevText": "Anterior",
    "pageNextText": "Siguiente",
    "completeText": "Completo",
    "startSurveyText": "Inicio",
    "progressText": "Página {0} de {1}",
    "requiredError": "Responda la pregunta.",
  
    "otherItemText": "Otro",
    "emptySurvey": "No hay ninguna página o pregunta visible en la encuesta.",
    "completeSurvey": "¡Gracias por completar la encuesta!",
    "completeSurveyBefore": "Nuestros registros muestran que ya completó esta encuesta.",
    "loadingSurvey": "La encuesta se está cargando ...",
    "optionsCaption": "Elegir ...",
    "valor": "valor",
    "requiredErrorInPanel": "Responda al menos una pregunta.",
    "requiredInAllRowsError": "Responda las preguntas en todas las filas.",
    "numericError": "El valor debe ser numérico.",
    "textMinLength": "Introduzca al menos {0} caracteres",
    "textMaxLength": "Introduzca menos de {0} caracteres",
    "textMinMaxLength": "Ingrese más de {0} y menos de {1} caracteres.",
    "minRowCountError": "Complete al menos {0} filas",
    "minSelectError": "Seleccione al menos {0} variantes",
    "maxSelectError": "No seleccione más de {0} variantes",
    "numericMinMax": "El '{0}' debe ser igual o mayor que {1} e igual o menor que {2}",
    "numericMin": "El '{0}' debe ser igual o superior a {1}",
    "numericMax": "El '{0}' debe ser igual o menor que {1}",
    "invalidEmail": "Introduzca una dirección de correo electrónico válida.",
    "invalidExpression": "La expresión: {0} debe devolver 'true'.",
    "urlRequestError": "La solicitud devolvió el error '{0}'. {1}",
    "urlGetChoicesError": "La solicitud devolvió datos vacíos o la propiedad 'ruta' es incorrecta",
    "excedeMaxSize": "El tamaño del archivo no debe exceder {0}.",
    "otherRequiredError": "Introduzca el otro valor.",
    "uploadingFile": "Su archivo se está cargando. Espere unos segundos y vuelva a intentarlo.",
    "loadingFile": "Cargando ...",
    "chooseFile": "Elija archivo (s) ...",
    "confirmDelete": "¿Desea eliminar el registro?",
    "keyDuplicationError": "Este valor debe ser único.",
    "addColumn": "Agregar columna",
    "addRow": "Agregar fila",
    "removeRow": "Eliminar",
    "addPanel": "Agregar nuevo",
    "removePanel": "Eliminar",
    "choice_Item": "elemento",
    "matrix_column": "Columna",
    "matrix_row": "Fila",
    "saveData": "Los resultados se guardan en el servidor ...",
    "saveDataError": "Se produjo un error y no pudimos guardar los resultados.",
    "saveDataSuccess": "¡Los resultados se guardaron correctamente!",
    "saveAgainButton": "Vuelve a intentarlo",
    "timerMin": "min",
    "timerSec": "seg",
    "timerSpentAll": "Has gastado {0} en esta página y {1} en total",
    "timerSpentPage": "Has gastado {0} en esta página",
    "timerSpentSurvey": "Ha gastado {0} en total",
    "timerLimitAll": "Ha gastado {0} de {1} en esta página y {2} de {3} en total.",
    "timerLimitPage": "Has gastado {0} de {1} en esta página",
    "timerLimitSurvey": "Ha gastado {0} de {1} en total",
    "cleanCaption": "Limpiar",
    "clearCaption": "Borrar"
  };
  