
let live_questions = [
  {
    title: {en: "How many hours do you work on your project per day?", ar: "كم ساعة تعمل على مشروعك في اليوم؟ "},
    description: {en: "On average last week, during working days.", ar: " في الأسبوع الماضي, خلال أيام العمل "},
    name: 'hours_work',
    type: "nouislider",
    step: 1,
    rangeMin: 0,
    rangeMax: 16,
    isRequired: true
  },
  {
    title: {en: "How many hours do you spend with your family, friends, on average per weekday?", ar: "كم عدد الساعات التي تقضيها مع العائلة والأصدقاء في اليوم؟"},
    description: {en: "On average last week, during working days.", ar: "الأسبوع الماضي, خلال أيام العمل"},
    name: 'hours_family',
    type: "nouislider",
    step: 1,
    rangeMin: 0,
    rangeMax: 16,
    isRequired: true
  },
  {
    title: {en: "How many hours have you slept last few days per night?", ar: "كم عدد الساعات التي نمتها خلال الأيام الماضية في الليلة الواحدة؟"},
    description: {en: "On average last week, during working days.", ar: "الأسبوع الماضي, خلال أيام العمل"},
    name: 'hours_sleep',
    type: "nouislider",
    step: 1,
    rangeMin: 0,
    rangeMax: 16,
    isRequired: true
  },
  {
    title: {en: "If you lost your income or business, how long could you pay your expenses?", ar: "إذا خسرت دخلك أو مشروعك, ما المدة التي تستطيع الإستمرار بها في دفع نفاقتك"},
    description: {en: "How many months could you continue to pay all your expenses.", ar: "كم عدد الأشهر التي تستطيع أن تستمر فيها في دفع نفقاتك؟"},
    name: 'months_buffer',
    type: "nouislider",
    step: 1,
    rangeMin: 0,
    rangeMax: 16,
    unit: 'months',
    isRequired: true
  },
  {
    title: {en: "Who takes care of your business if you are away or sick?", ar: "من مسؤول عن عملك في حال غيابك أو مرضك؟"},
    name: 'takes_care',
    type: "comment",
    isRequired: true
  }
];

export function add_raw_questions(assessment, test=true) {

  // export correct json
  (test ? [live_questions.splice(1, 3)] : live_questions).forEach((question) => {

    assessment.pages.push(
      {
        name: question.name,
        elements: [question]
      }
    )
  });
}