import { SurveyComponent } from "../../../../components/survey/survey_component";

export const income_eduloan = new SurveyComponent({
  title: {
    en: "Income and Expenses",
  },
  name: "income_and_expenses",
  elements: [
    {
      title: {
        en: "Income Details",
      },
      name: "income_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "basic_monthly_salary",
          type: "text",
          title: {
            en: "Basic monthly salary excluding overtime and bonus",
          },
          inputType: "number",
        },
        {
          name: "net_monthly_salary",
          type: "text",
          title: {
            en: "Net monthly salary excluding overtime and bonus",
          },
          inputType: "number",
        },
        {
          title: {
            en: "Please upload the latest salary advice slip",
          },
          name: "salary_advice_slip",
          type: "file_uploader",
          accept: "image/*,application/pdf",
        },
      ],
    },
    {
      title: {
        en: "Total monthly income",
      },
      type: "expression",
      name: "total_monthly_income",
      expression: `fixed(add({net_monthly_salary}), 2, true)`, // Floating Point Proof
      displayStyle: "currency",
      currency: "ZWL",
    },
    {
      title: {
        en: "Monthly expenses (e.g: food, clothes, insurance, housing etc.)",
      },
      name: "monthly_expenses",
      valueName: "monthly_expenses",
      type: "matrixdynamic",
      rowCount: 1,
      isRequired: false,
      addRowText: { en: "Add" },
      columns: [
        {
          title: { en: "Type of expense" },
          name: "title",
          isRequired: false,
          cellType: "text",
          inputType: "string",
        },
        {
          title: { en: "Amount per month (ZWS)" },
          name: "amount",
          isRequired: false,
          cellType: "text",
          inputType: "number",
        },
        {
          title: {
            en: "Please upload proof of expense",
          },
          name: "url",
          cellType: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: false,
        },
      ],
    },
    {
      title: {
        en: "Total monthly expenses",
      },
      type: "expression",
      name: "total_monthly_expenses",
      expression: `fixed(sumMatrixdropdown({monthly_expenses}, 'amount'), 2, true)`, // Floating Point Proof
      displayStyle: "currency",
      currency: "ZWL",
    },
    {
      title: {
        en: "Monthly Disposable Income",
      },
      type: "expression",
      name: "monthly_disposable_income",
      expression: `fixed(subtract({total_monthly_income}, {total_monthly_expenses}), 2, true)`, // Floating Point Proof
      displayStyle: "currency",
      currency: "ZWL",
    },
    {
      title: {
        en: "Please upload a proof or residence with your name and address. This can be the latest water, ZESA or Affidavit bill etc.",
      },
      isRequired: false,
      name: "picture_2",
      type: "file_uploader",
      accept: "image/*,application/pdf",
    },
  ],
});
