import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
const required = env.ENDPOINT !== "local";

export const production_bll = new SurveyComponent({
  name: "production",
  title: {
    en: "Production Information",
    rn: "Ibijanye n'umwimbu",
    "fr-BI": "Informations sur la production*",
  },
  description: {
    en: "The production information provided will be verified and confirmed by the cooperative.",
    rn: "Ibijanye n'umwimbu bizosuzumwa vyongere vyemezwe na Koperative",
    "fr-BI":
      "Les informations de production fournies seront vérifiées et confirmées par la coopérative.",
  },
  elements: [
    {
      title: {
        en: "Provide with us  production information of 2 previous seasons",
        rn: "Ibijanye n'umwimbu bizosuzumwa vyongere vyemezwe na Koperative",
        "fr-BI":
          "Veuillez nous fournir les informations sur la production des 2 saisons précédentes ",
      },
      name: "panel_home",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "First Season",
            rn: "Isizeni ya mbere",
            "fr-BI": "Première saison",
          },
          name: "season_1",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "custom_float_1",
              type: "text",
              inputType: "number",
              title: {
                en: "Number of beehives",
                rn: "Igitigiri c'ibitiba",
                "fr-BI": "Nombre de ruches",
              },
            },
            {
              name: "custom_float_2",
              type: "text",
              inputType: "number",
              title: {
                en: "Production in kg",
                rn: "Umwimbu mu biro",
                "fr-BI": "Production en kg",
              },
            },
            {
              name: "custom_float_3",
              type: "text",
              inputType: "number",
              title: {
                en: "Price per KG in BIF",
                rn: "Igiciro ku kilo mu mafranga y'amarundi",
                "fr-BI": "Prix par kg en BIF",
              },
            },
            {
              name: "custom_float_4",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Total Sales",
                rn: "Ivyadandajwe vyose ",
                "fr-BI": "Ventes totales",
              },
            },
          ],
        },
        {
          title: {
            en: "Second season",
            rn: "Isizeni ya kabiri",
            "fr-BI": "Deuxième saison",
          },
          name: "season_2",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "custom_float_5",
              isRequired: required,
              type: "text",
              inputType: "number",
              title: {
                en: "Number of beehives.",
                rn: "Igitigiri c'ibitiba",
                "fr-BI": "Nombre de ruches",
              },
            },
            {
              name: "custom_float_6",
              type: "text",
              inputType: "number",
              title: {
                en: "Production in kg",
                rn: "Umwimbu mu biro",
                "fr-BI": "Production en kg",
              },
            },
            {
              name: "custom_float_7",
              type: "text",
              inputType: "number",
              title: {
                en: "Price per KG in BIF",
                rn: "Igiciro ku kilo mu mafranga y'amarundi",
                "fr-BI": "Prix par kg en BIF",
              },
            },
            {
              name: "custom_float_8",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Total Sales",
                rn: "Ivyadandajwe vyose ",
                "fr-BI": "Ventes totales",
              },
            },
          ],
        },
      ],
    },
    {
      title: {
        en: "Current season needs",
        rn: "Ibikenewe muri iyo sizeni",
        "fr-BI": "Besoins de la saison en cours",
      },
      name: "season_3",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "custom_float_9",
          type: "text",
          inputType: "number",
          title: {
            en: "Expected number of beehives",
            rn: "Ibitiba bitegekanijwe",
            "fr-BI": "Nombre de ruches prévues",
          },
        },
        {
          name: "custom_float_10",
          type: "text",
          inputType: "number",
          title: {
            en: "Expected production in kg",
            rn: "Umwimbu utegekanywa mu biro",
            "fr-BI": "Production prévue en kg",
          },
        },
        {
          name: "custom_float_11",
          type: "text",
          inputType: "number",
          title: {
            en: "Expected price per KG in BIF",
            rn: "Igiciro gitegekanywa ku kilo mu mafranga y'amarundi",
            "fr-BI": "Prix ​​prévu par kg en BIF",
          },
        },
        {
          name: "custom_float_12",
          type: "text",
          inputType: "number",
          isRequired: required,
          title: {
            en: "Expected total Sales",
            rn: "Ibitegekanijwe kugurishwa vyose hamwe ",
            "fr-BI": "Ventes totales ​​prévues",
          },
        },
      ],
    },
    {
      name: "custom_text_10",
      type: "radiogroup",
      title: {
        en: "What is the purpose of the credit ?",
        rn: "Ni uyuhe mugambi w'iyo ngurane?",
        "fr-BI": "Quel est l'objet du crédit ?",
      },
      choices: [
        {
          value: "equipment",
          text: {
            en: "Acquisition of equipments",
            rn: "Kugura ibikoresho",
            "fr-BI": "Acquisition d’équipements",
          },
        },
        {
          value: "credit",
          text: {
            en: "Cash credit (stock financing, pre-financing of a payment to be received, etc.)",
            rn: "ngurane y'amafranga yo gukoresha (kurangura, amafranga akenewe imbere yuko urihwa, nibindi,,,,)",
            "fr-BI":
              "Crédit de trésorerie (financement de stock, préfinancement d’un paiement à recevoir, etc.)",
          },
        },
        {
          value: "other",
          text: {
            en: "Others (e.g. fittings, works, etc.)",
            rn: "bindi (akarorero: gutegura aho ukorera, ibikorwa, n'ibindi,,,)",
            "fr-BI": "Autres (ex : aménagements, travaux, etc.)",
          },
        },
      ],
    },
    {
      title: {
        en: "Amount of funding requested",
        rn: "Amafranga yasabwe",
        "fr-BI": "Montant du financement sollicité",
      },
      name: "current_agricultural_season",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "amount",
          type: "text",
          inputType: "number",
          title: {
            en: "Total amount of funding sought",
            rn: "Amafranga yose hamwe y'ingurane yasabwe",
            "fr-BI": "Montant total du financement sollicité",
          },
        },
        {
          name: "custom_float_14",
          type: "text",
          inputType: "number",
          title: {
            en: "Funds already available to the loan applicant",
            rn: "Amafranga uwasavye ingurane asanzwe afise",
            "fr-BI": "Fonds déjà à disposition du demandeur de crédit",
          },
        },
        {
          name: "custom_float_15",
          type: "text",
          inputType: "number",
          title: {
            en: "Amount of Credit requested for the purchase of Equipment",
            rn: "Amafranga y'ingurane yo kugura ibikoresho",
            "fr-BI": "Montant du crédit sollicité pour l’achat d'équipements",
          },
        },
        {
          name: "custom_float_17",
          type: "text",
          inputType: "number",
          title: {
            en: "Credit amount of other activities",
            rn: "Amafranga y'ingurane ku bindi bikorwa bitandukanye",
            "fr-BI": "Montant du crédit pour d’autres activités",
          },
        },
      ],
    },
    {
      name: "custom_text_15",
      type: "dropdown",
      title: {
        en: "Do you have personal or professional credits to repay ?",
        rn: "Urafise iyindi ngurane ku gatwe kawe canke ijanye n'akazi utaraheza kwishura?",
        "fr-BI":
          "Avez-vous des crédits personnels ou professionnels à rembourser ?",
      },
      choices: [
        { value: "oui", text: { en: "Yes", rn: "Ego", "es-MX": "Oui" } },
        { value: "non", text: { en: "No", rn: "Oya", "es-MX": "Non" } },
      ],
    },
    {
      name: "custom_text_16",
      type: "text",
      visibleIf: "{custom_text_15} = 'oui'",
      title: {
        en: "What type of credit ?",
        rn: "Ni ubuhe bwoko bw'ingurane?",
        "fr-BI": "Quel type de crédit",
      },
    },
    {
      title: {
        en: "If you have a loan in the Bank or MFI please provide with us the infomations below",
        rn: "Niba ufise ingurane muri Banki canke Microfinance duhe amakuru akurikira​",
        "fr-BI":
          "Si vous avez un prêt auprès d'une banque ou MFI, veuillez nous fournir les informations ci-dessous",
      },
      name: "bank_panel",
      type: "panel",
      innerIndent: 1,
      visibleIf: "{custom_text_15} = 'oui'",
      elements: [
        {
          name: "custom_text_17",
          type: "text",
          title: {
            en: "Name of Bank or MFI ",
            rn: "Izina rya banki canke microfinance",
            "fr-BI": "Nom de la Banque ou MFI",
          },
        },
        {
          name: "custom_float_19",
          type: "text",
          inputType: "number",
          title: {
            en: "Original amount borrowed",
            rn: "Ingurane yasabwe",
            "fr-BI": "Montant initialement emprunté",
          },
        },
        {
          name: "custom_float_20",
          type: "text",
          inputType: "number",
          title: {
            en: "Capital remaining to be repaid",
            rn: "Umutahe usigaje kwishura",
            "fr-BI": "Capital restant à rembourser",
          },
        },
        {
          name: "custom_float_21",
          type: "text",
          inputType: "number",
          title: {
            en: "Amount of the monthly payment",
            rn: "Amafranga wishura ku kwezi",
            "fr-BI": "Montant de la mensualité",
          },
        },
        {
          name: "custom_text_23",
          type: "text",
          title: {
            en: "Interest rate",
            rn: "Urugezo rw'inyungu",
            "fr-BI": "Taux d’intérêt",
          },
        },
      ],
    },
    {
      title: {
        en: "If you have a loan with cooperative or association please provide with us the information below",
        rn: "Niba ufise ingurane muri koperative canke inshirahamwe duhe amakuru akurkira​",
        "fr-BI":
          "Si vous avez un prêt auprès d'une coopérative ou assiociation, veuillez nous fournir les informations ci-dessous ",
      },
      name: "coop_panel",
      type: "panel",
      innerIndent: 1,
      visibleIf: "{custom_text_15} = 'oui'",
      elements: [
        {
          name: "custom_text_28",
          type: "text",
          title: {
            en: "Name of cooperative or association",
            rn: "Izina rya koperative cane Ishirahamwe ​",
            "fr-BI": "Nom de la coopérative ou association",
          },
        },
        {
          name: "custom_float_28",
          type: "text",
          inputType: "number",
          title: {
            en: "Original amount borrowed",
            rn: "Ingurane yasabwe",
            "fr-BI": "Montant initialement emprunté",
          },
        },
        {
          name: "custom_float_29",
          type: "text",
          inputType: "number",
          title: {
            en: "Capital remaining to be repaid",
            rn: "Umutahe usigaje kwishura",
            "fr-BI": "Capital restant à rembourser",
          },
        },
        {
          name: "custom_float_30",
          type: "text",
          inputType: "number",
          title: {
            en: "Amount of the monthly payment",
            rn: "Amafranga wishura ku kwezi",
            "fr-BI": "Montant de la mensualité",
          },
        },
        {
          name: "custom_text_29",
          type: "text",
          title: {
            en: "Interest rate",
            rn: "Urugezo rw'inyungu",
            "fr-BI": "Taux d’intérêt",
          },
        },
      ],
    },
  ],
});
