import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

export const fiduciary = new SurveyComponent({
  title: {
    en: "Fiduciary",
    "es-GT": "Fiduciaria",
  },
  description: {
    en: "Information related to your Guarantor",
    "es-GT": "Información relacionada con su fiador",
  },
  name: "fiduciary",
  type: "panel",
  innerIndent: 1,
  visibleIf: "{collateral_arrangements} == 'fiduciaria'",
  elements: [
    {
      name: "guarantor_first_name",
      type: "text",
      isRequired: required,
      title: {
        en: "Name of your guarantor *",
        "es-GT": "Nombre de su fiador *",
      },
    },
    {
      name: "guarantor_phone",
      type: "phone",
      isRequired: required,
      title: {
        en: "Telephone number of your guarantor*",
        "es-GT": "Número de teléfono de su fiador *",
      },
      description: {
        en: "We will use this phone number to send your guarantor a short application form.",
        "es-GT":
          "Usaremos este número de teléfono para solicitar a tu fiador autorización para consulta al buró de crédito",
      },
      validators: [
        expressions.phone_en("guarantor_phone"),
        expressions.phone_length("guarantor_phone"),
      ],
    },
    {
      title: {
        en: "Guarantor email address *",
        "es-GT": "Correo electrónico de su fiador *",
      },
      isRequired: required,
      name: "custom_text_55",
      type: "text",
      validators: [{ type: "email" }],
    },
  ],
});
