import { SurveyComponent } from "../../../../components/survey/survey_component";
import { expressions } from "../../../../survey/validators";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const student_eduloan = new SurveyComponent({
  title: {
    en: "Student's details",
  },
  name: "student",
  elements: [
    {
      title: { en: "Student's Surname *" },
      name: "student_lastname",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Student's Firstname *" },
      name: "student_firstname",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Student's ID no" },
      name: "student_id_no",
      type: "text"
    },
    {
      name: "student_phone",
      type: "phone",
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
      title: {
        en: "Student's Telephone number",
      },
    },
    {
      title: { en: "Student's Address" },
      name: "student_address",
      type: "text",
    },
    {
      title: { en: "Student's Email Address" },
      name: "student_email",
      type: "text",
    },
    {
      title: { en: "Student reg number" },
      name: "student_number",
      type: "text",
    },
    {
      title: {
        en: "Student's date of birth",
      },
      name: "student_date_of_birth",
      type: "text",
      inputType: "date",
    },
    {
      title: {
        en:
          "Name of University / College / Primary School / Secondary School *",
      },
      name: "academic_institution",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Current Semester / Term *" },
      name: "academic_current_semester",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Program of study / grade / form *" },
      name: "academic_major_study",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Please upload an invoice of the University / College / School *",
      },
      description: {
        en:
          "If you cannot find the document now, you can close this form and come back later.",
      },
      name: "picture_1",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: { en: "Start year" },
      name: "academic_start",
      type: "text",
      isRequired: false,
      inputType: "date",
    },
    {
      title: { en: "End year" },
      name: "academic_end",
      type: "text",
      isRequired: false,
      inputType: "date",
    },
  ],
});
