import Config from "../../config";
import env from "../../env";
import { expressions } from "../../survey/validators";

const required = env.ENDPOINT !== "local";

export const credit_check_form_banco_popular_new_client = [
  {
    name: "Credit check new client",
    title: {
      en: "Credit check new client",
      "es-HN": "Precalificación nuevo cliente",
    },
    description: {
      en: "Fill in the following information to pre-qualify your application",
      "es-HN": "Completa los siguientes datos para precalificar tu solicitud",
    },
    elements: [
      {
        type: "text",
        name: "first_name",
        title: {
          en: "Full name",
          "es-HN": "Nombre completo",
        },
        isRequired: required,
      },
      {
        name: "birth_date_panel",
        type: "panel",
        innerIndent: 2,
        title: {
          en: "Date of birth",
          "es-HN": "Fecha de nacimiento"
        },
        elements: [
          {
            type: "dropdown",
            name: "date_type_1",
            title: {
              en: "Enter date with:",
              "es-HN": "Ingresar fecha con:",
            },
            defaultValue: "calendario",
            choices: [
              {value: "calendario", text: {en: "Calendar", "es-HN": "Calendario"}},
              {value: "texto", text: {en: "Text", "es-HN": "Texto"}}
            ]
          },
          {
            visibleIf: "{date_type_1} == 'calendario'",
            type: "text",
            name: "date_of_birth",
            isRequired: required,
            inputType: "date",
            title: { en: "Date of birth", "es-HN": "Fecha de nacimiento" },
          },
          {
            visibleIf: "{date_type_1} == 'texto'",
            name: "date_of_birth",
            type: "text",
            title: { en: "Date of birth", "es-HN": "Fecha de nacimiento" },
            placeHolder: "aaaa-mm-dd",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Enter date with the format yyyy-mm-dd",
                  "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                },
                regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
              },
            ],
          }
        ]
      },
      {
        name: "id_type",
        title: {
          en: "Type of document",
          "es-HN": "Tipo de documento",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_documentos || [],
        isRequired: required,
      },
      {
        type: "text",
        name: "id_number",
        title: {
          en: "ID Number",
          "es-HN": "Número de documento",
        },
        isRequired: required,
        validators: [
          {
            type: "text",
            minLength: 13,
            text: "Valida tu identificación. Ingresa 13 caracteres como mínimo",
          },
          {
            type: "text",
            maxLength: 18,
            text: "Valida tu identificación. Ingresa 18 caracteres como máximo",
          },
        ],
      },
      {
        title: {
          en: "National Tax Registry",
          "es-HN": "Registro Tributario Nacional",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_10",
      },
      {
        name: "custom_text_1",
        title: {
          en: "Current Profession / Occupation / Dedication",
          "es-HN": "Profesión/ Oficio / Dedicación actual",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.profesiones || [],
        isRequired: required,
      },
      {
        name: "custom_text_8",
        title: {
          en: "Economic activity",
          "es-HN": "Actividad económica",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.act_economicas || [],
        isRequired: required,
      },
      {
        name: "custom_text_36",
        title: {
          en: "Economic sector",
          "es-HN": "Sector económico",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.sect_economicos || [],
        isRequired: required,
      },
      {
        name: "custom_text_2",
        title: {
          en: "Educational level",
          "es-HN": "Nivel educativo",
        },
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.nivel_educativo || [],
      },
      {
        type: "dropdown",
        name: "custom_text_34",
        title: {
          "es-HN": "Sueldo o venta promedio al mes",
          en: "Average monthly salary or sales",
        },
        isRequired: required,
        choices: Config.sueldos || [],
      },
      {
        title: { en: "Marital status", "es-HN": "Estado civil" },
        name: "marital",
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.estado_civil || [],
      },
      {
        type: "text",
        name: "dependence_number",
        title: {
          "es-HN": "N. dependientes",
          en: "Number of Dependents ",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 1,
            text: "Responda con mínimo 1",
          },
          {
            type: "numeric",
            maxValue: 99,
            text: "Responda con máximo 99",
          },
        ],
      },
      {
        type: "dropdown",
        name: "gender",
        title: {
          "es-HN": "Género",
          en: "Gender",
        },
        isRequired: required,
        choices: [
          { value: "masculino", text: { en: "Male", "es-HN": "MASCULINO" } },
          { value: "femenino", text: { en: "Female", "es-HN": "FEMENINO" } },
        ],
      },
      {
        name: "nationality",
        title: {
          en: "Nationality",
          "es-HN": "Nacionalidad",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.nacionalidad || [],
        isRequired: required,
      },
      {
        name: "custom_text_3",
        title: {
          en: "Type of housing",
          "es-HN": "Tipo de Vivienda",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_propiedad || [],
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_integer_1",
        title: {
          "es-HN": "Años de residencia",
          en: "Years of residence ",
        },
        isRequired: required,
        inputType: "number",
      },
      {
        type: "comment",
        name: "custom_textbox_1",
        title: {
          "es-HN": "Dirección exacta del domicilio",
          en: "Exact address of residence ",
        },
        isRequired: required,
        maxLength: 500,
      },
      {
        name: "home_city",
        title: {
          en: "City ",
          "es-HN": "Ciudad",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.ciudades || [],
        isRequired: required,
      },
      {
        name: "custom_text_5",
        title: {
          en: "Department ",
          "es-HN": "Departamento",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.departamento || [],
        isRequired: required,
      },
      {
        title: {
          en: "Mobile phone number",
          "es-HN": "No. Celular",
        },
        type: "phone",
        name: "phone_number",
        isRequired: required,
        validators: [
          expressions.phone_en("phone_number"),
          expressions.phone_length("phone_number"),
        ],
      },
      {
        title: {
          en: "Additional phone-number ",
          "es-HN": "No. de teléfono adicional",
        },
        type: "phone",
        name: "mobile_phone_number_2",
        validators: [
          expressions.phone_en("mobile_phone_number_2"),
          expressions.phone_length("mobile_phone_number_2"),
          expressions.same_phone_number("mobile_phone_number_2")
        ],
      },
      {
        title: {
          en: "Please upload a photo of the front of your ID",
          "es-HN": "Por favor subir foto del frente de la identidad",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a photo of the back of your ID",
          "es-HN": "Por favor subir foto del reverso de la identidad",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_2",
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a selfie showing your identity",
          "es-HN": "Por favor subir una selfie mostrando tu identidad",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_3",
        isRequired: required,
      },
      {
        name: "custom_jsonfield_3",
        valueName: "custom_jsonfield_3",
        type: "matrixdynamic",
        minRowCount: 3,
        maxRowCount: 4,
        rowLayout: "vertical",
        rowCount: 3,
        addRowLocation: "bottom",
        isRequired: required,
        validators: [
          {
            type: "expression",
            text: {
              en: "Please make sure to input different phone numbers for all members.",
              "es-HN":
                "Asegúrese de ingresar números de teléfono diferentes para todos los integrantes.",
            },
            expression: `differentPhoneNumbers({custom_jsonfield_3}, 'telefono') == true`,
          },
          {
            type: "expression",
            text: {
              en: "Please make sure none of the numbers are the same as yours.",
              "es-HN":
                "Asegúrese de que ningún número sea igual al suyo.",
            },
            expression: `samePhoneNumbersMatrix({custom_jsonfield_3}, {phone_number}, 'telefono') == true`,
          },
        ],
        addRowText: { en: "Add", "es-HN": "Añadir" },
        title: {
          en: "Personal references",
          "es-HN": "Referencias personales",
        },
        columns: [
          {
            title: { en: "Name", "es-HN": "Nombre" },
            name: "nombre",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {en: "Relationship or kinship", "es-HN": "Relación o parentesco" },
            name: "relacion",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Address", "es-HN": "Domicilio" },
            name: "domicilio",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Telephone", "es-HN": "Teléfono" },
            name: "telefono",
            isRequired: required,
            cellType: "phone",
            validators: [
              expressions.phone_en("telefono"),
              expressions.phone_length("telefono")
            ],
          },
        ],
      },
      {
        type: "signaturepad",
        name: "file_1",
        title: {
          en: "Applicant's digital signature",
          "es-HN": "Firma digital del solicitante",
        },
        isRequired: required,
      },
    ],
  },
];

export const credit_check_form_banco_popular_existing_client = [
  {
    name: "Credit check recurrent client",
    title: {
      en: "Credit check recurrent client",
      "es-HN": "Precalificación Recurrente",
    },
    description: {
      en: "Please fill in the following information to pre-qualify your request",
      "es-HN": "Completa los siguientes datos para precalificar tu solicitud",
    },
    elements: [
      {
        type: "text",
        name: "first_name",
        title: {
          en: "Full name",
          "es-HN": "Nombre completo",
        },
        isRequired: required,
      },
      {
        name: "birth_date_panel",
        type: "panel",
        innerIndent: 2,
        title: {
          en: "Date of birth",
          "es-HN": "Fecha de nacimiento"
        },
        elements: [
          {
            type: "dropdown",
            name: "date_type_1",
            title: {
              en: "Enter date with:",
              "es-HN": "Ingresar fecha con:",
            },
            defaultValue: "calendario",
            choices: [
              {value: "calendario", text: {en: "Calendar", "es-HN": "Calendario"}},
              {value: "texto", text: {en: "Text", "es-HN": "Texto"}}
            ]
          },
          {
            visibleIf: "{date_type_1} == 'calendario'",
            type: "text",
            name: "date_of_birth",
            isRequired: required,
            inputType: "date",
            title: { en: "Date of birth", "es-HN": "Fecha de nacimiento" },
          },
          {
            visibleIf: "{date_type_1} == 'texto'",
            name: "date_of_birth",
            type: "text",
            title: { en: "Date of birth", "es-HN": "Fecha de nacimiento" },
            placeHolder: "aaaa-mm-dd",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Enter date with the format yyyy-mm-dd",
                  "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                },
                regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
              },
            ],
          }
        ]
      },
      {
        type: "dropdown",
        name: "gender",
        title: {
          "es-HN": "Género",
          en: "Gender",
        },
        isRequired: required,
        choices: [
          { value: "masculino", text: { en: "Male", "es-HN": "MASCULINO" } },
          { value: "femenino", text: { en: "Female", "es-HN": "FEMENINO" } },
        ],
      },
      {
        name: "id_type",
        title: {
          en: "Type of document",
          "es-HN": "Tipo de documento",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.tipo_documentos || [],
        isRequired: required,
      },
      {
        type: "text",
        name: "id_number",
        title: {
          en: "ID Number",
          "es-HN": "Número de documento",
        },
        isRequired: required,
        validators: [
          {
            type: "text",
            minLength: 13,
            text: "Valida tu identificación. Ingresa 13 caracteres como mínimo",
          },
          {
            type: "text",
            maxLength: 18,
            text: "Valida tu identificación. Ingresa 18 caracteres como máximo",
          },
        ],
      },
      {
        title: {
          en: "National Tax Registry",
          "es-HN": "Registro Tributario Nacional",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_10",
      },
      {
        name: "custom_text_8",
        title: {
          en: "Economic activity",
          "es-HN": "Actividad económica",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.act_economicas || [],
        isRequired: required,
      },
      {
        name: "custom_text_36",
        title: {
          en: "Economic sector",
          "es-HN": "Sector económico",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.sect_economicos || [],
        isRequired: required,
      },
      {
        name: "custom_text_2",
        title: {
          en: "Educational level",
          "es-HN": "Nivel educativo",
        },
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.nivel_educativo || [],
      },
      {
        title: { en: "Marital status", "es-HN": "Estado civil" },
        name: "marital",
        isRequired: required,
        type: "dropdown",
        renderAs: "select2",
        choices: Config.estado_civil || [],
      },
      {
        type: "dropdown",
        name: "custom_text_34",
        title: {
          "es-HN": "Sueldo o venta promedio al mes",
          en: "Average monthly salary or sales",
        },
        isRequired: required,
        choices: Config.sueldos || [],
      },
      {
        type: "text",
        name: "dependence_number",
        title: {
          "es-HN": "N. dependientes",
          en: "Number of Dependents ",
        },
        isRequired: required,
        inputType: "number",
        validators: [
          {
            type: "numeric",
            minValue: 1,
            text: "Responda con mínimo 1",
          },
          {
            type: "numeric",
            maxValue: 99,
            text: "Responda con máximo 99",
          },
        ],
      },
      {
        title: {
          en: "Mobile phone number",
          "es-HN": "No. Celular",
        },
        type: "phone",
        name: "phone_number",
        isRequired: required,
        validators: [
          expressions.phone_en("phone_number"),
          expressions.phone_length("phone_number"),
        ],
      },
      {
        type: "comment",
        name: "custom_textbox_1",
        title: {
          "es-HN": "Dirección exacta del domicilio",
          en: "Exact address of residence ",
        },
        isRequired: required,
        maxLength: 500,
      },
      {
        name: "home_city",
        title: {
          en: "City ",
          "es-HN": "Ciudad",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.ciudades || [],
        isRequired: required,
      },
      {
        name: "custom_text_5",
        title: {
          en: "Department ",
          "es-HN": "Departmento",
        },
        description: {
          en: "You can search the name",
          "es-HN": "Puedes buscar el nombre",
        },
        type: "dropdown",
        renderAs: "select2",
        choices: Config.departamento || [],
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a photo of the front of your ID",
          "es-HN": "Por favor subir foto del frente de la identidad",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a photo of the back of your ID",
          "es-HN": "Por favor subir foto del reverso de la identidad",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_2",
        isRequired: required,
      },
      {
        name: "custom_jsonfield_3",
        valueName: "custom_jsonfield_3",
        type: "matrixdynamic",
        minRowCount: 3,
        maxRowCount: 4,
        rowLayout: "vertical",
        rowCount: 3,
        addRowLocation: "bottom",
        isRequired: required,
        validators: [
          {
            type: "expression",
            text: {
              en: "Please make sure to input different phone numbers for all members.",
              "es-HN":
                "Asegúrese de ingresar números de teléfono diferentes para todos los integrantes.",
            },
            expression: `differentPhoneNumbers({custom_jsonfield_3}, 'telefono') == true`,
          },
          {
            type: "expression",
            text: {
              en: "Please make sure none of the numbers are the same as yours.",
              "es-HN":
                "Asegúrese de que ningún número sea igual al suyo.",
            },
            expression: `samePhoneNumbersMatrix({custom_jsonfield_3}, {phone_number}, 'telefono') == true`,
          },
        ],
        addRowText: { en: "Add", "es-HN": "Añadir" },
        title: {
          en: "Personal references",
          "es-HN": "Referencias personales",
        },
        columns: [
          {
            title: { en: "Name", "es-HN": "Nombre" },
            name: "nombre",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {en: "Relationship or kinship", "es-HN": "Relación o parentesco" },
            name: "relacion",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Address", "es-HN": "Domicilio" },
            name: "domicilio",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Telephone", "es-HN": "Teléfono" },
            name: "telefono",
            isRequired: required,
            cellType: "phone",
            validators: [
              expressions.phone_en("telefono"),
              expressions.phone_length("telefono"),
            ],
          },
        ],
      },
      {
        type: "signaturepad",
        name: "file_1",
        title: {
          en: "Applicant's digital signature",
          "es-HN": "Firma digital del solicitante",
        },
        isRequired: required,
      },
    ],
  },
];
