export function isMobile() {
  // Check if device is mobile or ios
  return /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent)
}

export function isAndroid() {
  // Check if device is mobile or ios
  return /Android/i.test(window.navigator.userAgent)
}

export function isIOS() {
  // Check if device is mobile or ios
  return /iPhone|iPad|iPod/i.test(window.navigator.userAgent)
}

export function platform() {
  let platform = '';

  if (/Android/i.test(window.navigator.userAgent)) {
    platform = 'android';
  } else if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    platform = 'ios'
  } else {
    platform = 'desktop'
  }
  // Check if device is mobile or ios
  return platform;
}

export function URLToArray(url) {
  let request = {};
  let pairs = url.substring(url.indexOf('?') + 1).split('&');
  for (let i = 0; i < pairs.length; i++) {
    if (!pairs[i])
      continue;
    let pair = pairs[i].split('=');
    if (pair[1]) {
      request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }
  return request;
}

export function debounce(func, wait, immediate) {
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  let timeout;
  return function () {
    let context = this, args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function calcDistance(lat1, lon1, lat2, lon2) {
  let dLat = _deg2rad(lat2 - lat1);  // _deg2rad below
  let dLon = _deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(_deg2rad(lat1)) * Math.cos(_deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  ;
  // distance * radius Earth in meters
  return (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))) * 6371000
}

function _deg2rad(deg) {
  return deg * (Math.PI / 180)
}


export function openInWhatsapp(number, msg) {
  // note: number must be international format with +
  let url = `whatsapp://send?phone=${number}&text=${msg}`;
  if (isMobile()) {
    let now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 1200) return;
      window.open(`https://api.whatsapp.com/send?phone=${number}&text=${msg}`, '_blank');
    }, 1100);
    window.location = url;
  } else {
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=${msg}`, '_blank');
  }

}