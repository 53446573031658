import { SurveyComponent } from "../../../../components/survey/survey_component";
import { expressions } from "../../../../survey/validators";

export const references_eduloan = new SurveyComponent({
  title: {
    en: "Employer Deduction Form",
  },
  description:
    "Please upload your Employer Deduction Form.", 
  name: "home",
  elements: [
    {
      title: {
        en: "Please upload your Employer Deduction Form *",
      },
      name: "employer_deduction_form",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: true,
    },
    {
      title: {
        en: "",
      },
      type:"html",
      html: "<h4 class='sv_page_title'>Next of kin</h4><br/>Please add contact details of at least one person who is willing to be your next of kin. This person might be contacted for confirmation.",
    },
    {
      title: {
        en: "Next of kin #1",
      },
      name: "panel_ref1",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "ref1_name", // Changed text to next of kin, kept name for mapping the same
          type: "text",
          isRequired: false,
          title: {
            en: "Full name",
          },
        },
        {
          name: "ref1_phone",
          type: "phone",
          visibleIf: "{ref1_name} notempty",
          isRequired: false,
          title: {
            en: "Home telephone number",
          },
          validators: [
            expressions.phone_en("ref1_phone"),
            expressions.phone_length("ref1_phone"),
          ],
        },
        {
          name: "ref1_business_phone",
          type: "phone",
          visibleIf: "{ref1_name} notempty",
          title: {
            en: "Business telephone number",
          },
          validators: [
            expressions.phone_en("ref1_business_phone"),
            expressions.phone_length("ref1_business_phone"),
          ],
        },
        {
          title: { en: "Residential Address" },
          name: "ref1_street",
          type: "text",
          visibleIf: "{ref1_name} notempty",
        },
        {
          name: "ref1_mobile_phone",
          type: "phone",
          visibleIf: "{ref1_name} notempty",
          title: {
            en: "Mobile number",
          },

          validators: [
            expressions.phone_en("ref1_mobile_phone"),
            expressions.phone_length("ref1_mobile_phone"),
          ],
        },
        {
          title: { en: "Email Address" },
          name: "ref1_email",
          type: "text",
          validators: [{ type: "email" }],
          visibleIf: "{ref1_name} notempty",
        },
      ],
    },
    {
      title: {
        en: "Next of kin #2 (optional)",
      },
      name: "panel_ref2",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "ref2_name",
          type: "text",
          isRequired: false,
          title: {
            en: "Full name",
          },
        },
        {
          name: "ref2_phone",
          type: "phone",
          visibleIf: "{ref2_name} notempty",
          isRequired: false,
          title: {
            en: "Home telephone number",
          },
          validators: [
            expressions.phone_en("ref2_phone"),
            expressions.phone_length("ref2_phone"),
          ],
        },
        {
          name: "ref2_business_phone",
          type: "phone",
          visibleIf: "{ref2_name} notempty",
          title: {
            en: "Business telephone number",
          },
          validators: [
            expressions.phone_en("ref2_business_phone"),
            expressions.phone_length("ref2_business_phone"),
          ],
        },
        {
          title: { en: "Residential Address" },
          name: "ref2_street",
          type: "text",
          visibleIf: "{ref2_name} notempty",
        },
        {
          name: "ref2_mobile_phone",
          type: "phone",
          visibleIf: "{ref2_name} notempty",
          title: {
            en: "Mobile number",
          },
          validators: [
            expressions.phone_en("ref2_mobile_phone"),
            expressions.phone_length("ref2_mobile_phone"),
          ],
        },
        {
          title: { en: "Email Address" },
          name: "ref2_email",
          type: "text",
          visibleIf: "{ref2_name} notempty",
        },
      ],
    },
  ],
});
