import env from "../../env";
const required = env.ENDPOINT !== "local";

export const guarantor_procredito = (clientUrlForCatalog) => ({
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicación",
  },

  questionStartIndex: 1,
  requiredText: "",
  pages: [
    {
      name: "guarantor_intake",
      title: {
        en: "Guarantor acceptance",
        "es-MX": "Aceptación del obligado solidario",
      },
      elements: [
        {
          type: "html",
          html: {
            en: `Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.`,
            "es-MX": `Selecciona aceptas o no. Si tiene alguna pregunta, por favor contáctenos.`,
          },
        },
        {
          type: "radiogroup",
          title: {
            en: "Guarantor acceptance",
            "es-MX": "Aceptación del obligado solidario",
          },
          name: "guarantor_refused",
          isRequired: required,
          choices: [
            {
              value: false,
              text: { en: "I accept", "es-MX": "Acepto" },
            },
            {
              value: true,
              text: { en: "I don't accept", "es-MX": "No acepto" },
            },
          ],
        },
      ],
    },
    {
      title: { en: "Guarantor information", "es-MX": "Obligado solidario" },
      innerIndent: 1,
      elements: [
        {
          title: { en: "First name*", "es-MX": "Primer nombre*" },
          type: "text",
          name: "guarantor_first_name",
          isRequired: required,
        },
        {
          title: { en: "Second name", "es-MX": "Segundo nombre" },
          type: "text",
          name: "guarantor_middle_name",
        },
        {
          title: { en: "Surname*", "es-MX": "Primer apellido*" },
          type: "text",
          name: "guarantor_last_name",
          isRequired: required,
        },
        {
          title: { en: "Second surname*", "es-MX": "Segundo apellido" },
          type: "text",
          name: "guarantor_mother_name",
        },
        {
          title: { en: "Date of birth", "es-MX": "Fecha de nacimiento" },
          type: "text",
          name: "date_of_birth",
          inputType: "date",
          validators: [
            {
              type: "expression",
              text: {
                en: "You must be older than 18 to apply",
                "es-MX": "Debes tener más de 18 años para postularte.",
              },
              expression: "age({date_of_birth}) > 18",
            },
          ],
        },
        {
          title: { en: "Sex", "es-MX": "Género" },
          type: "radiogroup",
          name: "gender",
          choices: [
            { value: "Hombre", text: { en: "male", "es-MX": "Hombre" } },
            { value: "Mujer", text: { en: "female", "es-MX": "Mujer" } },
          ],
        },
        {
          type: "panel",
          name: "INE_panel",
          title: {
            en: "Which type of INE do you have? Select one *",
            "es-MX": "¿Qué tipo de INE tienes? Elige un opción *",
          },
          elements: [
            {
              name: "id_avatar_desc_picture",
              type: "html",
              html: `<h3>1.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_1.png")}' width='95%'/>`,
            },
            {
              name: "id_avatar_desc_picture",
              type: "html",
              html: `<h3>2.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_2.png")}' width='95%'/>`,
            },
            {
              name: "id_avatar_desc_picture",
              type: "html",
              html: `<h3>3.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_3.png")}' width='95%'/>`,
            },
            {
              title: { en: "INE Type", "es-MX": "Tipo de INE" },
              type: "dropdown",
              isRequired: required,
              name: "custom_text_71",
              choices: [
                { value: "1", text: { en: "1.", "es-MX": "1." } },
                { value: "2", text: { en: "2.", "es-MX": "2." } },
                { value: "3", text: { en: "3.", "es-MX": "3." } },
              ],
            },
            {
              type: "panel",
              name: "INE_1_panel",
              visibleIf: "{custom_text_71} == '1'",
              elements: [
                {
                  name: "id_number",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "CIC (9 digitos)",
                    "es-MX": "CIC (9 digitos)",
                  },
                  maxLength: "9",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "9 digits",
                        "es-MX": "9 digitos",
                      },
                      regex: `^[0-9]{9}$`,
                    },
                  ],
                },
                {
                  name: "custom_text_72",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "Identificador del ciudadano (9 digitos)",
                    "es-MX": "Identificador del ciudadano (9 digitos)",
                  },
                  maxLength: "9",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "9 digits",
                        "es-MX": "9 digitos",
                      },
                      regex: `^[0-9]{9}$`,
                    },
                  ],
                },
              ],
            },
            {
              type: "panel",
              name: "INE_2_panel",
              visibleIf: "{custom_text_71} == '2'",
              elements: [
                {
                  name: "id_number",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "CIC (9 digitos)",
                    "es-MX": "CIC (9 digitos)",
                  },
                  maxLength: "9",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "9 digits",
                        "es-MX": "9 digitos",
                      },
                      regex: `^[0-9]{9}$`,
                    },
                  ],
                },
                {
                  name: "custom_text_72",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "OCR (13 digitos)",
                    "es-MX": "OCR (13 digitos)",
                  },
                  maxLength: "13",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "13 digits",
                        "es-MX": "13 digitos",
                      },
                      regex: `^[0-9]{13}$`,
                    },
                  ],
                },
              ],
            },
            {
              type: "panel",
              name: "INE_3_panel",
              visibleIf: "{custom_text_71} == '3'",
              elements: [
                {
                  name: "id_number",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "Clave de elector (18 digitos)",
                    "es-MX": "Clave de elector (18 digitos)",
                  },
                  maxLength: "18",
                  placeHolder: "GAVLLR68082309A123",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "18 digits. Incorrect format",
                        "es-MX": "18 digitos. Formato incorrecto",
                      },
                      regex: `^[a-zA-Z]{6}[0-9]{8}[a-zA-Z]{1}[0-9]{3}$`,
                    },
                  ],
                },
                {
                  name: "custom_text_72",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "Número OCR (13 digitos)",
                    "es-MX": "Número Vertical (OCR) (13 digitos)",
                  },
                  maxLength: "13",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "13 digits",
                        "es-MX": "13 digitos",
                      },
                      regex: `^[0-9]{13}$`,
                    },
                  ],
                },
                {
                  name: "custom_text_73",
                  type: "text",
                  isRequired: required,
                  title: {
                    en: "Número de Emisión (2 digitos)",
                    "es-MX": "Número de Emisión (2 digitos)",
                  },
                  maxLength: "2",
                  validators: [
                    {
                      type: "regexvalidator",
                      text: {
                        en: "2 digits",
                        "es-MX": "2 digitos",
                      },
                      regex: `^[0-9]{2}$`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: { en: "Do you have RFC?", "es-MX": "¿Cuentas con RFC?" },
          type: "radiogroup",
          isRequired: required,
          name: "custom_text_2",
          choices: [
            { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
            { value: "no", text: { en: "No", "es-MX": "No" } },
          ],
        },
        {
          title: {
            en: "In the following link you can generate your RFC number",
            "es-MX": "En el siguiente enlace podrás generar tu número de RFC",
          },
          html: {
            en: `<div>On the following link you can generate your RFC <br> <a href='https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp' target=_blank'>https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp</a></div>`,
            "es-MX": `<div>En el siguiente enlace podrás generar tu número de RFC <br> <a href='https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp' target=_blank'>https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp</a></div>`,
          },
          type: "html",
          visibleIf: "{custom_text_2} == 'no'",
        },
        {
          title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
          type: "text",
          name: "custom_text_24",
          maxLength: "13",
          //visibleIf: "{custom_text_2} == 'si'", Always visible
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct Tax Id Number (RFC) of 10 characters or 13 with homoclave",
                "es-MX": "RFC 10 dígitos o 13 dígitos incluyendo homoclave",
              }, // Requires translation
              regex: `^([A-Za-z]{4}[ |-]{0,1}[0-9]{6})([0-9A-Za-z]{3}|$)$`, // should be the correct regex that includes 3 more chars at the end
            },
          ],
          placeHolder: "VECJ880326",
        },
        {
          title: { en: "Do you have Curp", "es-MX": "¿Cuentas con CURP?" },
          type: "radiogroup",
          isRequired: required,
          name: "custom_text_3",
          choices: [
            { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
            { value: "no", text: { en: "No", "es-MX": "No" } },
          ],
        },
        {
          title: {
            en: "In the following link you can generate your CURP number",
            "es-MX": "En el siguiente enlace podrás generar tu número de CURP",
          },
          html: {
            en: `<div>On the following link you can generate your CURP number <br> <a href='https://www.gob.mx/curp/' target='_blank'>https://www.gob.mx/curp/</a></div>`,
            "es-MX": `<div>En el siguiente enlace podrás generar tu número de CURP <br> <a href='https://www.gob.mx/curp/' target='_blank'>https://www.gob.mx/curp/</a></div>`,
          },
          type: "html",
          visibleIf: "{custom_text_3} == no",
        },
        {
          title: {
            en: "Unique Population Registry Code (CURP)",
            "es-MX": "CURP",
          },
          type: "text",
          name: "custom_text_4",
          maxLength: "18",
          //visibleIf: "{custom_text_3} == 'si'", always visible
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct CURP of 18 characters",
                "es-MX": "CURP 18 characters",
              }, // Requires translation
              regex: `^[a-zA-Z0-9]{18}$`, // should be the correct regex
            },
          ],
          placeHolder: "HEGG560427MVZRRL04",
        },
        {
          title: { en: "Kind of person", "es-MX": "Tipo de persona" },
          type: "dropdown",
          name: "custom_text_5",
          choices: [
            {
              value: "Persona Física",
              text: { en: "Persona Física", "es-MX": "Persona Física" },
            },
            {
              value: "Persona Física con Actividad Empresarial",
              text: {
                en: "Persona Física con Actividad Empresarial",
                "es-MX": "Persona Física con Actividad Empresarial",
              },
            },
            {
              value: "Persona Moral",
              text: { en: "Persona Moral", "es-MX": "Persona Moral" },
            },
          ],
        },
        {
          title: {
            en: "Relationship with the client",
            "es-MX": "Parentesco con el cliente ",
          },
          type: "text",
          name: "custom_text_6",
        },
        {
          title: { en: "Marital status", "es-MX": "Estado Civil" },
          type: "dropdown",
          name: "marital",
          choices: [
            { value: "soltero", text: { en: "Single", "es-MX": "Soltero" } },
            { value: "casado", text: { en: "Married", "es-MX": "Casado" } },
          ],
        },
        {
          title: { en: "Email*", "es-MX": "Correo electrónico*" },
          type: "text",
          name: "email",
          isRequired: required,
          validators: [{ type: "email" }],
        },
        {
          title: { en: "Nationality", "es-MX": "Nacionalidad *" },
          type: "text",
          name: "nationality",
          isRequired: required,
        },
        {
          title: { en: "Country *", "es-MX": "País *" },
          type: "dropdown",
          renderAs: "select2",
          name: "home_country",
          isRequired: required,
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
            path: "results",
            titleName: "description",
            valueName: "id",
          },
        },
        {
          title: { en: "State *", "es-MX": "Estado *" },
          type: "dropdown",
          renderAs: "select2",
          isRequired: required,
          name: "custom_text_7",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={home_country}`,
            path: "results",
            titleName: "description",
            valueName: "id",
          },
        },
        {
          title: { en: "Municipality *", "es-MX": "Municipio" },
          type: "dropdown",
          renderAs: "select2",
          name: "custom_text_8",
          isRequired: required,
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_7}`,
            path: "results",
            titleName: "description",
            valueName: "id",
          },
        },
        {
          title: { en: "City/Delegation *", "es-MX": "Ciudad/Localidad" },
          type: "dropdown",
          renderAs: "select2",
          name: "home_city",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_8}`,
            path: "results",
            titleName: "description",
            valueName: "id",
          },
        },
        {
          title: { en: "Postal Code *", "es-MX": "Código postal *" },
          type: "dropdown",
          renderAs: "select2",
          name: "custom_text_30",
          isRequired: required,
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_8}`,
            path: "results",
            valueName: "zipcode",
          },
        },
        {
          title: { en: "Suburb *", "es-MX": "Colonia *" },
          type: "dropdown",
          renderAs: "select2",
          name: "custom_text_31",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={custom_text_30}`,
            path: "results",
            titleName: "description",
            valueName: "id",
          },
        },
        {
          title: { en: "street", "es-MX": "Calle *" },
          type: "text",
          name: "custom_text_32",
          isRequired: required,
        },
        {
          title: { en: "Outdoor Number", "es-MX": "Número exterior" },
          type: "text",
          name: "custom_text_33",
        },
        {
          title: { en: "Interior Number", "es-MX": "Número interior" },
          type: "text",
          name: "custom_text_34",
        },
        {
          title: {
            en: "Work or have a business",
            "es-MX": "¿Trabaja o tiene algún negocio?",
          },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_9",
          choices: [
            { value: "sí", text: { en: "Yes", "es-MX": "Sí" } },
            { value: "no", text: { en: "No", "es-MX": "No" } },
          ],
        },
        {
          name: "work_business_panel",
          type: "panel",
          innerIndent: 1,
          visibleIf: "{custom_text_9} == 'sí'",
          elements: [
            {
              title: { en: "Country *", "es-MX": "País *" },
              type: "dropdown",
              renderAs: "select2",
              name: "custom_text_10",
              isRequired: required,
              choicesByUrl: {
                url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
                path: "results",
                titleName: "description",
                valueName: "id",
              },
            },
            {
              title: { en: "State *", "es-MX": "Estado *" },
              type: "dropdown",
              renderAs: "select2",
              isRequired: required,
              name: "custom_text_12",
              choicesByUrl: {
                url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={custom_text_10}`,
                path: "results",
                titleName: "description",
                valueName: "id",
              },
            },
            {
              title: { en: "Municipality *", "es-MX": "Municipio" },
              type: "dropdown",
              renderAs: "select2",
              name: "custom_text_13",
              isRequired:required,
              choicesByUrl: {
                url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_12}`,
                path: "results",
                titleName: "description",
                valueName: "id",
              },
            },
            {
              title: { en: "City/Delegation *", "es-MX": "Ciudad/Localidad" },
              type: "dropdown",
              renderAs: "select2",
              name: "custom_text_25",
              choicesByUrl: {
                url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_13}`,
                path: "results",
                titleName: "description",
                valueName: "id",
              },
            },
            {
              title: { en: "Postal Code *", "es-MX": "Código postal *" },
              type: "dropdown",
              renderAs: "select2",
              name: "custom_text_11",
              isRequired: required,
              choicesByUrl: {
                url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_13}`,
                path: "results",
                valueName: "zipcode",
              },
            },
            {
              title: { en: "Suburb *", "es-MX": "Colonia *" },
              type: "dropdown",
              renderAs: "select2",
              name: "custom_text_14",
              choicesByUrl: {
                url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={custom_text_11}`,
                path: "results",
                titleName: "description",
                valueName: "id",
              },
            },
            {
              title: { en: "Street", "es-MX": "Calle" },
              type: "text",
              name: "custom_text_15",
            },
            {
              title: { en: "Outdoor Number", "es-MX": "Número exterior" },
              type: "text",
              name: "custom_text_16",
            },
            {
              title: { en: "Interior number", "es-MX": "Número Interior" },
              type: "text",
              name: "custom_text_17",
            },
            {
              title: { en: "Type of address", "es-MX": "Tipo de domicilio" },
              type: "text",
              name: "custom_text_18",
            },
            {
              title: {
                en: "Years of residence",
                "es-MX": "Años de residencia ",
              },
              type: "text",
              name: "custom_text_19",
            },
            {
              title: {
                en: "Telephone number of the address",
                "es-MX": "Número telefónico del domicilio ",
              },
              type: "text",
              name: "custom_text_20",
            },
          ],
        },
        {
          title: {
            en: "Has held any public office in the last 12 months",
            "es-MX":
              "¿Ha desempeñado algún cargo público en los últimos 12 meses?",
          },
          type: "dropdown",
          name: "custom_text_21",
          choices: [
            { value: "sí", text: { en: "Yes", "es-MX": "Sí" } },
            { value: "no", text: { en: "No", "es-MX": "No" } },
          ],
        },
        {
          name: "public_office_panel",
          type: "panel",
          innerIndent: 1,
          visibleIf: "{custom_text_21} == 'sí'",
          elements: [
            {
              title: { en: "Job title", "es-MX": "Nombre del cargo" },
              type: "text",
              name: "custom_text_22",
            },
            {
              title: { en: "Dependence", "es-MX": "Dependencia" },
              type: "text",
              name: "custom_text_23",
            },
          ],
        },
      ],
    },
    {
      title: {
        en: "Guarantor agreement",
        "es-MX": "Acuerdo del obligado solidario",
      },
      innerIndent: 1,
      elements: [
        {
          html: {
            en: `<div>`,
            "es-MX": `<div style="text-align: justify;">Por este medio autorizo expresamente a PRO CONFIANZA S.A. DE C.V. SOFOM E.N. R., para que a partir de este momento sea tratado como cliente, a efecto de que 
                por conducto de sus funcionarios facultados lleve a cabo Investigaciones sobre mi comportamiento crediticio o el de la Empresa que represento, en Tras Union de México 
                S.A. SIC. y/o Dun & Bradstreet, S.A. SIC. <br><br>
    
                Así mismo, declaro que conozco la naturaleza y alcance de las sociedades de información crediticia y de la información contenida en los reportes de crédito y 
                reporte de crédito especial; declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que PRO CONFIANZA S.A. DE C.V. SOFOM E.N.R. 
                hará de tal información y de que ésta podrá realizar consultas periódicas sobre mi historial crediticio o el de la empresa que represento, consintiendo que esta 
                autorización se encuentre vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso, durante el tiempo que se mantenga la relación jurídica.<br><br>
    
                En caso de que el solicitante sea una Persona Moral, declaro bajo protesta de decir verdad ser Representante Legal de la Empresa mencionada en esta autorización; 
                manifiesto que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados, ni modificados en forma alguna.<br><br>
                
                Estoy consciente y acepto que este documento quede bajo custodia de PRO CONFIANZA S.A. DE C.V. SOFOM E.N.R. y/o Sociedad de Información Crediticia consultada para 
                efectos de control y cumplimiento del artículo 28 de la Ley para Regular las Sociedades de Información Crediticia; mismo que señala que las Sociedades sólo podrán 
                proporcionar información a un Usuario, cuando éste cuente con la autorización expresa del Cliente mediante su firma autógrafa.<br><br>
              </div>
                `,
          },
          type: "html",
          name: "custom_text_80",
        },
        {
          html: {
            en: `<div>`,
            "es-MX": `<strong>Autorización para Solicitar Reportes de Crédito</strong><br><br>
                <br><strong>Personas Físicas / Personas Morales. </strong><br>
                <br>Nombre del solicitante (Persona Física o Razón Social de la Persona Moral): {guarantor_first_name} {guarantor_middle_name} {guarantor_last_name} {guarantor_mother_name}<br>
                <br>Para el caso de Persona Moral, nombre del Representante Legal: <br>
                  <br>RFC o CURP: {custom_text_24} , {custom_text_4}<br>
                  <br><strong>Domicilio</strong>
                  <br>Nacionalidad: {nationality}
                  <br>Calle: {custom_text_32}, {custom_text_33}, {custom_text_34}
                  <br>Colonia: {custom_text_31}
                  <br>Municipio:{custom_text_8}
                  <br>Estado: {custom_text_7}
                  <br>Codigo Postal: {custom_text_30}
                  <br>Teléfono(s): {guarantor_phone}
                `,
          },
          type: "html",
          name: "custom_text_81",
        },
        {
          type: "expression",
          title: {
            en: "Sign date",
            "es-MX": "Fecha en que se firma la autorización:",
          },
          name: "custom_text_74",
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          type: "checkbox",
          title: " ",
          name: "custom_jsonfield_7",
          isRequired: true,
          choices: [
            {
              value: "acepto",
              text: {
                en: "I agree",
                "es-MX": "Acepto",
              },
            },
          ],
          validators: [
            {
              type: "expression",
              text: {
                en: "You should accept before continuing",
                "es-MX": "Debes aceptar antes de continuar",
              },
              expression: "{custom_jsonfield_7} contains 'acepto'",
            },
          ],
        },
      ],
    },
  ],
});
