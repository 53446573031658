import { SurveyComponent } from '../../../../components/survey/survey_component';
import { expressions } from '../../../../survey/validators';
import env from "../../../../env";
const required = env.ENDPOINT !== 'local';

// Rename to cooperative?
export const guarantor_bll = new SurveyComponent({
  title: {
    en: 'Information related to your Guarantor',
    rn: "Amakuru ajyanye n'umwishingizi wawe",
  },
  name: 'home',
  elements: [
    {
      type: "dropdown",
      name: "collateral_arrangements",
      title: {
        en: "Who is your Guarantor?",
        rn: "Umwishingizi wanyu ninde?",
        "fr-BI": "Qui est votre garant ?"
      },
      isRequired: required,
      choices: [
        {
          value: "cooperative",
          text: {
            en: "Cooperative",
            "fr-BI": "Coopérative",
            rn: "Koperative"
          }
        },
        {
          value: "individual",
          text: {
            en: "Individual",
            "fr-BI": "Individuel",
            rn: "Umuntu ku giti ciwe"
          }
        }
      ],
    },
    {
      type: "dropdown",
      name: "custom_text_31",
      visibleIf: "{collateral_arrangements} = 'individual'",
      storeOthersAsComment: false,
      title: {
        en: "What is the nature of Warranty ?",
        "fr-BI": "Choisissez la nature de la garantie",
        rn: "Hitamwo ubwoko bw'ingwatie"
      },
      choices: [
        {
          value: "terrain_nu",
          text: {
            en: "Bare ground",
            "fr-BI": "Terrain nu",
            rn: "Ikibanza kigaragara"
          }
        },
        {
          value: "parcelle_batie",
          text: {
            en: "Built plot",
            "fr-BI": "Parcelle Bâtie",
            rn: "Ikibanza cubatse"
          }
        },
        {
          value: "maison",
          text: {
            en: "House",
            "fr-BI": "Maison",
            rn: "Inzu"
          }
        },
        {
          value: "autre",
          text: {
            en: "Other",
            "fr-BI": "Autre",
            rn: "Ikindi"
          },
        },
      ],
    },
    {
      type: "text",
      name: "custom_text_50",
      visibleIf: "{custom_text_31} = 'autre'",
      title: {
        en: "Specify",
        "fr-BI": "Precisér",
        rn: "Erekana"
      },
      isRequired: required
    },
    {
      type: "text",
      name: "custom_text_32",
      visibleIf: "{collateral_arrangements} = 'individual'",
      title: {
        en: "Write the full name of the owner ?",
        "fr-BI": "Quel est le nom et prénom du titulaire de la garantie ?",
        rn: "Vuga izina rya nyene ingwati"
      },
      isRequired: required
    },
    {
      type: "panel",
      name: "guarantor_location_panel",
      visibleIf: "{collateral_arrangements} = 'individual'",
      title: {
        en: "Where is your warranty located ?",
        "fr-BI": "Où se situe votre garantie ?",
        rn: "Ingwati yanyu itumbereye hehe?"
      },
      elements: [
        {
          type: "text",
          name: "custom_text_33",
          title: {
            en: "Province",
            "fr-BI": "Province",
            rn: "Provensi"
            
          }
        },
        {
          type: "text",
          name: "custom_text_34",
          title: {
            en: "Municipality",
            "fr-BI": "Commune",
            rn: "Komine"
          }
        },
        {
          type: "text",
          name: "custom_text_35",
          title: {
            en: "Zone",
            "fr-BI": "Zone",
            rn: "Zone"
          }
        },
        {
          type: "text",
          name: "custom_text_36",
          title: {
            en: "Hill",
            "fr-BI": "Colline",
            rn: "Umutumba"
          }
        }
      ]
    },
    {
      type: "file_uploader",
      accept: "image/*,application/pdf",
      name: "picture_7",
      visibleIf: "{collateral_arrangements} = 'individual'",
      title: {
        en: "Upload the land title of your warranty",
        "fr-BI": "Téléchargez le titre de propriété de votre garantie",
        rn: "Erekana impapuro ndangatongo z'ingwati yanyu"
      },
    },
    {
      type: "text",
      inputType: "number",
      name: "custom_text_49",
      visibleIf: "{collateral_arrangements} = 'individual'",
      title: {
        en: "The value of the guarantee in BIF",
        "fr-BI": "La valeur de la garantie",
        rn: "Agaciro k'ingwate mu BIF"
      },
      isRequired: required
    },
    {
      type: "dropdown",
      name: "custom_text_37",
      visibleIf: "{collateral_arrangements} = 'cooperative'",
      title: {
        en: "Name of cooperative",
        "fr-BI": "Nom de la coopérative",
        rn: "Izina rya Koperative"
      },
      isRequired: required,
      choices: [
        {
          value: "COJAD",
          text: { en: "COJAD", "fr-BI": "COJAD",rn: "COJAD"},
        },
        {
          value: "TUGWIZUBUKI",
          text: { en: "TUGWIZUBUKI", "fr-BI": "TUGWIZUBUKI",rn: "TUGWIZUBUKI"},
        },
        {
          value: "TURASHOBOYE",
          text: { en: "TURASHOBOYE", "fr-BI": "TURASHOBOYE",rn: "TURASHOBOYE"},
        },
        {
          value: "RANGWAHOSE RUYUKI",
          text: { en: "RANGWAHOSE RUYUKI", "fr-BI": "RANGWAHOSE RUYUKI",rn: "RANGWAHOSE RUYUKI"},
        },
        {
          value: "COOPACI",
          text: { en: "COOPACI", "fr-BI": "COOPACI",rn: "COOPACI"},
        },
      ],
    },
    {
      type: "text",
      name: "guarantor_first_name",
      visibleIf: "{collateral_arrangements} = 'cooperative'",
      title: {
        en: "Name of president of the cooperative",
        "fr-BI": "Nom complet du président de la coopérative",
        rn: "Amazina yose y'uwurongoye Koperative"
      },
      isRequired: required
    },
    {
      type: "phone",
      name: "guarantor_phone",
      visibleIf: "{collateral_arrangements} = 'cooperative'",
      title: {
        en: "Telephone number of president of the cooperative",
        "fr-BI": "Numéro de téléphone du président de la coopérative",
        rn: "Inimero ya terefone y'Uwurongoye Koperative"
      },
      isRequired: required,
      validators: [
        expressions.same_phone_number('guarantor_phone'),
      ],
    },
    {
      type: "text",
      name: "guarantor_2_first_name",
      visibleIf: "{collateral_arrangements} = 'cooperative'",
      title: {
        en: "Full name of vice president of the cooperative",
        "fr-BI": "Nom complet du vice président de la coopérative",
        rn: "Amazina yose y'icegera c'uwurongoye Koperative"
      },
      isRequired: required
    },
    {
      type: "phone",
      name: "guarantor_2_phone",
      visibleIf: "{collateral_arrangements} = 'cooperative'",
      title: {
        "fr-BI": "Numéro de téléphone du vice président de la coopérative",
        en: "Telephone number of vice president of the cooperative",
        rn: "Inimero ya terefone y'icegera c'uwurongoye Koperative"
      },
      isRequired: required,
      validators: [
        expressions.same_phone_number('guarantor_2_phone'),
      ],
    },
  ],
});
