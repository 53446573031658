export const DIR_RIGHT_TO_LEFT = 'rtl';
export const DIR_LEFT_TO_RIGHT = 'ltr';

export const text_direction = (locale) => (locale === 'ar') ? DIR_RIGHT_TO_LEFT : DIR_LEFT_TO_RIGHT;


export const p = (v) => `<p style="margin-top:16px;">${v}</p>`; // Normal paragraph for english text
export const rtl = (v) => `<p style="margin-top:16px;" dir="rtl">${v}</p>`; // Right to left (rtl) text for arabic

// Educational Review
export const en_3rd_agreement_texts = [ //also used for guarantor
  'Administrative Fees will be calculated and added to the Loan Amount.',
  'Stamps and Taxes will be calculated and paid by the applicant on the first scheduled installment.',
  'Administrative Fees, stamps, and taxes will be listed in the Contract which should be signed from your side accordingly.',
];

export const ar_agree4 = [
'تقدّم شركة صندوق المرأة  للتمويل الأصغر برنامج حماية  وهو أحد البرامج التأمينية الميكروية المقدمة لمستفيدي الشركة والذي يتم من خلاله تسديد قيمة التمويل المتبقي لصندوق المرأة للتمويل الأصغر مع المصاريف الإدارية وكافة العمولات والغرامات المتبقية على المستفيد/ة في حالة وفاة المستفيد/ة، بحيث لا يكون عمر المستفيد/ة قد تجاوز 65 عاماً. قيمة القسط الشهري ثلاثون قرشاً شهرياً تسدّد مع القسط الشهري للتمويل.'
];

export const en_agree4 = [
  "The Women Microfinance Fund Company offers a Hemaya program, which is one of the micro-insurance programs provided to the beneficiaries of the company through which the remaining funding of the Women’s Microfinance Fund is paid with administrative expenses and all commissions and fines remaining on the beneficiary in the event of the death of the beneficiary, so that it is not The age of the beneficiary has exceeded 65 years. The value of the monthly installment is thirty piasters per month, to be paid with the monthly financing installment. ",
];


export const insurance_html = `<div dir='rtl' style="margin-right: 20px;">
<ol>
      <li><h5>برنامج عافيتنا</h5></li>
      <li><h5>برنامج حماية.</h5></li>
</ol>
<p>1. برنامج عافيتنا: هو برنامج تأمين ميكروي لمستفيدي/ات شركة صندوق المرأة وعائلاتهم/ن والأبناء من عمر (شهر الى 21 عاما ) المدرجين ضمن التمويلات الرئيسية بحيث يستفاد من البرنامج في الحالات التالية:</p>
<p> الاستشفاء (دخول المستشفى): أي مكوث المستفيد/ة  أو زوجته/ا و/ أو أحد أبنائه/ا غير المتزوجين والبالغين من العمر شهر الى 21 عاماً في المستشفى، وقضاء ليلة واحدة على الأقل بسبب مرض أو حادث معين، تحت اشراف طبي في مستشفى مرخص له بالعمل قانونياً كمستشفى</p>
<p>مميزات الاستشفاء:</p>
<ul>
      <li>تغطية تأمينية بقيمة 15 دينارعن كل ليلة إقامة داخل المستشفى، وأن يكون المستشفى ضمن المستشفيات التابعة لوزارة الصحة الأردنية، ومستشفيات الخدمات الطبية الملكية.</li>
      <li>تغطية تصل إلى  24 ليلة للدخول المتواصل داخل المستشفى.</li>
      <li>تغطية تصل إلى 36 ليلة في السنة التأمينية الواحدة، ويتم احتساب الحد الأقصى للتغطية بالنسبة للتمويلات التي تزيد عن 12 شهراً باحتساب ثلاث ليال عن كل شهر من عمر التمويل، ولكل فرد مؤمن في حال وجود معالين، على أن تستحق المنفعة القصوى للمؤمن عليهم في أي وقت من سريان التأمين.</li>
</ul>
<p>ب.  في حالة وفاة العميل/ـة: تقوم شركة التأمين بتسديد الدين المتبقي للعميل/ـة المتوفاة  بالإضافة إلى المصاريف الإدارية والغرامات لشركة صندوق المرأة للتمويل الأصغر عند تاريخ الوفاة. كما ويتم اصدار شيك بقيمة 1600 دينار أردني للمستفيد/ة المدرج/ـة على نموذج المستفيدين.</p>
<p>ج. في حال وفاة زوج المستفيد أو زوجة المستفيد ( بحيث لا يتجاوز عمر الزوج/ـة 65 عاماً): يتم صرف مبلغ 750 دينار أردني للمستفيد/ة.</p>
<ul>
      <li>القسط الشهري: هو قيمة الاشتراك ببرنامج عافيتنا، ويتم دفع هذه القيمة شهريا مع كل دفعة من عمر التمويل.</li>
      <li>المستفيد/ة فقط: القسط الشهري= 1.50 دينار (لا يوجد أبناء معالين ضمن الفئة العمرية من عمر شهر إلى 21 عام، المستفيد/ة أعزب، عزباء أرمل/ة بدون أبناء، مطلق/ة بدون أبناء)</li>
      <li>المستفيد/ة مع معالين: القسط الشهري=2.50  دينار (وجود أبناء ضمن الفئة العمرية من عمر شهر إلى 21 عام مسجلين بدفتر العائلة "غير متزوجين").</li>
</ul>
<p>2. برنامج حماية: هو أحد البرامج التأمينية الميكروية المقدمة لمستفيدي/ات شركة صندوق المرأة  للتمويل الأصغر يتم من خلاله تسديد قيمة التمويل المتبقي لصندوق المرأة للتمويل الأصغر مع المصاريف الإدارية وكافة العمولات والغرامات المتبقية على المستفيد/ة في حالة وفاة المستفيد/ة، بحيث لا يكون عمر المستفيد/ة قد تجاوز 65 عاماً.</p>
<p>قيمة القسط الشهري ثلاثون قرشاً شهرياً تسدد مع القسط الشهري للتمويل.<p>
</div>`;


export const insurance_html_en = `<div dir='ltr' style="margin-right: 20px;">
<ol>
      <li> <h5> Our Wellness Program </h5> </li>
      <li> <h5> Protection software. </h5> </li>
</ol>
<p> 1. Afafauna Program: It is a micro-insurance program for the beneficiaries of the Women’s Fund Company and their families / children and children from the age of (one month to 21 years) who are included in the main funds, so that they can benefit from the program in the following cases: </ p>
<p> Hospitalization (hospitalization): means that the beneficiary, his wife, and / or one of his / her unmarried children, aged one month to 21 years old, stay in the hospital, and spend at least one night due to a specific illness or accident, under supervision A medical doctor in a hospital licensed to operate legally as a hospital </ p>
<p> Hospital benefits: </p>
<ul>
      <li> Insurance coverage of 15 dinars for each night's stay in the hospital, and that the hospital is within the hospitals of the Jordanian Ministry of Health and the Royal Medical Services hospitals. </li>
      <li> Coverage of up to 24 nights for continuous hospitalization. </li>
      <li> Coverage of up to 36 nights in one insurance year, and the maximum coverage for financing that exceeds 12 months is calculated by counting three nights for each month of the financing life, and for every insured individual in the event of dependents, provided that the maximum benefit of the insured is entitled At what time the insurance becomes effective. </li>
</ul>
<p> b. In the event of the death of the client: the insurance company pays the remaining debt to the deceased client in addition to the administrative expenses and fines of the Women’s Microfinance Fund Company at the date of death. In addition, a check in the amount of JD 1600 will be issued to the beneficiary who is listed on the beneficiaries' form. </p>
<p> c. In the event of the death of the beneficiary's spouse or the beneficiary's wife (provided that the husband's age does not exceed 65 years): an amount of 750 Jordanian dinars will be disbursed to the beneficiary. </p>
<ul>
      <li> Monthly installment: is the subscription fee for our Wellness Program, and this value is paid monthly with each installment of the financing life. </li>
      <li> The beneficiary only: the monthly installment = 1.50 dinars (there are no dependent children within the age group from one month to 21 years old, the beneficiary is single, single, widowed without children, divorced / without children) </li>
      <li> The beneficiary with dependents: the monthly installment = 2.50 dinars (the presence of children in the age group from one month to 21 years old registered in the family book "unmarried"). </li>
</ul>
<p> 2. Hemaya program: It is one of the micro-insurance programs offered to the beneficiaries of the Women’s Microfinance Fund Company through which the remaining financing value of the Women’s Microfinance Fund is paid with administrative expenses and all commissions and fines remaining on the beneficiary in the event of the death of the beneficiary, so that the age is not The beneficiary is over 65 years old. </p>
<p> The value of the monthly installment is thirty piasters per month, to be paid with the monthly installment of the financing. <p>
</div>
`;
