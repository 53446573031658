import { SurveyComponent } from "../../../../components/survey/survey_component";
import { expressions } from "../../../../survey/validators";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const applicant_eduloan = new SurveyComponent({
  title: {
    en: "Applicant Information",
  },
  name: "applicant",
  elements: [
    {
      title: { en: "Applicant Full Name *" },
      name: "first_name",
      type: "text",
      isRequired: required,
    },
    {
      name: "phone_number",
      type: "phone",
      isRequired: required,
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
      title: {
        en: "Mobile No. 1 *",
      },
    },
    {
      title: {
        en: "Physical Address *",
      },
      name: "panel_home_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "home_street",
          type: "text",
          isRequired: false,
          title: {
            en: "Street Name",
          },
        },
        {
          name: "home_building_number",
          type: "text",
          isRequired: false,
          title: {
            en: "Building No.",
          },
        },
        {
          name: "home_house_number",
          type: "text",
          isRequired: false,
          title: {
            en: "House No.",
          },
        },
        {
          name: "home_addres_details",
          type: "comment",
          isRequired: false,
          title: {
            en: "Address Details",
          },
        },
        {
          name: "home_city",
          type: "text",
          isRequired: required,
          title: {
            en: "City *",
          },
        },
        {
          name: "home_country",
          type: "text",
          isRequired: required,
          title: {
            en: "Country *",
          },
        },
        {
          name: "property_ownership",
          type: "dropdown",
          isRequired: required,
          title: {
            en: "Property Ownership *",
          },
          choices: [
            { value: "owned", text: { en: "Owned" } },
            { value: "rented", text: { en: "Rented" } },
            { value: "mortgaged", text: { en: "Mortgaged" } },
          ],
        },
      ],
    },
    {
      title: {
        en: "Can you share a picture for yourself? *",
      },
      name: "profile_avatar",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Date of Birth *",
      },
      name: "date_of_birth",
      type: "text",
      inputType: "date",
      isRequired: required,
    },
    {
      title: {
        en: "What is your gender? *",
      },
      name: "gender",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "male", text: { en: "Male" } },
        { value: "female", text: { en: "Female" } },
      ],
    },
    {
      title: {
        en: "What is your marital status? *",
      },
      name: "marital",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "Married", text: { en: "Married" } },
        { value: "Single", text: { en: "Single" } },
        { value: "Divorced", text: { en: "Divorced" } },
        { value: "Widowed", text: { en: "Widowed" } },
      ],
    },
    {
      title: {
        en: "Occupation Details *",
      },
      name: "panel_occupation_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Occupation *",
          },
          name: "current_job_position",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Type of employment *",
          },
          type: "dropdown",
          name: "current_job_description",
          choices: [
            { value: "permanent", text: { en: "Permanent" } },
            { value: "contract", text: { en: "Contract" } },
            { value: "temporary", text: { en: "Temporary" } },
            { value: "self_employed", text: { en: "Self Employed" } },
            { value: "unemployed", text: { en: "Unemployed" } },
          ],
          isRequired: required,
        },
        {
          title: {
            en: "Name of your employer *",
          },
          name: "current_job_company",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Employer's address *",
          },
          name: "current_job_address",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Employee Number *",
          },
          name: "current_job_employee_number",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Years with current employer",
          },
          name: "current_job_duration",
          type: "text",
        },
        {
          title: {
            en: "Tel work",
          },
          name: "current_job_phone",
          type: "text",
        }
      ],
    },
    {
      title: {
        en: "Email address",
      },
      name: "email",
      type: "text",
      validators: [{ type: "email" }],
    },
    {
      title: {
        en: "Relationship with student",
      },
      name: "guarantor_relation",
      type: "text",
    },
    {
      title: {
        en: "How long do you want the loan to last?",
      },
      description: {
        en: "The loan term can be anywhere between 4 and 12 months.",
      },
      name: "repayment_period",
      type: "nouislider",
      step: 1,
      defaultValue: 12,
      rangeMin: 4,
      rangeMax: 12,
    },
    {
      name: "outstanding_loan_questions",
      type: "panel",
      elements: [
        {
          title: {
            en: "Do you currently have a loan outstanding? *",
          },
          name: "has_outstanding_loans",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: true,
              text: {
                en: "Yes",
              },
            },
            {
              value: false,
              text: {
                en: "No",
              },
            },
          ],
        },
        {
          title: {
            en: "Details of any collateral arrangements",
          },
          name: "collateral_arrangements",
          type: "text",
          visibleIf: "{has_outstanding_loans} == true"

        },
        {
          title: {
            en: "Details of other borrowings",
          },
          name: "other_borrowing",
          type: "text",
          visibleIf: "{has_outstanding_loans} == true"
        },
        {
          title: {
            en: "Name of lender/bank",
          },
          name: "lender_bank_name",
          type: "text",
          visibleIf: "{has_outstanding_loans} == true"
        },
        {
          title: {
            en: "Limit",
          },
          name: "limit",
          type: "text",
          visibleIf: "{has_outstanding_loans} == true"
        },
        {
          title: {
            en: "Repayment",
          },
          name: "repayment",
          type: "text",
          visibleIf: "{has_outstanding_loans} == true"
        },
        {
          title: {
            en: "Current/outstanding balance",
          },
          name: "current_outstanding_balance",
          type: "text",
          visibleIf: "{has_outstanding_loans} == true"
        },
      ],
    },
  ],
});
