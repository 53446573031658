import WayPoint from 'react-waypoint';
import React from 'react';

import './reveal.scss';

export class Reveal extends React.Component {
  constructor() {
    super();

    this.state = {
      update: true
    };

    this.visibleItems = [];
  }

  render({children, className} = this.props) {
    return (
      <div className={`revealContainer ${className}`}>
        {children.map((child, index) => (
          <WayPoint
            key={index}
            bottomOffset={10}
            onEnter={() => this._toggleItem(index)}
            onLeave={() => this._toggleItem(index)}>
            <div
              className={`revealItem ${child.props.className} ${this.visibleItems.indexOf(index) > -1 ? 'fadeIn' : 'fadeOut'}`}>
              {child.props.children}
            </div>
          </WayPoint>
        ))}
      </div>
    )
  }

  _toggleItem(itemIndex) {
    let visibleItems = this.visibleItems.slice(0);

    if (visibleItems.indexOf(itemIndex) === -1) {
      visibleItems.push(itemIndex);
    }

    this.visibleItems = visibleItems;

    this.setState({update: true});
  }
}

