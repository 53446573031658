import React from 'react';
import ReactDOM from 'react-dom';

import './phone.scss';

class PhoneComponent extends React.Component {
  constructor(props, context) {
    super(props, context);

    const fullNumber = props.question.value || '';

    let number, country = '';

    const country_codes = this.props.countries.map(c => c.country_code);
    country_codes.forEach(country_code => {
      if(fullNumber.startsWith(country_code)){
          number = fullNumber.replace(country_code,"")
          country = country_code
      }
    });

    // Correcting current numbers starting on 0
    if (country_codes.indexOf(country) === -1) {
      country = country_codes[0];
    }

    this.state = { country, number, countries: country_codes };

    this.update(country, number);
  }

  updateState(k) {
    return (event) => this.setState({ ...this.state, [k]: event.target.value });
  }

  update(country, number) {
    this.props.changeHandler(country && number ? `${country}${number}` : null);
  }

  componentWillUpdate(_props, { country, number }) {
    this.update(country, number);
  }

  countryOption(country) {
    if (country) {
      return <option value={country} key={country}>+{country}</option>;
    } else {
      return <option value=""></option>;
    }
  }

  render() {
    return (
      <div className='phone'>
        <select value={this.state.country} name="country" className="country_select" onChange={this.updateState('country')}>
          { this.state.countries.map(this.countryOption) }
        </select>
        <input value={this.state.number} type="text" className="phone_input" onChange={this.updateState('number')} onBlur={this.validate}/>
      </div>
    )
  }
}

export function registerPhoneComponent(Survey, countries) {
  let widget = {
    name: "phone",
    widgetIsLoaded: () => true,
    isFit: q => q.getType() === "phone",
    activatedByChanged: (_activatedBy) => {
      Survey.JsonObject.metaData.addClass("phone", [], null, "text");
    },
    afterRender: function (question, el) {
      ReactDOM.render(
        <PhoneComponent countries={countries} question={question} changeHandler={ (value) => question.value = value }/>,
        el
      );
    },
    htmlTemplate: "<div style='padding: 0 20px'></div>",
  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}
