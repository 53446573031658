// import env from "../../env";
const required = true;

export const crediavance_interview = [
  {
    title: {
      en: "Individual interview",
      "es-MX": "Entrevista persona física",
    },
    name: "individual_interview",
    elements: [
      {
        title: { en: "Nationality", "es-MX": "Nacionalidad" },
        type: "radiogroup",
        name: "custom_text_36",
        isRequired: required,
        choices: [
          {
            value: "Nacionalidad mexicana",
            text: {
              en: "Mexican nationality",
              "es-MX": "Nacionalidad mexicana",
            },
          },
          {
            value: "Nacionalidad extranjera con residencia permanente",
            text: {
              en: "Foreign nationality with permanent residence",
              "es-MX": "Nacionalidad extranjera con residencia permanente",
            },
          },
          {
            value: "Nacionalidad extranjera con residencia temporal",
            text: {
              en: "Foreign nationality with temporary residence",
              "es-MX": "Nacionalidad extranjera con residencia temporal",
            },
          },
        ],
      },
      {
        type: "expression",
        expression: `daysFromNow(0, 0, 'es-MX')`,
        title: {
          en: "Interview date",
          "es-MX": "Fecha de la entrevista",
        },
        name: "custom_text_101",
        isRequired: required,
      },
      {
        title: { en: "Branch office", "es-MX": "Sucursal" },
        type: "dropdown",
        name: "custom_text_34",
        isRequired: required,
        choices: [
          { value: "obregon", text: { en: "Obregon", "es-MX": "Obregón" } },
          {
            value: "los_mochis",
            text: { en: "Los Mochis", "es-MX": "Los Mochis" },
          },
        ],
      },
      {
        // Credit check currently only has an input for name -> first_name
        title: { en: "Client name", "es-MX": "Nombre del cliente" },
        type: "expression",
        expression: `getFullName({first_name},{middle_name},{surname},{second_surname})`,
        isRequired: required,
      },
      {
        title: {
          en: "About the documentation and information provided by the client",
          "es-MX":
            "Sobre la documentación e información proporcionada por el cliente",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "Provided all the information?",
              "es-MX": "¿Proporcionó toda la información?",
            },
            name: "custom_text_72",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_72} == 'si'",
              },
            ],
          },
          {
            title: {
              en: "Provided all documentation?",
              "es-MX": "¿Proporcionó toda la documentación?",
            },
            name: "custom_text_73",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_73} == 'si'",
              },
            ],
          },
          {
            type: "panel",
            elements: [
              {
                title: {
                  en: "Information not provided or consistent with the manifest",
                  "es-MX":
                    "Información no proporcionada o acorde con lo manifiesto",
                },
                type: "text",
                name: "custom_text_61",
                isRequired: required,
              },
              {
                title: {
                  en: "Customer argument",
                  "es-MX": "Argumento del cliente",
                },
                type: "text",
                name: "custom_text_62",
                isRequired: required,
              },
            ],
          },
          {
            type: "panel",
            elements: [
              {
                title: {
                  en: "Unsupported or suspicious documents",
                  "es-MX": "Documentos no proporcionados o sospechosos",
                },
                type: "checkbox",
                choices: [
                  {
                    value: "ninguno/sin sospecha",
                    text: { en: "None/No suspicion", "es-MX": "Ninguno/Sin sospecha" },
                  }
                ],
                isRequired: required,
                name: "custom_jsonfield_20",
              },
              {
                title: {
                  en: "Customer argument",
                  "es-MX": "Argumentos del cliente",
                },
                type: "text",
                name: "custom_text_64",
                isRequired: required,
              },
            ],
          },
        ],
      },

      {
        title: {
          en: "Sources of income and destination of resources of the client",
          "es-MX":
            "Sobre fuentes de ingresos y destino de recursos del cliente",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "What is your monthly income",
              "es-MX": "¿Cuál es su ingreso mensual?",
            },
            type: "text",
            name: "custom_float_3",
            isRequired: required,
            inputMask: "currency",
          },
          {
            title: {
              en: "What is your main source of income",
              "es-MX": "¿Cuál es su principal fuente de ingresos?",
            },
            type: "checkbox",
            name: "custom_jsonfield_3",
            isRequired: required,
            choices: [
              {
                value: "al_comercio",
                text: { en: "To trade", "es-MX": "Al comercio" },
              },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be 'To trade'",
                  "es-MX": "Indica 'Al comercio' en tu respuesta",
                },
                expression: "{custom_jsonfield_3} contains 'al_comercio'",
              },
            ],
          },
          {
            title: {
              en: "Seniority in that activity",
              "es-MX": "Antigüedad  en esa actividad",
            },
            type: "text",
            name: "custom_text_66",
            isRequired: required,
          },
          {
            title: {
              en: "Other sources of income identified?",
              "es-MX": "¿Otras fuentes de ingresos identificadas?",
            },
            name: "custom_text_74",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: { en: "Which one?", "es-MX": "¿Cuál?" },
            type: "text",
            name: "custom_text_68",
            isRequired: required,
          },
          {
            title: { en: "They are testable?", "es-MX": "¿Son comprobables?" },
            name: "custom_text_75",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be no",
                  "es-MX": "Indica no en tu respuesta",
                },
                expression: "{custom_text_75} == 'no'",
              },
            ],
          },
          {
            type: "panel",
            elements: [
              {
                title: {
                  en: "Main destination of your resources",
                  "es-MX": "Destino principal de sus recursos",
                },
                type: "checkbox",
                name: "custom_jsonfield_10",
                choices: [
                  {
                    value: "préstamos_a_terceros",
                    text: {
                      en: "Loans to third parties",
                      "es-MX": "Préstamos a terceros",
                    },
                  },
                ],
                validators: [
                  {
                    type: "expression",
                    text: {
                      en: "Answer should be 'Loans to third parties'",
                      "es-MX": "Indica 'Préstamos a terceros' en tu respuesta",
                    },
                    expression:
                      "{custom_jsonfield_10} contains 'préstamos_a_terceros'", 
                  },
                ],
              },
              {
                type: "text",
                title: { en: "Others", "es-MX": "Otros" },
                name: "custom_text_71",
                isRequired: required,
              },
              {
                title: {
                  en: "They are verifiable?",
                  "es-MX": "¿Son comprobables?",
                },
                name: "custom_text_76",
                type: "radiogroup",
                colCount: 2,
                choices: [
                  { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
                  { value: "no", text: { en: "No", "es-MX": "No" } },
                ],
              },
            ],
          },
        ],
      },

      {
        title: {
          en: "Initial transactional profile",
          "es-MX": "Perfil transaccional inicial",
        },
        type: "panel",
        elements: [
          {
            title: { en: "Cash", "es-MX": "Efectivo" },
            name: "custom_text_77",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_77} == 'si'",
              },
            ],
          },
          {
            title: { en: "SPEI", "es-MX": "SPEI" },
            name: "custom_text_78",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_78} == 'si'",
              },
            ],
          },
          {
            title: { en: "Transfer", "es-MX": "Transferencia" },
            name: "custom_text_79",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_79} == 'si'",
              },
            ],
          },
          {
            title: {
              en: "Will make early settlement?",
              "es-MX": "¿Hará liquidación anticipada?",
            },
            name: "custom_text_80",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_80} == 'si'",
              },
            ],
          },
          {
            title: {
              en: "Will make payments in another city",
              "es-MX": "¿Hará pagos en otra ciudad?",
            },
            name: "custom_text_81",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_81} == 'si'",
              },
            ],
          },
          {
            title: {
              en: "Will make installment payments",
              "es-MX": "¿Hará pagos fraccionados?",
            },
            name: "custom_text_82",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_82} == 'si'",
              },
            ],
          },
          {
            title: {
              en: "Will make advance payments",
              "es-MX": "¿Hará pagos anticipados?",
            },
            name: "custom_text_83",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be yes",
                  "es-MX": "Indica sí en tu respuesta",
                },
                expression: "{custom_text_83} == 'si'",
              },
            ],
          },
          {
            title: {
              en: "Time residing at home",
              "es-MX": "Tiempo de radicar en el domicilio ",
            },
            type: "text",
            name: "custom_text_88",
            isRequired: required,
          },
          {
            title: {
              en: "Month in which you will make advance payments",
              "es-MX": "Mes en que realizará pagos anticipados",
            },
            type: "checkbox",
            name: "custom_jsonfield_19",
            isRequired: required,
            choices: [
              {
                value: "durante_el_año",
                text: { en: "During the year", "es-MX": "Durante el año" },
              },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be 'During the year'",
                  "es-MX": "Indica 'Durante el año' en tu respuesta",
                },
                expression: "{custom_jsonfield_19} contains 'durante_el_año'", 
              },
            ],
          },
        ],
      },

      {
        title: {
          en: "Resource providers and beneficial owners",
          "es-MX": "Proveedores de recursos y propietarios reales",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "A possible resource provider was identified?",
              "es-MX": "¿Se identificó algún posible proveedor de recursos?",
            },
            name: "custom_text_84",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be no",
                  "es-MX": "Indica no en tu respuesta",
                },
                expression: "{custom_text_84} == 'no'",
              },
            ],
          },
          {
            title: { en: "Whose?", "es-MX": "¿Quién?" },
            type: "checkbox",
            name: "custom_jsonfield_15",
            isRequired: required,
            choices: [{ value: "N/A", text: { en: "N/A", "es-MX": "N/A" } }],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be N/A",
                  "es-MX": "Indica N/A en tu respuesta",
                },
                expression: "{custom_jsonfield_15} contains 'N/A'", 
              },
            ],
          },
          {
            title: {
              en: "Relationship with the client",
              "es-MX": "Relación con el cliente",
            },
            type: "checkbox",
            name: "custom_jsonfield_8",
            isRequired: required,
            choices: [{ value: "N/A", text: { en: "N/A", "es-MX": "N/A" } }],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be N/A",
                  "es-MX": "Indica N/A en tu respuesta",
                },
                expression: "{custom_jsonfield_8} contains 'N/A'", 
              },
            ],
          },
        ],
      },
      {
        title: {
          en: "A possible real owner was identified?",
          "es-MX": "¿Se identificó algún posible propietario real?",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "A possible real owner was identified?",
              "es-MX": "¿Se identificó algún posible propietario real?",
            },
            name: "custom_text_85",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be no",
                  "es-MX": "Indica no en tu respuesta",
                },
                expression: "{custom_text_85} == 'no'",
              },
            ],
          },
          {
            title: { en: "Whose?", "es-MX": "¿Quién?" },
            type: "checkbox",
            name: "custom_jsonfield_9",
            isRequired: required,
            choices: [{ value: "N/A", text: { en: "N/A", "es-MX": "N/A" } }],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be N/A",
                  "es-MX": "Indica N/A en tu respuesta",
                },
                expression: "{custom_jsonfield_9} contains 'N/A'", 
              },
            ],
          },
          {
            title: {
              en: "Relationship with the client",
              "es-MX": "Relación con el cliente",
            },
            type: "checkbox",
            name: "custom_jsonfield_11",
            isRequired: required,
            choices: [{ value: "N/A", text: { en: "N/A", "es-MX": "N/A" } }],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be N/A",
                  "es-MX": "Indica N/A en tu respuesta",
                },
                expression: "{custom_jsonfield_11} contains 'N/A'", 
              },
            ],
          },
        ],
      },
      {
        title: {
          en: "Politically exposed person",
          "es-MX": "Persona politicamente expuesta",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "Customer stands as PPE?",
              "es-MX": "¿El cliente se ubica como PPE?",
            },
            name: "custom_text_86",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be no",
                  "es-MX": "Indica no en tu respuesta",
                },
                expression: "{custom_text_86} == 'no'",
              },
            ],
          },
          {
            title: { en: "Role played", "es-MX": "Función que desempeña" },
            type: "checkbox",
            name: "custom_jsonfield_12",
            isRequired: required,
            choices: [{ value: "N/A", text: { en: "N/A", "es-MX": "N/A" } }],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be N/A",
                  "es-MX": "Indica N/A en tu respuesta",
                },
                expression: "{custom_jsonfield_12} contains 'N/A'", 
              },
            ],
          },
          {
            title: {
              en: "PPE Family Clients",
              "es-MX": "Clientes familiares de PPE",
            },
            name: "custom_text_87",
            type: "radiogroup",
            colCount: 2,
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be no",
                  "es-MX": "Indica no en tu respuesta",
                },
                expression: "{custom_text_87} == 'no'",
              },
            ],
          },
          {
            title: { en: "Who?", "es-MX": "¿Quiénes?" },
            type: "checkbox",
            name: "custom_jsonfield_13",
            isRequired: required,
            choices: [{ value: "N/A", text: { en: "N/A", "es-MX": "N/A" } }],
            validators: [
              {
                type: "expression",
                text: {
                  en: "Answer should be N/A",
                  "es-MX": "Indica N/A en tu respuesta",
                },
                expression: "{custom_jsonfield_13} contains 'N/A'", 
              },
            ],
          },
        ],
      },
      {
        title: {
          en: "Observations or comments:",
          "es-MX": "Observaciones o comentarios:",
        },
        type: "checkbox",
        name: "custom_jsonfield_14",
        isRequired: required,
        choices: [
          {
            value: "no_se_encantron_discrepancias_en_la_información_recabada",
            text: {
              en: "No discrepancies were found in the information collected",
              "es-MX":
                "No se encontraron discrepancias en la información recabada",
            },
          },
        ],
        validators: [
          {
            type: "expression",
            text: {
              en: "Answer should be 'No discrepancies'",
              "es-MX": "Indica 'No se encantron' en tu respuesta",
            },
            expression: "{custom_jsonfield_14} contains 'no_se_encantron_discrepancias_en_la_información_recabada'", 
          },
        ],
      },
      {
        title: {
          en: "Name of the interviewer",
          "es-MX": "Nombre del entrevistador",
        },
        type: "text",
        name: "custom_text_92",
        isRequired: required,
      },
      {
        title: {
          en: "Online signature of the interviewer",
          "es-MX": "Firma online del entrevistador",
        },
        type: "signaturepad",
        isRequired: required,
        name: "file_17",
      },
      {
        title: {
          en: "Name of the supervisor",
          "es-MX": "Nombre del supervisor",
        },
        type: "text",
        isRequired: required,
        name: "custom_text_94",
      },
      {
        title: {
          en: "Online signature of the supervisor",
          "es-MX": "Firma online del supervisor",
        },
        type: "signaturepad",
        isRequired: required,
        name: "file_18",
      },
    ],
  },
  {
    title: { en: "Attached documents", "es-MX": "Anexos" },
    name: "attached_documents",
    elements: [
      {
        html: {
          en: "<strong>Before going to the committee, all the documentation must be attached</strong>",
          "es-MX":
            "<strong>Antes de pasar a comite se tiene que anexar toda la documentación</strong>",
        },
        type: "html",
      },
      {
        title: { en: "Credit request", "es-MX": "Solicitud de crédito" },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_1",
      },
      {
        title: {
          en: "Statements of account of the competendia",
          "es-MX": "Estados de cuenta de la competencia",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_2",
      },
      {
        title: {
          en: "Receipt (no more than three months)",
          "es-MX": "Recibo (no mayor a tres meses)",
        },
        type: "radiogroup",
        name: "custom_text_97",
        choices: [
          { value: "cfe", text: { en: "CFE", "es-MX": "CFE" } },
          { value: "agua", text: { en: "AGUA", "es-MX": "AGUA" } },
          {
            value: "tel_fija",
            text: { en: "TEL. FIJA", "es-MX": "TEL. FIJA" },
          },
          { value: "cable_or_internet", text: { en: "CABLE OR INTERNET", "es-MX": "CABLE O INTERNET" } },
        ],
      },
      {
        title: { en: "{custom_text_97}", "es-MX": "{custom_text_97}" },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        visibleIf: "{custom_text_97} notempty",
        name: "file_3",
      },
      {
        title: {
          en: "IFE / INE current and clear",
          "es-MX": "IFE/INE vigente y clara",
        },

        type: "panel",
        elements: [
          {
            title: {
              en: "Upload photo of front",
              "es-MX": "Subir foto por delante",
            },

            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_7",
          },
          {
            title: {
              en: "Upload photo of the back",
              "es-MX": "Subir foto por atras",
            },

            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_8",
          },
        ],
      },

      {
        title: {
          en: "Property receipt (no more than 1 year)",
          "es-MX": "Recibo de predial (no mayor a 1 año)",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_9",
      },
      {
        title: {
          en: "Result of the PLD natural person interview",
          "es-MX": "Resultado de la entrevista persona física PLD",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_10",
      },
      {
        title: {
          en: "Address statement (when applicable)",
          "es-MX": "Manifiesto de domicilio (cuando aplique)",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_11",
      },
      {
        title: { en: "Bureau query", "es-MX": "Consulta de buró" },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_12",
      },
      {
        title: {
          en: "Socio-economic study",
          "es-MX": "Estudio socioeconómico",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_13",
      },
      {
        title: {
          en: "Proof of income (wage earners only)",
          "es-MX": "Comprobante de ingresos (solo asalariados)",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_14",
      },
      {
        title: {
          en: "Committee card in branch",
          "es-MX": "Cédula de comité en sucursal",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_15",
      },
      {
        title: {
          en: "CURP (when applicable)",
          "es-MX": "CURP (cuando aplique) ",
        },

        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_16",
      },
    ],
  },
];
