export const choose_flow_mfw_pages = [
  {
    title: {
      en: "What is the purpose of your loan?",
      ar: "ما هو الهدف من قرضك",
    },
    name: "purpose_page",
    elements: [
      {
        title: {
          en: "Do you want to take this loan for business or educational purposes?",
          ar: "هل ترغب في الحصول على هذا القرض لأغراض تجارية أو تعليمية؟",
        },
        name: "purpose_question",
        type: "radiogroup",
        isRequired: true,
        choices: [
          {
            value: "education",
            text: {
              en: "Education",
              ar: "التعليم",
            },
          },
          {
            value: "business",
            text: {
              en: "Business",
              ar: "اعمال",
            },
          },
        ],
      },
    ],
  },
];
