// Needs Rwandan translations

export const survey_rw = {
    "pagePrevText": "Ahabanza",
    "pageNextText": "Ahakurikira",
    "completeText": "Byuzuye",
    "startSurveyText": "Tangira",
    "progressText": "Urupapuro {0} kuri {1}",
    "requiredError": "Nyamuneka subiza ikibazo.",
  
    "otherItemText": "Ibindi",
    "emptySurvey": "Nta page cg ikibazo kigaragara hano muri iri bazwa.",
    "completingSurvey": "Urakoze kurangiza iri bazwa!",
    "completingSurveyBefore": "Amakuru ari kutwereka ko  warangije kuzuza iri bazwa.",
    "loadingSurvey": "Ibazwa rigiye kutangita...",
    "optionsCaption": "Hitamo...",
    "value": "Agaciro",
    "requiredErrorInPanel": " Subiza nibura ikibazo kimwe.",
    "requiredInAllRowsError": "Subiza ibibazo byose biri mu mirongo.",
    "numericError": "Agaciro kagomba kuba kari mumibare.",
    "textMinLength": "Andika  nibura {0} inyuguti.",
    "textMaxLength": "Andika musi ya {0} inyuguti.",
    "textMinMaxLength": "Andika hejuru ya {0} no munsi ya {1} inyuguti.",
    "minRowCountError": "Uzuza nibura {0} imirongo.",
    "minSelectError": " Hitamo nibura {0}.",
    "maxSelectError": "Hitamo munsi ya {0}.",
    "numericMinMax": "{0} igomba kuba ingana cg iri hejuru {1} nanone ikagangana cg ikaba iri munsi ya {2}.",
    "numericMin": "{0} igomba kuba ingana cg iri hejuru {0}.",
    "numericMax": "{0} igomba kuba ingana cg iri munsi {1}.",
    "invalidEmail": "Shyiramo emiri yawe.",
    "invalidExpression": "The expression: {0} should return 'true'.",
    "urlRequestError": "Ikifuzo cyagarutse ari ikosa {0}. {1}",
    "urlGetChoicesError": "Ikifuzo cyaragarutse ari busa cg waciye inzi itariyo",
    "exceedMaxSize": "Ingano ya dosiye nigomba kurenza {0}.",
    "otherRequiredError": "Please enter the other value.",
    "uploadingFile": " Dosiye yawe iri kujyaho.Tereza amasegonda make hanyuma wongere",
    "loadingFile": "Loading...",
    "chooseFile": " Hitamo dosiye...",
    "confirmDelete": "Urashaka gusiba iyi nyandiko?",
    "keyDuplicationError": "Umubare ugomba kuba udasa nundi.",
    "addColumn": "Shyiramo umurongo uhagaze",
    "addRow": "Shyiramo umurongo utambitse",
    "removeRow": "Kuramo",
    "addPanel": "Shyiramo umurongo mushya",
    "removePanel": "Kuramo",
    "choices_Item": "item",
    "matrix_column": "Umurongo uhagaze",
    "matrix_row": "Umurongo",
    "savingData": "Ibisubizo biri kubikwa kuri seriveri...",
    "savingDataError": "An error occurred and we could not save the results.",
    "savingDataSuccess": "The results were saved successfully!",
    "saveAgainButton": "Try again",
    "timerMin": "iminota",
    "timerSec": "sec",
    "timerSpentAll": "Wakoresheje {0} kuri iyi paji na {1} muri rusange.",
    "timerSpentPage": "Wakoresheje {0} kuri iyi paji.",
    "timerSpentSurvey": "Wakoresheje {0} kuri iyi paji na.",
    "timerLimitAll": "You have spent {0} of {1} on this page and {2} of {3} in total.",
    "timerLimitPage": "You have spent {0} of {1} on this page.",
    "timerLimitSurvey": "You have spent {0} of {1} in total.",
    "cleanCaption": "Hanagura",
    "clearCaption": "Clear"
  };
  