import React from 'react';
import ReactDOM from 'react-dom';
import Sortable from "sortablejs";
import './ranking.scss';
import {Icon, ws_arrow_down} from "../components/icon/icon";
import {colors} from "../theme/theme";
import {translate} from "../lib/intl";

class RankingComponent extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.finishedIndex = props.question.choices.length;
    this.locale = props.question.survey.locale;

    this.state = {
      choices: props.question.choices,
      currentIndex: 0,
      result: [],
    };
    console.log('initiated with choices:', this.state)
  }

  componentDidMount() {

    Sortable.create(this.source, {
      animation: 150,
      sort: false,
      group: "shared",
    });

    this.resultSortable = Sortable.create(this.result, {
      animation: 150,
      sort: true,
      group: "shared",

      onAdd: (evt) => {
        // make new element visible
        setTimeout(() =>
          this.setState({currentIndex: this.state.currentIndex + 1}, () => this._saveResults())
          , 250)
      },

      onSort: (evt) => {
        // Save the result array
        this._saveResults()
      }
    });

  }

  _saveResults(newValue) {
    if (this.state.currentIndex === this.finishedIndex) {
      // save the current order to the survey
      this.props.changeHandler(this.resultSortable.toArray())
    }
  };

  render({question} = this.props) {
    return (
      <div className='rankingComponent'>

        <div className='listWrapper'>
          <div ref={(ref) => this.source = ref} className={`list source ${this.state.currentIndex === this.finishedIndex ? 'hidden' : ''}`}>
            {this.state.choices.map((choice, index) => (
              <div key={index} className={`item ${index <= this.state.currentIndex ? '' : 'hidden'}`} data-id={choice.value}>
                <p className='white heavy'>{choice.text.split("+")[0]}</p>
                <p className='white small'>{choice.text.split("+")[1]}</p>
              </div>
            ))}
          </div>

          {this.state.currentIndex === this.finishedIndex && (
            <div>
              <p className='center bigSpacer'>
                {translate('ranking.done')}
              </p>
              <p className='center bigSpacer'>
                {translate('ranking.done2')}
              </p>
            </div>
          )}
          {this.state.currentIndex !== this.finishedIndex && (
            <div className='bounce center'>
              <Icon icon={ws_arrow_down} size={50} color={colors.coolGray.c4}/>
            </div>
          )}
        </div>

        <div className='sort'>
        </div>

        <p className='center cool_c4 small spacer'>
          {translate('ranking.most')}
        </p>

        <div className={`listWrapper ${this.state.currentIndex !== this.finishedIndex ? 'empty' : 'full'}`}>
          <div ref={(ref) => this.result = ref} className="list">

          </div>
          {this.state.currentIndex === 0 && (
            <p className='center bigSpacer'>
              {translate('ranking.drag')}
            </p>
          )}
        </div>

        <p className='center cool_c4 small spacer'>
          {translate('ranking.least')}
        </p>

      </div>
    )
  }
}

export function register_widget_ranking(Survey) {
  let widget = {
    name: "ranking",
    title: "Raking list",

    widgetIsLoaded: function () {
      return typeof Sortable !== "undefined";
    },

    areaStyle: "border: 1px solid #1ab394; width:100%; min-height:50px",
    itemStyle: "background-color:#1ab394;color:#fff;margin:5px;padding:10px;",

    isFit: function (question) {
      return question.getType() === "ranking";
    },

    htmlTemplate: "<div></div>",

    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass(
        "ranking", [], null, "checkbox"
      );

    },

    afterRender: function (question, el) {

      let rendering = <RankingComponent question={question} changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, el);
    },
    willUnmount: function (question, el) {
    }
  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}