import { SurveyComponent } from "../../../../components/survey/survey_component";
//import { expressions } from "../../../../survey/validators";
import env from "../../../../env";
const required = env.ENDPOINT !== "local";

export const residence_home_bll = new SurveyComponent({
  title: {
    en: "Residence & Home Information",
    rn: "Amakuru yaho utuye",
    "fr-BI": "Informations sur votre résidence et domicile",
  },
  name: "home",
  elements: [
    {
      title: {
        en: "Where do you live?",
        rn: "Ibijanye n'aho uba",
        "fr-BI": "Où habitez-vous?",
      },
      name: "panel_home",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "province",
          type: "text",
          isRequired: required,
          title: {
            en: "Province *",
            rn: "Provensi *",
            "fr-BI": "Province",
          },
        },
        {
          name: "district",
          type: "text",
          isRequired: required,
          title: {
            en: "Commune *",
            rn: "Komine *",
            "fr-BI": "Commune *",
          },
        },
        {
          name: "custom_text_5", 
          type: "text",
          isRequired: required,
          title: {
            en: "Zone *",
            rn: "Zone *",
            "fr-BI": "Zone *",
          },
        },
        {
          name: "cell",
          type: "text",
          isRequired: required,
          title: {
            en: "Hill *",
            rn: "Umutumba *",
            "fr-BI": "Colline *",
          },
        },
      ],
    },
  ],
});
