import React from "react";
import Config from "../../config";
import "./navigation.component.scss";

export class Navigation extends React.PureComponent {
  renderLocaleSelector(currentLocale, setLocale) {
    if (Config.has_available_locale) {
      if (Config.available_locale instanceof Array) {
        const locales = Config.available_locale.concat(Config.default_locale);
        const options = locales.map((locale) => (
          <option value={locale.lang}>{locale.translate_button}</option>
        ));
        return (
          <div className="center locale clickable">
            <select
              name="locales"
              id="locales"
              onChange={(val) => setLocale(val.target.value)}
              value={currentLocale}
            >
              {options}
            </select>
          </div>
        );
      } else {
        return (
          <div
            className="center locale clickable"
            onClick={() =>
              setLocale(
                currentLocale === Config.available_locale.lang
                  ? Config.default_locale.lang
                  : Config.available_locale.lang
              )
            }
          >
            <p>
              {currentLocale === Config.available_locale.lang
                ? Config.default_locale.translate_button
                : Config.available_locale.translate_button}
            </p>
          </div>
        );
      }
    }
  }
  render = ({ history, currentLocale, setLocale } = this.props) => {
    console.log(Config);
    return (
      <div className="navigationContainer">
        <div className="navigationWrapper">
          <div className="logoContainer">
            <a
              className="clickable"
              href="https://www.money-phone.com"
              rel="noopener noreferrer"
              target="_blank"
              title="MoneyPhone homepage"
            >
              <img className="logo" alt="MP" src={require("./res/logo.png")} />
            </a>
          </div>

          {this.renderLocaleSelector(currentLocale, setLocale)}
        </div>
      </div>
    );
  };
}
