import env from "../../env";
import { expressions, regexValidators } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const intake_finadev_pages = [
  // Page 1: Applicant Information
  {
    title: {
      en: "Applicant information",
      "fr-TD": "Informations sur le demandeur de prêt",
    },
    name: "Informations sur le demandeur",
    elements: [
      {
        type: "html",
        html: {
          en: "Please complete the required information about the applicant",
          "fr-TD":
            "Veuillez compléter les informations requises sur le demandeur. <br>",
        },
      },
      {
        title: {
          en: "What is your full name?*",
          "fr-TD": "Quel est votre nom complet ?*",
        },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: {
          en: "To verify your identity please upload a picture of yourself*",
          "fr-TD":
            "Pour vérifier votre identité, veuillez télécharger une photo de vous-même",
        },
        type: "file_uploader",
        isRequired: required,
        accept: "image/*,application/pdf",
        name: "file_8",
      },
      {
        title: {
          en: "what is your date of birth?*",
          "fr-TD": "Quelle est votre date de naissance ?",
        },
        type: "text",
        name: "date_of_birth",
        inputType: "date",
        isRequired: required,
      },
      {
        title: {
          en: "Please, specify your gender*",
          "fr-TD": " Que est votre sexe ?",
        },
        type: "dropdown",
        isRequired: required,
        name: "gender",
        choices: [
          { value: "Masculin", text: { en: "male", "fr-TD": "Masculin" } },
          { value: "Féminin", text: { en: "female", "fr-TD": "Féminin" } },
        ],
      },
      {
        title: {
          en: "What is your highest degree?",
          "fr-TD": "Quelle est votre dernier diplôme ?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_1",
        choices: [
          { value: "Bepct", text: { en: "Bepct", "fr-TD": "Bepct" } },
          { value: "bac", text: { en: "bac", "fr-TD": "bac" } },
          { value: "bac+1", text: { en: "bac+1", "fr-TD": "bac+1" } },
          { value: "bac+2", text: { en: "bac+2", "fr-TD": "bac+2" } },
          { value: "bac+3", text: { en: "bac+3", "fr-TD": "bac+3" } },
          { value: "autres", text: { en: "other", "fr-TD": "autres" } },
        ],
      },
      {
        title: {
          en: "Specify level",
          "fr-TD": "Préciser votre niveau scolaire",
        },
        type: "text",
        visibleIf: "{custom_text_1} == autres",
        isRequired: required,
        name: "custom_text_90",
      },
    ],
  },
  // Page 2: Business Information
  {
    title: {
      en: "Business information",
      "fr-TD": "Informations sur l'entreprise",
    },
    innerIndent: 1,
    name: "Informations sur l'entreprise",
    elements: [
      {
        title: { en: "Loan amount", "fr-TD": "Montant du prêt" },
        type: "text",
        inputType: "number",
        name: "amount",
        validators: [expressions.amount_greater_than_zero("amount")],
      },
      {
        title: {
          en: "Within how many months do you wish to repay your loan? *",
          "fr-TD":
            "Dans combien de mois souhaitez-vous rembourser votre prêt ? *",
        },
        inputType: "number",
        name: "repayment_period",
        type: "nouislider",
        isRequired: required,
        step: 1,
        rangeMin: 3,
        rangeMax: 36,
      },
      {
        title: {
          en: "Postpone Loan *",
          "fr-TD": "Differé",
        },
        inputType: "number",
        name: "custom_integer_1",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: 0,
            text: { en: "0", "fr-TD": "0" },
          },
          { value: 1, text: { en: "1", "fr-TD": "1" } },
          {
            value: 2,
            text: { en: "2", "fr-TD": "2" },
          },
          {
            value: 3,
            text: { en: "3", "fr-TD": "3" },
            visibleIf: "{repayment_period} >= 4",
          },
          {
            value: 4,
            text: { en: "4", "fr-TD": "4" },
            visibleIf: "{repayment_period} >= 5",
          },
          {
            value: 5,
            text: { en: "5", "fr-TD": "5" },
            visibleIf: "{repayment_period} >= 6",
          },
          {
            value: 6,
            text: { en: "6", "fr-TD": "6" },
            visibleIf: "{repayment_period} >= 7",
          },
          {
            value: 7,
            text: { en: "7", "fr-TD": "7" },
            visibleIf: "{repayment_period} >= 8",
          },
          {
            value: 8,
            text: { en: "8", "fr-TD": "8" },
            visibleIf: "{repayment_period} >= 9",
          },
          {
            value: 9,
            text: { en: "9", "fr-TD": "9" },
            visibleIf: "{repayment_period} >= 10",
          },
          {
            value: 10,
            text: { en: "10", "fr-TD": "10" },
            visibleIf: "{repayment_period} >= 11",
          },
          {
            value: 11,
            text: { en: "11", "fr-TD": "11" },
            visibleIf: "{repayment_period} >= 12",
          },
        ],
      },
      {
        title: { en: "Business Name *", "fr-TD": "Nom de l'entreprise *" },
        type: "text",
        name: "custom_text_49",
        isRequired: required,
      },
      {
        title: {
          en: "Business Owner *",
          "fr-TD": "Nom du propriétaire de l'entreprise *",
        },
        type: "text",
        name: "business_owner",
        isRequired: required,
      },
      {
        title: {
          en: "Business Type (Nature) *",
          "fr-TD": "Type d'entreprise (Nature) *",
        },
        type: "text",
        name: "business_nature",
        isRequired: required,
      },
      {
        title: {
          en: "Expected Monthly Income *",
          "fr-TD": "Revenu mensuel attendu *",
        },
        type: "text",
        inputType: "number",
        name: "business_income",
        isRequired: required,
      },
      {
        title: {
          en: "Business Start Date *",
          "fr-TD": "Date de début d'activité *",
        },
        type: "text",
        name: "custom_text_50",
        isRequired: required,
        inputType: "date",
      },
      {
        title: {
          en: "Is your business formally registered? *",
          "fr-TD": "Votre entreprise est-elle officiellement enregistrée ? *",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_51",
        choices: [
          { value: "oui", text: { en: "Yes", "fr-TD": "Oui" } },
          { value: "non", text: { en: "No", "fr-TD": "Non" } },
        ],
      },
      {
        title: {
          en: "Please upload your business formal registration documents *",
          "fr-TD":
            "Veuillez télécharger l'autorisation d'exercise de commerce *",
        },
        type: "file_uploader",
        isRequired: required,
        visibleIf: "{custom_text_51} == 'oui'",
        accept: "image/*,application/pdf",
        name: "file_4",
      },
      {
        title: {
          en: "Please upload proof of tax payment",
          "fr-TD": "Veuillez télécharger la preuve de paiement d'impôts *",
        },
        type: "file_uploader",
        visibleIf: "{custom_text_51} == 'oui'",
        accept: "image/*,application/pdf",
        name: "file_5",
      },
    ],
  },
  // Page 3: Identification Information
  {
    title: {
      en: "Identification information",
      "fr-TD": "Les informations sur l'identification du client",
    },
    name: "Les informations sur l'identification du client",
    elements: [
      {
        type: "html",
        html: {
          en: "The following information is needed to verify your identity.",
          "fr-TD":
            "Les informations suivantes sont nécessaires afin de vérifier votre identification.",
        },
      },
      {
        title: { en: "Nationality*", "fr-TD": "Quel est votre nationalité ?*" },
        type: "text",
        name: "nationality",
        isRequired: required,
      },
      {
        title: {
          en: "National Card ID",
          "fr-TD":
            " Quel est le numéro de votre Carte d'identité / Passeport / Permis de conduire / Carte d'électeur ?*",
        },
        type: "text",
        name: "id_number",
        isRequired: required,
      },
      {
        title: {
          en: "Upload your identity card / passport / driving license / voter card*(Front)",
          "fr-TD":
            "Téléchargez votre Carte d'identité / Passeport / Permis de conduire / Carte d'electeur*(avant)",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "id_file",
      },
      {
        title: {
          en: "Upload your identity card / passport / driving license / voter card*(Back)",
          "fr-TD":
            "Télécharger votre Carte d'identité / Passeport/ Permis de conduire / Carte d'electeur*(arrière)",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "id_file_back",
      },
      {
        title: {
          en: "To verify your identification, please upload a picture of yoursel holding you ID*",
          "fr-TD":
            "Pour vérifier votre identification, veuillez télécharger une photo de vous en tenant votre pièce d'identité*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
        name: "id_file_with_avatar",
      },
    ],
  },
  // Page 4: Family Information
  {
    title: { en: "Family information", "fr-TD": "Informations familiales" },
    name: "Informations familiales",
    elements: [
      {
        title: {
          en: "What is your marital status? *",
          "fr-TD": "Quel est votre situation matrimoniale ?*",
        },
        type: "dropdown",
        isRequired: required,
        name: "marital",
        choices: [
          {
            value: "Célibataire",
            text: { en: "Single", "fr-TD": "Célibataire" },
          },
          { value: "Marié(e)", text: { en: "Married", "fr-TD": "Marié(e)" } },
          {
            value: "Divorcé(e)",
            text: { en: "Divorced", "fr-TD": "Divorcé(e)" },
          },
          { value: "Veuf(ve)", text: { en: "Widowed", "fr-TD": "Veuf(ve)" } },
        ],
      },
      {
        type: "panel",
        visibleIf: "{marital} == 'Marié(e)'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: "Spouse full name",
              "fr-TD": "Nom complet du conjoint",
            },
            type: "text",
            name: "custom_text_2",
          },
          {
            title: {
              en: "Spouse Nationality",
              "fr-TD": "Nationalité du conjoint",
            },
            type: "text",
            name: "custom_text_3",
          },
          {
            title: {
              en: "Spouse National ID / Document No.",
              "fr-TD":
                "Télécharger votre carte d'identité / passeport du conjoint/permis de conduire/carte d'electeur",
            },

            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_1",
          },
          {
            title: {
              en: "Spouse Date of Birth",
              "fr-TD": "Date de naissance du conjoint",
            },
            type: "text",
            name: "custom_text_4",
            inputType: "date",
          },
          {
            title: {
              en: "Spouse Mobile No.",
              "fr-TD": "Numéro de téléphone du conjoint",
            },
            type: "text",
            inputType: "number",
            name: "custom_text_70",
          },
          {
            title: { en: "Spouse Job", "fr-TD": "Statut d'emploi du conjoint" },
            type: "text",
            name: "custom_text_5",
          },
          {
            title: {
              en: "Spouse Work Address",
              "fr-TD": "Adresse professionnelle du conjoint",
            },
            type: "text",
            name: "custom_text_6",
          },
        ],
      },
      {
        title: {
          en: "No. of Family Members",
          "fr-TD": "Nombre de personnes à charge",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_2",
      },
      {
        title: {
          en: " Do you own a house",
          "fr-TD": "Avez-vous une maison ?",
        },
        type: "dropdown",

        name: "custom_text_7",
        choices: [
          { value: "oui", text: { en: "Yes", "fr-TD": "Oui" } },
          { value: "non", text: { en: "No", "fr-TD": "Non" } },
        ],
      },
      {
        type: "panel",
        visibleIf: "{custom_text_7} == 'oui'",
        innerIndent: 1,
        elements: [
          {
            title: { en: "City*", "fr-TD": "Ville*" },
            type: "text",
            name: "custom_text_9",
            isRequired: required,
          },
          {
            title: { en: "Lot", "fr-TD": "Lot" },
            type: "text",
            name: "custom_text_10",
            isRequired: required,
          },
          {
            title: { en: "Cartier", "fr-TD": "Quartier " },
            type: "text",
            name: "custom_text_11",
            isRequired: required,
          },
          {
            title: { en: "Village", "fr-TD": "Village" },
            type: "text",
            name: "custom_text_12",
            isRequired: required,
          },
          {
            title: { en: "Conton", "fr-TD": "Canton " },
            type: "text",
            name: "custom_text_13",
            isRequired: required,
          },
          {
            title: {
              en: " Upload Ownership Agreement",
              "fr-TD": "Télécharger le titre  de propriété ",
            },
            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_2",
          },
        ],
      },
    ],
  },
  // Page 5: Residence and home information
  {
    title: {
      en: "Residence and home information",
      "fr-TD": "Informations sur la résidence",
    },
    innerIndent: 1,
    name: "Informations sur la résidence",
    elements: [
      {
        title: { en: "Country *", "fr-TD": "Pays*" },
        type: "text",
        name: "custom_text_14",
        isRequired: required,
      },
      {
        title: { en: "City *", "fr-TD": "Ville*" },
        type: "text",
        name: "custom_text_15",
        isRequired: required,
      },
      {
        title: { en: "lot", "fr-TD": " Lot" },
        type: "text",
        name: "custom_text_16",
        isRequired: required,
      },
      {
        title: { en: "Cartier", "fr-TD": "Quartier " },
        type: "text",
        name: "custom_text_17",
        isRequired: required,
      },
      {
        title: { en: "Village", "fr-TD": "Village" },
        type: "text",
        name: "custom_text_18",
        isRequired: required,
      },
      {
        title: { en: "Conton", "fr-TD": "Canton " },
        type: "text",
        name: "custom_text_19",
        isRequired: required,
      },
      {
        title: {
          en: "Indications",
          "fr-TD": " Fourni les Indications (itinéraire)",
        },
        type: "text",
        name: "custom_text_20",
        isRequired: required,
      },
      {
        title: { en: "Mobile No. 1 *", "fr-TD": " Votre numéro de téléphone*" },
        type: "text",
        inputType: "number",
        name: "custom_text_8",
        isRequired: required,
      },
      {
        title: {
          en: "Duration of occupation of current accommodation",
          "fr-TD": " Quel est la  durée d’occupation du logement actuel ?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_5",
      },
      {
        title: {
          en: "Seniority in the neighborhood (years)",
          "fr-TD": " Quel est l'ancienneté dans le quartier (années) ?",
        },
        type: "text",
        name: "custom_text_21",
      },
    ],
  },
  // Page 6: Guarantor Information
  {
    title: {
      en: "Guarantors information",
      "fr-TD": "Informations sur la garantie",
    },
    innerIndent: 1,
    elements: [
      {
        title: {
          en: "CUSTOMER AND WARRANTY INFORMATION",
          "fr-TD": " Veuillez fournir les information sur la garantie ",
        },
        type: "html",
      },
      {
        title: {
          en: "What type of collateral do you have?*",
          "fr-TD": " Quel est le type de garantie dont vous possédez ?",
        },
        type: "dropdown",
        isRequired: required,
        name: "collateral_arrangements",
        choices: [
          {
            value: "caution_solidaire_du_groupe",
            text: {
              en: "Group joint surety guarantee",
              "fr-TD": "Caution solidaire du groupe",
            },
          },
          {
            value: "réelle",
            text: { en: "Real Guarantee", "fr-TD": "Réelle" },
          },
        ],
      },
      {
        title: {
          en: "How many guarantees would you like to add",
          "fr-TD": " Combien de garanties voulez-vous ajouter ?",
        },
        type: "dropdown",
        isRequired: required,
        visibleIf: "{collateral_arrangements} == 'réelle'",
        name: "custom_integer_10",
        choices: [
          {
            value: "1",
            text: {
              en: "1",
              "fr-TD": "1",
            },
          },
          {
            value: "2",
            text: {
              en: "2",
              "fr-TD": "2",
            },
          },
          {
            value: "3",
            text: {
              en: "3",
              "fr-TD": " 3",
            },
          },
        ],
      },
      {
        title: {
          en: "Guarantee reele 1",
          "fr-TD": "Garantie Réelle Numéro 1",
        },
        type: "panel",
        visibleIf:
          "{collateral_arrangements} == 'réelle' && {custom_integer_10} >= '1'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: " Choose the nature of the guarantee",
              "fr-TD": " Choisissez la nature de la garantie ",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_75",
            choices: [
              {
                value: "Terrain nu",
                text: {
                  en: "Bare land",
                  "fr-TD": "Terrain nu",
                },
              },
              {
                value: "Parcelle batie",
                text: {
                  en: "Built-up plot",
                  "fr-TD": "Parcelle bâtie",
                },
              },
              {
                value: "Avaliseur Salarié",
                text: {
                  en: "Salaried guarantor",
                  "fr-TD": "Avaliseur Salarié",
                },
              },
              {
                value: "Autres",
                text: {
                  en: "Others",
                  "fr-TD": " Autres",
                },
              },
            ],
          },
          {
            title: { en: "if other, specify", "fr-TD": "Si autre préciser" },
            type: "text",
            name: "custom_text_24",
            visibleIf: "{custom_text_75} == 'Autres'",
          },
          {
            title: {
              en: "Do you own this guarantee",
              "fr-TD": "Êtes-vous le propriétaire de cette propriété ?",
            },
            type: "radiogroup",
            visibleIf: "{custom_text_75} == 'Terrain nu'",
            name: "custom_bool_1",
            isRequired: required,
            choices: [
              {
                value: false,
                text: { en: "I do", "fr-TD": "Oui" },
              },
              {
                value: true,
                text: {
                  en: "I don’t ",
                  "fr-TD": "Non",
                },
              },
            ],
          },
          {
            title: {
              en: "Do you own this guarantee",
              "fr-TD": "Êtes-vous le propriétaire de cette propriété ?",
            },
            type: "radiogroup",
            visibleIf: "{custom_text_75} == 'Parcelle batie'",
            name: "custom_bool_1",
            isRequired: required,
            choices: [
              {
                value: false,
                text: { en: "I do", "fr-TD": "Oui" },
              },
              {
                value: true,
                text: {
                  en: "I don’t ",
                  "fr-TD": "Non",
                },
              },
            ],
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD":
                "Quel est le nom et prénom du propriétaire de la garantie ?",
            },
            type: "text",
            visibleIf:
              "{custom_bool_1} == 'true' && {custom_text_75} != 'Avaliseur Salarié' && {custom_text_75} != 'Autres' ",
            name: "guarantor_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est son numéro de téléphone ?",
            },
            type: "text",
            visibleIf:
              "{custom_bool_1} == 'true' && {custom_text_75} != 'Avaliseur Salarié' && {custom_text_75} != 'Autres' ",
            name: "guarantor_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone"),
            ],
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD": "Quel est le nom et prénom du salarié ?",
            },
            type: "text",
            visibleIf: "{custom_text_75} == 'Avaliseur Salarié' ",
            name: "guarantor_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD": "Quel est le nom de votre avaliseur ?",
            },
            type: "text",
            visibleIf: "{custom_text_75} == 'Autres' ",
            name: "guarantor_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est le numéro de téléphone du salarié ?",
            },
            type: "phone",
            visibleIf: "{custom_text_75} == 'Avaliseur Salarié' ",
            name: "guarantor_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone"),
            ],
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est son numéro de téléphone ?",
            },
            type: "phone",
            visibleIf: "{custom_text_75} == 'Autres' ",
            name: "guarantor_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone"),
            ],
          },
          {
            title: {
              en: "Where is your guarantee?",
              "fr-TD": "Où habite l'avaliseur salarié ?",
            },
            type: "panel",
            visibleIf: " {custom_text_75} == 'Avaliseur Salarié'",
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_106",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_107",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_108",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_28",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_109",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_110",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee?",
              "fr-TD": "Où habite votre avaliseur ?",
            },
            type: "panel",
            visibleIf: " {custom_text_75} == 'Autres'",
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_106",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_107",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_108",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_28",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_109",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_110",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où se situe votre garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_75} == 'Terrain nu' && {custom_bool_1} == 'false'",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_106",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_107",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_108",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_28",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_109",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_110",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où se situe votre garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_75} == 'Parcelle batie' && {custom_bool_1} == 'false'",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_106",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_107",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_108",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_28",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_109",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_110",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee owner situated?",
              "fr-TD": "Où habite le propriétaire de la garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_75} == 'Terrain nu' && {custom_bool_1} == 'true'",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_106",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_107",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_108",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_28",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_109",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_110",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee owner situated?",
              "fr-TD": "Où habite le propriétaire de la garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_75} == 'Parcelle batie' && {custom_bool_1} == 'true'",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_106",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_107",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_108",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_28",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_109",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_110",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Download the title deed",
              "fr-TD": "Téléchargez le titre  de propriété",
            },
            visibleIf:
              "{custom_bool_1} == 'false' && {custom_text_75} != 'Autres' && {custom_text_75} != 'Avaliseur Salarié' ",
            type: "file_uploader",
            isRequired: required,
            accept: "image/*,application/pdf",
            name: "file_6",
          },
          {
            title: {
              en: "Value",
              "fr-TD": "Valeur",
            },
            type: "text",
            name: "custom_float_45",
            isRequired: required,
            validators: [regexValidators.max_two_decimal_places()],
          },
        ],
      },
      {
        title: {
          en: "Guarantee reele 2",
          "fr-TD": "Garantie Réelle Numéro 2",
        },
        type: "panel",
        visibleIf:
          "{collateral_arrangements} == 'réelle' && {custom_integer_10} >= '2'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: " Choose the nature of the guarantee",
              "fr-TD": " Choisissez la nature de la garantie ",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_83",
            choices: [
              {
                value: "Terrain nu",
                text: {
                  en: "Bare land",
                  "fr-TD": "Terrain nu",
                },
              },
              {
                value: "Parcelle batie",
                text: {
                  en: "Built-up plot",
                  "fr-TD": "Parcelle bâtie",
                },
              },
              {
                value: "Avaliseur Salarié",
                text: {
                  en: "Salaried guarantor",
                  "fr-TD": "Avaliseur Salarié",
                },
              },
              {
                value: "Autres",
                text: {
                  en: "Others",
                  "fr-TD": " Autres",
                },
              },
            ],
          },
          {
            title: {
              en: "Do you own this guarantee",
              "fr-TD": "Êtes-vous le propriétaire de cette propriété ?",
            },
            type: "radiogroup",
            visibleIf: "{custom_text_83} == 'Terrain nu'",
            name: "custom_bool_2",
            isRequired: required,
            choices: [
              {
                value: false,
                text: { en: "I do", "fr-TD": "Oui" },
              },
              {
                value: true,
                text: {
                  en: "I don’t ",
                  "fr-TD": "Non",
                },
              },
            ],
          },
          {
            title: {
              en: "Do you own this guarantee",
              "fr-TD": "Êtes-vous le propriétaire de cette propriété ?",
            },
            type: "radiogroup",
            visibleIf: "{custom_text_83} == 'Parcelle batie'",
            name: "custom_bool_2",
            isRequired: required,
            choices: [
              {
                value: false,
                text: { en: "I do", "fr-TD": "Oui" },
              },
              {
                value: true,
                text: {
                  en: "I don’t ",
                  "fr-TD": "Non",
                },
              },
            ],
          },
          {
            title: { en: "if other, specify", "fr-TD": "Si autre préciser" },
            type: "text",
            name: "custom_text_84",
            visibleIf: "{custom_text_83} == 'Autres'",
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD":
                "Quel est le nom et prénom du propriétaire de la garantie ?",
            },
            type: "text",
            visibleIf:
              "{custom_bool_2} == 'true' && {custom_text_83} != 'Avaliseur Salarié' && {custom_text_83} != 'Autres' ",
            name: "guarantor_2_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est son numéro de téléphone ?",
            },
            type: "text",
            visibleIf:
              "{custom_bool_2} == 'true' && {custom_text_83} != 'Avaliseur Salarié' && {custom_text_83} != 'Autres' ",
            name: "guarantor_2_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_2_phone"),
              expressions.same_phone_number("guarantor_2_phone"),
            ],
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD": "Quel est le nom et prénom du salarié ?",
            },
            type: "text",
            visibleIf: "{custom_text_83} == 'Avaliseur Salarié' ",
            name: "guarantor_2_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD": "Quel est le nom de votre avaliseur ?",
            },
            type: "text",
            visibleIf: "{custom_text_83} == 'Autres' ",
            name: "guarantor_2_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est le numéro de téléphone du salarié ?",
            },
            type: "text",
            visibleIf: "{custom_text_83} == 'Avaliseur Salarié' ",
            name: "guarantor_2_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_2_phone"),
              expressions.same_phone_number("guarantor_2_phone"),
            ],
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est son numéro de téléphone ?",
            },
            type: "text",
            visibleIf: "{custom_text_83} == 'Autres' ",
            name: "guarantor_2_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_2_phone"),
              expressions.same_phone_number("guarantor_2_phone"),
            ],
          },
          {
            title: {
              en: "Where is your guarantee?",
              "fr-TD": "Où habite l'avaliseur salarié ?",
            },
            type: "panel",
            visibleIf: " {custom_text_83} == 'Avaliseur Salarié'",
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_111",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_112",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_113",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_114",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_115",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_116",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee?",
              "fr-TD": "Où habite votre avaliseur ?",
            },
            type: "panel",
            visibleIf: " {custom_text_83} == 'Autres'",
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_111",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_112",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_113",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_114",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_115",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_116",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où se situe votre garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_83} == 'Terrain nu' && {custom_bool_2} == 'false' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_111",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_112",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_113",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_114",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_115",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_116",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où se situe votre garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_83} == 'Parcelle batie' && {custom_bool_2} == 'false' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_111",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_112",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_113",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_114",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_115",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_116",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee owner situated?",
              "fr-TD": "Où habite le propriétaire de la garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_83} == 'Terrain nu' && {custom_bool_2} == 'true' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_111",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_112",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_113",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_114",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_115",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_116",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee owner situated?",
              "fr-TD": "Où habite le propriétaire de la garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_83} == 'Parcelle batie' && {custom_bool_2} == 'true' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_111",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_112",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_113",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_114",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_115",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_116",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Download the title deed",
              "fr-TD": "Téléchargez le titre  de propriété",
            },
            visibleIf:
              "{custom_bool_2} = 'false' && {custom_text_83} != 'Autres' && {custom_text_83} != 'Avaliseur Salarié' ",
            type: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: required,
            name: "file_10",
          },
          {
            title: {
              en: "Value",
              "fr-TD": "Valeur",
            },
            type: "text",
            name: "custom_float_44",
            isRequired: required,
            validators: [regexValidators.max_two_decimal_places()],
          },
        ],
      },
      {
        title: {
          en: "Guarantee reele 3",
          "fr-TD": "Garantie Réelle Numéro 3",
        },
        type: "panel",
        visibleIf:
          "{collateral_arrangements} == 'réelle' && {custom_integer_10} >= '3'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: " Choose the nature of the guarantee",
              "fr-TD": " Choisissez la nature de la garantie ",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_91",
            choices: [
              {
                value: "Terrain nu",
                text: {
                  en: "Bare land",
                  "fr-TD": "Terrain nu",
                },
              },
              {
                value: "Parcelle batie",
                text: {
                  en: "Built-up plot",
                  "fr-TD": "Parcelle bâtie",
                },
              },
              {
                value: "Avaliseur Salarié",
                text: {
                  en: "Salaried guarantor",
                  "fr-TD": "Avaliseur Salarié",
                },
              },
              {
                value: "Autres",
                text: {
                  en: "Others",
                  "fr-TD": " Autres",
                },
              },
            ],
          },
          {
            title: { en: "if other, specify", "fr-TD": "Si autre préciser" },
            type: "text",
            name: "custom_text_92",
            visibleIf: "{custom_text_91} == 'Autres'",
          },
          {
            title: {
              en: "Do you own this guarantee",
              "fr-TD": "Êtes-vous le propriétaire de cette propriété ?",
            },
            type: "radiogroup",
            visibleIf: "{custom_text_91} == 'Terrain nu'",
            name: "custom_bool_3",
            isRequired: required,
            choices: [
              {
                value: false,
                text: { en: "I do", "fr-TD": "Oui" },
              },
              {
                value: true,
                text: {
                  en: "I don’t ",
                  "fr-TD": "Non",
                },
              },
            ],
          },
          {
            title: {
              en: "Do you own this guarantee",
              "fr-TD": "Êtes-vous le propriétaire de cette propriété ?",
            },
            type: "radiogroup",
            visibleIf: "{custom_text_91} == 'Parcelle batie'",
            name: "custom_bool_3",
            isRequired: required,
            choices: [
              {
                value: false,
                text: { en: "I do", "fr-TD": "Oui" },
              },
              {
                value: true,
                text: {
                  en: "I don’t ",
                  "fr-TD": "Non",
                },
              },
            ],
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD":
                "Quel est le nom et prénom du propriétaire de la garantie ?",
            },
            type: "text",
            visibleIf:
              "{custom_bool_3} == 'true' && {custom_text_91} != 'Avaliseur Salarié' && {custom_text_91} != 'Autres' ",
            name: "guarantor_3_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est son numéro de téléphone ?",
            },
            type: "text",
            visibleIf:
              "{custom_bool_3} == 'true' && {custom_text_91} != 'Avaliseur Salarié' && {custom_text_91} != 'Autres' ",
            name: "guarantor_3_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_3_phone"),
              expressions.same_phone_number("guarantor_3_phone"),
            ],
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD": "Quel est le nom et prénom du salarié ?",
            },
            type: "text",
            visibleIf: "{custom_text_91} == 'Avaliseur Salarié' ",
            name: "guarantor_3_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is the surname and first names of the holder of the guarantee?",
              "fr-TD": "Quel est le nom de votre avaliseur ?",
            },
            type: "text",
            visibleIf: "{custom_text_91} == 'Autres' ",
            name: "guarantor_3_first_name",
            isRequired: required,
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est le numéro de téléphone du salarié ?",
            },
            type: "text",
            visibleIf: "{custom_text_91} == 'Avaliseur Salarié' ",
            name: "guarantor_3_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_3_phone"),
              expressions.same_phone_number("guarantor_3_phone"),
            ],
          },
          {
            title: {
              en: "What is his telephone number",
              "fr-TD": "Quel est son numéro de téléphone ?",
            },
            type: "text",
            visibleIf: "{custom_text_91} == 'Autres' ",
            name: "guarantor_3_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_3_phone"),
              expressions.same_phone_number("guarantor_3_phone"),
            ],
          },
          {
            title: {
              en: "Where is your guarantee?",
              "fr-TD": "Où habite l'avaliseur salarié ?",
            },
            type: "panel",
            visibleIf: " {custom_text_91} == 'Avaliseur Salarié'",
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_117",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_118",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_119",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_120",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_121",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_122",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee?",
              "fr-TD": "Où habite votre avaliseur ?",
            },
            type: "panel",
            visibleIf: " {custom_text_91} == 'Autres'",
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_117",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_118",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_119",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_120",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_121",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_122",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où se situe votre garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_91} == 'Terrain nu' && {custom_bool_3} == 'false' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_117",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_118",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_119",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_120",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_121",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_122",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où se situe votre garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_91} == 'Parcelle batie' && {custom_bool_3} == 'false' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_117",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_118",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_119",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_120",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_121",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_122",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee situated?",
              "fr-TD": "Où habite le propriétaire de la garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_91} == 'Terrain nu' && {custom_bool_3} == 'true' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_117",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_118",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_119",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_120",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_121",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_122",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Where is your guarantee owner situated?",
              "fr-TD": "Où habite le propriétaire de la garantie ?",
            },
            type: "panel",
            visibleIf:
              " {custom_text_91} == 'Parcelle batie' && {custom_bool_3} == 'true' ",
            innerIndent: 1,
            elements: [
              {
                title: { en: "Country", "fr-TD": "Pays " },
                type: "text",
                name: "custom_text_117",
                isRequired: required,
              },
              {
                title: { en: "City", "fr-TD": "Ville " },
                type: "text",
                name: "custom_text_118",
                isRequired: required,
              },
              {
                title: { en: "Lot", "fr-TD": "Lot " },
                type: "text",
                name: "custom_text_119",
                isRequired: required,
              },
              {
                title: { en: "District", "fr-TD": "Quartier" },
                type: "text",
                name: "custom_text_120",
                isRequired: required,
              },
              {
                title: { en: "Village", "fr-TD": "Village " },
                type: "text",
                name: "custom_text_121",
                isRequired: required,
              },
              {
                title: { en: "Canton", "fr-TD": "Canton " },
                type: "text",
                name: "custom_text_122",
                isRequired: required,
              },
            ],
          },
          {
            title: {
              en: "Download the title deed",
              "fr-TD": "Téléchargez le titre  de propriété",
            },
            visibleIf:
              "{custom_bool_3} == 'false' && {custom_text_91} != 'Autres' && {custom_text_91} != 'Avaliseur Salarié' ",
            type: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: required,
            name: "file_12",
          },
          {
            title: {
              en: "Value",
              "fr-TD": "Valeur",
            },
            type: "text",
            name: "custom_float_43",
            isRequired: required,
            validators: [regexValidators.max_two_decimal_places()],
          },
        ],
      },
      {
        title: {
          en: "Total value",
          "fr-TD": "Valeur Totale",
        },
        description: {
          en: "This should be 150% of your loan",
          "fr-TD": "Cela devrait être 150% du montant de prêt demandée",
        },
        type: "expression",
        visibleIf:
          "{collateral_arrangements} == 'réelle' && {custom_integer_10} >= '1'",
        name: "custom_float_42",
        expression:
          "fixed(add(add({custom_float_43},{custom_float_44}),{custom_float_45}),2)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "How many guarantors do you need to add?",
          "fr-TD": "Combien de garanties voulez-vous ajouter ?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_126",
        visibleIf: "{collateral_arrangements} == 'caution_solidaire_du_groupe'",
        choices: [
          {
            value: "1",
            text: { en: "1", "es-MX": "1" },
          },
          {
            value: "2",
            text: { en: "2", "es-MX": "2" },
          },
        ],
      },
      {
        type: "panel",
        visibleIf: "{collateral_arrangements} == 'caution_solidaire_du_groupe'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: "What is the name of your number 1 guarantor?*",
              "fr-TD": "Quel est le nom de votre partenaire numéro 1 ?*",
            },
            type: "text",
            name: "guarantor_first_name",
            visibleIf: "{custom_text_126} >=1 ",
            isrequired: required,
          },
          {
            title: {
              en: "What is the phone number of your guarantor 1*",
              "fr-TD":
                "Quel est le numéro de téléphone de votre partenaire numéro 1 ?*",
            },
            type: "phone",
            name: "guarantor_phone",
            visibleIf: "{custom_text_126} >=1 ",
            isrequired: required,
            validators: [
              expressions.phone_en("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone"),
            ],
          },
          {
            type: "expression",
            name: "custom_text_127",
            visible: false,
            expression: `"partenaire numéro 1"`,
          },
          {
            title: {
              en: "What is the name of your number 2 guarantor?*",
              "fr-TD": "Quel est le nom de votre partenaire numéro 2 ?*",
            },
            type: "text",
            visibleIf: "{custom_text_126} == '2'",
            name: "guarantor_2_first_name",
            isRequired: required,
          },
          {
            type: "expression",
            name: "custom_text_128",
            visible: false,
            expression: `"partenaire numéro 2"`,
          },
          {
            title: {
              en: "What is the phone number of your avaliser 2*",
              "fr-TD":
                "Quel est le numéro de téléphone de votre partenaire numéro 2 ?*",
            },
            type: "phone",
            name: "guarantor_2_phone",
            validators: [
              expressions.phone_en("guarantor_2_phone"),
              expressions.same_phone_number("guarantor_2_phone"),
              {
                type: "expression",
                text: {
                  en: "Please make sure this number is not the same as your number",
                  "fr-TD":
                    "Assurez-vous que ce numéro n'est pas le même que parténaire 1*",
                },
                expression: `{collateral_arrangements} == 'caution_solidaire_du_groupe' && {guarantor_phone} != {guarantor_2_phone}`,
              },
            ],
            visibleIf: "{custom_text_126} == '2'",
            isRequired: required,
          },
        ],
      },
    ],
  },
  // Page 7: References
  {
    title: { en: "References", "fr-TD": "Références" },
    innerIndent: 1,
    name: "Les références",
    elements: [
      {
        html: {
          en: "Please list two references whom you are very close with( for exemple your next of kin)",
          "fr-TD":
            "Veuillez indiquer deux références auxquelles vous êtes très proche  (  par exemple plus proche parents)",
        },
        type: "html",
      },
      {
        title: { en: "Reference 1", "fr-TD": "Référence 1" },
        type: "panel",
        innerIndent: 1,
        name: "Référence 1",
        elements: [
          {
            title: {
              en: "Reference Full Name *",
              "fr-TD": "Nom complet de la référence*",
            },
            type: "text",
            name: "ref1_name",
            isRequired: required,
          },
          {
            title: {
              en: "Reference Mobile No. *",
              "fr-TD": "Numéro de téléphone de la référence*",
            },
            type: "text",
            inputType: "number",
            name: "ref1_phone",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
            validators: [expressions.phone_en("ref1_phone")],
          },
          {
            title: { en: "Country *", "fr-TD": "Pays*" },
            type: "text",
            name: "custom_text_34",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "City *", "fr-TD": "Ville*" },
            type: "text",
            name: "ref1_city",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "lot", "fr-TD": " Lot" },
            type: "text",
            name: "custom_text_35",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "Cartier", "fr-TD": "Quartier " },
            type: "text",
            name: "custom_text_36",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "Village", "fr-TD": "Village" },
            type: "text",
            name: "custom_text_37",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "Conton", "fr-TD": "Canton " },
            type: "text",
            name: "custom_text_38",
            visibleIf: "{ref1_name} != ''",
            isRequired: required,
          },
          {
            title: {
              en: "Indications",
              "fr-TD": " Fourni les Indications (itinéraire)",
            },
            type: "text",
            visibleIf: "{ref1_name} != ''",
            name: "custom_text_39",
            isRequired: required,
          },
          {
            title: {
              en: "What is the relation of [ Reference Name ] to you? * (Ex.  Parents  etc.)",
              "fr-TD":
                "Quel est votre relation avec la référence ? (Par exemple parents, etc.)*",
            },
            type: "text",
            visibleIf: "{ref1_name} != ''",
            name: "custom_text_40",
            isRequired: required,
          },
        ],
      },
      {
        title: { en: "Reference 2", "fr-TD": "Référence 2" },
        type: "panel",
        innerIndent: 1,
        name: "Référence 2",
        elements: [
          {
            title: {
              en: "Reference  Full Name *",
              "fr-TD": "Nom complet de la référence*",
            },
            type: "text",
            name: "ref2_name",
            isRequired: required,
          },
          {
            title: {
              en: "Reference Mobile No. *",
              "fr-TD": "Numéro de téléphone de la référence*",
            },
            type: "text",
            inputType: "number",
            name: "ref2_phone",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
            validators: [expressions.phone_en("ref2_phone")],
          },
          {
            title: { en: "Country *", "fr-TD": "Pays*" },
            type: "text",
            name: "custom_text_41",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "City *", "fr-TD": "Ville*" },
            type: "text",
            name: "ref2_city",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "lot", "fr-TD": " Lot" },
            type: "text",
            name: "custom_text_42",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "Cartier", "fr-TD": "Quartier " },
            type: "text",
            name: "custom_text_43",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "Village", "fr-TD": "Village" },
            type: "text",
            name: "custom_text_44",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: { en: "Conton", "fr-TD": "Canton " },
            type: "text",
            name: "custom_text_45",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: {
              en: "Indications",
              "fr-TD": " Fourni les Indications (itinéraire)",
            },
            type: "text",
            name: "custom_text_46",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
          {
            title: {
              en: "What is the relation of [ Reference Name ] to you? * (Ex.  Parents  etc.)",
              "fr-TD":
                "Quel est votre rapport avec la référence ? (Par exemple parents , etc.)*",
            },
            type: "text",
            name: "custom_text_47",
            visibleIf: "{ref2_name} != ''",
            isRequired: required,
          },
        ],
      },
    ],
  },
  // Page 8: Repayment Capacity
  {
    title: { en: "Repayment Capacity", "fr-TD": " Capacité de Remboursement" },
    innerIndent: 1,
    name: "Capacité de Remboursement",
    elements: [
      {
        title: {
          en: "Turnover - High",
          "fr-TD": "Chiffre d'Affaires - Hypothèse Haute",
        },
        description: {
          en: "Monthly Repayment Capacity (estimated by the applicant)",
          "fr-TD":
            "Capacité de Remboursement Mensuelle (estimée par le demandeur)",
        },
        name: "custom_jsonfield_6",
        valueName: "custom_jsonfield_6",
        type: "matrixdynamic",
        isRequired: required,
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Previous Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demand_precedente",
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total",
          "fr-TD": "Chiffre d'Affaires - Hypothèse Haute",
        },
        name: "custom_jsonfield_16",
        valueName: "custom_jsonfield_16",
        visibleIf: "'1'='0'",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_6}, 'demande_actuelle'),2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_6}, 'demand_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Retained Turnover - high ( current request )",
          "fr-TD":
            "Chiffre d'Affaires Retenu - Hypothèse Haute (Demande Actuelle) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'", // caluclation made inside here, made invisible, not removed
        name: "custom_float_18",
        expression:
          "fixed(sumInArray({custom_jsonfield_6}, 'demande_actuelle'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Retained Turnover - low (previous request)",
          "fr-TD":
            "Chiffre d'Affaires Retenu - Hypothèse Haute (Demande Précédente) :",
        },
        type: "expression",
        visibleIf: "'1'='0'", // calculation made inside here, made invisible, not removed
        name: "custom_float_19",
        expression:
          "fixed(sumInArray({custom_jsonfield_6}, 'demand_precedente'),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Turnover - Low",
          "fr-TD": "Chiffre d'Affaires - Hypothèse Basse",
        },
        description: {
          en: "Monthly Repayment Capacity (estimated by the applicant)",
          "fr-TD":
            "Capacité de Remboursement Mensuelle (estimée par le demandeur)",
        },
        name: "custom_jsonfield_8",
        valueName: "custom_jsonfield_8",
        type: "matrixdynamic",
        isRequired: required,
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demand_precedente",
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_greater_than_zero("demand_precedente"),
            ],
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total",
          "fr-TD": "Chiffre d'Affaires - Hypothèse Basse",
        },
        name: "custom_jsonfield_17",
        valueName: "custom_jsonfield_17",
        visibleIf: "'1'='0'",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",

            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_8}, 'demande_actuelle'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",

            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_8}, 'demand_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Retained Turnover - high (current)",
          "fr-TD":
            "Chiffre d'Affaires Retenu - Hypothèse Haute (Demande Actuelle) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'", // caluclation made inside here, made invisible, not removed
        name: "custom_float_20",
        expression:
          "fixed(sumInArray({custom_jsonfield_8}, 'demande_actuelle'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Retained Turnover - low (previous)",
          "fr-TD":
            "Chiffre d'Affaires Retenu - Hypothèse Basse (Demande Précédente) :",
        },
        type: "expression",
        visibleIf: "'1'='0'", // calculation made inside here, made invisible, not removed
        name: "custom_float_21",
        expression:
          "fixed(sumInArray({custom_jsonfield_8}, 'demand_precedente'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Retained Turnover average (current) ",
          "fr-TD": "Chiffre d'Affaires Retenu - Moyenne (Demande Actuelle) : ",
        },
        type: "expression",
        name: "custom_float_10",
        visibleIf: "'1'='0'",
        expression:
          "templateConditional({custom_float_18}, divide(add({custom_float_18},{custom_float_20}),2),1,{custom_float_20},'greater_than')",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Retained Turnover average (current) ",
          "fr-TD": "Chiffre d'Affaires Retenu - Moyenne (Demande Actuelle) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_10",
        expression:
          "templateConditional({custom_float_20},{custom_float_10},1,{custom_float_18},'greater_than')",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Retained Turnover Average (previous)",
          "fr-TD": "Chiffre d'Affaires Retenu - Moyenne (Demande Précédente) :",
        },
        type: "expression",
        name: "custom_float_11",
        visibleIf: "'1'='0'",
        expression:
          "templateConditional({custom_float_19}, divide(add({custom_float_19},{custom_float_21}),2),1,{custom_float_21},'greater_than')",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Retained Turnover Average (previous)",
          "fr-TD": "Chiffre d'Affaires Retenu - Moyenne (Demande Précédente) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_11",
        expression:
          "templateConditional({custom_float_21},{custom_float_11},1,{custom_float_19},'greater_than')",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total",
          "fr-TD": "Chiffre d'Affaires Retenu",
        },
        name: "custom_jsonfield_18",
        valueName: "custom_jsonfield_18",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression: "fixed(add({custom_float_10},0),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression: "fixed(add({custom_float_11}, 0),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Expenses",
          "fr-TD": "Dépenses",
        },
        name: "custom_jsonfield_7",
        valueName: "custom_jsonfield_7",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        isRequired: required,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Title ",
              "fr-TD": "Articles",
            },
            name: "articles",

            cellType: "dropdown",
            choices: [
              {
                value: "Achats Marchandises et Matières Premières",
                text: {
                  en: "Purchasing goods and raw materials",
                  "fr-TD": "Achats Marchandises et Matières Premières",
                },
              },
              {
                value: "Transport / Frais d’Approvisionnement",
                text: {
                  en: "Transport/Supply costs",
                  "fr-TD": "Transport / Frais d’Approvisionnement",
                },
              },
            ],
          },
          {
            title: {
              en: "Current Request *",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request *",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_greater_than_zero("demande_precedente"),
            ],
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total of expenses ( Current )",
          "fr-TD": "Total Dépenses ( Demande Actuelle ) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_12",
        expression:
          "fixed(sumInArray({custom_jsonfield_7}, 'demande_actuelle'),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total of expenses ( previous )",
          "fr-TD": "Total Dépenses ( Demande Précédente )",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_13",
        expression:
          "fixed(sumInArray({custom_jsonfield_7}, 'demande_precedente'),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total of expenses",
          "fr-TD": "Total Dépenses",
        },
        name: "custom_jsonfield_19",
        valueName: "custom_jsonfield_19",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_7}, 'demande_actuelle'))",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_7}, 'demande_precedente'))",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Gross Margin - actual",
          "fr-TD": "Marge Brute (Demande Actuelle) :",
        },
        type: "expression",
        name: "custom_float_14",
        visibleIf: "'1'='0'",
        expression: `fixed(subtract({custom_float_10}, {custom_float_12}), 2,true)`,
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Gross Margin - previous",
          "fr-TD": "Marge Brute (Demande Précédente):",
        },
        type: "expression",
        name: "custom_float_15",
        visibleIf: "'1'='0'",
        expression: `fixed(subtract({custom_float_11}, {custom_float_13}), 2,true)`,
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Gross Margin",
          "fr-TD": "Marge Brute",
        },
        name: "custom_jsonfield_20",
        valueName: "custom_jsonfield_20",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(subtract({custom_float_10}, {custom_float_12}), 2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(subtract({custom_float_11}, {custom_float_13}), 2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Margin rate - actual",
          "fr-TD": "Taux de marge (Demande Actuelle):",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_16",
        expression: `fixed(divide({custom_float_14}, {custom_float_10}), 2,true)`,
        displayStyle: "percent",
      },
      {
        title: {
          en: "Margin rate - previous",
          "fr-TD": "Taux de marge (Demande Précédente):",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_17",
        expression: `fixed(divide({custom_float_15}, {custom_float_11}), 2, true)`,
        displayStyle: "percent",
      },
      {
        title: {
          en: "Margin Rate",
          "fr-TD": "Taux de marge",
        },
        name: "custom_jsonfield_21",
        valueName: "custom_jsonfield_21",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(divide({custom_float_14}, {custom_float_10}), 2, true)",
            displayStyle: "percent",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(divide({custom_float_15}, {custom_float_11}), 2, true)",
            displayStyle: "percent",
          },
        ],
      },
      {
        title: {
          en: "Fixed charges",
          "fr-TD": "Charges Fixes",
        },
        name: "custom_jsonfield_9",
        valueName: "custom_jsonfield_9",
        type: "matrixdynamic",
        isRequired: required,
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Title ",
              "fr-TD": "Articles",
            },
            name: "articles",
            cellType: "dropdown",
            choices: [
              {
                value: "Loyer (local commercial)",
                text: {
                  en: "Rent ( commercial Premises )",
                  "fr-TD": "Loyer (local commercial)",
                },
              },
              {
                value: "Impôts et Taxes",
                text: { en: "Taxes", "fr-TD": "Impôts et Taxes" },
              },
              {
                value: "Salaires",
                text: { en: "Salaries", "fr-TD": "Salaires" },
              },
              {
                value: "Réparations et entretien",
                text: {
                  en: "Repair and maintenance",
                  "fr-TD": "Réparations et entretien",
                },
              },
              {
                value: "Eau, Électricité",
                text: { en: "Water/Electicity", "fr-TD": "Eau, Électricité" },
              },
              {
                value: "Autres Dépenses Activité",
                text: {
                  en: "Other activity expenses",
                  "fr-TD": "Autres Dépenses Activité",
                },
              },
            ],
          },
          {
            title: {
              en: "Current Request *",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request *",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_greater_than_zero("montant_revenu"),
            ],
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total fixed charges ( current )",
          "fr-TD": "Total Charges Fixes (Demande Actuelle) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_22",
        expression:
          "fixed(sumInArray({custom_jsonfield_9}, 'demande_actuelle'),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total fixed charges ( previous )",
          "fr-TD": "Total Charges Fixes (Demande Précédente) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_23",
        expression:
          "fixed(sumInArray({custom_jsonfield_9}, 'demande_precedente'),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "total fixed charges",
          "fr-TD": "Total Charges Fixes",
        },
        name: "custom_jsonfield_22",
        valueName: "custom_jsonfield_22",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_9}, 'demande_actuelle'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_9}, 'demande_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Grand total ( current )",
          "fr-TD": "Total général (Demande Actuelle) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_24",
        expression: "fixed(add({custom_float_12},{custom_float_22}),2)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Within how many months do you wish to repay your loan? *",
          "fr-TD":
            "Dans combien de mois souhaitez-vous rembourser votre prêt ? *",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_25",
        expression: "fixed(add({custom_float_13},{custom_float_23}),2)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Grand Total",
          "fr-TD": "Total Général",
        },
        name: "custom_jsonfield_23",
        valueName: "custom_jsonfield_23",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(add({custom_float_12},{custom_float_22}),2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(add({custom_float_13},{custom_float_23}),2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Cash Flow - current",
          "fr-TD": "Cash Flow (Demande Actuelle):",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_26",
        expression:
          "fixed(subtract({custom_float_10},{custom_float_24}),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Cash Flow - previous",
          "fr-TD": "Cash Flow (Demande Précédente):",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_27",
        expression:
          "fixed(subtract({custom_float_11},{custom_float_25}),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Cash Flow",
          "fr-TD": "Cash Flow",
        },
        name: "custom_jsonfield_24",
        valueName: "custom_jsonfield_24",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(subtract({custom_float_10},{custom_float_24}),2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(subtract({custom_float_11},{custom_float_25}),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Out of activity expenses",
          "fr-TD": "Dépenses Hors Activité",
        },
        name: "custom_jsonfield_10",
        valueName: "custom_jsonfield_10",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Title ",
              "fr-TD": "Articles",
            },
            name: "articles",
            cellType: "dropdown",
            choices: [
              {
                value: "Loyer Domicile",
                text: { en: "Home Rent", "fr-TD": "Loyer Domicile" },
              },
              {
                value: "Dépenses Familiales",
                text: { en: "Family Expenses", "fr-TD": "Dépenses Familiales" },
              },
            ],
          },
          {
            title: {
              en: "Current Request *",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request *",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_greater_than_zero("montant_revenu"),
            ],
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "revenu_document",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total out of activity expenses ( current )",
          "fr-TD": "Total Dépenses Hors Activité (Demande Actuelle) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_28",
        expression:
          "fixed(sumInArray({custom_jsonfield_10}, 'demande_actuelle'))",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total des dépenses hors activité ( previous )",
          "fr-TD": "Total Dépenses Hors Activité (Demande Précédente) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_29",
        expression:
          "fixed(sumInArray({custom_jsonfield_10}, 'demande_precedente'))",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total out of activity expenses",
          "fr-TD": "Total Dépénses Hors Activité",
        },
        name: "custom_jsonfield_25",
        valueName: "custom_jsonfield_25",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_10}, 'demande_actuelle'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_10}, 'demande_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Repayment Capacity",
          "fr-TD": "Capacité de Remboursement :",
        },
        type: "expression",
        name: "custom_float_30",
        expression:
          "fixed(subtract({custom_float_26},{custom_float_28}),2,true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Other net income",
          "fr-TD": "Autres Revenus Nets",
        },
        name: "custom_jsonfield_11",
        valueName: "custom_jsonfield_11",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Article",
              "fr-TD": "Articles",
            },
            name: "articles",
            cellType: "text",
            inputType: "text",
          },
          {
            title: {
              en: "Current Request *",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request *",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_greater_than_zero("montant_revenu"),
            ],
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total other net income ( current )",
          "fr-TD": "Total Autres Revenus Nets (Demande Actuelle) :",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_31",
        expression:
          "fixed(sumInArray({custom_jsonfield_11}, 'demande_actuelle'))",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total other net income ( previous )",
          "fr-TD": "Total Autres Revenus Nets (Demande Précédente)",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_32",
        expression:
          "fixed(sumInArray({custom_jsonfield_11}, 'demande_precedente'))",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total other net income",
          "fr-TD": "Total Autres Revenus Nets",
        },
        name: "custom_jsonfield_26",
        valueName: "custom_jsonfield_26",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_11}, 'demande_actuelle'),2,true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_11}, 'demande_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: " Do you have any debts with other MFIs or banks?",
          "fr-TD": "Avez-vous des dettes auprès d'autres IMF ou Banques ?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_100",
        choices: [
          { value: "oui", text: { en: "Yes", "fr-TD": "Oui" } },
          { value: "non", text: { en: "No", "fr-TD": "Non" } },
        ],
      },
      {
        title: {
          en: "Debts with other MFIs or banks?",
          "fr-TD": "Dettes au niveau des autres IMF ou Banques",
        },
        name: "custom_jsonfield_12",
        valueName: "custom_jsonfield_12",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        visibleIf: "{custom_text_100} == 'oui'",
        isRequired: required,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Name of the institution",
              "fr-TD": "Nom de l'institution",
            },
            name: "nom_etablissment",
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Amount",
              "fr-TD": "Montant",
            },
            name: "montant",
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "revenu_document",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total payables to other banks or mfi's :",
          "fr-TD": "Total Dettes Auprès d'Autres IMF ou Banques :",
        },
        type: "expression",
        name: "custom_float_33",
        visibleIf: "{custom_text_100} == 'oui'",
        expression:
          "fixed(sumInArray({custom_jsonfield_12}, 'montant'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
    ],
  },
  // Page 9: Monthly Cash Flow
  {
    title: { en: "Balance sheet analysis", "fr-TD": "Analyse Bilan" },
    innerIndent: 1,
    name: "Analyse Bilan",
    elements: [
      {
        title: {
          en: "Professional actives",
          "fr-TD":
            " Actifs professionnels (fonds de commerce) - Actifs à long / court terme",
        },
        name: "custom_jsonfield_13",
        valueName: "custom_jsonfield_13",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Actives for",
              "fr-TD": "Actifs à",
            },
            name: "elements",
            cellType: "dropdown",
            choices: [
              {
                value: "Long Terme",
                text: { en: "Long Time", "fr-TD": "Long Terme" },
              },
              {
                value: "Court Terme",
                text: { en: "Short Time", "fr-TD": "Court Terme" },
              },
            ],
          },
          {
            title: {
              en: "Title ",
              "fr-TD": "Articles",
            },
            name: "articles",
            cellType: "dropdown",
            choices: [
              {
                value: "Local/Terrain",
                text: { en: "Local/Terrain", "fr-TD": "Local/Terrain" },
                visibleIf: "{row.elements}=='Long Terme'",
              },
              {
                value: "Equipment",
                text: { en: "Equipment", "fr-TD": "Equipment" },
                visibleIf: "{row.elements}=='Long Terme'",
              },
              {
                value: "Stock (marchandises)",
                text: { en: "Stocks", "fr-TD": "Stock (marchandises) " },
                visibleIf: "{row.elements} == 'Court Terme'",
              },
              {
                value: "Créances Clients",
                text: {
                  en: "Customer Receivables",
                  "fr-TD": "Créances Clients",
                },
                visibleIf: "{row.elements} == 'Court Terme'",
              },
              {
                value: "Liquidités",
                text: { en: "Liquidities", "fr-TD": "Liquidités" },
                visibleIf: "{row.elements} == 'Court Terme'",
              },
              {
                value: "Autres",
                text: { en: "Others", "fr-TD": "Autres" },
                visibleIf: "{row.elements} == 'Court Terme'",
              },
            ],
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_greater_than_zero("montant_revenu_mensuel"),
            ],
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total actives - actual :",
          "fr-TD": "Total Actifs ( Demande Actuelle ) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_34",
        expression:
          "fixed(sumInArray({custom_jsonfield_13}, 'demande_actuelle'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total actives - previous :",
          "fr-TD": "Total Actifs ( Demande Précédente ) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_35",
        expression:
          "fixed(sumInArray({custom_jsonfield_13}, 'demande_precedente'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total Actives",
          "fr-TD": "Total Actifs",
        },
        name: "custom_jsonfield_27",
        valueName: "custom_jsonfield_27",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_13}, 'demande_actuelle'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_13}, 'demande_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Passive - Short and Long Term",
          "fr-TD": "Passif - Court / Moyen et Long Terme",
        },
        name: "custom_jsonfield_14",
        valueName: "custom_jsonfield_14",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Title ",
              "fr-TD": "Articles",
            },
            name: "articles",
            cellType: "dropdown",
            choices: [
              {
                value: "Dettes Fournisseurs",
                text: { en: "Payables", "fr-TD": "Dettes Fournisseurs" },
              },
              {
                value: "Autres Dettes à Court Terme",
                text: {
                  en: "Other short-term payables",
                  "fr-TD": "Autres Dettes à Court Terme",
                },
              },
              {
                value: "Dettes à Moyen et Long Terme",
                text: {
                  en: "Payables for now and Long term",
                  "fr-TD": "Dettes à Moyen et Long Terme",
                },
              },
              {
                value: "Autres Passifs à Long Terme",
                text: {
                  en: "Other payables",
                  "fr-TD": "Autres Passifs à Long Terme",
                },
              },
            ],
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: {
              en: "Previous Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total passives - actual :",
          "fr-TD": "Total Dettes ( Demande Actuelle ) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_36",
        expression:
          "fixed(sumInArray({custom_jsonfield_14}, 'demande_actuelle'))",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total passives - previous :",
          "fr-TD": "Total Dettes ( Demande Précédente ) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_37",
        expression:
          "fixed(sumInArray({custom_jsonfield_14}, 'demande_precedente'))",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total Passives",
          "fr-TD": "Total Dettes",
        },
        name: "custom_jsonfield_28",
        valueName: "custom_jsonfield_28",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_14}, 'demande_actuelle'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_14}, 'demande_precedente'))",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Owned Fundes",
          "fr-TD": "Fonds Propres",
        },
        name: "custom_jsonfield_15",
        valueName: "custom_jsonfield_15",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        addRowText: { en: "Add", "fr-TD": "Ajouter" },
        columns: [
          {
            title: {
              en: "Title ",
              "fr-TD": "Articles",
            },
            name: "articles",
            cellType: "dropdown",
            choices: [
              {
                value: "Capital Social",
                text: { en: "Social Capital", "fr-TD": "Capital Social" },
              },
              {
                value: "Béneficés Non Distribués",
                text: {
                  en: "Retained profit",
                  "fr-TD": "Béneficés Non Distribués",
                },
              },
              {
                value: "Autres Fonds Propres",
                text: {
                  en: "Other owned funds",
                  "fr-TD": "Autres Fonds Propres",
                },
              },
            ],
          },
          {
            title: { en: "Current Request", "fr-TD": "Demande Actuelle" },
            name: "demande_actuelle",
            isRequired: required,
            cellType: "text",
            inputType: "number",
            validators: [
              expressions.amount_equal_or_greater_than_zero("demande_actuelle"),
            ],
          },
          {
            title: { en: "Previous Request", "fr-TD": "Demande Précédente" },
            name: "demande_precedente",
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Attachment",
              "fr-TD": "Annexer",
            },
            name: "demande_annexer",
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
          },
        ],
      },
      {
        title: {
          en: "Total capital - actual:",
          "fr-TD": "Total Capitaux Propres (Demande Actuelle) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_38",
        expression:
          "fixed(sumInArray({custom_jsonfield_15}, 'demande_actuelle'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total Capital - previous :",
          "fr-TD": "Total Capitaux Propres( Demande Précédente ) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_39",
        expression:
          "fixed(sumInArray({custom_jsonfield_15}, 'demande_precedente'),2, true)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total Capital",
          "fr-TD": "Total Capitaux Propres",
        },
        name: "custom_jsonfield_29",
        valueName: "custom_jsonfield_29",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_15}, 'demande_actuelle'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression:
              "fixed(sumInArray({custom_jsonfield_15}, 'demande_precedente'),2, true)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
      {
        title: {
          en: "Total liabilities - actual:",
          "fr-TD": "Total Passifs (Demande Actuelle) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_40",
        expression: "fixed(add({custom_float_36},{custom_float_38}),2)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total Liabilities - previous :",
          "fr-TD": "Total Passifs( Demande Précédente ) : ",
        },
        type: "expression",
        visibleIf: "'1'='0'",
        name: "custom_float_41",
        expression: "fixed(add({custom_float_37},{custom_float_39}),2)",
        displayStyle: "currency",
        currency: "XAF",
      },
      {
        title: {
          en: "Total Liabilities",
          "fr-TD": "Total Passifs",
        },
        name: "custom_jsonfield_30",
        valueName: "custom_jsonfield_30",
        type: "matrixdynamic",
        maxRowCount: 1,
        minRowCount: 1,
        rowCount: 1,
        columns: [
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Actuelle",
            },
            name: "demande_actuelle",
            cellType: "expression",
            expression: "fixed(add({custom_float_36},{custom_float_38}),2)",
            displayStyle: "currency",
            currency: "XAF",
          },
          {
            title: {
              en: "Current Request",
              "fr-TD": "Demande Précédente",
            },
            name: "demande_precedente",
            cellType: "expression",
            expression: "fixed(add({custom_float_37},{custom_float_39}),2)",
            displayStyle: "currency",
            currency: "XAF",
          },
        ],
      },
    ],
  },
  // Page 10: Bank account info
  {
    title: {
      en: "Bank account info",
      "fr-TD": "Informations de compte bancaire",
    },
    innerIndent: 1,
    name: "Information de compte bancaire",
    elements: [
      {
        title: {
          en: "Do you have a bank account with Finadev? *",
          "fr-TD": "Avez-vous un compte chez FINADEV ?*",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_55",
        choices: [
          { value: "oui", text: { en: "yes", "fr-TD": "Oui" } },
          { value: "non", text: { en: "no", "fr-TD": "Non" } },
        ],
      },
      {
        title: {},
        name: "panel_bank_account",
        type: "panel",
        innerIndent: 1,
        visibleIf: "{custom_text_55} == 'oui'",
        elements: [
          {
            title: {
              en: "What is the opening date of the account with Finadev?",
              "fr-TD":
                " Quelle est la date d’ouverture du compte chez Finadev ?",
            },
            type: "text",

            name: "custom_text_56",
            isRequired: required,
            inputType: "date",
          },
          {
            title: {
              en: "Which type of  Bank account?",
              "fr-TD": "Quel est le type de votre compte ?",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_57",
            choices: [
              {
                value: "Dépôt_à_terme",
                text: { en: "Term", "fr-TD": "Dépôt à terme" },
              },
              { value: "Courant", text: { en: "Current", "fr-TD": "Courant" } },
              {
                value: "Epargne",
                text: { en: "Savings", "fr-TD": "Epargne" },
              },
            ],
          },
          {
            title: {
              en: "Bank Account No. *",
              "fr-TD": "Quel est votre code client ?",
            },
            type: "text",
            inputType: "number",
            name: "custom_text_58",
            isRequired: required,
          },
          {
            title: {
              en: "Bank Account Balance as of today *",
              "fr-TD": "Solde actuel du compte",
            },
            type: "text",
            inputType: "number",
            name: "custom_text_59",
            isRequired: required,
          },
        ],
      },
      {
        title: {
          en: "Do you have another bank account?",
          "fr-TD": "Avez-vous un autre compte bancaire ?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_60",
        choices: [
          { value: "oui", text: { en: "yes", "fr-TD": "Oui" } },
          { value: "non", text: { en: "no", "fr-TD": "Non" } },
        ],
      },
      {
        title: {},
        name: "panel_bank_account",
        type: "panel",
        innerIndent: 1,
        visibleIf: "{custom_text_60} == 'oui'",
        elements: [
          {
            title: {
              en: "If yes what is the name of Bank",
              "fr-TD": "Si oui, quel est le nom de la Banque ?",
            },
            type: "text",
            name: "custom_text_74",
          },
          {
            title: { en: "Bank Account No. *", "fr-TD": "Numéro de compte " },
            type: "text",
            inputType: "number",
            name: "custom_text_61",
            isRequired: required,
          },
          {
            title: {
              en: "Bank Account Balance as of today  *",
              "fr-TD": "Solde actuel du compte",
            },
            type: "text",
            inputType: "number",
            name: "custom_text_62",
            isRequired: required,
          },
          {
            title: {
              en: "Please upload the last three months bank statements - number 1 ",
              "fr-TD":
                "Veuillez télécharger vos relevés bancaires des  trois  derniers mois - numéro 1",
            },

            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_16",
          },
          {
            title: {
              en: "Please upload the last three months bank statements - number 2  ",
              "fr-TD":
                "Veuillez télécharger vos relevés bancaires des  trois  derniers mois - numéro 2",
            },

            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_17",
          },
          {
            title: {
              en: "Please upload the last three months bank statements - number 3 ",
              "fr-TD":
                "Veuillez télécharger vos relevés bancaires des  trois  derniers mois - numéro 3",
            },

            type: "file_uploader",
            accept: "image/*,application/pdf",
            name: "file_18",
          },
        ],
      },
    ],
  },
  // Page 11: Job Information
  {
    title: { en: "Job information", "fr-TD": "Information d'emploi" },
    innerIndent: 1,
    name: "Information d'emploi",
    elements: [
      {
        title: {
          en: "Do you have a current job? *",
          "fr-TD": "Avez-vous actuellement un emploi ?*",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_64",
        choices: [
          { value: "oui", text: { en: "yes", "fr-TD": "Oui" } },
          { value: "non", text: { en: "no", "fr-TD": "Non" } },
        ],
      },
      {
        title: {
          en: "Which type of work?",
          "fr-TD": " Quel est le type de votre emploi ?",
        },
        type: "dropdown",
        visibleIf: "{custom_text_64} == 'oui'",
        isRequired: required,
        name: "custom_text_65",
        choices: [
          {
            value: "Temporary",
            text: { en: "temporary", "fr-TD": "Temporaire" },
          },
          {
            value: "Permanent",
            text: { en: " permanent", "fr-TD": " Permanent" },
          },
        ],
      },
      {
        title: {
          en: "What is your job position?",
          "fr-TD": "Quel est votre poste ?",
        },
        type: "text",
        visibleIf: "{custom_text_64} == 'oui'",
        name: "custom_text_66",
      },
      {
        title: {
          en: "What is your company name?",
          "fr-TD":
            "Quel est le nom de l'entreprise pour laquelle vous travaillez ?",
        },
        type: "text",
        visibleIf: "{custom_text_64} == 'oui'",
        name: "custom_text_67",
      },
      {
        title: {
          en: "Upload a copy of  employment contract & work certificate / certificate if private sector employees",
          "fr-TD":
            " Télécharger le contrat de travail et attestation /certificat de travail si salariée dans le lecteur privé",
        },

        type: "file_uploader",
        visibleIf: "{custom_text_64} == 'oui'",
        accept: "image/*,application/pdf",
        name: "custom_text_68",
      },
      {
        title: {
          en: "What is your job Phone No.?",
          "fr-TD": "Quel est votre téléphone professionnel ?",
        },
        type: "text",
        visibleIf: "{custom_text_64} == 'oui'",
        inputType: "number",
        name: "custom_text_69",
      },
      {
        title: {
          en: "What is the address of your current work? ",
          "fr-TD": "Quelle est l'adresse de votre travail ?",
        },
        type: "text",
        visibleIf: "{custom_text_64} == 'oui'",
        name: "custom_text_82",
      },
      {
        title: {
          en: "What is your monthly income?",
          "fr-TD": "Quel est votre salaire mensuel ?",
        },
        type: "text",
        visibleIf: "{custom_text_64} == 'oui'",
        name: "custom_text_71",
      },
      {
        title: {
          en: "Please attach your salary slip.",
          "fr-TD": "Veuillez télécharger votre bulletin de salaire",
        },

        type: "file_uploader",
        visibleIf: "{custom_text_64} == 'oui'",
        accept: "image/*,application/pdf",
        name: "custom_text_72",
      },
    ],
  },
];
