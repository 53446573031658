import env from "../../env";

const required = env.ENDPOINT !== "local";

export const cooperative_rwanda_direct_model = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,

  completeText: { en: "Submit information", rw: "Ohereza amakuru" },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Guarantor/Accountant acceptance
    {
      title: { en: "Guarantor/Accountant acceptance", rw: "Kwemera ingwate" },
      name: "refused_page",
      elements: [
        {
          title: {
            en:
              "Welcome {guarantor_first_name}, you are invited to be the guarantor/cooperative of {user_first_name} for a loan amount of {amount} RWF. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
            rw:
              "Murakaza neza {guarantor_first_name} mutumiwe kuba ingwate ya {user_first_name} kunguzanyo ingana naya mafaranga {amount} RWF kuzuza ubu butumire, murabazwa amwe mumakuru aberekeyeho. Turabasaba guhitamo niba ubutumire mubwemeye, cyangwa mubuhakanye. Niba mufite ikibazo mwatumenyesha.",
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: "I accept",
                rw: "Ndabyemeye",
              },
            },
            {
              value: true,
              text: {
                en: "I don't accept",
                rw: "Simbyemeye",
              },
            },
          ],
        },
          {
          title: {
            en: "Guarantor Full Name (cooperative) *",
            rw: "Izina rya koperative *",
          },
          name: "cooperative_name",
          type: "expression",
          visible: false,
          expression: "{cooperative.name}"
        },
        {
          title: {
            en: "Legal registration number",
            rw: "Nimero iranga koperative",
          },
          name: "legal_registration_number",
          type: "expression",
          visible: false,
          expression: "{cooperative.legal_registration_number}"
        },
        {
          title: {
            en: 'Please upload RCA certificate',
            rw: "Shyiraho icyangombwa RCA",
          },
          name: "cooperative_rca_certificate",
          type: "expression",
          visible: false,
          expression: "{cooperative.rca_certificate}",
        },
        {
          title: {
            en: "Name of the president of the cooperative *",
            rw: "Izina rya perezida wa koperative yawe *",
          },
          name: "guarantor_first_name",
          type: "expression",
          visible: false,
          expression: "{cooperative.president_name}"

        },
        {
          title: {
            en: "President of cooperative No*",
            rw: "Nimero ya perezida wa koperative *",
          },
          name: "guarantor_phone",
          type: "expression",
          visible: false,
          expression: "{cooperative.president_phone}"
        },
        {
          title: {
            en: "Your Personal Details",
            rw: "Umwirondoro wawe",
          },
          name: "details_applicant",
          readOnly: "false",
          type: "panel",
          innerIndent: 1,
          visible: false,
          elements: [
            {
              title: {
                en: "Your full name",
                rw: "Amazina yombi ",
              },
              name: "advisor_name",
              type: "expression",
              expression: "{cooperative.advisor_name}"
            },
            {
              title: {
                en: "Your job title",
                rw: "Ushinzwe iki muri koperative",
              },
              name: "advisor_job_title",
              type: "expression",
              expression: "{cooperative.advisor_job_title}"
            },
            {
              title: {
                en: "Your ID Number",
                rw: "Nimero y'indangamuntu",
              },
              name: "advisor_id",
              type: "expression",
              expression: "{cooperative.advisor_id}"
            },
            {
              title: {
                en: "Your Phone Number",
                rw: "Telefone",
              },
              name: "advisor_phone_number",
              type: "expression",
              expression: "{cooperative.advisor_phone}"
            },
          ],
        }
      ],
    },
    // Page 2: Provided Production Information
    // CARROTS
    {
      title: { en: "Provided Production Information", rw: "Amakuru y'umusaruro yatanzwe" },
      description: {
        en: "Production information provided by the applicant",
        rw: "Amakuru y'umusaruro yatwanzwe n'usaba inguzanyo",
      },
      visibleIf: "{loan_purpose} == 'carrots'",
      name: "provided_production_information",
      elements: [
        {
          title: {
            en: 'Season 1',
            rw: "Igihembwe cya 1",
          },
          name: 'agricultural_season_1',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Cultivated Land of the selected crop (hectare)',
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
            },
            {
              name: 'supply_volume_kg_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Production of the selected crop in KGs',
                rw: "Ingano y'umusaruro kugihigwa wahisemo muri kilogarama",
              },
            },
            {
              name: 'variety_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: 'price_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price of the selected crop/kg in RWF',
                rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
              },
            },
            {
              name: 'sales_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales of selected crop in RWF',
                rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
              },
            },
          ],
        },
        {
          title: {
            en: 'Season 2',
            rw: "Igihembwe cya 2",
          },
          name: 'agricultural_season_2',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Cultivated land of the selected crop in hectare',
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare ",
              },
            },
            {
              name: 'supply_volume_kg_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Production of the selected crop in KG',
                rw: "Ingano y'umusaruro kugihigwa wahisemo  muri kilogarama",
              },
            },
            {
              name: 'variety_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: 'price_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price of the selected crop/kg in RWF ',
                rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
              },
            },
            {
              name: 'sales_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales of the selected crop in Rwf',
                rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
              },
            },
          ],
        },
        {
          title: {
            en: 'Information on current season of the selected crop',
            rw: 'Amakuru ku gihembwe  ugiye guhinga',
          },
          name: 'current_agricultural_season',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected Cultivated land of the selected crop in hectare',
                rw: "Ingano y'ubuso uteganya guhinga muri hegitare",
              },
            },
            {
              name: 'supply_volume_kg_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected production of selected crop in KG',
                rw: "Ingano y'umusaruro uteganyijwe muri kilogarama",
              },
            },
            {
              name: 'variety_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Which variety of potato will you cultivate?',
                rw: "Ubwoko uteganya guhinga",
              },
            },
            {
              name: 'price_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected price of selected crop/kg in RWF',
                rw: "Igiciro uteganya kugurishirizaho (RWF)?",
              },
            },
            {
              name: 'sales_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Expected sales of selected crop in Rwf',
                rw: "Amafaranga yose uteganya kwinjiza (RWF)",
              },
            },
          ],
        },
      ]
    },
    // CHILI AND POTATOES
    {
      title: { en: "Provided Production Information", rw: "Amakuru y'umusaruro yatanzwe" },
      description: {
        en: "Production information provided by the applicant",
        rw: "Amakuru y'umusaruro yatwanzwe n'usaba inguzanyo",
      },
      visibleIf: "{loan_purpose} == 'chili' or {loan_purpose} == 'potatoes'",
      name: "provided_production_information",
      elements: [
        {
          title: {
            en: 'Information related to season 1',
            rw: "Amakuru ku gihembwe cya 1",
          },
          name: 'agricultural_season_1',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Irish Potato Cultivated Land size (hectare) - potatoes',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare) - potatoes ",
              },
            },
            {
              name: 'supply_volume_kg_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama",
              },
            },
            {
              name: 'variety_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price/kg in RWF',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf',
              },
            },
            {
              name: 'sales_season_1',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf',
              },
            },
          ],
        },
        {
          title: {
            en: 'Information related to season 2',
            rw: "Amakuru ku gihembwe cya 2",
          },
          name: 'agricultural_season_2',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Irish Potato Cultivated Land size (hectare)',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare)",
              },
            },
            {
              name: 'supply_volume_kg_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama",
              },
            },
            {
              name: 'variety_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Price/kg in RWF 2',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf2',
              },
            },
            {
              name: 'sales_season_2',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf',
              },
            },
          ],
        },
        {
          title: {
            en: 'Fill the current agriculture season',
            rw: 'Andika amakuru kugihembwe ugiye guhinga',
          },
          name: 'current_agricultural_season',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'What size of land will you cultivate (hectare)?',
                rw: 'Uzahinga kubuso bungana iki (hegitare)?',
              },
            },
            {
              name: 'variety_current_season',
              type: 'text',
              readOnly: true,
              title: {
                en: 'Which variety of potato will you cultivate?',
                rw: "Uzahinga ubuhe bwoko bw'ibirayi?",
              },
            },
          ],
        },
      ],
    },
    // TEA
    {
      title: { en: "Provided Production Information", rw: "Amakuru y'umusaruro yatanzwe" },
      description: {
        en: "Production information provided by the applicant",
        rw: "Amakuru y'umusaruro yatwanzwe n'usaba inguzanyo",
      },
      visibleIf: "{loan_purpose} == 'tea'",
      name: "provided_production_information",
      elements: [
        {
          title: {
            en: "Month 1",
            rw: "Ukwezi kwa 1",
          },
          name: "agricultural_season_1",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_1",
              type: "text",
              readOnly: true,
              title: {
                en: "Cultivated Land of the selected crop (hectare)",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
            },
            {
              name: "supply_volume_kg_season_1",
              type: "text",
              readOnly: true,
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuwe kuruganda muri Kilogarama",
              },
            },
            {
              name: "variety_season_1",
              type: "text",
              readOnly: true,
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_1",
              type: "text",
              readOnly: true,
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe nuruganda ku kilo kimwe",
              },
            },
            {
              name: "sales_season_1",
              type: "expression",
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe n'uruganda",
              },
              expression: "{supply_volume_kg_season_1} * {price_season_1}"
            },
          ],
        },
        {
          title: {
            en: "Month 2",
            rw: "Ukwezi kwa 2",
          },
          name: "agricultural_season_2",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_2",
              type: "text",
              readOnly: true,
              title: {
                en: "Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
            },
            {
              name: "supply_volume_kg_season_2",
              type: "text",
              readOnly: true,
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuwe kuruganda muri Kilogarama",
              },
            },
            {
              name: "variety_season_2",
              type: "text",
              readOnly: true,
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_2",
              type: "text",
              readOnly: true,
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe nuruganda ku kilo kimwe",
              },
            },
            {
              name: "sales_season_2",
              type: "expression",
              readOnly: true,
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe n'uruganda",
              },
            },
          ],
        },
        {
          title: {
            en: "Month 3",
            rw: "Ukwezi kwa 3",
          },
          name: "agricultural_season_3",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_3",
              type: "text",
              readOnly: true,
              title: {
                en: "Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
            },
            {
              name: "supply_volume_kg_season_3",
              type: "text",
              readOnly: true,
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuwe kuruganda muri Kilogarama",
              },
            },
            {
              name: "variety_season_3",
              type: "text",
              readOnly: true,
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_3",
              type: "text",
              readOnly: true,
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe nuruganda ku kilo kimwe",
              },
            },
            {
              name: "sales_season_3",
              type: "expression",
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe n'uruganda",
              },
              expression: "{supply_volume_kg_season_3} * {price_season_3}"
            },
          ],
        },
      ]
    },
    // Page 3: Review and sign
    {
      title: {
        en: "Review the loan and sign in the next page",
        rw: "Ongera usuzume inguzanyo, hanyuma usinye hepfo",
      },
      name: "loan",
      elements: [
        {
          title: { en: "The loan terms:", rw: "Inguzanyo:" },
          name: "terms",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in Rwf",
                rw: "Inguzanyo yasabwe mu Rwf ",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              type: "expression",
              expression: "{loan_terms_summary.repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in Rwf to be deducted from the loan amount ({admin_fee})",
                rw: "Umufuragiro mu Rwf akurwa ku nguzanyo yemejwe ({admin_fee})",
              },
              type: "expression",
              expression:
                "{loan_terms_summary.charge_dict.charge_code_1.total}", //    Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in Rwf to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) kumufuragiro akurwa kunguzanyo yemejwe  ({vat_on_admin_fee})",   // Floating Point Proof
              },
              type: "expression",
              expression:
                "{loan_terms_summary.charge_dict.charge_code_2.total}", // Floating Point Proof
            },
            {
              title: {
                en:"Insurance fees in Rwf to be deducted from the loan amount ({insurance_fee}) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{loan_terms_summary.charge_dict.charge_code_3.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in Rwf ({product_interest_rate}% per year)",
                rw:
                  "Inyungu ku ngunzanyo mu mwaka mu Rwf ({product_interest_rate}% ku mwaka)",
              },
              type: "expression",
              expression:"{loan_terms_summary.total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Amafaranga y'inguzanyo yemejwe wongeyeho inyungu",
              },
              type: "expression",
              expression: "fixed(add({amount}, {loan_terms_summary.total_interest}", // Floating Point Proof
            },
          ],
        },
      ],
    },
    // Page 4: Terms and conditions
    {
      title: {
        en: "Terms and conditions",
        rw: "Amategeko n'amabwiriza",
      },
      name: "loan",
      elements: [
        {
          name: "insurance",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              type: "html",
              title: "",
              html: {
                en: "I, {guarantor_first_name} in representation of the cooperative {cooperative_name} the undersigned, declare the validity of the information, data and documents provided by the Borrower, and The cooperative, and that they are identical to my information and personal data, and they have been filled in, and I am fully legally qualified, and that the (cooperative name) fully responsible for the guarantee, as well as I fully understand that In the event that the Borrower does not pay, the cooperative will pay the full value of the loan.",
                rw: "Njyewe, {guarantor_first_name} uhagarariye koperative {cooperative_name} ndemeza ko amakuru yatanzwe nusaba inguzanyo ari ukuri,  kandi ahuye namakuru koperative ifite. Kandi ( izina ryamakoperative ) yemeye kumwishingira.",
              },
            },
            {
              type: "html",
              html: {
                en: "In the event that the cooperative does not pay, the Lender has the right to take legal measures against the cooperative without any objection. ",
                rw: "Mugihe atabashije kwishyura inguzanyo, Koperative izishyura agaciro kose k'umwenda w'inguzanyo yafashe. Mugihe koperative itishyura,   hazakurikizwa amategeko ",
              },
            },
            {
              title: "removeMe",
              type: "checkbox",
              name: "agreeTermsAndConditions",
              isRequired: true,
              choices: [
                {
                  value:
                    "Guarantor has agreed to terms and conditions",
                  text: {
                    en: "I agree",
                    rw: "Ndabyemeye",
                  },
                },
              ],
              validators: [],
            },
          ],
        },
      ],
    },
  ]
}
