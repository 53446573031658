import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const applicant_bll = new SurveyComponent({
  title: {
    en: "Applicant Information",
    rn: "Umwidondoro kuwusaba ingurane",
    "fr-BI": "Informations sur le demandeur du prêt",
  },
  name: "applicant",
  elements: [
    {
      title: {
        en: "Applicant Full Name *",
        rn: "Amazina yose y'uwusaba ingurane",
        "fr-BI": "Nom complet du demandeur du prêt",
      },
      name: "last_name",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Can you share a picture for yourself? *",
        rn: "Kugira tubone neza ibikuranga, Erekana ifoto yawe",
        "fr-BI":
          "Pour vérifier votre identité, veuillez télécharger une photo de vous-même",
      },
      name: "profile_avatar",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Date of Birth *",
        rn: "Wavutse ryari?",
        "fr-BI": "Quelle est votre date de naissance ?",
      },
      name: "date_of_birth",
      type: "text",
      inputType: "date",
      isRequired: required,
    },
    {
      title: {
        en: "What is your gender? *",
        rn: "Ni ikihe gitsina cawe?",
        "fr-BI": "Quel est votre sexe ?",
      },
      name: "gender",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: "Masculin",
          text: { en: "Male", rn: "Umugabo", "fr-BI": "Masculin" },
        },
        {
          value: "Féminin",
          text: { en: "Female", rn: "Umugore", "fr-BI": "Féminin" },
        },
      ],
    },
    {
      title: {
        en: "What is your mobile number ?",
        rn: "Inimero ya terefone ngendanwa yawe ni iyahe?",
        "fr-BI": "Quel est votre numéro de téléphone mobile ?",
      },
      name: "phone_number",
      type: "phone",
      isRequired: required,
    },
  ],
});
