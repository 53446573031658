import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";
import { extra_marital } from "./extra_marital";

const required = env.ENDPOINT !== "local";

export const applicant_ffdl = new SurveyComponent({
  title: {
    en: "Applicant Information",
    "es-GT": "Datos generales del solicitante",
  },
  name: "applicant",
  elements: [
    {
      type: "text",
      name: "first_name",
      isRequired: required,
      readOnly: true,
      title: {
        en: "First name *",
        "es-GT": "Primer nombre *",
      },
    },
    {
      type: "text",
      name: "middle_name",
      readOnly: true,
      title: {
        en: "Middle name",
        "es-GT": "Segundo nombre",
      },
    },
    {
      type: "text",
      name: "surname",
      isRequired: required,
      readOnly: true,
      title: {
        en: "Surname *",
        "es-GT": "Primer apellido *",
      },
    },
    {
      type: "text",
      name: "second_surname",
      readOnly: true,
      title: { en: "Second surname", "es-GT": "Segundo apellido" },
    },
    {
      type: "text",
      name: "married_name",
      readOnly: true,
      title: { en: "Married name", "es-GT": "Apellido de casada" },
    },
    {
      title: {
        en: "Gender *",
        "es-GT": "Género *",
      },
      name: "gender",
      type: "radiogroup",
      isRequired: required,
      readOnly: true,
      choices: [
        { value: "hombre", text: { en: "Male", "es-GT": "hombre" } },
        { value: "mujer", text: { en: "Female", "es-GT": "mujer" } },
      ],
    },
    {
      title: {
        en: "Ethnic group *",
        "es-GT": "Grupo étnico *",
      },
      name: "custom_text_61",
      type: "radiogroup",
      isRequired: required,
      readOnly: true,
      choices: [
        { value: "Indígena", text: { en: "Indigenous", "es-GT": "Indígena" } },
        { value: "No indígena", text: { en: "Non-indigenous", "es-GT": "No indígena" } },  
      ],
    },
    {
      title: {
        en: "Date of Birth *",
        "es-GT": "Fecha de nacimiento *",
      },
      name: "date_of_birth",
      type: "text",
      inputType: "date",
      isRequired: required,
      readOnly: true,
    },
    {
      title: { en: "N.I.T. *", "es-GT": "N.I.T. *" },
      name: "custom_text_62", 
      type: "text",
      isRequired: required,
      readOnly: true,
    },
    {
      name: "id_number",
      type: "text",
      isRequired: required,
      readOnly: true,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 13 digits length",
            "es-GT": "El número debe tener una longitud de 13 dígitos",
          },
          regex: "^[0-9]{13}$",
        },
      ],
      title: {
        en: "ID No. *",
        "es-GT": "DPI *",
      },
    },
    {
      title: {
        en: "Educational level *",
        "es-GT": "Nivel de educación *",
      },
      name: "education", // check mapping
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "no sabe leer ni escribir",
          text: {
            en: "Can't read or write",
            "es-GT": "No sabe leer ni escribir",
          },
        },
        { value: "primaria", text: { en: "Primary", "es-GT": "Primaria" } },
        { value: "secundaria", text: { en: "Secondary", "es-GT": "Secundaria" } },
        {
          value: "diversificado",
          text: { en: "Diversified", "es-GT": "Diversificado" },
        },
        {
          value: "universidad",
          text: { en: "University", "es-GT": "Universidad" },
        },
      ],
    },
    {
      title: {
        en: "Profession or occupation *",
        "es-GT": "Profesión u oficio *",
      },
      name: "current_job_description",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "No. dependents *",
        "es-GT": "No. dependientes *",
      },
      name: "custom_integer_1",
      type: "text",
      isRequired: required,
    },
    {
      name: "property_ownership",
      type: "dropdown",
      isRequired: required,
      title: {
        en: "Home *",
        "es-GT": "Casa *",
      },
      choices: [
        { value: "propia", text: { en: "Owned", "es-GT": "Propia" } },
        { value: "alquilada", text: { en: "Rented", "es-GT": "Alquilada" } },
        { value: "familiar", text: { en: "Family", "es-GT": "Familiar" } },
      ],
    },
    {
      title: {
        en: "Geographic location *",
        "es-GT": "Ubic. geográfica *",
      },
      name: "custom_text_7",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "urbana", text: { en: "Urban", "es-GT": "Urbana" } },
        { value: "rural", text: { en: "Rural", "es-GT": "Rural" } },
      ],
    },
    {
      title: {
        en: "Address *",
        "es-GT": "Direccion *",
      },
      name: "home_addres_details",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "References to get there *",
        "es-GT": "Referencias para llegar *",
      },
      name: "custom_text_8",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "How long have you lived there? *",
        "es-GT": "Tiempo de residir *",
      },
      name: "custom_text_6",
      type: "text",
      isRequired: required,
    },
    {
      name: "phone_number",
      isRequired: required,
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
      title: {
        en: "Mobile number *",
        "es-GT": "No. celular *",
      },
    },
    {
      name: "mobile_phone_number_2",
      type: "phone",
      validators: [
        expressions.phone_en("mobile_phone_number_2"),
        expressions.phone_length("mobile_phone_number_2"),
      ],
      title: {
        en: "Home telephone number",
        "es-GT": "Teléfono casa",
      },
    },
    {
      name: "custom_text_9",
      type: "phone",
      validators: [
        expressions.phone_en("custom_text_9"),
        expressions.phone_length("custom_text_9"),
      ],
      title: {
        en: "Other telephone number",
        "es-GT": "Otro Teléfono",
      },
    },
    {
      title: {
        en: "Email address *",
        "es-GT": "Correo electrónico *",
      },
      isRequired: required,
      name: "email",
      type: "text",
      validators: [{ type: "email" }],
      inputMask: "email",
    },
    {
      title: {
        en: "Can you share a picture for yourself? *",
        "es-GT": "Compártanos su fotografía *",
      },
      name: "profile_avatar",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },

    {
      title: {
        en: "What is your marital status? *",
        "es-GT": "Estado civil *",
      },
      name: "marital",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "casado", text: { en: "Married", "es-GT": "Casado" } },
        { value: "soltero", text: { en: "Single", "es-GT": "Soltero" } },
        {
          value: "union_de_hecho",
          text: { en: "Domestic partnership", "es-GT": "Unión de hecho" },
        },
      ],
    },
    extra_marital,
  ],
});
