
/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */

export const educationalReviewEduloan = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and sign your loan application",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          elements: [
            {
              title: {
                en: "Repayment Schedule",
              },
              type: "repayment_schedule",
              name: "repayment_schedule",
            },
            {
              title: {
                en: "Required tuition amount (in ZWL)",
              },
              type: "expression",
              expression: "fixed({intake.custom_float_10},2)",
              displayStyle: "currency",
            },
            {
              title: {
                en: "Loan amount to apply for (in ZWL)",
              },
              type: "expression",
              expression: "fixed({total_final_loan_amount}, 2)",
              displayStyle: "currency",
            },
            {
              title: {
                en: "Loan Term (in Months)",
              },
              type: "expression",
              expression: "{repayment_period}",
            },
            {
              title: {
                en: "Initiation fees (%)",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.percentage}", 
            },
            {
              title: {
                en: "Initiation fees amount",
              },
              type: "expression",
              expression: "fixed({charge_dict.charge_code_1.total}, 2)",
              displayStyle: "currency",
            },
            {
              title: {
                en: "Monthly Interest Rate (%)",
              },
              type: "expression",
              expression: "{monthly_interest_rate}",
            },
            {
              title: {
                en: "Monthly Installment (in ZWL)",
              },
              type: "expression",
              expression: "fixed({monthly_installment}, 2)",
              displayStyle: "currency",
            },
            {
              title: {
                en: "Net Disbursement",
              },
              type: "expression",
              expression: "fixed({intake.custom_float_10}, 2)",
              displayStyle: "currency",
            },
          ],
        },
        {
          title: "I am committing to a {repayment_period} month period payment of {monthly_installment} ZWL. I authorize my employer to deduct from my salary the loan amount of {monthly_installment} ZWL",
          description: "",
          type: "checkbox",
          name: "loan_terms",
          isRequired: true,
          choices: [
            {
              value: {
                en: "Applicant has agreed to loan amount, loan terms and total fees",
              },
              text: {
                en: "I agree",
              },
            },
          ],
          validators: [],
        },
        {
          type: "html",
          name: "repayment_method",
          html: {
            en:
              "Loan repayment method (Direct salary deduction/Bank transfer or Direct deposit/Stop order)",
          },    
        },
        {
          title: "By signing this the Consumer confirms acceptance of the quotation and that a binding agreement is concluded on the above Terms and Conditions read hereof, the contents of which are deemed to be incorporated herein, unless the Credit Provider rejects the application, in which case the Consumer will be advised accordingly in writing or electronically. The loan will only be made available to the Consumer subject to the Credit Provider undertaking an assessment and being satisfied that the Consumer can afford the loan.",
          description: "",
          type: "checkbox",
          name: "binding_agreement",
          isRequired: true,
          choices: [
            {
              value: {
                en: "Applicant has agreed to loan amount, loan terms and total fees",
              },
              text: {
                en: "I agree",
              },
            },
          ],
        }
      ],
    },
  ],
};
