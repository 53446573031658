import React from 'react';

export default class SjabloonScreen extends React.Component {

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', padding: 24}}>

        <p className='xsmall'>Mr. MoneyPhone is currently busy (xsmall)</p>
        <p className='small'>Mr. MoneyPhone is currently busy (small)</p>

        <p>Mr. MoneyPhone is currently busy (default)</p>
        <p className='thin'>Mr. MoneyPhone is currently busy (default, thin)</p>
        <p className='heavy'>Mr. MoneyPhone is currently busy (default, heavy)</p>

        <p className='medium'>Mr. MoneyPhone is currently busy (medium, h4)</p>
        <p className='large'>Mr. MoneyPhone is currently busy (large, h3)</p>
        <p className='xlarge'>Mr. MoneyPhone is currently busy (xlarge, h2)</p>
        <p className='xxlarge'>Mr. MoneyPhone is currently busy (xxlarge, h1)</p>

        <div style={{margin: 12, border: 'solid', borderWidth: 0.5, borderColor: '#444'}}>
          <h2 className='bigSpacer'>Example title with 'bigSpacer'</h2>
          <p>Content</p>
        </div>

        <div style={{margin: 12, border: 'solid', borderWidth: 0.5, borderColor: '#444'}}>
          <p className='spacer'>list 1 with 'spacer'</p>
          <p className='spacer'>list 2 with 'spacer'</p>
          <p className='spacer'>list 3 with 'spacer'</p>
        </div>


      </div>
    );
  }
}
