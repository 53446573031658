import React from 'react';
import ReactDOM from 'react-dom';
import './memory_question.scss';


class MemoryComponent extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: null
    };

  }

  _update(newValue) {
    this.setState({value: newValue});
    this.props.changeHandler(newValue);
  };

  _sendValue(value) {
    if (value) {
      this.props.changeHandler(value)
    }
  }

  render({question} = this.props) {
    return (
      <div className='memory'>

        <img className='question' src={`/test_assets/${question.name}_b.jpg`} alt='question' style={{maxWidth: 250, display: 'block'}}/>

        <p className='center bigSpacer'>{question.otherItem.text}</p>

        <div className='choices'>
          {[0, 1, 2, 3, 4, 5].map((_c, index) => (
            <div key={index} className='choice'>
              <label>
                <input type="radio" value={_c} checked={this.state.value === _c}
                       onChange={() => this._update(_c)}/>
                {_c}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export function register_widget_memory(Survey) {

  let widget = {
    name: "memory",

    widgetIsLoaded: function () {
      //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
      return true
    },
    isFit: function (question) {
      return question.getType() === "radiogroup" && question["renderAs"] === "memory";
    },

    activatedByChanged: function (activatedBy) {
      //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us

      Survey.JsonObject.metaData.addProperties("radiogroup", [
        {
          name: "renderAs",
          default: "standard",
        }
      ]);
    },

    afterRender: function (question, el) {
      let rendering = <MemoryComponent question={question} changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, el);
    },

    //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
    htmlTemplate: "<div style='padding: 0 20px'></div>",

    willUnmount: function (question, el) {
      // All unounting is handled in component itself
    }
  };

  // register widget
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}