import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const mortgage = new SurveyComponent({
  title: {
    en: "Mortgage",
    "es-GT": "Hipotecaria",
  },
  name: "mortgage",
  type: "panel",
  innerIndent: 1,
  visibleIf: "{collateral_arrangements} == 'hipotecaria'",
  elements: [
    {
      title: {
        en: "Please choose the type of property *",
        "es-GT": "Por favor, elija el tipo de inmueble *",
      },
      name: "custom_text_39",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "terreno", text: { en: "Land", "es-GT": "Terreno" } },
        {
          value: "terreno_y_construcción",
          text: {
            en: "Land under construction",
            "es-GT": "Terreno y construcción",
          },
        },
      ],
    },
    {
      title: {
        en: "Please choose the condition *",
        "es-GT": "Por favor, elija la condición *",
      },
      name: "custom_text_40",
      visibleIf: "{custom_text_39} == 'land_under_construction'",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "formal", text: { en: "Formal", "es-GT": "Formal" } },
        {
          value: "temporaral",
          text: {
            en: "Temporary",
            "es-GT": "Temporal",
          },
        },
      ],
    },
    {
      title: {
        en: "Please choose the type of construction *",
        "es-GT": "Por favor elija el tipo de construcción *",
      },
      name: "custom_text_41", // check mapping
      visibleIf: "{custom_text_40} == 'formal'",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "preconstruida",
          text: { en: "Pre-built", "es-GT": "Preconstruida" },
        },
        {
          value: "block_o_ladrillo",
          text: {
            en: "Block or brick",
            "es-GT": "Block o ladrillo",
          },
        },
      ],
    },
    {
      title: {
        en: "Please choose the type of material *",
        "es-GT": "Por favor elija el tipo de material *",
      },
      name: "custom_text_42",
      visibleIf: "{custom_text_40} == 'temporaral'",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "lamina",
          text: { en: "Foil", "es-GT": "Lámina" },
        },
        {
          value: "carton",
          text: {
            en: "Cardboard",
            "es-GT": "Cartón",
          },
        },
      ],
    },
    {
      title: {
        en: "Address *",
        "es-GT": "Dirección *",
      },
      name: "custom_text_43",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Add real estate book *",
        "es-GT": "Ingresar Finca, Folio y Libro de inmueble *",
      },
      name: "custom_text_44",
      type: "file_uploader",
      accept: "image/*,application/pdf",
    },
    {
      title: {
        en: "Upload image of the property *",
        "es-GT": "Subir foto de inmueble *",
      },
      name: "picture_5",
      type: "file_uploader",
      accept: "image/*,application/pdf",
    },
  ],
});
