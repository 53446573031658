import React from 'react';
import ReactDOM from 'react-dom';
import './repayment_schedule.scss';


function TableRow(data) {
    return (
        <tr>
            {data.row.map((val, idx) => <td key={idx}>{val}</td>)}
        </tr>
    )
}

class RepaymentScheduleComponent extends React.Component {

  render({question}=this.props) {
    return (
      <div div className='repaymentScheduleContainer'>
        <table style={{ width: 500 }}>
                <thead>
                    <tr>
                        {question.value.headers.map(head => <th key={head}>{head}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {question.value.data.map((row,idx) => <TableRow key={idx} row={row} />)}
                </tbody>
        </table>
      </div>
     )
  }
}

export function register_widget_repayment_schedule(Survey) {
  let widget = {
    name: "repayment_schedule",
    title: "Repayment Schedule",
    htmlTemplate: "<div></div>",

    isFit: function (question) {
        return question.getType() === "repayment_schedule";
      },

      activatedByChanged: function (activatedBy) {
        Survey.JsonObject.metaData.addClass(
          "repayment_schedule", [], null, "empty"
        );
  
      },

    afterRender: function (question, el) {
      let rendering = <RepaymentScheduleComponent question={question}/>;
      ReactDOM.render(rendering, el);
    },

  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}