import Config from "../../config";
import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const guarantor_intake_banco_popular = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-HN": "Firmar aplicación",
  },

  questionStartIndex: 1,
  requiredText: "",
  pages: [
    // Page 1: Business/Work Information
    {
      title: {
        en: "Business/Work Information",
        "es-HN": "Datos del negocio/laborales",
      },
      innerIndent: 1,
      elements: [
        {
          type: "dropdown",
          name: "custom_text_7",
          title: {
            "es-HN": "Tipo de cliente",
            en: "Type of client",
          },
          hasOther: true,
          storeOthersAsComment: false,
          otherText: { en: "Other", "es-HN": "Otro" },
          isRequired: required,
          choices: [
            {
              value: "independiente",
              text: {
                en: "Independent",
                "es-HN": "Independiente",
              },
            },
            {
              value: "asalariado",
              text: {
                en: "Salaried",
                "es-HN": "Asalariado",
              },
            },
            {
              value: "ama_de_casa",
              text: {
                en: "housewife",
                "es-HN": "Ama de casa",
              },
            },
          ],
        },
        {
          type: "text",
          name: "custom_text_8",
          title: {
            en: "Name of the supervisor or business",
            "es-HN": "Nombre del patrón o negocio",
          },
          isRequired: required,
        },
        {
          name: "start_date_panel",
          type: "panel",
          innerIndent: 2,
          title: {
            en: "Start date",
            "es-HN": "Fecha de ingreso/inicio",
          },
          elements: [
            {
              type: "dropdown",
              name: "date_type_2",
              title: {
                en: "Enter date with:",
                "es-HN": "Ingresar fecha con:",
              },
              defaultValue: "calendario",
              choices: [
                {
                  value: "calendario",
                  text: { en: "Calendar", "es-HN": "Calendario" },
                },
                { value: "texto", text: { en: "Text", "es-HN": "Texto" } },
              ],
            },
            {
              visibleIf: "{date_type_2} == 'calendario'",
              type: "text",
              name: "custom_text_11",
              isRequired: required,
              inputType: "date",
              title: { en: "Start date", "es-HN": "Fecha de ingreso/inicio" },
            },
            {
              visibleIf: "{date_type_2} == 'texto'",
              name: "custom_text_11",
              type: "text",
              title: { en: "Start date", "es-HN": "Fecha de ingreso/inicio" },
              placeHolder: "aaaa-mm-dd",
              validators: [
                {
                  type: "regexvalidator",
                  text: {
                    en: "Enter date with the format yyyy-mm-dd",
                    "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                  },
                  regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
                },
              ],
            },
          ],
        },
        {
          name: "payment_date_panel",
          type: "panel",
          innerIndent: 2,
          title: {
            en: "Payment date",
            "es-HN": "Fecha de pago",
          },
          elements: [
            {
              type: "dropdown",
              name: "date_type_3",
              title: {
                en: "Enter date with:",
                "es-HN": "Ingresar fecha con:",
              },
              defaultValue: "calendario",
              choices: [
                {
                  value: "calendario",
                  text: { en: "Calendar", "es-HN": "Calendario" },
                },
                { value: "texto", text: { en: "Text", "es-HN": "Texto" } },
              ],
            },
            {
              visibleIf: "{date_type_3} == 'calendario'",
              type: "text",
              name: "custom_text_13",
              isRequired: required,
              inputType: "date",
              title: { en: "Payment date", "es-HN": "Fecha de pago" },
            },
            {
              visibleIf: "{date_type_3} == 'texto'",
              name: "custom_text_13",
              type: "text",
              title: { en: "Payment date", "es-HN": "Fecha de pago" },
              placeHolder: "aaaa-mm-dd",
              validators: [
                {
                  type: "regexvalidator",
                  text: {
                    en: "Enter date with the format yyyy-mm-dd",
                    "es-HN": "Ingresar fecha en el formato aaaa-mm-dd",
                  },
                  regex: `^[0-9]{4}-(1[0-2]|0[1-9])-(0[1-9]|[12][0-9]|3[01])$`,
                },
              ],
            },
          ],
        },
        {
          type: "dropdown",
          title: {
            en: "Are you enlisted in ISR?",
            "es-HN": "¿Es afecto al ISR?",
          },
          name: "custom_text_14",
          choices: [
            { value: "si", text: { en: "Yes", "es-HN": "Sí" } },
            { value: "no", text: { en: "No", "es-HN": "No" } },
          ],
        },
        {
          title: {
            en: "Work Address",
            "es-HN": "Dirección del patrono o negocio",
          },
          name: "custom_text_15",
          type: "text",
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_16",
          title: {
            en: "Position held",
            "es-HN": "Cargo que ocupa",
          },
        },
        {
          name: "id_pictures_panel",
          type: "panel",
          innerIndent: 1,
          title: {
            en: "ID pictures",
            "es-HN": "Fotos de documento de indentidad",
          },
          elements: [
            {
              title: {
                en: "Please upload a photo of the front of your ID",
                "es-HN": "Por favor subir foto del frente de la identificación",
              },
              type: "file_uploader",
              accept: "image/*,application/pdf",
              name: "id_file",
              isRequired: required,
            },
            {
              title: {
                en: "Please upload a photo of the back of your ID",
                "es-HN":
                  "Por favor subir foto del reverso de la identificación",
              },
              type: "file_uploader",
              accept: "image/*,application/pdf",
              name: "id_file_back",
              isRequired: required,
            },
            {
              title: {
                en: "Please upload a selfie showing your identity",
                "es-HN":
                  "Por favor subir una selfie mostrando tu identificación",
              },
              type: "file_uploader",
              accept: "image/*,application/pdf",
              name: "id_file_with_avatar",
              isRequired: required,
            },
            {
              title: {
                en: "Voucher",
                "es-HN": "Constancia/Baucher",
              },
              type: "file_uploader",
              accept: "image/*,application/pdf",
              name: "picture_2",
              isRequired: required,
            },
            {
              title: {
                en: "Please, upload a picture of your business",
                "es-HN": "Por favor, suba una foto de su negocio",
              },
              visibleIf: "{custom_text_7} == 'independiente'",
              type: "file_uploader",
              accept: "image/*,application/pdf",
              name: "picture_3",
              isRequired: required,
            },
          ],
        },
      ],
    },
    // Page 2: Income and expenses
    {
      title: {
        en: "Income and expenses",
        "es-HN": "Ingresos y gastos",
      },
      innerIndent: 1,
      elements: [
        {
          type: "panel",
          title: {
            en: "Income",
            "es-HN": "Ingresos",
          },
          elements: [
            {
              name: "custom_float_3",
              type: "text",
              inputType: "number",
              title: {
                en: "Salary",
                "es-HN": "Sueldo",
              },
            },
            {
              name: "custom_float_4",
              type: "text",
              inputType: "number",
              title: {
                en: "Income for business/rent",
                "es-HN": "Ingreso por negocio/alquiler",
              },
            },
            {
              name: "custom_float_5",
              type: "text",
              inputType: "number",
              title: {
                en: "Other income",
                "es-HN": "Otros ingresos",
              },
            },
            {
              name: "custom_float_6",
              type: "expression",
              title: {
                "es-HN": "Total ingresos",
                en: "Total income",
              },
              expression:
                "sum({custom_float_3},{custom_float_4},{custom_float_5})",
              displayStyle: "currency",
              currency: "HNL",
            },
          ],
        },
        {
          type: "panel",
          title: {
            "es-HN": "Gastos",
            en: "Costs",
          },
          elements: [
            {
              name: "custom_float_7",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Monthly expenditure on food",
                "es-HN": "Gasto al mes en Alimentación",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
            {
              name: "custom_float_8",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Expenditure per month on Education",
                "es-HN": "Gasto al mes en Educación",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
            {
              name: "custom_float_9",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Monthly expenditure on public services",
                "es-HN": "Gasto al mes en Servicios Públicos",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
            {
              name: "custom_float_10",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Spending on Rents",
                "es-HN": "Gasto en Alquileres",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
            {
              name: "custom_float_11",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Health expenditure per month.",
                "es-HN": "Gasto al mes en Salud",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
            {
              name: "custom_float_12",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Business expenses",
                "es-HN": "Gastos del negocio",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
            {
              name: "custom_float_13",
              type: "text",
              isRequired: required,
              inputType: "number",
              title: {
                en: "Unforeseen",
                "es-HN": "Imprevistos",
              },
              displayStyle: "currency",
              currency: "HNL",
            },
          ],
        },
        {
          name: "custom_float_14",
          type: "expression",
          title: {
            "es-HN": "Total gastos familiares",
            en: "Total family expenses",
          },
          expression:
            "sum({custom_float_7},{custom_float_8},{custom_float_9},{custom_float_10},{custom_float_11},{custom_float_12},{custom_float_13})",
          displayStyle: "currency",
          currency: "HNL",
        },
      ],
    },
    // Page 3: Partner Information
    {
      title: {
        en: "Partner Information",
        "es-HN": "Información del cónyuge",
      },
      innerIndent: 1,
      visibleIf: "{marital} == 'CASADO' || {marital} == 'UNIDO'",
      elements: [
        {
          type: "text",
          name: "full_name_spouse",
          title: {
            en: "Full name spouse",
            "es-HN": "Nombre completo",
          },
          isRequired: required,
        },
        {
          name: "custom_text_17",
          title: {
            en: "Type of document",
            "es-HN": "Tipo de documento",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.tipo_documentos || [],
          isRequired: required,
        },
        {
          type: "text",
          name: "id_number_spouse",
          title: {
            en: "ID number spouse",
            "es-HN": "Número de documento",
          },
          isRequired: required,
          maxLength: 18,
        },
        {
          type: "text",
          name: "custom_integer_1",
          title: { en: "Age spouse", "es-HN": "Edad" },
          isRequired: required,
          inputType: "number",
          maxLength: 2,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Answer the question",
                "es-HN": "Responda la pregunta",
              },
              regex: `^[1-9][0-9]+$`,
            },
          ],
        },
        {
          name: "current_job_spouse",
          title: {
            en: "Profession/Trade/Current occupation",
            "es-HN": "Profesión/Oficio/Dedicación actual",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.profesiones || [],
          isRequired: required,
        },
        {
          type: "dropdown",
          name: "custom_text_18",
          title: {
            en: "Educational level spouse",
            "es-HN": "Nivel educativo",
          },
          isRequired: required,
          choices: Config.nivel_educativo || [],
        },
        {
          type: "dropdown",
          name: "custom_text_19",
          title: {
            en: "Gender spouse",
            "es-HN": "Género",
          },
          isRequired: required,
          choices: [
            { value: "masculino", text: { en: "Male", "es-HN": "Masculino" } },
            { value: "femenino", text: { en: "Female", "es-HN": "Femenino" } },
          ],
        },
        {
          name: "nationality_spouse",
          title: {
            en: "Nationality spouse",
            "es-HN": "Nacionalidad",
          },
          description: {
            en: "You can search the name",
            "es-HN": "Puedes buscar el nombre",
          },
          type: "dropdown",
          renderAs: "select2",
          choices: Config.nacionalidad || [],
          isRequired: required,
        },
        {
          type: "phone",
          name: "phone_number_spouse",
          title: {
            en: "Phone number spouse",
            "es-HN": "Celular",
          },
          isRequired: required,
          validators: [
            expressions.phone_en("phone_number_spouse"),
            expressions.phone_length("phone_number_spouse"),
          ],
        },
        {
          type: "phone",
          name: "custom_text_20",
          title: {
            en: "Aditional phone number",
            "es-HN": "Número de teléfono adicional",
          },
          isRequired: required,
          validators: [
            expressions.phone_en("phone_number_spouse"),
            expressions.phone_length("phone_number_spouse"),
          ],
        },
        {
          type: "text",
          name: "job_address_spouse",
          title: {
            en: "Place of work spouse",
            "es-HN": "Lugar de trabajo",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_float_1",
          title: {
            en: "Monthly income spouse",
            "es-HN": "Ingresos mensuales",
          },
          isRequired: required,
          inputType: "number",
        },
        {
          type: "text",
          name: "custom_float_2",
          title: {
            en: "Contribution to household expenditure ",
            "es-HN": "Aporte al gasto familiar",
          },
          isRequired: required,
        },
      ],
    },
    // Page 4: References
    {
      title: {
        en: "References",
        "es-HN": "Referencias",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Account References",
            "es-HN": "Referencias de cuenta",
          },
          name: "custom_jsonfield_2",
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 2,
          rowCount: 1,
          addRowText: {
            en: "Add another institution",
            "es-HN": "Añadir institución",
          },
          columns: [
            {
              title: {
                en: "Institution Name",
                "es-HN": "Nombre de la institución",
              },
              name: "institucion",
              cellType: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Monetary",
                "es-HN": "Monetario",
              },
              name: "monetario",
              cellType: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Savings",
                "es-HN": "Ahorro",
              },
              name: "ahorro",
              cellType: "text",
              inputType: "number",
            },
            {
              title: {
                en: "CDT",
                "es-HN": "CDT",
              },
              name: "cdt",
              cellType: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Investing",
                "es-HN": "Inversión",
              },
              name: "inversion",
              cellType: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Other",
                "es-HN": "Otros",
              },
              name: "otros",
              cellType: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Average Amount",
                "es-HN": "Monto promedio",
              },
              name: "monto_promedio",
              cellType: "text",
              inputType: "number",
            },
          ],
        },
      ],
    },
    // Page 5: Credit References
    {
      title: {
        en: "Credit References",
        "es-HN": "Referencias de Crédito",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Credit Institutions",
            "es-HN": "Instituciones de Crédito",
          },
          name: "custom_jsonfield_3",
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 2,
          rowCount: 1,
          addRowText: {
            en: "Add another institution",
            "es-HN": "Añadir institución",
          },
          columns: [
            {
              title: {
                en: "Institution Name",
                "es-HN": "Nombre de la institución",
              },
              name: "institucion",
              cellType: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Amount",
                "es-HN": "Monto",
              },
              name: "monto",
              cellType: "text",
              inputType: "number",
              isRequired: required,
            },
            {
              title: {
                en: "Current Balance",
                "es-HN": "Saldo actual",
              },
              name: "saldo_actual",
              cellType: "text",
              inputType: "number",
              isRequired: required,
            },
          ],
        },
        {
          title: {
            en: "Do you expect to receive remittances",
            "es-HN": "¿Espera recibir remesas?",
          },
          name: "custom_text_21",
          type: "dropdown",
          choices: [
            { value: "si", text: { en: "Yes", "es-HN": "Sí" } },
            { value: "no", text: { en: "No", "es-HN": "No" } },
          ],
        },
        {
          title: {
            en: "Remittances",
            "es-HN": "Remesas",
          },
          name: "custom_jsonfield_4",
          visibleIf: "{custom_text_21} == 'si'",
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 3,
          rowCount: 1,
          isRequired: required,
          addRowText: {
            en: "Add another remittance",
            "es-HN": "Añadir remesa",
          },
          columns: [
            {
              title: {
                en: "From which country?",
                "es-HN": "¿De qué país?",
              },
              name: "pais_de_remesa",
              cellType: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Amount",
                "es-HN": "Monto",
              },
              name: "monto",
              cellType: "text",
              inputType: "number",
              isRequired: required,
            },
          ],
        },
      ],
    },
    // Page 6: Personal References
    {
      title: {
        en: "Personal References",
        "es-HN": "Referencias Personales",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Personal References",
            "es-HN": "Referencias Personales",
          },
          name: "custom_jsonfield_5",
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 3,
          rowCount: 1,
          isRequired: required,
          addRowText: {
            en: "Add another reference",
            "es-HN": "Añadir referencia",
          },
          columns: [
            {
              title: {
                en: "Name",
                "es-HN": "Nombre",
              },
              name: "nombre",
              cellType: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Kinship",
                "es-HN": "Relación o parentesco",
              },
              name: "parentesco",
              cellType: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Address",
                "es-HN": "Domicilio",
              },
              name: "domicilio",
              cellType: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Telephone",
                "es-HN": "Teléfono",
              },
              name: "telefono",
              cellType: "phone",
              isRequired: required,
              validators: [
                expressions.phone_en("telefono"),
                expressions.phone_length("telefono"),
              ],
            },
          ],
        },
        {
          html: {
            en: `<div>`,
            "es-HN": `<div style="text-align: justify;">Yo actuando en lo personal por este medio, declaro bajo juramento que la información anterior es dedigna y 
                que me ha sido solicitada por Banco Popular, S.A. en cumplimiento de lo dispuesto en el Reglamento de la Ley Especial Contra el Lavado de Activos, 
                emitido por la Comision Nacional de Bancos y Seguros. Asimismo autorizo a BANCO POPULAR para investigar la información proporcionada en este y otros 
                formularios, en las fuentes que considere apropiadas, así como en las centrales de riesgo públicas o privadas. <br><br>
              </div>
                `,
          },
          type: "html",
        },
        {
          type: "signaturepad",
          name: "file_2",
          title: {
            en: "Guarantor's digital signature",
            "es-HN": "Firma digital del aval",
          },
          isRequired: required,
        },
      ],
    },
  ],
};
