// import env from "../../env";
// const required = env.ENDPOINT !== "local";


export const sign_banco_popular = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-HN": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-HN": "Revisa y firme tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "html",
          name: "",
          html: {
            en: "Great news, we have reviewed your application and accepted your loan request. <br /> Before we can proceed the payout, please review the final terms and sign the loan contract.",
            "es-HN": "Buenas noticias, hemos revisado y aceptado tu solicitud de crédito. <br /> Antes de que podamos proceder con el desembolso, revisa los términos finales y acepta las condiciones del crédito.",
          }
        },
        {
          type: "panel",
          title: {
            en: "Review and sign your loan contract",
            "es-HN": "Revisa y acepta tu contrato de préstamo",
          },
          name: "",
          elements: [
            {
              type: "html",
              name: "",
              html: {
                en: "Your final loan terms are:",
                "es-HN": "Los términos finales de tu préstamo son:",
              }
            },
            {
              type: "expression",
              expression: "{amount}", // calculate from credit amount
              title: {
                en: "Approved loan amount in pesos",
                "es-HN": "Monto del crédito aprobado en pesos mexicanos",
              },
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_22} == 'si'",
              expression: "{charge_dict.charge_code_1.total}",
              title: {
                en: "Insurance cost",
                "es-HN": "Seguro de vida (en pesos)"
              },
            },
            {
              type: "expression",
              expression: "{intake.custom_text_4}",
              title: {
                en: "RFC",
                "es-HN": "RFC"
              },
            },
            {
              type: "expression",
              expression: "{repayment_period}",
              title: {
                en: "Approved loan term in months",
                "es-HN": "Plazo del crédito  en meses",
              },
            },
            {
              type: "expression",
              expression: "{intake.custom_text_28}",
              title: {
                en: "Payment frequency",
                "es-HN": "Frecuencia de pago"
              },
            },
            {
              type: "expression",
              expression: "{intake.custom_text_90}",
              title: {
                en: "Pledge, Mortgage Guarantees",
                "es-HN": "Garantías Prendarias, Hipotecarias",
              },
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_25} === 'si' || {intake.custom_text_24} === 'no'",
              expression: "{charge_dict.charge_code_2.total}",
              title: {
                en: "Commission%",
                "es-HN": "% de Comisión",
              },
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_24} === 'si' ",
              expression: "{charge_dict.charge_code_4.total}",
              title: {
                en: "% of liquid guarantee",
                "es-HN": "%  de Garantía líquida",
              },
            },
            {
              type: "expression",
              expression:"-", // TODO add, requires new backend field
              title: {
                en: "Ordinary rate",
                "es-HN": "Tasa ordinaria",
              },
            },
            {
              type: "expression",
              expression:"-", // TODO add, requires new backend field
              title: {
                en: "Default rate",
                "es-HN": "Tasa moratoria",
              },
            },
          ]
        },
      ],
    },
  ],
};
