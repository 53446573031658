import React from 'react';
import ReactDOM from 'react-dom';
import './iq_question.scss';


class IQComponent extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: null,
    };

  }

  _update(newValue) {
    this.setState({value: newValue});
    this._sendValue(newValue);
  };

  _sendValue(value) {
    if (value) {
      this.props.changeHandler(value)
    }
  }

  render({question} = this.props) {
    return (
      <div className='iq'>

        <img className='question' src={`/test_assets/${question.name}_question.jpg`} alt='question'/>

        <h3 className='center bigSpacer'>{question.otherItem.text}</h3>

        <div className='choices'>
          {[1, 2, 3, 4].map((choice, index) => (
            <div className='imageParent' key={index} >
              <img className={`choice clickable ${this.state.value === choice ? 'active' : ''}`}
                   src={`/test_assets/${question.name}_${choice}.jpg`} alt='answer'
                   onClick={() => this._update(choice)}
              />
            </div>
          ))}
        </div>

        <div className='unclear'>

          <div className={`checkbox ${this.state.value === 'unclear' ? 'heavy' : ''}`}>
            <div>
              <label>
                <input type="checkbox" value="unclear" checked={this.state.value === 'unclear'}
                       onChange={() => this._update(this.state.value === 'unclear' ? null : 'unclear')}/>
                {question.otherErrorText}
              </label>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export function register_widget_logic(Survey) {

  let widget = {
    name: "iq",

    widgetIsLoaded: function () {
      //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
      return true
    },
    isFit: function (question) {
      return question.getType() === "radiogroup" && question["renderAs"] === "iq";
    },

    activatedByChanged: function (activatedBy) {
      //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us

      Survey.JsonObject.metaData.addProperties("radiogroup", [
        {
          name: "renderAs",
          default: "standard",
        }
      ]);
    },

    afterRender: function (question, el) {
      let rendering = <IQComponent question={question} changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, el);
    },

    //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
    htmlTemplate: "<div style='padding: 0 20px'></div>",

    onReadOnlyChanged: function (question) {
      let el = question.noUiSliderEl;
      if (!el) return;
      if (question.isReadOnly) {
        el.setAttribute("disabled", true);
      } else {
        el.removeAttribute("disabled");
      }
    },

    willUnmount: function (question, el) {
      // All unounting is handled in component itself
    }
  };

  // register widget
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");

}