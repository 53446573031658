import React from "react";
import { connect } from "react-redux";

import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import Config from "../../config";
import { translate} from "../../lib/intl";
import { loanActions } from "../../store/store";

export function auth_valid_pages() {
  
  const procredito_pages = [];

  const availablePages = {
    procredito: procredito_pages,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];


  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),

    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageNextText"), // note same as `Next`

    requiredText: "",
    isSinglePage: true, // Can be set to True for review mode
    pages: currentDeploymentPages,
  };
}

export class AuthenticationWaiting extends React.PureComponent {
  intervalId;
  
  tick = () => {
    this.props.checkLoanState(this.props.loan.uuid)
    .then((data) => {
        console.log(data)
        if(!["authenticate", ""].includes(data.state)){
          this.props.setPage(data.state)
        }
    })
  }
  componentDidMount() {
    // polling
    this.intervalId = setInterval(() => {
      this.tick();
    }, 10000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  render = ({ device } = this.props) => {
    return (
      <div className="center">
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />
        
        <div className="centerWrapper">
            <div>{translate("two_factor_auth.waiting")}</div>
        </div>
        <Footer />
        
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLoan: (uuid) => dispatch(loanActions.getLoan(uuid)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const mapStateToProps = (state) => {
  return {
    loan: state.loan,
    device: state.device,
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWaiting);

export { connectedContainer as AuthenticationWaitingContainer };
