

let live_questions = ['memory_1', 'memory_2', 'memory_3'];

export function add_memory_questions(assessment, test = true) {

  // export correct json
  (test ? live_questions.splice(1, 3) : live_questions).forEach((question) => {

    assessment.pages.push(
      {
        title: {en: "Study this image and click next when you've memorized the image", ar: "تمعن في هذه الصورة واضغط على التالي بعد أن تحفظ الصورة"},
        name: `${question}_memorize`,
        elements: [
          {
            type: "html",
            name: `${question}_memorize`,
            html: `<img src="/test_assets/${question}_a.jpg" style="max-width: 250px; margin: 0 auto; display: block" alt="question"/>`
          },
        ]
      }
    );
    assessment.pages.push(
      {
        title: {en: "How many items have changed in the image?", ar: "كم عدد الأشياء التي تغيرت في الصورة؟"},
        name: question,
        elements: [
          {
            title: {en: "(Please do not go back to view the original image)", ar: "(الرجاء عدم العودة لرؤية الصورة الأصلية)"},
            otherText: {en: "Select the number of changes you see:", ar: "حدد عدد التغييرات التي تراها:"},
            name: question,
            type: "radiogroup",
            renderAs: "memory",
            isRequired: true,
          },
        ]
      }
    )
  });
}