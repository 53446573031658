import env from "../../env";
const required = env.ENDPOINT !== "local";


export const sign_crediavance = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-MX": "Revisa y firme tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          name: "amount",
          title: {
            en: "Requested loan amount ($)",
            "es-MX": "Monto autorizado ($)",
          },
        },
        {
          type: "expression",
          name: "loan_term",
          title: {
            en: "Loan Term (months)",
            "es-MX": "Plazo del préstamo (meses)",
          },
        },
        {
          type: "expression",
          name: "repayment_period",
          title: {
            en: "expected Disbursement date if loan is approved:",
            "es-MX": "Fecha prevista de desembolso si se aprueba el préstamo",
          },
        },
        {
          type: 'checkbox',
          name: "confirm_4",
          isRequired: required,
          storeOthersAsComment: false,
          choices: [
            { value: "I agree", text: { en: "I agree", "es-MX": "Estoy de acuerdo" } },
          ],
          title: {
            en: "I understand the risks involved and will guarantee the repayment of the loan.",
            "es-MX": "Entiendo los riesgos involucrados y garantizaré el reembolso del préstamo.",
          },
        },
        {
          title: { en: "Sign loan application", "es-MX": "Firmar aplicacion" },
          type: "signaturepad",
          isRequired: true,
          name: "file_4",
        },
      ],
    },
  ],
};
