import { SurveyComponent } from '../../../../components/survey/survey_component';
import env from "../../../../env";

const required = env.ENDPOINT !== 'local';

export const applicant_essentials = new SurveyComponent({
  title: {
    en: 'Applicant Information',
    rw: 'Amakuru yusaba',
  },
  name: 'applicant',
  elements: [
    {
      title: { en: 'Applicant Full Name *', rw: 'Amazina yombi *' },
      name: 'last_name',
      type: 'text',
      isRequired: required,
    },
    {
      title: {
        en: 'Can you share a picture for yourself? *',
        rw: 'Waduha ifoto yawe? *',
      },
      name: 'profile_avatar',
      type: 'file_uploader',
      accept: 'image/*,application/pdf',
      isRequired: required,
    },
    {
      title: {
        en: 'Date of Birth *',
        rw: 'Itariki yamavuko *',
      },
      name: 'date_of_birth',
      type: 'text',
      inputType: 'date',
      isRequired: required,
    },
    {
      title: {
        en: 'What is your gender? *',
        rw: 'Igitsina ni ikihe? *',
      },
      name: 'gender',
      type: 'radiogroup',
      isRequired: required,
      choices: [
        { value: 'male', text: { en: 'Male', rw: 'Gabo' } },
        { value: 'female', text: { en: 'Female', rw: 'Gore' } },
      ],
    },
    {
      title: {
        en: 'What is your marital status? *',
        rw: 'Irangamimerere ni irihe? *',
      },
      name: 'marital',
      type: 'radiogroup',
      isRequired: required,
      choices: [
        { value: 'married', text: { en: 'Married', rw: 'Ndubatse' } },
        { value: 'single', text: { en: 'Single', rw: 'Ingaragu' } },
      ],
    },
  ],
});
