import env from "../../env";
const required = env.ENDPOINT !== "local";


export const sign_rentamigo = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "Final sining",
      title: {
        en: "Review and sign your loan application",
        "es-MX": "Revisa y firme tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          name: "amount",
          title: {
            en: "Requested loan amount (Q)",
            "es-MX": "Monto del préstamo solicitado (Q)",
          },
        },
        {
          type: "expression",
          name: "loan_term",
          title: {
            en: "Loan Term (months)",
            "es-MX": "Plazo del préstamo (meses)",
          },
        },
        {
          type: "expression",
          name: "monthly_installment",
          title: { en: "Monthly fees", "es-MX": "Cuota mensual" },
        },
        {
          type: "expression",
          name: "repayment_period",
          title: {
            en: "expected Disbursement date if loan is approved:",
            "es-MX": "Fecha prevista de desembolso si se aprueba el préstamo",
          },
        },
        {
          type: 'panel',
          elements: [
            {
              type: "html",
              html: {
                en: "<div> To ensure that you are serious about a timely monthly payment, you will be charged % for every day you are late with payment. </br>" +
                " If granted, the loan duration will be $<amount> months, the loan will be repaid in full. For this loan the total fees will be $<amount> </br> " +
                "No variation or amendment of this Agreement shall be valid unless committed to writing and signed by or on behalf of both parties</div>",
                "es-MX":
                  "<div> Recuerda efectuar siempre tu pago puntual, de lo contrario tendrás un recargo del 2%  mensual de mora por cada atraso.</br> " + 
                  "Si se concede, la duración del préstamo será de $<amount> meses, y las cuotas serán de $<amount>.</br>" + 
                  "Ninguna variación o enmienda de este Acuerdo será válida a menos que se comprometa por escrito y esté firmada por o en nombre de ambas partes.", 
              },
            }
          ]
        },
        {
          type: 'checkbox',
          name: "confirm_4",
          isRequired: required,
          storeOthersAsComment: false,
          choices: [
            { value: "I agree", text: { en: "I agree", "es-MX": "Estoy de acuerdo" } },
          ],
          title: {
            en: "I understand the risks involved and will guarantee the repayment of the loan.",
            "es-MX": "Entiendo los riesgos involucrados y garantizaré el reembolso del préstamo.",
          },
        },
        {
          title: { en: "Sign loan application", "es-MX": "Firmar aplicacion" },
          type: "signaturepad",
          isRequired: true,
          name: "",
        },
      ],
    },
  ],
};
