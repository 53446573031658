import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const crediavance_assessment_study = [
  {
    title: {
      en: "Housing conditions and economy",
      "es-MX": "Condiciones de vivienda y economia",
    },
    innerIndent: 1,
    name: "housing_conditions_economy_page",
    elements: [
      {
        title: {
          en: "Material with which it is built?",
          "es-MX": "¿Material con la que está construida?",
        },
        type: "text",
        name: "custom_text_6",
        isRequired: required,
      },
      {
        title: {
          en: "What does the house look like in appearance?",
          "es-MX": "¿Cómo se ve la casa en apariencia?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_7",
        choices: [
          {
            value: "habitada",
            text: { en: "Inhabited", "es-MX": "Habitada" },
          },
          {
            value: "sbandonada",
            text: { en: "Abandoned", "es-MX": "Abandonada" },
          },
        ],
      },
      {
        title: {
          en: "Where did you live previously?",
          "es-MX": "¿Dónde vivía anteriormente?",
        },
        type: "text",
        name: "custom_text_98",
        isRequired: required,
      },
      {
        title: {
          en: "How long did I live there?",
          "es-MX": "¿Cúanto tiempo vivió ahí?",
        },
        type: "text",
        name: "custom_text_32",
        isRequired: required,
      },
      {
        type: "panel",
        title: {
          en: "Housing items and services",
          "es-MX": "Artículos y servicios de la vivienda",
        },
        name: "housing_items_services_panel",
        elements: [
          {
            title: { en: "Stove", "es-MX": "Estufa" },
            name: "custom_text_104",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Fridge", "es-MX": "Refrigerador" },
            name: "custom_text_105",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Dining room", "es-MX": "Comedor" },
            name: "custom_text_106",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "TV", "es-MX": "TV" },
            name: "custom_text_107",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Electricity", "es-MX": "Luz" },
            name: "custom_text_108",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Water", "es-MX": "Agua" },
            name: "custom_text_109",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Internet", "es-MX": "Internet" },
            name: "custom_text_110",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Air conditioner", "es-MX": "Aire acondicionado" },
            name: "custom_text_111",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Sewer system", "es-MX": "Drenaje" },
            name: "custom_text_112",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
          {
            title: { en: "Paved street", "es-MX": "Calle Pavimentada" },
            name: "custom_text_113",
            colCount: 2,
            type: "radiogroup",
            choices: [
              {
                value: "Sí",
                text: {
                  en: "Yes",
                  "es-MX": "Sí",
                },
              },
              {
                value: "No",
                text: {
                  en: "No",
                  "es-MX": "No",
                },
              },
            ],
          },
        ],
      },
      {
        type: "panel",
        title: {
          en: "Housing distribution",
          "es-MX": "Distribución de la vivienda",
        },
        name: "housing_distribution_panel",
        elements: [
          {
            title: { en: "Kitchens", "es-MX": "Cocina" },
            type: "text",
            inputType: "number",
            name: "custom_integer_2",
            isRequired: required,
          },
          {
            title: { en: "Living rooms", "es-MX": "Sala" },
            type: "text",
            inputType: "number",
            name: "custom_integer_3",
            isRequired: required,
          },
          {
            title: { en: "Bedrooms", "es-MX": "Recamaras" },
            type: "text",
            inputType: "number",
            name: "custom_integer_4",
            isRequired: required,
          },
          {
            title: { en: "Bathrooms", "es-MX": "Baños" },
            type: "text",
            inputType: "number",
            name: "custom_integer_5",
            isRequired: required,
          },
          {
            title: { en: "Garages", "es-MX": "Cochera" },
            type: "text",
            inputType: "number",
            name: "custom_integer_6",
            isRequired: required,
          },
        ],
      },

      {
        title: {
          en: "Please upload a photo of the house outside with the applicant",
          "es-MX":
            "Por favor suba una foto de la vivienda por fuera con el solicitante",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a photo of the house inside with the applicant",
          "es-MX":
            "Por favor suba una foto de la vivienda por dentro con el solicitante",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_2",
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a photo of the house outside with the manager",
          "es-MX":
            "Por favor suba una foto de la vivienda por fuera con el gestor",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_3",
        isRequired: required,
      },
      {
        type: "panel",
        title: {
          en: "Housing info",
          "es-MX": "Datos de la casa",
        },
        name: "house_info_panel",
        elements: [
          {
            title: { en: "Colour", "es-MX": "Color" },
            type: "text",
            name: "custom_text_9",
            isRequired: required,
          },
          {
            title: { en: "Outdoor Number", "es-MX": "Número exterior" },
            type: "text",
            name: "home_house_number",
            isRequired: required,
          },
          {
            title: {
              en: "Particular signs",
              "es-MX": "Señas particulares",
            },
            type: "text",
            name: "custom_text_99",
            isRequired: required,
          },
          {
            title: { en: "Nearby businesses", "es-MX": "Negocios cercanos" },
            type: "text",
            name: "custom_text_11",
            isRequired: required,
          },
        ],
      },
      {
        type: "panel",
        title: {
          en: "Risk of the area",
          "es-MX": "Riesgo de la zona",
        },
        name: "area_risk_panel",
        elements: [
          {
            title: {
              en: "Fractional Housing",
              "es-MX": "Vivienda de Fraccionamiento",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_12",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: { en: "Comments", "es-MX": "Comentarios" },
            type: "text",
            name: "custom_text_100",
            isRequired: required,
          },
          {
            title: {
              en: "Good condition of the house",
              "es-MX": "Buen estado de la vivienda",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_14",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: { en: "Comments", "es-MX": "Comentarios" },
            type: "text",
            name: "custom_text_23",
            isRequired: required,
          },
          {
            title: {
              en: "Home or cars for sale",
              "es-MX": "Vivienda o autos en venta",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_16",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: { en: "Comments", "es-MX": "Comentarios" },
            type: "text",
            name: "custom_text_17",
            isRequired: required,
          },
          {
            title: {
              en: "Notes or notices of collections from other Companies",
              "es-MX": "Notas o avisos de cobros de otras Empresas",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_18",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: { en: "Comments", "es-MX": "Comentarios" },
            type: "text",
            name: "custom_text_19",
            isRequired: required,
          },
        ],
      },
      {
        title: { en: "Observations", "es-MX": "Observaciones" },
        type: "text",
        name: "custom_text_20",
        isRequired: required,
      },
      {
        type: "panel",
        title: {
          en: "I declare under protest of saying the truth that the information provided here was taken at my address which is authentic and what is expressed in what is written is true.",
          "es-MX":
            "Manifiesto bajo protesta de decir verdad que la información aquí proporcionada se tomó en mi domicilio la cuál es auténtica y lo que se expresa en lo escrito es lo cierto.​",
        },
        elements: [
          {
            // Credit check currently only has an input for name -> first_name
            title: {
              en: "Applicant's full name",
              "es-MX": "Nombre completo del solicitante",
            },
            type: "expression",
            expression: "getFullName({first_name},{surname},{second_surname})",
            isRequired: required,
          },
          {
            title: {
              en: "Applicant's digital signature",
              "es-MX": "Firma digital del solicitante",
            },
            type: "signaturepad",
            isRequired: true,
            name: "file_19",
          },
        ],
      },
    ],
  },
  {
    title: {
      en: "Familiy info",
      "es-MX": "Entorno familiar",
    },
    innerIndent: 1,
    name: "family_info_page",
    elements: [
      {
        title: {
          en: "Direct relatives who do NOT live with the applicant",
          "es-MX": "Familiares directos que NO viven con el solicitante",
        },
        name: "custom_jsonfield_1",
        valueName: "custom_jsonfield_1",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        horizontalScroll: true,
        columnLayout: "vertical",
        addRowLocation: "bottom",
        columnMinWidth: "130px",
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Full name", "es-MX": "Nombre completo" },
            name: "nombre_completo",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Dead or alive", "es-MX": "Finado o No Finado" },
            name: "finado",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "dropdown",
            choices: [
              { value: "finado", text: { en: "Dead", "es-MX": "Finado" } },
              {
                value: "no_finado",
                text: { en: "Alive", "es-MX": "No Finado" },
              },
            ],
          },
          {
            title: { en: "Relationship", "es-MX": "Parentesco" },
            name: "parentesco",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "text",
            inputType: "string",
            visibleIf: "{row.finado} != 'finado'",
          },
          {
            title: { en: "Telephone", "es-MX": "Teléfono" },
            name: "telefono_familiares_directos_no_viven",
            requiredIf: "{row.nombre_completo} != ''",
            visibleIf: "{row.finado} != 'finado'",
            cellType: "phone",
            validators: [
              expressions.phone_en("telefono_familiares_directos_no_viven"),
              expressions.phone_length("telefono_familiares_directos_no_viven"),
            ],
          },
          {
            title: { en: "Age", "es-MX": "Edad" },
            name: "edad",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "text",
            inputType: "number",
            visibleIf: "{row.finado} != 'finado'",
          },
          {
            title: { en: "Gender", "es-MX": "Sexo" },
            name: "sexo",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "dropdown",
            inputType: "string",
            choices: [
              { value: "hombre", text: { en: "Male", "es-MX": "Hombre" } },
              { value: "mujer", text: { en: "Female", "es-MX": "Mujer" } },
            ],
            visibleIf: "{row.finado} != 'finado'",
          },
          {
            title: { en: "Marital status", "es-MX": "Estado civil" },
            name: "estado_civil",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "dropdown",
            inputType: "string",
            visibleIf: "{row.finado} != 'finado'",
            choices: [
              {
                value: "casado",
                text: { en: "Married", "es-MX": "Casado" },
              },
              {
                value: "soltero",
                text: { en: "Single", "es-MX": "Soltero" },
              },
              {
                value: "viudo",
                text: { en: "Widowed", "es-MX": "Viudo" },
              },
              {
                value: "divorciado",
                text: { en: "Divorced", "es-MX": "Divorciado" },
              },
              {
                value: "union_libre",
                text: { en: "Common law", "es-MX": "Unión Libre" },
              },
            ],
          },
          {
            title: { en: "School level", "es-MX": "Nivel escolar" },
            name: "nivel_escolar",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "dropdown",
            inputType: "string",
            visibleIf: "{row.finado} != 'finado'",
            choices: [
              {
                value: "primaria",
                text: { en: "Primary", "es-MX": "Primaria" },
              },
              {
                value: "secundaria",
                text: { en: "Secondary", "es-MX": "Secundaria" },
              },
              {
                value: "preparatoria",
                text: { en: "Preparatory", "es-MX": "Preparatoria" },
              },
              {
                value: "carrera_tecnica",
                text: { en: "Technical career", "es-MX": "Carrera técnica" },
              },
              {
                value: "licenciatura",
                text: { en: "Bachelor's degree", "es-MX": "Licenciatura" },
              },
            ],
          },
          {
            title: { en: "Occupation", "es-MX": "Ocupación" },
            name: "ocupacion",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "text",
            inputType: "string",
            visibleIf: "{row.finado} != 'finado'",
          },
          {
            title: { en: "Company / School", "es-MX": "Empresa/Escuela" },
            name: "empresa_escuela",
            requiredIf: "{row.nombre_completo} != ''",
            cellType: "text",
            inputType: "string",
            visibleIf: "{row.finado} != 'finado'",
          },
        ],
      },
      {
        title: {
          en: "Direct relatives living with the applicant",
          "es-MX": "Familiares directos que viven con el solicitante",
        },
        name: "custom_jsonfield_2",
        valueName: "custom_jsonfield_2",
        type: "matrixdynamic",
        minRowCount: 0,
        rowCount: 1,
        horizontalScroll: true,
        columnLayout: "vertical",
        addRowLocation: "bottom",
        columnMinWidth: "130px",
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Full name", "es-MX": "Nombre completo" },
            name: "nombre_completo",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Relationship", "es-MX": "Parentesco" },
            name: "parentesco",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Telephone", "es-MX": "Teléfono" },
            name: "telefono_familiares_directos_viven",
            cellType: "phone",
            validators: [
              expressions.phone_en("telefono_familiares_directos_viven"),
              expressions.phone_length("telefono_familiares_directos_viven"),
            ],
          },
          {
            title: { en: "Age", "es-MX": "Edad" },
            name: "edad",
            cellType: "text",
            inputType: "number",
          },
          {
            title: { en: "Gender", "es-MX": "Sexo" },
            name: "sexo",
            cellType: "dropdown",
            inputType: "string",
            choices: [
              { value: "hombre", text: { en: "Male", "es-MX": "Hombre" } },
              { value: "mujer", text: { en: "Female", "es-MX": "Mujer" } },
            ],
          },
          {
            title: { en: "Marital status", "es-MX": "Estado civil" },
            name: "estado_civil",
            cellType: "dropdown",
            inputType: "string",
            choices: [
              {
                value: "casado",
                text: { en: "Married", "es-MX": "Casado" },
              },
              {
                value: "soltero",
                text: { en: "Married", "es-MX": "Soltero" },
              },
              {
                value: "viudo",
                text: { en: "Widowed", "es-MX": "Viudo" },
              },
              {
                value: "divorciado",
                text: { en: "Divorced", "es-MX": "Divorciado" },
              },
              {
                value: "union_libre",
                text: { en: "Common law", "es-MX": "Unión Libre" },
              },
            ],
          },
          {
            title: { en: "School level", "es-MX": "Nivel escolar" },
            name: "nivel_escolar",
            cellType: "dropdown",
            inputType: "string",
            choices: [
              {
                value: "primaria",
                text: { en: "Primary", "es-MX": "Primaria" },
              },
              {
                value: "secundaria",
                text: { en: "Secondary", "es-MX": "Secundaria" },
              },
              {
                value: "preparatoria",
                text: { en: "Preparatory", "es-MX": "Preparatoria" },
              },
              {
                value: "carrera_tecnica",
                text: { en: "Technical career", "es-MX": "Carrera técnica" },
              },
              {
                value: "licenciatura",
                text: { en: "Bachelor's degree", "es-MX": "Licenciatura" },
              },
            ],
          },
          {
            title: { en: "Occupation", "es-MX": "Ocupación" },
            name: "ocupacion",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Company / School", "es-MX": "Empresa/Escuela" },
            name: "empresa_escuela",
            cellType: "text",
            inputType: "string",
          },
        ],
      },
      {
        title: {
          en: "Applicant s income",
          "es-MX": "Ingresos del solicitante",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_1",
        isRequired: required,
      },
      {
        title: {
          en: "Total household income",
          "es-MX": "Ingresos totales del hogar",
        },
        type: "text",
        inputType: "number",
        name: "custom_float_2",
        isRequired: required,
      },
    ],
  },
  {
    title: {
      en: "Expenses, assets and debts",
      "es-MX": "Gastos, bienes y adeudos",
    },
    innerIndent: 1,
    name: "expenses_assets_debts",
    elements: [
      {
        title: {
          en: "Do you have a car?",
          "es-MX": "¿Cuenta con algún auto?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_21",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Cars",
          "es-MX": "Autos",
        },
        name: "custom_jsonfield_6",
        valueName: "custom_jsonfield_6",
        type: "matrixdynamic",
        visibleIf: "{custom_text_21} == 'si'",
        minRowCount: 1,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Owner", "es-MX": "Propietario" },
            name: "Propietario",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Year",
              "es-MX": "Año",
            },
            name: "Año",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Type",
              "es-MX": "Tipo",
            },
            name: "Tipo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            name: "marca",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Do you have a purchase agreement?",
              "es-MX": "¿Tiene una contrato de compraventa?",
            },
            cellType: "file_uploader",
            isRequired: false,
            accept: "image/*,application/pdf",
            name: "prueba_de_vehiculo",
          },
        ],
      },
      {
        title: {
          en: "Do you have any property?",
          "es-MX": "¿Cuenta con algún inmueble?",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_22",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Properties (houses, land, premises, etc.)",
          "es-MX": "Inmuebles (casas, terrenos, locales, etc.)",
        },
        name: "custom_jsonfield_7",
        valueName: "custom_jsonfield_7",
        visibleIf: "{custom_text_22} == 'si'",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 4,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Owner", "es-MX": "Propietario" },
            name: "Propietario",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Year",
              "es-MX": "Año",
            },
            name: "Año",
            inputType: "number",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Type",
              "es-MX": "Tipo",
            },
            name: "Tipo",
            isRequired: required,
            cellType: "text",
          },
          {
            title: {
              en: "Commercial value",
              "es-MX": "Valor comercial",
            },
            name: "Valor comercial",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
          {
            title: {
              en: "Do you have a deed, property or purchase agreement?",
              "es-MX":
                "¿Tiene una escritura, propiedad o contrato de compraventa?",
            },
            cellType: "file_uploader",
            isRequired: false,
            accept: "image/*,application/pdf",
            name: "Prueba de propiedad",
          },
        ],
      },
      {
        title: {
          en: "Monthly Expenses",
          "es-MX": "Gastos mensuales",
        },
        name: "monthly_expenses",
        valueName: "monthly_expenses",
        type: "matrixdynamic",
        minRowCount: 0,
        maxRowCount: 10,
        rowCount: 1,
        isRequired: false,
        addRowText: {
          en: "Add",
          "es-MX": "Añadir",
        },
        columns: [
          {
            title: { en: "Type", "es-MX": "Tipo" },
            name: "Tipo",
            isRequired: required,
            cellType: "dropdown",
            inputType: "string",
            hasOther: "true",
            otherText: { en: "Other", "es-MX": "Otros" },
            choices: [
              {
                value: "Alimentación",
                text: { en: "Food", "es-MX": "Alimentación" },
              },
              {
                value: "Pago de renta o hipoteca",
                text: {
                  en: "Payment of Rent or Mortgage",
                  "es-MX": "Pago de renta o hipoteca",
                },
              },
              {
                value: "Luz",
                text: { en: "Electricity", "es-MX": "Luz" },
              },
              { value: "Agua", text: { en: "Water", "es-MX": "Agua" } },
              { value: "Gas", text: { en: "Gas", "es-MX": "Gas" } },
              {
                value: "Teléfono o celular",
                text: {
                  en: "Telephone or cell phone",
                  "es-MX": "Teléfono o celular",
                },
              },
              {
                value: "Ropa o calzado",
                text: {
                  en: "Clothes or footwear",
                  "es-MX": "Ropa o calzado",
                },
              },
              {
                value: "Transporte o gasolina",
                text: {
                  en: "Transportation and gasoline",
                  "es-MX": "Transporte o gasolina",
                },
              },
              {
                value: "Servicio médico",
                text: { en: "Medical Service", "es-MX": "Servicio médico" },
              },
              {
                value: "Diversión (internet/cable)",
                text: {
                  en: "internet or cable",
                  "es-MX": "Diversión (internet/cable)",
                },
              },
            ],
          },
          {
            title: {
              en: "Amount",
              "es-MX": "El importe",
            },
            cellType: "text",
            inputType: "number",
            name: "El importe",
            isRequired: required,
          },
        ],
      },
      {
        title: {
          en: "Total monthly expenses",
          "es-MX": "Total de gastos mensuales",
        },
        type: "expression",
        name: "total_monthly_expenses",
        visibleIf: "fixed(sumInArray({monthly_expenses}, 'El importe'), 2) > 0",
        expression: "fixed(sumInArray({monthly_expenses}, 'El importe'), 2)", // Floating Point Proof
        displayStyle: "currency",
        currency: "MXN",
      },
    ],
  },
  {
    title: {
      en: "Neighborhood and personal references",
      "es-MX": "Referencias vecinales y personales",
    },
    description: {
      en: "Tell us the contact references of the applciant. We may contact",
      "es-MX":
        "Indicanos el contacto de las referecnias del aplicante. Puede que contactemos ",
    },
    elements: [
      {
        name: "custom_jsonfield_4",
        valueName: "custom_jsonfield_4",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 2,
        columnLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: true,
        addRowText: { en: "Add", "es-MX": "Añadir" },
        title: {
          en: "Neighbor references",
          "es-MX": "Referencias vecinales",
        },
        columns: [
          {
            title: { en: "Name", "es-MX": "Nombre" },
            name: "nombre",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Address", "es-MX": "Domicilio" },
            name: "domicilio",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Telephone", "es-MX": "Teléfono" },
            name: "telefono_referencias_vecinales",
            isRequired: "required",
            cellType: "phone",
            validators: [
              expressions.phone_en("telefono_referencias_vecinales"),
              expressions.phone_length("telefono_referencias_vecinales"),
            ],
          },
          {
            title: {
              en: "Time of knowing applicant",
              "es-MX": "Tiempo de conocerlo",
            },
            name: "tiempo_de_conocerlo",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Comments about the applicant",
              "es-MX": "Comentarios acerca del solicitante",
            },
            name: "comentarios_acerca_del_solicitante",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
        ],
      },
      {
        name: "custom_jsonfield_5",
        valueName: "custom_jsonfield_5",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 2,
        columnLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: true,
        addRowText: { en: "Add", "es-MX": "Añadir" },
        title: {
          en: "Personal references",
          "es-MX": "Referencias personales",
        },
        columns: [
          {
            title: { en: "Name", "es-MX": "Nombre" },
            name: "nombre",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Address", "es-MX": "Domicilio" },
            name: "domicilio",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
          {
            title: { en: "Telephone", "es-MX": "Teléfono" },
            name: "telefono_referencias_personal",
            isRequired: "required",
            cellType: "phone",
            validators: [
              expressions.phone_en("telefono_referencias_personal"),
              expressions.phone_length("telefono_referencias_personal"),
            ],
          },
          {
            title: { en: "Time to meet him", "es-MX": "Tiempo de conocerlo" },
            name: "tiempo_de_conocerlo",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Comments about the applicant",
              "es-MX": "Comentarios acerca del solicitante",
            },
            name: "comentarios_acerca_del_solicitante",
            isRequired: "required",
            cellType: "text",
            inputType: "string",
          },
        ],
      },
      {
        title: {
          en: "Suggested times for credit committee calls",
          "es-MX": "Horario sugerido para llamadas de comité de crédito",
        },
        type: "text",
        inputType: "time",
        name: "custom_text_102",
        isRequired: "required",
      },
      {
        title: {
          en: "Full name and signature of the person responsible for the visit",
          "es-MX": "Nombre completo y firma del responsable de la visita",
        },
        type: "text",
        name: "custom_text_103",
        isRequired: "required",
      },
      {
        html: {
          en: "Knowing the sanctions to which I can be a creditor, I declare under protest of saying the truth that the information provided here is authentic and what is expressed in what is written is true.",
          "es-MX":
            "<strong>Conociendo las sanciones a las que puedo ser acreedor (a), manifiesto bajo protesta de decir verdad que la información que aquí se proporciona es auténtica y lo que se expresa en lo escrito es lo cierto.</strong>",
        },
        type: "html",
      },
      {
        title: { en: "Online signature", "es-MX": "Firma online" },
        type: "signaturepad",
        isRequired: true,
        name: "file_20",
      },
    ],
  },
];
