
export const crediavance_buttons = (loan) => {
  
    let actionButtonList = [];
  
    let startIntakeBtn = {
      title: "Start Intake",
      localeTitle: "Comenzar a aplicar",
      nextState: "applying_intake",
    };
    if (!loan.intake.custom_bool_8) {
      actionButtonList.push(startIntakeBtn);
    }
  
    let assessmentStudyBtn = {
      title: "Start Assessment Study",
      localeTitle: "Estudio socioeconomico",
      nextState: "assessment_study",
    };
    if (!loan.intake.custom_bool_9 && loan.intake.custom_bool_8) {
      actionButtonList.push(assessmentStudyBtn);
    }

    let interviewBtn = {
        title: "Start Interview",
        localeTitle: "Entrevista",
        nextState: "interview",
      };
      if (!loan.intake.custom_bool_10 && loan.intake.custom_bool_8) {
        actionButtonList.push(interviewBtn);
      }
  
    return actionButtonList;
  };