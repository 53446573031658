import {BrowserRouter, Switch, Route} from 'react-router-dom';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import React, {Component} from 'react';

import {HomeSwitch} from "./containers/home/home.switch";
import SjabloonScreen from "./containers/sjabloon/sjabloon.screen";


class Redirect extends Component {
  constructor(props) {
    super();
    window.location = 'https://www.money-phone.com';
  }

  render = () => <p className='center bigSpacer'>Redirecting...</p>
}


export class Router extends React.PureComponent {
  render = () => (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path='/a/:uuid/:token' component={HomeSwitch}/>
          <Route exact path='/' component={Redirect}/>
          <Route exact path='/sjabloon' component={SjabloonScreen}/>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  )
}
