import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { applicant_eduloan } from './pages/eduloan/01_applicant_eduloan';
import { identification_eduloan } from './pages/eduloan/02_identification_eduloan';
import { student_eduloan } from './pages/eduloan/03_student_eduloan';
import { income_eduloan } from './pages/eduloan/04_income_details_eduloan';
import { references_eduloan } from './pages/eduloan/05_references_eduloan';

export function educational_intake(locale = DEFAULT_LOCALE) {
  const eduloan_pages = [
    applicant_eduloan,
    identification_eduloan,
    student_eduloan,
    income_eduloan,
    references_eduloan,
  ];

  const currentDeploymentPages =  eduloan_pages

  return {
    showProgressBar: 'top',
    showQuestionNumbers: 'off',
    sendResultOnPageNext: true,
    firstPageIsStarted: false,
    startSurveyText: translate('general.startSurveyText'),
    showCompletedPage: false,
    pagePrevText: translate('general.pagePrevText'),
    pageNextText: translate('general.pageNextText'),
    completeText: translate('general.pageNextText'), // note same as `Next`
    requiredText: '',
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
    locale,
  };
}
