export const review_demo = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    ar: "تقديم طلب القرض",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review your loan application",
        ar: "مراجعة طلب القرض الخاص بك",
      },
      elements: [
        {
          type: "panel",
          title: "",
          elements: [
            {
              type: "expression",
              name: "today_date",
              expression: "daysFromNow(0, 0, 'ar')",
              title: { en: "Date", ar: "التاريخ" },
            },
            {
              type: "expression",
              title: {
                en: "NAME",
                ar: "الاسم",
              },
              expression: `getFullName({first_name},{middle_name},{surname},{second_surname})`,
            },
            {
              type: "expression",
              expression: "age({date_of_birth})",
              title: { en: "AGE", ar: "العمر" },
            },
            {
              type: "expression",
              expression: "{home_street}",
              title: {
                en: "ADDRESS",
                ar: "العنوان",
              },
            },
            {
              type: "expression",
              expression: "{phone_number}",
              title: {
                en: "TELEPHONE",
                ar: "الهاتف",
              },
            },
            {
              type: "expression",
              expression: "capitalize('first', {marital})",
              title: {
                en: "MARITAL STATUS",
                ar: "الحالة الزوجية",
              },
            },
            {
              type: "expression",
              expression: "{amount}",
              title: {
                en: "AMOUNT REQUESTED",
                ar: "المبلغ المطلوب",
              },
              displayStyle: "currency",
              currency: "USD",
            }
          ],
        },
      ],
    },
  ],
};
