import { Component } from "react";

export class SurveyComponent extends Component {
  constructor(data) {
    super();
    Object.assign(this, data);
  }

  get data() {
    return this;
  }
  setName = (name) => this.set("name", name);
  setVisibleIf = (condition) => this.set("visibleIf", condition);
  setTitle = (title) => this.set("title", title);

  set = (k, v) => {
    const component = this.clone();
    component[k] = v;
    return component;
  };

  clone = () => new SurveyComponent(this);
}
