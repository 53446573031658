import PropTypes from 'prop-types';
import React from 'react';

import './fab.scss';
import {Icon} from "../icon/icon";
import {Spinner} from 'react-activity';


export class FabButton extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.number,

    title: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.number,

    isLoading: PropTypes.bool,

    // Functions
    onPress: PropTypes.func,
    style: PropTypes.any,
  };

  static defaultProps = {
    backgroundColor: '#000',
    color: '#fff',
    isLoading: false,
    title: '',
    icon: null,
    textColor: '#000',
    fontSize: 12,
    size: 50,
    style: {},

  };

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render({backgroundColor, color, title, icon, textColor, fontSize, size, isLoading, onPress, style} = this.props) {
    return (
      <div className="fabContainer" style={style}>
        {// eslint-disable-next-line
        }<a className="button"
           onClick={!isLoading ? onPress : () => {
           }}
           style={{
             width: size,
             height: size,
             backgroundColor: backgroundColor,
             color: color
           }}>

          {!isLoading ? (
              <div className="icon">
                <Icon icon={icon} size={size / 2} color={color}/>
              </div>
            ) :
            <Spinner className="spinner" color={color} size={size / 4} speed={1}/>
          }

        </a>
        {title && (
          <span className="title" style={{fontSize: fontSize, color: textColor}}>
            {title}
          </span>
        )}
      </div>
    )
  }
}
