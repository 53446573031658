import {accountState} from './account.state';

export function accountReducer(state = accountState, action) {
  switch (action.type) {

    case 'SET_LOCALE_SUCCESS':
      return {
        ...state,
        localePending: false,
      };

    case 'RESET_ACCOUNT_PENDING_STATES':
      return {
        ...state,
        updateAccountPending: false,
        profilePending: false,
        localePending: false,
      };

    case 'GET_ACCOUNT_PENDING':
      return {
        ...state,
        profilePending: true,
      };
    case 'GET_ACCOUNT_SUCCESS':
      return {
        ...state,
        profilePending: false,
        profile: action.profile
      };
    case 'GET_ACCOUNT_ERROR':
      return {
        ...state,
        profilePending: false,
      };

    case 'UPDATE_ACCOUNT_PENDING':
      return {
        ...state,
        updateAccountPending: true
      };
    case 'UPDATE_ACCOUNT_SUCCESS':
      return {
        ...state,
        profile: action.profile,
        updateAccountPending: false
      };
    case 'UPDATE_ACCOUNT_ERROR':
      return {
        ...state,
        updateAccountPending: false,
      };

    case 'RESET_ACCOUNT_STATE':
      return {
        ...accountState
      };

    default:
      return state;
  }
}

