import { loadLocales } from "../../lib/intl";
import { deviceState } from "./device.state";

export function deviceReducer(state = deviceState, action) {
  switch (action.type) {
    case "SET_LOCALE_PENDING":
      return {
        ...state,
        localePending: true,
      };

    case "SET_LOCALE":
      return {
        ...state,
        localePending: false,
        locale: action.locale,
      };
    case "SET_AUTH_FOR_TENANT":
      return {
        ...state,
        // address_service_token: action.api_service_tokens.address_service_token,
        api_service_tokens: action.api_service_tokens,
      };
    case "ADD_DEVICE_DATA":
      return {
        ...state,
        date_last_refreshed: Date.now(),
      };

    case "SET_HOME_PAGE":
      return {
        ...state,
        home_page: action.home_page,
      };

    case "SET_AUTHENTICATION_TOKEN":
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
      };

    case "SET_UTM_DATA":
      return {
        ...state,
        utm_campaign: action.utm_campaign,
      };

    case "SET_ALERT":
      return {
        ...state,
        alertShown: true,
        alertTitle: action.alertTitle,
        alertMessage: action.alertMessage,
        alertButtons: action.alertButtons,
      };
    case "CLEAR_ALERT":
      return {
        ...state,
        alertShown: false,
        alertMessage: "",
      };

    case "RESET_DEVICE_STATE":
      loadLocales(deviceState.locale);
      return {
        ...deviceState,
      };
    default:
      return state;
  }
}
