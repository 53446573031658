/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */
const i_agree = {
  title: "",
  type: "checkbox",
  name: "agree",
  isRequired: true,
  choices: [
    {
      value: {
        en: "Applicant has agreed to loan amount, loan terms and total fees",
        rw: "needs translation",
      },
      text: {
        en: "I agree",
        rw: "Ndabyemeye",
      },
    },
  ],
  validators: [],
};

export const review_rw_business = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and sign your loan application",
        rw: "Subiramo neza hanyuma usinyire ubusabe bwawe",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance}, {loan_purpose})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in Rwf",
                rw: "Inguzanyo yasabwe mu Rwf ",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'repayment_period'))", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in Rwf to be deducted from the loan amount (2%)",
                rw: "Umufuragiro mu Rwf akurwa ku nguzanyo yemejwe (2%)",
              },
              type: "expression",
              expression: "getLoanTerm({loan_terms}, 'total_admin_fee')", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in Rwf to be deducted from the loan amount (18%)",
                rw: "Umusoro(VAT) kumufuragiro akurwa kunguzanyo yemejwe  (18%)", //TODO nees translation
              },
              type: "expression",
              expression: "getLoanTerm({loan_terms}, 'total_vat_on_admin_fee')", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in Rwf to be deducted from the loan amount (0.6%) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe (0.6%)",
              },
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'total_insurance_fee'))", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in Rwf (16% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu Rwf (16% ku mwaka)",
              },
              name: "custom_float_5",
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'total_interest'))", // Floating Point Proof
            },
          ],
        },
        {
          type: "html",
          html: {
            en: `<h5 class="sv_q_title">You are committing to a six months payment of {amount} Rwf. You are aware that you will need to repay the whole loan of {amount} RWF plus {custom_float_5} RWF in fees.</h5>`,
            rw: `<h5 class="sv_q_title">Niyemeje kwishyura amafaranga nyuma y'amezi atandatu amafaranga angana na {amount} RWF hinyongereyeho {custom_float_5} RWF y'inyungu </h5>`,
          },
        },
        {
          type: "html",
          name: "penalty",
          html: {
            en: "To ensure that you are serious about a timely monthly payment, you will be charged 6% RWF for every month you are late with payment.",
            rw: "Kugirango twizere ko uzubahiriza  igihe cy'ubwishyu bwa buri kwezi, mugihe watinze kwishyura uzajya ucibwa amande ya 6% Rwf",
          },
        },
        i_agree,
      ],
    },
  ],
};

export const review_rw_farming = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and sign your loan application",
        rw: "Subiramo neza hanyuma usinyire ubusabe bwawe",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance}, {loan_purpose})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in Rwf",
                rw: "Inguzanyo yasabwe mu Rwf ",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              name: "custom_float_2",
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'repayment_period'))", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in Rwf to be deducted from the loan amount (2%)",
                rw: "Umufuragiro mu Rwf akurwa ku nguzanyo yemejwe (2%)",
              },
              type: "expression",
             expression: "getLoanTerm({loan_terms}, 'total_admin_fee')", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in Rwf to be deducted from the loan amount (18%)",
                rw: "Umusoro(VAT) kumufuragiro akurwa kunguzanyo yemejwe  (18%)", //TODO nees translation
              },
              type: "expression",
             expression: "getLoanTerm({loan_terms}, 'total_vat_on_admin_fee')", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in Rwf to be deducted from the loan amount (0.6%) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe (0.6%)",
              },
              type: "expression",
             expression: "(getLoanTerm({loan_terms}, 'total_insurance_fee'))", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in Rwf (16% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu Rwf (16% ku mwaka)",
              },
              name: "custom_float_1",
              type: "expression",
              expression: "(getLoanTerm({loan_terms}, 'total_interest'))", // Floating Point Proof
            },
          ],
        },
        {
          type: "html",
          html: {
            en: `<h5 class="sv_q_title">You are committing to a six months payment of {amount} Rwf. You are aware that you will need to repay the whole loan of {amount} RWF plus {custom_float_1} RWF in fees.</h5>`,
            rw: `<h5 class="sv_q_title">Niyemeje kwishyura amafaranga nyuma y'amezi atandatu amafaranga angana na {amount} RWF hinyongereyeho {custom_float_1} RWF in y'inyungu </h5>`,
          },
        },
        {
          type: "html",
          name: "penalty",
          html: {
            en: "To ensure that you are serious about a timely monthly payment, you will be charged 6% RWF for every month you are late with payment.",
            rw: "Kugirango twizere ko uzubahiriza  igihe cy'ubwishyu bwa buri kwezi, mugihe watinze kwishyura uzajya ucibwa amande ya 6% Rwf",
          },
        },
        i_agree,
      ],
    },
  ],
};
