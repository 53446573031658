import React from "react";
import { connect } from "react-redux";
import * as Survey from "survey-react";
import { select_survey_js_model } from '../../survey/survey.utils';
import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import { Start } from "../../components/start/start";
import Config from "../../config";
import { DEFAULT_LOCALE, translate, translateList } from "../../lib/intl";
import { loanActions } from "../../store/store";
import {
  calc_loanterms,
  calc_start_repayment_date,
} from "../../survey/calculations";
import {
  removeElementTitles,
  saveBase64,
  useBase,
} from "../../survey/survey.utils";
import { sign_eduloan } from "./eduloan/sign_eduloan";
import { sign } from "./sign";
import "./sign.container.scss";
import { sign_banco_popular } from "./sign_banco_popular";
import { sign_bll } from "./sign_bll";
import { sign_crediavance } from "./sign_crediavance";
import { sign_credicapital } from "./sign_credicapital";
import { sign_ffdl } from "./sign_ffdl";
import { sign_finadev } from "./sign_finadev";
import { sign_rentamigo } from "./sign_rentamigo";
import { sign_rwanda } from "./sign_rwanda";
import {
  sign_rwanda_direct_model_chili,
  sign_rwanda_direct_model_default,
  sign_rwanda_direct_model_tea
} from './sign_rwanda_direct_model';

class SignContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const start_repayment_date = calc_start_repayment_date(0, new Date());

    const sign_rwanda_direct_model = {
      default: sign_rwanda_direct_model_default,
      carrots: sign_rwanda_direct_model_default,
      chili: sign_rwanda_direct_model_chili,
      potatoes: sign_rwanda_direct_model_default,
      tea: sign_rwanda_direct_model_tea
    }

    const deploymentModels = {
      rw: sign_rwanda,
      ffdl: sign_ffdl,
      rwanda_direct_model: select_survey_js_model(props.loan.loan_purpose, sign_rwanda_direct_model),
      eduloan: sign_eduloan(translateList("toc")),
      mfw: sign,
      finadev: sign_finadev,
      crediavance: sign_crediavance,
      credicapital: sign_credicapital,
      rentamigo: sign_rentamigo,
      banco_popular: sign_banco_popular,
      bll: sign_bll,
    };

    // setup model
    const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
      const model =
        deploymentModels[Config.deployment] !== undefined
          ? deploymentModels[Config.deployment]
          : deploymentModels.mfw;
      model.locale = locale;
      return model;
    };

    this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

    //add backend calculated loan terms & intake data
    let loan_terms_summary = props.loan.loan_terms_summary
      ? props.loan.loan_terms_summary
      : {};
    const intake = props.loan.intake;

    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan,
      ...loan_terms_summary,
      intake: intake,
      province: props.loan.intake.province,
      cell: props.loan.intake.cell,
      district: props.loan.intake.district,
      home_country: props.loan.intake.home_country,
      village: props.loan.intake.village,
      first_name: props.loan.intake.first_name,
      last_name: props.loan.intake.last_name,
      bank_name: props.loan.intake.custom_text_8,
      bank_account: props.loan.intake.custom_text_9,
      mobile: props.loan.intake.custom_text_6,
      phone_number: props.loan.intake.phone_number,
      cooperative_name: props.loan.intake.cooperative_name,
      admin_fee: this.props.loan.loan_terms_summary?.charge_dict.charge_code_1?.percentage ?? '',
      vat_on_admin_fee: this.props.loan.loan_terms_summary?.charge_dict.charge_code_2?.description ?? '',
      insurance_fee: this.props.loan.loan_terms_summary?.charge_dict.charge_code_3?.percentage ?? '',

      // reset signature because of possible CORS
      // errors when not setting it.
      signature: "",
      start_repayment_date_translated: translate("general.date", {
        date_in: start_repayment_date,
      }),
    };

    // set callbacks
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };
  }

  onCompleting(survey) {
    let data = survey.data;

    let loan_terms_summary = this.props.loan.loan_terms_summary
      ? this.props.loan.loan_terms_summary
      : {};
    let loan_terms = {
      total_fees: 0,
      agreement: "",
      repayment_period: data.repayment_period,
      ...loan_terms_summary,
    };

    data.agreement = loan_terms.agreement;
    //calculate loan terms and set agrement for mfw, rwanda.
    if (["mfw", "rw"].includes(Config.deployment)) {
      loan_terms = calc_loanterms([
        data.amount,
        data.repayment_period,
        data.monthly_insurance,
        null,
      ]);
    }

    // first save signature
    saveBase64(
      "signature.png",
      "image/png",
      data.signature,
      (signature_date) => {
        this.props
          .signApplication(
            this.props.loan.uuid,
            signature_date,
            useBase(this.base, data),
            loan_terms
          )
          .then((_loan) => this.props.setPage("paying"));
      }
    );
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time:
        this.state.help_time +
        Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render = ({ history, account, device, loan } = this.props) => (
    <div className="saveContainer centerWrapper">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="guarantor"
        locale={device.locale}
      />

      {this.state.is_started ? (
        <div className="centerWrapper">
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="sign"
            time="2"
            onStart={() => this.setState({ is_started: true })}
          >
            <h2 className="heavy bigSpacer">
              {translate("start_signing.header", {
                first_name: loan.first_name,
              })}
            </h2>
            <p className="heavy">
              {translate("start_signing.desc1", {
                first_name: loan.first_name,
                amount: loan.amount,
              })}
            </p>
            <p className="spacer">{translate("start_signing.desc2")}</p>
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );

  componentDidMount() {
    removeElementTitles(document, ["removeMe"]);
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signApplication: (...params) =>
      dispatch(loanActions.signApplication(...params)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignContainer);

export { connectedContainer as SignContainer };
