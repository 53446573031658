function TocEduloan(toc_json) {
  const toc = [];

  // sections
  toc_json.sections.forEach((section, i) => {
    toc.push(`
      <p class="large heavy bigSpacer" key={uuidv4()}>
        ${i + 1}. ${section.text}
      </p>
    `);
    if (section.subText) {
      toc.push(`
        <p class="heavy medium" key={uuidv4()}>
        ${section.subText}
        </p>
      `)
    }
    // subsections
    section.subsections.forEach((subsection, j) => {
      toc.push(`
        <p class="spacer" key={uuidv4()}>
          <div class="subsection_header">${i + 1}.${j + 1}</div> 
          ${subsection.text}
        </p>
      `);
      if (subsection.points) {
        subsection.points.forEach((point, k) => {
          toc.push(`
            <p class="smallSpacer" key={uuidv4()}>
            <div class="point_header">${i + 1}.${j + 1}.${k + 1}</div> 
              ${point}
            </p>
          `);
        });
      }
    });
  });

  return {
    title: {
      en: toc_json.header,
    },
    name: "toc",
    elements: [
      {
        type: "html",
        title: "",
        html: toc.join(""),
      },
      {
        html: `<p class='heavy'>${toc_json.signed}</p>`,
        type: "html",
        name: "written_signed",
      },
      {
        title: {
          en:
            "I as  the borrower  I am acknowledging  that  I have  read, understood  and agree  to  all the terms  and conditions  contained herein. I understand that signing this Application Form signifies my acceptance to be legally bound by these terms and conditions . I choose domicilium citandi et executandi at the residential address shown above and hereby submit myself  to  the  jurisdiction  of  the  courts  of  Zimbabwe  in  any  dispute  arising  from this  agreement, notwithstanding that the time that proceedings are commenced I am domiciled outside Zimbabwe.",
        },
        type: "checkbox",
        name: "agree_toc",
        isRequired: true,
        choices: [{ value: "agree", text: { en: "I agree" } }],
        validators: [],
      },
    ],
  };
}

export default TocEduloan;
