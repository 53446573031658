import env from "../../env";
const required = env.ENDPOINT !== "local";

export const sign_finadev = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: false,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-GT": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and Sign Loan",
        "fr-TD": "Examiner et signer le prêt",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Requested loan amount ",
            "fr-TD": "Montant du prêt demandé",
          },
          type: "expression",
          expression: "fixed({intake.amount}, 2)",
          displayStyle: "currency",
          currency: "XAF",
        },
        {
          title: {
            en: "Approved amount",
            "fr-TD": "Montant approuvé",
          },
          type: "expression",
          expression: "fixed({amount}, 2)",
          displayStyle: "currency",
          currency: "XAF",
        },
        {
          title: { en: "Loan term (months)", "fr-TD": "Durée du prêt (mois)" },
          type: "expression",
          expression: "{repayment_period}",
        },
        {
          title: {
            en: "Admin fees%:",
            "fr-TD": "Frais d'administration TTC (5000 ou 0,5 %) :",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_1.total}",
        },
        {
          title: {
            en: "Monthly interest",
            "fr-TD": "Taux d'intérêt total (2,36% HT)",
          },
          type: "expression",
          expression: "{total_interest}",
        },
        {
          title: {
            en: "File analysis costs with a minimum of 2000 (2,36% HT):",
            "fr-TD":
              "Frais d'analyse de dossiers avec un minimum de 2000 (2,36 % HT) :",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_2.total}",
        },
        {
          title: {
            en: "Contribution APEMFT 200 FCAF",
            "fr-TD": "Contribution APEMFT 200 FCAF",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_3.total}",
        },
        {
          title: {
            en: "Death pervoyance fund (2% or 2.5% of the amount granted depending on the duration)",
            "fr-TD":
              "Fonds de pervoyance decès (2% ou 2,5% du montant accordés en fonction de la durée).",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_4.total}",
        },
        {
          title: {
            en: "The customer towards 20% of 1,000,000 or 200,000 of the security deposit. This amount is returned to the customer at the end of the repayment",
            "fr-TD":
              "Depot de garantie (10% à 20%) en fonction du montant accordé ",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_5.total}",
        },
        {
          html: {
            en: "To ensure that you are serious about a timely monthly payment, you will be charged % for every day you are late with payment.",
            "fr-TD":
              "En cas de retard de paiement, un supplément de 3% sur chaque échéance due (capital + intérêts) vous sera facturé prorata temporis ",
          },
          type: "html",
        },
        {
          title: {
            en: "I confirm that the information given in this form is true, complete and accurate.",
            "fr-TD":
              "Je confirme que les informations fournies sur ce formulaire sont vraies, complètes et exactes.",
          },
          type: "expression",
        },
        {
          title: { en: "I agree", "fr-TD": "Je suis d'accord" },
          type: "radio",
          isRequired: required,
          name: "confirm_4",
          choices: [
            {
              value: "Je suis d'accord",
              text: { en: "I agree", "fr-TD": "Je suis d'accord" },
            },
          ],
        },
        {
          title: { en: "Sign", "fr-TD": "Signer" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
      ],
    },
  ],
};
