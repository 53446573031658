import React from 'react';
import { connect } from 'react-redux';
import * as Survey from 'survey-react';
import { Footer } from '../../components/footer/footer';
import HelpComponent from '../../components/help/help';
import { Start } from '../../components/start/start';
import { agreement } from '../../components/survey/educational_agreement_page';
import Config from '../../config';
import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { loanActions } from '../../store/store';
import { calc_education_terms } from '../../survey/calculations';
import { removeElementTitles, saveBase64, useBase } from '../../survey/survey.utils';
import '../../theme/survey.scss';
import { educationalReview } from './educational_review';
import './educational_review.container.scss';
import { educationalReviewEduloan } from './educational_review_eduloan';

class EducationalReviewContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    // setup model
    const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
      const model =
        Config.deployment && Config.deployment === 'eduloan'
          ? educationalReviewEduloan
          : educationalReview;
      model.locale = locale;
      return model;
    };

    this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

    const amount = props.loan.intake.amount ? props.loan.intake.amount : 500;
    const repayment_period = props.loan.intake.repayment_period
      ? props.loan.intake.repayment_period
      : 12;
    const intake = props.loan.intake;

    let terms = calc_education_terms(amount, repayment_period);

    // add backend driven loan terms
    let loan_terms_summary = props.loan.loan_terms_summary ? props.loan.loan_terms_summary : {};

    // only restore certain data
    this.model.data = this.base = {
      amount,
      repayment_period,
      ...terms,
      ...loan_terms_summary,
      intake: intake,
      locale: props.device.locale,
      ...this.model.data,
    };

    // set callbacks
    this.model.onCompleting.add((survey) => this.onCompleting(survey));
    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };
  }

  onCompleting(survey) {
    const data = survey.data;

    // loan data should be in survey data, but new version does not contain this data. As such recaculate
    const terms = calc_education_terms(data.amount, data.repayment_period);
    let loan_agreement =
      this.props.loan.loan_terms_summary && this.props.loan.loan_terms_summary.agreement
        ? this.props.loan.loan_terms_summary.agreement
        : '';

    if (['mfw'].includes(Config.deployment)) {
      loan_agreement = agreement(
        data.amount,
        data.repayment_period,
        'The following agreements have been made in the educational-flow:',
        this.props.device.locale
      );
    }

    saveBase64('signature.png', 'image/png', data.signature, (signature_data) => {
      data['signature'] = signature_data;
      console.log('saving data:', data);

      this.props
        .saveReview(this.props.loan.uuid, this.base, {
          ...data,
          ...terms,
          agreement: loan_agreement,
        })
        .then((_loan) => this.props.setPage('guarantor_inviting'));
    });
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time: this.state.help_time + Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render({ history, account, device } = this.props) {
    return (
      <div className="educationalReviewContainer centerWrapper">
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />

        {this.state.is_started ? (
          <div className="centerWrapper">
            <Survey.Survey model={this.model} locale={device.locale} />
          </div>
        ) : (
          <div>
            <Start stage="review" time="5" onStart={() => this.setState({ is_started: true })}>
              <p className="heavy">{translate('start_review.desc1')}</p>
              <p className="spacer">{translate('start_review.desc2')}</p>
            </Start>
            <Footer />
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    removeElementTitles(document, ['removeMe']);
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReview: (uuid, base, data) => dispatch(loanActions.saveReview(uuid, useBase(base, data))),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(mapStateToProps, mapDispatchToProps)(EducationalReviewContainer);

export { connectedContainer as EducationalReviewContainer };
