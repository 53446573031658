

let live_questions = ['iq_1', 'iq_2', 'iq_4', 'iq_5', 'iq_6'];

export function add_iq_questions(assessment, test = true) {

  // export correct json
  (test ? live_questions.splice(1, 3) : live_questions).forEach((question) => {

    assessment.pages.push(
      {
        name: question,
        elements: [
          {
            title: {en: "which figure logically belongs on the spot of the question mark?", ar: "اي سؤال يتناسب مع مكان إشارة الإستفهام"},
            otherText: {en: "Choose the correct figure:", ar: "اختر الإسم الصحيح"},
            otherErrorText: {en: "I don't know the answer.", ar: "لا أعلم الإجابة"},
            name: question,
            type: "radiogroup",
            renderAs: "iq",
            isRequired: true,
          },
        ]
      }
    )
  });
}