import { api, logger, setupAuthToken } from "../../app";
import Config from "../../config";
import { all_locales, loadLocales } from "../../lib/intl";

export function setLocale(locale) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOCALE_PENDING",
        locale: locale,
      });

      // correct if you have a wrong locale, choose the default one
      if (all_locales.indexOf(locale) === -1) locale = all_locales[1];

      logger.leaveBreadcrumb("setLocale", { locale: locale });
      let response = await api
        .post("auth/locale.json", { locale: locale })
        .catch(() => {});
      await loadLocales(locale);
      dispatch({
        type: "SET_LOCALE",
        locale: locale,
      });

      dispatch({
        type: "SET_AUTH_FOR_TENANT",
        api_service_tokens: response.api_service_tokens,
      });
      // we set here for crdedicapital a different toke to point to their system
      // then here we just check for the config variable in order to see if we set hear the bearer or bellow
      // in the other call to setupAuthToken
      if (!Boolean(Config.uses_access_token_for_ChoicesRestfull)) {
        setupAuthToken(response.api_service_tokens.address_service_token);
      }
    } catch (error) {
      logger.captureException(error, { locale: locale });
      dispatch({
        type: "SET_LOCALE_SUCCESS",
      });
    }
  };
}

export const setAccessToken = (access_token) => {
  return async (dispatch) => {
    api.token = {
      ...api.token,
      access_token: access_token,
    };

    if (Config.uses_access_token_for_ChoicesRestfull) {
      await setupAuthToken(access_token);
    }
    // in case we dont want to send a dispatch method, i setup here the token so its only in one place
    if (dispatch) {
      dispatch({
        type: "SET_AUTHENTICATION_TOKEN",
        token: {
          access_token: access_token,
        },
      });
    }
  };
};

export function getData() {
  return async (dispatch) => {
    try {
      let data = await api.get("/user/data.json");
      dispatch({
        type: "ADD_DEVICE_DATA",
        data: data,
      });
    } catch (error) {
      console.log("NO DEVICE DATA FOUND.");
    }
  };
}

export function setHomePage(home_page) {
  return async (dispatch) => {
    dispatch({
      type: "SET_HOME_PAGE",
      home_page: home_page,
    });
  };
}

export function showAlert(alertTitle, alertMessage, alertButtons = []) {
  return async (dispatch) => {
    dispatch({
      type: "SET_ALERT",
      alertTitle: alertTitle,
      alertMessage: alertMessage,
      alertButtons: alertButtons,
    });
  };
}

export function clearAlert() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_ALERT",
    });
  };
}
