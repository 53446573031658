import { ar_agree4, en_3rd_agreement_texts, p, rtl, text_direction} from './review_fields'
import Translations  from '../../config/translations.json';

const { edu_agree_fields } = Translations

const i_agree = {
  title: 'removeMe',
  type: 'checkbox',
  name: 'agree',
  isRequired: true,
  choices: [
    {
      value:
        'Applicant has agreed to loan amount, loan term and total fees in the educational flow.',
      text: {
        en: 'I agree',
        ar: 'أنا أوافق',
      },
    },
  ],
  validators: [],
};

const map_agree_fields = edu_agree_fields.map(
  (field, index) =>
    (field = {
      type: 'panel',
      elements: [
        {
          type: 'html',
          title: '',
          html: {
            ar: `<p style="margin-top:5px;" dir="${text_direction('ar')}">${field['ar']}</p>`,
            en: `<p style="margin-top:5px;" dir="${text_direction('en')}">${field['ar']}</p>`,
          },
        },
        { ...i_agree, name: `edu_agree${index}` },
      ],
    })
);

const req_loan_amount_text = 'Loan Amount (JOD)';
const loan_terms_text = 'Loan Term (Months)';

export const agreement = (amount, repayment_period, prefix, locale) =>

  {

    return `<p>${prefix}</p>
      <p>Loan Amount (JOD): ${amount}</p>
      <p>[x] I agree</p>
      <p>Loan Terms (Months): ${repayment_period}</p>
      <p>[x] I agree</p>
      <p dir='${text_direction(locale)}'>${ar_agree4}</p>
      <p>[x] I agree</p>
      <p>${en_3rd_agreement_texts}</p>
      <p>[x] I agree</p>
      ${edu_agree_fields.map(agree => `<p dir='${text_direction(locale)}'>${agree['ar']}</p>\n<p>[x] I agree</p>\n`).join('')}
      `;
  };

export const EducationalAgreementPage = {
  title: { en: 'Signing Application', ar: 'توقيع الطلب' },
  name: 'review',
  elements: [
    {
      type: 'panel',
      elements: [
        {
          title: {
            en: req_loan_amount_text,
            ar: 'مبلغ القرض (دينار أردني)',
          },
          type: 'expression',
          expression: '{amount}', // Floating Point Proof
        },

        { ...i_agree, name: 'agree1' },
      ],
    },
    {
      type: 'panel',
      elements: [
        {
          title: {
            en: loan_terms_text,
            ar: 'فترة السداد (أشهر)',
          },
          type: 'expression',
          expression: '{repayment_period}', // Floating Point Proof
        },

        { ...i_agree, name: 'agree2' },
      ],
    },
    {
      type: 'panel',
      elements: [
        {
          type: 'html',
          title: '',
          html: {
            ar: ar_agree4.map(rtl).join(''),
            en: ar_agree4.map(p).join(''),
          },
        },
        { ...i_agree, name: 'agree4' },
      ],
    },
    {
      type: 'panel',
      elements: [
        {
          type: 'html',
          name: 'removeThisName',
          title: '',
          html: {
            ar: [
              'سيتم احتساب الرسوم الإدارية وإضافتها إلى مبلغ القرض.',
              'سيتم احتساب الطوابع والضرائب ودفعها من قبل مقدم الطلب على الدفعة الأولى المجدولة.',
              'سيتم إدراج الرسوم الإدارية ورسوم الطوابع والضرائب في العقد الذي يجب توقيعه من جانبك وفقًا لذلك.',
            ]
              .map(rtl)
              .join(''),
            en: en_3rd_agreement_texts.map(p).join(''),
          },
        },

        { ...i_agree, name: 'agree3' },
      ],
    },
    {
      type: 'panel',
      elements: map_agree_fields,
    },
    {
      type: 'text',
      inputType: 'text',
      name: 'sign_name',
      isRequired: true,
      title: {
        en: 'Applicant Full Name',
        ar: 'اسم مقدّم الطلب الكامل (الاسم الرباعي)',
      },
    },
    {
      type: 'text',
      inputType: 'date',
      name: 'sign_date',
      isRequired: true,
      title: {
        en: 'Application Date',
        ar: 'تاريخ الطلب',
      },
    },
    {
      type: 'signaturepad',
      name: 'signature',
      isRequired: true,
      title: {
        en: 'Please enter your signature',
        ar: 'الرجاء قم بالتوقيع في المربع في الأسفل',
      },
    },
  ],
};
