
const i_agree = {
  title: "",
  type: "checkbox",
  name: "agree",
  isRequired: true,
  choices: [
    {
      value: {
        en: "Applicant has agreed to loan amount, loan terms and total fees",
        rw: "needs translation",
      },
      text: {
        en: "I agree",
        rw: "Ndabyemeye",
      },
    },
  ],
  validators: [],
};

const current_date ={
  type: "expression",
  title: "",
  titleLocation: "none",
  name: "today_date",
  expression: `daysFromNow(0, 0, 'rw')`,
}

const page_1 = {
  title: {
    en: "Terms and conditions",
    rw: "Amategeko n'amabwiriza",
  },
  name: "loan",
  elements: [
    {
      name: "insurance",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          type: "html",
          title: "",
          html: {
            en: `I, the undersigned, hereby declare the validity and accuracy of the above-mentioned information, data and documents
                  provided by me to MoneyPhone Africa Ltd., the Lender, and that they are identical to my personal information and have
                  been filled out by me personally with my full legal capacity, my freedom and my will.`,
            rw: `Njyewe, nemeje ko amakuru nahaye MoneyPhone Africa Ltd ko ari ukuri , kandi ko ntanze amakuru yanjye bwite kandi
                  yujujwe nanjye mu mudendezo no kubushake bwanjye  mu uburyo bwemewe n'amategeko.`,
          },
        },
        {
          type: "html",
          html: {
            en: `I have no objection to the Lender verifying all the data submitted by me or by any party that the Lender seems
                  appropriate.`,
            rw: `Nemeye kandi ndumva neza ko nta nzitizi mfite ko MoneyPhone yagenzura cg yabaza  yatanga amakuru kunguzanyo yanjye
                  ku kigo gishinzwe gukenzura inguzanyo nibindi bigo na bank nkuru y'igihugu, Nemeye ko MoneyPhone yansaba andi makuru
                  yose ajyanye n'ubusabe bw'inguzanyo yanjye igihe ari cyose iyakeneye.`,
          },
        },
        {
          type: "html",
          html: {
            en: `I, the undersigned, declare that I have no objection to Lender inquiring and/or viewing and/or providing and/or exchanging
                  credit information and/or other information about me with credit information companies, and the exchange of any of the
                  information provided by me and any other relevant data with the Central Bank and/or any other related parties as of its
                  date without the Lender bearing any legal responsibility for that, and I acknowledge the right The Lender may request
                  any additional information and/or documents at any time, or review one of its branches if necessary. I also acknowledge
                  that I made the inquiry and that I am fully aware of all the administrative and legal aspects of failure to pay on time.`,
            rw: `Ndemera kandi  nzi neza ibijyanye n'amabwiriza n'amategeko byose bijyanye no kutishyurira ku gihe inguzanyo nsabye.`,
          },
        },
        {
          type: "html",
          html: {
            en: `I also declare that I have inquired and that I am fully aware of all financing conditions, prices, administrative expenses,
                  terms and conditions of use.`,
            rw: `Ndemeza ko nabajije kandi nzi neza  ibiciro byose by'iyi nguzanyo, umufuragiro, inyungu k'unguzanyo nandi mabwiriza yo
                  gukoresha iyi nguzanyo`,
          },
        },
        {
          type: "html",
          html: {
            en: `I declare that all copies of the attached documents that have been attached to the website/electronic application are
                  true copies and that all edited data are written by me, correct and under my responsibility.`,
            rw: `Ndemeza ko kopi zose zinyandiko nometse kurubuga  rwa MoneyPhone ari kopi zukuri kandi ko amakuru yose yahinduwe
                  yanditswe nanjye kandi ari ukuri`,
          },
        },
        {
          type: "html",
          html: {
            en: `I acknowledge that I have read and agreed to the terms, conditions and privacy policy provided to me by the Lender,
                  MoneyPhone Africa Ltd.`,
            rw: `Nemeye ko nasomye kandi nemera amabwiriza y'iyinguzanyo MoneyPhone Africa Ltd impaye.`,
          },
        },
        {
          type: "html",
          html: {
            en: `<h3>Loan Agreement Insurance Information</h3><br>
              As part of your loan agreement, you'll benefit from automatic insurance coverage
              for specific events. This insurance covers borrower death and total permanent disability,
              providing support for loan repayment. The insurance is offered by SANLAM VIE PLC].<br><br>
              The insurance premiums are included in your loan repayment, so there's no need for
              separate payments. If a covered event occurs, please inform us promptly, and we'll guide
              you through the claim process under the insurance policy.<br><br>
              It's important to note that the insurance has limitations and exclusions.<br><br>
              If you have any questions about this insurance coverage, don't hesitate to reach out to
              us for assistance.`,
            rw: `<h3>Amakuru ajyanye nubwishingizi bw'inguzanyo</h3><br>
              Amasezerano yinguzanyo yawe, uzabona ubwishingizi. Ubwo bwishingizi bwishingira
              uwasabye inguzanyo igihe yapfuye cyangwa yahuye nubumuga buhoraho, bukamufasha
              kwishyura inguzanyo. Ubwishingizi butangwa na SANLAM VIE PLC ].<br><br>
              Amafaranga yubwishingizi akubiye mu kiguzi cyinguzanyo wasabye, ntayandi mafaranga
              akenewe kwishyurwa bitandukanye. Kimwe muri ibyo byinshingirwa byavuzwe haruguru
              bibaye tumenyeshe ako kanya tukuyobore uburyo ubwishingizi bwagufasha<br><br>
              Ni ngombwa kumenya ko ubwishingizi bufite aho bugarukira<br><br>
              Niba ufite ikibazo kuri ubu bwishingizi tumenyeshe tugufashe`
          }
        },
        {
          title: "Agree to micro insurance programs",
          titleLocation: "none",
          type: "checkbox",
          name: "agreeMicroInsuranceProgramms",
          isRequired: true,
          choices: [
            {
              value:
                "Applicant has agreed to micro insurance programs in the review flow.",
              text: {
                en: "I agree",
                rw: "Ndabyemeye",
              },
            },
          ],
          validators: [],
        },
      ],
    },
  ],
};

export const review_rwanda_direct_model_default = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Terms and conditions
    page_1,
    // Page 2: Review
    {
      title: {
        en: "Review your loan application",
        rw: "Subiramo neza ubusabe bwawe",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {loan.repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression:"loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in RWF",
                rw: "Amafaranga y'inguzanyo asabwa",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              name: "custom_float_2",
              type: "expression",
              expression: "{loan.repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in RWF to be deducted from the loan amount ({admin_fee})",
                rw: "Umufuragiro mu RWF akurwa ku nguzanyo yemejwe ({admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.total}", 
            },
            {
              title: {
                en: "Total VAT on administrative fees in RWF to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) kumufuragiro akurwa kunguzanyo yemejwe  ({vat_on_admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_2.total}", // This will be done with on-page calculation like Credicapital
            },
            {
              title: {
                en: "Insurance fees in RWF to be deducted from the loan amount ({insurance_fee})",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_3.total}", 
            },
            {
              title: {
                en: "Loan interest in RWF ({product_interest_rate}% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu RWF ({product_interest_rate}% ku mwaka)",
              },
              name: "custom_float_1",
              type: "expression",
              expression: "{total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be disbursed: approved loan amount minus fees",
                rw: "Amafaranga azagera kuri konti yawe",
              },
              name: "custom_float_6",
              type: "expression",
              expression: "fixed(subtract({loan.amount},{charge_dict.charge_code_1.total},{charge_dict.charge_code_2.total},{charge_dict.charge_code_3.total})", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Amafaranga y’inguzanyo yemejwe wongeyeho inyungu",
              },
              name: "custom_float_7",
              type: "expression",
              expression: "fixed(add({loan.amount}, {total_interest}", // Floating Point Proof
            },
          ],
        },
        {
          type: "html",
          visibleIf: "{repayment_period} == 6",
          html: {
            en: `<h5 class="sv_q_title">
                  You are committing to a {repayment_period} months payment of {loan_amount} RWF. You are aware that you will need to repay the
                  whole loan of {loan_amount} RWF plus {custom_float_1} RWF in interest.
                </h5>`,
            rw: `<h5 class="sv_q_title">
                  Niyemeje kwishyura amafaranga nyuma y'amezi {repayment_period} amafaranga angana na {loan_amount} RWF hinyongereyeho
                  {custom_float_1} RWF in y'inyungu
                </h5>`,
          },
        },
        {
          type: "html",
          visibleIf: "{repayment_period} == 12",
          html: {
            en: `<h5 class="sv_q_title">
                  The borrower commits to repay {monthly_installment} RWF, half the amount of the loan and interest within 6 months from the date of signing and 
                  {monthly_installment} RWF, the other half of the loan and interest within 12 months of signing.
                  </h5>`,
            rw: `<h5 class="sv_q_title">
                  Niyemeje kwishyura {monthly_installment} RWF, kimwe cyakabiri cy’inguzanyo n'inyungu mu mezi atandatu guhera na {monthly_installment} RWF,
                  ikindi gice cy’inguzanyo n’inyungu mu mezi 12 .
                  </h5>`,
          },
        },
        {
          type: "html",
          name: "penalty",
          html: {
            en: `To ensure that you are serious about a timely monthly payment, you will be charged 6% RWF for every month you are
                  late with payment.`,
            rw: `Kugirango twizere ko uzubahiriza  igihe cy'ubwishyu bwa buri kwezi, mugihe watinze kwishyura uzajya ucibwa amande
                  ya 6% RWF`,
          },
        },
        i_agree,
      ],
    },
  ],
};

export const review_rwanda_direct_model_chili = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Terms and conditions
    page_1,
    // Page 2: Review and sign
    {
      title: {
        en: "Review your loan application",
        rw: "Subiramo neza ubusabe bwawe",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {loan.repayment_period} notempty",
          elements: [
            current_date,
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in RWF",
                rw: "Amafaranga y'inguzanyo asabwa",
              },
              type: "expression",
              expression: "{amount}",
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              name: "repayment_period",
              type: "expression",
              expression: "{loan.repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in RWF to be deducted from the loan amount ({admin_fee})",
                rw: "Amafaranga ya serivise y'ubuyobozi angana na {admin_fee} akurwa kunguzanyo yemejwe",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in RWF to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) ukurwa kunguzanyo yemejwe ({vat_on_admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_2.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in RWF to be deducted from the loan amount ({insurance_fee})",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_3.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in RWF ({product_interest_rate}% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu RWF ({product_interest_rate}% ku mwaka)",
              },
              name: "custom_float_5",
              type: "expression",
              expression: "{total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be disbursed: approved loan amount minus fees",
                rw: "Igiteranyo cy'Amafaranga yinguzanyo yemejwe gutangwa",
              },
              name: "custom_float_6",
              type: "expression",
              expression: "fixed(subtract({loan.amount},{charge_dict.charge_code_1.total},{charge_dict.charge_code_2.total},{charge_dict.charge_code_3.total})", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Igiteranyo cy' Amafaranga yinguzanyo yemejwe gusubizwa",
              },
              name: "custom_float_7",
              type: "expression",
              expression: "fixed(add({loan.amount}, {total_interest}", // Floating Point Proof
            },
          ],
        },
        {
          type: "html",
          visibleIf: "{loan.repayment_period} == 6",
          html: {
            en: `<h5 class="sv_q_title">
                  The borrower authorizes the cooperative to automatically deduct the amount corresponding to the loan
                  repayment directly from the production income.
                  <br><br>
                  The amount should be repaid within {repayment_period} months from the date of signing {today_date}.
                </h5>`,
            rw: `<h5 class="sv_q_title">
                  Uwagurijwe yemerera koperative guhita ikuramo amafaranga ahuye no kwishyura inguzanyo biturutse ku musaruro. 
                  <br><br>
                  Amafaranga agomba kwishyurwa mugihe cyamezi {repayment_period} uhereye umunsi wasinyiye {today_date}.
                </h5>`,
          },
        },
        {
          type: "html",
          visibleIf: "{repayment_period} == 12",
          html: {
            en: `<h5 class="sv_q_title">
                  The borrower commits to repay {monthly_installment} RWF, half the amount of the loan and interest within 6 months from the date of signing {today_date} and 
                  {monthly_installment} RWF, the other half of the loan and interest within 12 months of signing.
                  </h5>`,
            rw: `<h5 class="sv_q_title">
                  Niyemeje kwishyura {monthly_installment} RWF, kimwe cyakabiri cy’inguzanyo n'inyungu mu mezi atandatu guhera {today_date} na {monthly_installment} RWF,
                  ikindi gice cy’inguzanyo n’inyungu mu mezi 12.
                  </h5>`,
          },
        },
        {
          type: "html",
          name: "penalty",
          html: {
            en: `To ensure that you are serious about a timely monthly payment, you will be charged 6% RWF for every month you
                  are late with payment.`,
            rw: `Kugirango twizere ko uzubahiriza igihe cy'ubwishyu bwa buri kwezi, mugihe watinze kwishyura uzajya ucibwa amande
                  ya 6% RWF`,
          },
        },
        i_agree 
      ],
    },
  ],
};

export const review_rwanda_direct_model_tea = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Terms and conditions
    page_1,
    // Page 2: Review and sign
    {
      title: {
        en: "Review your loan application",
        rw: "Subiramo neza ubusabe bwawe",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {loan.repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in RWF",
                rw: "Amafaranga y'inguzanyo asabwa",
              },
              type: "expression",
              expression: "{amount}",
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe iguzanyo izamara (Mu mezi)",
              },
              name: "repayment_period",
              type: "expression",
              expression: "{loan.repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in RWF to be deducted from the loan amount ({admin_fee})",
                rw: "Amafaranga ya serivise y'ubuyobozi angana na {admin_fee} akurwa kunguzanyo yemejwe",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in RWF to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) ukurwa kunguzanyo yemejwe ({vat_on_admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_2.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in RWF to be deducted from the loan amount ({insurance_fee})",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_3.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in RWF ({product_interest_rate}% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu RWF ({product_interest_rate}% ku mwaka)",
              },
              name: "custom_float_5",
              type: "expression",
              expression: "{total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be disbursed: approved loan amount minus fees",
                rw: "Igiteranyo cy'Amafaranga yinguzanyo yemejwe gutangwa",
              },
              name: "custom_float_6",
              type: "expression",
              expression: "fixed(subtract({loan.amount},{charge_dict.charge_code_1.total},{charge_dict.charge_code_2.total},{charge_dict.charge_code_3.total})", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Igiteranyo cy' Amafaranga yinguzanyo yemejwe gusubizwa",
              },
              name: "custom_float_7",
              type: "expression",
              expression: "fixed(add({loan.amount}, {total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Monthly amount",
                rw: "Ubwishyu bwa buri kwezi",
              },
              name: "custom_float_8",
              type: "expression",
              expression: "fixed(divide({custom_float_7}, {loan.repayment_period}))",
            }
          ],
        },
        {
          type: "html",
          html: {
            en: `<h5 class="sv_q_title">
                  Over the {repayment_period} month period, you will make monthly payments in the amount of {custom_float_8} RWF.
                  You are aware that you will have to repay the full loan amount of {amount} RWF plus {custom_float_5} RWF for
                  expenses.
                  <br><br>
                  You authorise the cooperative to automatically deduct the amount corresponding to the monthly loan
                  repayment directly from the production income.
                </h5>`,
            rw: `<h5 class="sv_q_title">
                  Mugihe cy'amezi {repayment_period}, uzajya wishyura buri kwezi amafaranga angana na {custom_float_8} RWF. Uzishyura
                  amafaranga yose angana na {amount} RWF n'inyungu {custom_float_5} RWF.
                  <br><br>
                  Mpaye uburenganzira koperative bwo gukuraho amafaranga y'ubwishyu bw'inguzanyo bwa buri kwezi kumafaranga
                  yishyuwe kumusararuro wanjye.
                </h5>`,
          },
        },
        {
          type: "html",
          name: "penalty",
          html: {
            en: `To ensure that you are serious about a timely monthly payment, you will be charged 6% RWF for every month you
                  are late with payment.`,
            rw: `Kugirango twizere ko uzubahiriza igihe cy'ubwishyu bwa buri kwezi, mugihe watinze kwishyura uzajya ucibwa amande
                  ya 6% RWF`,
          },
        },
        i_agree,
      ],
    },
  ],
};
