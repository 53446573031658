import "../../intake.container.scss";
import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";
const required = env.ENDPOINT !== "local";

export const insurance_ffdl = new SurveyComponent({
  title: { en: "Insurance data", "es-GT": "Datos del seguro" },
  name: "loan_details",
  elements: [
    {
      type: "html",
      html: {
        en: `
              <p style="font-weight: 600;">With a single payment at the beginning of your loan you acquire additional benefits:</p>
              <p>- You do not inherit debts and your family receives Q5,000 *. <br/>- You can choose between:</p>
              <ul>
                <li style='margin-left:3rem;'>Funeral service *</li>
                <li style='margin-left:3rem;'>Unlimited medical consultations by phone</li>
              </ul>
              <p style="font-style: italic;">* In case of death.</p>
              <p style="font-style: italic;">You must enter one or two beneficiaries that add up to 100% of the benefit.</p>
            `,
        "es-GT": `
            <p style="font-weight: 600;">Con un único pago al inicio de tu crédito adquieres beneficios adicionales:</p>
            <ul>
              <li>No heredas deudas y tu familia recibe Q5,000*. </li>
              <li>Puedes elegir entre:</li>
            </ul>
            <ul class='dashed'>
              <li>Servicio funerario</li>
              <li>Realizar consultas médicas teléfonicas ilimitadas</li>
            </ul>
            * En caso de fallecimiento.<br/><br/>
            <span>Debes ingresar uno o dos beneficiarios que sumen el 100% del beneficio.</p>
          `,
      },
    },
    {
      title: {
        en: "Type of insurance *",
        "es-GT": "Tipo de seguro de vida *",
      },
      name: "custom_text_28",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: "servicio_funerario",
          text: { en: "Funeral Service", "es-GT": "Servicio funerario" },
        },
        {
          value: "telemedicina",
          text: { en: "Telemedicine", "es-GT": "Telemedicina" },
        },
      ],
    },
    {
      type: "text",
      name: "custom_text_29",
      isRequired: required,
      title: {
        en: "Beneficiary # 1 Name *",
        "es-GT": "Nombre de beneficiario #1 *",
      },
    },
    {
      name: "custom_text_30",
      type: "phone",
      isRequired: required,
      validators: [
        expressions.phone_en("custom_text_30"),
        expressions.phone_length("custom_text_30"),
      ],
      title: {
        en: "Beneficiary # 1 phone *",
        "es-GT": "Tel. de beneficiario #1 *",
      },
    },
    {
      name: "custom_text_31",
      type: "text",
      isRequired: required,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 13 digits length",
            "es-GT": "El número debe tener una longitud de 13 dígitos",
          },
          regex: "^[0-9]{13}$",
        },
      ],
      title: {
        en: "Beneficiary #1 ID No. *",
        "es-GT": "DPI de beneficiario #1 *",
      },
    },
    {
      title: {
        en: "Beneficiary #1 Relationship *",
        "es-GT": "Parentesco de beneficiario #1 *",
      },
      name: "custom_text_32", // check mapping
      type: "dropdown",
      isRequired: required,
      hasOther: true,
      otherText: {
        en: "Other",
        "es-GT": "Otros",
      },
      choices: [
        { value: "abuelo", text: { en: "Grandfather", "es-GT": "Abuelo" } },
        { value: "abuela", text: { en: "Grandmother", "es-GT": "Abuela" } },
        { value: "amigo", text: { en: "Friend", "es-GT": "Amigo" } },
        { value: "amiga", text: { en: "Friend", "es-GT": "Amiga" } },
        { value: "companero", text: { en: "Partner", "es-GT": "Compañero" } },
        { value: "companera", text: { en: "Partner", "es-GT": "Compañera" } },
        {
          value: "cunado",
          text: { en: "Brother-in-law", "es-GT": "Cuñado" },
        },
        {
          value: "cunada",
          text: { en: "Sister-in-law", "es-GT": "Cuñada" },
        },
        { value: "esposo", text: { en: "Husband", "es-GT": "Esposo" } },
        { value: "esposa", text: { en: "Wife", "es-GT": "Esposa" } },
        { value: "hermano", text: { en: "Brother", "es-GT": "Hermano" } },
        { value: "hermana", text: { en: "Sister", "es-GT": "Hermana" } },
        { value: "hijo", text: { en: "Son", "es-GT": "Hijo" } },
        { value: "hija", text: { en: "Daughter", "es-GT": "Hija" } },
        { value: "padre", text: { en: "Father", "es-GT": "Padre" } },
        { value: "madre", text: { en: "Mother", "es-GT": "Madre" } },
        { value: "nieto", text: { en: "Grandson", "es-GT": "Nieto" } },
        {
          value: "nieta",
          text: { en: "Granddaughter", "es-GT": "Nieta" },
        },
        { value: "yerno", text: { en: "Son-in-law", "es-GT": "Yerno" } },
        {
          value: "nuera",
          text: { en: "Daughter-in-law", "es-GT": "Nuera" },
        },
        { value: "primo", text: { en: "Cousin", "es-GT": "Primo" } },
        { value: "prima", text: { en: "Cousin", "es-GT": "Prima" } },
        { value: "sobrino", text: { en: "Nephew", "es-GT": "Sobrino" } },
        { value: "sobrina", text: { en: "Niece", "es-GT": "Sobrina" } },
        {
          value: "suegro",
          text: { en: "Father-in-law", "es-GT": "Suegro" },
        },
        {
          value: "suegra",
          text: { en: "Mother-in-law", "es-GT": "Suegra" },
        },
        { value: "tio", text: { en: "Uncle", "es-GT": "Tío" } },
        { value: "tia", text: { en: "Aunt", "es-GT": "Tía" } },
      ],
    },
    {
      title: {
        en: "Beneficiary #1 Date of birth *",
        "es-GT": "Fecha de nacimiento de beneficiario #1 *",
      },
      name: "custom_text_33",
      type: "text",
      inputType: "date",
      isRequired: required,
    },
    {
      title: {
        en: "Beneficiary #1 Percentage *",
        "es-GT": "Porcentaje de beneficiario #1 *",
      },
      name: "custom_text_48",
      type: "nouislider",
      step: 1,
      rangeMin: 0,
      defaultValue: 100,
      rangeMax: 100,
      isRequired: required,
      displayStyle: "percent",
    },
    {
      type: "text",
      name: "custom_text_34",
      isRequired: `{custom_text_48} != 100`,
      visibleIf: `{custom_text_48} != 100`,
      title: {
        en: "Beneficiary # 2 Name",
        "es-GT": "Nombre de beneficiario #2",
      },
      description: {
        en: "A second beneficiary is mandatory when the first beneficiary does not cover 100%.",
        "es-GT":
          "Un segundo beneficiario es obligatorio cuando el primer beneficiario no cubre el 100%.",
      },
    },
    {
      name: "custom_text_35", // Check mapping
      visibleIf: "{custom_text_34} notempty",
      type: "phone",
      isRequired: `{custom_text_48} != 100`,
      validators: [
        expressions.phone_en("custom_text_35"),
        expressions.phone_length("custom_text_35"),
      ],
      title: {
        en: "Beneficiary # 2 phone *",
        "es-GT": "Tel. de beneficiario #2 *",
      },
    },
    {
      name: "custom_text_36", // check mapping
      visibleIf: `{custom_text_48} != 100`,
      type: "text",
      isRequired: `{custom_text_48} != 100`,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 13 digits length",
            "es-GT": "El número debe tener una longitud de 13 dígitos",
          },
          regex: "^[0-9]{13}$",
        },
      ],
      title: {
        en: "Beneficiary #2 ID No. *",
        "es-GT": "DPI de beneficiario #2 *",
      },
    },
    {
      title: {
        en: "Beneficiary #2 Relationship *",
        "es-GT": "Parentesco de beneficiario #2 *",
      },
      visibleIf: "{custom_text_34} notempty",
      name: "custom_text_37",
      type: "dropdown",
      isRequired: `{custom_text_48} != 100`,
      hasOther: true,
      otherText: {
        en: "Other",
        "es-GT": "Otros",
      },
      choices: [
        { value: "abuelo", text: { en: "Grandfather", "es-GT": "Abuelo" } },
        { value: "abuela", text: { en: "Grandmother", "es-GT": "Abuela" } },
        { value: "amigo", text: { en: "Friend", "es-GT": "Amigo" } },
        { value: "amiga", text: { en: "Friend", "es-GT": "Amiga" } },
        { value: "companero", text: { en: "Partner", "es-GT": "Compañero" } },
        { value: "companera", text: { en: "Partner", "es-GT": "Compañera" } },
        {
          value: "cunado",
          text: { en: "Brother-in-law", "es-GT": "Cuñado" },
        },
        {
          value: "cunada",
          text: { en: "Sister-in-law", "es-GT": "Cuñada" },
        },
        { value: "esposo", text: { en: "Husband", "es-GT": "Esposo" } },
        { value: "esposa", text: { en: "Wife", "es-GT": "Esposa" } },
        { value: "hermano", text: { en: "Brother", "es-GT": "Hermano" } },
        { value: "hermana", text: { en: "Sister", "es-GT": "Hermana" } },
        { value: "hijo", text: { en: "Son", "es-GT": "Hijo" } },
        { value: "hija", text: { en: "Daughter", "es-GT": "Hija" } },
        { value: "padre", text: { en: "Father", "es-GT": "Padre" } },
        { value: "madre", text: { en: "Mother", "es-GT": "Madre" } },
        { value: "nieto", text: { en: "Grandson", "es-GT": "Nieto" } },
        {
          value: "nieta",
          text: { en: "Granddaughter", "es-GT": "Nieta" },
        },
        { value: "yerno", text: { en: "Son-in-law", "es-GT": "Yerno" } },
        {
          value: "nuera",
          text: { en: "Daughter-in-law", "es-GT": "Nuera" },
        },
        { value: "primo", text: { en: "Cousin", "es-GT": "Primo" } },
        { value: "prima", text: { en: "Cousin", "es-GT": "Prima" } },
        { value: "sobrino", text: { en: "Nephew", "es-GT": "Sobrino" } },
        { value: "sobrina", text: { en: "Niece", "es-GT": "Sobrina" } },
        {
          value: "suegro",
          text: { en: "Father-in-law", "es-GT": "Suegro" },
        },
        {
          value: "suegra",
          text: { en: "Mother-in-law", "es-GT": "Suegra" },
        },
        { value: "tio", text: { en: "Uncle", "es-GT": "Tío" } },
        { value: "tia", text: { en: "Aunt", "es-GT": "Tía" } },
      ],
    },
    {
      title: {
        en: "Beneficiary #2 Date of birth *",
        "es-GT": "Fecha de nacimiento de beneficiario #2 *",
      },
      visibleIf: `{custom_text_48} != 100`,
      name: "custom_text_38",
      type: "text",
      inputType: "date",
      isRequired: `{custom_text_48} != 100`,
    },
  ],
});
