import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const identification_eduloan = new SurveyComponent({
  name: "identification",
  title: {
    en: "Identification Information",
  },
  elements: [
    {
      name: "nationality",
      type: "dropdown",
      isRequired: required,
      storeOthersAsComment: false,
      hasOther: true,
      title: {
        en: "Nationality *",
      },
      choices: [
        {
          value: "zimbabwean",
          text: {
            en: "Zimbabwean",
          },
        },
      ],
    },
    {
      title: {
        en: "Upload a valid and certified Zimbabwean ID document",
      },
      name: "id_document",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "id_number",
          type: "text",
          isRequired: required,
          title: {
            en: "ID No. *",
          },
        },
        {
          title: {
            en: "Front Image (Attachment) *",
          },
          description: {
            en:
              "If you cannot find the document now, you can close this form and come back later.",
          },
          name: "id_file",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        }
      ],
    },
    {
      name: "id_avatar",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "Please verify that this card belongs to you",
      },
      elements: [
        {
          name: "id_avatar_desc",
          type: "html",
          description: {
            en:
              "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
          },
          html: {
            en:
              "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
          },
        },
        {
          name: "id_avatar_desc_picture",
          type: "html",
          html: `<img alt="example" src='${require("../../../../assets/images/example_card.png")}' width='200'/>`,
        },
        {
          name: "id_file_with_avatar",
          description: {
            en:
              "If you cannot find the document now, you can close this form and come back later.",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: false,
          title: {
            en: "Identification Picture *",
          },
        },
      ],
    },
  ],
});
