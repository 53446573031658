/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */

import { TechnicalLoanTerms } from "../../components/survey/questions/technical_loan_terms";

export const sign_rwanda = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: false,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign contract",
    rw: "Shyira umukono kuri aya masezerano",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      title: {
        en: "Review and sign your loan contract",
        rw: "Suzuma  unasinyire amaserano y'inguzanyo yawe",
      },
      name: "contract",
      elements: [
        {
          title: {
            en: "Your final loan terms are:",
            rw: "Amabwiriza yanyu ku nguzanyo yawe:",
          },
          name: "terms",
          type: "panel",
          innerIndent: 1,
          elements: [
            TechnicalLoanTerms,

            {
              title: {
                en: "Approved loan amount",
                rw: "Ingano y'Inguzanyo  wemerewe ",
              },
              description: {
                en: "In Rwf",
                rw: "In RWF",
              },
              type: "expression",
              expression: "fixed({amount}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "RWF",
            },

            {
              title: {
                en: "Monthly administrative fees",
                rw: "Umufuragiro",
              },
              type: "expression",
              expression:
                "(getLoanTerm({loan_terms}, 'monthly_administrative_fees') * 100) + '%'", // Floating Point Proof
            },

            {
              title: {
                en: "Approved loan term",
                rw: "Ambwiriza yemejwe",
              },
              description: {
                rw: "mu mezi",
                en: "in months",
              },
              type: "expression",
              expression: "{repayment_period}", // Floating Point Proof
            },

            {
              title: {
                en: "Total administrative fees",
                rw: "Umufurangiro wose",
              },
              description: {
                en: "In Rwf",
                rw: "In RWF",
              },
              type: "expression",
              expression: "fixed({total_fees}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "RWF",
            },

            {
              title: {
                en: "Approved monthly installment",
                rw: "Amafaranga yemejwe y' ikiciro buri kwezi",
              },
              description: {
                en: "In Rwf",
                rw: "In RWF",
              },
              type: "expression",
              expression: "fixed({monthly_installment}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "RWF",
            },

            {
              title: {
                en: "Approved first installment date",
                rw: "Italiki yikiciro cy'amafaranga yambere yemejwe",
              },
              type: "expression",
              expression: "{start_repayment_date_translated}", // Floating Point Proof
            },

            {
              title: {
                en: "Insurance fees to be added to the monthly installment",
                rw: "Amafaranga y'ubwishingizi  yongerwa kugice cy'ukwezi",
              },
              description: {
                en: "In Rwf",
                rw: "In RWF",
              },
              type: "expression",
              expression: "fixed({monthly_insurance}, 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "RWF",
            },

            {
              title: {
                en: "Sales tax (to be deducted from the approved loan)",
                rw: "Umusoro ku byaguzwe (gukurwa ku nguzanyo yemejwe)",
              },
              description: {
                en: "In Rwf",
                rw: "In RWF",
              },
              type: "expression",
              expression: "fixed(getLoanTerm({loan_terms}, 'sales_tax'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "RWF",
            },

            {
              title: {
                en: "Stamp (to be deducted from the approved loan)",
                rw: "Kashe (gukurwa ku nguzanyo yemejwe)",
              },
              description: {
                en: "In Rwf",
                rw: "In RWF",
              },
              type: "expression",
              expression: "fixed(getLoanTerm({loan_terms}, 'stamp'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "RWF",
            },

            {
              title: "",
              type: "checkbox",
              name: "agree_final_loan_terms",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", rw: "Ndabyemeye" } },
              ],
              validators: [],
            },

            {
              type: "html",
              name: "penalty",
              html: {
                en: "To ensure that you are serious about a timely monthly payment, you will be charged ... for every day you are late with payment.",
                rw: "Uzajya wishyure …. RWF buri munsi mu gihe watinze kwishyura.",
              },
            },

            {
              type: "html",
              name: "loan_fees",
              html: {
                en: "During {repayment_period} months, the loan will be repaid in full. For this loan the total fees will be {total_fees} RWF.",
                rw: "mugihe <umubare> amezi, inguzanyo izaba yishyuwe yose", // @TODO needs proper translation
              },
            },
          ],
        },

        {
          title: { en: "Your signature", rw: "Umukono wawe" },
          name: "signature",
          type: "panel",
          innerIndent: 1,

          elements: [
            {
              title: {
                en: "My name is {first_name} {last_name} and my ID number is {id_number}.  I confirm that the information given in this form is true, complete and accurate.",
                rw: "Amazina yanjye ni {first_name} {last_name} na nomero y'Indangamuntu {id_number}. Nemeje ko amakuru yatanzwe yuzuye kandi ari ukuri.",
              },
              type: "checkbox",
              name: "truth",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", rw: "Ndabyemeye" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "The loan amount of {amount} RWF that you want to borrow is reasonable and responsible considering your personal situation.",
                rw: "Ingano y'inguzanyo {amount} RWF ushaka kuguza i",
              },
              type: "checkbox",
              name: "responsible",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", rw: "Ndabyemeye" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "I understand the risks involved and will guarantee the repayment of the loan.",
                rw: "Ndumva ingaruka zirimo kandi nzemeza kwishyura inguzanyo",
              },
              type: "checkbox",
              name: "agree",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", rw: "Ndabyemeye" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "Are you sure that you agree on the loan terms and conditions?",
                rw: "Uremera amasezerano yinguzanyo?",
              },
              type: "checkbox",
              name: "agree_toc",
              isRequired: true,
              choices: [
                { value: "agree", text: { en: "I agree", rw: "Ndabyemeye" } },
              ],
              validators: [],
            },

            {
              title: {
                en: "Please upload a video or audio file of yourself",
                rw: "Shyiraho video yawe cg audio file",
              },
              description: {
                en: "Please upload a video or audio file of yourself in which you agree to the loan terms and conditions.",
                rw: "Shyiraho video cyangwa ijwi ryawe  ko wemeye amaserano y'inguzanyo",
              },
              name: "i_agree_on_video",
              type: "file_uploader",
              accept: "video/*,audio/*",
              isRequired: true,
            },

            {
              type: "signaturepad",
              name: "signature",
              isRequired: true,
              title: {
                en: "Please enter your signature",
                rw: "Shyiramo umukono wawe",
              },
            },

            {
              type: "text",
              inputType: "text",
              name: "sign_name",
              isRequired: true,
              title: {
                en: "Please enter your full name",
                rw: "Shyiramo amazina yombi",
              },
            },

            {
              type: "text",
              inputType: "date",
              name: "sign_date",
              isRequired: true,
              title: {
                en: "Please enter the date",
                rw: "Shyiramo italiki",
              },
            },
          ],
        },
      ],
    },
  ],
};
