import {authenticationState} from './authentication.state';

export function authenticationReducer(state = authenticationState, action) {
  switch (action.type) {

    // pending states depending on method of login
    case 'LOGIN_EMAIL_PENDING':
      return {
        ...state,
        loginEmailPending: true,
      };
    case 'SIGNUP_EMAIL_PENDING':
      return {
        ...state,
        signupEmailPending: true,
      };

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loginEmailPending: false,
        signupEmailPending: false,
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        Error: action.error,
        loginEmailPending: false,
        signupEmailPending: false,
      };

    case 'LOGOUT_PENDING':
      return {
        ...state,
        logoutPending: true,
      };

    case 'PASSWORD_RESET_PENDING':
      return {
        ...state,
        passwordResetPending: true
      };

    case 'PASSWORD_RESET_SUCCESS':
      return {
        ...state,
        passwordResetPending: false
      };

    case 'RESET_AUTHENTICATION_STATE':
      return {
        ...authenticationState
      };

    default:
      return state;
  }
}

