
export function add_pauze(assessment, number) {
  assessment.pages.push({
    name: 'pauze_' + number,
    elements: [
      {
        type: "html",
        name: "pauze_" + number,
        html: {
          en: ` <div className='pauze'>
                <p class='center bigSpacer medium'>You are doing really well!</p>
                <img src="/test_assets/pauze.gif" style="max-width: 50px; margin: 0 auto; display: block" alt="pauze"/>
                <p class='center bigSpacer'>Why don't you take a small break before moving on?</p>
                </div>`,
          ar: ` <div className='pauze'>
                <p class='center bigSpacer medium'>أنت تبلي بلاءً حسناً!</p>
                <img src="/test_assets/pauze.gif" style="max-width: 50px; margin: 0 auto; display: block" alt="pauze"/>
                <p class='center bigSpacer'>لما لا تأخذ إستراحة قبل المضي قدما؟</p>
                </div>`
        },
      }
    ]
  })
}