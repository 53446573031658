import "../../intake.container.scss";
import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
const required = env.ENDPOINT !== "local";

export const loan_details_ffdl = new SurveyComponent({
  title: { en: "Loan Details", "es-GT": "Detalles del préstamo" },
  name: "loan_details",
  elements: [
    {
      type: "html",
      visibleIf: "{custom_bool_1} == true",
      html: {
        en: `
              <p style="font-weight: 600;">For amounts over Q20,000.00 you must back your loan with one of the following guarantees:</p>
              <ul>
                <li style='margin-left:3rem;'>Fiduciary: A person who has the capacity to assume your debt.</li>
                <li style='margin-left:3rem;'>Mortgage: Registered deed of a piece of land, house, etc.</li>
                <li style='margin-left:3rem;'>Chattel: Vehicle or machinery.</li>
              </ul>
            `,
        "es-GT": `
            <p style="font-weight: 600;">Para montos mayores a Q20,000.00  debes respaldar tu crédito  con una de las siguientes garantías:</p>
            <ul>
              <li style='margin-left:3rem;'>Fiduciaria: Persona asalariada que tiene la capacidad de asumir tu deuda.</li>
              <li style='margin-left:3rem;'>Hipotecaria: Escritura registrada de un terreno, casa, etc.</li>
              <li style='margin-left:3rem;'>Mobiliaria: Vehículo o maquinaria.</li>
            </ul>
          `,
      },
    },
    {
      type: "html",
      visibleIf: "{custom_bool_1} == false",
      html: {
        en: `
              <p style="font-weight: 600;">For amounts over Q10,000.00 you must back your loan with one of the following guarantees:</p>
              <ul>
                <li style='margin-left:3rem;'>Fiduciary: A person who has the capacity to assume your debt.</li>
                <li style='margin-left:3rem;'>Mortgage: Registered deed of a piece of land, house, etc.</li>
                <li style='margin-left:3rem;'>Chattel: Vehicle or machinery.</li>
              </ul>
            `,
        "es-GT": `
            <p style="font-weight: 600;">Para montos mayores a Q10,000.00  debes respaldar tu crédito  con una de las siguientes garantías:</p>
            <ul>
              <li style='margin-left:3rem;'>Fiduciaria: Persona asalariada que tiene la capacidad de asumir tu deuda.</li>
              <li style='margin-left:3rem;'>Hipotecaria: Escritura registrada de un terreno, casa, etc.</li>
              <li style='margin-left:3rem;'>Mobiliaria: Vehículo o maquinaria.</li>
            </ul>
          `,
      },
    },
    {
      title: {
        en: "Loan purpose *",
        "es-GT": "Destino del préstamo *",
      },
      name: "purpose",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: "gastos_personales",
          text: { en: "Personal expenses", "es-GT": "Gastos personales" },
        },
        { value: "vivienda", text: { en: "Housing", "es-GT": "Vivienda" } },
      ],
    },
    {
      title: {
        en: "Requested loan amount (Q) *",
        "es-GT": "Monto solicitado (Q) *",
      },
      name: "amount",
      type: "nouislider",
      step: 1,
      rangeMin: 1500,
      rangeMaxStart: 30000,
      maxStep: 10000,
      rangeMax: 30000,
      isRequired: true,
      visibleIf: `({purpose} == "gastos_personales") || ({purpose} == "vivienda" && {custom_bool_1} == false)`
    },
    {
      title: {
        en: "Requested loan amount (Q) *",
        "es-GT": "Monto solicitado (Q) *",
      },
      name: "amount",
      type: "nouislider",
      step: 1,
      rangeMin: 1500,
      rangeMaxStart: 40000,
      maxStep: 10000,
      rangeMax: 40000,
      isRequired: true,
      visibleIf: `{purpose} == "vivienda" && {custom_bool_1} == true`
    },
    {
      name: "personal_expenses_panel",
      type: "panel",
      innerIndent: 1,
      visibleIf: "{purpose} == 'gastos_personales'",
      elements: [
        {
          title: {
            en: "Loan term *",
            "es-GT": "Plazo en meses",
          },
          name: "repayment_period",
          type: "dropdown",
          isRequired: required,
          choices: [
            { value: 6, text: 6 },
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 12, text: 12 },
            { value: 15, text: 15 },
            { value: 18, text: 18 }
          ]
        },
        {
          title: {
            en: "I will use the loan for? *",
            "es-GT": "Utilizar mi préstamo en: *",
          },
          name: "custom_text_24",
          type: "dropdown",
          isRequired: required,
          hasOther: true,
          otherText: {
            en: "Other", "es-GT": "Otros"
          },
          choices: [
            {
              value: "educacion",
              text: { en: "Education", "es-GT": "Educación" },
            },
            { value: "salud", text: { en: "Health", "es-GT": "Salud" } },
            {
              value: "compra_vehiculo",
              text: { en: "Vehicle Purchase", "es-GT": "Compra vehículo" },
            },
            {
              value: "emprendimiento_de_negocio",
              text: {
                en: "Business Entrepeneurship",
                "es-GT": "Emprendimiento de negocio",
              },
            },
          ],
        },
      ],
    },
    {
      name: "housing_panel",
      type: "panel",
      innerIndent: 1,
      visibleIf: "{purpose} == 'vivienda'",
      elements: [
        {
          title: {
            en: "Loan term *",
            "es-GT": "Plazo en meses",
          },
          name: "repayment_period",
          type: "dropdown",
          isRequired: required,
          choices: [
            { value: 6, text: 6 },
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 12, text: 12 },
            { value: 15, text: 15 },
            { value: 18, text: 18 },
            { value: 21, text: 21 },
            { value: 24, text: 24 }
          ]
        },
        {
          title: {
            en: "I will use the loan for? *",
            "es-GT": "Utilizar mi préstamo en: *",
          },
          name: "custom_text_24",
          type: "dropdown",
          isRequired: required,
          hasOther: true,
          otherText: {
            en: "Other", "es-GT": "Otros"
          },
          choices: [
            {
              value: "ampliación_o_mejoramiento_de_vivienda",
              text: {
                en: "Expansion or improvement of housing",
                "es-GT": "Ampliación o mejoramiento de vivienda",
              },
            },
            {
              value: "construcción",
              text: { en: "Construction", "es-GT": "Construcción" },
            },
            {
              value: "compra_de_terreno",
              text: { en: "Purchase of land", "es-GT": "Compra de terreno" },
            },
            {
              value: "kit_generación_de_energía_solar",
              text: {
                en: "Solar energy generation kit",
                "es-GT": "Kit generación de energía solar",
              },
            },
          ],
        },
      ],
    },
    {
      type: "text",
      name: "custom_text_25", // needs mapping
      title: {
        en: "In case you were referred, please indicate",
        "es-GT": "En caso haya sido referido indicar",
      },
    }
  ],
});
