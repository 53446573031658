import { SurveyComponent } from "../../components/survey/survey_component";
import env from "../../env";
import { expressions } from "../../survey/validators";

const required = env.ENDPOINT !== "local";

export const credit_check_form_rentamigo = new SurveyComponent({
  title: {
    en: "Auth to credit report",
    "es-MX": "Autorización para solicitar reportes de crédito",
  },
  elements: [
    {
      html: {
        en: "Estoy consciente y acepto que este documento quede bajo custodia de RENTAMIGO SA DE CV SOFOM ENR, para efectos de control y cumplimiento del artículo 28 de la Ley para Regular a Las Sociedades de Información Crediticia; mismo que señala que las Sociedades sólo podrán proporcionar información a un Usuario, cuando éste cuente con la autorización expresa del Cliente mediante su firma autógrafa.",
        "es-MX":
          "Estoy consciente y acepto que este documento quede bajo custodia de RENTAMIGO SA DE CV SOFOM ENR, para efectos de control y cumplimiento del artículo 28 de la Ley para Regular a Las Sociedades de Información Crediticia; mismo que señala que las Sociedades sólo podrán proporcionar información a un Usuario, cuando éste cuente con la autorización expresa del Cliente mediante su firma autógrafa.",
      },
      type: "html",
    },
    {
      title: {
        en: "Name(s)",
        "es-MX": "Nombre(s)",
      },
      type: "text",
      name: "first_name",
      isRequired: required,
    },
    {
      title: { en: "Last name", "es-MX": "Apellido paterno" },
      type: "text",
      name: "surname",
      isRequired: required,
    },
    {
      title: { en: "Mother's last name", "es-MX": "Apellido materno" },
      type: "text",
      name: "second_surname",
      isRequired: required,
    },
    {
      title: { en: "Phone number", "es-MX": "Teléfono celular" },
      type: "phone",
      name: "phone_number",
      isRequired: required,
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
    },
    {
      title: { en: "Email*", "es-MX": "Correo electrónico" },
      type: "text",
      name: "email",
      isRequired: required,
      validators: [{ type: "email" }],
    },
    {
      title: { en: "Identification", "es-MX": "Identificación" },
      type: "dropdown",
      isRequired: required,
      name: "custom_text_2",
      choices: [
        { value: "RFC", text: { en: "RFC", "es-MX": "RFC" } },
        { value: "CURP", text: { en: "CURP", "es-MX": "CURP" } },
      ],
    },
    {
      title: {
        en: "RFC",
        "es-MX": "RFC",
      },
      visibleIf: "{custom_text_2} == 'RFC'",
      type: "text",
      name: "custom_text_3",
      isRequired: required,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Please enter a correct Tax Id Number (RFC) of 13 characters",
            "es-MX": "RFC 13 dígitos",
          }, // Requires translation
          regex: `^[A-Za-z]{4}[ |-]{0,1}[0-9]{6}[ |-]{0,1}[0-9A-Za-z]{3}$`, // should be the correct regex
        },
      ],
      placeHolder: "VECJ880326 XXX",
    },
    {
      title: {
        en: "CURP",
        "es-MX": "CURP",
      },
      visibleIf: "{custom_text_2} == 'CURP'",
      type: "text",
      name: "custom_text_4",
      isRequired: required,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Please enter a correct CURP of 18 characters",
            "es-MX": "CURP 18 dígitos",
          }, // Requires translation
          regex: `^[a-zA-Z]{4}[0-9]{6}[a-zA-Z]{6}[0-9]{2}$`, // should be the correct regex
        },
      ],
      placeHolder: "HEGG560427MVZRRL04",
    },
    {
      title: {
        en: "Curp picture",
        "es-MX": "Fotos de tu CURP",
      },
      visibleIf: "{custom_text_2} == 'CURP'",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
      name: "picture_14",
    },
    {
      title: {
        en: "To verify the identity of the applicant, upload a profile photo",
        "es-MX": "Suba foto del INE por ambos lados",
      },
      type: "panel",
      elements: [
        {
          title: {
            en: "Front",
            "es-MX": "Parte delantera",
          },

          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "picture_1",
        },

        {
          title: {
            en: "Back",
            "es-MX": "Parte trasera",
          },

          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "picture_2",
        },
      ],
    },
    {
      title: { en: "Street", "es-MX": "Calle" },
      type: "text",
      name: "home_street",
      isRequired: required,
    },
    {
      title: { en: "Colony", "es-MX": "Colonia o barrio" },
      type: "text",
      name: "custom_text_5",
      isRequired: required,
    },
    {
      title: {
        en: "Delegation/Municipality",
        "es-MX": "Delegación o municipio",
      },
      type: "text",
      name: "custom_text_6",
      isRequired: required,
    },
    {
      title: { en: "State", "es-MX": "Estado" },
      type: "text",
      name: "custom_text_1",
      isRequired: required,
    },
    {
      title: { en: "PoscalCode", "es-MX": "Código postal" },
      type: "text",
      name: "custom_text_7",
      isRequired: required,
    },
    {
      title: {
        en: "Proof of address",
        "es-MX": "Suba una foto del comprobante del domicilio",
      },

      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
      name: "picture_3",
    },
    {
      type: "panel",
      title: {
        en: "I approve of RENTAMIGO SA DE CV SOFOM ENR to perform a check",
        "es-MX":
          "Por este conducto autorizo expresamente a RENTAMIGO SA DE CV SOFOM ENR, para que por conducto de sus funcionarios facultados lleve a cabo Investigaciones, sobre mi comportamiento.",
      },
      elements: [
        {
          type: "expression",
          expression: "daysFromNow(0, 0, 'es-MX')",
          title: {
            en: "Date of inquiry",
            "es-MX": "Fecha en la que se realiza la consulta",
          },
          name: "custom_text_8",
          isRequired: required,
        },
        {
          title: {
            en: "Name of applicant",
            "es-MX": "Nombre del aplicante",
          },
          type: "text",
          name: "custom_text_9",
          isRequired: required,
        },
        {
          title: {
            en: "Signature of applicant",
            "es-MX": "Firma del aplicante",
          },
          type: "signaturepad",
          isRequired: required,
          name: "file_1",
        },
      ],
    },
  ],
});
