import env from "../../env";
const required = env.ENDPOINT !== "local";

export const review_finadev = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "fr-TD": "Soumettre une demande de prêt",
  },
  questionStartIndex: 1,
  requiredText: "",
  //TODO calculations outstanding
  pages: [
    {
      title: { en: "Review and sign", "fr-TD": "Vérifiez et signez" },
      innerIndent: 1,
      name: "Vérifier et signer",
      elements: [
        {
          title: {
            en: "Requested loan amount (local currency)",
            "fr-TD": "Montant du prêt demandé (TTC) :",
          },
          type: "expression",
          expression: "formatCurrency({amount}, 'XAF', 'fr-TD')",
        },
        {
          title: { en: "Loan Term (months)", "fr-TD": "Durée du prêt (mois) :" },
          type: "expression",
          expression: "{repayment_period}",
        },
        {
          title: {
            en: "Admin fees%:",
            "fr-TD": "Frais d'administration TTC (5000 ou 0,5 %) :",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_1.total}",
        },
        {
          title: {
            en: "Monthly interest",
            "fr-TD": "Taux d'intérêt total (2,36% HT) :",
          },
          type: "expression",
          expression: "{total_interest}",
        },
        {
          title: {
            en: "File analysis costs with a minimum of 2000 (2,36% HT):",
            "fr-TD":
              "Frais d'analyse de dossier avec un minimum de 2000 (2,36 % HT) :",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_2.total}",
        },
        {
          title: {
            en: "Contribution APEMFT 200 FCAF",
            "fr-TD": "Contribution APEMFT 200 FCAF :",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_3.total}",
        },
        {
          title: {
            en: "Death pervoyance fund (2% or 2.5% of the amount granted depending on the duration)",
            "fr-TD":
              "Fonds de prévoyance décès (2% ou 2,5% du montant accordés en fonction de la durée) :",
          },
          type: "expression",
          expression: "{charge_dict.charge_code_4.total}",
        },
        {
          title: {
            en: "The customer towards 20% of 1,000,000 or 200,000 of the security deposit. This amount is returned to the customer at the end of the repayment",
            "fr-TD":
              "Dépôt de garantie (10% à 20%) en fonction du montant accordé :",
          },
          description: {
            en: "This is calculated only after the loan is approved",
            "fr-TD": "Le dépôt de garantie sera calculé après l’approbation du prêt",
          },
          type: "expression",
          expression: " ",
        },
        {
          html: {
            en: "To ensure that you are serious about a timely monthly payment, you will be charged 3% for every day you are late with payment.",
            "fr-TD":
              "En cas de retard de paiement, un supplément de 3% sur chaque échéance due (capital + intérêts) vous sera facturé prorata temporis. ",
          },
          type: "html",
        },
        {
          expression:
            "'En cas d`octroi, la durée du prêt sera de '+ {repayment_period} + ' mois, le prêt sera intégralement remboursé. Pour ce prêt, les versements seront de ' + fixed(sum({amount},{total_interest}),0) + ' CFA.'",
          type: "expression",
          title: {
            en: " ",
            "fr-TD": " ",
          },
        },
        {
          html: {
            en: " No variation or amendment of this Agreement shall be valid unless committed to writing and signed by or on behalf of both parties",
            "fr-TD":
              "Aucune variation ou amendement au présent Accord ne sera valide à moins d'être commis par écrit et signé par ou au nom des deux parties.",
          },
          type: "html",
        },
        {
          title: { en: "Your signature", "fr-TD": "Votre signature" },
          type: "panel",
          name: "Votre signature",
          elements: [
            {
              type: "checkbox",
              name: "confirm_4",
              isRequired: required,
              storeOthersAsComment: false,
              choices: [
                {
                  value: "Je suis d'accord",
                  text: { en: "I agree", "fr-TD": "Je suis d'accord" },
                },
              ],
              title: {
                en: "My name is {first_name} and my ID number is {id_number}. I confirm that the information given in this form is true, complete and accurate.",
                "fr-TD":
                  "Mon nom est {first_name} et mon numéro d'identification est {id_number}. Je confirme que les informations fournies sur ce formulaire sont vraies, complètes et exactes.",
              },
            },
            {
              title: {
                en: "Sign loan application",
                "fr-TD": "Signez la demande",
              },
              type: "signaturepad",
              isRequired: true,
              name: "signature",
            },
          ],
        },
      ],
    },
  ],
};
