import React from 'react';
import ReactDOM from 'react-dom';
import './file_uploader.scss';
import {Icon, ws_c_cancel, ws_plus} from "../components/icon/icon";
import {translate} from "../lib/intl";
import {Spinner} from 'react-activity';
import {colors} from "../theme/theme";
import './file_uploader.scss';
import {onUploadFiles} from "./survey.utils";
import {FabButton} from "../components/button/fab";

class UploadComponent extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      state: props.question.value ? 'loaded' : 'awaiting', // awaiting, pending, loaded
      file_url: props.question.value ? props.question.value : null,
      accept: props.question.accept ? props.question.accept : 'image/*,application/pdf',
      file_name: null
    };

  }

  _clean() {
    this.setState({state: 'awaiting', file_url: null, file_name: null});
    this.props.changeHandler(null);
  };

  _upload(event) {

    event.preventDefault();

    this.setState({state: 'pending'});

    let src = event.target || event.srcElement;

    // if (!window["FileReader"]) return;
    // if (!src || !src.files || src.files.length < 1) return;

    onUploadFiles(null, {files: src.files, callback: (file_url) => this._onLoaded(file_url)})

    // set filename
    this.setState({file_name: src.files[0]['name']})
  }

  _onLoaded(file_url) {

    // callback after loading function
    // save file url to local state, but also propagate to survey
    if (!file_url) {
      this.setState({state: 'awaiting'});

    } else {
      this.setState({state: 'loaded', file_url: file_url});
      this.props.changeHandler(file_url);
    }
  }

  render() {
    return (
      <div className='fileContainer'>
        <div className='file_uploader'
             style={this.state.state === 'loaded' ? {backgroundImage: `url(${this.state.file_url})`} : {}}>

          {this.state.state === 'awaiting' && (
            <label className='clickable'>
              <input
                ref={(ref) => this.fileInput = ref}
                type="file"
                style={{color: 'transparent'}}
                onChange={(event) => this._upload(event)}
                aria-label='file'
                multiple={false}
                title='title'
                accept={this.state.accept}
              />
              <div className='icon_upload'>
                <Icon icon={ws_plus} size={20} color={colors.coolGray.c4}/>
              </div>
              <div className='center xsmall'>{translate('general.file_upload')}</div>
            </label>
          )}

          {this.state.state === 'pending' && (
            <Spinner color={colors.coolGray.c4} size={20} speed={1}/>
          )}

          {this.state.state === 'loaded' && (
            <div className='cancel'>
              <FabButton title='' icon={ws_c_cancel} size={20} backgroundColor={colors.coolGray.c4}
                         onPress={() => this._clean()}/>
            </div>
          )}

        </div>

        {this.state.state === 'loaded' && this.state.file_name &&
        <div className='center xsmall spacer'>{this.state.file_name}</div>}

      </div>
    )
  }
}

export function register_widget_file_uploader(Survey) {

  let widget = {

    name: "file_uploader",

    widgetIsLoaded: function () {
      //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
      return true
    },
    isFit: function (question) {
      return question.getType() === "file_uploader";
    },

    activatedByChanged: function (activatedBy) {
      //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
      Survey.JsonObject.metaData.addClass("file_uploader", [], null, "empty");
      Survey.JsonObject.metaData.addProperties("file_uploader", [
        {
          name: "accept:string",
          default: 'picture',
          choices: ["picture", "file"]
        }
      ]);
    },

    afterRender: function (question, el) {
      let rendering = <UploadComponent question={question} changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, el);
    },

    //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
    htmlTemplate: "<div style='padding: 0 20px'></div>",
  };

  // register widget
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");

}
