import { SurveyComponent } from '../../../../components/survey/survey_component';
import { expressions } from '../../../../survey/validators';
import env from "../../../../env";
const required = env.ENDPOINT !== 'local';

// Rename to cooperative?
export const guarantor_essentials = new SurveyComponent({
  title: {
    en: 'Information related to your Guarantor',
    rw: "Amakuru ajyanye n'umwishingizi wawe",
  },
  name: 'home',
  elements: [
    {
      name: 'cooperative_name',
      type: 'text',
      isRequired: required,
      title: {
        en: 'Name of the Cooperative *',
        rw: 'Izina rya Cooperative *',
      },
    },
    {
      name: 'guarantor_first_name',
      visibleIf: '{cooperative_name} notempty',
      type: 'text',
      title: {
        en: 'Name of the president of the Cooperative​',
        rw: 'Amazina yombi ya perezida wa Cooperative​',
      },
    },
    {
      name: 'guarantor_phone',
      type: 'phone',
      visibleIf: '{cooperative_name} notempty',
      isRequired: required,
      title: {
        en: 'President Mobile No. *',
        rw: 'Nimero ya telefone ya perezida wa koperative​ *',
      },
      description: {
        en:
          'We will use these phone numbers to send your  cooperative a short application form.',
        rw:
          'Tuzakoresha izi numero twohereza umwishingizi wawe ifishi nto yokuzuza.',
      },
      validators: [
        expressions.phone_en('guarantor_phone'),
        expressions.phone_length('guarantor_phone'),
      ],
    },
  ],
});
