import env from "../../env";
const required = env.ENDPOINT !== "local";

export const sign_ffdl = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: false,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-GT": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "Final Signing",
      title: { en: "Your Loan Contract", "es-GT": "Tu pagaré / contrato" },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          expression: "{amount}",
          title: {
            en: "Approved loan amount in Q",
            "es-GT": "Monto del crédito aprobado en Q",
          },
        },
        {
          type: "expression",
          expression: "{product_interest_rate}",
          title: {
            en: "Monthly administrative fees",
            "es-GT": "Gasto administrativo anual (en porcentaje)",
          },
        },
        {
          type: "expression",
          expression: "{repayment_period}",
          title: {
            en: "Approved loan term in months",
            "es-GT": "Plazo del crédito  en meses",
          },
        },
        {
          type: "expression",
          expression: "None",
          title: {
            en: "The cost of insurance and consultation to the bureau will be deducted from the amount requested",
            "es-GT":
              "El costo del seguro y consulta a buró se descontará del monto solicitado",
          },
        },
        {
          type: "expression",
          expression: "fixed({monthly_installment}, 2)",
          title: {
            en: "Approved monthly installment",
            "es-GT": "Cuota mensual",
          },
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          type: "html",
          html: {
            en: "For late payments, you will be charged late interest of two percent (2%) per month and the administrative expenses that the system calculates for the days of delay in payment.",
            "es-GT":
              "Por atraso en pagos se le cobrará intereses moratorios del dos por ciento (2%) mensual y los gastos administrativos que el sistema calcule por los días de atraso en el pago",
          },
        },
        {
          type: "panel",
          name: "terms_and_conditions",
          title: {
            en: "TERMS AND CONDITIONS",
            "es-GT": "TÉRMINOS Y CONDICIONES",
          },
          elements: [
            {
              type: "html",
              html: {
                en: " No variation or amendment of this Agreement shall be valid unless committed to writing and signed by or on behalf of both parties",
                "es-GT":
                  "Ninguna variación o enmienda de este pagaré o contrato será válida a menos que se comprometa por escrito y esté firmada por  ambas partes.",
              },
            },
          ],
        },
        {
          type: "html",
          html: {
            en: "My name is {first_name} and my ID number is {id_number}. I confirm that the information given in this form is true, complete and accurate.",
            "es-GT":
              "Mi nombre es {first_name} y mi número de identificación es {id_number}. Confirmo que la información proporcionada en este formulario es verdadera, completa y precisa.",
          },
        },
        {
          type: "radiogroup",
          name: "confirm_3",
          isRequired: required,
          choices: [
            { value: "I agree", text: { en: "I agree", "es-GT": "Acepto" } },
          ],
          title: {
            en: "I, as the borrower, acknowledge that I have read, understood and accept all the terms and conditions contained herein. I promise to pay unconditionally to the order of the FUNDACIÓN FONDO DE DESARROLLO LOCAL DE GUATEMALA, domiciled at 12 CALLE A 2-34, zona 1, CIUDAD DE GUATEMALA the amount granted plus administrative expenses within the agreed term. I understand that signing this application form signifies my agreement to be legally bound by these terms and conditions. I choose domicilium citandi et executetandi at the residential address shown above and hereby submit to the jurisdiction of the courts of Guatemala in any dispute arising from this agreement, notwithstanding that at the time the procedure begins, I am domiciled outside of Guatemala.",
            "es-GT":
              "Yo, como prestatario, reconozco que he leído, entendido y acepto todos los términos y condiciones aquí contenidos. Prometo pagar incondicionalmente a la orden de la FUNDACIÓN FONDO DE DESARROLLO LOCAL DE GUATEMALA, con domicilio en 12 CALLE A 2-34, zona 1, CIUDAD DE GUATEMALA la cantidad otorgada más gastos administrativos en el plazo acordado.  Firmaré el pagaré o contrato de forma presencial en FDL de Guatemala el día del desembolso.",
          },
        },
        {
          type: "radiogroup",
          name: "confirm_4",
          isRequired: required,
          choices: [
            { value: "I agree", text: { en: "I agree", "es-GT": "Acepto" } },
          ],
          title: {
            en: "I understand the risks involved and will guarantee the repayment of the loan.",
            "es-GT":
              "Entiendo los riesgos involucrados y garantizaré el reembolso del préstamo.",
          },
        },
        {
          type: "signaturepad",
          name: "signature",
          isRequired: true,
          title: {
            en: "Please enter your signature",
            "es-GT": "Por favor ingrese su firma",
          },
        },
        {
          title: { en: "Address", "es-GT": "Dirección" },
          name: "address",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              type: "html",
              name: "address_1",
              html: "Ciudad Guatemala: 12 Calle A 2-34 z. 1",
              visibleIf: "{custom_text_68} == 'Ciudad Guatemala'",
            },
            {
              type: "html",
              name: "address_2",
              html: "Chimaltenango: 6a calle 1-26 Col. Quinta Los Aposentos II z. 1",
              visibleIf: "{custom_text_68} == 'Chimaltenango'",
            },
            {
              type: "html",
              name: "address_3",
              html: "Mazatenango: 4a avenida 1-05 z. 1",
              visibleIf: "{custom_text_68} == 'Mazatenango'",
            },
            {
              type: "html",
              name: "address_4",
              html: "Quetzaltenango: 14 Calle 19-64 z. 3 entrada a Condominio El Triángulo",
              visibleIf: "{custom_text_68} == 'Quetzaltenango'",
            },
          ],
        },
      ],
    },
  ],
};
