import { EducationalAgreementPage } from "../../components/survey/educational_agreement_page";

export const educationalReview = {
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {en: "Submit loan application", ar: "إرسال طلب التمويل"},

  questionStartIndex: 1,
  requiredText: "",

  pages: [ EducationalAgreementPage ]
};


