
export const credicapital_buttons = (loan) => {
  
    let actionButtonList = [];
  
    let startIntakeBtn = {
      title: "Start Intake",
      localeTitle: "Comenzar a aplicar",
      nextState: "applying_intake",
    };
    if (!loan.intake.custom_bool_9) {
      actionButtonList.push(startIntakeBtn);
    }
  
    let assessmentStudyBtn = {
      title: "Start Assessment Study",
      localeTitle: "Estudio socioeconomico",
      nextState: "assessment_study",
    };
    if (!loan.intake.custom_bool_10 && loan.intake.custom_bool_9) {
      actionButtonList.push(assessmentStudyBtn);
    }
  
    return actionButtonList;
  };