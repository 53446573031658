/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */

import Config from "../../../config";
import TocEduloan from "./toc_eduloan";

export function sign_eduloan(toc) {
  if (Config.deployment !== "eduloan") {
    return {};
  }
  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: false,

    firstPageIsStarted: false,

    showCompletedPage: false,
    completeText: {
      en: "Sign contract",
    },

    questionStartIndex: 1,
    requiredText: "",

    pages: [
      TocEduloan(toc),
      {
        title: {
          en: "Review and sign your loan contract",
        },
        name: "contract",
        elements: [
          {
            title: {
              en: "Your final loan terms are:",
            },
            name: "terms",
            type: "panel",
            innerIndent: 1,
            elements: [
              {
                title: {
                  en: "Repayment Schedule",
                },
                type: "repayment_schedule",
                name: "repayment_schedule",
              },
              {
                title: {
                  en: "Required tuition amount (in ZWL)",
                },
                type: "expression",
                expression: "fixed({intake.custom_float_10}, 2)",
                displayStyle: "currency",
                currency: "ZWL",
              },
              {
                title: {
                  en: "Loan amount to apply for (in ZWL)",
                },
                type: "expression",
                expression: "{total_final_loan_amount}",
              },
              {
                title: {
                  en: "Loan Term (in Months)",
                },
                type: "expression",
                expression: "{repayment_period}",
              },
              {
                title: {
                  en: "Initiation fees (%)",
                },
                type: "expression",
                expression: "{charge_dict.charge_code_1.percentage}",
              },
              {
                title: {
                  en: "Initiation fees amount",
                },
                type: "expression",
                expression: "fixed({charge_dict.charge_code_1.total}, 2)",
                displayStyle: "currency",
                currency: "ZWL",
              },
              {
                title: {
                  en: "Monthly Interest Rate (%)",
                },
                type: "expression",
                expression: "{monthly_interest_rate}",
              },
              {
                title: {
                  en: "Monthly Installment (in ZWL)",
                },
                type: "expression",
                expression: "fixed({monthly_installment}, 2)",
                displayStyle: "currency",
                currency: "ZWL",
              },
              {
                title: {
                  en: "Net Disbursement",
                },
                type: "expression",
                expression: "fixed({intake.custom_float_10}, 2)",
                displayStyle: "currency",
                currency: "ZWL",
              },
              {
                title: "Final Agreement",
                type: "checkbox",
                name: "agree_final_loan_terms",
                isRequired: true,
                choices: [{ value: "agree", text: { en: "I agree" } }],
                validators: [],
              },
            ],
          },
          {
            title: { en: "Your signature" },
            name: "signature",
            type: "panel",
            innerIndent: 1,

            elements: [
              {
                title: {
                  en: "My name is {first_name} and my ID number is {id_number}.  I confirm that the information given in this form is true, complete and accurate.",
                },
                type: "checkbox",
                name: "truth",
                isRequired: true,
                choices: [{ value: "agree", text: { en: "I agree" } }],
                validators: [],
              },
              {
                title: {
                  en: "I understand the risks involved and will guarantee the repayment of the loan.",
                },
                type: "checkbox",
                name: "risks",
                isRequired: true,
                choices: [{ value: "agree", text: { en: "I agree" } }],
                validators: [],
              },
              {
                type: "signaturepad",
                name: "signature",
                isRequired: true,
                title: {
                  en: "Please enter your signature",
                },
              },

              {
                type: "text",
                inputType: "text",
                name: "sign_name",
                isRequired: true,
                title: {
                  en: "Please enter your full name",
                },
              },

              {
                type: "text",
                inputType: "date",
                name: "sign_date",
                isRequired: true,
                title: {
                  en: "Please enter the date",
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
