import env from "../../env";
const required = env.ENDPOINT !== "local";

export const guarantor_finadev = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",
  calculatedValues: [
    {
      name: "page_title_name",
      expression: `iif({custom_text_128},{custom_text_128},iif({custom_text_127},{custom_text_127},"garant"))`,
    },
  ],
  pages: [
    {
      title: {
        en: "Guarantor acceptance",
        "fr-TD": "Acceptation du {page_title_name}",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Welcome {first_name}, you are invited to be the guarantor of {user_first_name} for a loan amount of {amount} Request at FINADEV. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
            "fr-TD":
              "Bienvenue {first_name}, vous êtes le garant de {user_first_name} pour un montant de {amount} de prêt de {user_first_name}, demandé chez FINADEV. Pour répondre à cette exigence, il vous sera demandé des informations vous concernant. Sélectionnez si vous acceptez ou pas. Si vous avez des questions, veuillez nous contacter.",
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: { en: "I agree", "fr-TD": "Je suis d'accord" },
            },
            {
              value: true,
              text: {
                en: "I don’t accept",
                "fr-TD": "Je n'accepte pas",
              },
            },
          ],
        },
      ],
    },
    {
      title: {
        en: "Guarantor acceptance and information ",
        "fr-TD":
          "Informations sur le {page_title_name}",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "What is your full name?*",
            "fr-TD": "Quel est votre nom complet?*",
          },
          type: "text",
          name: "first_name",
          isRequired: required,
        },
        {
          title: {
            en: "What is date of birth?*",
            "fr-TD": "Quel est votre date de naissance?*",
          },
          type: "text",
          name: "date_of_birth",
          isRequired: required,
          inputType: "date",
        },
        {
          title: {
            en: "What is your nationality?*",
            "fr-TD": "Quelle est votre nationalité?*",
          },
          type: "text",
          name: "nationality",
          isRequired: required,
        },
        {
          title: {
            en: "What is the number of your identity card or passport or driving license or voter card*",
            "fr-TD":
              "Quel est le numéro de votre Carte d'identité / Passeport / Permis de conduire / Carte d'électeur ?*",
          },
          type: "text",
          name: "id_number",
          isRequired: required,
        },
        {
          title: {
            en: "Upload your identity card or passport or driver's license or voter card",
            "fr-TD":
              "Téléchargez votre Carte d'identité / Passeport / Permis de conduire / Carte d'electeur*",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file",
          isRequired: required,
        },
        {
          title: {
            en: "Marital status*",
            "fr-TD": "Quel est votre situation matrimoniale?*",
          },
          type: "dropdown",
          isRequired: required,
          name: "marital",
          choices: [
            {
              value: "Célibataire",
              text: { en: "Single", "fr-TD": "Célibataire" },
            },
            { value: "Marié", text: { en: "Married", "fr-TD": "Marié" } },
          ],
        },
        {
          title: {
            en: " What is your gender?",
            "fr-TD": "Quel est votre sexe",
          },
          type: "dropdown",
          isRequired: required,
          name: "gender",
          choices: [
            { value: "Masculin", text: { en: "male", "fr-TD": "Masculin" } },
            { value: "Féminin", text: { en: "female", "fr-TD": "Féminin" } },
          ],
        },
        {
          title: {
            en: "No. Dependents",
            "fr-TD": "Nombre de personnes à charge",
          },
          type: "text",
          inputType: "number",
          name: "dependence_number",
          isRequired: required,
        },
        {
          title: {
            en: "Cell phone number*",
            "fr-TD": "Quel est votre numéro de telephone ?",
          },
          type: "text",
          inputType: "number",
          name: "cell",
          isRequired: required,
        },
        {
          title: { en: "Email", "fr-TD": "Adresse é-mail" },
          type: "text",
          name: "email",
          isRequired: false,
        },
        {
          title: { en: "Country *", "fr-TD": "Pays*" },
          type: "text",
          name: "home_country",
          isRequired: required,
        },
        {
          title: { en: "City *", "fr-TD": "Ville*" },
          type: "text",
          name: "home_city",
          isRequired: required,
        },
        {
          title: { en: "Lot", "fr-TD": "Lot" },
          type: "text",
          name: "custom_text_1",
          isRequired: required,
        },
        {
          title: { en: "Cartier", "fr-TD": "Quartier" },
          type: "text",
          name: "custom_text_2",
          isRequired: required,
        },
        {
          title: { en: "Village", "fr-TD": "Village" },
          type: "text",
          name: "custom_text_3",
          isRequired: required,
        },
        {
          title: { en: "Conton", "fr-TD": "Canton" },
          type: "text",
          name: "custom_text_4",
          isRequired: required,
        },
        {
          title: {
            en: "Indications",
            "fr-TD": " Fourni les Indications (itinéraire)",
          },
          type: "text",
          name: "custom_text_5",
          isRequired: required,
        },
        {
          name: "custom_text_123",
          title: {
            en: "Modality you guarantee with:",
            "fr-TD": "Mode de garantie:",
          },
          type: "expression",
          visibleIf: "{custom_text_75}!=''",
          expression: `{custom_text_75}`,
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger le titre de propreté de votre garantie ",
          },
          visibleIf:
            "{custom_text_75} == 'Terrain nu' || {custom_text_75} == 'Parcelle batie'",
          type: "file_uploader",
          isRequired: required,
          accept: "image/*,application/pdf",
          name: "file_2",
        },
        {
          title: {
            en: "Copy of employment contract & work certificate / certificate if private sector employees",
            "fr-TD":
              " Veuillez télécharger votre contrat de travail ou attestation/certificat de travail si vous travaillez pour le privé ou  l'attestation de validité de service si vous êtes permanent de l’Etat ou l'attestation/certificat de présence à corps si vous êtes  militaire ou policier",
          },
          type: "file_uploader",
          visibleIf: "{custom_text_75} == 'Avaliseur Salarié'",
          isRequired: required,
          accept: "image/*,application/pdf",
          name: "file_1",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 1 ",
          },
          visibleIf: "{custom_text_75} == 'Avaliseur Salarié'",
          type: "file_uploader",
          isRequired: required,
          accept: "image/*,application/pdf",
          name: "file_3",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 2 ",
          },
          visibleIf: "{custom_text_75} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_4",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 3 ",
          },
          visibleIf: "{custom_text_75} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_5",
        },
        {
          name: "custom_text_124",
          title: {
            en: "Modality you guarantee with:",
            "fr-TD": "Mode de garantie:",
          },
          type: "expression",
          visibleIf: "{custom_text_83}!=''",
          expression: `{custom_text_83}`,
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger le titre de propreté de votre garantie ",
          },
          visibleIf:
            "{custom_text_83} == 'Terrain nu' || {custom_text_83} == 'Parcelle batie'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_6",
        },
        {
          title: {
            en: "Copy of employment contract & work certificate / certificate if private sector employees",
            "fr-TD":
              " Veuillez télécharger votre contrat de travail ou attestation/certificat de travail si vous travaillez pour le privé ou  l'attestation de validité de service si vous êtes permanent de l’Etat ou l'attestation/certificat de présence à corps si vous êtes  militaire ou policier",
          },
          type: "file_uploader",
          visibleIf: "{custom_text_83} == 'Avaliseur Salarié'",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_14",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 1 ",
          },
          visibleIf: "{custom_text_83} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_7",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 2 ",
          },
          visibleIf: "{custom_text_83} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_8",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 3 ",
          },
          visibleIf: "{custom_text_83} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_9",
        },
        {
          name: "custom_text_125",
          title: {
            en: "Modality you guarantee with:",
            "fr-TD": "Mode de garantie:",
          },
          type: "expression",
          visibleIf: "{custom_text_91}!=''",
          expression: `{custom_text_91}`,
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger le titre de propreté de votre garantie ",
          },
          visibleIf:
            "{custom_text_91} == 'Terrain nu' || {custom_text_91} == 'Parcelle batie'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_10",
        },
        {
          title: {
            en: "Copy of employment contract & work certificate / certificate if private sector employees",
            "fr-TD":
              " Veuillez télécharger votre contrat de travail ou attestation/certificat de travail si vous travaillez pour le privé ou  l'attestation de validité de service si vous êtes permanent de l’Etat ou l'attestation/certificat de présence à corps si vous êtes  militaire ou policier",
          },
          type: "file_uploader",
          visibleIf: "{custom_text_91} == 'Avaliseur Salarié'",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_15",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 1 ",
          },
          visibleIf: "{custom_text_91} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_11",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 2 ",
          },
          visibleIf: "{custom_text_91} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_12",
        },
        {
          title: {
            en: " Update your property statement",
            "fr-TD": " Télécharger vos 3 derniers bulletins de paie: numéro 3 ",
          },
          visibleIf: "{custom_text_91} == 'Avaliseur Salarié'",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "file_13",
        },
        {
          name: "custom_text_131",
          title: {
            en: " ",
            "fr-TD": " ",
          },
          visibleIf: "{collateral_arrangements} == 'réelle'",
          type: "expression",
          expression: `'Mr/Mme ' + {first_name} + ', j\\'accepte volontairement de se porter garant pour garantir le remboursement du crédit qu\\'accordera FINADEV TCHAD SA à Mr/Mme ' + {user_first_name} + '. ' `,
        },
        {
          name: "custom_text_101",
          title: {
            en: " ",
            "fr-TD": " ",
          },
          visibleIf: "{collateral_arrangements} == 'caution_solidaire_du_groupe'",
          type: "expression",
          expression: `'Mr/Mme ' + {first_name} + ', j\\'accepte volontairement de se porter caution solidaire et indivisible pour garantir le remboursement du crédit qu\\'accordera FINADEV TCHAD SA à Mr/Mme ' + {user_first_name} + '. ' `,
        },
        {
          name: "custom_text_102",
          title: {
            en: " ",
            "fr-TD": " ",
          },
          type: "expression",
          expression: `'En cas de non-paiement, je déclare être prêt à faire face au montant de l\\'échéance et aux intérêts de retard dus au prorata du nombre de jours de retard conuu en cas d\\'incapacité de ce dernier à faire face à ses engagements financier quel qu\\'en soit la cause. ' `,
        },
        {
          name: "custom_text_103",
          type: "expression",
          title: {
            en: " ",
            "fr-TD": " ",
          },
          expression: `'Je comprends les risques liés à une demande de prêt et garantirai le remboursement du prêt au cas où ' + {user_first_name} + 'ne serait pas en mesure de rembourser. '`,
        },
        {
          title: { en: "I agree", "fr-TD": "Je suis d'accord" },
          type: "radio",
          isRequired: required,
          name: "confirm_4",
          choices: [
            {
              value: "Je suis d'accord",
              text: { en: "I agree", "fr-TD": "Je suis d'accord" },
            },
          ],
        },
        {
          title: { en: "Sign", "fr-TD": "Signer" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
      ],
    },
  ],
};
