import { SurveyComponent } from "../../../components/survey/survey_component";

export const TechnicalLoanTerms = new SurveyComponent({
  name: "loan_terms",
  valueName: "loan_terms",
  type: "expression",
  expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance}, null)", // Floating Point Proof
  title: "Technical loan terms (NOT visible for user)",
  visibleIf: "{amount} < 0",
});
