import React from 'react';
import { connect } from 'react-redux';
import * as Survey from 'survey-react';
import { Bullet } from '../../components/bullet/bullet';
import HelpComponent from '../../components/help/help';
import Config from '../../config';
import { DEFAULT_LOCALE, translate, translateList } from '../../lib/intl';
import { loanActions } from '../../store/store';
import { extractSurveyData } from '../../survey/survey.utils';
import { auth_procredito_pages } from './pages/auth_procredito';
import { date_formatter } from '../../survey/calculations';

export function auth_request_pages(locale = DEFAULT_LOCALE) {
  const procredito_pages = auth_procredito_pages;

  const availablePages = {
    procredito: procredito_pages,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),

    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageNextText"), // note same as `Next`

    requiredText: "",
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
  };
}

/**
 * Container for user to enter sms token
 * Users must be notified in the previous (AuthRequestContainer) step that they will be asked to enter a sms token
 * The backend flows will automticall send the sms token to the user via the phone number BEFORE this container is rendered
 * */
export class Authentication extends React.PureComponent {
  constructor(props) {
    super(props);

    // setup model
    this.model = new Survey.Model(auth_request_pages(props.device.locale));
    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan,
      intake: props.loan.intake,
      date: date_formatter(props.device.locale).format(new Date()),
    };

    // set callbacks
    this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };

    console.log(this.model);
    this.model.render();

    try {
      this.bullets = translateList("two_factor_auth.bullets").map(
        (bullet, idx) => Bullet(translate(bullet), idx)
      );
      this.requirements = translateList("two_factor_auth.requirements").map(
        (req, idx) => Bullet(translate(req), idx)
      );
      this.descList = translateList("two_factor_auth.desc_list").map(
        (desc, idx) => (
          <p className="spacer" key={idx}>
            {translate(desc)}
          </p>
        )
      );
    } catch (e) {
      console.error(
        `Unable to translate ${e}, check translation key is added in locale file `
      );
    }
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time:
        this.state.help_time +
        Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  async onCompleting(survey) {
    console.log("Complete! ", survey);
    let data = extractSurveyData(survey, this.state, this.base);

    await this.props.validateAuthToken(
      this.props.loan.uuid,
      data.custom_text_150
    );
  }

  render = ({ device } = this.props) => {
    return (
      <div className="">
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />
          <div className="centerWrapper">
            <Survey.Survey model={this.model} locale={device.locale} />
          </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateAuthToken: (uuid, token) =>
      dispatch(loanActions.twoFactorAuth(uuid, token, "validate")),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const mapStateToProps = (state) => {
  return {
    loan: state.loan,
    device: state.device,
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication);

export { connectedContainer as AuthenticationContainer };
