import PropTypes from 'prop-types';
import React from 'react';

import { Spinner } from 'react-activity';
import './Spinner.scss';
import { Icon } from "../icon/icon";


import './button.scss';

export class Button extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    style: PropTypes.any,
    onPress: PropTypes.func
  };

  static defaultProps = {
    title: 'default title',
    backgroundColor: '#000',
    color: '#fff',
    onPress: () => {
    }
  };

  render({
           className = '',
           backgroundColor,
           color,

           onPress,
           title,
           isLoading,
           iconSize = 16,
           fontSize = 16,

           borderColor = null,

           iconMiddle = '',
           iconLeft = '',
           icon = '',

           style,
         } = this.props) {

    return (
      <div className="buttonComponentContainer" style={{borderColor: borderColor ? borderColor : backgroundColor}}>
        <button
          className={`button ${className}`}
          style={{backgroundColor: backgroundColor, color: color, ...style}}
          disabled={isLoading}
          onClick={!isLoading ? onPress : () => {
          }}>
          {!isLoading ? (
              <div className={`buttonWrapper ${(iconLeft === '' && icon === '') ? 'padding' : ''}`}>

                {(iconLeft !== '' || icon !== '') && (
                  <div className='icon le'>
                    {(iconLeft !== '') && (
                      <Icon icon={iconLeft} color={color} size={iconSize}/>
                    )}
                  </div>
                )}

                <span className="title" style={{fontSize: fontSize}}>
                  {iconMiddle && (
                    <div style={{marginRight: 6}}>
                      <Icon icon={iconMiddle} color={color} size={iconSize}/>
                    </div>
                  )}
                  {title}
                </span>

                {(iconLeft !== '' || icon !== '') && (
                  <div className='icon ri'>
                    {icon !== '' && (
                      <Icon icon={icon} color={color} size={iconSize}/>
                    )}
                  </div>
                )}

              </div>
            ) :
            <Spinner color="#fff" size={fontSize} speed={1}/>
          }
        </button>
      </div>
    );
  }
}
