import { SurveyComponent } from '../../../../components/survey/survey_component';
import env from "../../../../env";
const required = env.ENDPOINT !== 'local';

export const identification_essentials = new SurveyComponent({
  name: 'identification',
  title: {
    en: 'Identification Information',
    rw: "Amakuru ajyanye n'umwirondoro",
  },
  elements: [
    {
      name: 'nationality',
      type: 'dropdown',
      isRequired: required,
      storeOthersAsComment: false,
      hasOther: true,
      title: {
        en: 'Nationality *',
        rw: 'Ubwenegihugu *',
      },
      choices: [
        {
          value: 'essentials',
          text: {
            en: 'Essentials',
            rw: 'Essentials',
          },
        },
      ],
    },
    {
      name: 'id_number',
      type: 'text',
      isRequired: required,
      validators: [
        {
          "type": "regexvalidator",
          "text": "Number should be from 16 digits length",
          "regex": "^[0-9]{16}$",
        }
      ],
      title: {
        en: 'ID No. *',
        rw: "Nimero y'indangamuntu ​ *",
      },
    },
    {
      title: {
        en: 'Front Image (Attachment) *',
        rw: "Ifoto y'imbere y'indangamuntu *",
      },
      description: {
        en:
          "If you don't have an ID card upload the replacement document.",
        rw: 'Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura',
      },
      name: 'id_file',
      type: 'file_uploader',
      accept: 'image/*,application/pdf',
      isRequired: required,
    },
    {
      title: {
        en: 'Back Image (Attachment) *',
        rw: "Ifoto y'inyuma y'indandamuntu *",
      },
      description: {
        en:
          'If you cannot find the document now, you can close this form and come back later.',
        rw: 'Niba udafite icyangombwa nonaha, wafunga ugakomeza nyuma',
      },
      name: 'id_file_back',
      type: 'file_uploader',
      accept: 'image/*,application/pdf',
      isRequired: required,
    },
    {
      name: 'id_avatar',
      type: 'panel',
      innerIndent: 1,
      title: {
        en: 'Please verify that this card belongs to you',
        rw: 'Genzura niba ikarita ari iyawe',
      },
      elements: [
        {
          name: 'id_avatar_desc',
          type: 'html',
          description: {
            en:
              'Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.',
            rw: 'Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe',
          },
          html: {
            en:
              'Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.',
            rw: 'Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe',
          },
        },
        {
          name: 'id_avatar_desc_picture',
          type: 'html',
          html: `<img alt="example" src='${require('../../../../assets/images/example_card.png')}' width='200'/>`,
        },
        {
          name: 'id_file_with_avatar',
          type: 'file_uploader',
          accept: 'image/*,application/pdf',
          isRequired: false,
          title: {
            en: 'Identification Picture ',
            rw: 'Ibiranga ifoto ',
          },
        },
      ],
    },
  ],
});
