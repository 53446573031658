import React from 'react';
import {translate} from "../../lib/intl";
import {api} from "../../app";
import {ws_whatsapp, ws_envelope} from "../icon/icon";
import {openInWhatsapp} from "../../lib/helpers";
import {Button} from "../button/button";

import './footer.scss';
import {colors} from "../../theme/theme";
import Config  from '../../config';

export class Footer extends React.PureComponent {

  render = () => (

    <div className='footerContainer'>

      <img className='banner' src={require('./res/footer_banner.png')} alt='footer'/>

      <div className='footerWrapper'>

        <div className="footer">

          <img src={require('../../assets/logos/logo_white.png')} alt='logo' width='100px'/>


          <h2 className='white bigSpacer'>{translate('footer.title')}</h2>

          <p className='white center spacer'>{translate('footer.par1')}</p>
          <p className='white center'>{translate('footer.par2')}</p>
          <p className='white center'>{translate('footer.par3')}</p>

          {/** Button only visible for deployments that use whatsapp */}
          {Config.whatsapp_active && <div className='footer_button bigSpacer'>
            <Button backgroundColor={colors.brand.secondary} color='#fff' borderColor='#fff'
                    fontSize={14} iconSize={20}
                    title={translate('footer.question_button')} icon={ws_whatsapp}
                    onPress={() => openInWhatsapp(api.endpoint.whatsapp_number, translate('footer.question_button'))}/>
          </div>}
          {/** Button only visible for deployments that get help through email */}
          {Config.email_active && <div className='footer_button bigSpacer'>
            <a href='mailto:contact@fogec.cd'>
              <Button backgroundColor={colors.brand.secondary} color='#fff' borderColor='#fff'
                      fontSize={14} iconSize={20}
                      title={translate('footer.question_button')} icon={ws_envelope}/>
            </a>
            
          </div>}

        </div>

      </div>
    </div>
  );
}
