// import env from "../../env";
// const required = env.ENDPOINT !== "local";

export const sign_credicapital = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-MX": "Revisa y firma tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "html",
          name: "",
          html: {
            en: "Great news, we have reviewed your application and accepted your loan request. <br /> Before we can proceed the payout, please review the final terms and sign the loan contract.",
            "es-MX":
              "Buenas noticias, hemos revisado y aceptado tu solicitud de crédito. <br /> Antes de que podamos proceder con el desembolso, revisa los términos finales y acepta las condiciones del crédito.",
          },
        },
        {
          type: "panel",
          title: {
            en: "Review and sign your loan contract",
            "es-MX": "Revisa y acepta tu contrato de préstamo",
          },
          name: "",
          elements: [
            {
              type: "html",
              name: "",
              html: {
                en: "Your final loan terms are:",
                "es-MX": "Los términos finales de tu préstamo son:",
              },
            },
            {
              type: "expression",
              expression:
              //temporary solution
              "templateConditional(templateConditional(sum(multiply({amount},1.1),{charge_ref_dict.insurance.total}),multiply({amount},1.1),{intake.custom_text_22},'si'),templateConditional(sum({amount},{charge_ref_dict.insurance.total}),{amount},{intake.custom_text_22},'si'),{intake.custom_text_141},'financiada')",
              //"templateConditional(sum({total_final_loan_amount}, {charge_ref_dict.insurance.total}), {total_final_loan_amount},{intake.custom_text_141},'financiada')",
              title: {
                en: "Approved loan amount in pesos",
                "es-MX": "Monto del crédito aprobado en pesos mexicanos",
              },
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_22} == 'si'",
              expression: "{charge_ref_dict.insurance.total}",
              title: {
                en: "Insurance cost",
                "es-MX": "Seguro de vida (en pesos)",
              },
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_22} == 'no'",
              expression: "{charge_ref_dict.unfinanced_insurance.total}",
              title: {
                en: "Insurance cost",
                "es-MX": "Seguro de vida (en pesos)",
              },
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression: "{intake.custom_text_4}",
              title: {
                en: "RFC",
                "es-MX": "RFC",
              },
            },
            {
              type: "expression",
              expression: "{repayment_period}",
              title: {
                en: "Approved loan term",
                "es-MX": "Plazo del crédito",
              },
            },
            {
              type: "expression",
              expression: "capitalize('first', {intake.custom_text_28})",
              title: {
                en: "Payment frequency",
                "es-MX": "Frecuencia de pago",
              },
            },
            {
              type: "expression",
              expression: "displayColumnsFromJsonfield({intake.custom_jsonfield_13}, 'descripcion_de_la_garantia')",
              title: {
                en: "Pledge, Mortgage Guarantees",
                "es-MX": "Garantías Prendarias, Hipotecarias",
              },
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_24} == 'comision'",
              expression: "{charge_ref_dict.commission.amount}",
              title: {
                en: "Commission%",
                "es-MX": "% de Comisión",
              },
            },
            {
              type: "expression",
              expression:
                "fixed({charge_ref_dict.commission.total}-{charge_ref_dict.vat_on_commission.total})",
              title: {
                en: "AMOUNT",
                "es-MX": "MONTO CREDICAPITAL",
              },
              visibleIf: "{intake.custom_text_24} == 'comision'",
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression: "fixed({charge_ref_dict.vat_on_commission.total})",
              title: {
                en: "AMOUNT",
                "es-MX": "IVA 16%",
              },
              visibleIf: "{intake.custom_text_24} == 'comision'",
            },
            {
              type: "expression",
              expression:
                "fixed(templateConditional(sum({charge_ref_dict.commission.total}, multiply({charge_ref_dict.insurance.total}, 0.04)), {charge_ref_dict.commission.total}, {intake.custom_text_22}, 'si'))",
              title: {
                en: "AMOUNT",
                "es-MX": "TOTAL COMISIÓN",
              },
              visibleIf: "{intake.custom_text_24} == 'comision'",
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              visibleIf: "{intake.custom_text_24} == 'garantia' ",
              expression: "{charge_dict.charge_code_4.total}",
              title: {
                en: "% of liquid guarantee",
                "es-MX": "%  de Garantía líquida",
              },
            },
            {
              type: "expression",
              expression: "{intake.custom_float_49}",
              title: {
                en: "Ordinary rate",
                "es-MX": "Tasa ordinaria",
              },
            },
            {
              type: "expression",
              expression: "{intake.custom_float_50}",
              title: {
                en: "Default rate",
                "es-MX": "Tasa moratoria",
              },
            },
          ],
        },
      ],
    },
  ],
};
