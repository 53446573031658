import React from 'react';

import {Icon, ws_next} from "../icon/icon";
import {colors} from "../../theme/theme";

import {Reveal} from "../reveal/reveal";
import {translate} from "../../lib/intl";

import './question.scss';

export class Question extends React.Component {
  constructor() {
    super();

    this.state = {
      openQuestion: null,
    }
  }

  render({questions, borderColor} = this.props) {
    return (

      <Reveal className="questionContainer">

          {questions.map((question, index) => (
            <div key={index} className="question">
              {// eslint-disable-next-line
              }<a onClick={() => this._toggleQuestion(index)}>

                <div className="question">
                  <p className="heavy questionHeader">{translate(`q_and_a.${question}_question`)}</p>

                  <div className={`toggleIcon ${this.state.openQuestion === index ? 'open' : 'closed'}`}>
                    <Icon icon={ws_next} color={colors.brand.darkgray} size={20}/>
                  </div>
                </div>
              </a>

              <div className={`answer ${this.state.openQuestion === index ? 'open' : 'closed'}`}>
                <p>{translate(`q_and_a.${question}_answer`)}</p>
              </div>
            </div>
          ))}
        </Reveal>
    )
  }

  _toggleQuestion(index) {
    this.setState({openQuestion: this.state.openQuestion === index ? null : index});
  }


}
