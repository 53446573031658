import React from "react";
import { connect } from "react-redux";
import { Start } from '../../components/start/start';
import Config from '../../config';
import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import { translate} from "../../lib/intl";
import { loanActions } from "../../store/store";

export function auth_valid_pages() {
  

  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),

    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageNextText"), // note same as `Next`

    requiredText: "",
    isSinglePage: true, // Can be set to True for review mode
    pages: [],
  };
}
export class AuthenticationStart extends React.PureComponent {

  render = ({ device, loan } = this.props) => {
    return (
      <div >
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />
    
            <div>
                <Start
                stage="two_factor_auth"
                time={Config.has_no_credit_check_time ? '0' : '5'}
                onStart={() => this.props.twoFactorAuth(loan.uuid, "send")}
                >
                {this.descList}
                {this.bullets}
                {this.requirements}
                </Start>
                <Footer />
            </div>
 
       <Footer />
      
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    twoFactorAuth: (uuid, action) => dispatch(loanActions.twoFactorAuth(uuid, '', action)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const mapStateToProps = (state) => {
  return {
    loan: state.loan,
    device: state.device,
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationStart);

export { connectedContainer as AuthenticationStartContainer };
