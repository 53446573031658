import env from "../../env";
const required = env.ENDPOINT !== "local";

const completion_page = {
  title: {
    en: "Credit bureau check",
    "es-MX": "Verificación Buró de crédito",
  },
  // innerIndent: 1,
  elements: [
    {
      type: "panel",
      name: "info2",
      elements: [
        {
          html: {
            en: `<div>`,
            "es-MX": `<div style="text-align: justify;">Por este medio autorizo expresamente a PRO CONFIANZA S.A. DE C.V. SOFOM E.N.R., para que por conducto de sus funcionarios facultados lleve a cabo 
            Investigaciones sobre mi comportamiento crediticio o el de la Empresa que represento, en Tras Union de México S.A. SIC. y/o Dun & Bradstreet, S.A. SIC.<br><br>

            Así mismo, declaro que conozco la naturaleza y alcance de las sociedades de información crediticia y de la información contenida en los reportes de crédito y 
            reporte de crédito especial; declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que PRO CONFIANZA S.A. DE C.V. SOFOM E.N.R. 
            hará de tal información y de que ésta podrá realizar consultas periódicas sobre mi historial crediticio o el de la empresa que represento, consintiendo que esta 
            autorización se encuentre vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso, durante el tiempo que se mantenga la relación jurídica.<br><br>

            En caso de que el solicitante sea una Persona Moral, declaro bajo protesta de decir verdad ser Representante Legal de la Empresa mencionada en esta autorización; 
            manifiesto que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados, ni modificados en forma alguna.<br><br>
            
            Estoy consciente y acepto que este documento quede bajo custodia de PRO CONFIANZA S.A. DE C.V. SOFOM E.N.R. y/o Sociedad de Información Crediticia consultada para 
            efectos de control y cumplimiento del artículo 28 de la Ley para Regular las Sociedades de Información Crediticia; mismo que señala que las Sociedades sólo podrán 
            proporcionar información a un Usuario, cuando éste cuente con la autorización expresa del Cliente mediante su firma autógrafa.<br><br>
          </div>
            `,
          },
          title: {
            en: "Authorization Text 1",
            "es-MX": "Authorization Text 1",
          },
          type: "html",
          name: "custom_text_80",
        },
        {
          html: {
            en: `<div>`,
            "es-MX": `<strong>Autorización para Solicitar Reportes de Crédito</strong><br>
            <br><strong>Personas Físicas / Personas Morales. </strong><br>
            <br>Nombre del solicitante (Persona Física o Razón Social de la Persona Moral): {first_name} {middle_name} {surname} {second_surname}<br>
            <br>Para el caso de Persona Moral, nombre del Representante Legal: <br>
              <br>RFC o CURP: {custom_text_5} , {custom_text_6}<br>
              <br><strong>Domicilio</strong>
              <br>Calle: {custom_text_69}, {custom_text_19}, {custom_text_66}
              <br>Colonia: {custom_text_29}
              <br>Municipio:{custom_text_28}
              <br>Estado: {custom_text_14}
              <br>Codigo Postal: {po_box_postal_code}
              <br>Teléfono(s): {phone_number}
            `,
          },
          title: {
            en: "Minimum fare charge",
            "es-MX": "Cobro mínimo del pasaje",
          },
          type: "html",
        },
        {
          type: "expression",
          title: {
            en: "Sign date",
            "es-MX": "Fecha en que se firma la autorización:",
          },
          name: "custom_text_74",
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          type: "checkbox",
          title: " ",
          name: "custom_jsonfield_6",
          isRequired: required,
          choices: [
            {
              value: "acepto",
              text: {
                en: "I agree",
                "es-MX": "Acepto",
              },
            },
          ],
          validators: [
            {
              type: "expression",
              text: {
                en: "You should accept before continuing",
                "es-MX": "Debes aceptar antes de continuar",
              },
              expression: "{custom_jsonfield_6} contains 'acepto'",
            },
          ],
        },
      ],
    },
  ],
};

export const credit_check_form_procredito_transport = (clientUrlForCatalog) => [
  //Page 1
  {
    title: {
      en: "Credit bureau check",
      "es-MX": "Verificación Buró de crédito",
    },
    // innerIndent: 1,
    elements: [
      {
        type: "panel",
        name: "info",
        elements: [
          {
            html: {
              en: "One of the conditions to apply for a loan is that you are creditworthy. We will do a check with the credit bureau with your permission. Only when this credit check has as positive outcome, you will be asked for more information. ",
              "es-MX":
                "Una de las condiciones para aplicar a un préstamo es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito para continuar tu solicitud.",
            },
            type: "html",
            name: "penalty",
          },
          {
            html: {
              en: "We will notify you by WhatsApp when we have the results of the credit bureau check.",
              "es-MX":
                "Te notificaremos a través de WhatsApp para continuar con el proceso.",
            },
            type: "html",
            name: "notify",
          },
        ],
      },
      {
        title: { en: "First name *", "es-MX": "Primer nombre *" },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: { en: "Second name *", "es-MX": "Segundo nombre" },
        type: "text",
        name: "middle_name",
      },
      {
        title: { en: "Surname *", "es-MX": "Primer apellido *" },
        type: "text",
        name: "surname",
        isRequired: required,
      },
      {
        title: { en: "Second surname", "es-MX": "Segundo apellido" },
        type: "text",
        name: "second_surname",
      },
      {
        title: { en: "Date of birth", "es-MX": "Fecha de nacimiento" },
        type: "text",
        name: "date_of_birth",
        inputType: "date",
        validators: [
          {
            type: "expression",
            text: {
              en: "You must be older than 18 to apply",
              "es-MX": "Debes tener más de 18 años para postularte.",
            },
            expression: "age({date_of_birth}) > 18",
          },
        ],
      },
      {
        title: { en: "Gender", "es-MX": "Género" },
        type: "dropdown",
        isRequired: required,
        name: "gender",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_genders/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        type: "panel",
        name: "INE_panel",
        title: {
          en: "Which type of INE do you have? Select one *",
          "es-MX": "¿Qué tipo de INE tienes? Elige un opción *",
        },
        elements: [
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>1.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_1.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>2.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_2.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>3.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_3.png")}' width='95%'/>`,
          },
          {
            title: { en: "INE Type", "es-MX": "Tipo de INE" },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_71",
            choices: [
              { value: "1", text: { en: "1.", "es-MX": "1." } },
              { value: "2", text: { en: "2.", "es-MX": "2." } },
              { value: "3", text: { en: "3.", "es-MX": "3." } },
            ],
          },
          {
            type: "panel",
            name: "INE_1_panel",
            visibleIf: "{custom_text_71} == '1'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 dígitos)",
                  "es-MX": "CIC (9 dígitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 dígitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Identificador del ciudadano (9 dígitos)",
                  "es-MX": "Identificador del ciudadano (9 dígitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 dígitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_2_panel",
            visibleIf: "{custom_text_71} == '2'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 dígitos)",
                  "es-MX": "CIC (9 dígitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 dígitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "OCR (13 dígitos)",
                  "es-MX": "OCR (13 dígitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 dígitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_3_panel",
            visibleIf: "{custom_text_71} == '3'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "Clave de elector (18 dígitos)",
                  "es-MX": "Clave de elector (18 dígitos)",
                },
                maxLength: "18",
                placeHolder: "GAVLLR68082309A123",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "18 digits. Incorrect format",
                      "es-MX": "18 dígitos. Formato incorrecto",
                    },
                    regex: `^[a-zA-Z]{6}[0-9]{8}[a-zA-Z]{1}[0-9]{3}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número OCR (13 dígitos)",
                  "es-MX": "Número Vertical (OCR) (13 dígitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 dígitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
              {
                name: "custom_text_73",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número de Emisión (2 dígitos)",
                  "es-MX": "Número de Emisión (2 dígitos)",
                },
                maxLength: "2",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "2 digits",
                      "es-MX": "2 dígitos",
                    },
                    regex: `^[0-9]{2}$`,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: { en: "Address", "es-MX": "Domicilio" },
        type: "panel",
        name: "address_details",
        elements: [
          {
            title: { en: "Country *", "es-MX": "País *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_country",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "State *", "es-MX": "Estado *" },
            type: "dropdown",
            renderAs: "select2",
            isRequired: required,
            name: "custom_text_14",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={home_country}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Municipality *", "es-MX": "Municipio *" },
            type: "dropdown",
            renderAs: "select2",
            isRequired: required,
            name: "custom_text_28",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_14}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "City/Delegation *", "es-MX": "Ciudad/Localidad *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_city",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_28}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Postal Code *", "es-MX": "Código postal *" },
            type: "dropdown",
            renderAs: "select2",
            name: "po_box_postal_code",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_28}`,
              path: "results",
              valueName: "zipcode",
            },
          },
          {
            title: { en: "Suburb *", "es-MX": "Colonia *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_29",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={po_box_postal_code}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Street *", "es-MX": "Calle *" },
            type: "text",
            name: "custom_text_69",
            isRequired: required,
          },
          {
            title: { en: "Outdoor number", "es-MX": "Número exterior" },
            type: "text",
            name: "custom_text_19",
          },
          {
            title: { en: "Interior number", "es-MX": "Número interior" },
            type: "text",
            name: "custom_text_66",
          },
        ],
      },
      {
        name: "custom_integer_1",
        type: "text",
        inputType: "number",
        isRequired: required,
        title: {
          en: "How many trucks do you currently have?",
          "es-MX": "¿Con cuántas camionetas cuentas actualmente?",
        },
      },
      {
        title: {
          en: "Units are paid in full?",
          "es-MX": "¿Las unidades están pagadas en su totalidad? *",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_2",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "How many trucks are not paid for?",
          "es-MX": "¿Cuántas camionetas no están pagadas?",
        },
        type: "text",
        inputType: "number",
        name: "custom_integer_23",
        visibleIf: "{custom_text_2} == 'no'",
        isRequired: required,
      },
      {
        name: "custom_integer_3",
        type: "text",
        inputType: "number",
        isRequired: required,
        title: {
          en: "How many trucks do you plan to finance?",
          "es-MX": "¿Cuántas camionetas planeas financiar?*",
        },
        validators: [
          {
            type: "numeric",
            minValue: 1,
            text: {
              en: "Please make sure the amount is 1 or more",
              "es-MX": `Asegúrese de que la cantidad sea 1 o más`,
            },
          },
        ],
      },
      {
        name: "custom_text_65",
        type: "text",
        isRequired: required,
        title: {
          en: "What is the route you currently use?",
          "es-MX": "¿Cuál es la ruta que empleas actualmente? *",
        },
      },
      {
        name: "custom_integer_40",
        type: "text",
        inputType: "number",
        title: {
          en: "Postal code of the route",
          "es-MX": "Código Postal de la base de su ruta",
        },
      },
      {
        title: {
          en: "Do you have the following data? (RFC/CURP)",
          "es-MX": "¿Cuentas con los siguientes datos? (RFC/CURP)",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_4",
        choices: [
          { value: "sí", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "info2",
        visibleIf: "{custom_text_4} == 'no'",
        elements: [
          {
            html: {
              en: `<div>On the following link you can generate your RFC <br> <a href='https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp' target='_blank'>https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp</a></div>`,
              "es-MX": `<div>En el siguiente enlace podrás generar tu número de RFC <br> <a href='https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp' target='_blank'>https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp</a></div>`,
            },
            type: "html",
            name: "rfc",
          },
          {
            type: "html",
            name: "curp",
            html: {
              en: `<div>On the following link you can generate your CURP number <br> <a href='https://www.gob.mx/curp/' target='_blank'>https://www.gob.mx/curp/</a></div>`,
              "es-MX": `<div>En el siguiente enlace podrás generar tu número de CURP <br> <a href='https://www.gob.mx/curp/' target='_blank'>https://www.gob.mx/curp/</a></div>`,
            },
          },
        ],
      },
      {
        title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
        type: "text",
        name: "custom_text_5", // always visible
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 10 characters or 13 with homoclave",
              "es-MX":
                "El RFC cuenta con 10 dígitos o 13 dígitos incluyendo la homoclave",
            },
            regex: `^([A-Za-z]{4}[ |-]{0,1}[0-9]{6})([0-9A-Za-z]{3}|$)$`, // should be the correct regex that includes 3 more chars at the end
          },
        ],
        placeHolder: "VECJ880326",
      },
      {
        title: {
          en: "Unique Population Registry Code (CURP)",
          "es-MX": "CURP",
        },
        type: "text",
        name: "custom_text_6",
        maxLength: "18",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct CURP of 18 characters",
              "es-MX": "Por favor ingrese una CURP correcta con 18 caracteres",
            },
            regex: `^[a-zA-Z0-9]{18}$`, // should be the correct regex
          },
        ],
        placeHolder: "HEGG560427MVZRRL04",
      },
      {
        title: {
          en: "Do you have FIEL?",
          "es-MX": "¿Cuentas con la FIEL? (Firma Electronica Avanzada)",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_67",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Please specify the number (FIEL)",
          "es-MX": "Por favor, indica el número (FIEL)",
        },
        type: "text",
        name: "custom_text_68",
        visibleIf: "{custom_text_67} == 'si'",
        isRequired: required,
        maxLength: 20,
        validators: [
          {
            type: "regexvalidator",
            regex: `^[0-9]{20}$`,
            text: "Ingresa 20 dígitos",
          },
        ],
      },
      {
        title: {
          en: "Quantity you requested *",
          "es-MX": "Cantidad solicitada *",
        },
        name: "custom_float_30", // unit value is not the same as loan amount. Amount is either 95/90% based on frequency of payments. see workflow.
        type: "nouislider",
        step: 1,
        rangeMin: 200000,
        rangeMaxStart: 750000,
        maxStep: 50000,
        rangeMax: 700000,
        isRequired: required,
        validators: [
          {
            type: "numeric",
            minValue: 200000,
            text: {
              en: "Please enter the requested amount",
              "es-MX": "Por favor, ingrese el monto a solicitar",
            },
          },
        ],
      },
      {
        title: {
          en: "Loan term in months *",
          "es-MX": "Plazo en meses *",
        },
        name: "repayment_period",
        type: "dropdown",
        isRequired: required,
        choices: [
          { value: 12, text: 12 },
          { value: 24, text: 24 },
          { value: 36, text: 36 },
          { value: 48, text: 48 },
          { value: 60, text: 60 },
        ],
      },
      {
        title: { en: "Payment method", "es-MX": "Forma de pago *" },
        type: "dropdown",
        renderAs: "select2",
        isRequired: required,
        name: "custom_text_7",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_periods/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
    ],
  },
  //Completion Page
  completion_page,
];

export const credit_check_form_procredito_agro = (clientUrlForCatalog) => [
  //Page 1
  {
    title: {
      en: "Credit bureau check",
      "es-MX": "Verificación Buró de crédito",
    },
    // innerIndent: 1,
    elements: [
      {
        type: "panel",
        name: "info",
        elements: [
          {
            html: {
              en: "One of the conditions to apply for a loan is that you are creditworthy. We will do a check with the credit bureau with your permission. Only when this credit check has as positive outcome, you will be asked for more information. ",
              "es-MX":
                "Una de las condiciones para aplicar a un préstamo es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito para continuar tu solicitud.",
            },
            type: "html",
            name: "penalty",
          },
          {
            html: {
              en: "We will notify you by WhatsApp when we have the results of the credit bureau check.",
              "es-MX":
                "Te notificaremos a través de WhatsApp para continuar con el proceso.",
            },
            type: "html",
            name: "notify",
          },
        ],
      },
      {
        title: { en: "First name *", "es-MX": "Primer nombre *" },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: { en: "Second name *", "es-MX": "Segundo nombre" },
        type: "text",
        name: "middle_name",
      },
      {
        title: { en: "Surname *", "es-MX": "Primer apellido *" },
        type: "text",
        name: "surname",
        isRequired: required,
      },
      {
        title: { en: "Second surname", "es-MX": "Segundo apellido" },
        type: "text",
        name: "second_surname",
      },
      {
        title: { en: "Date of birth", "es-MX": "Fecha de nacimiento" },
        type: "text",
        name: "date_of_birth",
        inputType: "date",
        validators: [
          {
            type: "expression",
            text: {
              en: "You must be older than 18 to apply",
              "es-MX": "Debes tener más de 18 años para postularte.",
            },
            expression: "age({date_of_birth}) > 18",
          },
        ],
      },
      {
        title: { en: "Gender", "es-MX": "Género" },
        type: "dropdown",
        isRequired: required,
        name: "gender",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_genders/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
      {
        type: "panel",
        name: "INE_panel",
        title: {
          en: "Which type of INE do you have? Select one *",
          "es-MX": "¿Qué tipo de INE tienes? Elige un opción *",
        },
        elements: [
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>1.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_1.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>2.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_2.png")}' width='95%'/>`,
          },
          {
            name: "id_avatar_desc_picture",
            type: "html",
            html: `<h3>3.</h3><img alt="example" src='${require("../../assets/images/procredito/INE_3.png")}' width='95%'/>`,
          },
          {
            title: { en: "INE Type", "es-MX": "Tipo de INE" },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_71",
            choices: [
              { value: "1", text: { en: "1.", "es-MX": "1." } },
              { value: "2", text: { en: "2.", "es-MX": "2." } },
              { value: "3", text: { en: "3.", "es-MX": "3." } },
            ],
          },
          {
            type: "panel",
            name: "INE_1_panel",
            visibleIf: "{custom_text_71} == '1'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 dígitos)",
                  "es-MX": "CIC (9 dígitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 dígitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Identificador del ciudadano (9 dígitos)",
                  "es-MX": "Identificador del ciudadano (9 dígitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 dígitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_2_panel",
            visibleIf: "{custom_text_71} == '2'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "CIC (9 dígitos)",
                  "es-MX": "CIC (9 dígitos)",
                },
                maxLength: "9",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "9 digits",
                      "es-MX": "9 dígitos",
                    },
                    regex: `^[0-9]{9}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "OCR (13 dígitos)",
                  "es-MX": "OCR (13 dígitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 dígitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
            ],
          },
          {
            type: "panel",
            name: "INE_3_panel",
            visibleIf: "{custom_text_71} == '3'",
            elements: [
              {
                name: "id_number",
                type: "text",
                isRequired: required,
                title: {
                  en: "Clave de elector (18 dígitos)",
                  "es-MX": "Clave de elector (18 dígitos)",
                },
                maxLength: "18",
                placeHolder: "GAVLLR68082309A123",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "18 digits. Incorrect format",
                      "es-MX": "18 dígitos. Formato incorrecto",
                    },
                    regex: `^[a-zA-Z]{6}[0-9]{8}[a-zA-Z]{1}[0-9]{3}$`,
                  },
                ],
              },
              {
                name: "custom_text_72",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número OCR (13 dígitos)",
                  "es-MX": "Número Vertical (OCR) (13 dígitos)",
                },
                maxLength: "13",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "13 digits",
                      "es-MX": "13 dígitos",
                    },
                    regex: `^[0-9]{13}$`,
                  },
                ],
              },
              {
                name: "custom_text_73",
                type: "text",
                isRequired: required,
                title: {
                  en: "Número de Emisión (2 dígitos)",
                  "es-MX": "Número de Emisión (2 dígitos)",
                },
                maxLength: "2",
                validators: [
                  {
                    type: "regexvalidator",
                    text: {
                      en: "2 digits",
                      "es-MX": "2 dígitos",
                    },
                    regex: `^[0-9]{2}$`,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: { en: "Address", "es-MX": "Domicilio" },
        type: "panel",
        name: "address_details",
        elements: [
          {
            title: { en: "Country *", "es-MX": "País *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_country",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_countries/`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "State *", "es-MX": "Estado *" },
            type: "dropdown",
            renderAs: "select2",
            isRequired: required,
            name: "custom_text_14",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_regions/?countryid={home_country}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Municipality *", "es-MX": "Municipio *" },
            type: "dropdown",
            renderAs: "select2",
            isRequired: required,
            name: "custom_text_28",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities/?country_region_id={custom_text_14}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "City/Delegation *", "es-MX": "Ciudad/Localidad *" },
            type: "dropdown",
            renderAs: "select2",
            name: "home_city",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_country_zonas/?city_id={custom_text_28}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Postal Code *", "es-MX": "Código postal *" },
            type: "dropdown",
            renderAs: "select2",
            name: "po_box_postal_code",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_city_zipcodes/?id={custom_text_28}`,
              path: "results",
              valueName: "zipcode",
            },
          },
          {
            title: { en: "Suburb *", "es-MX": "Colonia *" },
            type: "dropdown",
            renderAs: "select2",
            name: "custom_text_29",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/catalogue_cities_zonas/?zipcode={po_box_postal_code}`,
              path: "results",
              titleName: "description",
              valueName: "id",
            },
          },
          {
            title: { en: "Street *", "es-MX": "Calle *" },
            type: "text",
            name: "custom_text_69",
            isRequired: required,
          },
          {
            title: { en: "Outdoor number", "es-MX": "Número exterior" },
            type: "text",
            name: "custom_text_19",
          },
          {
            title: { en: "Interior number", "es-MX": "Número interior" },
            type: "text",
            name: "custom_text_66",
          },
        ],
      },
      {
        title: {
          en: "Do you have the following data? (RFC/CURP)",
          "es-MX": "¿Cuentas con los siguientes datos? (RFC/CURP)",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_4",
        choices: [
          { value: "sí", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        name: "info2",
        visibleIf: "{custom_text_4} == 'no'",
        elements: [
          {
            html: {
              en: `<div>On the following link you can generate your RFC <br> <a href='https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp' target='_blank'>https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp</a></div>`,
              "es-MX": `<div>En el siguiente enlace podrás generar tu número de RFC <br> <a href='https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp' target='_blank'>https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp</a></div>`,
            },
            type: "html",
            name: "rfc",
          },
          {
            type: "html",
            name: "curp",
            html: {
              en: `<div>On the following link you can generate your CURP number <br> <a href='https://www.gob.mx/curp/' target='_blank'>https://www.gob.mx/curp/</a></div>`,
              "es-MX": `<div>En el siguiente enlace podrás generar tu número de CURP <br> <a href='https://www.gob.mx/curp/' target='_blank'>https://www.gob.mx/curp/</a></div>`,
            },
          },
        ],
      },
      {
        title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
        type: "text",
        name: "custom_text_5", // always visible
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 10 characters or 13 with homoclave",
              "es-MX":
                "El RFC cuenta con 10 dígitos o 13 dígitos incluyendo la homoclave",
            },
            regex: `^([A-Za-z]{4}[ |-]{0,1}[0-9]{6})([0-9A-Za-z]{3}|$)$`, // should be the correct regex that includes 3 more chars at the end
          },
        ],
        placeHolder: "VECJ880326",
      },
      {
        title: {
          en: "Unique Population Registry Code (CURP)",
          "es-MX": "CURP",
        },
        type: "text",
        name: "custom_text_6",
        maxLength: "18",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct CURP of 18 characters",
              "es-MX": "Por favor ingrese una CURP correcta con 18 caracteres",
            },
            regex: `^[a-zA-Z0-9]{18}$`, // should be the correct regex
          },
        ],
        placeHolder: "HEGG560427MVZRRL04",
      },
      {
        title: {
          en: "Do you have FIEL?",
          "es-MX": "¿Cuentas con la FIEL? (Firma Electronica Avanzada)",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_67",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: {
          en: "Please specify the number (FIEL)",
          "es-MX": "Por favor, indica el número (FIEL)",
        },
        type: "text",
        name: "custom_text_68",
        visibleIf: "{custom_text_67} == 'si'",
        isRequired: required,
        maxLength: 20,
        validators: [
          {
            type: "regexvalidator",
            regex: `^[0-9]{20}$`,
            text: "Ingresa 20 dígitos",
          },
        ],
      },
      {
        title: {
          en: "Quantity you requested *",
          "es-MX": "Cantidad solicitada *",
        },
        name: "custom_float_30", // unit value is not the same as loan amount. Amount is either 95/90% based on frequency of payments. see workflow.
        type: "nouislider",
        step: 1,
        rangeMin: 200000,
        rangeMaxStart: 750000,
        maxStep: 50000,
        rangeMax: 700000,
        isRequired: required,
        validators: [
          {
            type: "numeric",
            minValue: 200000,
            text: {
              en: "Please enter the requested amount",
              "es-MX": "Por favor, ingrese el monto a solicitar",
            },
          },
        ],
      },
      {
        title: {
          en: "Loan term in months *",
          "es-MX": "Plazo en meses *",
        },
        name: "repayment_period",
        type: "nouislider",
        step: 1,
        rangeMin: 1,
        rangeMax: 60,
      },
      {
        title: { en: "Payment method", "es-MX": "Forma de pago *" },
        type: "dropdown",
        renderAs: "select2",
        isRequired: required,
        name: "custom_text_7",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/tenant-data/catalogue_periods/`,
          path: "results",
          titleName: "description",
          valueName: "id",
          attachOriginalItems: true,
        },
      },
    ],
  },
  //Completion Page
  completion_page,
];
