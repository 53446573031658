import env from "../../env";
const required = env.ENDPOINT !== "local";

export const review_ffdl = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-GT": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-GT": "Revisa y firme tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          title: {
            en: "Requested loan amount",
            "es-GT": "Monto del préstamo solicitado",
          },
          expression: "{amount}",
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          type: "expression",
          title: {
            en: "GA%:",
            "es-GT": "Gastos administrativos (%) GA",
          },
          expression: "{product_interest_rate}",
        },
        {
          type: "expression",
          expression: "{repayment_period}",
          title: {
            en: "Loan Term (months)",
            "es-GT": "Plazo del préstamo (meses)",
          },
        },
        {
          type: "expression",
          expression: "monthsAndDaysFromNow(1,2)",
          title: {
            en: "Approved first installment date",
            "es-GT": "Fecha de primera cuota",
          },
        },
        {
          type: "expression",
          name: "monthly_installment",
          title: { en: "Monthly fees", "es-GT": "Cuota mensual" },
          expression:
            "fixed(calcPmt(divide(divide({product_interest_rate},100),12),{repayment_period},{amount}),2)",
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          type: "expression",
          expression: "daysFromNow(2)",
          title: {
            en: "Expected Disbursement date if loan is approved:",
            "es-GT": "Fecha prevista de desembolso si se aprueba el préstamo",
          },
        },
        {
          type: "panel",
          elements: [
            {
              type: "html",
              html: {
                en:
                  "<div> To ensure that you are serious about a timely monthly payment, you will be charged 2% for every day you are late with payment. </br>" +
                  " If granted, the duration of the loan will be {repayment_period} months, and the installments will be {monthly_installment}. </br> " +
                  "No variation or amendment of this Agreement shall be valid unless committed to writing and signed by or on behalf of both parties</div>",
                "es-GT":
                  "<div> Recuerda efectuar siempre tu pago puntual, de lo contrario tendrás un recargo del 2%  mensual de mora por cada atraso.</br> " +
                  "Si se concede, la duración del préstamo será de {repayment_period} meses, y las cuotas serán de {monthly_installment}.</br>" +
                  "Ninguna variación o enmienda de este Acuerdo será válida a menos que se comprometa por escrito y esté firmada por o en nombre de ambas partes.",
              },
            },
          ],
        },
        {
          type: "checkbox",
          name: "confirm_4",
          isRequired: required,
          storeOthersAsComment: false,
          choices: [
            { value: "I agree", text: { en: "I agree", "es-GT": "Acepto" } },
          ],
          title: {
            en: "I understand the risks involved and will guarantee the repayment of the loan.",
            "es-GT":
              "Entiendo los riesgos involucrados y garantizaré el reembolso del préstamo.",
          },
        },
      ],
    },
  ],
};
