export const auth_procredito_pages = [
    {
        title: { en: "Authentication", "es-MX": "Autenticación" },
        innerIndent: 1,
        name: "authentication",
        elements: [
            {

                title: {
                    en: `Today being {date}, do you authorize PRO CONFIANZA S.A DE C.V. SOFOM E.N.R. to consult your credit history for a single occasion
                         before the Credit Information Companies that you deem appropriate, declaring that you know the nature, 
                         scope and use that PRO CONFIANZA S.A DE C.V. SOFOM E.N.R. will make of the information?`,
                    "es-MX": `Hoy siendo {date}, ¿autoriza a PRO CONFIANZA S.A DE C.V. SOFOM E.N.R. a consultar sus antecedentes crediticios
                    por única ocasión ante las Sociedades de Información Crediticia que estime conveniente, declarando que conoce
                    la naturaleza, alcance y uso que PRO CONFIANZA S.A DE C.V. SOFOM E.N.R. hará de la información?`
                },
                type: "radiogroup",
                choices: [
                    { value: "si", text: { en: "Yes", "es-MX": "Sí", }, },
                    { value: "no", text: { en: "No", "es-MX": "No", }, },
                ],
                name: "accept_authorization"
            },
            {
                html: {
                    en: `I hereby expressly authorize PRO CONFIANZA S.A DE C.V. SOFOM E.N.R., through its authorized officials, 
                        to carry out investigations on my Credit behavior in the Credit Information 
                        Companies that it deems appropriate.<br> Likewise, I declare that I am aware of the nature 
                        and scope of the information that will be requested, the use that PRO CONFIANZA S.A DE C.V. SOFOM E.N.R. will make of 
                        such information and that it may make periodic inquiries of my credit history, consenting that 
                        this authorization is valid for a period of 3 years from the date of its issue and in 
                        In any case, during the time that we maintain a legal relationship. <br>
                        <strong>To authorize, enter your password again</strong>`,
                    "es-MX": `Por este conducto autorizo expresamente a PRO CONFIANZA S.A DE C.V. SOFOM E.N.R., para que por conducto
                        de sus funcionarios facultados lleve a cabo investigaciones, sobre mi comportamiento
                        Crediticio en las Sociedades de Información Crediticia que estime conveniente.<br>
                        Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará,
                        del uso que PRO CONFIANZA S.A DE C.V. SOFOM E.N.R. hará de tal información y de que esta podrá realizar
                        consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre
                        vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso
                        durante el tiempo que mantengamos relación jurídica.
                        <br><strong>Para autorizar, ingresar nuevamente su contraseña</strong>`
                },
                type: "html",
                visibleIf: "{accept_authorization} == 'si'"
            },
            {
                title: {
                    en: "To aunthenticate enter your code here",
                    "es-MX": "Para autorizar, ingresa tu código aquí"
                },
                type: "text",
                name: "custom_text_150",
                visibleIf: "{accept_authorization} == 'si'"
            },
        ],
    }
]