import { api } from "../../app";

// GET_LOAN_PENDING   Called before requesting loan
// GET_LOAN_DONE      Requesting / submitting loan data unsuccesful
// RESET_LOAN_STATE   Requesting loan data unsuccesful
// LOAN_SUCCESS       Requesting loan data succesful, dispatching with data-attribute

export function getLoan(uuid) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "GET_LOAN_PENDING",
      });
      let data = await api.get(`loan/${uuid}/view.json`);
      dispatch({
        type: "LOAN_SUCCESS",
        data: data,
      });
      return data;
    } catch (error) {
      console.log("NO DATA FOUND.");
      dispatch({
        type: "RESET_LOAN_STATE",
      });
    }
  };
}

export function checkedIntake(uuid, purpose) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING" });

      const data = await api.post(`loan/checked_intake.json`, { uuid });

      dispatch({ data, type: "LOAN_SUCCESS" });

      await setPage(data.state)(dispatch);
    } catch (error) {
      console.error("Setting flow not successful, try again later", error);
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function saveIntake(uuid, intake, submit = false) {
  return async (dispatch) => {
    try {
      intake["is_completed"] = false;
      if (submit) {
        intake["is_completed"] = true;
        dispatch({
          type: "GET_LOAN_PENDING",
        });
      }

      let data = await api.post(`loan/update_intake.json`, { uuid, intake });

      if (submit) {
        dispatch({
          type: "LOAN_SUCCESS",
          data: data,
        });
      }
    } catch (error) {
      console.error("Save not successfull, try again later");
      dispatch({
        type: "GET_LOAN_DONE",
      });
    }
  };
}

export function completeCreditCheck(uuid, state, loan_purpose) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING" });

      let data = await api.post(`loan/complete_credit_check.json`, {
        uuid,
        state,
        loan_purpose,
      });

      dispatch({ data, type: "LOAN_SUCCESS" });
    } catch (error) {
      console.error(
        "Credit check not completed successful, try again later",
        error
      );
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function completeInterview(uuid, state, loan_purpose) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING" });

      let data = await api.post(`loan/complete_interview.json`, {
        uuid,
        state,
        loan_purpose,
      });

      dispatch({ data, type: "LOAN_SUCCESS" });
    } catch (error) {
      console.error(
        "Interview not completed successful, try again later",
        error
      );
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function completeAssessmentStudy(uuid, state, loan_purpose) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING" });

      let data = await api.post(`loan/complete_assessment_study.json`, {
        uuid,
        state,
        loan_purpose,
      });

      dispatch({ data, type: "LOAN_SUCCESS" });
    } catch (error) {
      console.error(
        "Interview not completed successful, try again later",
        error
      );
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function setFlow(uuid, state, loan_purpose) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING" });

      let data = await api.post(`loan/set_flow.json`, {
        uuid,
        state,
        loan_purpose,
      });

      dispatch({ data, type: "LOAN_SUCCESS" });
    } catch (error) {
      console.error("Setting flow not successful, try again later", error);
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function saveAssessment(uuid, assessment, submit = false) {
  return async (dispatch) => {
    try {
      if (submit) {
        assessment["is_completed"] = true;
        dispatch({
          type: "GET_LOAN_PENDING",
        });
      }
      let data = await api.post(`loan/update_assessment.json`, {
        uuid,
        assessment,
      });
      if (submit) {
        dispatch({
          type: "LOAN_SUCCESS",
          data: data,
        });
      }
    } catch (error) {
      console.error("Save not successfull, try again later");
      dispatch({
        type: "GET_LOAN_DONE",
      });
    }
  };
}

export function saveReview(uuid, loan_terms) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "GET_LOAN_PENDING",
      });

      // correct signature, from [{name: xxx_url}] to simple string
      if (
        loan_terms.signature &&
        loan_terms.signature[0] &&
        loan_terms.signature[0].name
      )
        loan_terms.signature = loan_terms.signature[0].name;

      let data = await api.post(`loan/complete_review.json`, {
        ...loan_terms,
        uuid: uuid,
      });
      dispatch({
        type: "LOAN_SUCCESS",
        data: data,
      });
    } catch (error) {
      console.error("Save not successfull, try again later");
      dispatch({
        type: "GET_LOAN_DONE",
      });
    }
  };
}

export function updateGuarantor(uuid, intake_guarantor, complete) {
  return async (dispatch) => {
    try {
      if (complete) {
        // guarantor completes directly
        intake_guarantor["date_completed"] = new Date();
        intake_guarantor["is_completed"] = true;
        intake_guarantor["date_signed"] = new Date();
        dispatch({
          type: "GET_LOAN_PENDING",
        });
      }
      if (
        intake_guarantor.signature &&
        intake_guarantor.signature[0] &&
        intake_guarantor.signature[0].name
      )
        intake_guarantor.signature = intake_guarantor.signature[0].name;
      let data = await api.post(`loan/update_guarantor.json`, {
        uuid,
        intake_guarantor,
      });

      if (complete) {
        dispatch({
          type: "LOAN_SUCCESS",
          data: data,
        });
      }
    } catch (error) {
      console.error("Save not successfull, try again later");
      dispatch({
        type: "GET_LOAN_DONE",
      });
    }
  };
}

export function signGuarantor(uuid, intake_guarantor, complete) {
  return async (dispatch) => {
    try {
      if (complete) {
        // guarantor completes directly
        intake_guarantor["date_signed"] = new Date();
        dispatch({
          type: "GET_LOAN_PENDING",
        });
      }

      // correct signature, from [{name: xxx_url}] to simple string
      if (
        intake_guarantor.final_signature &&
        intake_guarantor.final_signature[0] &&
        intake_guarantor.final_signature[0].name
      )
        intake_guarantor.final_signature =
          intake_guarantor.final_signature[0].name;

      let data = await api.post(`loan/sign_guarantor.json`, {
        uuid,
        intake_guarantor,
      });

      if (complete) {
        dispatch({
          type: "LOAN_SUCCESS",
          data: data,
        });
      }
    } catch (error) {
      console.error("Save not successfull, try again later");
      dispatch({
        type: "GET_LOAN_DONE",
      });
    }
  };
}

export function signApplication(
  uuid,
  signature,
  {
    // data
    i_agree_on_video,
    sign_name,
    sign_date,
  },
  {
    // loan_terms
    start_repayment_date,
    stamp,
    sales_tax,
  }
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "GET_LOAN_PENDING",
      });

      // save data
      let _load = {
        uuid: uuid,
        date_signed: new Date(),
        signature: signature,
        i_agree_on_video,
        start_repayment_date,
        sign_name,
        sign_date,
        stamp,
        sales_tax,
      };

      // correct signature, from [{name: xxx_url}] to simple string
      if (_load.signature && _load.signature[0] && _load.signature[0].name)
        _load.signature = _load.signature[0].name;

      let data = await api.post(`loan/sign.json`, _load);
      dispatch({
        type: "LOAN_SUCCESS",
        data: data,
      });
    } catch (error) {
      console.error("Save not successfull, try again later");
      dispatch({
        type: "GET_LOAN_DONE",
      });
    }
  };
}

export function toStart(uuid, state, loan_purpose, intake) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING " });

      let data = await api.post(`loan/to_start.json`, {
        uuid,
        state,
        loan_purpose,
      });

      dispatch({ type: "LOAN_SUCCES", data: data });
    } catch (error) {
      console.error("Setting flow not successful, try again later", error);
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function switchLoanState(uuid, next_state) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING " });

      let data = await api.post(`loan/switch_loan_state.json`, {
        uuid,
        next_state,
      });

      dispatch({ data, type: "LOAN_SUCCESS" });

      await setPage(data.state)(dispatch);
    } catch (error) {
      console.error("Setting flow not successful, try again later", error);
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

/**
 * Genereic action to transition loan to next state.
 * Next state is determined by the backend
 *
 * @param uuid - uuid of loan
 * @param action - action to perform on loan, must correspond to transition function
 * @param user_type - the type of user performing the action
 */
export function transitionLoanState(uuid, action, user_type = "guarantor") {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING " });

      let data = await api.post(`loan/transition_state.json`, {
        uuid,
        action,
        user_type,
      });
      dispatch({ data, type: "LOAN_SUCCESS" });
      await setPage(data.state)(dispatch);
    } catch (error) {
      console.error("Transition not successful, failed with error", error);
      dispatch({ type: "GET_LOAN_DONE" });
    }
  };
}

export function setPage(page) {
  return async (dispatch) => {
    dispatch({
      type: "SET_PAGE",
      page: page,
    });
  };
}

export function twoFactorAuth(uuid, token, action) {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOAN_PENDING" });

      let data = await api.post(`loan/two_factor_auth.json`, {
        uuid,
        token,
        action,
      });
      // TODO check if we need to authentication_waiting page

      dispatch({ data, type: "LOAN_SUCCESS" });
      await setPage(data.state)(dispatch);
    } catch (error) {
      await setPage("authentication_failed")(dispatch);
    }
  };
}
