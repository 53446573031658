import env from "../../env";
const required = env.ENDPOINT !== "local";

export const review_procredito_transport = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-MX": "Revisa y firma tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          name: "edited_unit_value",
          title: {
            en: "Unit Value",
            "es-MX": "Valor de la Unidad",
          },
          expression: "formatCurrency(fixed({unit_value}, 2, true))+' MXN'",
        },
        {
          type: "expression",
          name: "edited_total_final_loan_amount",
          title: {
            en: "Requested loan amount",
            "es-MX": "Monto del préstamo",
          },
          expression:
            "formatCurrency(fixed({total_final_loan_amount}, 2, true))+' MXN'",
        },
        {
          type: "expression",
          name: "edited_disbursement_commission",
          title: {
            en: "Disbursement commission",
            "es-MX": "Comisión por desembolso",
          },
          expression: "formatCurrency(fixed({disbursement_commission}, 2, true))+' MXN'",
        },
        {
          type: "expression",
          name: "edited_iva_commission",
          title: {
            en: "Commision VAT",
            "es-MX": "IVA de la comisión",
          },
          expression: "formatCurrency(fixed({iva_commission}, 2, true))+' MXN'",
        },
        {
          type: "expression",
          name: "edited_commission_advance",
          expression: "formatCurrency(fixed({commission_advance}, 2, true))+' MXN'",
          title: {
            en: "Total commission paid in advance",
            "es-MX": "Total comisión pagado por adelantado",
          },
        },
        {
          type: "expression",
          name: "edited_commission_disbursement",
          title: {
            en: "Total to be paid at the signing of the Contract",
            "es-MX": "Total a pagar a la firma del Contrato",
          },
          expression: "formatCurrency(fixed({commission_disbursement}, 2, true))+' MXN'",
          displayStyle: "currency",
          currency: "MXN",
        },
        {
          type: "expression",
          name: "loan_term",
          title: {
            en: "Loan Term (months)",
            "es-MX": "Plazo del préstamo (meses)",
          },
          expression: "{repayment_period}",
        },
        {
          type: "expression",
          expression: `getValueFromUrl("api/tenant-data/catalogue_periods/?id=", {custom_text_7})`,
          title: {
            en: "Payment frequency",
            "es-MX": "Frecuencia de pago",
          },
        },
        {
          type: "expression",
          expression: `weekdaysFromNow(5, 0, 'es-MX')`,
          title: {
            en: "Expected Disbursement date if loan is approved:",
            "es-MX": "Fecha prevista de desembolso si se aprueba el préstamo",
          },
        },
        {
          type: "panel",

          elements: [
            {
              type: "html",
              html: {
                en: "To ensure that you will make your payments on time, a 5% surcharge will be charged for each delay.",
                "es-MX":
                  "Para asegurar que  realizarás tus pagos puntuales, se cobrará el 5% de recargo por cada atraso.",
              },
            },
            {
              type: "expression",
              name: "base_interest_rate",
              visible: false,
              expression:
                "templateConditional(divide(0.26,12) , divide(0.29,12),{custom_text_7}, 'mensual')", //hard coded
            },
            {
              type: "expression",
              expression: `formatCurrency(fixed(calcPmt({base_interest_rate},{repayment_period},{custom_float_30}), 2, true))+' MXN'`,
              displayStyle: "currency",
              currency: "MXN",
              title: {
                en: "Amount of the fee without VAT and without insurance of the unit",
                "es-MX": "Monto de la cuota sin IVA y sin seguro de la unidad.",
              },
            },
            {
              type: "html",
              html: {
                en: `The cost of the damage insurance can be bought in cash or financed within the same credit "According to the price of the day of signing"`,
                "es-MX": `El costo del seguro de daños podrá ser comprado de contado o financiado dentro del mismo crédito "De acuerdo a la cotización del día de la firma"`,
              },
            },
            {
              type: "html",
              html: {
                en: `Your credit contract protects you for free through death or permanent disability insurance`,
                "es-MX": `Tu contrato de crédito te protege de manera gratuita mediante un seguro por muerte o incapacidad permanente`,
              },
            },
            {
              type: "html",
              html: {
                en: `Average CAT of 32% without VAT. For informational and comparison purposes, calculated on an amount of 500,000 at 60 months at a rate of 26% anual`,
                "es-MX": ` CAT promedio del 32% sin IVA. Para fines informativos y de comparación, calculado sobre un monto de $500,000 MXN a 60 meses a una tasa del 26% anual`,
              },
            },
            {
              type: "html",
              html: {
                en: `No variation or amendment of this Agreement shall be valid unless committed to writing and signed by or on behalf of both parties`,
                "es-MX": `Ninguna variación o enmienda de este Acuerdo será válida a menos que se comprometa por escrito y esté firmada por o en nombre de ambas partes.`,
              },
            },
          ],
        },
        {
          title: {
            en: `My name is {first_name} and my ID number is {id_number}. I confirm that the information given in this form is true, complete and accurate.`,
            "es-MX": `Mi nombre es {first_name} y mi número de identificación es {id_number}. Confirmo que la información proporcionada en este formulario es verdadera, completa y precisa.`,
          },
          type: "checkbox",
          name: "confirm_4",
          isRequired: required,
          choices: [
            {
              value: "Estoy de acuerdo",
              text: {
                en: "I agree",
                "es-MX": "Estoy de acuerdo",
              },
            },
          ],
        },
        {
          type: "expression",
          title: {
            en: "Sign date",
            "es-MX": "Fecha de firma",
          },
          name: "sign_date",
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          title: { en: "Sign loan application", "es-MX": "Tu firma" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
        //Invisible fields used for calculations
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "unit_value",
          title: {
            en: "Unit Value",
            "es-MX": "Valor de la Unidad",
          },
          expression: "{custom_float_30}",
          displayStyle: "currency",
          currency: "MXN",
        },
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "total_final_loan_amount",
          title: {
            en: "Requested loan amount", // 0.90 of unit value if mensual else 0.95
            "es-MX": "Monto del préstamo",
          },
          expression: "fixed({amount})",
          displayStyle: "currency",
          currency: "MXN",
        },
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "disbursement_commission", // These all need the proper values (all set to amount atm)
          title: {
            en: "Disbursement commission",
            "es-MX": "Comisión por desembolso",
          },
          expression: "fixed(multiply({total_final_loan_amount}, 0.04))",
          displayStyle: "currency",
          currency: "MXN",
        },
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "iva_commission", // These all need the proper values (all set to amount atm)
          title: {
            en: "Commision VAT",
            "es-MX": "IVA de la comisión",
          },
          expression: "fixed(multiply({disbursement_commission}, 0.16))",
          displayStyle: "currency",
          currency: "MXN",
        },
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "commission_advance", // These all need the proper values
          expression: "sum({iva_commission}, {disbursement_commission})",
          title: {
            en: "Total commission paid in advance",
            "es-MX": "Total comisión pagado por adelantado",
          },
          displayStyle: "currency",
          currency: "MXN",
        },
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "commission_disbursement",
          title: {
            en: "Total to be paid at the signing of the Contract",
            "es-MX": "Total a pagar a la firma del Contrato",
          },
          expression:
            "fixed(sum({commission_advance},(subtract({custom_float_30},{total_final_loan_amount}))))",
          displayStyle: "currency",
          currency: "MXN",
        },
      ],
    },
  ],
};

export const review_procredito_agro = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-MX": "Revisa y firma tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          type: "expression",
          name: "edited_total_final_loan_amount",
          title: {
            en: "Loan amount",
            "es-MX": "Monto del préstamo",
          },
          expression: "formatCurrency(fixed({total_final_loan_amount}, 2, true))+' MXN'",
        },
        {
          visibleIf: "1 == 0",
          type: "expression",
          name: "total_final_loan_amount",
          title: {
            en: "Loan amount",
            "es-MX": "Monto del préstamo",
          },
          expression: "{loan.requested_amount}"
        },
        {
          type: "expression",
          name: "loan_term",
          title: {
            en: "Loan Term (months)",
            "es-MX": "Plazo del préstamo (meses)",
          },
          expression: "{repayment_period}",
        },
        {
          type: "expression",
          expression: `getValueFromUrl("api/tenant-data/catalogue_periods/?id=", {custom_text_7})`,
          title: {
            en: "Payment frequency",
            "es-MX": "Frecuencia de pago",
          },
        },
        {
          type: "expression",
          expression: `weekdaysFromNow(5, 0, 'es-MX')`,
          title: {
            en: "Expected Disbursement date if loan is approved:",
            "es-MX": "Fecha prevista de desembolso si se aprueba el préstamo",
          },
        },
        {
          title: {
            en: `My name is {first_name} and my ID number is {id_number}. I confirm that the information given in this form is true, complete and accurate.`,
            "es-MX": `Mi nombre es {first_name} y mi número de identificación es {id_number}. Confirmo que la información proporcionada en este formulario es verdadera, completa y precisa.`,
          },
          type: "checkbox",
          name: "confirm_4",
          isRequired: required,
          choices: [
            {
              value: "Estoy de acuerdo",
              text: {
                en: "I agree",
                "es-MX": "Estoy de acuerdo",
              },
            },
          ],
        },
        {
          type: "expression",
          title: {
            en: "Sign date",
            "es-MX": "Fecha de firma",
          },
          name: "sign_date",
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          title: { en: "Sign loan application", "es-MX": "Tu firma" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
      ],
    },
  ],
};
