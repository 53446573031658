import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";
// if you start using the (clientUrlForCatalog) remove the _ in the name

export const credit_credicapital = (_clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Loan info",
      "es-MX": "Datos del crédito solicitado",
    },
    elements: [
      {
        title: { en: "Loan amount", "es-MX": "Monto del crédito" },
        name: "amount",
        type: "nouislider",
        step: 10,
        rangeMin: 5000,
        maxStep: 500,
        rangeMax: 200000,
      },
      {
        title: { en: "Payment modality", "es-MX": "Modalidad de pagos" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_28",
        choices: [
          { value: "semanal", text: { en: "week", "es-MX": "Semanal" } },
          {
            value: "catorcenal",
            text: { en: "bi-weekly", "es-MX": "Catorcenal" },
          },
          {
            value: "quincenal",
            text: { en: "bi-weekly", "es-MX": "Quincenal" },
          },
          { value: "mensual", text: { en: "monthly", "es-MX": "Mensual" } },
        ],
      },
      // WEEKLY
      {
        title: { en: "Loan term", "es-MX": "Plazo" },
        name: "loan_term",
        visibleIf: "{custom_text_28} == 'semanal'",
        type: "text",
        inputType: "number",
        isRequired: required,
        validators: [
          {
            type: "numeric",
            minValue: 12,
            text: "Responda con mínimo 12",
          },
          {
            type: "numeric",
            maxValue: 72,
            text: "Responda con máximo 72",
          },
        ],
      },
      // BIWEEKLY
      {
        title: { en: "Loan term", "es-MX": "Plazo" },
        name: "loan_term",
        visibleIf: "{custom_text_28} == 'catorcenal' || {custom_text_28} == 'quincenal'",
        type: "text",
        inputType: "number",
        isRequired: required,
        validators: [
          {
            type: "numeric",
            minValue: 6,
            text: "Responda con mínimo 6",
          },
          {
            type: "numeric",
            maxValue: 36,
            text: "Responda con máximo 36",
          },
        ],
      },
      // MONTHLY
      {
        title: { en: "Loan term", "es-MX": "Plazo" },
        name: "loan_term",
        visibleIf: "{custom_text_28} == 'mensual'",
        type: "text",
        inputType: "number",
        isRequired: required,
        validators: [
          {
            type: "numeric",
            minValue: 3,
            text: "Responda con mínimo 3",
          },
          {
            type: "numeric",
            maxValue: 18,
            text: "Responda con máximo 18",
          },
        ],
      },
      {
        title: { en: "Financed insurance", "es-MX": "Seguro financiado" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_22",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: { en: "Select input", "es-MX": "Selecciona" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_24",
        choices: [
          { value: "garantia", text: { en: "Guarantee", "es-MX": "Garantía" } },
          {
            value: "comision",
            text: { en: "Commission", "es-MX": "Comisión" },
          },
        ],
      },
      {
        title: { en: "Guarantee input", "es-MX": "Garantía" },
        type: "dropdown",
        visibleIf: "{custom_text_24} == 'garantia'",
        name: "custom_text_141",
        isRequired: "{custom_text_24} == 'garantia'",
        choices: [
          {
            value: "financiada",
            text: { en: "Financed", "es-MX": "Financiada" },
          },
          {
            value: "no_financiada",
            text: { en: "Not financed", "es-MX": "No financiada" },
          },
        ],
      },
      {
        title: {
          en: "Type of credit operation",
          "es-MX": "Tipo de operación de crédito",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_26",
        choices: [
          {
            value: "capital_de_trabajo",
            text: { en: "working capital", "es-MX": "Capital de trabajo" },
          },
          {
            value: "inversión",
            text: { en: "investment", "es-MX": "Inversión" },
          },
          {
            value: "libre_disposición",
            text: { en: "free disposal", "es-MX": "Libre disposición" },
          },
        ],
      },
      {
        title: {
          en: "specifically detail what you will buy with the credit",
          "es-MX": "Detallar específicamente que comprará con el crédito",
        },
        type: "text",
        name: "custom_text_27",
        isRequired: required,
        visibleIf: "{custom_text_26} !== 'libre_disposición' ",
      },
      {
        title: {
          en: "Number of payments",
          "es-MX": "Número de pagos"
        },
        name: "custom_integer_1",
        type: "text",
        inputType: "numeric",
        isRequired: required,
        validators: [
          {
            type: "numeric",
            minValue: 1,
            text: "Responda con mínimo 1",
          },
          {
            type: "numeric",
            maxValue: 99,
            text: "Responda con máximo 99",
          },
        ],
      }
    ],
  });
