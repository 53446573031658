import React from "react";
import { connect } from "react-redux";
import { Button } from "../../components/button/button";
import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import Config from "../../config";
import { translate } from "../../lib/intl";
import { loanActions } from "../../store/store";
import { colors } from "../../theme/theme";
import { crediavance_buttons } from "./crediavance_action_buttons";
import { credicapital_buttons } from "./credicapital_action_buttons";

export class IntakeInProgress extends React.PureComponent {
  constructor(props) {
    super(props);

    // show action buttons based on  loan state
    const availableClients = {
      credicapital: credicapital_buttons,
      crediavance: crediavance_buttons,
    };

    const currentDeploymentButtons = availableClients[Config.deployment]
      ? availableClients[Config.deployment]
      : undefined;

    this.actionButtonList = currentDeploymentButtons
      ? currentDeploymentButtons(this.props.loan)
      : [];
  }

  render({ loan, device } = this.props) {
    return (
      <div className="applicationContainer">
        <HelpComponent
          onOpen={() => {
            this.setState({ help_count: this.state.help_count + 1 });
          }}
          type="home"
          locale={device.locale}
        />

        <div className="header">
          {this.actionButtonList.length !== 0 && (
            <h2 className="primary center">
              {translate(`intake_in_progress.header`)}
            </h2>
          )}
          {this.actionButtonList.length === 0 && (
            <h2 className="primary center">
              {translate(`intake_in_progress.header_complete_intake`)}
            </h2>
          )}
        </div>

        <div className="continueButton">
          {this.actionButtonList.length !== 0 &&
            this.actionButtonList.map((btn) => (
              <div>
                <Button
                  style={{ width: "240px" }}
                  className="center bigSpacer"
                  backgroundColor={colors.brand.primary}
                  color="#fff"
                  title={btn.localeTitle}
                  onPress={() =>
                    this.props.switchLoanState(loan.uuid, btn.nextState)
                  }
                />
                <p className="center bigSpacer"></p>
              </div>
            ))}
        </div>
        {(this.actionButtonList.length === 0 || (Config.intake_in_progress && !Config.intake_in_progress.hide_default_button)) && (
          <div className="continueButton">
            <div>
              <Button
                style={{ width: "240px" }}
                className="center"
                backgroundColor={colors.brand.accent}
                color={colors.brand.darkgray}
                title={translate("intake_in_progress.default_button_title")}
                onPress={() =>
                  this.props.switchLoanState(loan.uuid, "checking_intake")
                }
              />
              <p className="center bigSpacer"></p>
            </div>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    switchLoanState: (uuid, next_state) => {
      dispatch(loanActions.switchLoanState(uuid, next_state));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loan: state.loan,
    device: state.device,
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntakeInProgress);

export { connectedContainer as IntakeInProgressContainer };
