export const choose_flow_banco_popular_pages = [
  {
    name: "purpose_page",
    title: {
      en: "Client type",
      "es-HN": "Tipo de cliente",
    },
    elements: [
      {
        type: "dropdown",
        name: "purpose_question",
        title: {
          "es-HN": "¿Eres cliente nuevo?",
          en: "Are you a new client? ",
        },
        isRequired: true,
        choices: [
          {
            value: "new_client",
            text: {
              en: "Yes",
              "es-HN": "Sí",
            },
          },
          {
            value: "existing_client",
            text: {
              en: "No",
              "es-HN": "No",
            },
          },
        ],
      },
    ],
  },
];
