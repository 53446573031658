import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";
// if you start using the (clientUrlForCatalog) remove the _ in the name
export const identification_credicapital_new_client = (_clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Identification information",
      "es-MX": "Información sobre identificación",
    },
    elements: [
      {
        title: { en: "ID Type", "es-MX": "Tipo de identificación *" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_16",
        choices: [
          { value: "ine", text: { en: "INE", "es-MX": "INE" } },
          { value: "otro", text: { en: "Other", "es-MX": "Otro" } },
        ],
      },
      {
        title: {
          en: "In case of other, indicate ",
          "es-MX": "En caso de otro, indicar *",
        },
        type: "text",
        name: "custom_text_17",
        isRequired: "{custom_text_16} == 'otro'",
        visibleIf: "{custom_text_16} == 'otro'",
      },
      {
        title: { en: "ID Number (INE)", "es-MX": "Folio (INE)*" },
        type: "text",
        name: "custom_text_18",
        isRequired: "{custom_text_16} == 'ine'",
        visibleIf: "{custom_text_16} == 'ine'",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please, enter 13 characters",
              "es-MX": "Por favor, ingrese 13 caracteres",
            },
            regex: `^[0-9a-zA-Z]{13}$`,
          },
        ],
      },
      {
        title: { en: "Expedition date", "es-MX": "Fecha de expedición*" },
        type: "text",
        name: "custom_text_19",
        isRequired: required,
        inputType: "date",
        validators: [
          {
            type: "expression",
            text: {
              en: "The date must be less than 10 years ago",
              "es-MX": "La fecha debe ser menor a 10 años",
            },
            expression:
              "age({custom_text_19}) < 10",
          },
        ],
      },
      {
        title: {
          en: "Image from the front (INE)",
          "es-MX": "Imagen parte de adelante (INE)*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_1",
        visibleIf: "{custom_text_16} == 'ine'",
      },
      {
        title: {
          en: "Image from the back (INE)",
          "es-MX": "Imagen parte de atrás (INE)*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_2",
        visibleIf: "{custom_text_16} == 'ine'",
      },
      {
        title: {
          en: "To verify your identity please upload a photo holding said identification card (INE)",
          "es-MX":
            "Para verificar tu identidad por favor, sube una foto sujetando dicha tarjeta de identificación (INE)*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_3",
      },
      {
        title: { en: "Email", "es-MX": "Correo electrónico" },
        type: "text",
        inputType: "email",
        name: "email",
      },
      {
        title: {
          en: "Unique Population Registry Code (CURP)",
          "es-MX": "CURP",
        },
        type: "text",
        name: "custom_text_21",
        isRequired: required,
        validators: [expressions.curp()],
        placeHolder: "HEGG560427MVZRRL04",
      },
      {
        title: { en: "Scholarship *", "es-MX": "Escolaridad *" },
        type: "dropdown",
        name: "custom_text_42",
        isRequired: required,
        choices: [
          {
            value: "primaria",
            text: { en: "Primary", "es-MX": "Primaria" },
          },
          {
            value: "secundaria",
            text: { en: "Secundary", "es-MX": "Secundaria" },
          },
          {
            value: "preparatoria",
            text: { en: "High-School", "es-MX": "Preparatoria" },
          },
          {
            value: "profesional",
            text: { en: "College", "es-MX": "Profesional" },
          },
          {
            value: "no_tiene",
            text: { en: "Doesn't have", "es-MX": "No tiene" },
          },
        ],
      },
      {
        title: {
          en: "Economic dependents *",
          "es-MX": "Dependientes económicos *",
        },
        type: "text",
        name: "custom_text_20",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please, enter only numbers",
              "es-MX": "Por favor, ingrese solo números"
            },
            regex: `^[0-9]{1,2}$`,
          },
        ],
      },
      {
        type: "text",
        name: "custom_text_4",
        isRequired: required,
        title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 4 letters and 6 digits",
              "es-MX":
                "Por favor ingrese un RFC correcto de 4 letras y 6 dígitos",
            },
            regex: `^[A-Za-z]{4}[0-9]{6}$`,
          },
        ],
        placeHolder: "VECJ880326",
      },
      {
        title: { en: "Homoclave", "es-MX": "Homoclave" },
        type: "text",
        name: "custom_text_5",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct homoclave of 3 characters",
              "es-MX":
                "Por favor ingrese una homoclave correcta de 3 caracteres",
            },
            regex: `^[0-9A-Za-z]{3}$`,
          },
        ],
        placeHolder: "XXX",
      },
      {
        title: {
          en: "Serial number of the Advanced Electronic Signature",
          "es-MX": "Número de serie de la Firma Electrónica Avanzada",
        },
        type: "text",
        name: "custom_text_54",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Número de serie 20 characters.",
              "es-MX": "Número de serie 20 dígitos.",
            },
            regex: `^[0-9A-Za-z]{20}$`,
          },
        ],
      },
    ],
  });

export const identification_credicapital_recurring_client = (_clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Identification information",
      "es-MX": "Información sobre identificación",
    },
    elements: [
      {
        title: { en: "ID Type", "es-MX": "Tipo de identificación *" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_16",
        choices: [
          { value: "ine", text: { en: "INE", "es-MX": "INE" } },
          { value: "otro", text: { en: "Other", "es-MX": "Otro" } },
        ],
      },
      {
        title: {
          en: "In case of other, indicate ",
          "es-MX": "En caso de otro, indicar *",
        },
        type: "text",
        name: "custom_text_17",
        isRequired: "{custom_text_16} == 'otro'",
        visibleIf: "{custom_text_16} == 'otro'",
      },
      {
        title: { en: "ID Number (INE)", "es-MX": "Folio (INE)*" },
        type: "text",
        name: "custom_text_18",
        isRequired: "{custom_text_16} == 'ine'",
        visibleIf: "{custom_text_16} == 'ine'",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please, enter 13 characters",
              "es-MX": "Por favor, ingrese 13 caracteres",
            },
            regex: `^[0-9a-zA-Z]{13}$`,
          },
        ],
      },
      {
        title: { en: "Expedition date", "es-MX": "Fecha de expedición*" },
        type: "text",
        name: "custom_text_19",
        isRequired: required,
        inputType: "date",
      },
      {
        title: {
          en: "Image from the front (INE)",
          "es-MX": "Imagen parte de adelante (INE)*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_1",
        visibleIf: "{custom_text_16} == 'ine'",
      },
      {
        title: {
          en: "Image from the back (INE)",
          "es-MX": "Imagen parte de atrás (INE)*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_2",
        visibleIf: "{custom_text_16} == 'ine'",
      },
      {
        title: {
          en: "To verify your identity please upload a photo holding said identification card (INE)",
          "es-MX":
            "Para verificar tu identidad por favor, sube una foto sujetando dicha tarjeta de identificación (INE)*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_3",
      },
      {
        title: { en: "Email", "es-MX": "Correo electrónico" },
        type: "text",
        inputType: "email",
        name: "email",
      },
      {
        title: {
          en: "Unique Population Registry Code (CURP)",
          "es-MX": "CURP",
        },
        type: "expression",
        expression: "{custom_text_21}"
      },
      {
        title: { en: "Scholarship *", "es-MX": "Escolaridad *" },
        type: "dropdown",
        name: "custom_text_42",
        isRequired: required,
        choices: [
          {
            value: "primaria",
            text: { en: "Primary", "es-MX": "Primaria" },
          },
          {
            value: "secundaria",
            text: { en: "Secundary", "es-MX": "Secundaria" },
          },
          {
            value: "preparatoria",
            text: { en: "High-School", "es-MX": "Preparatoria" },
          },
          {
            value: "profesional",
            text: { en: "College", "es-MX": "Profesional" },
          },
          {
            value: "no_tiene",
            text: { en: "Doesn't have", "es-MX": "No tiene" },
          },
        ],
      },
      {
        title: {
          en: "Economic dependents *",
          "es-MX": "Dependientes económicos *",
        },
        type: "text",
        name: "custom_text_20",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please, enter only numbers",
              "es-MX": "Por favor, ingrese solo números"
            },
            regex: `^[0-9]{1,2}$`,
          },
        ],
      },
      {
        title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
        type: "expression",
        expression: "{custom_text_4}"
      },
      {
        title: { en: "Homoclave", "es-MX": "Homoclave" },
        type: "text",
        name: "custom_text_5",
        readOnly: true
      },
      {
        title: {
          en: "Serial number of the Advanced Electronic Signature",
          "es-MX": "Número de serie de la Firma Electrónica Avanzada",
        },
        type: "text",
        name: "custom_text_54",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Número de serie 20 characters.",
              "es-MX": "Número de serie 20 dígitos.",
            },
            regex: `^[0-9A-Za-z]{20}$`,
          },
        ],
      },
    ],
  });