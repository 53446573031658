import React from 'react';
import ReactDOM from 'react-dom';
import nouislider from "nouislider";

import './slider.scss';
import {setAlertDialog} from "../components/alert/alert";
import {translate} from "../lib/intl";
import { debounce } from '../lib/helpers';

class SliderComponent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.locale = props.question.survey.locale;

    this.state = {
      value: props.question.value,
      max: this.props.question.rangeMaxStart > 0 ? this.props.question.rangeMaxStart : this.props.question.rangeMax
    };

    this._onChangeDebounced = debounce(this._onChangeDebounced, 6000);//1 second per digit
  }

  _decimals() {
    if (this.props.question.decimals === undefined) {
      return 2; // default
    } else {
      return this.props.question.decimals;
    }
  }

  componentDidMount() {
    // setup slider
    this.slider = nouislider.create(this.sliderContainer, {
      start: this.props.question.value ? this.props.question.value : 0,
      connect: [true, false],
      step: this.props.question.step,
      tooltips: false,
      pips: {
        mode: 'positions',
        values: [0, 25, 50, 75, 100],
        density: 3,
        stepped: true
      },
      range: {
        min: this.props.question.rangeMin,
        max: this.state.max,
      }
    });

    // TODO: callback change
    // slider.set(question.value);

    // TODO: readonly change
    // if (question.isReadOnly) {
    //   el.setAttribute("disabled", true);
    // }

    // define on change
    this.slider.on('change', () => this._updateFromSlider(this.slider.get()));

  }

  _round(value) {
    if (!value) {
      return 0;    
    }

    const factor = this._decimals() <= 0 ? 0 : 10 ** this._decimals();
    return Math.round(value * factor) / factor;
  }

  _updateFromSlider(newValue) {
    const value = this._round(newValue);

    this.setState({ value });
    this._saveResults(value);
    this._updateMax(value, false)
  };

  _updateFromInput(newValue) {
    const value = this._round(newValue);

    this.setState({ value });

    this._onChangeDebounced(value);
  }

  _onChangeDebounced(newValue) {
    if (newValue > this.props.question.rangeMax) {
      newValue = this.props.question.rangeMax;
      setAlertDialog(translate('errors.numericMax', {max: this.props.question.rangeMax}))
    } else if (newValue < this.props.question.rangeMin) {
      newValue = this.props.question.rangeMin;
      setAlertDialog(translate('errors.numericMin', {max: this.props.question.rangeMin}))
    }

    this.setState({value: newValue});
    this._saveResults(newValue);
    this._updateMax(newValue, true)
    this.slider.set(newValue);
  }

  _updateMax(newValue, setSlider=false) {

    // possibly update the maximum scale, otherwise indicate that maximum has reached
    if (this.state.max < this.props.question.rangeMax && newValue >= this.state.max) {

      // determine the new maximum value on the slider, either from the range or the input if that is bigger
      let newMax = this.state.max + this.props.question.maxStep;

      while (newMax < newValue) {
        newMax += this.props.question.maxStep;
      }

      // update it till this number unless it is more then maximum
      if (newMax > this.props.question.rangeMax) newMax = this.props.question.rangeMax;
      this.setState({max: newMax}, () => {
        this.slider.updateOptions({
          range: {
            'min': 0,
            'max': newMax
          }
        });

        if (setSlider) {
          // also set value
          this.slider.set(newValue);
        }

      });
    }
  }

  _onEnter(event) {
    if (event.key === 'Enter') {
      this.valueInput.blur();
    }
  }

  _saveResults(newValue) {
    this.props.changeHandler(newValue)
  };

  render({question} = this.props) {
    return (
      <div className='sliderComponent'>

        <div className='sliderWrapper'>
          <div ref={ref => {
            this.sliderContainer = ref
          }}/>
        </div>

        <input
          ref={(ref) => this.valueInput = ref}
          className="input"
          type="number"
          step={question.step}
          name="price"
          placeholder=""
          disabled={this.props.question.readOnly}
          value={this.state.value}
          onChange={(event) => this._updateFromInput(event.target.value)}
          onKeyDown={(event) => this._onEnter(event)}/>
      </div>
    )
  }
}

export function register_widget_slider(Survey) {

  let widget = {
    name: "nouislider",
    title: "noUiSlider",
    iconName: "icon-nouislider",

    widgetIsLoaded: function () {
      //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
      return !!nouislider.version
    },
    isFit: function (question) {
      //SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
      return question.getType() === "nouislider";

    },

    activatedByChanged: function (activatedBy) {
      //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
      Survey.JsonObject.metaData.addClass("nouislider", [], null, "empty");
      Survey.JsonObject.metaData.addProperties("nouislider", [
        {
          name: "step:number",
          default: 1
        },
        {
          name: "rangeMin:number",
          default: 0
        },
        {
          name: "rangeMaxStart:number",
          default: 0
        },
        {
          name: "maxStep:number",
          default: 50
        },
        {
          name: "rangeMax:number",
          default: 200
        },
        {
          name: "readOnly:number",
          default: 0
        },
        {
          name: "unit:string",
          default: ''
        }
      ]);
    },

    afterRender: function (question, el) {
      //el is our root element in htmlTemplate, is "div" in our case
      let rendering = <SliderComponent question={question} changeHandler={(value) => question.value = value}/>;
      ReactDOM.render(rendering, el);
    },

    htmlTemplate: "<div></div>",

    willUnmount: function (question, el) {
    }
  };

  // register widget
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");

}
