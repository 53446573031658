import React from "react";
import { translate } from "../../lib/intl";
import { Icon, ws_clock, ws_next } from "../icon/icon";
import { Button } from "../button/button";
import { colors, windowWidth } from "../../theme/theme";

import "./start.scss";

let img_lookup = {
  intake: require("../../assets/images/stages/intake_next.png"),
  credit_check: require("../../assets/images/stages/intake_next.png"),
  interview: require("../../assets/images/stages/intake_next.png"),
  supervision: require("../../assets/images/stages/intake_next.png"),
  assessment_study: require("../../assets/images/stages/intake_next.png"),
  assessment: require("../../assets/images/stages/assessment_next.png"),
  review: require("../../assets/images/stages/review_next.png"),
  guarantor: require("../../assets/images/stages/assessment_next.png"),
  sign: require("../../assets/images/stages/sign_next.png"),
  sign_guarantor: require("../../assets/images/stages/sign_next.png"),
  two_factor_auth: require("../../assets/images/stages/two_factor_auth.png"),
};

let header_lookup = {
  intake: require("../../assets/images/stages/header_intake.png"),
  credit_check: require("../../assets/images/stages/header_intake.png"),
  supervision: require("../../assets/images/stages/header_intake.png"),
  interview: require("../../assets/images/stages/header_intake.png"),
  assessment_study: require("../../assets/images/stages/header_intake.png"),
  assessment: require("../../assets/images/stages/header_assessment.png"),
  review: require("../../assets/images/stages/header_review.png"),
  guarantor: require("../../assets/images/stages/header_assessment.png"),
  sign: require("../../assets/images/stages/header_sign.png"),
  sign_guarantor: require("../../assets/images/stages/header_sign.png"),
};

export class Start extends React.PureComponent {
  render = (
    {
      children = "<div>OK</div>",
      stage = "intake",
      time = 20,
      onStart = () => {},
      onSkip = null,
    } = this.props
  ) => {
    return (
      <div className="startComponent">
        <div className="startHeaderWrapper">
          <div
            className="startHeader"
            style={
              windowWidth < 345
                ? {
                    height: 170,
                    backgroundImage: `url(${header_lookup[stage]})`,
                  }
                : {}
            }
          >
            <img className="headerButton" src={img_lookup[stage]} alt="stage" />

            <div className="startTitle">
              <p className="white xlarge">
                {translate("application." + stage)}
              </p>

              {time !== "0" ? (
                <div className="time">
                  <Icon icon={ws_clock} size={20} color="#fff" />
                  <p className="white">
                    {translate("general.minutes", { minutes: time })}
                  </p>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>

        <div className="startBody centerWrapper">{children}</div>

        <div className="startButton">
          {onSkip && (
            <div style={{ marginBottom: 12 }}>
              <Button
                backgroundColor={colors.brand.primary_light}
                title={translate("general.skip")}
                icon={ws_next}
                iconSize={24}
                color={colors.brand.darkgray}
                onPress={onSkip}
              />
            </div>
          )}

          <Button
            backgroundColor={colors.brand.accent}
            title={translate("general.start")}
            icon={ws_next}
            iconSize={24}
            color={colors.brand.darkgray}
            onPress={onStart}
          />
        </div>
      </div>
    );
  };
}
