
export const track = (event, data = {}) => {
  // create custom data layer event, you can add additional data to the array
  // don't track if the dataLayer is not yet created, this causes timing issues with Optimize page flickering script

  if (window.dataLayer) {
    data['event'] = event;
    window.dataLayer.push(data);
  }
};
