import env from "../../env";
const required = env.ENDPOINT !== "local";

export const review_rentamigo = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review and sign your loan application",
        "es-MX": "Revisa y firme tu solicitud de préstamo",
      },
      innerIndent: 1,
      elements: [
        {
          title: {
            en: `My name is {first_name} and my ID number is {id_number}. I confirm that the information given in this form is true, complete and accurate.`,
            "es-MX": `Mi nombre es {first_name} {last_name}, coaplicante del grupo {custom_text_150} y aplicando en la sucursal {custom_text_10} por un monto de {amount}. Confirmo que la información proporcionada en este formulario es verdadera, completa y precisa.`,
          },
          type: "checkbox",
          name: "confirm_1",
          isRequired: required,
          choices: [
            {
              value: "Estoy de acuerdo",
              text: {
                en: "I agree",
                "es-MX": "Estoy de acuerdo",
              },
            },
          ],
        },
        {
          title: { en: "Sign loan application", "es-MX": "Tu firma" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
      ],
    },
  ],
};
