import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import { api } from "../app";

// barrel import actions
import * as accountActions from "./account/account.actions";
import * as authenticationActions from "./authentication/authentication.actions";
import * as deviceActions from "./device/device.actions";
import * as loanActions from "./loan/loan.actions";
export { deviceActions, authenticationActions, accountActions, loanActions };

export const authenticateApi = (store) => {
  // set token in api so we can make auth calls again
  let state = store.getState();
  api.token = state.device.token;

  // this is kind of redundant in a way but i wanted to keep setting the ChoicesRestfull token in a single file
  deviceActions.setAccessToken(state.device.token.access_token)();

  // set store in api so we can dispatch actions again
  api.store = store;
};

export const initStore = (
  storage,
  rootReducer,
  middleware = [],
  logger = false
) => {
  let persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["device", "account"],
  };

  let middleWares = [thunk];

  if (middleware) {
    middleWares.concat(middleware);
  }
  if (logger) {
    middleWares.push(createLogger());
  }

  let store = createStore(
    persistReducer(persistConfig, rootReducer),
    undefined,
    compose(applyMiddleware(...middleWares))
  );

  let persistor = persistStore(store, null, () => {
    authenticateApi(store);
  });

  return { store, persistor };
};
