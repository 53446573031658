export const loanState = {

  getLoanPending: false,
  page: "",

  uuid: null,
  state: "draft",

  // information on loan itself
  role: 'unauthorized',
  title: null,
  description: null,
  amount: null,
  repayment_period: null,
  start_repayment_date: null,
  monthly_insurance: null,
  monthly_installment: null,
  total_fees: 0,
  signature: null,

  // Who it is taking
  first_name: null,
  guarantor_first_name: null,

  // Message, depending on who views the loan in which stage this can trigger extra messages
  message: null, // not_found, awaiting_guarantor, submitted, submitted_guarantor

  intake: {
    date_created: "2018-06-13T09:53:10.698865",
    date_updated: "2018-06-13T09:53:10.698879",
    date_completed: null,
    is_completed: false,
    current_page: 0,

    date_signed: null,
    signature: null
    // remaining dynamic data not typed in form
  },

  intake_guarantor: {
    date_created: "2018-06-13T09:53:10.698865",
    date_updated: "2018-06-13T09:53:10.698879",
    date_completed: null,
    is_completed: false,
    current_page: 0,

    date_signed: null,
    signature: null
    // remaining dynamic data not typed in form
  },

  assessment: {
    date_created: "2018-06-13T09:53:10.698865",
    date_updated: "2018-06-13T09:53:10.698879",
    date_completed: null,
    is_completed: false,
    current_page: 0,
    help_count: 0,
    help_time: 0,
    help_log: []
  }
};
