export const deviceState = {
  isAuthenticated: false,
  token: {
    access_token: null,
    expires_in: null,
    refresh_token: null,
    scope: null,
    token_type: null,
  },

  // device data from backend
  date_last_refreshed: null,

  alertShown: false,
  alertMessage: "",
  alertTitle: "",
  alertButtons: [],

  home_page: "home",
  locale: "en",
  api_service_tokens: {},
};
