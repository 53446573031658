import "../../intake.container.scss";
import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";
const required = env.ENDPOINT !== "local";

export const economic_ffdl = new SurveyComponent({
  title: {
    en: "Economic Info",
    "es-GT": "Información económica",
  },
  name: "economic_info",
  elements: [
    {
      title: {
        en: "Income Details",
        "es-GT": "Detalles de ingresos",
      },
      name: "income_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Net monthly salary* (excluding overtime and bonus)",
            "es-GT": "Salario mensual neto * (sin descuentos)",
          },
          name: "custom_float_15",
          isRequired: required,
          type: "text",
          inputType: "number",
        },
        {
          title: {
            en: "Upload a last salary advice slip *",
            "es-GT": "Subir imagen de última contancia de pago salarial *",
          },
          name: "current_job_salary_slip",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: {
            en: "Other income",
            "es-GT":
              "Otros ingresos, ej. remesas, salario cónyuge, pensión alimenticia, pensión jubilación, ingresos de hijos, etc.",
          },
          name: "other_incomes",
          valueName: "other_incomes",
          type: "matrixdynamic",
          columnLayout: "vertical",
          addRowLocation: "bottom",
          minRowCount: 0,
          rowCount: 1,
          isRequired: false,
          addRowText: {
            en: "Add another income",
            "es-GT": "Agregar otro ingreso",
          },
          columns: [
            {
              title: {
                en: "Please add the income in Q:",
                "es-GT": "Agregue los ingresos en Q:",
              },
              name: "Ingresos",
              isRequired: false,
              cellType: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Where do you get this income:",
                "es-GT": "¿De dónde obtiene estos ingresos?",
              },
              name: "Origen",
              isRequired: false,
              cellType: "text",
              inputType: "text",
            },
            {
              title: {
                en: "Proof of other income",
                "es-GT": "Constancia(s) de otros ingresos",
              },
              name: "Constancia ingresos",
              cellType: "file_uploader",
              accept: "image/*,application/pdf",
              isRequired: false,
            },
          ],
        },
        {
          title: {
            en: "Total other monthly income",
            "es-GT": "Total de otros ingresos mensuales",
          },
          type: "expression",
          name: "custom_float_8",
          expression: `fixed(sumMatrixdropdown({other_incomes}, 'Ingresos'))`,
          displayStyle: "currency",
          currency: "GTQ",
          //visibleIf: "1 > 0",
        },
        {
          title: {
            en: "Total monthly income",
            "es-GT": "Ingreso mensual total",
          },
          type: "expression",
          name: "custom_float_11",
          expression: `fixed(add({custom_float_8},{custom_float_15}), 2, true)`, // Floating Point Proof
          displayStyle: "currency",
          currency: "GTQ",
        },

      ],
    },
    {
      title: {
        en: "Details of Expenses",
        "es-GT": "Detalles de gastos",
      },
      name: "expenses_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          name: "mcf_expenses",
          type: "matrixdynamic",
          columnLayout: "vertical",
          addRowLocation: "bottom",
          title: {
            en: "Familiar Expenses *",
            "es-GT": "Gastos familiares *",
          },
          minRowCount: 1,
          rowCount: 1,
          isRequired: required,
          addRowText: {
            en: "Add",
            "es-GT": "Agregar",
          },
          columns: [
            {
              title: {
                en: "Item",
                "es-GT": "Elemento",
              },
              name: "Tipo",
              isRequired: required,
              cellType: "dropdown",
              inputType: "string",
              hasOther: true,
              otherText: {
                en: "Other",
                "es-GT": "Otros",
              },
              choices: [
                {
                  value: "Alimentación",
                  text: {
                    en: "Food",
                    "es-GT": "Alimentación",
                  },
                },
                {
                  value: "Energía eléctrica",
                  text: {
                    en: "Electricity",
                    "es-GT": "Energía eléctrica",
                  },
                },
                {
                  value: "Agua potable",
                  text: {
                    en: "drinking_water",
                    "es-GT": "Agua potable",
                  },
                },
                {
                  value: "TV por cable",
                  text: {
                    en: "Cable TV",
                    "es-GT": "TV por cable",
                  },
                },
                {
                  value: "Basura",
                  text: {
                    en: "Garbage",
                    "es-GT": "Basura",
                  },
                },
                {
                  value: "Telefóno en casa",
                  text: {
                    en: "Home phone",
                    "es-GT": "Telefóno en casa",
                  },
                },
                {
                  value: "Internet",
                  text: {
                    en: "Internet",
                    "es-GT": "Internet",
                  },
                },
                {
                  value: "Educación",
                  text: {
                    en: "Education",
                    "es-GT": "Educación",
                  },
                },
                {
                  value: "Telefóno Celular",
                  text: {
                    en: "Cell phone",
                    "es-GT": "Telefóno Celular",
                  },
                },
                {
                  value: "Transporte",
                  text: {
                    en: "Transport",
                    "es-GT": "Transporte",
                  },
                },
                {
                  value: "Salud",
                  text: {
                    en: "Health",
                    "es-GT": "Salud",
                  },
                },
                {
                  value: "Mantenimiento Vehículo",
                  text: {
                    en: "Vehicle maintenance",
                    "es-GT": "Mantenimiento Vehículo",
                  },
                },
                {
                  value: "Combustible",
                  text: {
                    en: "Fuel",
                    "es-GT": "Combustible",
                  },
                },
                {
                  value: "Alquiler",
                  text: {
                    en: "Rent",
                    "es-GT": "Alquiler",
                  },
                },
              ],
            },
            {
              title: {
                en: "Amount per month *",
                "es-GT": "Cantidad mensual *",
              },
              name: "Cantidad mensual",
              isRequired: required,
              cellType: "text",
              inputType: "number",
              validators: [
                expressions.amount_greater_than_zero("cantidad_mensual"),
              ],
            },
            {
              title: {
                en: "Attachment (optional)",
                "es-GT": "Adjunto (opcional)",
              },
              name: "mcf_expense_attachment",
              isRequired: false,
              cellType: "file_uploader",
              accept: "image/*,application/pdf",
            },
          ],
        },
        {
          title: {
            en: "Total familiar expenses",
            "es-GT": "Total de gastos familiares",
          },
          type: "expression",
          name: "mcf_total_expense_amount",
          visibleIf: "sumInArray({mcf_expenses}, 'Cantidad mensual') > 0",
          expression:
            "fixed(sumInArray({mcf_expenses}, 'Cantidad mensual'), 2)", // Floating Point Proof
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          title: {
            en: "Upload a last electric energy bill or last water bill *",
            "es-GT":
              "Suba imagen del último recibo de energía eléctrica o agua *",
          },
          isRequired: required,
          name: "picture_2",
          type: "file_uploader",
          accept: "image/*,application/pdf",
        },
        {
          name: "custom_jsonfield_1",
          valueName: "custom_jsonfield_1", // weird name for expenses
          type: "matrixdynamic",
          title: {
            en: "Other Expenses *",
            "es-GT": "Otros gastos (IGSS, ISR) *",
          },
          description: {
            en: "Enter at least one *",
            "es-GT": "Que ingrese al menos una *",
          },
          minRowCount: 1,
          columnLayout: "vertical",
          addRowLocation: "bottom",
          rowCount: 1,
          isRequired: required,
          addRowText: {
            en: "Add",
            "es-GT": "Agregar",
          },
          columns: [
            {
              title: {
                en: "Item",
                "es-GT": "Elemento",
              },
              name: "Tipo",
              isRequired: required,
              cellType: "dropdown",
              inputType: "string",
              hasOther: true,
              otherText: {
                en: "Other",
                "es-GT": "Otros",
              },
              choices: [
                {
                  value: "IGSS",
                  text: {
                    en: "IGSS",
                    "es-GT": "IGSS",
                  },
                },
                {
                  value: "ISR",
                  text: {
                    en: "ISR",
                    "es-GT": "ISR",
                  },
                },
              ],
            },
            {
              title: {
                en: "Amount per month *",
                "es-GT": "Cantidad mensual *",
              },
              name: "Cantidad mensual",
              isRequired: required,
              cellType: "text",
              inputType: "number",
              validators: [
                expressions.amount_greater_than_zero("Cantidad mensual"),
              ],
            },
            {
              title: {
                en: "Attachment (optional)",
                "es-GT": "Adjunto (opcional)",
              },
              name: "Adjunto (opcional)",
              isRequired: false,
              cellType: "file_uploader",
              accept: "image/*,application/pdf",
            },
          ],
        },
        {
          title: {
            en: "Total other expenses",
            "es-GT": "Total de otros gastos",
          },
          type: "expression",
          name: "custom_float_12",
          visibleIf: "sumInArray({custom_jsonfield_1}, 'Cantidad mensual') > 0",
          expression:
            "fixed(sumInArray({custom_jsonfield_1}, 'Cantidad mensual'), 2)", // Floating Point Proof
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          title: {
            en: "Total expenses",
            "es-GT": "Gastos totales",
          },
          type: "expression",
          name: "custom_float_13",
          expression:
            "fixed(add({custom_float_12}, {mcf_total_expense_amount}), 2)", // Floating Point Proof
          displayStyle: "currency",
          currency: "GTQ",
        },
      ],
    },
    {
      title: {
        en: "Details of debt",
        "es-GT": "Detalle de las deudas",
      },
      name: "debt_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Debts",
            "es-GT": "Deudas",
          },
          columnLayout: "vertical",
          addRowLocation: "bottom",
          name: "outstanding_loans",
          valueName: "outstanding_loans",
          type: "matrixdynamic",
          minRowCount: 0,
          rowCount: 1,
          isRequired: false,
          addRowText: {
            en: "Add another debt",
            "es-GT": "Agregar otra deuda",
          },
          columns: [
            {
              title: {
                en: "Item",
                "es-GT": "Elemento",
              },
              name: "Tipo",
              isRequired: false,
              cellType: "dropdown",
              inputType: "string",
              hasOther: true,
              otherText: {
                en: "Other",
                "es-GT": "Otros",
              },
              choices: [
                {
                  value: "Hipoteca",
                  text: {
                    en: "Mortgage",
                    "es-GT": "Hipoteca",
                  },
                },
                {
                  value: "Préstamo vehículo",
                  text: {
                    en: "Vehicle loan",
                    "es-GT": "Préstamo vehículo",
                  },
                },
                {
                  value: "Préstamo negocio",
                  text: {
                    en: "Business loan",
                    "es-GT": "Préstamo negocio",
                  },
                },
                {
                  value: "Extra financiamiento",
                  text: {
                    en: "Visa fee/ finance",
                    "es-GT": "Visa cuota/extrafinanciamiento",
                  },
                },
              ],
            },
            {
              title: {
                en: "Institution",
                "es-GT": "Institución",
              },
              name: "Institución",
              isRequired: false,
              cellType: "text",
              inputType: "text",
            },
            {
              title: {
                en: "Condition",
                "es-GT": "Condición",
              },
              name: "Condición",
              isRequired: false,
              cellType: "dropdown",
              inputType: "text",
              choices: [
                { value: "Deudor", text: { en: "Debtor", "es-GT": "Deudor" } },
                {
                  value: "Fiador",
                  text: { en: "Guarantor", "es-GT": "Fiador" },
                },
              ],
            },
            {
              title: {
                en: "Starting amount",
                "es-GT": "Monto inicial",
              },
              name: "Monto inicial",
              isRequired: false,
              cellType: "text",
              inputType: "number",
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              title: {
                en: "Outstanding balance",
                "es-GT": "Saldo pendiente",
              },
              name: "Saldo pendiente",
              isRequired: false,
              cellType: "text",
              inputType: "number",
              displayStyle: "currency",
              currency: "GTQ",
            },
            {
              title: {
                en: "Monthly payment",
                "es-GT": "Pago mensual",
              },
              name: "Pago mensual",
              isRequired: false,
              cellType: "text",
              inputType: "number",
              displayStyle: "currency",
              currency: "GTQ",
            },
          ],
        },
        {
          title: {
            en: "Total outstanding debts",
            "es-GT": "Total deudas pendientes",
          },
          type: "expression",
          name: "custom_float_14",
          expression: `fixed(sumMatrixdropdown({outstanding_loans}, 'Pago mensual'), 2, true)`, // Sum of monthly payments
          displayStyle: "currency",
          currency: "GTQ",
        },
      ],
    },
    {
      type: "panel",
      visibleIf: "{custom_float_14} == 'This will stay hidden'",
      title: {
        en: `Based on the amount you entered above, we have calculated your Total Assets, Total Activos, Total …etc`,
        "es-GT": `En base a la cantidad introducida anteriormente, hemos calculado el activo total.`,
      },
      elements: [
        {
          type: "expression",
          name: "custom_float_19",
          title: {
            en: "Availability (total revenues - total expenditures)",
            "es-GT": "Disponibilidad "
          },
          expression: `fixed(subtract({custom_float_11},{custom_float_13}), 2 , true)`,
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          type: "expression",
          name: "custom_float_26",
          title: {
            en: "Ga calculation",
            "es-GT": "Ga calculation "
          },
          expression: `fixed(subtract({custom_float_11},{custom_float_13}), 2 , true)`,
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          type: "expression",
          name: "custom_float_17",
          title: {
            en: "Capital share (requested loan amount /loan term)",
            "es-GT": "Cuota de capital "
          },
          expression: `fixed(divide({amount},subtract({custom_float_11},{custom_float_13}), 2 , true)`, //amount / disponibilidad
          displayStyle: "currency",
          currency: "GTQ",
        },
        {
          type: "expression",
          name: "custom_float_18",
          title: {
            en: "NEI (capital quota / availability)",
            "es-GT": "NEI (cuota capital dividido disponibilidad)" //cuota de capital / disponibilidad
          },
          expression: `fixed(divide({{custom_float_17}},{{custom_float_19}}), 2 , true)`,
          displayStyle: "currency",
          currency: "GTQ",
        },
      ]
    },
  ],
});
