import env from "../../env";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const intake_crediavance_pages = [
  {
    title: {
      en: "Applicant information",
      "es-MX": "Solicitud del distribuidor",
    },
    innerIndent: 1,
    name: "Solicitud distribuidor",
    elements: [
      {
        title: { en: "Branch office", "es-MX": "Sucursal" },
        type: "dropdown",
        name: "custom_text_34",
        isRequired: required,
        choices: [
          { value: "obregon", text: { en: "Obregon", "es-MX": "Obregón" } },
          {
            value: "los_mochis",
            text: { en: "Los Mochis", "es-MX": "Los Mochis" },
          },
        ],
      },
      {
        type: "expression",
        expression: `daysFromNow(0, 0, 'es-MX')`,
        title: {
          en: "Application date",
          "es-MX": "Fecha de solicitud",
        },
        name: "custom_text_35",
        isRequired: required,
      },
      {
        title: { en: "Nationality", "es-MX": "Nacionalidad" },
        type: "radiogroup",
        name: "custom_text_36",
        isRequired: required,
        choices: [
          {
            value: "Nacionalidad mexicana",
            text: {
              en: "Mexican nationality",
              "es-MX": "Nacionalidad mexicana",
            },
          },
          {
            value: "Nacionalidad extranjera con residencia permanente",
            text: {
              en: "Foreign nationality with permanent residence",
              "es-MX": "Nacionalidad extranjera con residencia permanente",
            },
          },
          {
            value: "Nacionalidad extranjera con residencia temporal",
            text: {
              en: "Foreign nationality with temporary residence",
              "es-MX": "Nacionalidad extranjera con residencia temporal",
            },
          },
        ],
      },
      {
        title: {
          en: "Please give us your full name (as it appears in the INE)",
          "es-MX":
            "Por favor indicanos tu nombre completo (como aparece en la INE)",
        },
        type: "panel",
        elements: [
          {
            title: { en: "First name", "es-MX": "Primer nombre" },
            type: "text",
            name: "first_name",
            isRequired: required,
          },
          {
            title: { en: "Second name", "es-MX": "Segundo nombre" },
            type: "text",
            name: "middle_name",
          },
          {
            title: { en: "Last name", "es-MX": "Apellido paterno" },
            type: "text",
            name: "surname",
            isRequired: required,
          },
          {
            title: { en: "Mother's last name", "es-MX": "Apellido materno" },
            type: "text",
            name: "second_surname",
          },
          {
            title: {
              en: "Voting license",
              "es-MX": "Credencial de elector /INE",
            },
            type: "text",
            name: "custom_text_40",
            isRequired: required,
            regex: `^[a-zA-Z0-9]{18}$`,
          },
        ],
      },
      {
        title: { en: "CURP", "es-MX": "CURP" },
        type: "text",
        name: "custom_text_2",
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Enter a valid CURP",
              "es-MX": "Ingresa un CURP válido",
            },
            // regex: `^[a-zA-Z0-9]{18}$`,
            regex: `^[a-zA-Z]{4}[0-9]{6}[a-zA-Z]{6}[0-9]{2}$`,
          },
        ],
        placeHolder: "HEGG560427MVZRRL04",
      },
      {
        title: { en: "RFC", "es-MX": "RFC" },
        type: "text",
        name: "custom_text_1",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Enter a valid RFC",
              "es-MX": "Ingresa un RFC válido",
            },
            // regex: `^[0-9A-Za-z]{13}$`,
            regex: `^[A-Za-z]{4}[ |-]{0,1}[0-9]{6}[ |-]{0,1}[0-9A-Za-z]{3}$`,
          },
        ],
        placeHolder: "VECJ880326XXX",
      },
      {
        title: { en: "FIEL Code", "es-MX": "FIEL" },
        type: "text",
        name: "custom_text_10",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "FIEL 20 digits",
              "es-MX": "FIEL 20 dígitos",
            },
            regex: `^[0-9A-Za-z]{20}$`,
          },
        ],
      },
      {
        title: { en: "Sex", "es-MX": "Sexo" },
        type: "dropdown",
        name: "gender",
        isRequired: required,
        choices: [
          { value: "Masculino", text: { en: "male", "es-MX": "Masculino" } },
          { value: "Femenino", text: { en: "female", "es-MX": "Femenino" } },
        ],
      },
      {
        title: { en: "Date of birth", "es-MX": "Fecha de nacimiento" },
        type: "text",
        name: "date_of_birth",
        isRequired: required,
        inputType: "date",
        validators: [
          {
            type: "expression",
            text: {
              en: "You must be between 21 and 65 years of age.",
              "es-MX": "Debes tener entre 21 y 65 años de edad.",
            },
            expression:
              "age({date_of_birth}) >= 21 && age({date_of_birth}) <= 65",
          },
        ],
      },
      {
        title: { en: "Age", "es-MX": "Edad" },
        type: "expression",
        name: "custom_integer_11",
        isRequired: required,
        expression: "age({date_of_birth})",
      },
      {
        title: {
          en: "Federal entity of birth",
          "es-MX": "Entidad federativa de nacimiento",
        },
        type: "text",
        name: "custom_text_41",
        isRequired: required,
      },
      {
        title: { en: "Country of birth", "es-MX": "País de nacimiento" },
        type: "text",
        name: "custom_text_42",
        isRequired: required,
      },
      {
        title: { en: "Nationality", "es-MX": "Nacionalidad" },
        type: "text",
        name: "custom_text_43",
        isRequired: required,
      },
      {
        title: {
          en: "Occupation / profession / activity",
          "es-MX": "Ocupación/Profesión/Actividad",
        },
        type: "radiogroup",
        name: "custom_text_44",
        isRequired: required,
        choices: [
          {
            value: "Al comercio",
            text: { en: "To trade", "es-MX": "Al comercio" },
          },
        ],
      },
      {
        title: {
          en: "Address",
          "es-MX": "Domicilio",
        },
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: "Street (post or block)",
              "es-MX": "Calle (poste o manzana)",
            },
            type: "text",
            name: "home_street",
            isRequired: required,
          },
          {
            title: { en: "N. exterior", "es-MX": "N. exterior" },
            type: "text",
            inputType: "number",
            name: "custom_integer_12",
            isRequired: required,
          },
          {
            title: { en: "N. interior", "es-MX": "N. interior" },
            type: "text",
            inputType: "number",
            name: "custom_integer_13",
            isRequired: required,
          },
          {
            title: { en: "Settlement", "es-MX": "Colonia" },
            type: "text",
            name: "custom_text_3",
            isRequired: required,
          },
          {
            title: { en: "Postal Code", "es-MX": "Código postal" },
            type: "text",
            name: "po_box_postal_code",
            isRequired: required,
          },
          {
            title: { en: "Municipality", "es-MX": "Municipio" },
            type: "text",
            name: "custom_text_4",
            isRequired: required,
          },
          {
            title: { en: "City or population", "es-MX": "Ciudad o población" },
            type: "text",
            name: "home_city",
            isRequired: required,
          },
          {
            title: { en: "State", "es-MX": "Estado" },
            type: "text",
            name: "custom_text_5",
            isRequired: required,
          },
          {
            title: { en: "Country", "es-MX": "País" },
            type: "text",
            name: "custom_text_50",
            isRequired: required,
          },
        ],
      },
      {
        type: "panel",
        title: {
          en: "Phone numbers",
          "es-MX": "Números de teléfono",
        },
        isRequired: true,
        requiredErrorText: {
          en: "Please enter at least one phone number",
          "es-MX": "Por favor ingrese al menos un número de teléfono",
        },
        elements: [
          {
            title: {
              en: "Home phone (include lada)",
              "es-MX": "Tel. particular (incluir lada)",
            },
            type: "phone",
            name: "custom_text_15",
            validators: [
              expressions.phone_en("custom_text_15"),
              expressions.phone_length("custom_text_15"),
            ],
          },
          {
            title: {
              en: "Cell phone (include lada)",
              "es-MX": "Tel. celular (incluir lada)",
            },
            type: "phone",
            name: "phone_number",
            validators: [
              expressions.phone_en("phone_number"),
              expressions.phone_length("phone_number"),
            ],
          },
          {
            title: { en: "Cell company", "es-MX": "Compañía celular" },
            name: "custom_text_27",
            type: "dropdown",
            hasOther: true,
            otherText: { en: "Otro", "es-MX": "Otro" },
            choices: [
              { value: "telcel", text: { en: "Telcel", "es-MX": "Telcel" } },
              {
                value: "movistar",
                text: { en: "Movistar", "es-MX": "Movistar" },
              },
              { value: "at_t", text: { en: "AT&T", "es-MX": "AT&T" } },
              { value: "unefon", text: { en: "Unefon", "es-MX": "Unefon" } },
              {
                value: "virgin_mobile",
                text: { en: "Virgin Mobile", "es-MX": "Virgin Mobile" },
              },
            ],
          },
        ],
      },
      {
        title: { en: "email", "es-MX": "email" },
        type: "text",
        name: "email",
        validators: [{ type: "email" }],
      },
      {
        title: { en: "School grade", "es-MX": "Grado de escolaridad" },
        type: "dropdown",
        name: "custom_text_51",
        isRequired: required,
        choices: [
          { value: "Primaria", text: { en: "Elementary", "es-MX": "Primaria"}},
          { value: "Secundaria", text: { en: "Middle school", "es-MX": "Secundaria"}},
          { value: "Preparatoria", text: { en: "High school", "es-MX": "Preparatoria"}},
          { value: "Carrera técnica", text: { en: "Technical career", "es-MX": "Carrera técnica"}},
          { value: "Carrera profesional", text: { en: "Professional career", "es-MX": "Carrera profesional"}},
        ]
      },
      {
        title: { en: "Marital status", "es-MX": "Estado civil" },
        type: "dropdown",
        isRequired: required,
        name: "marital",
        choices: [
          { value: "Soltero", text: { en: "Single", "es-MX": "Soltero" } },
          { value: "Casado", text: { en: "Married", "es-MX": "Casado" } },
          { value: "Viudo", text: { en: "Widowed", "es-MX": "Viudo" } },
          {
            value: "Divorced",
            text: { en: "Divorced", "es-MX": "Divorciado" },
          },
          {
            value: "Common Law",
            text: { en: "Common Law", "es-MX": "Unión Libre" },
          },
        ],
      },
      {
        title: { en: "Property regime", "es-MX": "Régimen de bienes" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_52",
        visibleIf: "{marital} == 'Casado'",
        choices: [
          { value: "Mancomunados", text: { en: "Joint", "es-MX": "Mancomunados" } },
          {
            value: "Separados",
            text: { en: "Separated", "es-MX": "Separados" },
          },
        ],
      },
      {
        title: { en: "Housing type", "es-MX": "Tipo de vivienda" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_53",
        choices: [
          { value: "Propia", text: { en: "Own", "es-MX": "Propia" } },
          {
            value: "Financiada",
            text: { en: "Financed", "es-MX": "Financiada" },
          },
          {
            value: "Rentada (con aval)",
            text: {
              en: "Rented (with endorsement)",
              "es-MX": "Rentada (con aval)",
            },
          },
          {
            value: "Rentada (sin aval)",
            text: {
              en: "Rented (without endorsement)",
              "es-MX": "Rentada (sin aval)",
            },
          },
          { value: "Familiar", text: { en: "Family", "es-MX": "Familiar" } },
        ],
        hasOther: "true",
        otherText: { en: "Other", "es-MX": "Otro" },
      },
      {
        title: {
          en: "Do you want to add a guarantor?",
          "es-MX": "¿Quieres añadir un obligado?",
        },
        type: "dropdown",
        name: "custom_text_89",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
        visibleIf: "{custom_text_53} == 'Propia' || {custom_text_53} == 'Financiada' || {custom_text_53} == 'Familiar'",
        isRequired: required,
      },
      {
        title: {
          en: "Guarantor's name",
          "es-MX": "Nombre del obligado",
        },
        type: "text",
        name: "custom_text_90",
        visibleIf: "{custom_text_89} == 'si'",
        isRequired: required,
      },
      {
        title: {
          en: "Guarantor's phone number",
          "es-MX": "Número de teléfono del obligado",
        },
        type: "phone",
        name: "custom_text_91",
        visibleIf: "{custom_text_89} == 'si'",
        isRequired: required,
        validators: [
          expressions.phone_en("custom_text_91"),
          expressions.phone_length("custom_text_91"),
        ],
      },
      {
        title: {
          en: "Relationship with guarantor",
          "es-MX": "Relación o parentesco con el obligado",
        },
        type: "text",
        name: "custom_text_93",
        visibleIf: "{custom_text_89} == 'si'",
        isRequired: required,
      },
      {
        title: {
          en: "Owner's name",
          "es-MX": "Nombre del propietario y parentesco",
        },
        type: "text",
        name: "custom_text_55",
        visibleIf: "{custom_text_53} == 'Familiar'",
        isRequired: required,
      },
      {
        title: { en: "Time residing", "es-MX": "Tiempo de residir" },
        type: "text",
        name: "custom_text_56",
        isRequired: required,
      },
      {
        title: { en: "Share", "es-MX": "Cuota" },
        visibleIf: "{custom_text_53} != 'Propia' && {custom_text_53} != 'Familiar'",
        type: "text",
        inputType: "number",
        name: "custom_text_57",
        isRequired: required,
      },
      {
        title: {
          en: "Suggested hours for credit committee calls",
          "es-MX": "Horario sugerido para llamadas de comité de crédito ",
        },
        type: "text",
        name: "custom_text_58",
        isRequired: required,
      },
      {
        title: {
          en: "If you do not have the document to be signed with you, you can download it at the following link.",
          "es-MX":
            "si no tienes contigo el documento para firmar, puedes descargarlo en el siguiente enlace.",
        },
        html: {
          en: `<div>On the following link you can generate your RFC <br> <a href='https://www.mi-rfc.com.mx/consulta-rfc-homoclave' target='_blank' rel='noopener noreferrer'>https://www.mi-rfc.com.mx/consulta-rfc-homoclave</a></div>`,
          "es-MX": `<div>En el siguiente enlace podrás generar tu número de RFC <br> <a href='https://www.mi-rfc.com.mx/consulta-rfc-homoclave' target='_blank' rel='noopener noreferrer'>https://www.mi-rfc.com.mx/consulta-rfc-homoclave</a></div>`,
        },
        type: "html",
        expression: "",
        name: "rfc",
      },
    ],
  },
];
