import React from 'react';

import './help.scss'
import './close.scss';
import {translate} from "../../lib/intl";
import {colors} from "../../theme/theme";
import {openInWhatsapp} from "../../lib/helpers";
import {ws_whatsapp} from "../icon/icon";
import {api} from "../../app";
import {Button} from "../button/button";
import {Question} from "./question";

export default class HelpComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  _close() {
    this.setState({visible: false}, () => this.props.onClose())
  }

  _open() {
    setTimeout(() => this.setState({visible: true}, () => this.props.onOpen()), 100)

  }

  render({type, locale} = this.props) {
    return (
      <div className='helpContainer'>

        <div className={`small clickable helpButton ${locale === 'ar' ? 'ar' : 'en'}`}
             onClick={() => this._open()}>{translate('help.button')}</div>

        <div className={`cd-panel ${this.state.visible ? 'is_visible' : ''}`}>
          <header className="helpHeader">
            <a href="#0" className="cd-panel__close" onClick={() => this._close()}>Close</a>
          </header>

          <div className="cd-panel__container">
            <div className="cd-panel__content">

              <div className='help_image'>
                <img src={require('./res/help_image.png')} alt='help' width='150px'/>
              </div>
              <h2 className='bigSpacer white'>{translate('help.title')}</h2>
              <p className='small white'>{translate('help.par1')}</p>
              <p className='small white spacer'>{translate('help.par2')}</p>

              <Question questions={['guarantor', 'pause', 'someone_else']}/>

              <Button backgroundColor={colors.brand.green} color='#fff'
                      title={translate('help.whatsapp')} icon={ws_whatsapp}
                      iconSize={25}
                      onPress={() => openInWhatsapp(api.endpoint.whatsapp_number, translate('help.whatsapp_message'))}/>

            </div>
          </div>
        </div>
      </div>
    )
  }

}