import { SurveyComponent } from "../../components/survey/survey_component";
import env from "../../env";
import { expressions } from "../../survey/validators";

const required = env.ENDPOINT !== "local";

export const credit_check_form_crediavance = new SurveyComponent({
  title: {
    en: "Credit bureau check",
    "es-MX": "Verificación Buró de crédito",
  },
  innerIndent: 1,
  elements: [
    {
      title: { en: "Applicant's name *", "es-MX": "Nombre del solicitante *" },
      type: "text",
      name: "first_name",
      isRequired: required,
    },
    {
      title: {
        en: "If you have any of the following please indicate it",
        "es-MX": "Si cuentas con alguno de los siguientes por favor indícalo",
      },
      type: "panel",
      name: "rfc_curp_panel",
      elements: [
        {
          title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
          type: "text",
          name: "custom_text_1",
          maxLength: 13,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct Tax Id Number (RFC) of 13 characters",
                "es-MX": "RFC 13 dígitos",
              },
              regex: `^[A-Za-z]{4}[ |-]{0,1}[0-9]{6}[ |-]{0,1}[0-9A-Za-z]{3}$`, // should be the correct regex
            },
          ],
          placeHolder: "VECJ880326 XXX",
        },
        {
          title: {
            en: "Please upload the back of your tax identification card",
            "es-MX":
              "Por favor subir el respaldo de la cédula de identificación fiscal",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "picture_4",
          visibleIf: "{custom_text_1} notempty",
          isRequired: required,
        },
        {
          title: {
            en: "Unique Population Registry Code (CURP)",
            "es-MX": "CURP",
          },
          type: "text",
          name: "custom_text_2",
          maxLength: 18,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct CURP of 18 characters",
                "es-MX": "CURP 18 dígitos",
              },
              regex: `^[a-zA-Z]{4}[0-9]{6}[a-zA-Z]{6}[0-9]{2}$`, // should be the correct regex
            },
          ],
          placeHolder: "HEGG560427MVZRRL04",
        },
      ],
    },
    {
      title: {
        en: "Please upload a photo of the front of your INE",
        "es-MX": "Por favor subir foto del frente de la INE​",
      },
      type: "file_uploader",
      accept: "image/*,application/pdf",
      name: "picture_5",
      isRequired: required,
    },
    {
      title: {
        en: "Please upload a photo of the back of your INE",
        "es-MX": "Por favor subir foto del reverso de la INE",
      },
      type: "file_uploader",
      accept: "image/*,application/pdf",
      name: "picture_6",
      isRequired: required,
    },
    {
      title: { en: "Address *", "es-MX": "Domicilio *" },
      type: "text",
      name: "home_street",
      isRequired: required,
    },
    {
      title: { en: "Suburb *", "es-MX": "Colonia *" },
      type: "text",
      name: "custom_text_3",
      isRequired: required,
    },
    {
      title: {
        en: " Municipality *",
        "es-MX": "Municipio *",
      },
      type: "text",
      name: "custom_text_4",
      isRequired: required,
    },
    {
      title: { en: "City *", "es-MX": "Ciudad *" },
      type: "text",
      name: "home_city",
      isRequired: required,
    },
    {
      title: { en: "State *", "es-MX": "Estado *" },
      type: "text",
      name: "custom_text_5",
      isRequired: required,
    },
    {
      title: { en: "Postal Code *", "es-MX": "Código postal*" },
      type: "text",
      name: "po_box_postal_code",
      isRequired: required,
    },
    {
      name: "phone_number",
      type: "phone",
      isRequired: required,
      title: {
        en: "Phone number *",
        "es-MX": "Teléfono *",
      },
      maxLength: 10,
      inputMask: "phone",
      inputFormat: "##-####-####",
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
    },
    {
      name: "phone_verified",
      type: "phone",
      visibleIf: "{phone_number} notempty",
      isRequired: required,
      maxLength: 10,
      inputMask: "phone",
      inputFormat: "##-####-####",
      title: {
        en: "Confirm Phone number *",
        "es-MX": "Confirma tu número de teléfono​",
      },
      validators: [
        {
          type: "expression",
          expression: "{phone_number} == {phone_verified}", // Floating Point Proof
          text: {
            en: "Please make sure both numbers are the same",
            "es-MX": "Asegúrese de que ambos números sean iguales",
          },
        },
      ],
    },
  ],
});
